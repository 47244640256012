import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getIn, Field } from 'formik';

import { FormikInput } from '../Utils/Input';
import RegionCommuneSelect from './RegionCommuneSelect';

const AddressFieldsForm = ({ abbr, values, errors, touched, setFieldValue, parentModel }) => {
  const modelName = `${parentModel}[addressAttributes]`;

  return (
    <>
      <RegionCommuneSelect
        communeAbbr={abbr}
        regionAbbr={abbr}
        modelName={modelName}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
      />
      <Row>
        <Col md={8}>
          <Field name={`${modelName}[address]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr={abbr}
                placeholder="Calle, número, oficina, departamento, etc."
                label="Dirección"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default AddressFieldsForm;
