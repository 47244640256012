import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setFilters } from '../../../actions/utils';
import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexInsuredInsurancePoliciesRequest } from '../../../requests/insured/insuredInsurancePolicies';
import { redirectTo, scrollToTop } from '../../../services/utils';
import {
  insurancePolicyAdvancedOptions,
  insurancePolicyFiltersParams
} from '../../InsurancePolicy/Filters/InsurancePolicyFiltersParams';
import InsurancePolicyDataTable from '../../InsurancePolicy/InsurancePolicyDataTable';
import columns from './columns';
import useManageInsurancePolicy from './useManageInsurancePolicy';

const InsuredInsurancePolicyIndex = ({ location }) => {
  const modelUrl = '/insured/insurance_policies';
  const history = useHistory();

  const customParamsRef = useRef({});
  const dispatch = useDispatch();
  const canManageInsurancePolicy = useManageInsurancePolicy();

  // Custom initial tab: ALL
  insurancePolicyFiltersParams.isValid = '';

  const { data: contracts, totalData, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredInsurancePoliciesRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las pólizas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (state) dispatch(setFilters(state));
  };

  useEffect(scrollToTop, []);
  useEffect(handleSetFiltersOnMount, [location]);

  return (
    <DefaultHeader
      title="Pólizas"
      headerBtn={canManageInsurancePolicy}
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Póliza',
        onClick: () => redirectTo(history, `${modelUrl}/new`, { policyType: 'contract' })
      }}
    >
      <InsurancePolicyDataTable
        tableData={contracts}
        tableDataAmount={totalData}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
        columns={columns}
        filterHeaderComponentProps={{
          filterParams: insurancePolicyFiltersParams,
          advancedOptions: insurancePolicyAdvancedOptions.filter(option => option.value !== 'isVip')
        }}
      />
    </DefaultHeader>
  );
};

export default InsuredInsurancePolicyIndex;
