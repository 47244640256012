import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikDatePicker, FormikInput } from '../..';

const InsuranceEndorsementFormDate = ({ dateLabel, modelName, insurancePolicy, ...props }) => {
  const { errors, touched, setFieldValue, values } = useFormikContext();

  const { endorsementAction, endorsementDate, endorsementType } = values.insuranceEndorsement;
  const { defaultDate, disabled, termDuration } = props;

  const shouldBeMinDate =
    endorsementAction === 'extension' ? insurancePolicy?.validityEnd : insurancePolicy?.validityStart;
  const shouldBeMaxDate = endorsementAction === 'extension' ? undefined : insurancePolicy?.validityEnd;

  const handleInitialValues = () => {
    if (endorsementDate && !defaultDate) return;
    setFieldValue(`${modelName}[endorsementDate]`, defaultDate || new Date());
  };

  useEffect(handleInitialValues, [endorsementAction, endorsementType]);

  return (
    <Row>
      <Col md={3}>
        <Field name={`${modelName}[endorsementDate]`}>
          {({ field }) => (
            <FormikDatePicker
              {...field}
              abbr
              minDate={shouldBeMinDate}
              maxDate={shouldBeMaxDate}
              label={dateLabel}
              placeholder="dd/mm/aaaa"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              disabled={disabled}
            />
          )}
        </Field>
      </Col>
      {termDuration >= 0 && (
        <Col md={3}>
          <Field name={`${modelName}[termDuration]`}>
            {({ field }) => <FormikInput {...field} abbr disabled label="Nueva duración" value={termDuration} />}
          </Field>
        </Col>
      )}
    </Row>
  );
};

export default InsuranceEndorsementFormDate;
