import React from 'react';
import { useHistory } from 'react-router-dom';
import IconGG from '../Utils/Icon';

const NotificationItem = ({ notification, handleClose }) => {
  const history = useHistory();
  const { createdAt, icon, message, read, url } = notification;

  const handleOnClick = () => {
    history.push(`/${url}`);
    handleClose();
  };

  return (
    <div
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      tabIndex={0}
      role="button"
      className={`notification-list--item ${read ? '' : 'unread'}`}
    >
      <div className="notification-list--item__container">
        <div className="notification-list--item__icon">
          <IconGG icon={icon} size="xl" className="text-color-secondary" />
        </div>
        <div className="notification-list--item__body">
          <p className="font-weight-500 mb-3">{message}</p>
          <p className="text-right detail">{createdAt}</p>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
