import React from 'react';
import { Row } from 'react-bootstrap';
import BrokerLandingComertialSuport from '../../../assets/images/Landing/BrokerLanding/comertial-suport.webp';
import BrokerLandingTISuport from '../../../assets/images/Landing/BrokerLanding/ti-suport.webp';

const BrokerLandingSuport = () => {
  return (
    <section id="suport" className="broker-landing__suport__container">
      <Row className="align-items-center">
        <div className="col-md-6">
          <h3 className="broker-landing__suport__title text-dark-blue">
            Si necesitas
            <br /> llegar más lejos,
            <br /> vamos contigo
            <br />
          </h3>
        </div>
        <div className="col-md-6">
          <Row className="broker-landing__suport__cardcontainer mb-5">
            <div
              alt="Manos sosteniendose entre si como representación del apoyo que ofrecemos a nuestros clientes"
              className="col-3 p-0"
              style={{
                backgroundImage: `url(${BrokerLandingComertialSuport})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '40px 0px 0px 40px'
              }}
            />
            <div className="col-9 broker-landing__suport__containertext">
              <h6 className="broker-landing__suport__titlecard">Apoyo comercial</h6>
              <p>
                Para cuentas grandes, riesgos complejos, ramos menos explorados o simplemente mayor presencia. Nuestro
                equipo te asesora y acompaña a visitar a tu cliente.
              </p>
            </div>
          </Row>
          <Row className="broker-landing__suport__cardcontainer">
            <div
              alt="Un computador en representación del apoyo técnico que ofrecemos en nico"
              className="col-3 p-0"
              style={{
                backgroundImage: `url(${BrokerLandingTISuport})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '40px 0px 0px 40px'
              }}
            />
            <div className="col-9 broker-landing__suport__containertext">
              <h6 className="broker-landing__suport__titlecard">Apoyo técnico</h6>
              <p>
                En coberturas específicas, pólizas con reaseguro, siniestros o simplemente mejor información. Nuestro
                equipo te asesora y acompaña a visitar a tu cliente.
              </p>
            </div>
          </Row>
        </div>
      </Row>
    </section>
  );
};

export default BrokerLandingSuport;
