import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminBlogRequest } from '../../../requests/admin/blogs';
import AdminBlogForm from './AdminBlogForm';
import { basicBlog } from './basicBlog';

const AdminBlogNew = () => {
  const modelUrl = '/admin/blogs';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Blogs', href: modelUrl },
    { key: 2, name: 'Crear nuevo blog' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Blog creado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    createAdminBlogRequest({
      dispatch,
      params: values,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminBlogForm action="new" blog={basicBlog} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminBlogNew;
