import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { FormHeader } from '../../components';
import { useFetchData } from '../../hooks';
import { debounceIndexAccountContactsRequest } from '../../requests/Account/contact';
import { debounceIndexAdminAccountContactsRequest } from '../../requests/admin/adminContacts';
import { scrollToTop } from '../../services/utils';
import ContactCard from './ContactCard';
import ContactNewModal from './ContactNew';
import { debounceIndexInsuredAccountContactsRequest } from '../../requests/insured/insuredContacts';

const ContactIndex = ({ match }) => {
  const { id: accountId } = match.params;

  let userModule;
  if (match.path.includes('/admin')) {
    userModule = 'admin';
  } else if (match.path.includes('/insured')) {
    userModule = 'insured';
  } else {
    userModule = 'broker';
  }

  const getParentModelUrl = () => {
    const urlMap = {
      admin: '/admin/accounts',
      broker: '/accounts',
      insured: '/insured/accounts'
    };

    return urlMap[userModule];
  };

  const getContactDebouncedIndexRequest = () => {
    const urlMap = {
      admin: debounceIndexAdminAccountContactsRequest,
      broker: debounceIndexAccountContactsRequest,
      insured: debounceIndexInsuredAccountContactsRequest
    };

    return urlMap[userModule];
  };

  const parentModelUrl = getParentModelUrl();
  const [modalShow, setModalShow] = useState(false);

  const { data: contacts, setMoreData, isFetching } = useFetchData({
    debouncedIndexRequest: getContactDebouncedIndexRequest(),
    customParams: {
      sort_column: 'is_primary_contact',
      sort_direction: 'asc'
    },
    parentId: accountId
  });

  const totalCommercialContacts = contacts.filter(contact => contact.isPrimaryContact).length;

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleBreadCumb = [
    { key: 1, name: 'Cuenta', href: `${parentModelUrl}` },
    { key: 2, name: 'Detalle de cuenta', href: `${parentModelUrl}/${accountId}` },
    { key: 3, name: 'Contactos' }
  ];

  useEffect(scrollToTop, []);

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isFetching && (
        <>
          <FormHeader
            handleBreadcrumb={handleBreadCumb}
            title="Contactos"
            headerBtn
            headerBtnOptions={{
              icon: 'add',
              name: 'Nuevo Contacto',
              onClick: () => setModalShow(true)
            }}
            formComponent={contacts.map(contact => (
              <ContactCard
                key={contact.id}
                contact={contact}
                setMoreData={setMoreData}
                accountId={accountId}
                fromAdmin={userModule === 'admin'}
                totalCommercialContacts={totalCommercialContacts}
                canBeDeleted={!(contact.isPrimaryContact && totalCommercialContacts === 1)}
                userModule={userModule}
              />
            ))}
          />
          <ContactNewModal
            handleModalClose={handleModalClose}
            modalShow={modalShow}
            accountId={accountId}
            setMoreData={setMoreData}
            fromAdmin={userModule === 'admin'}
          />
        </>
      )}
    </>
  );
};

export default ContactIndex;
