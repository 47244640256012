import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { IconBtn } from '../Button';

const handleDelete = (arrayValues, setFieldValue, valuePath, index) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  const newValues = [...arrayValues.slice(0, index), ...arrayValues.slice(index + 1, arrayValuesLength)];

  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    newValues.push(deleteValue);
  }

  setFieldValue(`${valuePath}`, newValues);
};

const addRow = (arrayValues, setFieldValue, valuePath, newAttributes) => {
  const newValues = [...arrayValues, newAttributes];
  setFieldValue(`${valuePath}`, newValues);
};

const buttonRemoveItem = (arrayValues, setFieldValue, valuePath, index, removeBtnStyle) => {
  return (
    <Col xs={2} md={1} className="remove-btn text-right align-self-center" style={{ top: '-1rem', ...removeBtnStyle }}>
      <IconBtn
        variant="transparent"
        className="danger no-shadow"
        size="md"
        icon="trash"
        onClick={() => handleDelete(arrayValues, setFieldValue, valuePath, index)}
      />
    </Col>
  );
};

const AddRowButton = ({ attribute, array, className, isLinkBtn, newAttributes, title, setFieldValue }) => (
  <Col className="d-flex justify-content-end">
    {isLinkBtn ? (
      <IconBtn
        variant="link"
        className={`px-3 ${className}`}
        onClick={() => addRow(array, setFieldValue, attribute, newAttributes)}
      >
        {title}
      </IconBtn>
    ) : (
      <IconBtn
        size="lg"
        icon="add"
        variant="primary"
        className={className}
        onClick={() => addRow(array, setFieldValue, attribute, newAttributes)}
      >
        {title}
      </IconBtn>
    )}
  </Col>
);

const NestedBody = ({
  additionalNested,
  arrayValues,
  body,
  deleteLastOne,
  index,
  inputLength,
  removeBtnStyle,
  removeFirstItem,
  setFieldValue,
  shownLength,
  splitLine,
  valuePath
}) => {
  return (
    <>
      {body && (
        <Col xs={12} className="nested-attribute">
          <Row>
            {body}
            {deleteLastOne
              ? index === inputLength - 1 &&
                inputLength !== 0 &&
                shownLength !== 0 &&
                buttonRemoveItem(arrayValues, setFieldValue, valuePath, index, removeBtnStyle)
              : (removeFirstItem || (inputLength !== 0 && shownLength > 1)) &&
                buttonRemoveItem(arrayValues, setFieldValue, valuePath, index, removeBtnStyle)}
          </Row>
          {splitLine && !additionalNested && index !== inputLength && <hr />}
        </Col>
      )}
    </>
  );
};

const NestedAttributes = ({
  additionalNested,
  arrayValues,
  attributesTitle,
  bottomBtnAddRow = false,
  btnClassName,
  btnMessage,
  deleteLastOne,
  isColumnReverse = false,
  isLinkBtn = false,
  mapInputs,
  newAttributes,
  noBtn = false,
  removeBtnStyle,
  removeFirstItem,
  setFieldValue,
  splitLine = true,
  valuePath
}) => {
  const inputLength = mapInputs.length - 1;
  const shownLength = mapInputs.filter(element => element !== undefined).length;

  const nestedBody = mapInputs.map((body, index) => (
    <NestedBody
      key={`nested-${index.toString()}`}
      arrayValues={arrayValues}
      body={body}
      deleteLastOne={deleteLastOne}
      removeFirstItem={removeFirstItem}
      index={index}
      inputLength={inputLength}
      setFieldValue={setFieldValue}
      shownLength={shownLength}
      valuePath={valuePath}
      newAttributes={newAttributes}
      additionalNested={additionalNested}
      splitLine={splitLine}
      removeBtnStyle={removeBtnStyle}
    />
  ));

  return (
    <>
      {((mapInputs.length > 0 && attributesTitle) || (!bottomBtnAddRow && !noBtn)) && (
        <Row key={`btn-add-row-${shownLength.toString()}`} className="mb-4">
          {mapInputs.length > 0 && attributesTitle && (
            <Col className="align-self-center">
              <p className="section-title m-0">{attributesTitle}</p>
            </Col>
          )}
          {!bottomBtnAddRow && !noBtn && (
            <AddRowButton
              isLinkBtn={isLinkBtn}
              attribute={valuePath}
              title={btnMessage}
              array={arrayValues}
              newAttributes={newAttributes}
              className={btnClassName}
              setFieldValue={setFieldValue}
            />
          )}
        </Row>
      )}

      <Row className={`nested-attributes ${isColumnReverse ? 'd-flex flex-column-reverse' : ''}`}>{nestedBody}</Row>

      {bottomBtnAddRow && !noBtn && (
        <Row>
          <AddRowButton
            isLinkBtn={isLinkBtn}
            attribute={valuePath}
            title={btnMessage}
            array={arrayValues}
            newAttributes={newAttributes}
            className={mapInputs.length > 0 ? btnClassName : 'mr-3'}
            setFieldValue={setFieldValue}
          />
        </Row>
      )}
    </>
  );
};

export default NestedAttributes;
