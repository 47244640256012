import React from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { BtnTooltip, CustomBtn } from '../../../components';
import { exportAdminInsuranceEndorsementSummary } from '../../../requests/admin/insuranceEndorsements';
import { exportInsuranceEndorsementSummary } from '../../../requests/insuranceEndorsement';
import { formatCurrency, handleDateFormat, openFile } from '../../../services/utils';
import ExportInsuranceEndorsementSummaryButton from './ExportInsuranceEndorsementSummaryButton';

const InsuranceEndorsementColumns = ({
  canEditEndorsements,
  currency,
  fromAdmin,
  handleShowModal,
  insurancePolicy,
  setInsuranceEndorsement,
  canManageEndorsement,
  userModule
}) => {
  const columns = [
    {
      name: 'N° propuesta',
      selector: 'id',
      sortable: true,
      grow: '1'
    },
    {
      name: 'N° endoso (NICO)',
      selector: 'index',
      sortable: true,
      grow: '1'
    },
    {
      name: 'Fecha de endoso',
      selector: 'endorsementDate',
      cell: ({ endorsementDate, restricted }) => (restricted ? 'Pendiente' : handleDateFormat(endorsementDate)),
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Tipo de endoso',
      selector: 'endorsementTypeLabel',
      cell: ({ endorsementTypeLabel, restricted }) => (restricted ? 'Pendiente' : endorsementTypeLabel),
      sortable: true,
      grow: '2'
    },
    {
      name: 'Ítem modificado',
      selector: 'insuranceItemId',
      cell: ({ insuranceItemAttributes, restricted }) => (restricted ? 'Pendiente' : insuranceItemAttributes.name),
      sortable: true,
      grow: '1'
    },
    {
      name: 'Variación prima neta',
      selector: 'netPremiumVariation',
      cell: ({ variationTotalNetPremium, restricted }) =>
        restricted
          ? 'Pendiente'
          : formatCurrency({
              value: variationTotalNetPremium,
              addon: ` ${currency}`
            })
    },
    {
      name: 'Propuesta (NICO)',
      grow: '1',
      cell: ({ id, restricted }) => (
        <ExportInsuranceEndorsementSummaryButton
          insurancePolicyId={insurancePolicy.id}
          insuranceEndorsementId={id}
          disabled={restricted}
          fromAdmin={fromAdmin}
        />
      )
    },
    {
      name: 'Endoso (compañía)',
      cell: ({ id, companyEndorsementInfo }) => (
        <CompanyEndorsementFile id={id} companyEndorsementInfo={companyEndorsementInfo} />
      ),
      grow: '1'
    },
    {
      name: 'N° endoso (compañía)',
      selector: 'companyEndorsementNumber',
      cell: ({ companyEndorsementNumber }) => companyEndorsementNumber || 'Pendiente',
      sortable: true,
      grow: '1'
    },
    {
      name: 'Fecha de emisión (compañía)',
      selector: 'companyIssueDate',
      cell: ({ companyIssueDate }) => companyIssueDate || 'Pendiente',
      sortable: true,
      grow: '1.25'
    }
  ];

  if (canEditEndorsements) {
    columns.push({
      cell: insuranceEndorsement => (
        <ActionButton
          fromAdmin={fromAdmin}
          insurancePolicy={insurancePolicy}
          insuranceEndorsement={insuranceEndorsement}
          setInsuranceEndorsement={setInsuranceEndorsement}
          handleShowDestroyModal={handleShowModal}
          canManageEndorsement={canManageEndorsement}
          userModule={userModule}
        />
      ),
      sortable: false,
      grow: '2'
    });
  }

  return columns;
};

const CompanyEndorsementFile = ({ id, companyEndorsementInfo }) =>
  Object.keys(companyEndorsementInfo).length === 0 ? (
    'Pendiente'
  ) : (
    <CustomBtn
      key={`company-endorsement-${id.toString()}`}
      onClick={() => openFile(companyEndorsementInfo)}
      className="btn-sm"
    >
      PDF
    </CustomBtn>
  );

const ActionButton = ({
  fromAdmin,
  handleShowDestroyModal,
  insuranceEndorsement,
  insurancePolicy,
  setInsuranceEndorsement,
  canManageEndorsement,
  userModule
}) => {
  const dispatch = useDispatch();

  const { id, applied, companyEndorsementInfo, companyEndorsementNumber, restricted } = insuranceEndorsement;
  const isCompanyEndorsement = companyEndorsementNumber && Object.keys(companyEndorsementInfo).length > 0;

  const exportSummary = exportFunc => {
    exportFunc(insurancePolicy.id, insuranceEndorsement.id, {
      dispatch,
      params: {},
      successCallback: response => {
        setInsuranceEndorsement({
          ...insuranceEndorsement,
          isCompanyEndorsement: false,
          endorsementSummaryInfo: camelCaseRecursive(response.data)
        });
      }
    });
  };

  const handleGenerateEndorsementSummary = () => {
    if (fromAdmin) {
      exportSummary(exportAdminInsuranceEndorsementSummary);
    } else {
      exportSummary(exportInsuranceEndorsementSummary);
    }
  };

  const applyEndorsementPathName = () => {
    const pathMap = {
      admin: `/admin/insurance_policies/${insurancePolicy.id}/endorsements/${id}/company_edit`,
      broker: `/insurance_policies/${insurancePolicy.id}/endorsements/${id}/company_edit`,
      insured: `/insured/insurance_policies/${insurancePolicy.id}/endorsements/${id}/company_edit`
    };

    return pathMap[userModule];
  };

  const applyEndorsementOriginPath = () => {
    const pathMap = {
      admin: `/admin/insurance_policies/${insurancePolicy.id}}`,
      broker: `/insurance_policies/${insurancePolicy.id}}`,
      insured: `/insured/insurance_policies/${insurancePolicy.id}}`
    };

    return pathMap[userModule];
  };

  const editEndorsementPathName = () => {
    const pathMap = {
      admin: `/admin/insurance_policies/${insurancePolicy.id}/endorsements/${id}/edit`,
      broker: `/insurance_policies/${insurancePolicy.id}/endorsements/${id}/edit`,
      insured: `/insured/insurance_policies/${insurancePolicy.id}/endorsements/${id}/edit`
    };

    return pathMap[userModule];
  };

  const editEndorsementOriginPath = () => {
    const pathMap = {
      admin: `/admin/insurance_policies/${insurancePolicy.id}}`,
      broker: `/insurance_policies/${insurancePolicy.id}}`,
      insured: `/insured/insurance_policies/${insurancePolicy.id}}`
    };

    return pathMap[userModule];
  };

  return (
    <>
      {(fromAdmin || canManageEndorsement) && (
        <SendEmailBtn title="propuesta" onClick={handleGenerateEndorsementSummary} disabled={restricted} />
      )}

      {isCompanyEndorsement ? (
        <SendEmailBtn
          title="endoso"
          onClick={() => setInsuranceEndorsement({ ...insuranceEndorsement, isCompanyEndorsement: true })}
        />
      ) : (
        (fromAdmin || canManageEndorsement) && (
          <>
            <BtnTooltip
              disabled={restricted}
              icon="file-add"
              iconSize="sm"
              variant="transparent"
              className="green"
              tooltipText={restricted ? '' : 'Aplicar endoso'}
              to={{
                pathname: applyEndorsementPathName(),
                state: { originPath: applyEndorsementOriginPath(), insurancePolicy }
              }}
            />
            <BtnTooltip
              disabled={applied}
              icon="pen"
              iconSize="sm"
              variant="transparent"
              className="edit-btn"
              tooltipText="Editar"
              to={{
                pathname: editEndorsementPathName(),
                state: { originPath: editEndorsementOriginPath(), insurancePolicy }
              }}
            />
            <BtnTooltip
              disabled={applied}
              icon="trash"
              iconSize="sm"
              variant="transparent"
              className="danger"
              tooltipText="Eliminar"
              onClick={() => handleShowDestroyModal(insuranceEndorsement)}
            />
          </>
        )
      )}
    </>
  );
};

const SendEmailBtn = ({ disabled, onClick, title }) => (
  <BtnTooltip
    icon="mail"
    iconSize="sm"
    variant="transparent"
    className="black ml-2"
    tooltipText={disabled ? '' : `Enviar ${title}`}
    onClick={onClick}
    disabled={disabled}
  />
);

export default InsuranceEndorsementColumns;
