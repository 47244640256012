import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import jwt from 'jwt-decode';
import axios from 'axios';

import { camelCaseEmptyStringRecursive } from '../services/utils';

const useFetchData = ({
  customParams = {},
  debouncedIndexRequest,
  filterStatus,
  parentId,
  setFilterStatus = () => null,
  withDataTable = false,
  withoutWrapper = false
}) => {
  const axiosCancelToken = useRef(null);

  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    if (withoutWrapper) {
      const responseData = camelCaseEmptyStringRecursive(response.data);
      setData(responseData);
    } else {
      const responseData = camelCaseEmptyStringRecursive(response.data.data);
      const totalAmountOfData = response.data.metadata.amount;
      setData(responseData);
      setTotalData(totalAmountOfData);
    }
  };

  const handleSaveDataTableParams = newParams => {
    if (!filterStatus) return;
    const allFilters = { ...filterStatus.allFilters, ...camelCaseEmptyStringRecursive(newParams) };
    setFilterStatus({ ...filterStatus, isAnUpdate: true, allFilters });
  };

  const handleIndexRequest = (params, newParams = false) => {
    if (axiosCancelToken.current) axiosCancelToken.current.cancel();

    const payload = localStorage.getItem('jwt');

    if (payload) {
      const currentTime = Date.now() / 1000;
      const isExpired = currentTime >= jwt(payload).exp;
      if (isExpired) return;
    }

    let sendParams = { ...params, ...customParams };

    if (newParams) {
      sendParams = { ...sendParams, ...newParams };
      handleSaveDataTableParams(newParams);
    }

    axiosCancelToken.current = axios.CancelToken.source();

    if (parentId) {
      debouncedIndexRequest(parentId, {
        source: axiosCancelToken.current,
        dispatch,
        params: sendParams,
        successCallback: handleSuccessRequest,
        callback: () => setIsFetching(false)
      });
    } else {
      debouncedIndexRequest({
        source: axiosCancelToken.current,
        dispatch,
        params: sendParams,
        successCallback: handleSuccessRequest,
        callback: () => setIsFetching(false)
      });
    }

    setIsFetching(true);
  };

  const initialFetch = () => {
    const payload = localStorage.getItem('jwt');
    if (payload) {
      const currentTime = Date.now() / 1000;
      const isExpired = currentTime >= jwt(payload).exp;
      if (isExpired) return;
    }

    if (!withDataTable) {
      handleIndexRequest({
        sort_column: 'created_at',
        sort_direction: 'asc'
      });
    }
  };

  useEffect(initialFetch, [moreData]);

  return {
    data,
    totalData,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  };
};

export default useFetchData;
