import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import '../../../services/yupCustomMethods';
import { BasicTextArea, FormikInput, FormikSwitch, FormikNumberFormat, UploadImage } from '../../../components';
import { lowerCaseTextFormat, percentageLimit, rutFormat, validRutInput } from '../../../services/utils';
import ExecutiveManagerRemoteSelector from '../../../components/Shared/ExecutiveManagerRemoteSelector';

const AdminCompanyForm = ({ action, errors, isSubmitting, submitVariant, touched, setFieldValue }) => {
  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const handleFormatTextToLowerCase = e => {
    const formattedValue = lowerCaseTextFormat(e.target.value);
    setFieldValue(e.target.name, formattedValue);
  };

  const submitBtnText = action === 'new' ? 'Crear Empresa' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <AttachImage label="Adjuntar logo" attribute="logo" modelName="company" />
        </Col>
        <Col md={6}>
          <Field name="company[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={6}>
          <Field name="company[active]">
            {({ field }) => <FormikSwitch {...field} abbr field={field} label="¿Empresa activa?" />}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                onChange={handleFormatTextToLowerCase}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <p className="section-title mt-5">Gestión NICO</p>
      <Row>
        <Col md={6}>
          <Field name="company[parsedNicoCommissionPercentageGeneral]">
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="% Comisión NICO Generales"
                suffix=" %"
                isAllowed={percentageLimit}
                fieldName="company[nicoCommissionPercentageGeneral]"
                error={getIn(errors, 'company[nicoCommissionPercentageGeneral]')}
                touched={getIn(touched, 'company[nicoCommissionPercentageGeneral]')}
              />
            )}
          </Field>
        </Col>

        <Col md={6}>
          <Field name="company[parsedNicoCommissionPercentageLife]">
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="% Comisión NICO Vida"
                suffix=" %"
                isAllowed={percentageLimit}
                fieldName="company[nicoCommissionPercentageLife]"
                error={getIn(errors, 'company[nicoCommissionPercentageLife]')}
                touched={getIn(touched, 'company[nicoCommissionPercentageLife]')}
              />
            )}
          </Field>
        </Col>

        <Col md={6}>
          <Field name="company[executiveManagerId]">
            {({ field }) => <ExecutiveManagerRemoteSelector fromAdmin field={field} modelName="company" />}
          </Field>
        </Col>
      </Row>

      <p className="section-title mt-5">Marcas de empresa</p>
      <Row>
        <Col xs={6} md={4} lg={2}>
          <Field name="company[isNotifiedInsuredUser]">
            {({ field }) => <FormikSwitch {...field} field={field} label="Cobranza a cliente" />}
          </Field>
        </Col>
      </Row>

      <p className="section-title mt-5">Parámetros del landing</p>
      <Row>
        <Col xs={12}>
          <AttachImage label="Adjuntar imagen de fondo" attribute="landingBackground" modelName="company" />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="company[landingPrimaryColor]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Color primario"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[landingSecondaryColor]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Color secundario"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field name="company[landingContentOne]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                rows={3}
                maxLength={60}
                label="Contenido 1"
                placeholder="Escribe el mensaje principal tamaño medio"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[landingContentOneTypewriter]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                rows={3}
                maxLength={40}
                label="Contenido 1 (texto con efecto)"
                placeholder="Escribe el texto con efecto escritura-borrado-escritura, separar por coma"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="company[landingContentTwo]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                rows={3}
                maxLength={150}
                label="Contenido 2"
                placeholder="Escribe el mensaje principal tamaño grande"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="my-5">
          <Button block type="submit" variant={submitVariant} className="w-100" disabled={isSubmitting}>
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const AttachImage = ({ abbr, attribute, disabled, label, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const fileData = getIn(values, `${modelName}[${attribute}Info]`);

  return (
    <Field name={`${modelName}[${attribute}]`}>
      {({ field }) => (
        <UploadImage
          {...field}
          abbr={abbr}
          forProfileAvatar
          label={label}
          imageUrl={getIn(fileData, 'fileUrl')}
          onChange={logo => setFieldValue(field.name, logo)}
          error={getIn(errors, field.name)}
          touched={getIn(touched, field.name)}
          disabled={disabled}
        />
      )}
    </Field>
  );
};

const setInitialValues = ({ company }) => {
  const { nationalIdentification } = company;
  return {
    company: {
      ...company,
      nationalIdentification: rutFormat(nationalIdentification)
    }
  };
};

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    email: Yup.string()
      .required('Debes ingresar un correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    executiveManagerId: Yup.string().required('Debes seleccionar un ejecutivo'),
    landingPrimaryColor: Yup.string().nullable(),
    landingSecondaryColor: Yup.string().nullable(),
    landingContentOne: Yup.string().nullable(),
    landingContentTwo: Yup.string().nullable(),
    landingBackground: Yup.mixed()
      .notRequired()
      .test('fileSize', 'Archivo es demasiado grande.', value => {
        if (!value || Object.keys(value).length === 0) return true;
        return value && value.size && value.size <= FILE_SIZE;
      })
      .test('fileFormat', 'Formato debe ser .jpg, .jpeg o .png', value => {
        if (!value || Object.keys(value).length === 0) return true;
        return value && value.type && SUPPORTED_FORMATS.includes(value.type);
      }),
    logo: Yup.mixed()
      .notRequired()
      .test('fileSize', 'Archivo es demasiado grande.', value => {
        if (!value || Object.keys(value).length === 0) return true;
        return value && value.size && value.size <= FILE_SIZE;
      })
      .test('fileFormat', 'Formato debe ser .jpg, .jpeg o .png', value => {
        if (!value || Object.keys(value).length === 0) return true;
        return value && value.type && SUPPORTED_FORMATS.includes(value.type);
      }),
    name: Yup.string().required('Debes ingresar un nombre'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un RUT')
      .rut('El RUT es inválido'),
    nicoCommissionPercentageGeneral: Yup.string()
      .required('Debes ingresar un porcentaje para la comisión general')
      .typeError('Debes ingresar un porcentaje para la comisión general'),
    nicoCommissionPercentageLife: Yup.string()
      .required('Debes ingresar un porcentaje para la comisión vida')
      .typeError('Debes ingresar un porcentaje para la comisión vida')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminCompanyForm);
