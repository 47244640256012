import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../../actions/utils';
import { FormHeader } from '../../../../components';
import { createAdminCommuneRequest } from '../../../../requests/admin/communes';
import AdminCommuneForm from './AdminCommuneForm';
import basicCommune from './basicCommune';

const AdminCommuneNew = () => {
  const modelUrl = '/admin/communes';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Comunas', href: modelUrl },
    { key: 2, name: 'Crear nueva comuna' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Comuna creada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createAdminCommuneRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminCommuneForm action="new" commune={basicCommune} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminCommuneNew;
