const reservationEmailProps = ({ lead, currentUser }) => {
  const { account, subjectMatter } = lead;

  const subjectMatterInfo = subjectMatter
    ? subjectMatter.split('Información de formulario de cotización:')[0].trim()
    : '[DESCRIPCIÓN GENERAL]';

  return {
    modalTitle: { textConnector: '', text: 'reserva', recipient: account.name },
    subject: `NICO Seguros - Solicitud de reserva para la cuenta ${account.name}`,
    cc: [currentUser.email],
    body: `Hola!<br><br>
    NICO Corredores de Seguros SpA solicita la reserva de cotización para la cuenta ${account.name} y su materia a asegurar ${subjectMatterInfo}.<br><br>
    En los próximos días enviaré los detalles para iniciar el proceso de cotización.<br><br>
    Por favor confirmar recepción y reserva respondiendo a este mismo correo.<br><br>
    ¡Gracias!<br>
    ${currentUser.name}`,
    attachments: []
  };
};

export default reservationEmailProps;
