import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { IconBtn, IconGG } from '../../components';

const HomeTermsServices = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Row className="home-terms" style={{ bottom: 'auto !impotant' }}>
      <Col xs={12} className="home-contact__navbar">
        <IconBtn onClick={goBack} variant="transparent" className="home-contact__navbar__back-btn">
          <IconGG icon="chevron-left" />
          <span>Volver</span>
        </IconBtn>
      </Col>

      <Col lg={{ span: 8, offset: 3 }} className="home-contact__form">
        <Row>
          <Col md={12} className="home-contact__form__title">
            <h6>Términos y Condiciones de Uso del Servicio de Consulta de Pólizas</h6>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>1. Descripción del Servicio:</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              NICO Corredores de Seguros SpA (en adelante ‘NICO’) ofrece un servicio de consulta de pólizas de seguros
              que permite a los usuarios obtener información sobre sus pólizas ‘vigentes’ y ‘no vigentes con
              obligaciones vigentes’ a través de una conexión con un servicio proporcionado por la Comisión para el
              Mercado Financiero (en adelante ‘CMF’). El usuario ingresa sus datos de identificación en el sitio web de
              NICO y la CMF entrega un resumen con las pólizas vigentes.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>2. Responsabilidades:</h6>
          </Col>
          <Col md={12}>
            <ul style={{ padding: '5px 30px' }}>
              <li>
                NICO: NICO no se hace responsable de la veracidad, exactitud o integridad de la información
                proporcionada por la CMF. NICO solo actúa como un intermediario que facilita la conexión al servicio de
                la CMF.
              </li>
              <li>
                CMF: La CMF es responsable de la veracidad, exactitud e integridad de la información proporcionada por
                el servicio.
              </li>
              <li>
                Usuario: El usuario es responsable de la exactitud de los datos de identificación que ingresa en el
                sistema.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>3. Limitaciones del Servicio:</h6>
          </Col>
          <Col md={12}>
            <ul style={{ padding: '5px 30px' }}>
              <li>El servicio solo está disponible para pólizas de seguros contratadas en Chile.</li>
              <li>
                El servicio no incluye información sobre pólizas de seguros que no estén vigentes y no tengan
                obligaciones vigentes.
              </li>
              <li>
                El servicio no incluye información sobre pólizas de seguros emitidas por entidades no reguladas por la
                CMF.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>4. Uso de la Información:</h6>
          </Col>
          <Col md={12}>
            <ul style={{ padding: '5px 30px' }}>
              <li>
                NICO no utilizará ni almacenará la información personal proporcionada por el usuario para ningún otro
                propósito que no sea el de facilitar la conexión a la CMF.
              </li>
              <li>
                La CMF podrá utilizar la información personal proporcionada por el usuario de acuerdo con sus propios
                términos y condiciones.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>5. Modificaciones:</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              NICO se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Las
              modificaciones se publicarán en el sitio web de NICO y entrarán en vigor a partir de la fecha de
              publicación.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>6. Aceptación de los Términos:</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Al utilizar el servicio de resumen de pólizas de seguros, el usuario acepta estos términos y condiciones.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>7. Ley Aplicable:</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Estos términos y condiciones se regirán e interpretarán de conformidad con las leyes de Chile.
            </p>
            <p className="mb-3">
              Si tienes preguntas sobre nuestros términos y condiciones, puedes ponerte en contacto con nuestro equipo
              de atención al cliente en aurora@nicoseguros.com.
            </p>
            <p className="mb-3">Fecha de última actualización: 15 de febrero de 2024</p>
          </Col>
        </Row>
      </Col>

      <div className="background-circles">
        <span className="circle-top" />
        <span className="circle-left" />
        <span className="circle-petite-1" />
        <span className="circle-petite-2" />
        <span className="circle-petite-3" />
      </div>
    </Row>
  );
};

export default HomeTermsServices;
