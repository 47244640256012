export const warningYellow = '#f8d73c';
export const varWarningYellow = '#fdf3c5';
export const varLightBlue = '#F4FBFE';
export const primaryLightBlue = '#21B0F2';
export const gray600 = '#adb5bd';
export const primaryVarBlue = '#bce7fb';
export const primaryVarGrey = '#bcc0ca';
export const varDanger = '#FACCD7';
export const danger = '#AB0000';
export const varDark = '#555555';
export const dark = '#232323';
export const varSuccess = '#D1F7DE';
export const greenSubmit = '#17D859';
export const primaryDarkBlue = '#1F2E4F';

export const black = '#232323';
export const white = '#FFFFFF';
