import React from 'react';
import Select from 'react-select';

import colorStyles from './colorStyles';

const DataTableSelect = ({ id, attribute, defaultValue, options, updateDataTable, updateRequest }) => {
  return (
    <Select
      // menuIsOpen
      isSearchable={false}
      className="w-100"
      defaultValue={options.filter(option => option.value.includes(defaultValue))}
      options={options}
      onChange={selectedOption => updateRequest({ id, attribute, value: selectedOption.value, updateDataTable })}
      components={{ IndicatorSeparator: () => null }}
      loadingMessage={() => 'Cargando...'}
      noOptionsMessage={() => 'No hay opciones'}
      styles={{ ...colorStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPosition="fixed"
      menuShouldBlockScroll
    />
  );
};

export default DataTableSelect;
