import React from 'react';
import { Image } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';

const CompanyLandingMain = ({ company }) => {
  const {
    landingBackgroundInfo,
    landingContentOne,
    landingContentOneTypewriter,
    landingContentTwo,
    landingPrimaryColor,
    logoInfo
  } = company;

  return (
    <main
      id="main"
      className="company-landing__main"
      style={{
        '--inline-company-background': `url(${landingBackgroundInfo.fileUrl})`,
        '--inline-primary-color': landingPrimaryColor
      }}
    >
      <div className="landing--main__info w-75">
        <Image fluid src={logoInfo.fileUrl} alt="Logo" className="main-logo" width="100" height="auto" />
        <p className="main-subtitle mt-5 mb-3">
          <span className="mr-2">{landingContentOne}</span>
          {landingContentOneTypewriter.length > 0 && (
            <span className="font-weight-bold">
              <Typewriter
                options={{
                  strings: landingContentOneTypewriter,
                  autoStart: true,
                  loop: true
                }}
              />
            </span>
          )}
        </p>
        <h1 className="main-title">{landingContentTwo}</h1>
      </div>
    </main>
  );
};

export default CompanyLandingMain;
