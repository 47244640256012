import React from 'react';
import { Row } from 'react-bootstrap';
import Smile from '../../../assets/svg/js/smile';
import Document from '../../../assets/svg/js/document';
import Documents from '../../../assets/svg/js/documents';
import Arrows from '../../../assets/svg/js/arrows';
import Dollar from '../../../assets/svg/js/dollar';
import Build from '../../../assets/svg/js/build';
import Medal from '../../../assets/svg/js/medal';
import Flag from '../../../assets/svg/js/flag';
import Eye from '../../../assets/svg/js/eye';
import Listbox from '../../../assets/svg/js/listbox';

const PartnerLandingPillsServices = () => {
  return (
    <section id="demo" className="client-landing--main__info__services">
      <Row className="align-items-start m-0 d-flex justify-content-center ">
        <div className="col-sm-12 col-md-12 col-lg-5 p-0">
          <h6 className="client-landing--main__info__services__subtitle">
            El % de cobro de NICO dependerá del tipo de seguro ofrecido a tus clientes.
          </h6>
          <h3 className="client-landing--main__info__services__title">
            Empieza a ofrecer seguros a tus clientes rápido y fácil. <br /> NICO es{' '}
            <span style={{ color: '#21B0F2' }}>plug & play. </span>
          </h3>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-7 d-flex flex-column justify-content-center">
          <p className="mb-3 client-landing--main__info__services__subtitle">Aquí los servicios de NICO</p>
          <Row>
            <div className="d-flex client-landing--main__info__services__pills col-lg-4 col-sm-12 ">
              <Smile />
              <p>Recibe los leads</p>
            </div>

            <div className="d-flex client-landing--main__info__services__pills col-lg-2 col-sm-12 ">
              <Document />
              <p>Cotiza</p>
            </div>

            <div className="d-flex client-landing--main__info__services__pills col-lg-4 col-sm-12 ">
              <Arrows />
              <p>Renueva pólizas</p>
            </div>
            <div className="d-flex client-landing--main__info__services__pills col-lg-3 col-sm-12 ">
              <Dollar />
              <p>Negocia</p>
            </div>

            <div className="d-flex client-landing--main__info__services__pills col-lg-4 col-sm-12">
              <Build />
              <p>Construye propuestas</p>
            </div>

            <div className="d-flex client-landing--main__info__services__pills col-lg-4 col-sm-12 ">
              <Documents />
              <p>Endosos y prórrogas</p>
            </div>
            <div className="d-flex client-landing--main__info__services__pills col-lg-6 col-sm-12 ">
              <Medal />
              <p>Acompaña la emisión de pólizas</p>
            </div>

            <div className="d-flex client-landing--main__info__services__pills col-lg-4 col-sm-12 ">
              <Flag />
              <p>Administra siniestros</p>
            </div>
            <div className="d-flex client-landing--main__info__services__pills col-lg-5 col-sm-12 ">
              <Eye />
              <p>Monitorea deudas y cobra</p>
            </div>

            <div className="d-flex client-landing--main__info__services__pills col-lg-6 col-sm-12 ">
              <Listbox />
              <p>Cuadra, cobra y paga comisiones</p>
            </div>
          </Row>
        </div>
      </Row>
    </section>
  );
};

export default PartnerLandingPillsServices;
