import React from 'react';
import { IconBtn } from '../../../components';
import AdminSettingForm from './AdminSettingForm';

const AdminSettingColumn = ({ handleUpdateRequest, setting, isEditing, setIsEditing }) => {
  const { value, dataType } = setting;
  const isFile = ['file', 'image'].includes(dataType);
  const attachmentUrl = setting.image?.fileUrl || setting.file?.fileUrl;
  const attachmentMessage = dataType === 'image' ? 'Ver Imagen' : 'Ver Archivo';

  return (
    <>
      {isEditing ? (
        <div>
          <AdminSettingForm setting={setting} formRequest={handleUpdateRequest} setIsEditing={setIsEditing} />
        </div>
      ) : (
        <div>
          {isFile ? (
            <a href={attachmentUrl} target="_blank" rel="noreferrer">
              {attachmentUrl ? attachmentMessage : 'Aun no hay datos'}
            </a>
          ) : (
            value || ''
          )}
          <span className="link-menu float-right">
            <IconBtn icon="pen" iconSize="xs" className="btn edit-btn no-shadow" onClick={() => setIsEditing(true)} />
          </span>
        </div>
      )}
    </>
  );
};

export default AdminSettingColumn;
