import React from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { BulletSwitchBtn } from '../../../components';
import { updateAdminCompanyRequest } from '../../../requests/admin/companies';

const AdminCompanyInfo = ({ company }) => {
  const {
    active,
    createdAt,
    email,
    executiveManagerLabel,
    nationalIdentification,
    parsedNicoCommissionPercentageGeneral,
    parsedNicoCommissionPercentageLife
  } = company;

  return (
    <>
      <p className="section-title">Información registrada</p>
      <div className="grid-two-columns mb-5">
        <InfoLine title="¿Empresa activa?" value={active ? 'Sí' : 'No'} />
        <InfoLine title="RUT" value={nationalIdentification} />
        <InfoLine title="Correo electrónico" value={email} />
        <InfoLine title="Ejecutivo" value={executiveManagerLabel} />
        <InfoLine title="% Comisión NICO General" value={parsedNicoCommissionPercentageGeneral} />
        <InfoLine title="% Comisión NICO Vida" value={parsedNicoCommissionPercentageLife} />
        <InfoLine title="Fecha de creación" value={createdAt} />
      </div>

      <CompanyMarks company={company} />
    </>
  );
};

const InfoLine = ({ title, value }) => (
  <p className="bullet-element">
    {title}: {value || 'Sin especificar'}
  </p>
);

const CompanyMarks = ({ company }) => {
  const { id, isNotifiedInsuredUser } = company;
  const dispatch = useDispatch();

  const handleUpdateRequest = ({ attribute, value }) => {
    const sendParams = snakecaseKeys({ company: { [attribute]: value } });
    updateAdminCompanyRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Empresa actualizada con éxito' }));
      }
    });
  };

  return (
    <>
      <p className="section-title">Marcas de empresa</p>
      <BulletSwitchBtn
        title="Cobranza a cliente"
        customWidth={{ width: '18rem' }}
        attribute="isNotifiedInsuredUser"
        initialValue={isNotifiedInsuredUser}
        onClick={handleUpdateRequest}
        tooltipText="Al estar habilitado como 'Cobranza a cliente', <br/>
                     el contacto comercial de la cuenta recibirá <br/>
                     correos de cobranza por cada una de sus <br/>
                     pólizas con deuda."
      />
    </>
  );
};

export default AdminCompanyInfo;
