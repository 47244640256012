/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import defaultProfileImg from '../../../assets/images/default-profile.png';

const UploadImage = ({ error, label, touched, ...props }) => {
  const { forProfileAvatar, imageUrl, isImage = true, onChange, roundedImg } = props;
  const [url, setUrl] = useState('');
  const [name, setName] = useState(label);

  const handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setUrl(reader.result);
      setName(file.name);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    onChange(file);
  };

  useEffect(() => {
    if (props.fileName) setName(props.fileName);
  }, [props.fileName]);

  return (
    <div className={forProfileAvatar ? 'profile-avatar' : ''}>
      {isImage && (
        <div className="custom-file-img mb-3">
          <Image
            src={url || imageUrl || `${forProfileAvatar ? defaultProfileImg : defaultImg}`}
            fluid
            alt="image-1"
            className={roundedImg ? 'rounded-circle' : ''}
          />
        </div>
      )}
      <div className="input-group mb-3">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input secondary"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            onChange={handleImageChange}
          />
          <label
            className={`custom-file-label text-truncate ${forProfileAvatar ? 'btn btn-sm' : ''}`}
            htmlFor="inputGroupFile01"
          >
            {forProfileAvatar ? label : name}
          </label>
        </div>
      </div>
      {error && touched ? <small className="text-danger form-text ml-5">{error}</small> : null}
    </div>
  );
};

UploadImage.defaultProps = {
  onChange: () => {},
  label: 'Seleccionar archivo'
};

export default UploadImage;
