import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';

const ClientLandingAccordionFaqsPersons = () => {
  return (
    <Accordion defaultActiveKey="0" className="container__accordion__client">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" className="font-weight-bold">
          ¿NICO es un corredor de seguros?
          <Accordion.Collapse eventKey="0">
            <Card.Body className="font-weight-500 ">
              Sí, NICO está certificado como corredor de seguros (código 9065), y es regulado por la Comisión para el
              Mercado Financiero (CMF).
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1" className="font-weight-bold">
          Para tener mis seguros con NICO, ¿necesito instalar o pagar algo?
          <Accordion.Collapse eventKey="1">
            <Card.Body className="font-weight-500 ">
              No, solo tienes que contratar tus seguros con nosotros. Así, NICO es tu corredor de seguros y se encarga
              de todo. NICO cobra una comisión por distribución a las compañías de seguros. Así lo establece la
              regulación de la CMF.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2" className="font-weight-bold">
          ¿Puedo contratar todo tipo de seguros con NICO?
          <Accordion.Collapse eventKey="2">
            <Card.Body className="font-weight-500 ">
              Sí, puedes contratar seguros de todo tipo, de todos los ramos, de las áreas de seguros Generales, Vida,
              Salud, Garantía y Crédito. Más abajo, puedes ver todas las compañías de seguros con las que trabajamos.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3" className="font-weight-bold">
          Si contrato un seguro con NICO, ¿a quién le pago la prima (costo del seguro)?
          <Accordion.Collapse eventKey="3">
            <Card.Body className="font-weight-500 ">
              Las primas se pagan directamente a la compañía de seguros (a menos que haya un convenio de recaudación).
              Así lo establece la regulación dictada por la CMF. Sin embargo, NICO se encarga de asegurarse que no
              tengas deudas y te recuerda si te atrasas en el pago para que no pierdas tu cobertura.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4" className="font-weight-bold">
          ¿Qué debo hacer si tengo un siniestro (choque, incendio, robo, etc.)?
          <Accordion.Collapse eventKey="4">
            <Card.Body className="font-weight-500 ">
              Lo primero que debes hacer siempre es dar aviso a NICO. Nosotros te daremos la asesoría para reportar a la
              compañía de seguros y acompañaremos en todo el proceso.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="5" className="font-weight-bold">
          ¿Qué pasa si algún día quiero cambiar de corredor?
          <Accordion.Collapse eventKey="5">
            <Card.Body className="font-weight-500 ">
              Como cliente asegurado, siempre puedes cambiar de corredor. Solo debes informarlo a tu nuevo corredor y la
              compañía de seguros. Trabajaremos para que eso nunca pase. Y si pasa, esperamos seguir siendo amigos :)
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
    </Accordion>
  );
};

export default ClientLandingAccordionFaqsPersons;
