import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { emptyFalseParamsRecursive, combineDateTime } from '../../services/utils';
import { updateTaskRequest, deleteTaskRequest } from '../../requests/tasks';
import { conditionalRowStyles, customStyles } from '../Shared/DataTable';
import ComponentDataTable from '../Utils/DataTable';
import { SimpleCenteredModal } from '../Utils/Modal';
import TaskFilters from './TaskFilters';
import { taskFiltersParams } from './taskFiltersParams';
import TaskForm from './TaskForm';

const TaskDataTable = ({
  columns,
  customParamsRef,
  fromAdmin = false,
  handleIndexRequest,
  isFetching,
  moreData,
  setMoreData,
  showAllTasks = false,
  tableData,
  tableDataAmount
}) => {
  const [customParams, setCustomParams] = useState({
    ...taskFiltersParams,
    sort_column: 'due_date',
    sort_direction: 'asc',
    finished: showAllTasks
  });
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [modalSize, setModalSize] = useState('md');
  const dispatch = useDispatch();

  const fromModule = fromAdmin ? 'admin' : 'broker';

  const handleSortCase = name => {
    switch (name) {
      default:
        return { sort_column: name };
    }
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleSuccessEditTaskRequest = () => {
    handleCloseModal();
    dispatch(sendAlert({ kind: 'success', message: 'Tarea editada con éxito' }));
    setMoreData(m => !m);
  };

  const handleEditTaskRequest = values => {
    const { id: taskId, dueDateDate, dueDateTime, description } = values.task;
    const formattedDueDate = combineDateTime(dueDateDate, dueDateTime);
    const sendParams = snakeCaseKeys({
      task: {
        description,
        dueDate: formattedDueDate
      }
    });
    updateTaskRequest(taskId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessEditTaskRequest
    });
  };

  const handleSuccessDeleteTaskRequest = () => {
    handleCloseModal();
    dispatch(sendAlert({ kind: 'success', message: 'Tarea eliminada con éxito' }));
    setMoreData(m => !m);
  };

  const handleDeleteTaskRequest = task => {
    // eslint-disable-next-line no-alert
    const confirmDelete = window.confirm('¿Seguro deseas eliminar ésta tarea?');
    if (confirmDelete) {
      deleteTaskRequest(task.id, {
        dispatch,
        params: snakeCaseKeys({ fromModule }),
        formData: true,
        successCallback: handleSuccessDeleteTaskRequest
      });
    }
  };

  const handleOnRowClicked = task => {
    setModalShow(true);
    setModalSize('md');
    setModalBody(
      <TaskForm
        fromAdmin={fromAdmin}
        action="edit"
        task={task}
        formRequest={handleEditTaskRequest}
        handleDeleteTaskRequest={() => handleDeleteTaskRequest(task)}
        setMoreData={setMoreData}
        fromDataTable
      />
    );
  };

  const handleSetUpFilters = () => {
    const newParams = snakeCaseKeys({
      ...emptyFalseParamsRecursive(customParams),
      finished: customParams.finished,
      fromModule
    });
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(!moreData);
  };

  useEffect(handleSetUpFilters, [customParams]);

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({ setMoreData })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        highlightOnHover
        conditionalRowStyles={conditionalRowStyles}
        customStyles={customStyles}
        handleSortCase={handleSortCase}
        customDatatableHeader={<TaskFilters customParams={customParams} setCustomParams={setCustomParams} />}
        resourceRequest={handleIndexRequest}
        pointerOnHover
        onRowClicked={handleOnRowClicked}
        noDataComponent={
          <div className="py-5">{!isFetching && <p>No tienes tareas para las fechas seleccionadas</p>}</div>
        }
      />
      <SimpleCenteredModal show={modalShow} onHide={handleCloseModal} body={modalBody} size={modalSize} />
    </>
  );
};

export default TaskDataTable;
