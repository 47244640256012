import React, { useEffect } from 'react';
import { Field, Form, getIn, useFormikContext } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';

import { BasicTextArea, FormikInput } from '../..';
import { endorsementTypeOptions } from './attributeOptions';

const InsuranceEndorsementDocumentForm = ({ modelName, insurancePolicy, onHide }) => {
  const { isSubmitting, touched, errors, handleSubmit, values, setFieldValue } = useFormikContext();
  const { endorsementType } = values.insuranceEndorsement;
  const {
    insuranceCompany: { fullName },
    policyNumber
  } = insurancePolicy;

  const handleInitialValues = () => {
    const endorsementTypeObject = endorsementTypeOptions.find(obj => obj.value === endorsementType);
    const translatedEndorsementType = endorsementTypeObject.label.toLowerCase();

    const title = `Propuesta de endoso de ${translatedEndorsementType} de NICO Corredores de Seguros SpA a ${fullName}`;
    const description = `NICO Corredores de Seguros SpA entrega esta propuesta de endoso de ${translatedEndorsementType} para la póliza n° ${policyNumber} según los datos entregados a continuación.`;

    setFieldValue(`${modelName}[title]`, title);
    setFieldValue(`${modelName}[description]`, description);
  };

  useEffect(handleInitialValues, []);

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name={`${modelName}[title]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Título"
                placeholder="Ingresa un título (máximo 200 caracteres)"
                touched={getIn(touched, field.name)}
                error={getIn(errors, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name={`${modelName}[description]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                label="Descripción"
                placeholder="Ingresa una descripción (máximo 500 caracteres)"
                rows={3}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="mt-2 d-flex justify-content-end">
        <Col md={4} className="mt-4">
          <Button block variant="cancel" className="no-shadow" onClick={onHide}>
            Cancelar
          </Button>
        </Col>
        <Col md={5} className="mt-4">
          <Button
            block
            type="submit"
            variant="submit"
            className="no-shadow"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InsuranceEndorsementDocumentForm;
