import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setFilters } from '../../actions/utils';
import { redirectTo } from '../../services/utils';
import { IconBtn } from '../Utils/Button';

const PendingSummaryModalShow = ({ summary = {}, taskCount = '', selectedTimeHorizon }) => {
  const { sinisterCount, debtCount, nearInvalidity = {}, availableQuotationCount } = summary;

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="pending-summary--modal-show">
      <PendingSummaryModalShowItem
        icon="smile-sad"
        variant="danger"
        title="Siniestros"
        value={`${sinisterCount} pólizas`}
        clickable
        onClick={() => redirectTo(history, '/insurance_policies', { withSinister: true, isValid: '' })}
      />
      <PendingSummaryModalShowItem
        icon="dollar"
        variant="danger"
        title="Deudas"
        value={`${debtCount} pólizas`}
        clickable
        onClick={() => redirectTo(history, '/insurance_policies', { withDebt: true, isValid: '' })}
      />
      <PendingSummaryModalShowItem
        icon="danger"
        variant="warning"
        title="Por vencer"
        value={`${nearInvalidity[selectedTimeHorizon]} pólizas`}
        clickable={selectedTimeHorizon === 'today'}
        onClick={() => redirectTo(history, '/insurance_policies', { nearInvalidity: true })}
      />
      <PendingSummaryModalShowItem
        icon="calculator"
        variant="primary-light"
        title="Cotizaciones disponibles"
        value={availableQuotationCount}
        clickable
        onClick={() => {
          dispatch(
            setFilters({
              step: 'quotation',
              quotationStatus: 'with_quotation',
              stepNumber: 2
            })
          );
          redirectTo(history, '/leads');
        }}
      />
      <PendingSummaryModalShowItem icon="briefcase" variant="primary-light" title="Tareas" value={taskCount} />
    </div>
  );
};

const PendingSummaryModalShowItem = ({ icon, variant, title, value, clickable, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={clickable ? () => onClick() : null}
      onKeyPress={clickable ? () => onClick() : null}
      className={`pending-summary--modal-show__item ${clickable ? 'clickable-container' : ''}`}
    >
      <IconBtn noBtn variant={variant} icon={icon} className="non-clickable mr-3" />
      <p className="font-weight-500">
        <span className="font-weight-bold mr-2">{title}:</span>
        <span>{value}</span>
      </p>
    </div>
  );
};

export default PendingSummaryModalShow;
