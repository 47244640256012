import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { Spinner } from 'react-bootstrap';

import { FormHeader } from '../../../components';
import { IndexFiltersProvider } from '../../../components/Shared';
import { AbilityContext } from '../../../config/abilityContext';
import { useIndexFilters } from '../../../hooks';
import { showAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';
import AccountInfo from '../../Account/AccountInfo';
import basicAccount from '../../Account/basicAccount';

const AccountShow = ({ match }) => {
  const modelUrl = '/admin/accounts';

  const { id } = match.params;

  const { filterStatus } = useIndexFilters({ originPath: modelUrl });

  const [account, setAccount] = useState(basicAccount);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { insuranceBrokerId } = account;

  const handleBreadcrumb = [
    { key: 1, name: 'Cuentas', onClick: () => redirectTo(history, modelUrl, filterStatus) },
    { key: 2, name: 'Detalle de cuenta' }
  ];

  const handleShowAccount = () => {
    setOnRequest(true);
    showAdminAccountRequest(id, {
      dispatch,
      successCallback: response => setAccount(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowAccount, [moreData]);

  return (
    <IndexFiltersProvider value={filterStatus}>
      {onRequest ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={account.name}
          headerBtn
          headerBtnOptions={{
            icon: 'add',
            name: 'Nueva Póliza',
            onClick: () =>
              redirectTo(history, '/admin/insurance_policies/new', {
                policyType: 'contract',
                accountId: id,
                insuranceBrokerId
              })
          }}
          secondBtn={ability.can('manage', 'Lead')}
          secondBtnOptions={{
            icon: 'add',
            name: 'Nueva Oportunidad',
            onClick: () => redirectTo(history, '/admin/leads/new', { accountId: id, insuranceBrokerId })
          }}
          formComponent={
            <AccountInfo userModule="admin" fromAdmin account={account} modelUrl={modelUrl} setMoreData={setMoreData} />
          }
        />
      )}
    </IndexFiltersProvider>
  );
};

export default AccountShow;
