export const basicBlog = {
  id: '',
  title: '',
  subtitle: '',
  content: '',
  featured: false,
  active: true,
  blogType: '',
  publishDate: '',
  blogUrl: ''
};

export const roles = [
  {
    label: 'Corredor',
    value: 'broker'
  },
  {
    label: 'Partner',
    value: 'partner'
  },
  {
    label: 'Asegurado',
    value: 'insured'
  }
];
