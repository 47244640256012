import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPolicyBeneficiaryRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_policies/${insurancePolicyId}/beneficiaries`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createPolicyBeneficiaryRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/insurance_policies/${insurancePolicyId}/beneficiaries`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const buklUploadBeneficiaryRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/insurance_policies/${insurancePolicyId}/beneficiaries/bulk_upload`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updatePolicyBeneficiaryRequest = (
  insurancePolicyId,
  beneficiaryId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/insurance_policies/${insurancePolicyId}/beneficiaries/${beneficiaryId}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deletePolicyBeneficiaryRequest = (
  insurancePolicyId,
  beneficiaryId,
  { dispatch, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('delete', `/insurance_policies/${insurancePolicyId}/beneficiaries/${beneficiaryId}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const exportBeneficiariesRequest = (
  insurancePolicyId,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) => {
  ApiService.request('get', `/insurance_policies/${insurancePolicyId}/beneficiaries/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const debounceIndexPolicyBeneficiaryRequest = AwesomeDebouncePromise(indexPolicyBeneficiaryRequest, 300);
