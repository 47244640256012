import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikInput } from '../../../components';
import { InsuranceCategoryCompanySelect } from '../../../components/InsurancePolicies';
import { textUpperCase } from '../../../services/utils';
import NicoCodeSelect from './NicoCodeSelect';

const PolicyInfoSection = ({ isProposal, modelName, disableNicoCode }) => {
  const { errors, setFieldValue, touched } = useFormikContext();
  return (
    <section className="form-section mb-5 w-100">
      <p className="section-title">Información del Seguro</p>
      <Row>
        <Col lg={3} md={6}>
          <Field name={`${modelName}[policyNumber]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="0000"
                label={isProposal ? 'Número de propuesta' : 'Número de póliza'}
                onChange={input => setFieldValue(field.name, textUpperCase(input.target.value))}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={isProposal}
              />
            )}
          </Field>
        </Col>
        {!disableNicoCode && (
          <Col lg={3} md={6}>
            <Field name={`${modelName}[nicoCode]`}>
              {({ field }) => <NicoCodeSelect field={field} modelName={modelName} />}
            </Field>
          </Col>
        )}
        <InsuranceCategoryCompanySelect companyAbbr modelName={modelName} />
      </Row>
    </section>
  );
};

export default PolicyInfoSection;
