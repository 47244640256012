import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { SimpleCenteredModal } from '../../components';
import ModalBodyIcon from '../../components/Shared/ModalBodyIcon';

const LeadSuccessCloseModal = ({ showModal, handleClose }) => {
  return (
    <SimpleCenteredModal
      show={showModal}
      onHide={handleClose}
      body={
        <ModalBodyIcon
          icon="check"
          iconVariant="success"
          content="¡Felicitaciones!, has hecho un"
          highlightedText="cierre exitoso"
          highlightedVariant="light-success"
          subContent="ahora NICO se encargará de la emisión de la póliza y te avisaremos cuando esté cargada."
          confirmText={false}
          handleModalClose={handleClose}
          formContent={
            <Row className="justify-content-center">
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" onClick={handleClose}>
                  entendido
                </Button>
              </Col>
            </Row>
          }
        />
      }
    />
  );
};

export default LeadSuccessCloseModal;
