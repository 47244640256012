export const renewalSteps = [
  { key: 1, label: 'Renovaciones', value: 'renewal' },
  { key: 2, label: 'Cotización y negociación', value: 'quotation' },
  { key: 3, label: 'Cierre', value: 'closed' }
];

export const firstStep = {
  stepName: 'renewal',
  filtersOptions: [
    { label: 'Renovaciones', value: 'active' },
    { label: 'Prorrogables', value: 'extendable' }
  ],
  advancedOptions: []
};

export const secondStep = {
  stepName: 'quotationStatus',
  filtersOptions: [
    { label: 'Con cotización', value: 'with_quotation' },
    { label: 'Cotización pendiente', value: 'without_quotation' },
    { label: 'Todas', value: '' }
  ],
  advancedOptions: []
};

export const thirdStep = {
  stepName: 'closeStatus',
  filtersOptions: [
    { label: 'exitoso', value: ['success', 'proposal_generated', 'contract_issued'] },
    { label: 'no exitoso', value: 'failure' },
    { label: 'Todas', value: '' }
  ],
  advancedOptions: []
};

export const renewalParams = {
  1: {
    isVip: '',
    legalPersonType: '',
    legal: '',
    natural: '',
    insuranceCategoryId: '',
    subjectMatter: '',
    step: 'renewal',
    renewalQuotation: '',
    closeStatus: '',
    renewal: 'active',
    stepNumber: 1,
    proposalNumber: ''
  },
  2: {
    isVip: '',
    legalPersonType: '',
    legal: '',
    natural: '',
    insuranceCategoryId: '',
    subjectMatter: '',
    step: 'quotation',
    renewalQuotation: '',
    closeStatus: '',
    stepNumber: 2,
    proposalNumber: ''
  },
  3: {
    isVip: '',
    legalPersonType: '',
    legal: '',
    natural: '',
    insuranceCategoryId: '',
    subjectMatter: '',
    step: 'closed',
    renewalQuotation: '',
    closeStatus: ['success', 'proposal_generated', 'contract_issued'],
    stepNumber: 3,
    proposalNumber: ''
  }
};
