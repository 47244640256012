import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikDatePicker, FormikInput, FormikNumberFormat, UploadFile } from '../../../components';

const CompanyLandingThirdSection = ({ company, modelName, sectionInfoComponent: SectionInfo }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { landingPrimaryColor } = company;

  return (
    <div className="mb-5">
      <SectionInfo
        title="Información del seguro actual del vehículo"
        description="Para lograr una mejor cotización, ayúdanos con esta información extra opcional."
      />
      <Row>
        <Col sm={6}>
          <Field name={`${modelName}[validityEnd]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                label="Fecha de término de vigencia"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText={`Es la fecha en la que termina la cobertura<br/>
                             del actual seguro del vehículo a asegurar.<br/>
                             Si es que tiene ${String.fromCodePoint('0x1F62C')}`}
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[parsedInsurancePolicyDeductible]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                suffix=" UF"
                label="Deducible"
                fieldName={`${modelName}[insurancePolicyDeductible]`}
                defaultValue={getIn(values, `${modelName}[insurancePolicyDeductible]`)}
                error={getIn(errors, `${modelName}[insurancePolicyDeductible]`)}
                touched={getIn(touched, `${modelName}[insurancePolicyDeductible]`)}
                tooltipText="El deducible es lo que pagas para activar el<br/>
                             seguro cuando tienes un siniestro (accidente,<br/>
                             robo, etc.). Los montos más comunes son 0 UF,<br/>
                             3 UF, 5 UF y 10 UF, pero también hay más altos!"
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[insurancePolicyAmount]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Precio"
                placeholder="$55.000 / 2 UF / mensuales / al contado"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText="Normalmente, los seguros de vehículos se pagan<br/>
                             en cuotas mensuales. Si tu seguro actual es así,<br/>
                             ingresa cuánto pagas todos los meses. Si pagaste<br/>
                             al contado, ingresa cuánto pagaste. En cualquier<br/>
                             caso, a continuación puedes especificar cómo pagas<br/>
                             y si el monto lo estás informando en pesos o UFs."
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[contract]`}>
            {({ field }) => (
              <UploadFile
                {...field}
                label="Póliza"
                fileAccept="application/pdf"
                onChange={file => setFieldValue(field.name, file)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                buttonStyle={{ backgroundColor: landingPrimaryColor }}
                tooltipText={`Si tienes el PDF de tu póliza actual,<br/>
                              puedes cargarlo aquí y así vemos cómo<br/>
                              mejorar las condiciones que tienes <br/>
                              actualmente ${String.fromCodePoint('0x1F4AA')}`}
              />
            )}
          </Field>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyLandingThirdSection;
