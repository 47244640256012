import React from 'react';
import { useDispatch } from 'react-redux';

import { sendAlert, getUnreadNotifications } from '../../actions/utils';
import { BtnSwitch } from '../Utils/Button';
import { StatisticCard } from '../Utils/Cards';

const SwitchButton = ({
  id,
  attribute,
  cardVariant,
  icon,
  initialValue,
  readonly,
  setMoreData,
  subtitle,
  title,
  updateRequest
}) => {
  const dispatch = useDispatch();

  const handleUpdateAttributeRequest = ({ value }) => {
    updateRequest(id, {
      dispatch,
      params: { insurance_policy: { [`${attribute}`]: value } },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Póliza actualizada con éxito' }));
        setMoreData(m => !m);
        dispatch(getUnreadNotifications());
      }
    });
  };

  return (
    <StatisticCard
      title={title}
      subtitle={subtitle}
      icon={icon.name}
      variant={icon.variant}
      cardVariant={cardVariant}
      footerButton={
        <BtnSwitch
          id={`${attribute}-switch`}
          variant={icon.variant}
          initialValue={initialValue}
          onClickChecked={() => handleUpdateAttributeRequest({ value: true })}
          onClickUnchecked={() => handleUpdateAttributeRequest({ value: false })}
          readonly={readonly}
        />
      }
    />
  );
};

export default SwitchButton;
