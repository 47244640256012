import React from 'react';
import { Link } from 'react-router-dom';
import IconGG from '../../Utils/Icon';

const UserTypeCard = ({ icon, title, description, href, customUserTypeStyle }) => {
  return (
    <Link className="col-md-4 multilanding--card__nounderline" to={href}>
      <div className={`multilanding--card multilanding--card__${customUserTypeStyle}`}>
        <div className="multilanding--card__icon-container">
          <div className="multilanding--card__bgicon">
            <IconGG className="multilanding--card__icon" icon={icon} />
          </div>
        </div>
        <p className="multilanding--card__title font-weight-bold">{title}</p>
        <p className="multilanding--card__text font-weight-500">{description}</p>
      </div>
    </Link>
  );
};

export default UserTypeCard;
