import React from 'react';
import { useHistory } from 'react-router-dom';
import { WhyNicoCard } from '../Shared';
import { IconBtn } from '../../Utils/Button';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const ClientLandingWhyNicoPersons = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <section id="why-nico" className="landing--why-nico__container">
      <div className="text-center">
        <h4 className="landing--why-nico-client__titlecompanies-persons">
          Revisa aquí los tipos de protección más comunes que necesitan las empresas. Podemos apoyarte en todos.
        </h4>
      </div>
      <div className="landing--why-nico-client landing--why-nico-client__content landing--why-nico-client landing--why-nico-client__margin">
        <WhyNicoCard
          icon="fire"
          title="incendio"
          text="Activos como casas o deptos, incluyendo los contenidos de los mismos. Principalmente contra incendio, explosión y riesgos de la naturaleza."
        />
        <WhyNicoCard
          icon="detective"
          title="robo/asalto"
          text="Indemnización por daño o pérdida de los bienes asegurados, derivados del robo o asalto por parte de terceros."
        />
        <WhyNicoCard
          icon="car"
          title="vehículos motorizados"
          text="Daños del vehículo a causa de incendio, volcamiento y colisión, entre otros, responsabilidad civil, y robo, hurto o uso no autorizado del vehículo."
        />
        <WhyNicoCard
          icon="person"
          title="accidentes personales"
          text="Protege a la familia del asegurado en caso de fallecimiento o invalidez a causa de un accidente."
        />
        <WhyNicoCard
          icon="world"
          title="viajes"
          text="Urgencias médicas, demoras de vuelos, equipaje perdido o robo. Mejor viajar protegido."
        />
        <WhyNicoCard
          icon="paw"
          title="mascotas"
          text="Protege la salud de tu mascota y responsabilidad civil por eventuales daños a terceros."
        />
      </div>

      <div className="d-flex justify-content-center">
        <IconBtn
          onClick={() => navigateToContact()}
          className="main-button btn-landing w-mobile-100"
          variant="secondary"
        >
          Quiero asesoría GRATIS
        </IconBtn>
      </div>
    </section>
  );
};

export default ClientLandingWhyNicoPersons;
