import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminIndustryRequest } from '../../../requests/admin/industries';
import basicIndustry from './basicIndustry';
import AdminIndustryForm from './AdminIndustryForm';

const AdminIndustryNew = () => {
  const modelUrl = '/admin/industries';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Rubros', href: modelUrl },
    { key: 2, name: 'Crear nuevo rubro' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Rubro creado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createAdminIndustryRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminIndustryForm action="new" industry={basicIndustry} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminIndustryNew;
