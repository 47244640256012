export const monthTranslation = {
  Ene: 'Enero',
  Feb: 'Febrero',
  Mar: 'Marzo',
  Abr: 'Abril',
  May: 'Mayo',
  Jun: 'Junio',
  Jul: 'Julio',
  Ago: 'Agosto',
  Sep: 'Septiembre',
  Oct: 'Octubre',
  Nov: 'Noviembre',
  Dic: 'Diciembre'
};

export const legendTranslation = {
  renewable: 'Renovable',
  sold: 'Venta Cerrada',
  total: 'Total Proyectado'
};

export const legendColor = {
  renewable: 'normal',
  sold: 'grey',
  total: 'dark-blue'
};
