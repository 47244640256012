import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn, Field, useFormikContext } from 'formik';

import { percentageLimit } from '../../services/utils';
import { InsuranceCompanyRemoteSelector } from '../InsurancePolicies';
import { InfoBox } from '../Shared';
import { FormikInput, FormikNumberFormat } from '../Utils/Input';
import { UploadFile } from '../Utils/Upload';

const LeadQuotationFields = ({ insuranceCategory, modelName }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  return (
    <Row>
      <Col xs={12}>
        <InfoBox
          variant="primary"
          text="Las compañías aseguradoras están filtradas por la categoría del ramo seleccionado por el corredor."
        />
      </Col>

      <Col xs={12}>
        <Field name={`${modelName}[insuranceCompanyId]`}>
          {({ field }) => (
            <InsuranceCompanyRemoteSelector
              field={field}
              modelName={modelName}
              categorySelected={insuranceCategory.category}
            />
          )}
        </Field>
      </Col>
      <Col xs={6}>
        <Field name={`${modelName}[parsedAffectCommissionPercentage]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              label="% Comisión póliza afecta"
              suffix=" %"
              isAllowed={percentageLimit}
              fieldName={`${modelName}[affectCommissionPercentage]`}
              error={getIn(errors, `${modelName}[affectCommissionPercentage]`)}
              touched={getIn(touched, `${modelName}[affectCommissionPercentage]`)}
            />
          )}
        </Field>
      </Col>
      <Col xs={6}>
        <Field name={`${modelName}[parsedExemptCommissionPercentage]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              label="% Comisión póliza exenta"
              suffix=" %"
              isAllowed={percentageLimit}
              fieldName={`${modelName}[exemptCommissionPercentage]`}
              error={getIn(errors, `${modelName}[exemptCommissionPercentage]`)}
              touched={getIn(touched, `${modelName}[exemptCommissionPercentage]`)}
            />
          )}
        </Field>
      </Col>
      <Col xs={12}>
        <Field name={`${modelName}[name]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              label="Nombre de la cotización"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col xs={12}>
        <Field name={`${modelName}[quotation]`}>
          {({ field }) => (
            <UploadFile
              {...field}
              abbr
              fileAccept="application/pdf"
              label="Cotización"
              onChange={file => setFieldValue(field.name, file)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default LeadQuotationFields;
