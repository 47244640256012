import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCommuneRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/communes', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showCommuneRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/communes/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexCommunesRequest = AwesomeDebouncePromise(indexCommuneRequest, 300);
