import React from 'react';

const InsurancePolicyCommission = ({
  basePercentage,
  brokerAmount,
  brokerPercentage,
  companyAmount,
  companyPercentage,
  nicoAmount,
  nicoPercentage,
  totalAmount
}) => (
  <div className="commission-structure">
    <p>Desglose de comisiones:</p>
    <dl>
      <dt>Comisión póliza neta = {totalAmount}</dt>
      <dd>
        Comisión NICO = {nicoPercentage} = {nicoAmount}
      </dd>
      <dd>
        Comisión empresa = {companyPercentage} (del {basePercentage}) = {companyAmount}
      </dd>
      <dd>
        Comisión corredor = {brokerPercentage} (del {basePercentage}) = {brokerAmount}
      </dd>
    </dl>
  </div>
);

InsurancePolicyCommission.defaultProps = {
  basePercentage: '0%',
  brokerAmount: '0',
  brokerPercentage: '0%',
  companyAmount: '0',
  companyPercentage: '0%',
  nicoAmount: '0',
  nicoPercentage: '0%',
  totalAmount: '0'
};

export default InsurancePolicyCommission;
