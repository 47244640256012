import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import ContactForm from './ContactForm';
import { SimpleCenteredModal } from '../../components';
import { createAccountContactRequest } from '../../requests/Account/contact';
import { createAdminAccountContactRequest } from '../../requests/admin/adminContacts';
import basicContact from './basicContact';
import { sendAlert } from '../../actions/utils';

const ContactNewModal = ({ accountId, handleModalClose, modalShow, setMoreData, fromAdmin }) => {
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Contacto creado con éxito' }));
    setMoreData(m => !m);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    if (fromAdmin) {
      createAdminAccountContactRequest(accountId, {
        dispatch,
        params: sendParams,
        formData: true,
        successCallback: handleSuccessCreate,
        failureCallback: handleFailureCreate
      });
    } else {
      createAccountContactRequest(accountId, {
        dispatch,
        params: sendParams,
        formData: true,
        successCallback: handleSuccessCreate,
        failureCallback: handleFailureCreate
      });
    }
  };

  return (
    <SimpleCenteredModal
      title="Crear Contacto"
      body={
        <ContactForm
          action="new"
          contact={basicContact}
          show={modalShow}
          formRequest={handleCreateRequest}
          handleModalClose={handleModalClose}
        />
      }
      show={modalShow}
      onHide={handleModalClose}
      size="lg"
    />
  );
};

export default ContactNewModal;
