import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { ButtonTooltip } from '../Tooltips';
import colorStyles from './colorStyles';

const handleDefaultMultiValue = (value, defaultValue, defaultMultiValues) => {
  if (defaultValue && value && value === defaultValue) return defaultMultiValues;
  return undefined;
};

const findDefaultValue = (value, defaultValue, options, props) => {
  const { isMulti, defaultMultiValues } = props;
  const isDefaultValue = defaultValue && value && defaultValue !== '' && value !== '' && value === defaultValue;
  if (isMulti) return handleDefaultMultiValue(value, defaultValue, defaultMultiValues);
  if (isDefaultValue) return options.filter(e => defaultValue === e.value)[0];
  if (value === '') return null;
  return undefined;
};

const FormikSelect = ({
  abbr,
  defaultValue,
  error,
  helpText,
  isDisabled,
  label,
  name,
  optional,
  options,
  placeholder,
  setFieldTouched,
  tooltipHtml,
  tooltipText,
  touched,
  value,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const customStyles = {
    ...colorStyles,
    indicatorsContainer: base => ({
      ...base,
      ...(tooltipText ? { paddingRight: '2rem' } : {})
    })
  };

  return (
    <Form.Group
      controlId={label}
      className={`form-select ${isDisabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''} ${
        tooltipText ? 'with-tooltip' : ''
      }`}
    >
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
          {optional && <span className="optional-label">(Opcional)</span>}
        </Form.Label>
      )}
      <Select
        className={`${isOpen ? 'border-on ' : ''} w-100`}
        placeholder={placeholder || 'Buscar...'}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => {
          if (setFieldTouched) setFieldTouched();
          setIsOpen(false);
        }}
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay opciones'}
        options={options}
        isSearchable
        name={name}
        value={findDefaultValue(value, defaultValue, options, props)}
        isDisabled={isDisabled}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null
        }}
        {...props}
      />
      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default FormikSelect;
