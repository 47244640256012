import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { calculateDays, partPerThousandLimit, setFieldAttribute } from '../../../services/utils';
import { FormikInput, FormikInputRadio, FormikNumberFormat } from '../../Utils/Input';
import { coverageTypeOptions } from './basicInsuranceCover';

const InsuranceCoverForm = ({ modelName, fromEndorsement, insurancePolicy }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { amount, fee = 0, netPremium } = getIn(values, modelName);
  const { currency } = insurancePolicy || values.insurancePolicy || values.lead;
  const endorsementDate = fromEndorsement && values.insuranceEndorsement.endorsementDate;

  const calculateNetPremium = () => {
    if (amount) {
      if (fee && Number(fee) === 0) return;
      const defaultNetPremium = Number.isNaN(parseFloat(netPremium)) ? '' : parseFloat(netPremium);
      let netPremiumUpdated = defaultNetPremium;
      if (amount && fee) netPremiumUpdated = amount * (fee / 1000);
      if (amount && fee && endorsementDate) {
        const { validityStart, validityEnd } = insurancePolicy;
        const originalDuration = calculateDays(validityStart, validityEnd);
        const newTermDuration = calculateDays(endorsementDate, validityEnd);
        netPremiumUpdated = amount * (fee / 1000) * (newTermDuration / originalDuration);
      }
      setFieldAttribute(modelName, 'netPremium', setFieldValue, netPremiumUpdated, ` ${currency}`);
    }
  };

  useEffect(calculateNetPremium, [amount, fee]);

  return (
    <>
      <Row>
        <Col sm={6} md={6} xl={6}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                maxLength={160}
                label="Detalle cobertura"
                placeholder="Máx. 160 caracteres"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs sm={6} md={4} xl={3}>
          <Field name={`${modelName}[parsedDeductible]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                field={field}
                label="Deducible"
                placeholder="UF/USD"
                suffix={` ${currency}`}
                defaultValue={getIn(values, `${modelName}[deductible]`)}
                fieldName={`${modelName}[deductible]`}
                error={getIn(errors, `${modelName}[deductible]`)}
                touched={getIn(touched, `${modelName}[deductible]`)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row>
        <Col sm={6} md={4} xl={3}>
          <Field name={`${modelName}[parsedAmount]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="Monto asegurado"
                suffix={` ${currency}`}
                defaultValue={getIn(values, `${modelName}[amount]`)}
                fieldName={`${modelName}[amount]`}
                error={getIn(errors, `${modelName}[amount]`)}
                touched={getIn(touched, `${modelName}[amount]`)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} xl={3}>
          <Field name={`${modelName}[parsedFee]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                optional
                label="Tasa"
                placeholder="partes por mil"
                decimalScale={3}
                isAllowed={partPerThousandLimit}
                defaultValue={getIn(values, `${modelName}[fee]`)}
                fieldName={`${modelName}[fee]`}
                error={getIn(errors, `${modelName}[fee]`)}
                touched={getIn(touched, `${modelName}[fee]`)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6} md={4} xl={3}>
          <Field name={`${modelName}[parsedNetPremium]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="Prima neta"
                suffix={` ${currency}`}
                defaultValue={getIn(values, `${modelName}[netPremium]`)}
                fieldName={`${modelName}[netPremium]`}
                error={getIn(errors, `${modelName}[netPremium]`)}
                touched={getIn(touched, `${modelName}[netPremium]`)}
              />
            )}
          </Field>
        </Col>
        <Col xs sm={6} md={4} xl={3}>
          <Field name={`${modelName}[coverageType]`}>
            {({ field }) => (
              <FormikInputRadio
                {...field}
                abbr
                field={field}
                label="Tipo"
                options={coverageTypeOptions}
                fieldName={`${modelName}[coverageTypeLabel]`}
                error={getIn(errors, `${modelName}[coverageType]`)}
                touched={getIn(touched, `${modelName}[coverageType]`)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default InsuranceCoverForm;
