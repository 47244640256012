const basicSlide = {
  id: '',
  active: true,
  image: {},
  position: '',
  caption: '',
  landingType: ''
};

export default basicSlide;
