// nico_frontend/src/app/hooks/useGetRoleByRoute.js

import { useLocation } from 'react-router-dom';

const useGetRoleByRoute = () => {
  const location = useLocation();

  const roleMappings = {
    broker: 'corredor',
    partner: 'partner',
    insured: 'asegurado',
    company: 'l'
  };

  const reverseRoleMappings = Object.fromEntries(Object.entries(roleMappings).map(([key, value]) => [value, key]));

  const getTypeFromUrl = () => {
    const types = Object.values(roleMappings);
    const foundType = types.find(type => location.pathname.includes(type));
    return reverseRoleMappings[foundType] || 'broker';
  };

  const type = getTypeFromUrl();
  const translateType = roleMappings[type] || '';

  return { type, translateType };
};

export default useGetRoleByRoute;
