import React from 'react';

const ExclusionsTextDescription = () => {
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  return (
    <>
      <p>Para cargar las exclusiones:</p>
      <ul>
        <li className="ml-4">
          Ingresa un titular (o sus cargas) por línea con los siguientes datos: RUT titular; Fecha de exclusión; Fecha
          de envío a compañía - exclusión; Número de cargas; Comentarios
        </li>
        <li className="ml-4">
          Si estás excluyendo un titular, debes dejar el número de cargas en 0 y se excluirá al titular con todas sus
          cargas.
        </li>
        <li className="ml-4">
          Si estás excluyendo una o más cargas de un titular, especifica el número de cargas a excluir y se eliminarán
          solo esas cargas y no el titular.
        </li>
        <li className="ml-4">
          Copia los datos desde un documento <span className={commonClasses}>XLSX</span> o separa cada dato de la póliza
          utlizando <span className={commonClasses}>;</span>
        </li>
      </ul>
    </>
  );
};

export default ExclusionsTextDescription;
