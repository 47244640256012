import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';

const BrokerLandingAccordionFaqs = () => {
  return (
    <Accordion defaultActiveKey="0" className="container__accordion">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" className="font-weight-bold">
          ¿NICO es un corredor de seguros?
          <Accordion.Collapse eventKey="0">
            <Card.Body className="font-weight-500 ">
              Sí, NICO está certificado como corredor de seguros (código 9065), y es regulado por la Comisión para el
              Mercado Financiero (CMF).
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1" className="font-weight-bold">
          Al asociarme a NICO, ¿mis pólizas pasan a usar el código de corredor de NICO?
          <Accordion.Collapse eventKey="1">
            <Card.Body className="font-weight-500 ">
              Sí, traspasamos tus pólizas a nuestro código para poder hacer todo lo que hacemos y operar tu cartera. De
              todas formas, tu cartera es y seguirá siendo tuya. Así lo establecemos en el contrato de asociación y en
              la práctica, tus clientes siempre te siguen a ti por la confianza que han construído.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2" className="font-weight-bold">
          ¿Qué pasa si algún día decido no seguir trabajando con NICO?
          <Accordion.Collapse eventKey="2">
            <Card.Body className="font-weight-500 ">
              No hay problema, las pólizas vuelven a tu código de corredor y esperamos seguir siendo amigos.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3" className="font-weight-bold">
          ¿NICO cobra las comisiones a las compañías de seguros?
          <Accordion.Collapse eventKey="3">
            <Card.Body className="font-weight-500 ">
              Sí, NICO hace todas las cuadraturas, facturación y recepción de pagos. Después te entregamos un reporte
              detallado para que nos boletees/factures y así te pagamos tu comisión.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4" className="font-weight-bold">
          ¿Cuánto se demoran en pagarme la comisión?
          <Accordion.Collapse eventKey="4">
            <Card.Body className="font-weight-500 ">
              Todos los meses, a más tardar el día 15. Esto considera todas las comisiones generadas por tus ventas y
              pagadas por las compañías de seguros el mes inmediatamente anterior.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="5" className="font-weight-bold">
          Para empezar a trabajar con NICO, ¿necesito instalar o pagar algo?
          <Accordion.Collapse eventKey="5">
            <Card.Body className="font-weight-500 ">
              No, puedes empezar a operar el mismo día que te asocias a NICO. Nuestro software es en línea, sin
              instalación y siempre disponible.
            </Card.Body>
          </Accordion.Collapse>
        </Accordion.Toggle>
      </Card>
    </Accordion>
  );
};

export default BrokerLandingAccordionFaqs;
