import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';

const userObject = ({ user }) => {
  const {
    birthday,
    brokerCode,
    email,
    fullAddress,
    nationalIdentification,
    parsedBrokerCommission,
    phone,
    translatedBrokerType
  } = user.insuranceBrokerAttributes;

  const object = {};

  object.brokerType = infoCardElement({
    string: true,
    key: 'Tipo',
    value: translatedBrokerType
  });
  if (brokerCode)
    object.brokerCode = infoCardElement({
      string: true,
      key: 'Código del corredor',
      value: brokerCode
    });
  object.brokerCommission = infoCardElement({
    string: true,
    key: '% Sub-comisión',
    value: parsedBrokerCommission
  });
  object.nationalIdentification = infoCardElement({
    string: true,
    key: 'RUT',
    value: nationalIdentification
  });
  if (birthday)
    object.birthday = infoCardElement({
      string: true,
      key: 'Fecha de nacimiento',
      value: birthday
    });
  if (phone)
    object.phone = infoCardElement({
      string: true,
      key: 'Teléfono',
      value: phone
    });
  object.email = infoCardElement({
    string: true,
    key: 'Correo electrónico',
    value: email
  });
  if (fullAddress)
    object.address = infoCardElement({
      string: true,
      key: 'Dirección',
      value: fullAddress
    });

  return object;
};

export default userObject;
