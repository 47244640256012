import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useFetchData } from '../../hooks';
import { debounceIndexBlogRequest, indexBolgRequest } from '../../requests/blogs';
import BrokerLandingDemoSecond from '../Landing/BrokerLanding/BrokerLandingDemoSecond';
import WhatsAppButton from '../Utils/WhatsAppBtn/WhatsAppButton';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';
import BlogCard from './BlogCard';
import CustomPagination from './CustomPagination';
import loader from '../../assets/images/Landing/loader.gif';
import SearchDateFilter from './SearchDateFilter';

function Blog() {
  const { type } = useGetRoleByRoute();
  const dispatch = useDispatch();
  const allBlogsRef = useRef(null);

  const [featuredBlogs, setFeaturedBlogs] = useState([]);
  const [customParams, setCustomParams] = useState({
    query: '',
    date_from: '',
    date_to: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 6;

  // Featured Blogs request
  const getFeaturedBlogs = () => {
    return indexBolgRequest({
      dispatch,
      params: {
        featured: true,
        display_length: 2,
        with_blog_type: type
      },
      successCallback: response => {
        setFeaturedBlogs(camelCaseRecursive(response.data.data));
      }
    });
  };

  // Blogs request
  const { data: blogs, totalData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexBlogRequest,
    fetchingErrorMessage: 'Error al buscar. Por favor inténtelo nuevamente.',
    customParams: {
      ...customParams,
      display_start: currentPage * rowsPerPage - rowsPerPage,
      display_length: rowsPerPage,
      with_blog_type: type
    }
  });

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    if (allBlogsRef.current) {
      window.scrollTo({
        top: allBlogsRef.current.offsetTop - 100,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    getFeaturedBlogs();
  }, []);

  useEffect(() => {
    handleIndexRequest();
  }, [currentPage, customParams]);

  return (
    <div className="blog-section">
      <div className={`blog-header blog-header-${type}`}>
        <h1>Blog</h1>
      </div>
      {featuredBlogs.length !== 0 && (
        <div className="fetured-blogs">
          <h2>Artículos destacados</h2>
          <div className="featured-blogs-grid">
            {featuredBlogs.map(blog => (
              <BlogCard blog={blog} />
            ))}
          </div>
        </div>
      )}

      <div className="all-blogs">
        {blogs.length !== 0 && (
          <div className="all-blogs-title-and-search">
            <h4 ref={allBlogsRef}>Todos los artículos</h4>
            <SearchDateFilter containerClass="ml-lg-5" customParams={customParams} setCustomParams={setCustomParams} />
          </div>
        )}
        {isFetching && (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '600px' }}>
            <img style={{ width: '60px' }} src={loader} alt="Loader" />
          </div>
        )}
        {!isFetching && blogs.length !== 0 && (
          <>
            <div className="all-blogs-grid mb-4 pb-4">
              {blogs.map(blog => (
                <BlogCard blog={blog} />
              ))}
            </div>
            <CustomPagination
              rowCount={totalData}
              rowsPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              currentPage={currentPage}
            />
          </>
        )}
        {blogs.length === 0 && <h6 className="text-center">No existen blogs actualmente</h6>}
        <hr />
      </div>

      <BrokerLandingDemoSecond />
      <WhatsAppButton />
    </div>
  );
}

export default Blog;
