import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import { lowerCaseTextFormat } from '../../../services/utils';
import { DataTableTextArea } from '../Input';
import { SimpleCenteredModal } from '../Modal';
import { BtnTooltip } from '../Button';

const ReadMore = ({ children, isEditable, item, less, lines, more, title, iconBtn, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const handleTruncate = status => {
    if (truncated !== status) {
      setTruncated(status);
    }
  };

  const toggleLines = event => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="text-center">
        <Truncate
          lines={lines}
          onTruncate={handleTruncate}
          ellipsis={
            <span>
              ...{' '}
              {!isEditable && (
                <a href="/#" className="text-primary" onClick={toggleLines}>
                  {more}
                </a>
              )}
            </span>
          }
          {...props}
        >
          {children}
        </Truncate>
        {!iconBtn && isEditable && (
          <Button block variant="transparent" className="btn-sm px-0" onClick={() => setExpanded(true)}>
            Editar {lowerCaseTextFormat(title)}
          </Button>
        )}
        {iconBtn && isEditable && (
          <BtnTooltip
            icon="pen"
            iconSize="sm"
            variant="transparent"
            className="edit-btn"
            tooltipText={`Editar ${lowerCaseTextFormat(title)}`}
            onClick={() => setExpanded(true)}
          />
        )}
      </div>
      <SimpleCenteredModal
        show={expanded}
        title={title}
        body={
          isEditable ? (
            <DataTableTextArea
              id={item.id}
              attribute={item.attribute}
              defaultValue={item.defaultValue}
              updateRequest={item.handleUpdateRequest}
              maxLength={1000}
              updateDataTable
              verticalResize
              rows={9}
            />
          ) : (
            <div className="small">{children}</div>
          )
        }
        onHide={() => setExpanded(false)}
      />
    </>
  );
};

ReadMore.defaultProps = {
  lines: 2,
  more: 'Leer más',
  less: 'Leer menos'
};

ReadMore.propTypes = {
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string
};

export default ReadMore;
