import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { ComponentDataTable } from '..';
import { IndexFiltersContext } from '../Shared';
import { conditionalRowStyles, customStyles } from '../Shared/DataTable';
import { emptyFalseParamsRecursive, excludeKeys, redirectTo } from '../../services/utils';

const AccountDataTable = ({
  columns,
  handleIndexRequest,
  isFetching,
  modelUrl,
  moreData,
  setMoreData,
  tableData,
  tableDataAmount,
  accountFiltersParams,
  FilterHeaderComponent
}) => {
  const { customParamsRef, filterStatus, setFilterStatus } = useContext(IndexFiltersContext);
  const [customParams, setCustomParams] = useState(accountFiltersParams);
  const history = useHistory();

  const handleFilterRequest = () => {
    const { legal, natural } = customParams;

    let legalPersonType = '';
    if (legal && !natural) legalPersonType = 'legal';
    else if (!legal && natural) legalPersonType = 'natural';

    const newParams = { ...emptyFalseParamsRecursive(customParams), legalPersonType };
    setFilterStatus({ ...filterStatus, isAnUpdate: true, allFilters: { ...filterStatus.allFilters, ...newParams } });
    customParamsRef.current = { ...snakeCaseKeys(newParams) };
    setMoreData(!moreData);
  };

  const handleRowClick = ({ id }) => {
    const accountShowPath = `${modelUrl}/${id}`;
    redirectTo(history, accountShowPath, excludeKeys(filterStatus, ['isAnUpdate']));
  };

  const handleSortCase = name => {
    switch (name) {
      case 'created_at':
        return { sort_column: 'created_at' };
      case 'name':
        return { sort_column: name };
      case 'valid_policies_count':
        return { sort_valid_policies: name };
      case 'total_broker_commission_amount':
        return { sort_total_commission: name };
      case 'all_policies_total_fee':
        return { sort_total_prime: name };
      case 'insurance_broker':
        return { sort_insurance_broker: name };
      case 'valid_total_net_premium':
        return { sort_valid_total_net_premium: name };
      default:
        return { sort_priority: name };
    }
  };

  const handleOnChangeFilterStatus = () => {
    if (filterStatus.isAnUpdate) return;
    setCustomParams({ ...customParams, ...filterStatus.allFilters });
  };

  useEffect(handleOnChangeFilterStatus, [filterStatus]);
  useEffect(handleFilterRequest, [customParams]);

  return (
    <ComponentDataTable
      onRequest={isFetching}
      columns={columns({ setMoreData })}
      data={tableData}
      totalRows={tableDataAmount}
      moreData={moreData}
      customStyles={customStyles}
      handleSortCase={handleSortCase}
      onRowClicked={(row, e) => handleRowClick(row, e)}
      highlightOnHover
      pointerOnHover
      conditionalRowStyles={conditionalRowStyles}
      customDatatableHeader={<FilterHeaderComponent customParams={customParams} setCustomParams={setCustomParams} />}
      resourceRequest={handleIndexRequest}
      defaultParams={{ sortColumn: 'priority', ...filterStatus.allFilters }}
    />
  );
};

export default AccountDataTable;
