import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { createTaskRequest, updateTaskRequest, deleteTaskRequest } from '../../requests/tasks';
import { combineDateTime } from '../../services/utils';
import { IconBtn } from '../Utils/Button';
import { SimpleCenteredModal } from '../Utils/Modal';
import TaskForm from './TaskForm';
import basicTask from './basicTask';

const DatatableTaskCreator = ({ fromAdmin = false, shortBtn, task, taskableType, taskableId, setMoreData }) => {
  const dispatch = useDispatch();
  const [modalBody, setModalBody] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalSize, setModalSize] = useState('md');
  const [modalTitle, setModalTitle] = useState('');

  const fromModule = fromAdmin ? 'admin' : 'broker';

  const btnText = task?.id ? (
    <div className="d-flex flex-column">
      <p className="mb-1">Mostrar</p>
      <p className="detail">{task?.dueDateDate}</p>
    </div>
  ) : (
    'Crear'
  );

  const handleBtnText = shortBtn ? '' : btnText;

  const handleCloseModal = () => {
    setModalShow(false);
  };

  // CREATE TASK REQUEST
  const handleSuccessCreateTaskRequest = () => {
    handleCloseModal();
    dispatch(sendAlert({ kind: 'success', message: 'Tarea creada con éxito' }));
    setMoreData(m => !m);
  };

  const handleCreateTaskRequest = values => {
    const { dueDateDate, dueDateTime } = values.task;
    const formattedDueDate = combineDateTime(dueDateDate, dueDateTime);
    const sendParams = snakeCaseKeys({
      fromModule,
      task: { ...values.task, dueDate: formattedDueDate, taskableType, taskableId }
    });
    createTaskRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreateTaskRequest
    });
  };

  const handleShowCreateTaskModal = () => {
    setModalShow(true);
    setModalTitle('Crear tarea');
    setModalSize('sm');
    setModalBody(
      <TaskForm fromAdmin={fromAdmin} action="new" task={basicTask} formRequest={handleCreateTaskRequest} />
    );
  };

  // DELETE TASK REQUEST
  const handleSuccessDeleteTaskRequest = () => {
    handleCloseModal();
    dispatch(sendAlert({ kind: 'success', message: 'Tarea eliminada con éxito' }));
    setMoreData(m => !m);
  };

  const handleDeleteTaskRequest = () => {
    // eslint-disable-next-line no-alert
    const confirmDelete = window.confirm('¿Seguro deseas eliminar ésta tarea?');
    if (confirmDelete) {
      deleteTaskRequest(task.id, {
        dispatch,
        params: snakeCaseKeys({ fromModule }),
        formData: true,
        successCallback: handleSuccessDeleteTaskRequest
      });
    }
  };

  // EDIT TASK REQUEST
  const handleSuccessEditTaskRequest = () => {
    handleCloseModal();
    dispatch(sendAlert({ kind: 'success', message: 'Tarea editada con éxito' }));
    setMoreData(m => !m);
  };

  const handleEditTaskRequest = values => {
    const { id: taskId, dueDateDate, dueDateTime } = values.task;
    const formattedDueDate = combineDateTime(dueDateDate, dueDateTime);
    const sendParams = snakeCaseKeys({ fromModule, task: { ...values.task, dueDate: formattedDueDate } });
    updateTaskRequest(taskId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessEditTaskRequest
    });
  };

  const handleShowEditTaskModal = () => {
    setModalShow(true);
    setModalSize('sm');
    setModalBody(
      <TaskForm
        fromAdmin={fromAdmin}
        action="edit"
        task={task}
        formRequest={handleEditTaskRequest}
        handleDeleteTaskRequest={handleDeleteTaskRequest}
        setMoreData={setMoreData}
      />
    );
  };

  return (
    <>
      <div className="datatable-task-component--container">
        {task?.id ? (
          <TaskIcon isShow icon="play-list-check" onClick={handleShowEditTaskModal} text={handleBtnText} />
        ) : (
          <TaskIcon icon="play-list-add" onClick={handleShowCreateTaskModal} text={handleBtnText} />
        )}
      </div>
      <SimpleCenteredModal
        title={modalTitle}
        show={modalShow}
        onHide={handleCloseModal}
        body={modalBody}
        size={modalSize}
      />
    </>
  );
};

const TaskIcon = ({ icon, isShow, onClick, text }) => {
  return (
    <IconBtn
      icon={icon}
      onClick={onClick}
      variant="transparent"
      className={`datatable-task-component--button btn black ${isShow ? 'show' : ''}`}
    >
      {text}
    </IconBtn>
  );
};

export default DatatableTaskCreator;
