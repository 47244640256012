import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';
import { guidGenerator } from '../../../services/utils';
import { warningYellow, black } from '../Color';

const ButtonTooltip = ({
  children,
  className: customClassName,
  containerClassName,
  delayHide,
  delayShow,
  direction,
  html,
  noBtn,
  tooltipText,
  variant,
  ...props
}) => {
  const setType = color => {
    if (color === 'danger') {
      return 'error';
    }
    if (color === 'grey') {
      return 'dark';
    }
    return color;
  };

  const guid = guidGenerator();

  return (
    <>
      <div className={`${containerClassName} icon-container xs d-block`}>
        <Button
          className={`icon-btn ${customClassName} ${noBtn ? 'non-clickable' : ''} no-shadow`}
          variant={variant}
          data-tip
          data-place={direction}
          data-for={`registerTip-input-${guid}`}
          {...props}
        >
          <p className="font-weight-bold">{children}</p>
        </Button>
      </div>
      {tooltipText && (
        <ReactTooltip
          id={`registerTip-input-${guid}`}
          delay={{ show: delayShow, hide: delayHide }}
          place={direction}
          type={setType(variant)}
          effect="solid"
          backgroundColor={warningYellow}
          textColor={black}
          html={html}
          clickable={false}
          className="button-tooltip opaque"
          border
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </>
  );
};

ButtonTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  containerClassName: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  direction: PropTypes.string,
  html: PropTypes.bool,
  noBtn: PropTypes.bool,
  variant: PropTypes.string
};

ButtonTooltip.defaultProps = {
  className: '',
  containerClassName: '',
  delayHide: 250,
  delayShow: 200,
  direction: 'top',
  html: true,
  noBtn: false,
  variant: 'warning'
};

export default ButtonTooltip;
