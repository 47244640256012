import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { sendAlert } from '../../actions/utils';
import { FormHeader } from '../../components';
import { camelCaseEmptyStringRecursive, excludeKeys, redirectTo } from '../../services/utils';
import basicInsurancePolicy from './basicInsurancePolicy';
import InsurancePolicyForm from './InsurancePolicyForm';
import { AbilityContext } from '../../config/abilityContext';
import { showInsurancePolicyRequest, updateInsurancePolicyRequest } from '../../requests/insurancePolicies';

const InsurancePolicyEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/insurance_policies';

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const { isRenewal, leadModelUrl, leadPath, policyType } = !!location.state && location.state;
  const isProposal = policyType === 'proposal';

  const [insurancePolicy, setInsurancePolicy] = useState(basicInsurancePolicy);
  const [modalShow, setModalShow] = useState(false);

  let handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Detalle de póliza', href: `${modelUrl}/${id}` },
    { key: 3, name: 'Editar póliza' }
  ];

  const fromLeadBreadcrumb = [
    { key: 1, name: isRenewal ? 'Renovaciones' : 'Ventas', href: leadModelUrl },
    { key: 2, name: `Detalle de ${isRenewal ? 'renovación' : 'oportunidad'}`, href: leadPath },
    { key: 3, name: 'Editar propuesta' }
  ];

  if (isProposal) handleBreadcrumb = fromLeadBreadcrumb;

  const fetchInsurancePolicy = () => {
    showInsurancePolicyRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: response => setInsurancePolicy(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: `${isProposal ? 'Propuesta' : 'Póliza'} actualizada con éxito` }));
    if (isProposal) redirectTo(history, leadPath);
    else redirectTo(history, `${modelUrl}/${id}`);
  };

  const handleParams = ({ acceptance, contract, contractProposal, quotation, ...currentInsurancePolicy }) => {
    const contractKeys = ['contract', 'contractProposal'];
    const proposalKeys = ['acceptance', 'proposalBody', 'proposalTitle', 'quotation'];
    const filteredParams = excludeKeys(currentInsurancePolicy, isProposal ? contractKeys : proposalKeys);
    let sendParams = snakeCaseKeys(filteredParams, { exclude: ['_destroy'] });

    if (isProposal) sendParams = { ...sendParams, acceptance, quotation };
    else sendParams = { ...sendParams, contract, contract_proposal: contractProposal };

    return sendParams;
  };

  const handleUpdateInsurancePolicyRequest = (values, setSubmitting) => {
    const sendParams = handleParams(values.insurancePolicy);
    updateInsurancePolicyRequest(id, {
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => {
        setSubmitting(false);
        setModalShow(false);
      }
    });
  };

  const disableNicoCode = () => {
    return ability.can('manage', 'InsurancePolicy');
  };

  const canManageInsurancePolicy = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'InsurancePolicy');
  };

  useEffect(fetchInsurancePolicy, []);

  if (!canManageInsurancePolicy()) {
    return <Redirect to={modelUrl} />;
  }

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <InsurancePolicyForm
          userModule="broker"
          action="edit"
          modelUrl={modelUrl}
          insurancePolicy={insurancePolicy}
          formRequest={handleUpdateInsurancePolicyRequest}
          proposalOptions={{ isProposal, modalShow, setModalShow }}
          disableNicoCode={disableNicoCode()}
        />
      }
    />
  );
};

export default InsurancePolicyEdit;
