import basicAccount from '../../screens/Account/basicAccount';
import basicUser from '../../screens/User/basicUser';

const basicLeadQuotation = {
  id: '',
  acceptance: '',
  acceptanceDate: '',
  acceptanceIp: '',
  acceptanceKey: '',
  account: basicAccount,
  // commissionPercentage: 0,
  affectCommissionPercentage: 0,
  exemptCommissionPercentage: 0,
  insuranceCompanyId: '',
  keyExpiration: '',
  name: '',
  quotation: '',
  selected: false,
  user: basicUser
};

export default basicLeadQuotation;
