import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { InsuranceBrokerFields } from '../InsuranceBroker';
import { FormikInput, FormikSwitch, LinkBtn } from '../../components';
import { handleFormatTextToLowerCase } from '../../services/utils';

const UserForm = ({ action, errors, onHide, submitVariant, touched, setFieldValue, user, values }) => {
  const submitBtnText = action === 'new' ? 'Crear Usuario' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="user[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Usuario activo?" />}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <InsuranceBrokerFields
          fromPlatformUserForm
          values={values}
          errors={errors}
          touched={touched}
          user={user}
          setFieldValue={setFieldValue}
        />
      </Row>
      <Row className="d-flex flex-row-reverse">
        <Col md={3} sm={12} className="mt-4">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
        <Col md={2} sm={12} className="mt-4">
          <LinkBtn type="submit" variant="cancel" size="lg" className="w-100" to="/users">
            Cancelar
          </LinkBtn>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ user }) => {
  return {
    user
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .required('Debes ingresar un correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    insuranceBrokerAttributes: Yup.object().shape({
      name: Yup.string().required('Debes ingresar Nombre y Apellido del corredor'),
      nationalIdentification: Yup.string().required('Debes ingresar RUT del corredor'),
      brokerCommission: Yup.string().required('Debes ingresar un porcentaje de comisión'),
      addressAttributes: Yup.object().nullable()
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(UserForm);
