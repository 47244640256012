import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { ComponentDataTable } from '../../../components';
import { SearchDateFilter } from '../../../components/Shared';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminBlogRequest } from '../../../requests/admin/blogs'; // Asegúrate de tener esta función para eliminar blogs
import { emptyFalseParamsRecursive } from '../../../services/utils';
import columns from './Columns'; // Asegúrate de ajustar este archivo de columnas para que se adapte a los blogs

const AdminBlogDataTable = ({
  customParamsRef,
  handleIndexRequest,
  isFetching,
  modelUrl,
  moreData,
  setMoreData,
  tableData,
  tableDataAmount
}) => {
  const [customParams, setCustomParams] = useState({ query: '', dateFrom: '', dateTo: '' });
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminBlogRequest,
    modelName: 'Blog',
    nameEndsInA: false, // Ajusta según si el nombre del modelo en tu idioma termina en 'a'
    setMoreData
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSetUpFilters = () => {
    const newParams = snakeCaseKeys(emptyFalseParamsRecursive(customParams));
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(!moreData);
  };

  useEffect(handleSetUpFilters, [customParams]);

  return (
    <>
      <ComponentDataTable
        onRequest={!isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: true, // Ajusta estas opciones según las acciones que desees permitir
          withEdit: true,
          withDestroy: true
        })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        customDatatableHeader={
          <Row>
            <Col md={{ span: 7, offset: 5 }} lg={{ span: 5, offset: 7 }}>
              <SearchDateFilter
                containerClass="ml-lg-5"
                customParams={customParams}
                setCustomParams={setCustomParams}
              />
            </Col>
          </Row>
        }
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default AdminBlogDataTable;
