import React, { useState, useEffect } from 'react';
import { useAbility } from '@casl/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { updateAbility } from '../../../config/ability';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAdminUserRequest, showAdminUserRequest } from '../../../requests/admin/adminUsers';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import AdminUserForm from './AdminUserForm';
import basicUser from './basicUser';

const AdminUserEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/users';
  const [user, setUser] = useState(basicUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const ability = useAbility(AbilityContext);

  const handleBreadcrumb = [
    { key: 1, name: 'Usuarios', href: modelUrl },
    { key: 2, name: 'Editar usuario' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Usuario actualizado con éxito' }));
    updateAbility(ability);
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminUserRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchUser = () => {
    showAdminUserRequest(id, {
      dispatch,
      successCallback: response => setUser(camelCaseEmptyStringRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchUser, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminUserForm action="edit" user={user} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminUserEdit;
