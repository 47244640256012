import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';

import { FormikDatePicker, FormikNumberFormat, FormikSelect } from '../../../components';
import { statusOptions } from '../../../components/CommissionCollection/attributeOptions';
import { buildRecordAdminPremiumCollectionRequest } from '../../../requests/admin/adminPremiumCollections';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import '../../../services/yupCustomMethods';

const AdminCommissionCollectionSummaryOnCreate = ({ modelName, premiumCollection, setShowBuiltRecord }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const dispatch = useDispatch();

  const { collectionType, createdFrom, insurancePolicyId, status } = values.premiumCollection;

  const handleBuildRecordRequest = () => {
    const sendParams = { premiumCollection: { collectionType, createdFrom, insurancePolicyId } };
    buildRecordAdminPremiumCollectionRequest({
      dispatch,
      formData: true,
      params: snakeCaseKeys(sendParams),
      successCallback: response => {
        const builtData = camelCaseEmptyStringRecursive(response.data);
        if (builtData) setFieldValue(modelName, builtData);
        else setFieldValue(modelName, { ...premiumCollection, ...sendParams });
        setShowBuiltRecord(builtData);
      }
    });
  };

  const handleOnChangeInsurancePolicyId = () => {
    if (createdFrom && insurancePolicyId) handleBuildRecordRequest();
    else setShowBuiltRecord(false);
  };

  const netCompanyCommissionCalculated = getIn(values, `${modelName}[netCompanyCommissionCalculated]`);
  const isNegative = netCompanyCommissionCalculated < 0;

  useEffect(handleOnChangeInsurancePolicyId, [insurancePolicyId]);

  return (
    <>
      <p className="section-subtitle">Resumen de creación</p>
      <Row>
        <Col md={6}>
          <Field name={`${modelName}[status]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                isDisabled
                label="Estado"
                placeholder="Seleccionar estado"
                options={statusOptions}
                defaultValue={status}
                onChange={option => setFieldValue(field.name, option?.value || '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[collectionDate]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                disabled
                label="Fecha cobranza"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[parsedTotalNetCommissionCalculated]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                disabled
                label="Comisión calculada total neta"
                prefix="CLP$"
                defaultValue={getIn(values, `${modelName}[totalNetCommissionCalculated]`)}
                fieldName={`${modelName}[totalNetCommissionCalculated]`}
                error={getIn(errors, `${modelName}[totalNetCommissionCalculated]`)}
                touched={getIn(touched, `${modelName}[totalNetCommissionCalculated]`)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[parsedNetCompanyCommissionCalculated]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                disabled
                onlyNegative={isNegative}
                label="Comisión empresa calculada neta"
                prefix="CLP$"
                defaultValue={getIn(values, `${modelName}[netCompanyCommissionCalculated]`)}
                fieldName={`${modelName}[netCompanyCommissionCalculated]`}
                error={getIn(errors, `${modelName}[netCompanyCommissionCalculated]`)}
                touched={getIn(touched, `${modelName}[netCompanyCommissionCalculated]`)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default AdminCommissionCollectionSummaryOnCreate;
