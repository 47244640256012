import React from 'react';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconBtn } from '../../Utils/Button';
import BrokerLandingMainImage from '../../../assets/images/Landing/BrokerLanding/broker_landing_3.webp';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingMain = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <main id="main" className="landing--main broker">
      <div className="landing--main__info-container w-120">
        <div className="landing--main__info">
          <h1 className="main-title">Ahorra tiempo,</h1>
          <h1 className="main-title">mejora tu operación</h1>
          <h1 className="main-title">y enfócate en tus clientes</h1>
          <p className="main-subtitle">
            Centraliza toda tu cartera en un solo lugar y acércate más a tus clientes. NICO se encarga de todo,
            absolutamente todo lo demás.
          </p>
          <IconBtn
            onClick={() => navigateToContact()}
            className="main-button btn-landing w-mobile-100"
            variant="secondary"
          >
            Empieza GRATIS
          </IconBtn>
        </div>
      </div>
      <div className="landing--main__image">
        <Image src={BrokerLandingMainImage} alt="Interfaz del dashboard y modal Nico seguro" />
      </div>
    </main>
  );
};

export default BrokerLandingMain;
