import React from 'react';

const Listbox = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1828 4.92148H4.92031C4.53155 4.92148 4.21641 5.23663 4.21641 5.62539V15.4801C4.21641 15.8688 4.53155 16.184 4.92031 16.184H16.1828C16.5716 16.184 16.8867 15.8688 16.8867 15.4801V5.62539C16.8867 5.23663 16.5716 4.92148 16.1828 4.92148ZM4.92031 3.51367C3.75404 3.51367 2.80859 4.45912 2.80859 5.62539V15.4801C2.80859 16.6463 3.75404 17.5918 4.92031 17.5918H16.1828C17.3491 17.5918 18.2945 16.6463 18.2945 15.4801V5.62539C18.2945 4.45912 17.3491 3.51367 16.1828 3.51367H4.92031ZM6.32812 7.0332H7.73593V8.44101H6.32812V7.0332ZM9.84765 7.0332C9.45889 7.0332 9.14375 7.34835 9.14375 7.73711C9.14375 8.12586 9.45889 8.44101 9.84765 8.44101H14.0711C14.4598 8.44101 14.775 8.12586 14.775 7.73711C14.775 7.34835 14.4598 7.0332 14.0711 7.0332H9.84765ZM7.73593 9.84882H6.32812V11.2566H7.73593V9.84882ZM9.14375 10.5527C9.14375 10.164 9.45889 9.84882 9.84765 9.84882H14.0711C14.4598 9.84882 14.775 10.164 14.775 10.5527C14.775 10.9415 14.4598 11.2566 14.0711 11.2566H9.84765C9.45889 11.2566 9.14375 10.9415 9.14375 10.5527ZM7.73593 12.6644H6.32812V14.0723H7.73593V12.6644ZM9.14375 13.3684C9.14375 12.9796 9.45889 12.6644 9.84765 12.6644H14.0711C14.4598 12.6644 14.775 12.9796 14.775 13.3684C14.775 13.7571 14.4598 14.0723 14.0711 14.0723H9.84765C9.45889 14.0723 9.14375 13.7571 9.14375 13.3684Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46731">
          <rect width="16.8937" height="16.8937" fill="white" transform="translate(2.10547 2.10547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Listbox;
