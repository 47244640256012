import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  PartnerLandingMain,
  PartnerLandingNumbers,
  PartnerLandingWhyNico,
  PartnerLandingKindServices,
  PartnerLandingSuport
} from '../../components/Landing/PartnerLanding';
import { BrokerLandingUiPublicity, BrokerLandingCompanies } from '../../components/Landing/BrokerLanding';
import { LandingCenteredContent, LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';
import cmfLogo from '../../assets/images/Landing/BrokerLanding/cmf-logo.png';
import BrokerLandingCenteredContentImage from '../../assets/images/Landing/BrokerLanding/broker_landing_2.png';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';
import TestimonySection from '../../components/Landing/Shared/TestimonySection';
import { scrollToTop } from '../../services/utils';

const PartnerLanding = () => {
  const { settings } = useSelector(state => state.utils);
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  useEffect(scrollToTop, []);

  return (
    <WebLayout type="partner">
      <PartnerLandingMain settings={settings} />
      <PartnerLandingSuport />
      <PartnerLandingWhyNico />
      <LandingCenteredContent
        title="Estamos regulados por la CMF"
        subtitle="¿Qué esperas?"
        btnText="Empieza GRATIS"
        btnVariant="secondary"
        bgImage={BrokerLandingCenteredContentImage}
        logo={cmfLogo}
        onClick={navigateToContact}
      />
      <PartnerLandingNumbers />
      <PartnerLandingKindServices />
      <TestimonySection />
      <BrokerLandingUiPublicity />
      <BrokerLandingCompanies />
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default PartnerLanding;
