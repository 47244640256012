import React from 'react';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconBtn } from '../../Utils/Button';
import ClientLandingMainImage from '../../../assets/images/Landing/ClientLanding/client_landing_1.webp';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const ClientLandingMain = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <main id="main" className="landing--main client">
      <div className="client-landing--main__heroclientsplans__container client-landing--main__heroclientsplans__container__main">
        <div className="landing--main__info">
          <h2 className="client-landing--main__info__main-title">Simplificar el universo de los seguros</h2>
          <h4 className="client-landing--main__info__main-subtitle">
            En NICO trabajamos para que la experiencia completa de los seguros sea más simple. Y así, proteger a más
            personas.
          </h4>
          <p className="client-landing--main__info__main-text">
            Organiza tus seguros en un solo lugar, con información ordenada y al día, y úsalos de la mejor manera.
            Simple y transparente.
          </p>
          <IconBtn onClick={() => navigateToContact()} className="main-button btn-landing" variant="secondary">
            Quiero asesoría GRATIS
          </IconBtn>
        </div>
      </div>
      <div className="landing--main__image">
        <Image
          src={ClientLandingMainImage}
          alt="Chica contenta y tranquila porque confía que en nico le daremos la mejor opción de seguro"
        />
      </div>
    </main>
  );
};

export default ClientLandingMain;
