import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { FormHeader } from '../../../components';
import { showAdminBlogRequest } from '../../../requests/admin/blogs';
import AdminBlogInfo from './AdminBlogInfo';
import { basicBlog } from './basicBlog';

const AdminBlogShow = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/blogs';
  const [blog, setBlog] = useState(basicBlog);
  const dispatch = useDispatch();

  const handleBreadcrumb = [
    { key: 1, name: 'Blogs', href: modelUrl },
    { key: 2, name: 'Detalle del blog' }
  ];

  const handleSuccessShow = response => {
    setBlog(camelCaseRecursive(response.data));
  };

  const handleShowBlog = () => {
    showAdminBlogRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(handleShowBlog, []);

  return <FormHeader handleBreadcrumb={handleBreadcrumb} formComponent={<AdminBlogInfo blog={blog} />} />;
};

export default AdminBlogShow;
