import React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import AdminSettingTableRow from './AdminSettingTableRow';

const AdminSettingTable = ({ settings, setMoreData, moreData, isFetching, settingTypeFilter = 'general' }) => {
  if (isFetching) {
    return (
      <div className="containerSpinnerLoad fullHeightAbsolute">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div className="mt-5">
      <Row className="border px-3 py-2">
        <Col md={3}>
          <h5>Opciones</h5>
        </Col>
        <Col md={9}>
          <h5>valor</h5>
        </Col>
      </Row>
      {settings
        .filter(({ settingType }) => settingType === settingTypeFilter)
        .map(setting => (
          <AdminSettingTableRow key={setting.id} setting={setting} setMoreData={setMoreData} moreData={moreData} />
        ))}
    </div>
  );
};

export default AdminSettingTable;
