import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import {
  showAdminInsuranceEndorsementRequest,
  updateAdminInsuranceEndorsementRequest
} from '../../../requests/admin/insuranceEndorsements';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';
import basicInsuranceEndorsement from './basicInsuranceEndorsement';
import InsuranceEndorsementForm from '../../../components/InsuranceEndorsement/InsuranceEndorsementForm';
import { InsuranceEndorsementProvider } from '../../../components/InsuranceEndorsement/InsuranceEndorsementContext';

const AdminInsuranceEndorsementEdit = ({ location, match }) => {
  const { id } = match.params;
  const { fromFormHeaderShow = false, insurancePolicy = {}, modelUrl, originPath } = location.state || {};
  const action = fromFormHeaderShow ? 'new' : 'edit';

  const [insuranceEndorsement, setInsuranceEndorsement] = useState(basicInsuranceEndorsement);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', onClick: () => redirectTo(history, modelUrl) },
    { key: 2, name: 'Detalle de póliza', onClick: () => redirectTo(history, originPath) }
  ];

  const currentPathName = fromFormHeaderShow ? 'Crear nuevo endoso' : 'Editar endoso';
  handleBreadcrumb.push({ key: 3, name: currentPathName });

  const handleSuccessUpdate = () => {
    const message = fromFormHeaderShow ? 'Endoso creado con éxito' : 'Endoso actualizado con éxito';
    dispatch(sendAlert({ kind: 'success', message }));
    redirectTo(history, originPath);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { approval } = values.insuranceEndorsement;
    const sendParams = snakeCaseKeys(values.insuranceEndorsement);
    updateAdminInsuranceEndorsementRequest(insurancePolicy.id, id, {
      dispatch,
      params: { insurance_endorsement: { ...sendParams, approval, status: 'updated' } },
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const fetchInsuranceEndorsement = () => {
    showAdminInsuranceEndorsementRequest(insurancePolicy.id, id, {
      dispatch,
      successCallback: response => {
        const data = camelCaseEmptyStringRecursive(response.data);
        const originalItem = insurancePolicy.insuranceItemsAttributes.find(item => item.id === data.insuranceItemId);
        const sendData = {
          ...data,
          approval: data.approvalInfo.fileSignature,
          originalInsuranceItem: originalItem,
          insuranceItemId: parseInt(data.draftForm.insuranceItemAttributes?.value, 10),
          insuranceItemAttributes: data.draftForm.insuranceItemAttributes,
          modificationType: data.draftForm.modificationType,
          additionalClauses: data.draftForm.additionalClauses
        };
        setInsuranceEndorsement(sendData);
      }
    });
  };

  useEffect(scrollToTop, []);
  useEffect(fetchInsuranceEndorsement, []);

  return (
    <InsuranceEndorsementProvider value={{ action, originalInsuranceEndorsement: insuranceEndorsement }}>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={
          <InsuranceEndorsementForm
            action={action}
            insurancePolicy={insurancePolicy}
            insuranceEndorsement={insuranceEndorsement}
            formRequest={handleUpdateRequest}
            originPath={originPath}
          />
        }
      />
    </InsuranceEndorsementProvider>
  );
};

export default AdminInsuranceEndorsementEdit;
