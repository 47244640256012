import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import jwt from 'jwt-decode';

import { NavbarTop, Sidebar } from '../../components';

const InsuredLoggedLayout = ({ component: Component, insuredGamma = false, insuredDelta = false, ...rest }) => {
  const getUserModule = roles => {
    const rolePriority = ['insured'];
    return rolePriority.find(role => roles.includes(role)) || 'unknown';
  };

  const hasAccess = insuredType => {
    return (insuredGamma && insuredType === 'gamma') || (insuredDelta && insuredType === 'delta');
  };

  return (
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');
        const user = payload && jwt(payload);

        const tagManagerArgs = { dataLayer: { userEmail: user?.email } };
        TagManager.dataLayer(tagManagerArgs);

        if (!payload) return <Redirect to={{ pathname: '/insured/login', state: { from: props.location } }} />;

        const { roles, insured_type: insuredType } = user;

        if (!roles.includes('insured'))
          return <div className="w-50 mx-auto text-center">Need to be an insured to access this route</div>;

        if (!hasAccess(insuredType))
          return <div className="w-50 mx-auto text-center">Need to be an insured to access this route</div>;

        return (
          <>
            <NavbarTop userModule={getUserModule(roles)} insuredType={insuredType} />
            <Sidebar userModule={getUserModule(roles)} insuredType={insuredType} />
            <div className="content-body">
              <Component {...props} />
            </div>
          </>
        );
      }}
    />
  );
};

export default InsuredLoggedLayout;
