/* eslint-disable object-shorthand */
import React from 'react';
import { Image } from 'react-bootstrap';
import IconGG from '../../Utils/Icon';
import car from '../../../assets/svg/car.svg';
import detective from '../../../assets/svg/detective.svg';
import fire from '../../../assets/svg/fire.svg';
import paw from '../../../assets/svg/paw.svg';
import person from '../../../assets/svg/person.svg';
import world from '../../../assets/svg/world.svg';
import graph from '../../../assets/svg/graph.svg';
import school from '../../../assets/svg/school.svg';
import hand from '../../../assets/svg/hand.svg';
import aid from '../../../assets/svg/aid.svg';
import stethoscope from '../../../assets/svg/stethoscope.svg';
import tooth from '../../../assets/svg/tooth.svg';
import airplane from '../../../assets/svg/airplane.svg';
import document from '../../../assets/svg/document.svg';
import handshake from '../../../assets/svg/handshake.svg';
import dollar from '../../../assets/svg/dollar.svg';
import union from '../../../assets/svg/union.svg';
import check from '../../../assets/svg/check.svg';
import organisation from '../../../assets/svg/organisation.svg';

const iconMap = {
  aid,
  airplane,
  car,
  detective,
  dollar,
  check,
  fire,
  graph,
  hand,
  handshake,
  paw,
  person,
  school,
  stethoscope,
  tooth,
  world,
  document,
  organisation,
  union
};

const WhyNicoCard = ({ cardStyle, icon, iconContainerStyle, title, subtitle, text }) => {
  const iconSrc = iconMap[icon];
  return (
    <div className="why-nico--card" style={cardStyle}>
      <div className="why-nico--card__icon-container" style={iconContainerStyle}>
        {iconSrc ? (
          <Image fluid src={iconSrc} alt="icon" />
        ) : (
          <IconGG className="why-nico--card__icon text-color-secondary-light-blue" icon={icon} />
        )}
      </div>
      <p className="why-nico--card__title section-title font-weight-bold" style={iconContainerStyle}>
        {title}
      </p>
      <p className="why-nico--card__subtitle section-title font-weight-bold" style={iconContainerStyle}>
        {subtitle}
      </p>
      <p className="why-nico--card__text font-weight-500" style={iconContainerStyle}>
        {text}
      </p>
    </div>
  );
};

export default WhyNicoCard;
