import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { useLocation } from 'react-router-dom';

import { signInByVerificationCodeRequest } from '../../../actions/auth';
import { sendAlert } from '../../../actions/utils';
import { LoginCatchphrase, LoginNavbar } from '../../../components/Auth';
import { scrollToTop } from '../../../services/utils';
import sendVerificationCodeRequest from '../../../requests/insured/insuredSignInByVerificationCodes';
import InsuredSignInBox from './InsuredSignInBox';

const InsuredLoginIndex = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/insured/home' } };

  const [sentEmail, setSentEmail] = useState(false);

  const handleSendVerificationCodeRequest = (values, setSubmitting) => {
    sendVerificationCodeRequest({
      dispatch,
      params: { email: values.user.email },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Código de verificación enviado con éxito' }));
        setSentEmail(true);
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleSignInVerificationCodeRequest = (values, setSubmitting) => {
    const sendParams = snakecaseKeys(values);
    dispatch(signInByVerificationCodeRequest(sendParams, setSubmitting, from));
  };

  useEffect(scrollToTop, []);

  return (
    <>
      <div className="login-index bg-secondary">
        <LoginNavbar showRegister />
        <LoginCatchphrase
          textColor="text-color-secondary-light-blue"
          arrayText={['Simplificar el universo de los seguros']}
        />
        <InsuredSignInBox
          formRequest={sentEmail ? handleSignInVerificationCodeRequest : handleSendVerificationCodeRequest}
          sendCodeRequest={handleSendVerificationCodeRequest}
          sentEmail={sentEmail}
        />
      </div>
      <div className="login-index--circles">
        <span className="circle circle-big" />
        <span className="circle white circle-petite-1" />
        <span className="circle white circle-petite-2" />
        <span className="circle white circle-petite-3" />
        <span className="circle white circle-bottom" />
        <span className="circle circle-colored" />
      </div>
    </>
  );
};

export default InsuredLoginIndex;
