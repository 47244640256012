import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const contactObject = ({ contact, isEmpty }) => {
  if (isEmpty) return false;

  const { email, name, phoneNumber, position } = contact;

  const object = {};

  object.name = infoCardElement({
    string: true,
    key: 'Nombre completo',
    value: name
  });
  if (contact) {
    object.email = infoCardElement({
      string: true,
      key: 'Correo electrónico',
      value: email || 'Sin correo electrónico'
    });
    object.phoneNumber = infoCardElement({
      string: true,
      key: 'Teléfono',
      value: phoneNumber || 'Sin número de teléfono'
    });
    object.position = infoCardElement({
      string: true,
      key: 'Cargo',
      value: position || 'No especificado'
    });
  }

  return object;
};

export default contactObject;
