import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexLeadRequest = ({ dispatch, params = {}, successCallback, failureCallback, callback, source }) =>
  ApiService.request('get', '/leads', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showLeadRequest = (id, { dispatch, params = {}, successCallback, failureCallback, callback, source }) =>
  ApiService.request('get', `/leads/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createLeadRequest = ({ dispatch, params, formData, successCallback, failureCallback, callback, source }) =>
  ApiService.request('post', '/leads', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/leads/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteLeadRequest = (id, { dispatch, successCallback, callback, source }) =>
  ApiService.request('delete', `/leads/${id}`, {
    dispatch,
    successCallback,
    callback,
    source
  });

export const closeLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/leads/${id}/close_lead`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const rollbackCloseLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/leads/${id}/rollback_close_lead`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const rollbackRequestedLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/leads/${id}/rollback_requested_lead`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const autosaveLeadRequest = (id, { dispatch, params, formData, successCallback, callback, source }) =>
  ApiService.request('patch', `/leads/${id}/autosave`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    source
  });

export const sendLeadQuotationsRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) => {
  ApiService.request('post', `/leads/${id}/send_quotations`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const sendLeadReservationRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) => {
  ApiService.request('post', `/leads/${id}/send_reservation`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const debounceIndexLeadRequest = AwesomeDebouncePromise(indexLeadRequest, 1000);
export const debounceAutosaveLeadRequest = AwesomeDebouncePromise(autosaveLeadRequest, 1000);
