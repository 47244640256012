import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { FormikInputRadio } from '../Utils/Input';

const CurrencyRadioInput = ({ disabled, field }) => {
  const { errors, values } = useFormikContext();
  const currency = getIn(values, field.name);
  const options = [
    { value: 'UF', label: 'UF' },
    { value: 'USD', label: 'USD' }
  ];

  return (
    <FormikInputRadio
      {...field}
      abbr
      field={field}
      label="Moneda"
      defaultValue={currency}
      disabled={disabled}
      // value={currency}
      error={getIn(errors, field.name)}
      options={options}
      tooltipText="Moneda en la cual se establece la Póliza."
    />
  );
};

export default CurrencyRadioInput;
