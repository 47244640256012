import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { setFilters } from '../../actions/utils';
import { StatisticCard, BtnTooltip, HomeWelcomeCard, HomeProfileCard, CustomBarChart } from '../../components';
import { RequestRegisterSuccess } from '../../components/Shared';
import { useFetchData, useUtils } from '../../hooks';
import { debouncePoliciesSummaryRequest, debounceChartDataSummaryRequest } from '../../requests/user';
import { redirectTo } from '../../services/utils';

const HomeIndex = () => {
  const history = useHistory();
  const { reduxUser } = useUtils();
  const dispatch = useDispatch();
  const { data: summary, isFetching } = useFetchData({
    debouncedIndexRequest: debouncePoliciesSummaryRequest,
    withoutWrapper: true
  });

  const { data: charDataResponse } = useFetchData({
    debouncedIndexRequest: debounceChartDataSummaryRequest,
    withoutWrapper: true
  });

  const { chartData = {} } = charDataResponse;
  const { commission: commissionData, premium: premiumData } = chartData;

  const insuranceBrokerAttributes = camelCaseRecursive(getIn(reduxUser, 'insurance_broker_attributes'));
  const brokerName = getIn(insuranceBrokerAttributes, 'name');
  const brokerDaysOnNico = getIn(insuranceBrokerAttributes, 'daysOnNico');
  const {
    availableQuotationCount,
    debtCount,
    nearInvalidityCount,
    parsedMonthlyCommission,
    parsedPoliciesValidTotalNetPremium,
    sinisterCount,
    totalClients,
    userAvatarInfo = {},
    validCount
  } = summary;

  const { fileUrl: userAvatarFileUrl } = userAvatarInfo;
  const greetMessage = () => {
    return brokerName ? `¡Hola ${brokerName}!` : 'Bienvenido a nico';
  };

  const parseDays = () => {
    const daysString = brokerDaysOnNico > 1 ? 'días' : 'día';
    return `${brokerDaysOnNico} ${daysString}`;
  };

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isFetching && (
        <div className="home-index">
          <RequestRegisterSuccess />
          <header className="home-index--header">
            <HomeWelcomeCard
              icon="smile-mouth-open"
              variant="transparent"
              title={greetMessage()}
              subtitle={`Llevas ${parseDays()} usando NICO`}
            />
            <HomeProfileCard
              user={insuranceBrokerAttributes}
              userProfileImg={userAvatarFileUrl}
              buttonComponent={
                <BtnTooltip
                  to="/profile"
                  variant="primary-dark"
                  icon="pen"
                  iconSize="md"
                  tooltipText="Editar Perfil"
                  className="edit-btn no-shadow"
                />
              }
            />
          </header>
          <div className="home-index--statistics">
            <StatisticCard
              icon="briefcase"
              variant="primary-light"
              title="Clientes"
              value={totalClients}
              clickable
              onClick={() => redirectTo(history, '/accounts')}
            />
            <StatisticCard
              icon="notes"
              variant="primary-light"
              title="Pólizas vigentes"
              value={validCount}
              clickable
              onClick={() => redirectTo(history, '/insurance_policies')}
            />
            <StatisticCard
              icon="dollar"
              variant="success"
              title="Prima total neta"
              value={parsedPoliciesValidTotalNetPremium}
            />
            <StatisticCard
              icon="dollar"
              variant="success"
              title="Comisión mensualizada"
              value={parsedMonthlyCommission}
              tooltipText="Corresponde al ingreso mensualizado generado <br/>
                por cada póliza vigente. Es decir, si una póliza <br/>
                vigente tiene vigencia de 6 meses, tu comisión <br/>
                por esa póliza se incluye dividida por 6. Esto te <br/>
                ayudará a ver cuánto estás generando en <br/>
                promedio mensualmente."
            />
            <StatisticCard
              icon="smile-sad"
              variant="danger"
              title="Siniestros"
              value={sinisterCount}
              clickable
              onClick={() => redirectTo(history, '/insurance_policies', { withSinister: true, isValid: '' })}
            />
            <StatisticCard
              icon="dollar"
              variant="danger"
              title="Deudas"
              value={debtCount}
              clickable
              onClick={() => redirectTo(history, '/insurance_policies', { withDebt: true, isValid: '' })}
            />
            <StatisticCard
              icon="danger"
              variant="warning"
              title="Pólizas por vencer"
              value={nearInvalidityCount}
              clickable
              onClick={() => redirectTo(history, '/insurance_policies', { nearInvalidity: true })}
            />
            <StatisticCard
              icon="calculator"
              variant="primary-light"
              title="Cotizaciones disponibles"
              value={availableQuotationCount}
              clickable
              onClick={() => {
                dispatch(
                  setFilters({
                    step: 'quotation',
                    quotationStatus: 'with_quotation',
                    stepNumber: 2
                  })
                );
                redirectTo(history, '/leads');
              }}
            />
          </div>
          <div className="home-index--charts">
            {premiumData && (
              <CustomBarChart
                title="Prima neta vendida mensual"
                data={premiumData}
                tooltipText="Para los cálculos de ambos gráficos se usa la <br/>
                  fecha de inicio de vigencia de las pólizas."
              />
            )}
            {commissionData && (
              <CustomBarChart title="Comisión estimada mensual" data={commissionData} titleClass="mb-4" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HomeIndex;
