import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import { useAbility } from '@casl/react';
import * as Yup from 'yup';

import { FormikInput } from '../../../components';
import { updateAbility } from '../../../config/ability';
import { AbilityContext } from '../../../config/abilityContext';

const InsuredSignInBox = ({
  errors,
  isSubmitting,
  sendCodeRequest,
  sentEmail,
  setFieldValue,
  setSubmitting,
  touched,
  values
}) => {
  const { signedIn } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);

  const submitBtnText = sentEmail ? 'Iniciar sesión' : 'Continuar';

  const handleOnLogin = () => {
    if (!signedIn) return;
    updateAbility(ability);
  };

  useEffect(handleOnLogin, [signedIn]);

  return (
    <div className="login-box--container">
      <div className="login-box">
        <h6 className="login-box--title text-lowercase text-color-white text-center">Ingresa a tu cuenta</h6>
        <Form className="login-box--form">
          {sentEmail ? (
            <Field name="user[verificationCode]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  noLabelFocus
                  label="Código de verificación"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          ) : (
            <Field name="user[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  noLabelFocus
                  label="Correo electrónico"
                  placeholder="usuario@correo.com"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          )}

          <Button
            block
            type="submit"
            variant="secondary"
            className="my-5 w-100 text-lowercase btn-big"
            disabled={isSubmitting}
          >
            {submitBtnText}
          </Button>

          {sentEmail && (
            <Button
              block
              variant="transparent"
              className="white btn-big"
              onClick={() => {
                setFieldValue('user[verificationCode]', '');
                sendCodeRequest(values, setSubmitting);
              }}
            >
              reenviar código de verificación
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
};

const setInitialValues = () => ({ user: { email: '', verificationCode: '' } });

const validationSchema = ({ sentEmail }) =>
  Yup.object().shape({
    user: Yup.object().shape({
      email: Yup.string()
        .email('El email que ingresaste no es válido')
        .required('Debes ingresar un email'),
      verificationCode: sentEmail
        ? Yup.string().required('Debes ingresar un código de verificación')
        : Yup.string().nullable()
    })
  });

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit
})(InsuredSignInBox);
