// eslint-disable-next-line import/prefer-default-export

export const insurancePolicyFiltersParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  isValid: 'true',
  isVip: '',
  insuranceCompanyId: '',
  insuranceCategoryId: '',
  withSinister: '',
  withDebt: ''
};

export const insurancePolicyAdvancedOptions = [
  { label: 'Vip', value: 'isVip' },
  { label: 'Siniestro', value: 'withSinister' },
  { label: 'Deuda', value: 'withDebt' },
  { label: 'Por vencer', value: 'nearInvalidity' }
];

export const insuranceTabsOptions = [
  { label: 'Vigentes', value: 'true' },
  { label: 'No Vigentes', value: 'false' },
  { label: 'Todas', value: '' }
];

export const insuranceSearchOptions = [
  {
    placeholder: 'Buscar número propuesta...',
    icon: 'search',
    fieldName: 'proposalNumber',
    style: { marginLeft: 'auto' }
  },
  {
    placeholder: 'Buscar número endoso...',
    icon: 'search',
    fieldName: 'endorsementNumber',
    style: {}
  }
];
