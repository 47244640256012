import React, { useState } from 'react';
import snakeCaseKeys from 'snakecase-keys';
import { BtnTooltip } from '../Utils/Button';
import AdditionalDocumentForm from '../Shared/AdditionalDocumentForm';
import { SimpleCenteredModal } from '../Utils/Modal';

const UploadFiles = ({ updateRequest, item }) => {
  const [modalShow, setModalShow] = useState(false);
  const modelName = 'premiumCollection';

  const handleUpdate = values => {
    const { addDocumentKeys = [], removeAttachmentIds = [] } = values[modelName];
    const sendParams = {
      id: item.id,
      updateParams: snakeCaseKeys({ addDocumentKeys, removeAttachmentIds }),
      updateDataTable: true
    };
    updateRequest(sendParams);
    // setModalShow(false);
  };

  return (
    <>
      <BtnTooltip
        icon="export"
        iconSize="sm"
        variant="transparent"
        className="black"
        tooltipText="Cargar archivos"
        onClick={() => setModalShow(true)}
      />

      <SimpleCenteredModal
        size="xl"
        title="Agregar o eliminar documentos"
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={
          <AdditionalDocumentForm
            action="edit"
            modelName={modelName}
            object={item}
            formRequest={handleUpdate}
            handleCancelButton={() => setModalShow(false)}
          />
        }
      />
    </>
  );
};

export default UploadFiles;
