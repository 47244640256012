import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { useDebouncedValue, useDidMountEffect } from '../../../hooks';
import { emptyKeys, setFieldAttribute, sumArrayValues } from '../../../services/utils';
import { BasicTextArea, FormikInput } from '../../Utils/Input';
import { NestedAttributes } from '../../Utils/NestedAttributes';
import { basicInsuranceCover } from './basicInsuranceCover';
import basicInsuranceItem from './basicInsuranceItem';
import CoverNestedAttributes from './CoverNestedAttributes';
import DictionaryFields from './DictionaryFields';
import { AccordionCard } from '../../Utils/Cards';

const ItemNestedAttributes = ({
  fromEndorsement,
  baseItemNumber,
  modelName,
  action,
  fromLeadProposal,
  isProposal,
  leadPresent,
  isLead = false,
  isRenewal
}) => {
  const { setFieldValue, values } = useFormikContext();
  const { insuranceItemsAttributes } = getIn(values, modelName);
  const { currency, dictionaryDetails } = values.insurancePolicy || values.lead;

  const debouncedInsuranceItems = useDebouncedValue(insuranceItemsAttributes, 300);

  const currentInsuranceItems = insuranceItemsAttributes.filter(insuranceItem => !insuranceItem._destroy);
  const lastInsuranceItemObject = currentInsuranceItems[currentInsuranceItems.length - 1];
  const lastInsuranceItem = emptyKeys(lastInsuranceItemObject, ['id', 'name']);
  const insuranceCovers = lastInsuranceItem.insuranceCoversAttributes || [];
  lastInsuranceItem.insuranceCoversAttributes = insuranceCovers.map(cover => emptyKeys(cover, ['id']));

  const mapResults = insuranceItemsAttributes.map((body, index) => {
    if (body._destroy) return undefined;

    return (
      <Col key={`item-${index.toString()}`}>
        <AccordionCard
          title={`Ítem ${baseItemNumber ? baseItemNumber + index + 1 : index + 1}`}
          length={null}
          body={
            <InsuranceItemForm
              key={`item-${index.toString()}`}
              title={`Ítem ${baseItemNumber ? baseItemNumber + index + 1 : index + 1}`}
              modelName={`${modelName}[insuranceItemsAttributes][${index}]`}
              fromEndorsement={fromEndorsement}
              dictionaryDetails={dictionaryDetails}
              action={action}
              fromLeadProposal={fromLeadProposal}
              isProposal={isProposal}
              leadPresent={leadPresent}
              isLead={isLead}
              isRenewal={isRenewal}
            />
          }
        />
      </Col>
    );
  });

  const handleDebouncedValues = () => {
    const premiums = { affectPremium: [], exemptPremium: [] };
    insuranceItemsAttributes.forEach(insuranceItem => {
      if (insuranceItem._destroy) return;
      premiums.affectPremium.push(parseFloat(insuranceItem.affectPremium || 0));
      premiums.exemptPremium.push(parseFloat(insuranceItem.exemptPremium || 0));
    });
    setFieldAttribute(
      modelName,
      'affectPremium',
      setFieldValue,
      sumArrayValues(premiums.affectPremium),
      ` ${currency}`
    );
    setFieldAttribute(
      modelName,
      'exemptPremium',
      setFieldValue,
      sumArrayValues(premiums.exemptPremium),
      ` ${currency}`
    );
  };

  useDidMountEffect(handleDebouncedValues, [debouncedInsuranceItems]);

  return (
    <NestedAttributes
      bottomBtnAddRow
      splitLine={false}
      mapInputs={mapResults}
      attributesTitle={baseItemNumber ? '' : 'Ítems'}
      arrayValues={insuranceItemsAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${modelName}[insuranceItemsAttributes]`}
      newAttributes={{ ...basicInsuranceItem, insuranceCoversAttributes: [basicInsuranceCover], ...lastInsuranceItem }}
      btnMessage="Agregar ítem"
      removeBtnStyle={{ position: 'absolute', top: '7.5rem', right: '2rem' }}
      noBtn={fromEndorsement}
    />
  );
};

export const InsuranceItemForm = ({
  fromEndorsement,
  modelName,
  title,
  insurancePolicy,
  dictionaryDetails,
  action,
  fromLeadProposal,
  isProposal,
  isLead,
  leadPresent,
  isRenewal
}) => {
  const { errors, touched } = useFormikContext();

  return (
    <Row className="mb-5">
      <Col xs={10} md={11} className={`d-flex align-items-center mb-3 ${fromEndorsement ? '' : 'pl-5'}`}>
        <p className={`d-block mt-4 mr-5 ${fromEndorsement ? 'section-subtitle' : 'section-title'}`}>
          {title} <abbr className="text-danger">*</abbr>
        </p>
        <Field name={`${modelName}[name]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              simpleInput
              maxLength={160}
              placeholder="Nombre ítem"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              margin="custom-width"
            />
          )}
        </Field>
      </Col>

      <DictionaryFields
        modelName={modelName}
        dictionaryDetails={dictionaryDetails}
        errors={errors}
        touched={touched}
        action={action}
        fromLeadProposal={fromLeadProposal}
        isProposal={isProposal}
        leadPresent={leadPresent}
        isRenewal={isRenewal}
      />

      <Col xs={12} className={`mb-4 ${fromEndorsement ? '' : 'pl-5'}`}>
        <Field name={`${modelName}[notes]`}>
          {({ field }) => (
            <BasicTextArea
              {...field}
              label="Notas"
              placeholder="Escribe todas las notas necesarias del ítem"
              maxLength={1000}
              rows={2}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              className="p-3"
            />
          )}
        </Field>
      </Col>

      {!isLead && (
        <Col xs={12} className={`additional-nested-attributes ${fromEndorsement ? 'mb-5' : 'pl-5'}`}>
          <CoverNestedAttributes
            modelName={modelName}
            fromEndorsement={fromEndorsement}
            insurancePolicy={insurancePolicy}
          />
        </Col>
      )}
    </Row>
  );
};

export default ItemNestedAttributes;
