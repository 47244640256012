import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Image } from 'react-bootstrap';

const InfoCardInsuranceCompany = () => {
  const { insuranceCompanyLogos } = useSelector(state => state.utils);

  return (
    <Card>
      <Card.Header as="p" className="section-title capitalize-text px-0">
        Companías
      </Card.Header>

      <Card.Text>
        Estas son todas las compañías de seguros con las que trabajamos y, por lo tanto, a las que puedes acceder para
        proteger tus bienes, vida y salud. Junto a cada una, encontrarás links de interés para hacer tu vida más fácil.
      </Card.Text>

      <Card.Body className="insurance-company-logo">
        {insuranceCompanyLogos.map((insuranceCompany, index) => (
          <div key={`insurance-company-logo-${index.toString()}`} className="insurance-company-logo__container">
            <div className="insurance-company-logo__container__image">
              <Image src={insuranceCompany.logoInfo?.fileUrl} alt={insuranceCompany.altInfo} />
            </div>
            <div className="insurance-company-logo__container__button">
              <LinkButton variant="primary" title="Sitio web" url={insuranceCompany.websiteLink} />
              <LinkButton
                variant="secondary-var-blue"
                title="Pago de primas"
                url={insuranceCompany.premiumPaymentLink}
              />
              <LinkButton
                variant="secondary-var-blue"
                title="Denuncia de siniestros"
                url={insuranceCompany.sinisterReportLink}
              />
            </div>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

const LinkButton = ({ title, url, variant }) => {
  const openLink = () => url && window.open(url, '_blank', 'noopener');

  return (
    <Button block variant={variant} className="btn-sm" onClick={openLink}>
      {title}
    </Button>
  );
};

export default InfoCardInsuranceCompany;
