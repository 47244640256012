import React from 'react';

import { IconBtn, LinkBtn } from '../Button';

const DatatableMainInfo = ({
  btnIconStyle = {},
  className: customClass,
  icon = 'user',
  iconSize = 'sm',
  linkTitle,
  noIcon,
  secondarySubtitle,
  subtitle,
  title
}) => {
  return (
    <div className={`datatable main-info ${noIcon ? 'd-block text-left' : ''}`}>
      {!noIcon && (
        <div className={`main-info--icon ${customClass || ''}`}>
          <IconBtn noBtn icon={icon} variant={`datatable ${btnIconStyle.color}`} iconSize={iconSize} />
        </div>
      )}
      <div className="main-info--data">
        {linkTitle ? (
          <LinkBtn
            to={linkTitle}
            variant="transparent"
            className="justify-content-start p-0 pe-auto text-left"
            style={{ minHeight: '2rem', fontSize: '1.4rem' }}
          >
            {title}
          </LinkBtn>
        ) : (
          <p className="row-title">{title}</p>
        )}
        {subtitle && <p className="detail">{subtitle}</p>}
        {secondarySubtitle && <p className="detail">{secondarySubtitle}</p>}
      </div>
    </div>
  );
};

export default DatatableMainInfo;
