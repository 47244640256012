import { useEffect, useRef } from 'react';

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  const handleDidMountEffect = () => {
    if (didMount.current) func();
    else didMount.current = true;
  };

  useEffect(handleDidMountEffect, deps);
};

export default useDidMountEffect;
