/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../../components';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => {
    return [
      {
        name: 'Nombre',
        selector: 'name',
        sortable: false,
        grow: '2'
      },
      {
        name: 'Región',
        selector: 'regionName',
        sortable: false,
        grow: '2'
      },
      {
        cell: item => (
          <BasicActionBtns
            item={item}
            clickHandler={handleBasicActions}
            path={modelUrl}
            withShow={withShow}
            withEdit={withEdit}
            withDestroy={withDestroy}
            showClick={showClick}
            editClick={editClick}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        grow: '1',
        minWidth: '150px'
      }
    ];
  }
);

export default columns;
