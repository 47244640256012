import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';

import { InfoCardGeneral } from '../InfoCard';
import InfoCardRisk from '../InfoCard/InfoCardRisk';
import InfoCardSummaryCoverage from '../InfoCard/InfoCardSummaryCoverage';

const InsurancePolicyFormSummary = ({ isRenewal, isProposal, modelName }) => {
  const { values, setFieldValue } = useFormikContext();
  const insurancePolicy = getIn(values, modelName);

  // const { acceptance, contract, contractFile, contractNumber, contractProposal, quotation } = insurancePolicy;
  // const createContractFromProposal = isProposal && contractNumber && contractFile;

  const handleInicialProposalValues = () => {
    const { fromPolicyNumber, insuranceCompanyFullName } = values.insurancePolicy;
    const companyName = 'NICO Corredores de Seguros SpA';
    const proposalType = isRenewal ? 'Renovación' : 'Contratación';
    const title = `Propuesta de ${proposalType.toLowerCase()} de ${companyName} a ${insuranceCompanyFullName}`;
    let description = `${companyName} entrega esta propuesta de ${proposalType.toLowerCase()} de`;
    description = description.concat(' ', isRenewal ? `la póliza N° ${fromPolicyNumber}` : 'seguros');
    description = description.concat(' ', 'según los datos entregados a continuación.');
    setFieldValue('insurancePolicy[proposalTitle]', title);
    setFieldValue('insurancePolicy[proposalBody]', description);
  };

  useEffect(handleInicialProposalValues, [isProposal]);

  return (
    <>
      <p className="section-title">Resumen</p>
      <div className="insurance-policy-show-layout summary-main">
        <div className="general-information">
          <InfoCardGeneral isForm insurancePolicy={insurancePolicy} />
        </div>
        <div className="details-information">
          <InfoCardRisk insurancePolicy={insurancePolicy} />
          <InfoCardSummaryCoverage insurancePolicy={insurancePolicy} fromAdmin isProposal={isProposal} />
        </div>
        {/* <div className="side-components">
          {isProposal ? (
            <>
              <AttachedFile title="Cotización" attribute="quotation" modelName={modelName} file={quotation} />
              <AttachedFile title="Aprobación" attribute="acceptance" modelName={modelName} file={acceptance} />
              {createContractFromProposal && (
                <AttachedFile title="Póliza" attribute="contractFile" modelName={modelName} file={contractFile} />
              )}
            </>
          ) : (
            <>
              <AttachedFile title="Póliza" attribute="contract" modelName={modelName} file={contract} />
              <AttachedFile
                title="Propuesta"
                attribute="contractProposal"
                modelName={modelName}
                file={contractProposal}
              />
            </>
          )}
        </div> */}
      </div>
    </>
  );
};

// const AttachedFile = ({ attribute, file, modelName, title }) => {
//   const { values } = useFormikContext();
//   const savedFile = getIn(values, `${modelName}[${attribute}Info]`);

//   const fileLoaded = value => typeof value === 'object' && value;

//   return (
//     <StatisticCard
//       icon="notes"
//       variant="secondary-light"
//       title={title}
//       footerButton={
//         <CustomBtn
//           disabled={!file}
//           tooltipText={`${!file ? 'Sin documento asociado' : ''}`}
//           onClick={() => openFile(fileLoaded(file) || savedFile)}
//         >
//           Ver
//         </CustomBtn>
//       }
//     />
//   );
// };

export default InsurancePolicyFormSummary;
