import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../../services/yupCustomMethods';
import { BasicTextArea, FormikInput, FormikNumberFormat } from '../../Utils/Input';
import { rutFormat, validRutInput, handleFormatTextToLowerCase } from '../../../services/utils';

const RequestRegisterForm = ({ errors, touched, setFieldValue }) => {
  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="register[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre y apellido"
                placeholder="Ej. Neil Armstrong"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="register[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                placeholder="00.000.000-0"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText="RUT de la persona natural o<br/>
                             jurídica corredora de seguros"
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="register[phone]">
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="Teléfono"
                format="+56 # #### ####"
                onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="register[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                placeholder="usuario@correo.com"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="register[message]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Mensaje"
                placeholder="Escribe aquí tu mensaje"
                rows={3}
                className="p-3"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="mt-2 d-flex justify-content-end">
        <Col md={4} className="mt-4">
          <Button block type="submit" variant="submit" className="no-shadow">
            Solicitar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ register }) => ({ register });

const validationSchema = Yup.object().shape({
  register: Yup.object().shape({
    email: Yup.string()
      .required('Debes ingresar tu correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    name: Yup.string().required('Debes ingresar tu nombre'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar tu RUT')
      .rut('El RUT es inválido'),
    phone: Yup.string().required('Debes ingresar tu teléfono')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(RequestRegisterForm);
