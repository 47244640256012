import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns, ReadMore } from '../../../components';
import { StepDaysLabel } from '../../../components/Shared';
import { DataTableBadges, DatatableMainInfo } from '../../../components/Utils/DataTable';
import { DatatableTaskCreator, basicTask } from '../../../components/Task';

const columns = memoize(
  (
    step,
    { setMoreData = () => null },
    {
      handleBasicActions,
      modelUrl,
      withShow = false,
      withEdit = false,
      withDestroy = false,
      showClick = false,
      editClick = false
    }
  ) => {
    const isClosed = step === 'closed';

    const leadColumns = [
      {
        name: 'Cuenta',
        selector: 'account',
        sortable: true,
        grow: '2',
        minWidth: '150px',
        cell: ({ accountName, accountNationalIdentification, accountLegalPersonType }) => (
          <DatatableMainInfo
            icon={accountLegalPersonType.includes('natural') ? 'user' : 'organisation'}
            title={accountName}
            subtitle={accountNationalIdentification}
            iconSize="sm"
          />
        )
      },
      {
        name: 'Corredor',
        selector: 'insurance_broker',
        sortable: true,
        grow: '1.5',
        cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
          <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
        )
      }
    ];

    if (isClosed) {
      leadColumns.push(
        {
          name: 'F. cierre',
          selector: 'close_date',
          sortable: true,
          grow: '1',
          cell: ({ closeDate }) => closeDate
        },
        {
          name: 'Compañía',
          selector: 'insurance_company_name',
          sortable: true,
          grow: '1',
          cell: ({ insuranceCompanyName, insuranceProposalNumber }) => (
            <DatatableMainInfo noIcon title={insuranceCompanyName} subtitle={insuranceProposalNumber} />
          )
        }
      );
    }

    leadColumns.push(
      {
        name: 'Ramo',
        selector: 'insurance_category',
        sortable: true,
        grow: '1',
        cell: ({ insuranceCategoryName }) => insuranceCategoryName
      },
      {
        name: 'Materia',
        selector: 'subject_matter',
        sortable: true,
        grow: '1',
        cell: ({ subjectMatter }) => (
          <ReadMore more="+" title="Materia">
            {subjectMatter}
          </ReadMore>
        )
      },
      {
        name: 'Tareas',
        selector: 'tasks',
        sortable: false,
        grow: '1',
        minWidth: '130px',
        cell: ({ task = basicTask, id: leadId }) => (
          <DatatableTaskCreator
            shortBtn
            fromAdmin
            task={task}
            taskableType="Lead"
            taskableId={leadId}
            setMoreData={setMoreData}
          />
        )
      },
      {
        name: 'Días de plazo',
        selector: 'deadline_days',
        sortable: true,
        grow: '1',
        cell: ({ deadlineDays, validityStart }) => (
          <DatatableMainInfo noIcon title={deadlineDays} subtitle={validityStart} />
        )
      },
      {
        name: 'Días en la etapa',
        selector: 'step_days',
        sortable: true,
        grow: '0.5',
        cell: lead => <StepDaysLabel days={lead[`${lead.currentStep}StepDays`]} />
      },
      {
        cell: row => <DataTableBadges row={row} fromAdmin />,
        sortable: false,
        right: true,
        grow: '1.5'
      },
      {
        cell: item => (
          <BasicActionBtns
            item={item}
            clickHandler={handleBasicActions}
            path={modelUrl}
            withShow={withShow}
            withEdit={withEdit}
            withDestroy={withDestroy}
            showClick={showClick}
            editClick={editClick}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        grow: '1',
        minWidth: '150px'
      }
    );

    return leadColumns;
  }
);

export default columns;
