import React from 'react';

import { HeaderFilter } from '../../../components';
import {
  indexInsuranceBrokerRequest,
  debounceIndexInsuranceBrokeresRequest
} from '../../../requests/admin/insuranceBrokers';
import { accountAdvancedOptions, accountTabsOptions } from './AccountFiltersParams';
import { debounceIndexAdminUsersRequest, indexAdminUserRequest } from '../../../requests/admin/adminUsers';

const AdminAccountFilterHeader = ({ customParams, setCustomParams }) => {
  const adminSelectorOptions = [
    {
      fieldName: 'executiveId',
      placeholder: 'Ejecutivo',
      requestParams: { sortColumn: 'email', for_executives: 'present', admins: 'present', active: 'present' },
      indexRequest: indexAdminUserRequest,
      debounceRequest: debounceIndexAdminUsersRequest
    },
    {
      fieldName: 'insuranceBrokerId',
      placeholder: 'Corredor',
      indexRequest: indexInsuranceBrokerRequest,
      debounceRequest: debounceIndexInsuranceBrokeresRequest
    }
  ];

  const selectorOptions = [...adminSelectorOptions];

  return (
    <HeaderFilter
      paramName="clientType"
      tabOptions={accountTabsOptions}
      remoteSelectors={selectorOptions}
      advancedOptions={accountAdvancedOptions}
      customParams={customParams}
      setCustomParams={setCustomParams}
    />
  );
};

export default AdminAccountFilterHeader;
