import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { ButtonTooltip } from '../Tooltips';

const DataTableTextArea = ({
  id,
  attribute,
  defaultValue,
  horizontalResize,
  inputType,
  maxLength,
  noResize,
  placeholder,
  rows,
  tooltipHtml,
  tooltipText,
  updateDataTable,
  updateRequest,
  verticalResize,
  ...props
}) => {
  const resize = (noResize && 'no') || (horizontalResize && 'horizontal') || (verticalResize && 'vertical') || 'both';

  return (
    <InputGroup className="datatable-textarea">
      <Form.Control
        as="textarea"
        type={inputType}
        maxLength={maxLength}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={({ target }) => updateRequest({ id, attribute, value: target.value, updateDataTable })}
        className={`textarea-${resize}-resize ${tooltipText ? 'with-tooltip' : ''}`}
        rows={rows}
        {...props}
      />
      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
    </InputGroup>
  );
};

export default DataTableTextArea;
