import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';
import { InputButtons } from '../../../../components';
import { insuredTypeOptions } from './typeOptions';

const InsuredFields = ({ errors, fromProfileForm, setFieldValue, touched, values }) => {
  return (
    <>
      <Col md={12} className="my-5">
        <section className="insurance-broker-fields usertypes">
          <p className="section-title">Información de usuario</p>
          <Row>
            {!fromProfileForm && (
              <Col md={12}>
                <InputButtons
                  values={values}
                  label="Tipo de asegurado"
                  optionsArray={insuredTypeOptions}
                  modelName="user[insuredType]"
                  setFieldValue={setFieldValue}
                  error={getIn(errors, 'user[insuredType]')}
                  touched={getIn(touched, 'user[insuredType]')}
                />
              </Col>
            )}
          </Row>
        </section>
      </Col>
    </>
  );
};
export default InsuredFields;
