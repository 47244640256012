import React from 'react';
import { Row } from 'react-bootstrap';

const BrokerLandingNumbers = () => {
  return (
    <section id="numbers" className="broker-landing__horizontal-info__number">
      <h4 className="broker-landing__horizontal-info__title landing-section-title">
        Súmate a NICO y juntos protejamos a más personas
      </h4>

      <Row>
        <div className="col-md-12 col-lg-4">
          <div className="broker-landing__horizontal-info__number__card ">
            <h2 className="broker-landing__horizontal-info__number__card__titlecard">+15.000</h2>
            <p className="broker-landing__horizontal-info__number__card__textcard">
              Personas <br />
              protegidas{' '}
            </p>
          </div>
        </div>

        <div className="col-md-12 col-lg-4">
          <div className="broker-landing__horizontal-info__number__card ">
            <h2 className="broker-landing__horizontal-info__number__card__titlecard">+900</h2>
            <p className="broker-landing__horizontal-info__number__card__textcard">
              Pólizas vigentes <br />
              en operación{' '}
            </p>
          </div>
        </div>

        <div className="col-md-12 col-lg-4">
          <div className="broker-landing__horizontal-info__number__card ">
            <h2 className="broker-landing__horizontal-info__number__card__titlecard">+100.000</h2>
            <p className="broker-landing__horizontal-info__number__card__textcard">
              UF de cartera bajo <br />
              administración{' '}
            </p>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default BrokerLandingNumbers;
