import AwesomeDebouncePromise from 'awesome-debounce-promise';

import ApiService from '../../services/apiService';

export const indexInsuredInsuranceBrokerRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insured/insurance_brokers', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexInsuredInsuranceBrokerRequest = AwesomeDebouncePromise(
  indexInsuredInsuranceBrokerRequest,
  1000
);
