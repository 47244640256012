import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikSelect } from '../../../Utils/Select';

const SelectField = ({ keyValue, details, modelName, errors, touched, optional }) => {
  const { setFieldValue, values } = useFormikContext();
  const { title: label, placeholder, required, tooltip, options, selected_option: selectedOption } = details;

  const adaptedOptions = options.map(option => ({
    label: option,
    value: option
  }));

  const defaultValue = getIn(values, `${modelName}[${keyValue}]`);
  return (
    <Col key={keyValue} xs={12} md={6} className="mb-4 pl-5">
      <Field name={`${modelName}[${keyValue}]`}>
        {({ field }) => (
          <FormikSelect
            abbr={optional ? false : required}
            label={label}
            placeholder={placeholder || label}
            defaultValue={defaultValue || selectedOption}
            options={adaptedOptions}
            error={getIn(errors, field.name)}
            value={defaultValue || selectedOption}
            onChange={option => {
              setFieldValue(field.name, option?.value || '');
            }}
            tooltipText={tooltip}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
    </Col>
  );
};

export default SelectField;
