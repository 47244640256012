import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import AdminInsuranceCompanyForm from './AdminInsuranceCompanyForm';
import {
  showAdminInsuranceCompanyRequest,
  updateAdminInsuranceCompanyRequest
} from '../../../requests/admin/insuranceCompanies';
import basicInsuranceCompany from './basicInsuranceCompany';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';

const AdminInsuranceCompanyEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/insurance_companies';
  const [insuranceCompany, setInsuranceCompany] = useState(basicInsuranceCompany);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Aseguradoras', href: modelUrl },
    { key: 2, name: 'Editar aseguradora' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Aseguradora actualizada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const { logo } = values.insuranceCompany;
    const sendParams = snakeCaseKeys(values);
    sendParams.insurance_company.logo = logo;
    updateAdminInsuranceCompanyRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchInsuranceCompany = () => {
    showAdminInsuranceCompanyRequest(id, {
      dispatch,
      successCallback: response => {
        const data = camelCaseEmptyStringRecursive(response.data);
        setInsuranceCompany({ ...data, logo: data.logoInfo });
      },
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchInsuranceCompany, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <AdminInsuranceCompanyForm
          action="edit"
          insuranceCompany={insuranceCompany}
          formRequest={handleUpdateRequest}
        />
      }
    />
  );
};

export default AdminInsuranceCompanyEdit;
