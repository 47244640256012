import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import InsuranceEndorsementPolicySummary from './InsuranceEndorsementPolicySummary';
import InsuranceEndorsementItemsChanges from './InsuranceEndorsementItemsChanges';

const InsuranceEndorsementSecondStep = ({ insurancePolicy, modelName, DocumentModal }) => {
  const { values, setFieldValue } = useFormikContext();

  const {
    affectCommissionPercentage,
    affectPremium,
    commissionTotalAmount,
    exemptCommissionPercentage,
    exemptPremium
  } = insurancePolicy;

  const {
    endorsementAction,
    endorsementType,
    insuranceItemAttributes: insuranceItem,
    modificationType = '',
    variationAffectPremium,
    variationExemptPremium
  } = values.insuranceEndorsement;

  const contractModification =
    ['modification'].includes(endorsementType) && ['additional_clauses'].includes(modificationType);
  const itemModification = ['modification'].includes(endorsementType) && ['item'].includes(modificationType);
  const isActionItem = ['modify', 'remove'].includes(endorsementAction);
  const hasItemAttributes = itemModification || isActionItem;

  const calculateVariationCommissionTotalAmount = () => {
    const affectPremiumToF = parseFloat(affectPremium) + variationAffectPremium;
    const exemptPremiumToF = parseFloat(exemptPremium) + variationExemptPremium;
    const affectCommissionPercentageToF = parseFloat(affectCommissionPercentage);
    const exemptCommissionPercentageToF = parseFloat(exemptCommissionPercentage);

    const affectCommission = affectPremiumToF * (affectCommissionPercentageToF / 100);
    const exemptCommission = exemptPremiumToF * (exemptCommissionPercentageToF / 100);

    const newCommissionTotalAmount = affectCommission + exemptCommission;
    const variationCommissionTotalAmount = newCommissionTotalAmount - commissionTotalAmount;
    setFieldValue('insuranceEndorsement[variationCommissionTotalAmount]', variationCommissionTotalAmount);
  };

  const buildMonetaryInfoObject = (acc, curr) => {
    const coverageAttributeName = curr.coverageType === 'affect' ? 'variationAffectPremium' : 'variationExemptPremium';
    const variationNetPremiumToF = parseFloat(curr.variationNetPremium);
    const variationTotalNetPremiumToF = parseFloat(acc.variationTotalNetPremium);
    return {
      ...acc,
      [coverageAttributeName]: acc[coverageAttributeName] + (variationNetPremiumToF || 0),
      variationTotalNetPremium: variationTotalNetPremiumToF + (variationNetPremiumToF || 0)
    };
  };

  const handleItemMonetaryInfo = variations => {
    setFieldValue(modelName, { ...values.insuranceEndorsement, ...variations });
  };

  const updatePolicyMonetaryInfo = () => {
    // eslint-disable-next-line no-useless-return
    if (
      ['cut', 'rehabilitation'].includes(endorsementType) ||
      endorsementAction === 'extension' ||
      contractModification
    )
      return;
    if (endorsementAction === 'remove') {
      handleItemMonetaryInfo({
        variationAffectPremium: insuranceItem.variationAffectPremium,
        variationExemptPremium: insuranceItem.variationExemptPremium,
        variationTotalNetPremium: insuranceItem.variationTotalNetPremium
      });
      return;
    }
    if (endorsementAction === 'add') {
      handleItemMonetaryInfo({
        variationAffectPremium: insuranceItem.affectPremium,
        variationExemptPremium: insuranceItem.exemptPremium,
        variationTotalNetPremium: insuranceItem.affectPremium + insuranceItem.exemptPremium
      });
      return;
    }
    const { insuranceCoversAttributes } = insuranceItem;
    const baseMonetaryObject = { variationAffectPremium: 0, variationExemptPremium: 0, variationTotalNetPremium: 0 };
    const monetaryInfoObject = insuranceCoversAttributes.reduce(buildMonetaryInfoObject, baseMonetaryObject);
    handleItemMonetaryInfo({ ...monetaryInfoObject });
  };

  useEffect(updatePolicyMonetaryInfo, []);
  useEffect(calculateVariationCommissionTotalAmount, [variationAffectPremium, variationExemptPremium]);

  return (
    <>
      {DocumentModal()}
      <InsuranceEndorsementPolicySummary insurancePolicy={insurancePolicy} />
      {hasItemAttributes && <InsuranceEndorsementItemsChanges insuranceItem={insuranceItem} />}
    </>
  );
};

export default InsuranceEndorsementSecondStep;
