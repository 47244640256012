import ApiService from '../services/apiService';

export const showLeadQuotationRequest = (id, { dispatch, params = {}, successCallback, callback, source }) =>
  ApiService.request('get', `/lead_quotations/${id}`, {
    dispatch,
    params,
    successCallback,
    callback,
    source
  });

export const sendQuotationLeadQuotationRequest = (id, { dispatch, params, successCallback, callback, source }) =>
  ApiService.request('post', `/lead_quotations/${id}/send_quotation`, {
    dispatch,
    params,
    successCallback,
    callback,
    source
  });
