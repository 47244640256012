const basicInsuranceCompanyLogo = {
  id: '',
  active: true,
  altInfo: '',
  displayOrder: '',
  logo: {},
  premiumPaymentLink: '',
  websiteLink: ''
};

export default basicInsuranceCompanyLogo;
