import React from 'react';
import { Table } from 'react-bootstrap';
import SelectQuotation from '../SelectQuotation';

const LeadQuotationTable = ({
  lead,
  fromAdmin,
  setSelectedQuotation,
  setSelectedQuotations,
  selectedQuotations,
  setSelectedFile,
  setMoreData,
  canCloseQuotation,
  modelUrl
}) => (
  <Table size="sm">
    <thead>
      <tr>
        <th />
        <th />
        <th>Afecta</th>
        <th>Exenta</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      {lead.leadQuotations.map(item => (
        <SelectQuotation
          key={item.id}
          lead={lead}
          fromAdmin={fromAdmin}
          currentLeadQuotation={item}
          setSelectedQuotation={setSelectedQuotation}
          setSelectedQuotations={setSelectedQuotations}
          isSelected={selectedQuotations.find(q => q.id === item.id)}
          setSelectedFile={setSelectedFile}
          setMoreData={setMoreData}
          canCloseQuotation={canCloseQuotation}
          indexPath={modelUrl}
        />
      ))}
    </tbody>
  </Table>
);

export default LeadQuotationTable;
