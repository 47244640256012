import ApiService from '../../services/apiService';

export default function contractDebtRequest({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) {
  ApiService.request('post', '/admin/bulk_uploads/debt_contract', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });
}
