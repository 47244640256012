const customStyles = {
  headRow: {
    style: {
      border: 'none'
    }
  },
  headCells: {
    style: {
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: '500',
      padding: '0',
      textAlign: 'center'
    }
  },
  rows: {
    style: {
      backgroundColor: '#F4F5F6',
      border: '1px solid transparent',
      borderRadius: '100px',
      marginBottom: '1.75rem',
      cursor: 'pointer',
      transition: '.3s',
      '&:not(:last-child)': {
        borderBottom: '1px solid #FFF'
      }
    },
    highlightOnHoverStyle: {
      backgroundColor: '#F4F5F6',
      border: '1px solid'
    }
  },
  cells: {
    style: {
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: '500 !important',
      padding: '0'
    }
  },
  pagination: {
    style: {
      border: 'none',
      display: 'flex',
      justifyContent: 'center'
    }
  }
};

export default customStyles;
