import ApiService from '../../services/apiService';

export const showPublicLeadQuotationRequest = (
  acceptanceKey,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/public/lead_quotations/${acceptanceKey}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updatePublicLeadQuotationRequest = (
  acceptanceKey,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/public/lead_quotations/${acceptanceKey}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });
