import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';

import { IconBtn, SimpleCenteredModal } from '..';
// import { StepMap } from '../components/Shared';
import {
  InsuranceEndorsementFormAdd,
  InsuranceEndorsementFormCut,
  InsuranceEndorsementFormFooter,
  InsuranceEndorsementFormHeader,
  InsuranceEndorsementFormModification,
  InsuranceEndorsementFormModify,
  InsuranceEndorsementFormType
} from './Form';
import InsuranceEndorsementSecondStep from './Form/InsuranceEndorsementSecondStep';
import { insuranceItemSchemaByCase } from './Form/InsuranceItemAttributesSchemas';
import InsuranceEndorsementDocumentForm from './Form/InsuranceEndorsementDocumentForm';
import { StepMap } from '../Shared';

const InsuranceEndorsementForm = ({
  action,
  errors,
  isSubmitting,
  setFieldValue,
  touched,
  values,
  isValid,
  handleSubmit,
  validateForm,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const modelName = 'insuranceEndorsement';

  const { insurancePolicy, originPath } = props;
  const { isAnnulled, isCancelled } = insurancePolicy;
  const {
    endorsementAction,
    endorsementType,
    insuranceItemId,
    modificationType,
    originalInsuranceItem
  } = values.insuranceEndorsement;

  const [step, setStep] = useState(1);
  const isFirstStep = step === 1;
  const isLastStep = step === 2;

  const proposalMessage = action === 'edit' ? 'Editar propuesta' : 'Crear propuesta';
  const submitText = isLastStep ? proposalMessage : 'Siguiente';

  // InsuranceEndorsementFormCut:
  const cutOne = ['cut'].includes(endorsementType) && ['cancellation', 'annulment'].includes(endorsementAction);
  const cutTwo = ['decrease'].includes(endorsementType) && ['remove'].includes(endorsementAction) && insuranceItemId;
  const cutThree = ['increase'].includes(endorsementType) && ['extension'].includes(endorsementAction);
  const cutFour = ['rehabilitation'].includes(endorsementType) && !endorsementAction && (isCancelled || isAnnulled);
  const isFormCut = cutOne || cutTwo || cutThree || cutFour;
  // InsuranceEndorsementFormModify:
  const isFormModify =
    ['decrease', 'increase'].includes(endorsementType) && ['modify'].includes(endorsementAction) && insuranceItemId;
  // InsuranceEndorsementFormAdd:
  const isFormAdd = ['increase'].includes(endorsementType) && ['add'].includes(endorsementAction);
  // InsuranceEndorsementFormModification:
  const isContractModification = modificationType === 'additional_clauses';
  const modificationItem = insuranceItemId || isContractModification;
  const isFormModification = ['modification'].includes(endorsementType) && !endorsementAction && modificationItem;

  const firstStepIsValid = () => {
    const endorsementErrors = errors?.insuranceEndorsement;
    if (endorsementErrors) {
      const errorKeys = Object.keys(endorsementErrors);
      const hasOnlyTitleAndDescription =
        errorKeys.length === 2 && errorKeys.includes('title') && errorKeys.includes('description');
      return hasOnlyTitleAndDescription;
    }
    return true;
  };

  const toggleStep = ({ back = false }) => {
    const limitStep = back ? 1 : 2;
    if (isFirstStep) {
      validateForm().then(() => {
        if (firstStepIsValid) setStep(limitStep);
      });
      return;
    }
    setStep(limitStep);
  };

  return (
    <Form>
      <StepMap steps={[1, 2]} currentStep={step} />
      {step === 1 && (
        <>
          <InsuranceEndorsementFormHeader insurancePolicy={insurancePolicy} />
          <InsuranceEndorsementFormType modelName={modelName} insurancePolicy={insurancePolicy} />

          {isFormCut && <InsuranceEndorsementFormCut insurancePolicy={insurancePolicy} />}
          {isFormModification && (
            <InsuranceEndorsementFormModification
              isContractModification={isContractModification}
              modelName={modelName}
              insurancePolicy={insurancePolicy}
            />
          )}
          {isFormModify && (
            <InsuranceEndorsementFormModify
              originalInsuranceItem={originalInsuranceItem}
              insurancePolicy={insurancePolicy}
            />
          )}
          {isFormAdd && <InsuranceEndorsementFormAdd modelName={modelName} insurancePolicy={insurancePolicy} />}

          {(isFormCut || isFormModification || isFormModify || isFormAdd) && (
            <InsuranceEndorsementFormFooter modelName={modelName} />
          )}
        </>
      )}

      {step === 2 && (
        <InsuranceEndorsementSecondStep
          insurancePolicy={insurancePolicy}
          isFormCut={isFormCut}
          modelName={modelName}
          DocumentModal={() => (
            <SimpleCenteredModal
              title="Documento de endoso"
              show={showModal}
              body={
                <InsuranceEndorsementDocumentForm
                  modelName={modelName}
                  insurancePolicy={insurancePolicy}
                  onHide={() => setShowModal(false)}
                />
              }
              onHide={() => setShowModal(false)}
            />
          )}
        />
      )}

      <Row className="mt-5">
        <Col md={4} lg={3} className="mt-4">
          {!isFirstStep && (
            <Button block variant="cancel" onClick={() => toggleStep({ back: true })}>
              Volver
            </Button>
          )}
        </Col>
        <Col md={8} lg={9}>
          <Row className="d-flex flex-row-reverse align-items-center">
            <Col md={6} lg={4} className="mt-4">
              <Button
                disabled={!endorsementType || !firstStepIsValid(errors) || isSubmitting}
                block
                variant="submit"
                onClick={isLastStep ? () => setShowModal(true) : toggleStep}
              >
                {submitText}
              </Button>
            </Col>
            <Col md={5} lg={3} className="mt-4">
              <IconBtn block variant="cancel" to={originPath}>
                Cancelar
              </IconBtn>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ insuranceEndorsement }) => ({ insuranceEndorsement });

const validationSchema = Yup.object().shape({
  insuranceEndorsement: Yup.object().shape({
    title: Yup.string()
      .max(200, 'El título no puede tener más de 200 caracteres')
      .required('El título es obligatorio'),
    description: Yup.string()
      .max(500, 'La descripción no puede tener más de 500 caracteres')
      .required('La descripción es obligatoria'),
    additionalClauses: Yup.string().when(
      ['endorsementType', 'modificationType'],
      (endorsementType, modificationType) => {
        if (['modification'].includes(endorsementType)) {
          const isContractModification = ['additional_clauses'].includes(modificationType);
          if (isContractModification) return Yup.string().required('Debes ingresar cláusulas adicionales');
        }
        return Yup.string().nullable();
      }
    ),
    approval: Yup.mixed().required('Debes adjuntar una aprobacion'),
    comments: Yup.string().nullable(),
    endorsementAction: Yup.string().nullable(),
    endorsementDate: Yup.string().when('endorsementType', {
      is: currentType => ['cut', 'decrease', 'increase', 'modification'].includes(currentType),
      then: Yup.string().required('Debes ingresar una fecha'),
      otherwise: Yup.string().nullable()
    }),
    endorsementType: Yup.string().required('Debes ingresar un tipo'),
    insuranceItemId: Yup.string().when('endorsementType', {
      is: currentType => ['decrease', 'modification'].includes(currentType),
      then: Yup.string().required('Debes seleccionar un ítem'),
      otherwise: Yup.string().nullable()
    }),
    insuranceItemAttributes: Yup.object().when(
      ['endorsementType', 'endorsementAction', 'modificationType'],
      (endorsementType, endorsementAction, modificationType, insuranceItemAttributesSchema) =>
        insuranceItemSchemaByCase({
          endorsementType,
          endorsementAction,
          modificationType,
          insuranceItemAttributesSchema
        })
    ),
    totalNetPremium: Yup.number().when(['endorsementType', 'endorsementAction'], {
      is: (endorsementType, endorsementAction) =>
        ['cut'].includes(endorsementType) && ['cancellation'].includes(endorsementAction),
      then: Yup.number()
        .required('Debes ingresar una prima total neta')
        .min(0, 'La prima total neta debe ser mayor o igual a 0'),
      otherwise: Yup.number().nullable()
    })
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(InsuranceEndorsementForm);
