import React from 'react';
import { Table } from 'react-bootstrap';

const InfoCardInsuranceItem = ({ insuranceItem, itemNumber = '', coverColumns, dictionaryDetails = [] }) => {
  const { insuranceCoversAttributes = [], name = '', notes = '' } = insuranceItem || {};
  const hasNotes = notes.length > 0;

  const shouldShowDetail = detail => {
    const { showif } = detail;
    if (!showif) return true;

    const { id, selectedOption } = showif;
    const selectedValue = insuranceItem[id];
    return selectedValue && selectedOption.includes(selectedValue);
  };

  return (
    <React.Fragment key={`insurance-item-${itemNumber.toString()}`}>
      <p className="section-title mx-3">
        Ítem {itemNumber}: <span className="font-weight-normal">{name}</span>
      </p>

      <div className="mb-3">
        {dictionaryDetails.map(detail => {
          const { id, title } = detail;
          const detailValue = insuranceItem[id];
          return detailValue && shouldShowDetail(detail) ? (
            <p key={id} className="mx-3">
              <b>{title}</b>: <span>{detailValue}</span>
            </p>
          ) : null;
        })}
      </div>
      <p className="mx-3" style={{ whiteSpace: 'pre-wrap' }}>
        {hasNotes ? notes : 'Sin notas para este ítem'}
      </p>
      <div className="info-card bg-primary-var-light-blue border-0 pl-5 mt-4 mb-5">
        <p className="section-title">
          Coberturas <span className="small">( {insuranceCoversAttributes.length} )</span>
        </p>
        <Table size="sm" responsive borderless className="table__insurance-covers">
          <InsuranceCoverTableHeader columns={coverColumns} />
          <InsuranceCoverTableBody insuranceCovers={insuranceCoversAttributes} columns={coverColumns} />
        </Table>
      </div>
    </React.Fragment>
  );
};

const InsuranceCoverTableHeader = ({ columns }) => (
  <thead>
    <tr>
      {!columns && (
        <>
          <th style={{ width: '50px' }}>N°</th>
          <th>Detalle cobertura</th>
          <th style={{ width: '200px' }}>Monto asegurado</th>
          <th style={{ width: '100px' }}>Tasa</th>
          <th style={{ width: '150px' }}>Prima neta</th>
          <th style={{ width: '100px' }}>Tipo</th>
          <th style={{ width: '100px' }}>Deducible</th>
        </>
      )}
      {columns &&
        columns.map(({ columnName, columnStyle }, index) => (
          <th style={columnStyle} key={columnName.concat('-', index + 1)}>
            {columnName}
          </th>
        ))}
    </tr>
  </thead>
);

const InsuranceCoverTableBody = ({ insuranceCovers, columns }) => (
  <tbody>
    {insuranceCovers.map((insuranceCover, index) => (
      <tr key={`insurance-cover-${index.toString()}`}>
        {columns &&
          columns.map(({ value, selector, columnName }, coverIndex) => (
            <td className="align-middle" key={columnName.concat('-', coverIndex + 1)}>
              {value ?? insuranceCover[selector]}
            </td>
          ))}
        {!columns && (
          <>
            <td className="align-middle">{index + 1}</td>
            <td className="align-middle">{insuranceCover.name}</td>
            <td className="align-middle">{insuranceCover.parsedAmount}</td>
            <td className="align-middle">{insuranceCover.parsedFee}</td>
            <td className="align-middle">{insuranceCover.parsedNetPremium}</td>
            <td className="align-middle">{insuranceCover.coverageTypeLabel}</td>
            <td className="align-middle">{insuranceCover.parsedDeductible}</td>
          </>
        )}
      </tr>
    ))}
  </tbody>
);

export default InfoCardInsuranceItem;
