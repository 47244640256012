import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminBlogRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/admin/blogs/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminBlogsRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/admin/blogs${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdminBlogRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/admin/blogs', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminBlogRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/blogs/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminBlogRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/admin/blogs/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminBlogsRequest = AwesomeDebouncePromise(indexAdminBlogsRequest, 300);
