import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';

import { FormikInput, FormikSwitch } from '../../../components';

const AdminPaymentPlanForm = ({ action, errors, onHide, submitVariant, touched }) => {
  const submitBtnText = action === 'new' ? 'Crear plan de pago' : 'Guardar cambios';

  return (
    <Form>
      <Row>
        <Col xs={12}>
          <Field name="paymentPlan[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Plan de pago activo?" />}
          </Field>
        </Col>
        <Col md={8}>
          <Field name="paymentPlan[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name="paymentPlan[installments]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Cuotas"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end">
        <Col md={6} lg={4} xl={3} className="my-5">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ paymentPlan }) => ({ paymentPlan });

const validationSchema = Yup.object().shape({
  paymentPlan: Yup.object().shape({
    installments: Yup.string().required('Debes ingresar una cantidad de cuotas'),
    name: Yup.string().required('Debes ingresar un nombre')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminPaymentPlanForm);
