import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { ComponentDataTable, SearchFilter } from '../../../../components';
import { useDestroyOne } from '../../../../hooks';
import { deleteAdminRegionRequest } from '../../../../requests/admin/regions';
import { emptyFalseParamsRecursive } from '../../../../services/utils';
import columns from './columns';

const AdminRegionDataTable = ({
  customParamsRef,
  handleIndexRequest,
  isFetching,
  modelUrl,
  moreData,
  setMoreData,
  tableData,
  tableDataAmount
}) => {
  const [customParams, setCustomParams] = useState({ query: '' });

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminRegionRequest,
    modelName: 'Región',
    nameEndsInA: true,
    setMoreData
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSetUpFilters = () => {
    const newParams = snakeCaseKeys(emptyFalseParamsRecursive(customParams));
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(!moreData);
  };

  useEffect(handleSetUpFilters, [customParams]);

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withEdit: true,
          withDestroy: true
        })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        customDatatableHeader={
          <Row>
            <Col md={{ span: 6, offset: 6 }} lg={{ span: 4, offset: 8 }} className="pt-4 pl-md-5">
              <SearchFilter customParams={customParams} setCustomParams={setCustomParams} />
            </Col>
          </Row>
        }
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default AdminRegionDataTable;
