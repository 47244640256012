import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminInsuranceCompanyLogosRequest } from '../../../requests/admin/insuranceCompanyLogos';
import InsuranceCompanyLogoDataTable from './InsuranceCompanyLogoDataTable';

const InsuranceCompanyLogoIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: insuranceCompanyLogos,
    totalData: totalInsuranceCompanyLogo,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminInsuranceCompanyLogosRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los logos. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/insurance_company_logos';

  return (
    <DefaultHeader
      title="Logo de Aseguradoras"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Logo',
        path: `${modelUrl}/new`
      }}
    >
      <InsuranceCompanyLogoDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={insuranceCompanyLogos}
        tableDataAmount={totalInsuranceCompanyLogo}
      />
    </DefaultHeader>
  );
};

export default InsuranceCompanyLogoIndex;
