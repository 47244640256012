import React from 'react';

import { LeadRenewalIndexBody } from '../../../../components/LeadRenewal';

const AdminLeadRenewalIndex = () => {
  const renewableUrl = '/admin/renewals';
  const extendableUrl = '/admin/insurance_policies';

  return <LeadRenewalIndexBody fromAdmin renewableUrl={renewableUrl} extendableUrl={extendableUrl} />;
};

export default AdminLeadRenewalIndex;
