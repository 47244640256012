import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexAccountRequest = ({ dispatch, params = {}, successCallback, failureCallback, callback, source }) =>
  ApiService.request('get', '/accounts', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showAccountRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/accounts/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAccountRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/accounts', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAccountRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/accounts/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAccountRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('delete', `/accounts/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const accountPoliciesSummaryRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('get', `/accounts/${id}/policies_summary`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexAccountRequest = AwesomeDebouncePromise(indexAccountRequest, 1000);
export const debounceUpdateAccountRequest = AwesomeDebouncePromise(updateAccountRequest, 1000);
export const debouncedAccountPoliciesSummaryRequest = AwesomeDebouncePromise(accountPoliciesSummaryRequest, 1000);
