import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Form } from 'react-bootstrap';

const FormikCheckBox = ({
  abbr,
  label,
  margin,
  field,
  toolbarVariant,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  onClick,
  optional = false,
  ...props
}) => (
  <>
    <Form.Group onClick={onClick} className={`d-flex align-items-center form-checkbox ${margin}`}>
      <Form.Check {...field} custom type="checkbox" checked={field.value} id={field.name} {...props} />
      {label && (
        <Form.Label
          data-tip
          data-for={`registerTip-checkbox-${tooltipText}`}
          htmlFor={field.name}
          className={`m-0 ${field.value ? 'checked' : ''}`}
        >
          <p className="font-weight-500 m-0">
            {label} {optional && <span className="text-muted">(Opcional)</span>}
          </p>
        </Form.Label>
      )}
      {tooltipText && (
        <ReactTooltip
          id={`registerTip-checkbox-${tooltipText}`}
          delay={{ show: delayShow, hide: delayHide }}
          place={direction}
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </Form.Group>
  </>
);
FormikCheckBox.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikCheckBox.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};
export default FormikCheckBox;
