import React from 'react';
import memoize from 'memoize-one';
// import { BtnCheckbox } from '../../components';
import { DatatableMainInfo } from '../../../components/Utils/DataTable';
import TaskCompleteCheckbox from '../../../components/Task/TaskCompleteCheckbox';

const columns = memoize(({ setMoreData }) => {
  return [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: false,
      grow: '2',
      cell: ({ datatableMainComponentInfo: { icon, name, subtitle, secondarySubtitle } }) => (
        <DatatableMainInfo
          icon={icon}
          iconSize="lg"
          title={name}
          subtitle={subtitle}
          secondarySubtitle={secondarySubtitle}
        />
      )
    },
    {
      name: 'Creada el',
      selector: 'createdAt',
      sortable: false,
      grow: '1'
    },
    {
      name: 'F. Tarea',
      selector: 'dueDate',
      sortable: false,
      grow: '1'
    },
    {
      name: 'Descripción',
      selector: 'description',
      sortable: false,
      grow: '2',
      style: {
        justifyContent: 'flex-start'
      }
    },
    {
      name: '',
      selector: 'action-btn',
      sortable: false,
      grow: '1',
      cell: item =>
        // eslint-disable-next-line react/destructuring-assignment
        item.done ? (
          'Terminada'
        ) : (
          <TaskCompleteCheckbox fromAdmin task={item} setMoreData={setMoreData} label="Dar por terminada" />
        ),
      minWidth: '220px'
    }
  ];
});

export default columns;
