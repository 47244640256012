import React from 'react';
import { Col } from 'react-bootstrap';
import { getIn, useFormikContext } from 'formik';

import { useDebouncedValue, useDidMountEffect } from '../../../hooks';
import { emptyKeys, setFieldAttribute, sumArrayValues } from '../../../services/utils';
import { AccordionCard } from '../../Utils/Cards';
import { NestedAttributes } from '../../Utils/NestedAttributes';
import { basicInsuranceCover } from './basicInsuranceCover';
import InsuranceCoverForm from './InsuranceCoverForm';

const CoverNestedAttributes = ({ modelName, fromEndorsement, insurancePolicy }) => {
  const { setFieldValue, values } = useFormikContext();
  const { insuranceCoversAttributes } = getIn(values, modelName);
  const { currency } = insurancePolicy || values.insurancePolicy || values.lead;
  const debouncedInsuranceCovers = useDebouncedValue(insuranceCoversAttributes, 300);

  const currentInsuranceCovers = insuranceCoversAttributes.filter(insuranceCover => !insuranceCover._destroy);
  const lastInsuranceCoverObject = currentInsuranceCovers[currentInsuranceCovers.length - 1];
  const lastInsuranceCover = emptyKeys(lastInsuranceCoverObject, ['id']);

  const mapResults = insuranceCoversAttributes.map((body, index) => {
    if (body._destroy) return undefined;

    return (
      <Col>
        <InsuranceCoverForm
          key={`cover-${index.toString()}`}
          modelName={`${modelName}[insuranceCoversAttributes][${index}]`}
          fromEndorsement={fromEndorsement}
          insurancePolicy={insurancePolicy}
        />
      </Col>
    );
  });

  const handleDebouncedValues = () => {
    const premiums = { affectPremium: [], exemptPremium: [] };
    insuranceCoversAttributes.forEach(({ coverageType: type, _destroy, netPremium: premium }) => {
      if (_destroy || !type) return;
      if (type === 'affect') premiums.affectPremium.push(parseFloat(premium || 0));
      if (type === 'exempt') premiums.exemptPremium.push(parseFloat(premium || 0));
    });
    setFieldAttribute(
      modelName,
      'affectPremium',
      setFieldValue,
      sumArrayValues(premiums.affectPremium),
      ` ${currency}`
    );
    setFieldAttribute(
      modelName,
      'exemptPremium',
      setFieldValue,
      sumArrayValues(premiums.exemptPremium),
      ` ${currency}`
    );
  };

  useDidMountEffect(handleDebouncedValues, [debouncedInsuranceCovers]);

  return (
    <AccordionCard
      length={mapResults.length}
      title="Coberturas"
      body={
        <NestedAttributes
          isLinkBtn
          bottomBtnAddRow
          additionalNested
          splitLine={false}
          mapInputs={mapResults}
          arrayValues={insuranceCoversAttributes}
          setFieldValue={setFieldValue}
          valuePath={`${modelName}[insuranceCoversAttributes]`}
          newAttributes={{ ...basicInsuranceCover, ...lastInsuranceCover }}
          btnMessage="Agregar cobertura"
        />
      }
    />
  );
};

export default CoverNestedAttributes;
