import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { useFetchForRemoteSelect } from '../../hooks';
import { debounceIndexIndustryRequest, indexIndustryRequest } from '../../requests/industries';
import ContactAdd from '../../screens/Contact/ContactAdd';
import { validRutInput, rutFormat, sortResultLast } from '../../services/utils';
import DropzoneFileSection from '../Shared/DropzoneFileSection';
import { InputButtons, FormikInput, FormikCheckBox } from '../Utils/Input';
import { InputRemoteSelect } from '../Utils/Select';

const AccountFields = ({ values, errors, touched, action, userModule }) => {
  const { setFieldValue } = useFormikContext();

  const { additionalDocumentsInfo, contactsAttributes } = values.account;
  const legalPersonTypeArray = [{ value: 'natural' }, { label: 'jurídica', value: 'legal' }];
  const legalPersonTypeValue = getIn(values, 'account[legalPersonType]');
  const isNaturalPerson = legalPersonTypeValue === 'natural';
  const isCreating = action === 'new';

  const { options: industries, selectedOption, fetchOptions } = useFetchForRemoteSelect({
    indexRequest: indexIndustryRequest,
    debouncedIndexRequest: debounceIndexIndustryRequest,
    value: getIn(values, 'account[industryId]')
  });

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const showisNonWantedCheckBox = () => {
    return ['admin', 'broker'].includes(userModule);
  };

  return (
    <>
      <Row>
        <Col md={6} lg={4}>
          <InputButtons
            abbr
            values={values}
            optionsArray={legalPersonTypeArray}
            modelName="account[legalPersonType]"
            setFieldValue={setFieldValue}
            label="Tipo de cuenta"
            error={getIn(errors, 'account[legalPersonType]')}
            touched={getIn(touched, 'account[legalPersonType]')}
          />
        </Col>
        <Col md={6} lg={4}>
          <Field name="account[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                placeholder="Nombre de la cuenta"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} lg={4}>
          <Field name="account[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                placeholder="00.000.000-0"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                onChange={handleNationalIdentificationFormat}
              />
            )}
          </Field>
        </Col>
        <Col md={6} lg={4}>
          <Field name="account[industryId]">
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                abbr={!isNaturalPerson}
                isClearable
                label="Rubro"
                defaultOptions={industries.sort((a, b) => sortResultLast(a, b, 'Otros'))}
                value={selectedOption}
                request={fetchOptions}
                placeholder={isNaturalPerson ? 'No aplica' : 'Área de trabajo'}
                onChange={data => setFieldValue(field.name, data?.value || '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                disabled={isNaturalPerson}
              />
            )}
          </Field>
        </Col>
        {!isCreating && (
          <Col md={12} lg={8} className="d-flex mt-4">
            <span className="checkbox-group-label">Características opcionales</span>
            <Field name="account[isVip]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Vip" className="mt-3 mb-2" />}
            </Field>
            {showisNonWantedCheckBox() && (
              <Field name="account[isNonWantedClient]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="No deseado" className="mt-3 mb-2" />}
              </Field>
            )}
          </Col>
        )}
      </Row>

      <DropzoneFileSection
        multiple
        label="Documentos adicionales"
        modelName="account"
        originalObject={values.account}
        persistedFiles={additionalDocumentsInfo}
      />

      {isCreating && (
        <>
          <hr className="w-100 my-5" />
          <section className="contacts-form">
            <ContactAdd
              contactsAttributes={contactsAttributes}
              touched={touched}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
              parent="account"
              className="mt-5"
              userModule={userModule}
            />
          </section>
        </>
      )}
    </>
  );
};

export default AccountFields;
