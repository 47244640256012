export const insuredLeadParams = {
  insuranceCategoryId: '',
  step: 'quotation',
  stepNumber: 2
};

export const tabOptions = [
  // { label: 'Con cotización', value: 'with_quotation' },
  // { label: 'Sin cotización', value: 'without_quotation' },
  { label: 'Todas', value: '' }
];

export const advancedOptions = [];
