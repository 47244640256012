import moment from 'moment';

import basicInsuranceItem from '../../components/InsurancePolicies/Forms/basicInsuranceItem';
import { addDaysToDate } from '../../services/utils';
import basicInsuranceEndorsement from '../AdminScreens/InsuranceEndorsement/basicInsuranceEndorsement';
import basicAccount from '../Account/basicAccount';

const currentDate = moment().format('DD/MM/YYYY');
const nextYear = addDaysToDate(moment(), 365);

const basicInsurancePolicy = {
  id: '',
  acceptanceInfo: basicAccount,
  account: {},
  accountId: '',
  accountName: '',
  active: true,
  additionalClauses: '',
  additionalDocumentsInfo: [],
  adminComments: '',
  affectCommissionPercentage: '',
  affectPremium: '',
  beneficiaryPersonAddress: '',
  beneficiaryPersonName: '',
  beneficiaryPersonNationalIdentification: '',
  brokerCommissionAmount: '',
  brokerCommissionPercentage: '',
  cadenceType: 'unique',
  collectionEmail: '',
  comments: '',
  commissionPercentage: '',
  commissionTotalAmount: '',
  companyCommissionAmount: '',
  contractInfo: {},
  contractNumber: '',
  contractProposalInfo: {},
  currency: 'UF',
  exemptCommissionPercentage: '',
  exemptPremium: '',
  firstPaymentDate: '',
  grossInstallment: '',
  hiringPersonAddress: '',
  hiringPersonName: '',
  hiringPersonNationalIdentification: '',
  insuranceBrokerCompanyName: '',
  insuranceBrokerId: '',
  insuranceBrokerName: '',
  insuranceCategoryCategory: '',
  insuranceCategoryId: '',
  insuranceCategoryName: '',
  insuranceCompanyFullName: '',
  insuranceCompanyId: '',
  insuranceCompanyName: '',
  insuranceEndorsementsAttributes: [basicInsuranceEndorsement],
  insuranceItemsAttributes: [basicInsuranceItem],
  insuredPersonAddress: '',
  insuredPersonName: '',
  insuredPersonNationalIdentification: '',
  issueDate: '',
  leadProposal: {},
  nicoCode: 'yes',
  nicoCommissionAmount: '',
  nicoCommissionPercentage: '',
  parsedAffectCommissionPercentage: '',
  parsedBrokerCommissionPercentage: '',
  parsedExemptCommissionPercentage: '',
  parsedGrossInstallment: '',
  parsedNicoCommissionPercentage: '',
  paymentMethod: 'manual',
  paymentPlanId: '',
  paymentPlanName: '',
  policyNumber: '',
  policyType: '',
  proposalBody: '',
  proposalTitle: '',
  quotationInfo: {},
  renewalType: '',
  responsibleEmail: '',
  selectedLeadQuotation: {},
  subjectMatter: '',
  subjectMatterAddress: '',
  totalNetPremium: '',
  totalPremium: '',
  translatedCadenceType: 'Única',
  translatedNicoCode: 'Sí',
  translatedPaymentMethod: 'Manual',
  translatedRenewalType: '',
  validityEnd: nextYear,
  validityStart: currentDate
};

export default basicInsurancePolicy;
