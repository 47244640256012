import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconBtn } from '../Button';
import SidebarAccordion from './SidebarAccordion';

const AdminLandingSidebarLinks = ({ isActive, handleCloseSidebar }) => {
  const configAccordionItems = [
    {
      itemTitle: 'Landing Corredor',
      to: 'admin/broker_landing_settings'
    },
    {
      itemTitle: 'Landing Cliente',
      to: 'admin/client_landing_settings'
    },
    {
      itemTitle: 'Blog',
      to: 'admin/blogs'
    }
  ];

  return (
    <>
      <SidebarLink
        eventKey="home"
        icon="home-alt"
        optionTitle="Inicio"
        optionPath="/admin/home"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarAccordion
        accordionTitle="Configuraciones"
        accordionIcon="options"
        handleCloseSidebar={handleCloseSidebar}
        isActive={isActive}
        accordionItems={configAccordionItems}
      />
    </>
  );
};

const SidebarLink = ({ badge = false, badgeItem, icon, isActivePath, optionPath, optionTitle, ...props }) => (
  <Nav.Link as={Link} to={optionPath} active={isActivePath(optionPath)} {...props}>
    <span className="link-menu">
      <IconBtn icon={icon} variant="disabled" iconSize="sm" className="btn mr-4" />
      {optionTitle}
      {badge && <span className="link-menu--badge">{badgeItem}</span>}
    </span>
  </Nav.Link>
);

export default AdminLandingSidebarLinks;
