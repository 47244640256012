const insuredBasicAccount = {
  id: '',
  adminComments: '',
  clientType: '',
  comments: '',
  contactsAttributes: [
    {
      name: '',
      phoneNumber: '',
      email: '',
      position: '',
      addressAttributes: {
        address: '',
        RegionId: '',
        communeId: ''
      }
    }
  ],
  executiveManagerId: '',
  industry: {},
  industryId: '',
  insuranceBrokerId: '',
  isNonWantedClient: false,
  isVip: false,
  legalPersonType: 'natural',
  name: '',
  nationalIdentification: '',
  primaryContact: {},
  isInsuredUser: true
};

export default insuredBasicAccount;
