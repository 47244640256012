import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import snakeCaseKeys from 'snakecase-keys';
import PropTypes from 'prop-types';

import { removeFilters, sendAlert } from '../../actions/utils';
import { useDestroyOne } from '../../hooks';
import {
  deleteAdminPremiumCollectionRequest,
  debounceUpdateAdminPremiumCollectionRequest
} from '../../requests/admin/adminPremiumCollections';
import { emptyFalseParamsRecursive } from '../../services/utils';
import { conditionalRowStyles, customStyles } from '../Shared/DataTable';
import { ComponentDataTable } from '..';
import { createdFromOptions, initialFilterParams } from './attributeOptions';
import defaultColumns from './columns';
import PremiumCollectionFilters from '../PremiumCollection/PremiumCollectionFilters';
// import PremiumCollectionFilters from './PremiumCollectionFilters';

const CommissionCollectionDataTable = ({
  columns,
  customParamsRef,
  defaultParams,
  FilterHeaderComponent,
  filterHeaderComponentProps = {},
  handleIndexRequest,
  isFetching,
  moreData,
  noSubHeader,
  setMoreData,
  tableData,
  tableDataAmount,
  userModule
}) => {
  const dispatch = useDispatch();

  const [customParams, setCustomParams] = useState(filterHeaderComponentProps.filterParams || initialFilterParams);

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminPremiumCollectionRequest,
    modelName: 'Cobranza comisión',
    nameEndsInA: true,
    setMoreData
  });

  const handleUpdateRequest = ({ id, attribute, value, updateDataTable = false }) => {
    debounceUpdateAdminPremiumCollectionRequest(id, {
      dispatch,
      params: { premium_collection: { [attribute]: value } },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Actualizado con éxito' }));
        if (updateDataTable) setMoreData(!moreData);
      }
    });
  };

  const filtersSanitize = (params = {}) => {
    if (noSubHeader) return;
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
    dispatch(removeFilters());
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Error: Action not found');
    }
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({ userModule, handleButtonClick, handleUpdateRequest })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        customStyles={customStyles}
        highlightOnHover
        conditionalRowStyles={conditionalRowStyles}
        customDatatableHeader={
          FilterHeaderComponent ? (
            <FilterHeaderComponent {...filterHeaderComponentProps} />
          ) : (
            <PremiumCollectionFilters
              userModule={userModule}
              customParams={customParams}
              setCustomParams={setCustomParams}
              collectionType="commission"
              createdFromOptions={createdFromOptions}
            />
          )
        }
        pointerOnHover
        resourceRequest={handleIndexRequest}
        noSubHeader={noSubHeader}
        defaultParams={{ ...defaultParams }}
      />
      <OnDestroyModalComponent />
    </>
  );
};

CommissionCollectionDataTable.propTypes = {
  columns: PropTypes.func
};

CommissionCollectionDataTable.defaultProps = {
  columns: defaultColumns
};

export default CommissionCollectionDataTable;
