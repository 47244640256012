import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Image, Spinner } from 'react-bootstrap';

import {
  basicLeadQuotation,
  LeadQuotationAcceptanceModal,
  LeadQuotationSuccessModal
} from '../../components/LeadQuotation';
import DarkNicoLogoSvg from '../../components/Utils/MainLogo/logos/dark-nico-logo.svg';
import { showPublicLeadQuotationRequest } from '../../requests/public/leadQuotations';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { WhatsAppButton } from '../../components';

const LeadQuotationApproval = ({ match }) => {
  const { id: acceptanceKey } = match.params;
  const [accepted, setAccepted] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [leadQuotation, setLeadQuotation] = useState(basicLeadQuotation);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const dispatch = useDispatch();

  const { account, user, validAcceptanceKey } = leadQuotation;
  const { insuranceBrokerAttributes: insuranceBroker } = user;

  const handleModalClose = () => setShowModal(false);

  const handleSuccessRequest = response => {
    if (response.data === null) return;
    setLeadQuotation(camelCaseEmptyStringRecursive(response.data));
  };

  const handleShowPublicLeadQuotationRequest = () => {
    setIsFetching(true);
    showPublicLeadQuotationRequest(acceptanceKey, {
      dispatch,
      successCallback: handleSuccessRequest,
      callback: () => setIsFetching(false)
    });
  };

  useEffect(handleShowPublicLeadQuotationRequest, []);

  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="approval-container">
          <div className="container">
            <Image fluid src={DarkNicoLogoSvg} alt="approval-dark-nico-logo" />

            {validAcceptanceKey && !accepted ? (
              <div className="approval-container__content">
                <h4>hola, {account.name}</h4>
                <p>
                  <span>{insuranceBroker.name}</span> está pidiendo tu aprobación en una póliza para que estés más
                  protegido.
                </p>
                <p>Si estás de acuerdo con la cotización, apruébala aquí mismo y nosotros nos encargamos del resto.</p>
                <p className="fine-print">Si no estás de acuerdo, simplemente cierra la página</p>
                <div className="buttons">
                  <a
                    className="btn show-quotation"
                    href={leadQuotation.quotation.fileUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ver cotización
                  </a>
                  <Button variant="submit" className="approve-quotation" onClick={() => setShowModal(true)}>
                    Aprobar cotización
                  </Button>
                </div>
              </div>
            ) : (
              <div className="approval-container__response-message">
                {accepted ? (
                  <>
                    <h6>Has aprobado la cotización con éxito.</h6>
                    <p>
                      Pronto <span>{insuranceBroker.name}</span> te contactará para confirmar la emisión de la póliza y
                      que estás protegido.
                    </p>
                    <p>¡Gracias!</p>
                  </>
                ) : (
                  <p>Este link ha expirado, comunícate con tu corredor para generar uno nuevo.</p>
                )}
              </div>
            )}
          </div>
          <div className="background-circles">
            <span className="circle-top" />
            <span className="circle-left" />
            <span className="circle-bottom" />
            <span className="circle-petite-1" />
            <span className="circle-petite-2" />
            <span className="circle-petite-3" />
          </div>
        </div>
      )}

      <LeadQuotationAcceptanceModal
        leadQuotation={leadQuotation}
        showModal={showModal}
        handleClose={handleModalClose}
        setShowModal={setShowModal}
        setShowSuccessModal={setShowSuccessModal}
        setAccepted={setAccepted}
      />
      <LeadQuotationSuccessModal
        brokerName={insuranceBroker.name}
        showModal={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
      />
      <WhatsAppButton />
    </>
  );
};

export default LeadQuotationApproval;
