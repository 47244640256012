import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { withFormik, Form } from 'formik';
import { Prompt } from 'react-router-dom';
import * as Yup from 'yup';

import { AccountFields } from '../../components/Account';
import { rutFormat } from '../../services/utils';
import '../../services/yupCustomMethods';

const AccountForm = ({ action, errors, handleCancelButton, isSubmitting, touched, values }) => {
  const submitBtnText = action === 'new' ? 'Crear Cuenta' : 'Guardar Cambios';

  return (
    <>
      <Prompt when={!isSubmitting} message="¿Seguro deseas abandonar? Toda la información ingresada será perdida" />
      <Form>
        <AccountFields userModule="broker" values={values} errors={errors} touched={touched} action={action} />
        <Row className="d-flex flex-row-reverse align-items-center">
          <Col md={4} lg={3} className="mt-4">
            <Button block type="submit" variant="submit" disabled={isSubmitting}>
              {submitBtnText}
            </Button>
          </Col>
          <Col md={3} lg={2} className="mt-4">
            <Button block variant="cancel" className="mr-3" onClick={handleCancelButton}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ account }) => {
  const { nationalIdentification } = account;
  return {
    account: {
      ...account,
      nationalIdentification: rutFormat(nationalIdentification)
    }
  };
};

const validationSchema = Yup.object().shape({
  account: Yup.object().shape({
    contactsAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debes ingresar un nombre'),
        addressAttributes: Yup.object().nullable()
      })
    ),
    industryId: Yup.string().when('legalPersonType', {
      is: 'natural',
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Debes ingresar un rubro')
    }),
    legalPersonType: Yup.string().required('Debes ingresar un tipo de cuenta'),
    name: Yup.string().required('Debes ingresar un nombre'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un RUT')
      .rut('El RUT es inválido')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AccountForm);
