import React, { useRef } from 'react';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import LeadFiltersHeader from '../Lead/Filters/LeadFiltersHeader';
import { useDidMountEffect, useFetchData } from '../../hooks';
import { debounceIndexAdminInsurancePoliciesRequest } from '../../requests/admin/adminInsurancePolicies';
import { debounceIndexInsurancePoliciesRequest } from '../../requests/insurancePolicies';
import { extractKeys } from '../../services/utils';
import {
  insurancePolicyAdvancedOptions,
  insurancePolicyFiltersParams
} from '../../screens/InsurancePolicy/Filters/InsurancePolicyFiltersParams';
import InsurancePolicyDataTable from '../../screens/InsurancePolicy/InsurancePolicyDataTable';
import {
  firstStep,
  renewalParams,
  renewalSteps,
  secondStep,
  thirdStep
} from '../../screens/Lead/LeadRenewal/LeadRenewalFiltersParams';
import columns from './extendableColumns';

const ExtendableDataTable = ({ currentStep, dataCounter, fromAdmin, modelUrl, onChangeTab, setCurrentStep }) => {
  const customParamsRef = useRef({});

  const extendableKeys = Object.keys(snakeCaseKeys(insurancePolicyFiltersParams));
  extendableKeys.push('validity_end_date_from', 'validity_end_date_to');

  const extendableParams = { ...renewalParams, 1: { ...renewalParams[1], renewal: 'extendable' } };

  const customFilterOptions = firstStep.filtersOptions.map(option => {
    let currentOption = option;
    if (option.value === dataCounter.name) currentOption = { ...option, counter: dataCounter.counter };
    return currentOption;
  });

  const options = {
    steps: renewalSteps,
    params: extendableParams,
    1: { ...firstStep, filtersOptions: customFilterOptions, advancedOptions: insurancePolicyAdvancedOptions },
    2: secondStep,
    3: thirdStep
  };

  const { data: extendableContracts, totalData, moreData, isFetching, handleIndexRequest, setMoreData } = useFetchData({
    debouncedIndexRequest: fromAdmin
      ? debounceIndexAdminInsurancePoliciesRequest
      : debounceIndexInsurancePoliciesRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los datos. Por favor inténtalo nuevamente',
    withDataTable: true,
    customParams: {
      renewal_type: 'extendable',
      lead_renewal_extendable: true,
      ...extractKeys(customParamsRef.current, extendableKeys)
    }
  });

  const handleOnChangeTab = () => {
    const { renewal, step, stepNumber } = camelCaseRecursive(customParamsRef.current) || {};
    setCurrentStep({ key: stepNumber, value: step });
    onChangeTab({ renewal, step });
  };

  useDidMountEffect(handleOnChangeTab, [customParamsRef.current]);

  return (
    <InsurancePolicyDataTable
      fromAdmin={fromAdmin}
      tableData={extendableContracts}
      tableDataAmount={totalData}
      modelUrl={modelUrl}
      isFetching={isFetching}
      moreData={moreData}
      setMoreData={setMoreData}
      handleIndexRequest={handleIndexRequest}
      customParamsRef={customParamsRef}
      columns={columns}
      defaultParams={{ sortColumn: 'step_days' }}
      FilterHeaderComponent={LeadFiltersHeader}
      filterHeaderComponentProps={{
        fromAdmin,
        options,
        initialStep: currentStep.key,
        dateFromField: 'validityEndDateFrom',
        dateToField: 'validityEndDateTo',
        advancedOptionsBtn: false,
        customParamsRef,
        setMoreData
      }}
    />
  );
};

export default ExtendableDataTable;
