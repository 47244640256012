import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/premium_collections', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const headerDashboardPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/premium_collections/header_dashboard', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const indexCommissionCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/premium_collections', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexPremiumCollectionRequest = AwesomeDebouncePromise(indexPremiumCollectionRequest, 300);
export const debounceIndexCommissionCollectionRequest = AwesomeDebouncePromise(indexCommissionCollectionRequest, 300);

export const debounceHeaderDashboardPremiumCollectionRequest = AwesomeDebouncePromise(
  headerDashboardPremiumCollectionRequest,
  300
);
