import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { updateAdminPaymentPlanRequest, showAdminPaymentPlanRequest } from '../../../requests/admin/paymentPlans';
import AdminPaymentPlanForm from './AdminPaymentPlanForm';
import basicPaymentPlan from './basicPaymentPlan';

const AdminPaymentPlanEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/payment_plans';
  const [paymentPlan, setPaymentPlan] = useState(basicPaymentPlan);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Planes de pago', href: modelUrl },
    { key: 2, name: 'Editar planes' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Plan de pago actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminPaymentPlanRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchPaymentPlan = () => {
    showAdminPaymentPlanRequest(id, {
      dispatch,
      successCallback: response => setPaymentPlan(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchPaymentPlan, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminPaymentPlanForm action="edit" paymentPlan={paymentPlan} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminPaymentPlanEdit;
