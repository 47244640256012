import { BasicTextArea, FormikSelect } from '../../../components';

export const fieldOptions = [
  {
    key: 1,
    Component: BasicTextArea,
    name: 'sinisterDeclaration',
    title: 'Declaración de siniestro',
    description:
      'Describe aquí el siniestro para que tu corredor tome conocimiento y pueda ayudarte. Recuerda especificar de la mejor manera posible la materia asegurada (ej: casa, auto, fábrica, salud, etc.), fecha y hora, lugar y los daños.',
    fieldProps: {
      abbr: true,
      placeholder: 'Máximo 2000 caracteres',
      row: 6
    },
    yupProps: {
      requiredMessage: 'Debes ingresar una declaración de siniestro',
      characterLimit: 2000
    }
  },
  {
    key: 2,
    Component: FormikSelect,
    name: 'accountId',
    title: 'Cuenta',
    description:
      'Selecciona la cuenta (persona natural o jurídica) a la que está asociada la póliza que cubre este siniestro.',
    fieldProps: {
      abbr: true,
      options: [],
      placeholder: 'Seleccionar cuenta'
    },
    yupProps: {
      requiredMessage: 'Debes seleccionar una cuenta'
    },
    isSelector: true,
    selectorProps: ({ accounts, customFieldProps, name, setFieldValue }) => {
      const fieldProps = customFieldProps;
      fieldProps.options = accounts;
      fieldProps.onChange = data => setFieldValue(`insuredContact[${name}]`, data ? data.value : '');
    }
  },
  {
    key: 3,
    Component: FormikSelect,
    name: 'insurancePolicyId',
    title: 'Número de póliza',
    description: 'Selecciona el número de póliza que cubre este siniestro.',
    fieldProps: {
      abbr: false,
      options: [],
      placeholder: 'Seleccionar número póliza',
      isClearable: true
    },
    yupProps: {
      requiredMessage: 'Debes seleccionar un número de póliza'
    },
    isSelector: true,
    selectorProps: ({
      customFieldProps,
      fieldProps: originalFieldProps,
      insurancePolicies,
      isFetching,
      name,
      setFieldValue
    }) => {
      const fieldProps = customFieldProps;
      fieldProps.isDisabled = isFetching;
      fieldProps.options = insurancePolicies;
      fieldProps.placeholder = isFetching ? 'Cargando opciones...' : originalFieldProps.placeholder;
      fieldProps.onChange = data => setFieldValue(`insuredContact[${name}]`, data ? data.value : '');
    }
  }
];

const extractAttributes = () => {
  const fieldAttribute = {};
  fieldOptions.forEach(({ name }) => {
    fieldAttribute[name] = '';
  });
  return fieldAttribute;
};

export const insuredContactAttributes = extractAttributes();
