import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import confetti from '../../../assets/images/confetti.gif';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const SuccessModal = ({
  textButton = 'Volver al inicio',
  text = '¡Gracias! Te contactaremos para confirmar día y hora'
}) => {
  const history = useHistory();
  const navigateTo = path => {
    history.push(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const { translateType } = useGetRoleByRoute();

  return (
    <div className="success-modal">
      <Image src={confetti} fluid alt="confetti" />
      <h6 className="text-center mb-4">{text}</h6>
      <Button type="button" onClick={() => navigateTo(`/${translateType}`)} className="mt-4 btn-submit">
        {textButton}
      </Button>
    </div>
  );
};

export default SuccessModal;
