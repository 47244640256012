import React from 'react';

const Arrows = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46733)">
        <path
          d="M17.0078 9.31069L15.4388 3.45508L13.7762 5.11765C10.7337 3.20054 6.6648 3.56702 4.01473 6.2171L5.25216 7.45454C7.21413 5.49257 10.176 5.13987 12.4974 6.39645L11.1522 7.74168L17.0078 9.31069Z"
          fill="white"
        />
        <path
          d="M3.98924 11.6874L5.55825 17.543L7.22082 15.8805C10.2633 17.7976 14.3323 17.4311 16.9823 14.781L15.7449 13.5436C13.7829 15.5055 10.8211 15.8582 8.49963 14.6017L9.84486 13.2564L3.98924 11.6874Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46733">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Arrows;
