import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Form, Field, getIn, useFormikContext } from 'formik';

import { BasicTextArea, FormikInput } from '../..';

const InsurancePolicyProposalForm = ({ formRequest, isSubmitting, onHide }) => {
  const { errors, touched } = useFormikContext();

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="insurancePolicy[proposalTitle]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Título"
                placeholder="Ingresa un título (máximo 200 caracteres)"
                touched={getIn(touched, field.name)}
                error={getIn(errors, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="insurancePolicy[proposalBody]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                label="Descripción"
                placeholder="Ingresa una descripción (máximo 500 caracteres)"
                rows={3}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="mt-2 d-flex justify-content-end">
        <Col md={4} className="mt-4">
          <Button block variant="cancel" className="no-shadow" onClick={onHide}>
            Cancelar
          </Button>
        </Col>
        <Col md={5} className="mt-4">
          <Button
            block
            type="submit"
            variant="submit"
            className="no-shadow"
            onClick={formRequest}
            disabled={isSubmitting}
          >
            Crear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InsurancePolicyProposalForm;
