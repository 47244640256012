import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { ComponentDataTable, DefaultModal } from '../../../components';
import { SearchDateFilter } from '../../../components/Shared';
import {
  updateActiveAttributeAdminUserRequest,
  resendWelcomeEmailAdminUserRequest
} from '../../../requests/admin/adminUsers';
import { emptyFalseParamsRecursive, lowerCaseTextFormat } from '../../../services/utils';
import columns from './Columns';

const AdminUserDataTable = ({
  customParamsRef,
  handleIndexRequest,
  isFetching,
  modelUrl,
  moreData,
  setMoreData,
  tableData,
  tableDataAmount
}) => {
  const [customParams, setCustomParams] = useState({ query: '', dateFrom: '', dateTo: '' });
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Correo de bienvenida reenviado con éxito' }));
  };

  const handleUpdateRequest = user => {
    resendWelcomeEmailAdminUserRequest(user.id, {
      dispatch,
      params: snakeCaseKeys({ user }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleModalClose = () => setModalShow(false);

  const handleUpdateActiveAttributeRequest = user => {
    updateActiveAttributeAdminUserRequest(user.id, {
      dispatch,
      successCallback: response => {
        const activeAction = response.data.active ? 'activado' : 'desactivado';
        dispatch(sendAlert({ kind: 'success', message: `Usuario ${activeAction} con éxito` }));
        setMoreData(m => !m);
      },
      callback: handleModalClose
    });
  };

  const handleModalConfirm = () => handleUpdateActiveAttributeRequest(modalItem);

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'landingPath':
        navigator.clipboard.writeText(item.landingPath);
        dispatch(sendAlert({ kind: 'success', message: 'Landing URL copiada con éxito' }));
        break;
      case 'resend':
        handleUpdateRequest(item);
        break;
      case 'inactivate': {
        const activeAction = item.active ? 'Desactivar' : 'Activar';
        const bodyContent = (
          <>
            <p className="mb-3">Estás por {lowerCaseTextFormat(activeAction)} un usuario,</p>
            <p>¿estás seguro de querer hacerlo?</p>
          </>
        );
        setModalItem({ ...item, activeAction });
        setModalBody(bodyContent);
        setModalShow(true);
        break;
      }
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSetUpFilters = () => {
    const newParams = snakeCaseKeys(emptyFalseParamsRecursive(customParams));
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(!moreData);
  };

  useEffect(handleSetUpFilters, [customParams]);

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({ handleBasicActions, modelUrl, withEdit: true })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        customDatatableHeader={
          <Row>
            <Col md={{ span: 7, offset: 5 }} lg={{ span: 5, offset: 7 }}>
              <SearchDateFilter
                containerClass="ml-lg-5"
                customParams={customParams}
                setCustomParams={setCustomParams}
              />
            </Col>
          </Row>
        }
      />
      <DefaultModal
        withButtons
        size="sm"
        title={`${modalItem?.activeAction} Usuario`}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
      />
    </>
  );
};

export default AdminUserDataTable;
