import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminInsuranceCategoryRequest } from '../../../requests/admin/insuranceCategories';
import AdminInsuranceCategoryDataTable from './AdminInsuranceCategoryDataTable';

const AdminInsuranceCategoryIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: insuranceCategories,
    totalData: totalCategories,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminInsuranceCategoryRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los ramos. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/insurance_categories';

  return (
    <DefaultHeader
      title="Ramos"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Ramo',
        path: `${modelUrl}/new`
      }}
    >
      <AdminInsuranceCategoryDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={insuranceCategories}
        tableDataAmount={totalCategories}
      />
    </DefaultHeader>
  );
};

export default AdminInsuranceCategoryIndex;
