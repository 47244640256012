import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const generalObject = ({ lead, userModule }) => {
  const {
    account,
    accountId,
    accountName,
    beneficiaryPersonAddress,
    beneficiaryPersonName,
    beneficiaryPersonNationalIdentification,
    collectionEmail,
    executiveManagerLabel,
    hiringPersonAddress,
    hiringPersonName,
    hiringPersonNationalIdentification,
    insuranceBrokerCompanyName,
    insuranceBrokerName,
    insuredPersonAddress,
    insuredPersonName,
    insuredPersonNationalIdentification,
    responsibleEmail
  } = lead.hasProposal && lead.insurancePolicyProposal ? lead.insurancePolicyProposal : lead;

  const object = {};

  object.accountName = infoCardElement({
    link: true,
    key: 'Cuenta',
    value: accountName,
    url: userModule === 'broker' ? `/accounts/${accountId}` : `/admin/accounts/${accountId}`
  });
  object.accountNationalIdentification = infoCardElement({
    string: true,
    key: 'RUT Cuenta',
    value: account.nationalIdentification
  });
  if (hiringPersonName)
    object.hiringPerson = infoCardElement({
      string: true,
      key: 'Contratante',
      value: `${hiringPersonName} ${hiringPersonNationalIdentification}`
    });
  if (hiringPersonAddress)
    object.hiringPersonAddress = infoCardElement({
      string: true,
      key: 'Dirección contratante',
      value: hiringPersonAddress
    });
  if (insuredPersonName)
    object.insuredPerson = infoCardElement({
      string: true,
      key: 'Asegurado',
      value: `${insuredPersonName} ${insuredPersonNationalIdentification}`
    });
  if (insuredPersonAddress)
    object.insuredPersonAddress = infoCardElement({
      string: true,
      key: 'Dirección asegurado',
      value: insuredPersonAddress
    });

  if (beneficiaryPersonName)
    object.beneficiaryPerson = infoCardElement({
      string: true,
      key: 'Beneficiario',
      value: `${beneficiaryPersonName} ${beneficiaryPersonNationalIdentification}`
    });
  if (beneficiaryPersonAddress)
    object.beneficiaryPersonAddress = infoCardElement({
      string: true,
      key: 'Dirección beneficiario',
      value: beneficiaryPersonAddress
    });
  if (responsibleEmail)
    object.responsibleEmail = infoCardElement({
      string: true,
      key: 'Contacto comercial',
      value: responsibleEmail
    });
  if (collectionEmail)
    object.collectionEmail = infoCardElement({
      string: true,
      key: 'Contacto cobranza',
      value: collectionEmail
    });
  object.insuranceBroker = infoCardElement({
    string: true,
    key: 'Corredor',
    value: `${insuranceBrokerCompanyName}/${insuranceBrokerName}`
  });
  if (executiveManagerLabel)
    object.executiveManager = infoCardElement({
      string: true,
      key: 'Ejecutivo NICO',
      value: executiveManagerLabel
    });

  return object;
};

export default generalObject;
