import React, { useState } from 'react';

import { useFetchData } from '../../hooks';
import { renewalTypeCounterAdminInsurancePolicyRequest } from '../../requests/admin/adminInsurancePolicies';
import { renewalTypeCounterInsurancePolicyRequest } from '../../requests/insurancePolicies';
import { DefaultHeader } from '..';
import ExtendableDataTable from './ExtendableDataTable';
import RenewableDataTable from './RenewableDataTable';

const LeadRenewalIndexBody = ({ extendableUrl, fromAdmin, renewableUrl }) => {
  const [isExtendable, setIsExtendable] = useState(false);
  const [currentStep, setCurrentStep] = useState({ key: 1, value: 'renewal' });

  const { data: extendableTotalData } = useFetchData({
    debouncedIndexRequest: fromAdmin
      ? renewalTypeCounterAdminInsurancePolicyRequest
      : renewalTypeCounterInsurancePolicyRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los datos. Por favor inténtalo nuevamente',
    customParams: { lead_renewal: true, renewal_type: 'extendable' },
    withoutWrapper: true
  });

  const handleOnChangeTab = ({ renewal, step }) => {
    if (step !== 'renewal') return;
    if (renewal === 'extendable') setIsExtendable(true);
    else setIsExtendable(false);
  };

  return (
    <DefaultHeader title="Renovaciones">
      {isExtendable && currentStep.value === 'renewal' ? (
        <ExtendableDataTable
          fromAdmin={fromAdmin}
          modelUrl={extendableUrl}
          onChangeTab={handleOnChangeTab}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          dataCounter={{ name: 'extendable', counter: extendableTotalData }}
        />
      ) : (
        <RenewableDataTable
          fromAdmin={fromAdmin}
          modelUrl={renewableUrl}
          onChangeTab={handleOnChangeTab}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          dataCounter={{ name: 'extendable', counter: extendableTotalData }}
        />
      )}
    </DefaultHeader>
  );
};

export default LeadRenewalIndexBody;
