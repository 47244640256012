import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ReactMultiEmail } from 'react-multi-email';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import 'react-multi-email/dist/style.css';

import { emailRegex } from '../../../services/utils';
import { ButtonTooltip } from '../Tooltips';
import { IconBtn } from '../Button';

const FormikMultiEmail = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  error,
  helpText,
  label,
  margin,
  optional,
  tooltipHtml,
  tooltipText,
  touched,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const labelRef = useRef();

  const [invalidEmails, setInvalidEmails] = useState([]);

  return (
    <>
      <Form.Group className={`form-multi-email ${margin}`} data-tip data-for={`registerTip-input-${tooltipText}`}>
        {label && (
          <Form.Label ref={labelRef}>
            {label} {abbr && <abbr className="text-danger">*</abbr>}
            {optional && <span className="optional-label">(Opcional)</span>}
          </Form.Label>
        )}
        <ReactMultiEmail
          emails={props.value || []}
          validateEmail={email => {
            const isValid = emailRegex.test(email);
            if (!isValid) setInvalidEmails([...invalidEmails, email]);
            return isValid;
          }}
          onChange={emails => setFieldValue(props.name, emails)}
          getLabel={(email, index, removeEmail) => (
            <CustomLabel key={index} email={email} index={index} removeEmail={removeEmail} />
          )}
          className={error && touched ? 'is-invalid' : ''}
        />
        {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
        {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
        {tooltipText && (
          <ButtonTooltip
            html={tooltipHtml}
            tooltipText={tooltipText}
            containerClassName="custom-input--tooltip"
            tabIndex="-1"
          >
            ?
          </ButtonTooltip>
        )}
      </Form.Group>
    </>
  );
};

const CustomLabel = ({ email, index, removeEmail }) => (
  <div data-tag>
    {email}
    <IconBtn icon="close" iconSize="xs" variant="transparent" className="black" onClick={() => removeEmail(index)} />
  </div>
);

FormikMultiEmail.propTypes = {
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  tooltipHtml: PropTypes.bool
};

FormikMultiEmail.defaultProps = {
  direction: 'top',
  delayShow: 250,
  delayHide: 0,
  tooltipHtml: true
};

export default FormikMultiEmail;
