import React from 'react';

const Eye = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46727)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6549 10.4904C13.6549 12.2374 12.2386 13.6537 10.4916 13.6537C8.74458 13.6537 7.32834 12.2374 7.32834 10.4904C7.32834 8.74337 8.74458 7.32713 10.4916 7.32713C12.2386 7.32713 13.6549 8.74337 13.6549 10.4904ZM12.0732 10.4904C12.0732 11.3639 11.3651 12.072 10.4916 12.072C9.6181 12.072 8.90997 11.3639 8.90997 10.4904C8.90997 9.61688 9.6181 8.90876 10.4916 8.90876C11.3651 8.90876 12.0732 9.61688 12.0732 10.4904Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4916 3.37305C14.9135 3.37305 18.629 6.39738 19.6824 10.4904C18.629 14.5834 14.9135 17.6077 10.4916 17.6077C6.06974 17.6077 2.35425 14.5834 1.30078 10.4904C2.35425 6.39738 6.06974 3.37305 10.4916 3.37305ZM10.4916 16.0261C6.95226 16.0261 3.9526 13.6996 2.94503 10.4904C3.9526 7.28115 6.95226 4.95468 10.4916 4.95468C14.031 4.95468 17.0306 7.28115 18.0382 10.4904C17.0306 13.6996 14.031 16.0261 10.4916 16.0261Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46727">
          <rect width="18.9796" height="18.9796" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Eye;
