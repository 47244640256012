import React from 'react';
import { IconBtn } from '../Button';

const IconNumberCard = ({ icon, title, value = 0, variant }) => (
  <div className="icon-number-card">
    {title && <p className="icon-number-card__title">{title}</p>}
    <div className="icon-number-card__content">
      <IconBtn noBtn icon={icon} iconSize="xs" variant={variant} className="no-shadow" />
      <p className="icon-number-card__value">{value}</p>
    </div>
  </div>
);

export default IconNumberCard;
