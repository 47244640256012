import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminLeadRequest = ({ dispatch, params = {}, successCallback, failureCallback, callback, source }) =>
  ApiService.request('get', '/admin/leads', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showAdminLeadRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/leads/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createAdminLeadRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/leads', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/leads/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAdminLeadRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('delete', `/admin/leads/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const autosaveAdminLeadRequest = (id, { dispatch, params, formData, successCallback, callback, source }) =>
  ApiService.request('patch', `/admin/leads/${id}/autosave`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    source
  });

export const closeAdminLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/leads/${id}/close_lead`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const rollbackCloseAdminLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/leads/${id}/rollback_close_lead`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const rollbackRequestedAdminLeadRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/leads/${id}/rollback_requested_lead`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const sendAdminLeadQuotationsRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) => {
  ApiService.request('post', `/admin/leads/${id}/send_quotations`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const sendAdminLeadReservationRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) => {
  ApiService.request('post', `/admin/leads/${id}/send_reservation`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const debounceIndexAdminLeadRequest = AwesomeDebouncePromise(indexAdminLeadRequest, 1000);
export const debounceAutosaveAdminLeadRequest = AwesomeDebouncePromise(autosaveAdminLeadRequest, 1000);
