import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';
import { formatCurrency } from '../../../services/utils';

const monetaryObject = ({ insurancePolicy, setModalShow }) => {
  const {
    affectCommissionPercentage,
    affectPremium,
    exemptCommissionPercentage,
    exemptPremium,
    insuranceItemsAttributes,
    parsedAffectCommissionPercentage,
    parsedAffectPremium,
    parsedBaseCommissionPercentage,
    parsedBrokerCommissionAmount,
    parsedBrokerCommissionPercentage,
    parsedCommissionTotalAmount,
    parsedCompanyCommissionAmount,
    parsedCompanyCommissionPercentage,
    parsedExemptCommissionPercentage,
    parsedExemptPremium,
    parsedNicoCommissionAmount,
    parsedNicoCommissionPercentage,
    parsedTotalNetPremium,
    parsedTotalPremium,
    currency,
    cadenceType,
    translatedCadenceType
  } = insurancePolicy;

  const currentInsuranceItems = insuranceItemsAttributes.filter(insuranceItem => !insuranceItem._destroy);
  const affectCommissionAmount = (affectPremium * affectCommissionPercentage) / 100;
  const exemptCommissionAmount = (exemptPremium * exemptCommissionPercentage) / 100;
  const cadenceTooltipText =
    cadenceType === 'monthly'
      ? 'Las pólizas con cadencia mensual informan un valor referencial de las primas y comisiones mensuales.<br/> Estos pueden variar mes a mes.'
      : '';

  const object = {};

  object.insuranceItems = infoCardElement({
    file: true,
    item: {
      title: `${currentInsuranceItems.length} ${currentInsuranceItems.length > 1 ? 'Ítems' : 'Ítem'}`,
      name: 'Ver detalle',
      onClick: () => setModalShow(true)
    }
  });
  object.cadence = infoCardElement({
    string: true,
    key: 'Cadencia',
    value: translatedCadenceType,
    tooltipText: cadenceTooltipText
  });
  object.totalPremium = infoCardElement({
    string: true,
    key: 'Prima total bruta',
    value: parsedTotalPremium
  });
  object.totalNetPremium = infoCardElement({
    array: true,
    key: 'Prima total neta',
    value: parsedTotalNetPremium,
    items: [
      infoCardElement({
        string: true,
        subelement: true,
        key: 'Prima afecta',
        value: parsedAffectPremium
      }),
      infoCardElement({
        string: true,
        subelement: true,
        key: 'Prima exenta',
        value: parsedExemptPremium
      })
    ]
  });
  object.commission = infoCardElement({
    array: true,
    key: 'Comisión póliza neta',
    value: parsedCommissionTotalAmount,
    items: [
      infoCardElement({
        string: true,
        subelement: true,
        key: `Comisión póliza afecta (${parsedAffectCommissionPercentage})`,
        value: formatCurrency({ value: affectCommissionAmount, addon: ` ${currency}` })
      }),
      infoCardElement({
        string: true,
        subelement: true,
        key: `Comisión póliza exenta (${parsedExemptCommissionPercentage})`,
        value: formatCurrency({ value: exemptCommissionAmount, addon: ` ${currency}` })
      })
    ]
  });
  object.nicoCommission = infoCardElement({
    string: true,
    key: `Comisión NICO (${parsedNicoCommissionPercentage})`,
    value: parsedNicoCommissionAmount
  });
  object.companyCommission = infoCardElement({
    string: true,
    key: `Comisión empresa (${parsedCompanyCommissionPercentage} del ${parsedBaseCommissionPercentage})`,
    value: parsedCompanyCommissionAmount
  });
  object.brokerCommission = infoCardElement({
    string: true,
    key: `Comisión corredor (${parsedBrokerCommissionPercentage} del ${parsedBaseCommissionPercentage})`,
    value: parsedBrokerCommissionAmount
  });

  return object;
};

export default monetaryObject;
