import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexNotificationsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) => {
  ApiService.request('get', '/notifications', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });
};

export const readAllNotificationsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/notifications/read_all', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexNotificationsRequest = AwesomeDebouncePromise(indexNotificationsRequest, 300);
