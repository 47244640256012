import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminTaskRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/admin/tasks', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const getAdminTasksTabCountRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/tasks/tabs_count', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminTaskRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/tasks', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminTaskRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/tasks/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminTaskRequest = (id, { dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('delete', `/admin/tasks/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminTaskRequest = AwesomeDebouncePromise(indexAdminTaskRequest, 1000);
export const debounceGetAdminTasksTabCountRequest = AwesomeDebouncePromise(getAdminTasksTabCountRequest, 1000);
