import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminInsuranceCompanyRequest } from '../../../requests/admin/insuranceCompanies';
import AdminInsuranceCompanyDataTable from './AdminInsuranceCompanyDataTable';

const AdminInsuranceCompanyIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: insuranceCompanies,
    totalData: totalCompanies,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminInsuranceCompanyRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar las empresas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/insurance_companies';

  return (
    <DefaultHeader
      title="Aseguradoras"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Aseguradora',
        path: `${modelUrl}/new`
      }}
    >
      <AdminInsuranceCompanyDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={insuranceCompanies}
        tableDataAmount={totalCompanies}
      />
    </DefaultHeader>
  );
};

export default AdminInsuranceCompanyIndex;
