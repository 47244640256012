import React from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { BtnTooltip } from '../../../components';
import { exportAdminMovementSummary } from '../../../requests/admin/adminBeneficiaryMovements';
import { exportMovementSummary } from '../../../requests/beneficiaryMovements';

const BeneficiaryMovementColumns = ({ fromAdmin, insurancePolicyId, setBeneficiaryMovement, handleOpenEmailModal }) => {
  const columns = [
    {
      name: 'Fecha',
      selector: 'createdAt',
      sortable: true,
      grow: '1'
    },
    {
      name: 'Título',
      selector: 'title',
      sortable: true,
      grow: '1'
    }
  ];

  columns.push({
    cell: beneficiaryMovement => (
      <ActionButton
        fromAdmin={fromAdmin}
        insurancePolicyId={insurancePolicyId}
        beneficiaryMovement={beneficiaryMovement}
        setBeneficiaryMovement={setBeneficiaryMovement}
        handleOpenEmailModal={handleOpenEmailModal}
      />
    ),
    sortable: false,
    grow: '2'
  });

  return columns;
};

const ActionButton = ({
  fromAdmin,
  beneficiaryMovement,
  insurancePolicyId,
  setBeneficiaryMovement,
  handleOpenEmailModal
}) => {
  const dispatch = useDispatch();

  const exportSummary = exportFunc => {
    exportFunc(insurancePolicyId, beneficiaryMovement.id, {
      dispatch,
      params: {},
      successCallback: response => {
        setBeneficiaryMovement({
          ...beneficiaryMovement,
          movementSummaryInfo: camelCaseRecursive(response.data)
        });

        handleOpenEmailModal();
      }
    });
  };

  const handleGenerateMovementSummary = () => {
    if (fromAdmin) {
      exportSummary(exportAdminMovementSummary);
    } else {
      exportSummary(exportMovementSummary);
    }
  };

  const handleDownloadMovementSummary = () => {
    const openFile = fileUrl => window.open(fileUrl, '_blank', 'noopener');
    const exportSummaryRequest = fromAdmin ? exportAdminMovementSummary : exportMovementSummary;
    exportSummaryRequest(insurancePolicyId, beneficiaryMovement.id, {
      dispatch,
      params: {},
      successCallback: response => {
        openFile(response.data.file_url);
      }
    });
  };

  return (
    <>
      <SendEmailBtn title="movimiento" onClick={handleGenerateMovementSummary} />

      <DownloadBtn onClick={handleDownloadMovementSummary} />
    </>
  );
};

const SendEmailBtn = ({ disabled, onClick, title }) => (
  <BtnTooltip
    icon="mail"
    iconSize="sm"
    variant="transparent"
    className="black ml-2"
    tooltipText={disabled ? '' : `Enviar ${title}`}
    onClick={onClick}
    disabled={disabled}
  />
);

const DownloadBtn = ({ disabled = false, onClick }) => (
  <BtnTooltip
    icon="software-download"
    iconSize="sm"
    variant="transparent"
    className="black ml-2"
    tooltipText="Descargar movimiento"
    onClick={onClick}
    disabled={disabled}
  />
);

export default BeneficiaryMovementColumns;
