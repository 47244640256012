import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getIn } from 'formik';
import PropTypes from 'prop-types';

import { capitalizeString } from '../../../services/utils';

const InputButtons = ({
  optionsArray,
  label,
  values,
  modelName,
  setFieldValue,
  abbr,
  error,
  touched,
  containerClass,
  buttonsVariant = 'secondary-light'
}) => {
  const [selectedBtn, setSelectedBtn] = useState(optionsArray[0].value || '');

  const handleClick = e => {
    const { target } = e.target.dataset;
    setSelectedBtn(() => target);
    setFieldValue(`${modelName}`, target);
  };

  const handleOnMount = () => {
    if (values) {
      const selectedOption = getIn(values, `${modelName}`);
      setSelectedBtn(selectedOption);
    }
  };

  useEffect(handleOnMount, [values]);

  return (
    <section className="input-buttons">
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
        </Form.Label>
      )}
      <div className={`input-buttons__options ${containerClass}`}>
        {optionsArray.map(item => (
          <Button
            key={item.value}
            data-target={item.value}
            variant={`${buttonsVariant}`}
            className={`mr-3 ${selectedBtn === item.value ? '' : 'outline'} no-shadow`}
            onClick={handleClick}
          >
            {item.label ? capitalizeString(item.label) : capitalizeString(item.value)}
          </Button>
        ))}
        {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
      </div>
    </section>
  );
};

InputButtons.propTypes = {
  containerClass: PropTypes.string,
  label: PropTypes.string,
  modelName: PropTypes.string,
  optionsArray: PropTypes.instanceOf(Array),
  setFieldValue: PropTypes.func,
  values: PropTypes.instanceOf(Object)
};

InputButtons.defaultProps = {
  containerClass: '',
  label: '',
  modelName: '',
  optionsArray: [],
  setFieldValue: () => {},
  values: undefined
};

export default InputButtons;
