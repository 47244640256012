/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns, IconBtn } from '../../../components';
import { roles } from './basicBlog';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => {
    return [
      {
        name: 'Título',
        selector: 'title',
        sortable: true,
        grow: '2'
      },
      {
        name: 'URL',
        selector: 'blogUrl',
        sortable: true,
        grow: '3',
        cell: ({ blogUrl }) => <p style={{ fontSize: '10px', letterSpacing: '0px'}}>{blogUrl}</p> || '-'
      },
      {
        name: 'Tipo',
        selector: 'blogType',
        sortable: true,
        grow: 1.5,
        cell: ({ blogType }) => {
          const role = roles.find(r => r.value === blogType);
          return role ? role.label : '';
        }
      },
      {
        name: 'Activo',
        selector: 'active',
        sortable: true,
        center: true,
        grow: 0.5,
        cell: ({ active }) => (
          <>
            {active ? (
              <IconBtn icon="check" iconSize="xs" variant="submit" noBtn className="no-shadow" />
            ) : (
              <IconBtn icon="close" iconSize="xs" variant="danger" noBtn className="no-shadow" />
            )}
          </>
        )
      },
      {
        name: 'Destacado',
        selector: 'featured',
        sortable: true,
        center: true,
        grow: 0.5,
        cell: ({ featured }) => (
          <>
            {featured ? (
              <IconBtn icon="check" iconSize="xs" variant="submit" noBtn className="no-shadow" />
            ) : (
              <IconBtn icon="close" iconSize="xs" variant="danger" noBtn className="no-shadow" />
            )}
          </>
        )
      },
      {
        name: 'F. Publicación',
        selector: 'publish_date',
        sortable: true,
        grow: '2',
        cell: ({ publishDate }) => publishDate
      },
      {
        cell: item => (
          <BasicActionBtns
            item={item}
            clickHandler={handleBasicActions}
            path={modelUrl}
            withShow={withShow}
            withEdit={withEdit}
            withDestroy={withDestroy}
            showClick={showClick}
            editClick={editClick}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        grow: '1',
        minWidth: '150px'
      }
    ];
  }
);

export default columns;
