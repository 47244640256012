import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import IconBtn from './IconBtn';

const BtnTooltip = ({
  toolbarVariant,
  direction,
  tooltipText,
  variant,
  children,
  icon,
  iconSize = 'md',
  to,
  delayShow,
  delayHide,
  ...props
}) => (
  <div className={toolbarVariant}>
    <span data-tip data-for={`registerTip-${tooltipText}`}>
      <IconBtn {...props} variant={variant} icon={icon} iconSize={iconSize} to={to}>
        {children}
      </IconBtn>
    </span>

    {tooltipText && (
      <ReactTooltip id={`registerTip-${tooltipText}`} delay={{ show: delayShow, hide: delayHide }} place={direction}>
        {tooltipText}
      </ReactTooltip>
    )}
  </div>
);
BtnTooltip.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

BtnTooltip.defaultProps = {
  direction: 'top',
  variant: 'info',
  icon: '',
  iconSize: 'md',
  to: '',
  delayShow: 250,
  delayHide: 0,
  toolbarVariant: ''
};

export default BtnTooltip;
