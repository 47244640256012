import React, { useState } from 'react';

import { InfoCard, SimpleCenteredModal } from '../../../components';
import { InfoCardInsuranceItem } from '../../../components/Shared';
import { excludeKeys } from '../../../services/utils';
import monetaryObject from './monetaryObject';

const InfoCardMonetary = ({ hideAttributes, insurancePolicy }) => {
  const [modalShow, setModalShow] = useState(false);

  let monetaryInfo = monetaryObject({ insurancePolicy, setModalShow });
  monetaryInfo = excludeKeys(monetaryInfo, hideAttributes);
  const dictionaryDetails = insurancePolicy.dictionaryDetails || insurancePolicy.insuranceCategory?.dictionaryDetails;

  return (
    <InfoCard title="Información monetaria" object={monetaryInfo}>
      <SimpleCenteredModal
        size="xl"
        title="Ítems"
        show={modalShow}
        body={
          <InsuranceItemTable
            insuranceItems={insurancePolicy.insuranceItemsAttributes}
            dictionaryDetails={dictionaryDetails}
          />
        }
        onHide={() => setModalShow(false)}
      />
    </InfoCard>
  );
};

const InsuranceItemTable = ({ insuranceItems, dictionaryDetails }) => {
  const currentInsuranceItems = insuranceItems.filter(insuranceItem => !insuranceItem._destroy);

  return currentInsuranceItems.map((insuranceItem, index) => (
    <InfoCardInsuranceItem
      key={`insurance-item-table-${index.toString()}`}
      insuranceItem={insuranceItem}
      dictionaryDetails={dictionaryDetails}
      itemNumber={index + 1}
    />
  ));
};

export default InfoCardMonetary;
