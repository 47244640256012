import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import SlideForm from './SlideForm';
import { showSlideRequest, updateSlideRequest } from '../../requests/slides';

const SlideEdit = props => {
  const [slide, setSlide] = useState({
    id: '',
    fileInfo: {},
    position: '',
    caption: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _slide = camelCaseRecursive(response.data);
    setSlide(_slide);
  };

  const handleRequest = () => {
    const { match } = props;
    const slideId = match.params.id;
    showSlideRequest(slideId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  };

  useEffect(handleRequest, []);

  const handleSuccessUpdate = response => {
    const { history } = props;
    const _slide = response.data;
    history.push(`/slides/${_slide.id}`);
  };

  const handleUpdateRequest = _slide => {
    const slideId = _slide.slide.id;
    updateSlideRequest(slideId, {
      dispatch,
      params: _slide,
      formData: true,
      successCallback: response => handleSuccessUpdate(response)
    });
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h1>Editar Slide</h1>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light" block to="/slides">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <SlideForm slide={slide} action="edit" formRequest={handleUpdateRequest} />
        </Col>
      </Row>
    </>
  );
};

export default connect()(SlideEdit);
