import React from 'react';
import jwt from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import { NavbarTop, Sidebar } from '../../components';

const AdminLoggedLayout = ({ component: Component, adminStandard = false, adminLight = false, ...rest }) => {
  const getUserModule = roles => {
    const rolePriority = ['admin', 'admin_standard', 'admin_landing'];
    return rolePriority.find(role => roles.includes(role)) || 'unknown';
  };

  const hasAccess = roles => {
    return (
      roles.includes('admin') ||
      (adminStandard && roles.includes('admin_standard')) ||
      (adminLight && roles.includes('admin_landing'))
    );
  };

  return (
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');
        const user = payload && jwt(payload);
        if (!payload) return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;

        const { roles } = user;

        if (!hasAccess(roles))
          return <div className="w-50 mx-auto text-center">Need to be an admin to access this route</div>;

        return (
          <>
            <NavbarTop userModule={getUserModule(roles)} />
            <Sidebar userModule={getUserModule(roles)} />
            <div className="content-body">
              <Component {...props} />
            </div>
          </>
        );
      }}
    />
  );
};

export default AdminLoggedLayout;
