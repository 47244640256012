import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';

import { setTaskFilterTabsCount } from '../../actions/utils';
import { ButtonTooltip } from '../../components';
import { TaskDataTable } from '../../components/Task';
import { PendingSummary } from '../../components/Pending';
import { useFetchData } from '../../hooks';
import { debounceIndexTaskRequest, debounceGetTasksTabCountRequest } from '../../requests/tasks';
import columns from './column';

const MyAgendaIndex = () => {
  const dispatch = useDispatch();

  const customParamsRef = useRef({});
  const { data: tasks, totalData: totalTasks, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexTaskRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las tareas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current
  });

  const { date_from: dateFrom, date_to: dateTo, query } = customParamsRef.current;

  const handleSuccessGetTasksTabsRequest = response => {
    const filterTabsCount = response.data;
    dispatch(setTaskFilterTabsCount({ ...filterTabsCount }));
  };

  const handleGetTaskTabsCountRequest = () => {
    const sendParams = snakecaseKeys({ ...customParamsRef.current });
    delete sendParams.taskable_type;
    debounceGetTasksTabCountRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessGetTasksTabsRequest
    });
  };

  const tooltipText =
    'Aquí encontrarás todas las tareas que has<br/>creado directamente en los módulos de<br/>Cuentas, Pólizas, Renovaciones y Ventas';

  useEffect(handleGetTaskTabsCountRequest, [dateFrom, dateTo, query, moreData]);

  return (
    <div className="myagenda-index">
      <h5 className="text-color-primary-dark mb-5 mt-4 pt-2">Mi agenda</h5>
      <PendingSummary moreData={moreData} />
      <div className="d-flex align-items-center">
        <p className="section-title d-flex align-items-center mb-0">Tareas pendientes</p>
        <ButtonTooltip tooltipText={tooltipText} className="mx-3" direction="right">
          ?
        </ButtonTooltip>
        <Link className="custom-link" to="/agenda/all">
          Ver historial de tareas terminadas
        </Link>
      </div>
      <TaskDataTable
        columns={columns}
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        tableData={tasks}
        tableDataAmount={totalTasks}
        moreData={moreData}
        setMoreData={setMoreData}
      />
    </div>
  );
};

export default MyAgendaIndex;
