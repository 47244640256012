import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTaskRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/tasks', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const getTasksTabCountRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/tasks/tabs_count', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createTaskRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/tasks', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateTaskRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/tasks/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteTaskRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('delete', `/tasks/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexTaskRequest = AwesomeDebouncePromise(indexTaskRequest, 1000);
export const debounceGetTasksTabCountRequest = AwesomeDebouncePromise(getTasksTabCountRequest, 1000);
