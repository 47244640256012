const userTypes = [
  {
    key: 'broker',
    title: 'Corredor',
    description: 'Soy corredor de seguros y me gustaría mejorar mi operación',
    icon: 'tab',
    customStyle: 'broker',
    href: '/corredor'
  },
  {
    key: 'partner',
    title: 'Partner',
    description: 'Soy una empresa u organización y me gustaría ofrecer seguros a mis clientes o comunidad',
    icon: 'organisation',
    customStyle: 'partner',
    href: '/partner'
  },
  {
    key: 'insured',
    title: 'Asegurado',
    description: 'Soy una persona o empresa y me gustaría estar protegido',
    icon: 'smile-mouth-open',
    customStyle: 'insured',
    href: '/asegurado'
  }
];

export default userTypes;
