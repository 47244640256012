import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminInsuranceCompanyRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/insurance_companies', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminInsuranceCompanyRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_companies/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminInsuranceCompanyRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/insurance_companies', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminInsuranceCompanyRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/insurance_companies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminInsuranceCompanyRequest = (
  id,
  { dispatch, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/insurance_companies/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminInsuranceCompanyRequest = AwesomeDebouncePromise(indexAdminInsuranceCompanyRequest, 300);
