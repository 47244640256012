import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { FormHeader, SimpleCenteredModal } from '../../components';
import { HeaderTag } from '../../components/Shared';
import { AbilityContext } from '../../config/abilityContext';
import { showInsurancePolicyRequest } from '../../requests/insurancePolicies';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../services/utils';
import RequestInsuranceEndorsementNew from '../InsuranceEndorsement/RequestEndorsementNew';
import basicInsurancePolicy from './basicInsurancePolicy';
import InsurancePolicyInfo from './InsurancePolicyInfo';
import { policyTypeOptions } from './InsurancePoliciesForms/insurancePoliciesFormOptions';

const InsurancePolicyShow = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/insurance_policies';
  const [insurancePolicy, setInsurancePolicy] = useState(basicInsurancePolicy);
  const [isFetching, setIsFetching] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const ability = useAbility(AbilityContext);

  const {
    accountName,
    active,
    insuranceEndorsementsAttributes,
    isAnnulled,
    isCancelled,
    policyNumber,
    policyType,
    renewalId
  } = insurancePolicy;

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Detalle de póliza' }
  ];

  const currentPolicyType = policyTypeOptions.find(policyTypeOption => policyTypeOption.value === policyType) || {};
  const isContract = policyType === 'contract';

  const endorsementRequested = insuranceEndorsementsAttributes.find(object => object.status === 'requested') || false;
  const insuranceEndorsementPath = endorsementRequested ? `${endorsementRequested.id}/edit` : 'new';

  const showHeaderTag = isCancelled || isAnnulled || !isContract;
  const headerTagProps =
    isCancelled || isAnnulled
      ? { variant: 'danger', text: `${currentPolicyType.label} ${isCancelled ? 'cancelada' : 'anulada'}` }
      : { variant: 'secondary-light', text: currentPolicyType.label };

  const handleSuccessShow = response => {
    setInsurancePolicy(camelCaseEmptyStringRecursive(response.data));
  };

  const handleShowInsurancePolicy = (callback = handleSuccessShow) => {
    setIsFetching(true);
    showInsurancePolicyRequest(id, {
      dispatch,
      successCallback: callback,
      callback: () => setIsFetching(false)
    });
  };

  const handleModalClose = () => setModalShow(false);

  const handleModalBody = () => {
    setModalShow(true);
    setModalBody(
      <RequestInsuranceEndorsementNew
        insurancePolicyId={id}
        handleModalClose={handleModalClose}
        setMoreData={setMoreData}
      />
    );
  };

  const canManageInsurancePolicy = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'InsurancePolicy');
  };

  const adminHeaderBtnOptions = () => {
    return {
      isIconTooltip: true,
      className: 'edit-btn',
      variant: 'primary-dark',
      icon: 'pen',
      name: 'Editar Póliza',
      path: `${modelUrl}/${id}/edit`
    };
  };

  const adminSecondBtnOptions = () => {
    return {
      icon: 'add',
      name: 'Nuevo Endoso',
      path: {
        pathname: `${modelUrl}/${id}/endorsements/${insuranceEndorsementPath}`,
        state: { fromFormHeaderShow: true, modelUrl, originPath: `${modelUrl}/${id}`, insurancePolicy }
      }
    };
  };

  const headerBtnOptions = () => {
    if (canManageInsurancePolicy()) return adminHeaderBtnOptions();
    return {
      icon: endorsementRequested ? '' : 'add',
      name: endorsementRequested ? 'Endoso Solicitado' : 'Solicitar Endoso',
      onClick: handleModalBody,
      disabled: endorsementRequested
    };
  };

  const secondBtnOptions = () => {
    if (canManageInsurancePolicy()) return adminSecondBtnOptions();
    return {
      icon: 'renewal-file',
      iconSize: 'sm',
      name: 'Renovar',
      onClick: () => redirectTo(history, `/renewals/${renewalId}`)
    };
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowInsurancePolicy, [moreData]);

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isFetching && (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={`${accountName} - N° ${policyNumber}`}
          headerTag={showHeaderTag && <HeaderTag variant={headerTagProps.variant} text={headerTagProps.text} />}
          headerBtn={active}
          headerBtnOptions={headerBtnOptions()}
          secondBtn
          secondBtnOptions={secondBtnOptions()}
          formComponent={
            <InsurancePolicyInfo
              userModule="broker"
              modelUrl={modelUrl}
              insurancePolicy={insurancePolicy}
              setMoreData={setMoreData}
            />
          }
        />
      )}
      <SimpleCenteredModal show={modalShow} body={modalBody} onHide={handleModalClose} />
    </>
  );
};

export default InsurancePolicyShow;
