import React from 'react';
import { useHistory } from 'react-router-dom';

import { useDestroyOne } from '../../../hooks';
import { deleteAdminLeadProposalRequest } from '../../../requests/admin/leadProposals';
import { deleteProposalAdminInsurancePolicyRequest } from '../../../requests/admin/adminInsurancePolicies';
import sendDocumentEmail from '../../Shared/SendDocumentEmail';
import { leadProps } from '../../Shared/SendEmailModalProps';
import { InfoCard } from '../../Utils/Cards';
import leadCoverageObject from './leadCoverageObject';

const InfoCardCoverage = ({ fromAdmin, lead, modelUrl, setMoreData, userModule }) => {
  const history = useHistory();

  const { SendEmailModal, openEmailModal } = sendDocumentEmail({
    fromAdmin,
    executiveModel: { id: lead.id, name: 'Lead' },
    showCcField: true,
    textCancelButton: 'Cerrar',
    fileSection: true,
    fileSectionLabel: 'Adjuntos'
  });

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminLeadProposalRequest,
    parentId: lead.id,
    modelName: 'Propuesta',
    nameEndsInA: true,
    setMoreData
  });

  const { handleShowModal: deleteProposal, OnDestroyModalComponent: DeleteProposalModalComponent } = useDestroyOne({
    deleteRequest: deleteProposalAdminInsurancePolicyRequest,
    modelName: 'Propuesta',
    nameEndsInA: true,
    setMoreData
  });

  const leadInfo = leadCoverageObject({
    deleteProposal,
    fromAdmin,
    handleShowModal,
    history,
    lead,
    modelUrl,
    openEmailModal,
    userModule
  });

  return (
    <InfoCard title="Información de la cobertura" object={leadInfo} className="h-100">
      <OnDestroyModalComponent />
      <DeleteProposalModalComponent />
      <SendEmailModal {...leadProps({ lead })} />
    </InfoCard>
  );
};

export default InfoCardCoverage;
