import React from 'react';

const Build = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46729)">
        <path d="M14 3.5H15.75V5.25H17.5V7H15.75V8.75H14V7H12.25V5.25H14V3.5Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 10.5V5.25H3.5V17.5H15.75V10.5H10.5ZM5.25 7H8.75V10.5H5.25V7ZM8.75 12.25V15.75H5.25V12.25H8.75ZM14 12.25V15.75H10.5V12.25H14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46729">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Build;
