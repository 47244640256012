import React from 'react';

import IconGG from '../Utils/Icon';

const InfoBox = ({ className, icon, text, variant }) => (
  <div className={`info-bg-${variant} mb-4 d-flex align-items-center ${className}`}>
    <div className="mr-3">
      <IconGG icon={icon} />
    </div>
    <p>{text}</p>
  </div>
);

InfoBox.defaultProps = {
  className: '',
  icon: 'info',
  text: '',
  variant: 'primary'
};

export default InfoBox;
