import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal, StatisticCard } from '../../../components';
import { ModalBodyIcon } from '../../../components/Shared';
import debtContractRequest from '../../../requests/admin/bulkUploads';
import InsurancePolicyDebtContractForm from './Form/InsurancePolicyDebtContractForm';

const InsurancePolicyDebtContract = ({ errorMessage, handleModalClose, handleModalOpen, ...props }) => {
  const { setErrorMessage, setModalBody } = props;

  const [modalConfirmationBody, setModalConfirmationBody] = useState('');
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    setErrorMessage(response.data.join(', '));
    if (response.data.length > 0) return;

    dispatch(sendAlert({ kind: 'success', message: 'Pólizas actualizadas con éxito' }));
    handleModalClose();
  };

  const handleDebtContractRequest = (values, setSubmitting) => {
    setModalConfirmationShow(false);
    debtContractRequest({
      dispatch,
      params: snakeCaseKeys(values.insurancePolicy),
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleModalConfirmation = (values, setSubmitting) => {
    setModalConfirmationShow(true);
    setModalConfirmationBody(
      <ModalBodyIcon
        icon="reorder"
        iconVariant="danger"
        iconClass="text-dark"
        submitVariant="strong-danger"
        content="esta acción afecta a todas las pólizas con deuda y es"
        highlightedText="irreversible"
        highlightedVariant="light-danger"
        formRequest={() => handleDebtContractRequest(values, setSubmitting)}
        handleModalClose={() => {
          setModalConfirmationShow(false);
          setSubmitting(false);
        }}
      />
    );
  };

  const handleModalBody = () => (
    <InsurancePolicyDebtContractForm
      insurancePolicy={{ debtContracts: '' }}
      formRequest={handleModalConfirmation}
      handleModalClose={handleModalClose}
      errorMessage={errorMessage}
    />
  );

  const handleOnClickBtn = () => handleModalOpen({ title: 'Marcar pólizas con deuda', body: handleModalBody });

  const handleUpdateModalBody = () => setModalBody(handleModalBody);

  useEffect(handleUpdateModalBody, [errorMessage]);

  return (
    <Col sm={6} lg={4} className="mt-5">
      <StatisticCard
        icon="export"
        variant="primary-light"
        title="Pólizas con deuda"
        clickable
        onClick={handleOnClickBtn}
      />
      <SimpleCenteredModal
        show={modalConfirmationShow}
        body={modalConfirmationBody}
        onHide={() => null}
        closeButton={false}
      />
    </Col>
  );
};

export default InsurancePolicyDebtContract;
