import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Breadcrumb as BSBreadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { setFilters } from '../../../actions/utils';
import { redirectTo } from '../../../services/utils';
import { IndexFiltersContext } from '../../Shared';
import IconGG from '../Icon';

const Breadcrumb = ({ routes, previousFilterParams }) => {
  const filterStatus = useContext(IndexFiltersContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { originPath } = filterStatus;

  return (
    <Col xs={12}>
      <Row className="d-flex align-items-center custom-breadcrumb">
        <Button
          variant="transparent"
          className="primary-light-blue no-border no-shadow no-bg-active p-0 rounded-border"
          onClick={() => {
            if (previousFilterParams) dispatch(setFilters(previousFilterParams));
            if (originPath) redirectTo(history, originPath, filterStatus);
            else history.goBack();
          }}
        >
          <IconGG className="icon-btn--icon mx-3" icon="chevron-left" size="md" />
        </Button>
        <BSBreadcrumb>
          {routes.map((route, index) => (
            <BSBreadcrumb.Item
              key={`breadcrumb-${route.key.toString()}`}
              active={routes.length === index + 1}
              onClick={route.onClick}
              href={route.href}
              className="h4 m-0 text-lowercase"
            >
              {route.name}
            </BSBreadcrumb.Item>
          ))}
        </BSBreadcrumb>
      </Row>
    </Col>
  );
};

Breadcrumb.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      key: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Breadcrumb;
