import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { es } from 'date-fns/locale';

import { sendAlert } from '../../actions/utils';
import { closeLeadRequest, rollbackRequestedLeadRequest } from '../../requests/lead';
import { closeAdminLeadRequest, rollbackRequestedAdminLeadRequest } from '../../requests/admin/lead';
import { ModalBodyIcon } from '../Shared';
import { BtnCheckbox, IconBtn } from '../Utils/Button';
import { BasicTextArea } from '../Utils/Input';
import { UploadFile } from '../Utils/Upload';

const CloseQuotationModal = ({
  fromAdmin,
  handleModalClose,
  isSuccess,
  lead,
  modelUrl,
  // selectedFile,
  // setSelectedFile,
  selectedQuotation,
  setShowSuccessModal
}) => {
  const submitBtnText = isSuccess ? 'Aprobar' : 'Cierre no exitoso';
  const variant = isSuccess ? 'submit' : 'strong-danger';
  // const highlightedVariant = isSuccess ? 'light-success' : 'light-danger';
  const iconVariant = isSuccess ? 'success' : 'danger';
  const icon = isSuccess ? 'check-o' : 'close-o';
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);

  const [currentComments, setCurrentComments] = useState('');
  const [selectedValidityStart, setSelectedValidityStart] = useState(lead.validityStart);
  const [notifyBroker, setNotifyBroker] = useState(true);
  const isDisabled = !currentComments || !selectedFile;

  const handleSuccessRequest = () => {
    const kind = isSuccess ? 'success' : 'error';
    dispatch(sendAlert({ kind, message: submitBtnText }));
    if (isSuccess) setShowSuccessModal(true);
    else history.push(modelUrl);
  };

  const handleCloseLeadParams = () => {
    const closeStatus = isSuccess ? 'success' : 'failure';
    let sendParams = { close_status: closeStatus };
    if (isSuccess) {
      sendParams.comments = currentComments;
      sendParams.lead_quotations_attributes = [{ id: selectedQuotation.id, acceptance: selectedFile, selected: true }];
    } else {
      sendParams = {
        ...sendParams,
        validity_start: selectedValidityStart,
        comments: currentComments,
        notify_broker: notifyBroker ? 'true' : 'false'
      };
    }
    return { lead: sendParams };
  };

  const handleCloseLeadRequest = () => {
    const sendParams = handleCloseLeadParams();
    const closeRequest = fromAdmin ? closeAdminLeadRequest : closeLeadRequest;
    closeRequest(lead.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest
    });
  };

  const handleRollbackCloseLeadParams = () => {
    const sendParams = {
      close_status: null,
      validity_start: selectedValidityStart,
      comments: currentComments,
      notify_broker: notifyBroker ? 'true' : 'false'
    };
    return { lead: sendParams };
  };

  const handleRollbackCloseLeadRequest = () => {
    const sendParams = handleRollbackCloseLeadParams();
    const closeRequest = fromAdmin ? rollbackRequestedAdminLeadRequest : rollbackRequestedLeadRequest;
    closeRequest(lead.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest
    });
  };

  const dateFormat = date => {
    if (!date) return '';
    if (date instanceof Date) return date;
    const dateArray = date.split('/');
    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
  };

  const handleChecked = () => {
    setNotifyBroker(true);
  };

  const handleUnchecked = () => {
    setNotifyBroker(false);
  };

  useEffect(() => {
    if (lead.validityStart) {
      const initialValidityStart = dateFormat(lead.validityStart);
      initialValidityStart.setFullYear(initialValidityStart.getFullYear() + 1);
      setSelectedValidityStart(initialValidityStart);
    }
  }, [lead.validityStart]);

  return (
    <>
      {isSuccess ? (
        <CloseQuotationSuccess
          iconVariant={iconVariant}
          icon={icon}
          currentComments={currentComments}
          setCurrentComments={setCurrentComments}
          setSelectedFile={setSelectedFile}
          handleModalClose={handleModalClose}
          handleCloseLeadRequest={handleCloseLeadRequest}
          isDisabled={isDisabled}
        />
      ) : (
        <ModalBodyIcon
          icon={icon}
          iconVariant={iconVariant}
          content="Estás por hacer un cierre no exitoso"
          confirmText={false}
          subContent={
            <>
              <p>
                Puedes hacer que vuelva a la etapa 1 para volver a intentarlo más adelante o puedes hacer que avance a
                la etapa 3 y no volver a intentarlo.
              </p>
              <p>
                Ingresa una nueva fecha de inicio de vigencia u objetivo. Puedes dejarlo como recordatorio para una
                futura oportunidad de renovación.
              </p>

              <Row>
                <Col md={12}>
                  <div className="form-datepicker m-0">
                    <Form.Label>
                      Inicio vigencia <abbr className="text-danger">*</abbr>
                    </Form.Label>
                    <DatePicker
                      className="form-control m-0"
                      selected={dateFormat(selectedValidityStart)}
                      dateFormat="dd/MM/yyyy"
                      autoComplete="off"
                      onChange={selectedStartDate => {
                        setSelectedValidityStart(selectedStartDate);
                      }}
                      placeholderText="dd/mm/aaaa"
                      locale={es}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <BasicTextArea
                    abbr
                    label="Comentarios"
                    onChange={e => {
                      setCurrentComments(e.target.value);
                    }}
                    placeholder="Deja un comentario para saber por qué no fue posible hacer el cierre."
                    value={currentComments}
                    row={3}
                  />
                </Col>
                <Col md={12}>
                  <BtnCheckbox
                    label="Notificar al corredor"
                    checked={notifyBroker}
                    onClickChecked={handleChecked}
                    onClickUnchecked={handleUnchecked}
                  />
                </Col>
              </Row>
            </>
          }
          formContent={
            <Row className="justify-content-center">
              <Col md={4}>
                <Button block variant="cancel" className="no-shadow btn-sm" onClick={handleModalClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  block
                  type="submit"
                  variant={variant}
                  className="no-shadow btn-sm"
                  onClick={handleRollbackCloseLeadRequest}
                  disabled={!selectedValidityStart || !currentComments}
                >
                  Volver a etapa 1
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  block
                  type="submit"
                  variant={variant}
                  className="no-shadow btn-sm"
                  onClick={handleCloseLeadRequest}
                  disabled={!selectedValidityStart || !currentComments}
                >
                  Avanzar a etapa 3
                </Button>
              </Col>
            </Row>
          }
          highlightedText=""
          highlightedVariant="white"
          handleModalClose={handleModalClose}
          submitVariant={variant}
        />
      )}
    </>
  );
};

const CloseQuotationSuccess = ({ iconVariant, icon, variant, handleModalClose, isDisabled, ...props }) => {
  const { setCurrentComments, currentComments, setSelectedFile, handleCloseLeadRequest } = props;
  const handleOnChange = e => {
    setCurrentComments(e.target.value);
  };
  return (
    <>
      <Row>
        <Col xs={12} className="modal-body-icon__icon text-center">
          <IconBtn noBtn variant={iconVariant} icon={icon} iconSize="xxl" />
        </Col>
        <Col xs={12} className="modal-body-icon__content text-center">
          <h6>Estás por aprobar la contratación de una póliza.</h6>
        </Col>

        <Col xs={12} className="modal-body-icon__sub-content mb-0 text-center">
          <p className="mb-0">
            A continuación, especifica los detalles de la cobertura aprobada (plan, deducible, prima, etc.)
            <abbr className="text-danger">*</abbr>
          </p>
        </Col>

        <Col xs={12}>
          <BasicTextArea onChange={handleOnChange} value={currentComments} row={2} />
        </Col>

        <Col xs={12} className="modal-body-icon__sub-content mt-3">
          <p className="mb-0">
            Finalmente, adjunta la aprobación del cliente.
            <abbr className="text-danger">*</abbr>
          </p>
        </Col>

        <Col xs={12}>
          <UploadFile
            abbr
            fileAccept="image/*, application/pdf"
            label="Aprobación"
            onChange={file => setSelectedFile(file)}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mt-4">
          <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={6} className="mt-4">
          <Button
            block
            type="submit"
            variant={variant}
            className="no-shadow"
            onClick={handleCloseLeadRequest}
            disabled={isDisabled}
          >
            Aprobar
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default CloseQuotationModal;
