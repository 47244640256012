const paymentMethodOptions = [
  { label: 'Manual', value: 'manual' },
  { label: 'PAT', value: 'pat' },
  { label: 'PAC', value: 'pac' }
];

const renewalTypeOptions = [
  { label: 'Prorrogable', value: 'extendable' },
  { label: 'Renovable', value: 'renewable' },
  { label: 'No renovable', value: 'non_renewable' }
];

const policyTypeOptions = [
  { label: 'Póliza', value: 'contract' },
  { label: 'Propuesta', value: 'proposal' }
];

export { paymentMethodOptions, policyTypeOptions, renewalTypeOptions };
