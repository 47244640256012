import React from 'react';

import { HeaderFilter } from '../../../components';
import {
  indexInsuranceCategoryRequest,
  debounceIndexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';
import {
  indexInsuranceCompanyRequest,
  debounceIndexInsuranceCompanyRequest
} from '../../../requests/insuranceCompanies';
import {
  indexInsuranceBrokerRequest,
  debounceIndexInsuranceBrokeresRequest
} from '../../../requests/admin/insuranceBrokers';
import {
  insurancePolicyAdvancedOptions,
  insuranceSearchOptions,
  insuranceTabsOptions
} from './InsurancePolicyFiltersParams';
import { debounceIndexAdminUsersRequest, indexAdminUserRequest } from '../../../requests/admin/adminUsers';

const InsurancePolicyFiltersHeader = ({
  customParams,
  filterHeaderComponentProps = {},
  fromAdmin,
  setCustomParams
}) => {
  const adminSelectorOptions = [
    {
      fieldName: 'executiveId',
      placeholder: 'Ejecutivo',
      requestParams: { sortColumn: 'email', for_executives: 'present', admins: 'present', active: 'present' },
      indexRequest: indexAdminUserRequest,
      debounceRequest: debounceIndexAdminUsersRequest
    },
    {
      fieldName: 'insuranceBrokerId',
      placeholder: 'Corredor',
      indexRequest: indexInsuranceBrokerRequest,
      debounceRequest: debounceIndexInsuranceBrokeresRequest
    }
  ];
  const baseSelectorOptions = [
    {
      fieldName: 'insuranceCompanyId',
      placeholder: 'Compañía',
      indexRequest: indexInsuranceCompanyRequest,
      debounceRequest: debounceIndexInsuranceCompanyRequest
    },
    {
      isMulti: true,
      fieldName: 'insuranceCategoryId',
      placeholder: 'Ramo',
      indexRequest: indexInsuranceCategoryRequest,
      debounceRequest: debounceIndexInsuranceCategoryRequest
    }
  ];
  const selectorOptions = [...(fromAdmin ? adminSelectorOptions : []), ...baseSelectorOptions];

  return (
    <HeaderFilter
      paramName="isValid"
      tabOptions={insuranceTabsOptions}
      advancedOptions={filterHeaderComponentProps.advancedOptions || insurancePolicyAdvancedOptions}
      remoteSelectors={selectorOptions}
      searchOptions={insuranceSearchOptions}
      customParams={customParams}
      setCustomParams={setCustomParams}
      dateFilterTooltipText="Busca por rangos de fecha de<br/>
                             inicio de vigencia de la póliza"
    />
  );
};

export default InsurancePolicyFiltersHeader;
