import React, { useContext, useEffect, useState } from 'react';
import snakeCaseKeys from 'snakecase-keys';

import { HeaderFilter } from '../../../components';
import { IndexFiltersContext } from '../../../components/Shared';
import {
  indexInsuranceCategoryRequest,
  debounceIndexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';
import { advancedOptions, insuredLeadParams, tabOptions } from './InsuredLeadFilterParams';

const InsuredLeadFiltersHeader = ({ setMoreData }) => {
  const { customParamsRef } = useContext(IndexFiltersContext);
  const [customParams, setCustomParams] = useState(insuredLeadParams);

  const selectorOptions = [
    {
      isMulti: true,
      fieldName: 'insuranceCategoryId',
      placeholder: 'Ramo',
      indexRequest: indexInsuranceCategoryRequest,
      debounceRequest: debounceIndexInsuranceCategoryRequest
    }
  ];

  const handleOnChangeParams = () => {
    customParamsRef.current = snakeCaseKeys(customParams);
    setMoreData(m => !m);
  };

  const dateFilterTooltipText = () => {
    return `Selecciona las fechas de inicio y término para cruzar por la fecha de 'Inicio de vigencia'.<br/>
    Recuerda que esta puede ser el término de vigencia de la póliza actual, o simplemente una fecha objetivo.`;
  };

  useEffect(handleOnChangeParams, [customParams]);

  return (
    <HeaderFilter
      paramName="quotationStatus"
      tabOptions={tabOptions}
      advancedOptions={advancedOptions}
      remoteSelectors={selectorOptions}
      customParams={customParams}
      setCustomParams={setCustomParams}
      dateFilterTooltipText={dateFilterTooltipText()}
    />
  );
};

export default InsuredLeadFiltersHeader;
