import { BasicTextArea, FormikSelect } from '../../../components';

export const fieldOptions = [
  {
    key: 1,
    Component: BasicTextArea,
    name: 'contactDetail',
    fieldProps: {
      abbr: true,
      placeholder: 'Máximo 2000 caracteres',
      row: 6
    },
    yupProps: {
      requiredMessage: 'Debes ingresar un detalle de contacto',
      characterLimit: 2000
    }
  },
  {
    key: 2,
    Component: FormikSelect,
    name: 'accountId',
    title: 'Cuenta',
    description: 'Selecciona la Cuenta (persona natural o jurídica) por la que nos quieres contactar.',
    fieldProps: {
      abbr: true,
      options: [],
      placeholder: 'Seleccionar cuenta'
    },
    yupProps: {
      requiredMessage: 'Debes seleccionar una cuenta'
    },
    isSelector: true,
    selectorProps: ({ accounts, customFieldProps, name, setFieldValue }) => {
      const fieldProps = customFieldProps;
      fieldProps.options = accounts;
      fieldProps.onChange = data => setFieldValue(`insuredContact[${name}]`, data ? data.value : '');
    }
  }
];

const extractAttributes = () => {
  const fieldAttribute = {};
  fieldOptions.forEach(({ name }) => {
    fieldAttribute[name] = '';
  });
  return fieldAttribute;
};

export const insuredContactAttributes = extractAttributes();
