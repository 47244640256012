import React from 'react';

import { InfoCard, StatisticCard } from '../../components';
import userObject from './InfoCard/userObject';

const UserInfo = ({ user }) => {
  const userInfo = userObject({ user });

  const {
    accountWithClientsCount,
    parsedMonthlyCommission,
    parsedPoliciesValidTotalNetPremium,
    policiesWithDebtCount,
    policiesWithSinisterCount,
    validInsurancePoliciesCount
  } = user.insuranceBrokerAttributes;

  return (
    <div className="user-show-layout">
      <div className="user-show-layout--main">
        <InfoCard title="Información general" object={userInfo} />

        <div className="statistics">
          <StatisticCard icon="briefcase" variant="primary-light" title="Clientes" value={accountWithClientsCount} />
          <StatisticCard
            icon="notes"
            variant="primary-light"
            title="Pólizas vigentes"
            value={validInsurancePoliciesCount}
          />
          <StatisticCard
            icon="dollar"
            variant="success"
            title="Prima Total Neta"
            value={parsedPoliciesValidTotalNetPremium}
          />
          <StatisticCard
            icon="dollar"
            variant="success"
            title="Comisión mensualizada"
            value={parsedMonthlyCommission}
            tooltipText="Corresponde al ingreso mensualizado generado <br/>
              por cada póliza vigente. Es decir, si una póliza <br/>
              vigente tiene vigencia de 6 meses, su comisión <br/>
              por esa póliza se incluye dividida por 6. Esto le <br/>
              ayudará a ver cuánto está generando en <br/>
              promedio mensualmente."
          />
          <StatisticCard icon="smile-sad" variant="danger" title="Siniestros" value={policiesWithSinisterCount} />
          <StatisticCard icon="dollar" variant="danger" title="Deudas" value={policiesWithDebtCount} />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
