import React from 'react';
import { Button } from 'react-bootstrap';

import { isObject } from '../../services/utils';
import IconGG from '../Utils/Icon';

const StepMap = ({ currentStep, steps, showSummaryIcon = true }) => (
  <div className="custom-steps-map-container">
    <hr className="custom-steps-map--line" />
    <div className="custom-steps-map">
      {steps.map((step, index) => {
        const summaryStepNumber = steps.length;
        const stepNumber = isObject(step) ? step.value : step;
        const isActiveStep = stepNumber === currentStep;

        return isObject(step) ? (
          <div key={`step-map-${index.toString()}`}>
            <ButtonComponent
              currentStep={currentStep}
              stepNumber={stepNumber}
              summaryStepNumber={summaryStepNumber}
              showSummaryIcon={showSummaryIcon}
            />
            <p className={`mt-2 ${isActiveStep ? 'font-weight-bold' : 'font-weight-normal'}`}>{step.label}</p>
          </div>
        ) : (
          <ButtonComponent
            key={`step-map-${index.toString()}`}
            currentStep={currentStep}
            stepNumber={stepNumber}
            summaryStepNumber={summaryStepNumber}
            showSummaryIcon={showSummaryIcon}
          />
        );
      })}
    </div>
  </div>
);

const ButtonComponent = ({ currentStep, stepNumber, summaryStepNumber, showSummaryIcon }) => (
  <Button className={`custom-steps-map--step btn non-clickable ${stepNumber <= currentStep ? 'filled' : ''}`}>
    {stepNumber === summaryStepNumber && showSummaryIcon ? <IconGG icon="eye" size="lg" /> : <>{stepNumber}</>}
  </Button>
);

export default StepMap;
