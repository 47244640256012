import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { sendAlert } from '../../actions/utils';
import approveQuotationRequest from '../../requests/insured/insuredLeadQuotations';
import { SimpleCenteredModal } from '../Utils/Modal';
import ModalBodyIcon from '../Shared/ModalBodyIcon';

const ApproveQuotation = ({ indexPath, lead, leadQuotation, quotationTitle, disabled }) => {
  const [onRequest, setOnRequest] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleModalClose = () => setShowModal(false);

  const handleApproveQuotationRequest = () => {
    setOnRequest(true);
    approveQuotationRequest(lead.id, leadQuotation.id, {
      dispatch,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cotización aprobada con éxito' }));
        history.push(indexPath);
      },
      callback: () => setOnRequest(false)
    });
  };

  return (
    <>
      <Button
        variant="secondary"
        className="btn-sm"
        onClick={() => setShowModal(true)}
        disabled={onRequest || disabled}
      >
        Aprobar
      </Button>

      <SimpleCenteredModal
        show={showModal}
        onHide={handleModalClose}
        body={
          <ModalBodyIcon
            highlightedText="contratar esta cotización"
            highlightedVariant="light-warning"
            handleModalClose={handleModalClose}
            formRequest={handleApproveQuotationRequest}
            subContent={`Cotización seleccionada: ${quotationTitle}`}
          />
        }
      />
    </>
  );
};

export default ApproveQuotation;
