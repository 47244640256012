import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';

const CustomBtn = ({ children, customClass, variant, to, tooltipText, direction, delayShow, delayHide, ...props }) => (
  <>
    <span data-tip data-for={`registerTip-custom-btn-${tooltipText}`} className={customClass}>
      <Button {...props} variant={variant}>
        {children}
      </Button>
    </span>
    {tooltipText && (
      <ReactTooltip
        id={`registerTip-custom-btn-${tooltipText}`}
        delay={{ show: delayShow, hide: delayHide }}
        place={direction}
      >
        {tooltipText}
      </ReactTooltip>
    )}
  </>
);

CustomBtn.propTypes = {
  to: PropTypes.string,
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number
};

CustomBtn.defaultProps = {
  to: null,
  direction: 'top',
  delayShow: 250,
  delayHide: 0
};

export default CustomBtn;
