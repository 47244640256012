import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import {
  showAdminInsurancePolicyRequest,
  updateAdminInsurancePolicyRequest
} from '../../../requests/admin/adminInsurancePolicies';
import { camelCaseEmptyStringRecursive, excludeKeys, redirectTo } from '../../../services/utils';
import basicInsurancePolicy from '../../InsurancePolicy/basicInsurancePolicy';
import InsurancePolicyForm from '../../InsurancePolicy/InsurancePolicyForm';
import useConvertDictionaryKeys from '../../../hooks/useConvertDictionaryKeys';

const AdminInsurancePolicyEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/insurance_policies';

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { isRenewal, leadModelUrl, leadPath, policyType } = !!location.state && location.state;
  const isProposal = policyType === 'proposal';

  const [insurancePolicy, setInsurancePolicy] = useState(basicInsurancePolicy);
  const [modalShow, setModalShow] = useState(false);
  const { convertDictionaryKeys } = useConvertDictionaryKeys();

  let handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Detalle de póliza', href: `${modelUrl}/${id}` },
    { key: 3, name: 'Editar póliza' }
  ];

  const fromLeadBreadcrumb = [
    { key: 1, name: isRenewal ? 'Renovaciones' : 'Ventas', href: leadModelUrl },
    { key: 2, name: `Detalle de ${isRenewal ? 'renovación' : 'oportunidad'}`, href: leadPath },
    { key: 3, name: 'Editar propuesta' }
  ];

  if (isProposal) handleBreadcrumb = fromLeadBreadcrumb;

  const fetchInsurancePolicy = () => {
    showAdminInsurancePolicyRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: response => setInsurancePolicy(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: `${isProposal ? 'Propuesta' : 'Póliza'} actualizada con éxito` }));
    if (isProposal) redirectTo(history, leadPath);
    else redirectTo(history, `${modelUrl}/${id}`);
  };

  const handleParams = ({ acceptance, contract, contractProposal, quotation, ...currentInsurancePolicy }) => {
    const contractKeys = ['contract', 'contractProposal'];
    const proposalKeys = ['acceptance', 'proposalBody', 'proposalTitle', 'quotation'];
    const filteredParams = excludeKeys(currentInsurancePolicy, isProposal ? contractKeys : proposalKeys);
    let sendParams = snakeCaseKeys(filteredParams, { exclude: ['_destroy'] });
    if (sendParams.insurance_items_attributes) {
      sendParams.insurance_items_attributes = convertDictionaryKeys(sendParams.insurance_items_attributes);
    }
    if (isProposal) sendParams = { ...sendParams, acceptance, quotation };
    else sendParams = { ...sendParams, contract, contract_proposal: contractProposal };

    return sendParams;
  };

  const handleUpdateInsurancePolicyRequest = (values, setSubmitting) => {
    const sendParams = handleParams(values.insurancePolicy);
    updateAdminInsurancePolicyRequest(id, {
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => {
        setSubmitting(false);
        setModalShow(false);
      }
    });
  };

  useEffect(fetchInsurancePolicy, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <InsurancePolicyForm
          userModule="admin"
          fromAdmin
          action="edit"
          modelUrl={modelUrl}
          insurancePolicy={insurancePolicy}
          formRequest={handleUpdateInsurancePolicyRequest}
          proposalOptions={{ isProposal, isRenewal, modalShow, setModalShow }}
        />
      }
    />
  );
};

export default AdminInsurancePolicyEdit;
