import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { IconBtn } from '../../Utils/Button';
import PartnerLandingUiDashboard from '../../../assets/images/Landing/PartnerLanding/ui-dashboard.webp';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const PartnerLandingMain = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <main id="mainpartner" className="partner-landing__heropartner ">
      <div className="partner-landing__heropartner__container">
        <div className="landing--main__info col-md-6">
          <h1>Entrega más valor, mantén el foco en tu negocio y genera ingresos adicionales recurrentes.</h1>
          <p>
            NICO es un <span className="font-italic"> Broker as a Service </span>, con un modelo de distribución
            apalancado en tu cartera de clientes y que se adapta a tu modelo de ventas.
          </p>
          <IconBtn
            onClick={() => navigateToContact()}
            className="main-button btn-landing w-mobile-100 icon-btn outline-white-bgblue btn-transparent btn "
          >
            Quiero saber más
          </IconBtn>
        </div>
        <div className="col-md-6 col-sm-12 p-0 partner-landing__heropartner__hidemobile">
          <Image
            className="w-100"
            src={PartnerLandingUiDashboard}
            alt="Intefaz de dashboard con datos reales entre ellos: 75 clientes, pólizas vigentes 223."
          />
        </div>
      </div>
    </main>
  );
};

export default PartnerLandingMain;
