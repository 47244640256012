import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { TabFilters } from '../Utils/Filters';
import { SearchDateFilter } from '../Shared';
import taskTabsOptions from './taskFiltersParams';

const TaskFilters = ({ customParams, setCustomParams }) => {
  const { filters = {} } = useSelector(state => state.utils);
  const { tasksFilterTabsCounter } = filters;

  return (
    <Row className="d-flex justify-content-between align-items-center">
      <Col lg={7}>
        <TabFilters
          paramName="taskableType"
          optionsArray={taskTabsOptions(tasksFilterTabsCounter)}
          customParams={customParams}
          setCustomParams={setCustomParams}
        />
      </Col>
      <Col md={7} lg={5}>
        <SearchDateFilter
          containerClass="ml-lg-5"
          customParams={customParams}
          setCustomParams={setCustomParams}
          showDateOnMount
        />
      </Col>
    </Row>
  );
};

export default TaskFilters;
