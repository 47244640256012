import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { basicLead } from '../../../components/Lead/basicLead';
import LeadForm from '../../../components/Lead/LeadForm';
import { showLeadRequest, updateLeadRequest } from '../../../requests/lead';
import { showAdminLeadRequest, updateAdminLeadRequest } from '../../../requests/admin/lead';
import { camelCaseEmptyStringRecursive, scrollToTop } from '../../../services/utils';
import basicInsuranceItem from '../../../components/Lead/Form/basicInsuranceItem';
import useConvertDictionaryKeys from '../../../hooks/useConvertDictionaryKeys';

const AdminLeadRequestQuotation = ({ match }) => {
  const { id } = match.params;
  const currentModel = match.path.split('/');
  const isRenewal = currentModel.includes('renewals');
  const fromAdmin = currentModel.includes('admin');
  let modelUrl = isRenewal ? '/renewals' : '/leads';
  if (fromAdmin) modelUrl = `/admin${modelUrl}`;
  const showUrl = `${modelUrl}/${id}`;

  const [lead, setLead] = useState(basicLead);
  const dispatch = useDispatch();
  const history = useHistory();
  const { convertDictionaryKeys } = useConvertDictionaryKeys();

  const renewalBreadcrumb = [
    { key: 1, name: 'Renovación', href: modelUrl },
    { key: 2, name: 'Detalle de póliza', href: showUrl },
    { key: 3, name: 'Solicitud de cotización' }
  ];

  const leadsBreadcrumb = [
    { key: 1, name: 'Ventas', href: modelUrl },
    { key: 2, name: 'Detalle de oportunidad', href: showUrl },
    { key: 3, name: 'Solicitud de cotización' }
  ];

  const handleBreadcrumb = isRenewal ? renewalBreadcrumb : leadsBreadcrumb;

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cotización solicitada con éxito' }));
    history.push(showUrl);
  };

  const shouldShowDetail = (detail, item) => {
    const { showif } = detail;
    if (!showif) return true;

    const { id: showIfId, selected_option: selectedOption } = showif;
    const selectedValue = item[showIfId];
    return selectedValue && selectedOption.includes(selectedValue);
  };

  const proccessInsuranceItemsAttributes = sendParams => {
    const otherFields = sendParams.lead.dictionary_details.filter(detail => detail.id.startsWith('other'));
    if (otherFields.length === 0) return '';

    const title = 'Información de formulario de cotización:\n';
    let data = '';
    if (!data.includes(title)) {
      data += title;
    }

    sendParams.lead.insurance_items_attributes.forEach(item => {
      const otherFieldsString = otherFields
        .filter(detail => item[detail.id] !== undefined && shouldShowDetail(detail, item))
        .map(detail => `${detail.title}: ${item[detail.id]}`)
        .join('\n');

      data += otherFieldsString;
    });

    return data;
  };

  const proccessSubjectMatter = sendParams => {
    if (sendParams.lead.subject_matter) {
      return `\n${proccessInsuranceItemsAttributes(sendParams)}`;
    }

    return proccessInsuranceItemsAttributes(sendParams);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { quotationRequestFile } = values.lead;
    const sendParams = snakeCaseKeys({ lead: { ...values.lead, requested: true } }, { exclude: ['_destroy'] });
    sendParams.lead.quotation_request_file = quotationRequestFile;

    if (sendParams.lead.insurance_items_attributes) {
      if (isRenewal && sendParams.lead.dictionary_details.length > 0) {
        sendParams.lead.subject_matter += proccessSubjectMatter(sendParams);
      }

      sendParams.lead.insurance_items_attributes = convertDictionaryKeys(sendParams.lead.insurance_items_attributes);
    }

    const updateRequest = fromAdmin ? updateAdminLeadRequest : updateLeadRequest;

    updateRequest(lead.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const handleSuccessShow = response => {
    const data = camelCaseEmptyStringRecursive(response.data);
    if (!data.insuranceItemsAttributes || data.insuranceItemsAttributes.length === 0) {
      data.insuranceItemsAttributes = [basicInsuranceItem];
    }
    setLead(data);
  };

  const fetchLead = () => {
    const fetchLeadRequest = fromAdmin ? showAdminLeadRequest : showLeadRequest;

    fetchLeadRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: handleSuccessShow
    });
  };

  useEffect(scrollToTop, []);
  useEffect(fetchLead, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <LeadForm
          userModule="admin"
          action="requestQuotation"
          fromAdmin={fromAdmin}
          isRenewal={isRenewal}
          modelUrl={modelUrl}
          lead={lead}
          formRequest={handleUpdateRequest}
        />
      }
    />
  );
};

export default AdminLeadRequestQuotation;
