import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAccountContactRequest = (
  accountId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/accounts/${accountId}/contacts`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAccountContactRequest = (
  accountId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/accounts/${accountId}/contacts`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('delete', `/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAccountContactsRequest = AwesomeDebouncePromise(indexAccountContactRequest, 300);
