import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { ButtonTooltip } from '../Tooltips';

const FormikInput = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  error,
  helpText,
  inputType,
  label,
  margin,
  maxLength,
  noLabelFocus,
  optional,
  placeholder,
  simpleInput,
  size,
  tooltipHtml,
  tooltipText,
  touched,
  ...props
}) => {
  const labelRef = useRef();

  const darkenLabel = () => {
    if (label) labelRef.current.classList.add('focused');
  };

  const lightenLabel = () => {
    if (label) labelRef.current.classList.remove('focused');
  };

  return (
    <>
      <Form.Group
        className={`${simpleInput ? 'form-simple-input' : 'form-input'} ${margin}`}
        data-tip
        data-for={`registerTip-input-${tooltipText}`}
        onBlur={lightenLabel}
      >
        {label && (
          <Form.Label ref={labelRef} className={noLabelFocus ? 'text-color-white' : ''}>
            {label} {abbr && <abbr className="text-danger">*</abbr>}
            {optional && <span className="optional-label">(Opcional)</span>}
          </Form.Label>
        )}
        <Form.Control
          size={size}
          maxLength={maxLength}
          placeholder={placeholder}
          type={inputType}
          onFocus={darkenLabel}
          className={error && touched ? 'is-invalid' : ''}
          {...props}
        />
        {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
        {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
        {tooltipText && (
          <ButtonTooltip
            html={tooltipHtml}
            tooltipText={tooltipText}
            containerClassName="custom-input--tooltip"
            tabIndex="-1"
          >
            ?
          </ButtonTooltip>
        )}
      </Form.Group>
    </>
  );
};

FormikInput.propTypes = {
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  tooltipHtml: PropTypes.bool
};

FormikInput.defaultProps = {
  direction: 'top',
  delayShow: 250,
  delayHide: 0,
  tooltipHtml: true
};

export default FormikInput;
