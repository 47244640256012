import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';

import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../requests/dropzone';
import { camelCaseEmptyStringRecursive, stringCamelCaseToSnakeCase } from '../../services/utils';
import { Dropzone } from '../Utils/Upload';

const DropzoneFileSection = ({ disabled, label, modelName, multiple, originalObject, persistedFiles, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  const { temporalDropzoneFiles, setTemporalDropzoneFiles } = props; // multi-step form: keep the files loaded.
  const removedAttachmentIds = originalObject.removeAttachmentIds || [];
  const documentType = `${stringCamelCaseToSnakeCase(modelName)}_file`;

  const [documentKeys, setDocumentKeys] = useState([]);
  const [object, setObject] = useState({ ...originalObject, persistedFiles, files: temporalDropzoneFiles });

  const handleOnDrop = (code, documents) => {
    const sendParams = { drop_zone_file: { code, document_type: documentType, documents } };
    return createDropZoneFileRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: response => {
        const allDocumentKeys = documentKeys;
        const { data: dropzoneFiles } = camelCaseEmptyStringRecursive(response);
        dropzoneFiles.map(dropzoneFile => allDocumentKeys.push(dropzoneFile.documentKey));
        setDocumentKeys(allDocumentKeys);
        return response;
      }
    });
  };

  const handleOnDropUploaded = uploadedFiles => {
    if (setTemporalDropzoneFiles) setTemporalDropzoneFiles(uploadedFiles);
    setFieldValue(`${modelName}[addDocumentKeys]`, documentKeys);
    setObject({ ...object, files: uploadedFiles });
  };

  const handleOnDelete = file => {
    const sendParams = snakeCaseKeys({ dropZoneFile: { documentKey: file.code } });
    deleteDropZoneFileRequest(file.id, {
      dispatch,
      params: sendParams,
      successCallback: () => {
        const updatedDocumentKeys = documentKeys.filter(documentKey => documentKey !== file.code);
        setDocumentKeys(updatedDocumentKeys);

        if (setTemporalDropzoneFiles) {
          const updatedFiles = object.files.filter(objectFile => objectFile.code !== file.code);
          setTemporalDropzoneFiles(updatedFiles);
        }
      }
    });
  };

  const handleOnDeletePersistedFiles = attachment => {
    const repeatedId = removedAttachmentIds.some(attachmentId => attachmentId === attachment.id);
    if (repeatedId) return;

    const removeAttachmentIds = [...removedAttachmentIds, attachment.id];
    setFieldValue(`${modelName}[removeAttachmentIds]`, removeAttachmentIds);

    const updatedPersistedFiles = object.persistedFiles.filter(file => file.id !== attachment.id);
    setObject({ ...object, persistedFiles: updatedPersistedFiles });
  };

  const updatePersistedFiles = () => {
    const currentPersistedFiles = persistedFiles.filter(file => !removedAttachmentIds.includes(file.id));
    setObject({ ...object, persistedFiles: currentPersistedFiles });
  };

  useEffect(updatePersistedFiles, [persistedFiles]);

  return (
    <Row>
      <Col xs={12}>
        <Dropzone
          label={label}
          multiple={multiple}
          disabled={disabled}
          files={object.files}
          persistedFiles={object.persistedFiles}
          onDrop={handleOnDrop}
          onDropUploaded={handleOnDropUploaded}
          onDelete={handleOnDelete}
          onDeletePersistedFiles={handleOnDeletePersistedFiles}
        />
      </Col>
    </Row>
  );
};

DropzoneFileSection.defaultProps = {
  disabled: false,
  filesInfo: [],
  label: '',
  modelName: '',
  multiple: false,
  originalObject: {},
  persistedFiles: [],
  setTemporalDropzoneFiles: false,
  temporalDropzoneFiles: []
};

export default DropzoneFileSection;
