import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import InsuranceEndorsementFormDate from './InsuranceEndorsementFormDate';
import { BasicTextArea } from '../../Utils/Input';

const ContractModification = ({ modelName, insurancePolicy, sectionStructure: SectionStructure }) => (
  <>
    <SectionStructure title="Información actual de cláusulas adicionales">
      <CurrentInsurancePolicy modelName="insurancePolicy" insurancePolicy={insurancePolicy} />
    </SectionStructure>
    <SectionStructure title="Nueva información de cláusulas adicionales">
      <UpdatedInsurancePolicy modelName={modelName} insurancePolicy={insurancePolicy} />
    </SectionStructure>
  </>
);

const CurrentInsurancePolicy = ({ insurancePolicy, modelName }) => {
  const { additionalClauses } = insurancePolicy;

  return (
    <Row>
      <Col xs={12}>
        <Field name={`${modelName}[additionalClauses]`}>
          {({ field }) => (
            <BasicTextArea
              {...field}
              disabled
              label="Cláusulas adicionales"
              value={additionalClauses}
              className="p-3"
              row={6}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

const UpdatedInsurancePolicy = ({ insurancePolicy, modelName }) => {
  const { errors, touched } = useFormikContext();

  return (
    <>
      <InsuranceEndorsementFormDate
        modelName="insuranceEndorsement"
        dateLabel="Fecha de modificación"
        insurancePolicy={insurancePolicy}
      />
      <Row>
        <Col xs={12}>
          <Field name={`${modelName}[additionalClauses]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                className="p-3"
                label="Ingresa la nueva información de las cláusulas adicionales"
                placeholder="Ingresa cláusulas adicionales..."
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                row={6}
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default ContractModification;
