import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput } from '../../../components';
import AdminInsuranceCompanyExecutiveSection from './AdminInsuranceCompanyExecutiveSection';
import { BasicTextAreaWithEditor } from '../../../components/Utils/Input';

const AdminInsuranceLifeTypeSection = () => {
  const { errors, touched, setFieldValue } = useFormikContext();

  return (
    <section className="mt-5">
      <p className="section-title">Seguros Vida</p>

      <Row className="my-3">
        <Col md={6}>
          <Row>
            <Col sm={12}>
              <Field name="insuranceCompany[lifeWebsiteLink]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Sitio web"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col sm={12}>
              <Field name="insuranceCompany[lifePremiumPaymentLink]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Pago de primas"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col sm={12}>
              <Field name="insuranceCompany[lifeSinisterReportLink]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Denuncia de siniestros"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Field name="insuranceCompany[lifePaymentTransferData]">
            {({ field }) => (
              <BasicTextAreaWithEditor
                {...field}
                value={field.value}
                label="Datos de transferencia para pago"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                onChange={value => setFieldValue(field.name, value)}
                className="insurance-company-demo-editor"
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="my-3">
        <Col md={4} sm={12}>
          <AdminInsuranceCompanyExecutiveSection title="Ejecutivo de atención (Vida)" prefix="lifeExecutive" />
        </Col>
        <Col md={4} sm={12}>
          <AdminInsuranceCompanyExecutiveSection
            title="Ejecutivo de cobranza (Vida)"
            prefix="lifeCollectionExecutive"
          />
        </Col>
        <Col md={4} sm={12}>
          <AdminInsuranceCompanyExecutiveSection
            title="Ejecutivo de siniestros (Vida)"
            prefix="lifeSinisterExecutive"
          />
        </Col>
      </Row>
    </section>
  );
};

export default AdminInsuranceLifeTypeSection;
