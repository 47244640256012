import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikNumberFormat } from '../..';
import { InfoCardInsuranceItem } from '../../Shared';
import { useDidMountEffect } from '../../../hooks';
import { calculateDays, partPerThousandLimit, setFieldAttribute } from '../../../services/utils';
import InsuranceEndorsementFormDate from './InsuranceEndorsementFormDate';

const InsuranceEndorsementFormModify = ({ originalInsuranceItem, insurancePolicy }) => (
  <>
    <hr className="w-100 my-5" />
    <p className="section-title">Información actual del ítem</p>
    <CurrentInsuranceItem originalInsuranceItem={originalInsuranceItem} />

    <hr className="w-100 my-5" />
    <p className="section-title">Información modificada del ítem</p>
    <UpdatedInsuranceItem
      modelName="insuranceEndorsement"
      originalInsuranceItem={originalInsuranceItem}
      insurancePolicy={insurancePolicy}
    />
  </>
);

const CurrentInsuranceItem = ({ originalInsuranceItem }) => (
  <div className="mt-5">
    <InfoCardInsuranceItem insuranceItem={originalInsuranceItem} itemNumber={originalInsuranceItem?.itemNumber} />
  </div>
);

const UpdatedInsuranceItem = ({ originalInsuranceItem, modelName, insurancePolicy }) => {
  const { insuranceCoversAttributes = [] } = originalInsuranceItem || {};

  return (
    <>
      <InsuranceEndorsementFormDate
        modelName={modelName}
        dateLabel="Fecha de modificación"
        insurancePolicy={insurancePolicy}
      />

      <div className="mb-4">
        {insuranceCoversAttributes.map((insuranceCover, index) => (
          <NewInsuranceCoverValues
            key={`insurance-cover-${index.toString()}`}
            title={`N° ${index + 1} - ${insuranceCover.name}`}
            modelName={`${modelName}[insuranceItemAttributes][insuranceCoversAttributes][${index}]`}
            originalInsuranceItem={originalInsuranceItem}
            insurancePolicy={insurancePolicy}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

const NewInsuranceCoverValues = ({ originalInsuranceItem, modelName, title, insurancePolicy, index }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const originalModelName = `insuranceEndorsement[originalInsuranceItem][insuranceCoversAttributes][${index}]`;
  const { endorsementDate, endorsementType } = values.insuranceEndorsement;
  const { currency } = insurancePolicy;

  const { amount, netPremium } = originalInsuranceItem.insuranceCoversAttributes[index];
  const { fee = 0, variationAmount = 0, variationNetPremium = 0 } = getIn(values, modelName) || {};

  const feeToF = parseFloat(fee);
  const amountToF = parseFloat(amount);
  const netPremiumToF = parseFloat(netPremium);
  const variationAmountToF = parseFloat(variationAmount);
  const variationNetPremiumToF = parseFloat(variationNetPremium);

  const isDecrease = endorsementType === 'decrease';

  const calculateTermDuration = () => {
    const { validityStart, validityEnd } = insurancePolicy;
    const originalTermDuration = calculateDays(validityStart, validityEnd);
    const newTermDuration = calculateDays(validityStart, endorsementDate);
    return { originalTermDuration, newTermDuration };
  };

  const calculatePercentageByDate = () => {
    const { originalTermDuration, newTermDuration } = calculateTermDuration();
    const proportionalPercentage = (newTermDuration * 100) / originalTermDuration;
    return proportionalPercentage / 100;
  };

  const calculateVariations = ({ originalValue }) => {
    if (!endorsementDate || !originalValue) return { variation: 0, value: 0 };
    const proportionalPercentage = calculatePercentageByDate();
    const variation = originalValue * (1 - proportionalPercentage);
    const value = originalValue * proportionalPercentage;
    return { value, variation };
  };

  const handleOnChangeAmountAndFee = () => {
    const { variation } = calculateVariations({ originalValue: variationAmountToF });
    const newVariationNetPremium = variation * (feeToF / 1000);
    const parsedResult = Number.isNaN(newVariationNetPremium) ? 0 : newVariationNetPremium;
    setFieldAttribute(modelName, 'variationNetPremium', setFieldValue, parsedResult, ` ${currency}`);
  };

  const handleOnChangeNetPremium = () => {
    const newNetPremium = netPremiumToF + variationNetPremiumToF;
    setFieldAttribute(modelName, 'netPremium', setFieldValue, newNetPremium, ` ${currency}`);
  };

  const handleOnChangeAmount = () => {
    const newAmount = amountToF + variationAmountToF;
    setFieldAttribute(modelName, 'amount', setFieldValue, newAmount, ` ${currency}`);
  };

  const originalModelNameVariations = () => {
    setFieldAttribute(originalModelName, 'variationAmount', setFieldValue, variationAmountToF, ` ${currency}`);
    setFieldAttribute(originalModelName, 'variationNetPremium', setFieldValue, variationNetPremiumToF, ` ${currency}`);
  };

  useEffect(originalModelNameVariations, [variationAmount, variationNetPremium]);
  useEffect(handleOnChangeAmount, [variationAmount]);
  useEffect(handleOnChangeNetPremium, [variationNetPremium]);
  useDidMountEffect(handleOnChangeAmountAndFee, [fee, variationAmount, endorsementDate]);

  return (
    <Row>
      <Col xs={12}>
        <p className="section-subtitle">{title}</p>
      </Col>
      <Col sm={6} md={4} xl>
        <Field name={`${modelName}[parsedVariationAmount]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              onlyNegative={isDecrease}
              suffix={` ${currency}`}
              label="Variación monto asegurado"
              defaultValue={getIn(values, `${modelName}[variationAmount]`)}
              fieldName={`${modelName}[variationAmount]`}
              error={getIn(errors, `${modelName}[variationAmount]`)}
              touched={getIn(touched, `${modelName}[variationAmount]`)}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={4} xl>
        <Field name={`${modelName}[parsedFee]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              optional
              label="Tasa"
              placeholder="partes por mil"
              decimalScale={3}
              isAllowed={partPerThousandLimit}
              defaultValue={getIn(values, `${modelName}[fee]`)}
              fieldName={`${modelName}[fee]`}
              error={getIn(errors, `${modelName}[fee]`)}
              touched={getIn(touched, `${modelName}[fee]`)}
            />
          )}
        </Field>
      </Col>
      <Col sm={6} md={4} xl>
        <Field name={`${modelName}[parsedVariationNetPremium]`}>
          {({ field }) => (
            <FormikNumberFormat
              {...field}
              abbr
              onlyNegative={isDecrease}
              suffix={` ${currency}`}
              label="Variación prima neta"
              defaultValue={getIn(values, `${modelName}[variationNetPremium]`)}
              fieldName={`${modelName}[variationNetPremium]`}
              error={getIn(errors, `${modelName}[variationNetPremium]`)}
              touched={getIn(touched, `${modelName}[variationNetPremium]`)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default InsuranceEndorsementFormModify;
