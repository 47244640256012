const basicCompanyLanding = {
  commune: '',
  contract: {},
  email: '',
  insurancePolicyAmount: '',
  insurancePolicyDeductible: '',
  name: '',
  nationalIdentification: '',
  parsedInsurancePolicyDeductible: '',
  validityEnd: '',
  vehicleLicensePlate: '',
  vehicleUse: ''
};

const basicCompany = {
  id: '',
  landingBackgroundInfo: {},
  landingContentOne: '',
  landingContentOneTypewriter: [],
  landingContentTwo: '',
  landingPrimaryColor: '',
  landingSecondaryColor: '',
  logoInfo: {}
};

export { basicCompany, basicCompanyLanding };
