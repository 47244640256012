import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexInsuranceCategoryRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insurance_categories', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexInsuranceCategoryRequest = AwesomeDebouncePromise(indexInsuranceCategoryRequest, 300);
