import React, { useRef } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LeadDataTable } from '..';
import { useDidMountEffect, useFetchData } from '../../hooks';
import {
  indexInsuranceBrokerRequest,
  debounceIndexInsuranceBrokeresRequest
} from '../../requests/admin/insuranceBrokers';
import {
  indexInsuranceCategoryRequest,
  debounceIndexInsuranceCategoryRequest
} from '../../requests/insuranceCategories';
import { indexInsuranceCompanyRequest, debounceIndexInsuranceCompanyRequest } from '../../requests/insuranceCompanies';
import { debounceIndexAdminLeadRequest } from '../../requests/admin/lead';
import { debounceIndexLeadRequest } from '../../requests/lead';
import {
  firstStep,
  renewalParams,
  renewalSteps,
  secondStep,
  thirdStep
} from '../../screens/Lead/LeadRenewal/LeadRenewalFiltersParams';
import columns from '../../screens/AdminScreens/Lead/LeadRenewal/Columns';

const RenewableDataTable = ({ currentStep, dataCounter, fromAdmin, modelUrl, onChangeTab, setCurrentStep }) => {
  const customParamsRef = useRef({});

  const customFilterOptions = firstStep.filtersOptions.map(option => {
    let currentOption = option;
    if (option.value === dataCounter.name) currentOption = { ...option, counter: dataCounter.counter };
    return currentOption;
  });

  const options = {
    steps: renewalSteps,
    params: renewalParams,
    1: { ...firstStep, filtersOptions: customFilterOptions },
    2: secondStep,
    3: thirdStep
  };

  const { data: renewals, totalData, moreData, isFetching, handleIndexRequest, setMoreData } = useFetchData({
    debouncedIndexRequest: fromAdmin ? debounceIndexAdminLeadRequest : debounceIndexLeadRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los datos. Por favor inténtalo nuevamente',
    customParams: { renewals: true, ...customParamsRef.current },
    withDataTable: true
  });

  const baseSelectorOptions = [
    {
      fieldName: 'insuranceCompanyId',
      placeholder: 'Compañía',
      indexRequest: indexInsuranceCompanyRequest,
      debounceRequest: debounceIndexInsuranceCompanyRequest
    },
    {
      isMulti: true,
      fieldName: 'insuranceCategoryId',
      placeholder: 'Ramo',
      indexRequest: indexInsuranceCategoryRequest,
      debounceRequest: debounceIndexInsuranceCategoryRequest
    }
  ];

  const adminSelectorOptions = [
    {
      fieldName: 'insuranceBrokerId',
      placeholder: 'Corredor',
      indexRequest: indexInsuranceBrokerRequest,
      debounceRequest: debounceIndexInsuranceBrokeresRequest
    }
  ];

  const remoteSelectorOptions = [...(fromAdmin ? adminSelectorOptions : []), ...baseSelectorOptions];

  const handleOnChangeTab = () => {
    const { renewal, step, stepNumber } = camelCaseRecursive(customParamsRef.current) || {};
    setCurrentStep({ key: stepNumber, value: step });
    onChangeTab({ renewal, step });
  };

  useDidMountEffect(handleOnChangeTab, [customParamsRef.current]);

  return (
    <LeadDataTable
      fromAdmin={fromAdmin}
      tableData={renewals}
      tableDataAmount={totalData}
      modelUrl={modelUrl}
      isFetching={isFetching}
      moreData={moreData}
      setMoreData={setMoreData}
      handleIndexRequest={handleIndexRequest}
      customParamsRef={customParamsRef}
      columns={columns}
      selectorOptions={remoteSelectorOptions}
      headerOptions={options}
      defaultParams={{ sortColumn: 'step_days' }}
      dateFromField="insurancePolicyDateFrom"
      dateToField="insurancePolicyDateTo"
      initialStep={currentStep.key}
    />
  );
};

export default RenewableDataTable;
