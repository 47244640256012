import React from 'react';
import { useSelector } from 'react-redux';

import {
  PartnerLandingHowHeader,
  PartnerLandingFaqs,
  PartnerLandingPillsServices
} from '../../components/Landing/PartnerLanding';
import {
  BrokerLandingDemoSecond,
  BrokerLandingCompanies,
  BrokerLandingUiPublicity
} from '../../components/Landing/BrokerLanding';
import { LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';

const PartnerHowDoesItWork = () => {
  const { settings } = useSelector(state => state.utils);

  return (
    <WebLayout type="partner">
      <PartnerLandingHowHeader />
      {/* <LandingCenteredContent title={needABrokerMainTitle} btnText="Necesito un corredor" to="/client" /> */}
      <PartnerLandingPillsServices />
      <BrokerLandingUiPublicity />
      <PartnerLandingFaqs />
      <BrokerLandingDemoSecond />
      <BrokerLandingCompanies />
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default PartnerHowDoesItWork;
