import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../../actions/utils';
import { FormHeader } from '../../../../components';
import { createAdminRegionRequest } from '../../../../requests/admin/regions';
import AdminRegionForm from './AdminRegionForm';
import basicRegion from './basicRegion';

const AdminRegionNew = () => {
  const modelUrl = '/admin/regions';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Regiones', href: modelUrl },
    { key: 2, name: 'Crear nueva región' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Región creada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createAdminRegionRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminRegionForm action="new" region={basicRegion} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminRegionNew;
