import React from 'react';

import { IconBtn } from '../Utils/Button';

const HeaderTag = ({ text, variant }) => (
  <IconBtn noBtn variant={variant} className="btn-xs">
    {text}
  </IconBtn>
);

export default HeaderTag;
