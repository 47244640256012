import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useAbility } from '@casl/react';

import { FormHeader } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { showUserRequest } from '../../requests/user';
import basicUser from './basicUser';
import UserInfo from './UserInfo';

const UserShow = ({ match }) => {
  const modelUrl = '/users';
  const { id } = match.params;
  const ability = useAbility(AbilityContext);
  const [user, setUser] = useState(basicUser);
  const dispatch = useDispatch();

  const handleBreadcrumb = [
    { key: 1, name: 'Usuarios', href: modelUrl },
    { key: 2, name: 'Detalle de usuario' }
  ];

  const handleSuccessShow = response => {
    setUser(camelCaseRecursive(response.data));
  };

  const handleGetUser = () => {
    showUserRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(handleGetUser, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title={user.insuranceBrokerAttributes.name}
      headerBtn={ability.can('create', 'PlatformUser')}
      headerBtnOptions={{
        isIconTooltip: true,
        className: 'edit-btn',
        variant: 'primary-dark',
        icon: 'pen',
        name: 'Editar Usuario',
        path: `${modelUrl}/${id}/edit`
      }}
      formComponent={<UserInfo user={user} />}
    />
  );
};

export default UserShow;
