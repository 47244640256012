import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import IconGG from '../Icon';

const SearchFilter = ({ customParams, setCustomParams, queryKey = 'query' }) => {
  const { query } = customParams;

  const [textInput, setTextInput] = useState(query || '');

  const handleQueryChange = e => {
    const { value } = e.target;
    setTextInput(value);
    setCustomParams(params => ({ ...params, [`${queryKey}`]: value }));
  };

  const handleOnChangeCustomParams = () => setTextInput(query || '');

  useEffect(handleOnChangeCustomParams, [query]);

  return (
    <Form.Group className="d-flex search-filter--container align-items-center px-3 py-2 m-0">
      <Form.Control
        placeholder="Buscar..."
        type="search"
        name="textInput"
        value={textInput}
        onChange={handleQueryChange}
      />
      <IconGG icon="search" className="ml-2" />
    </Form.Group>
  );
};

SearchFilter.defaultProps = {
  queryKey: 'query'
};

export default SearchFilter;
