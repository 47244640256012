import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexIndustryRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/industries', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexIndustryRequest = AwesomeDebouncePromise(indexIndustryRequest, 300);
