const dictionary1 = {
  detail1: { format: 'text', required: true },
  detail2: { format: 'text', required: true },
  detail3: { format: 'text', required: true },
  detail4: { format: 'text', required: true },
  detail5: { format: 'text', required: true },
  other1: { format: 'text', required: true },
  other2: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other3: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other4: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other5: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other6: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other7: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other8: {
    format: 'text',
    required: true,
    showif: { id: 'detail5', selectedOption: ['Habitacional permanente', 'Habitacional vacacional'] }
  },
  other9: {
    format: 'text',
    required: true,
    showif: { id: 'other8', selectedOption: ['Sí'] }
  }
};

export default dictionary1;
