import React from 'react';
import { IconBtn } from '../../Utils/Button';
import IconGG from '../../Utils/Icon';

const RegisterModalMessage = ({
  title,
  message,
  icon,
  iconBgVariant,
  closeBtnMessage,
  closeBtnVariant,
  handleCloseModal
}) => {
  return (
    <div className="register-request-modal">
      <div className={`register-request-modal--icon-container ${iconBgVariant || ''}`}>
        <IconGG icon={icon} className="text-color-black" />
      </div>
      <h6 className="font-weight-900 mb-3">{title}</h6>
      <p className="font-weight-500 text-center mb-5 w-75"> {message}</p>
      {closeBtnMessage && (
        <IconBtn onClick={handleCloseModal} variant={closeBtnVariant}>
          {closeBtnMessage}
        </IconBtn>
      )}
    </div>
  );
};

export default RegisterModalMessage;
