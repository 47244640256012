import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { forgotPasswordRequest } from '../../../requests/utils';

const RecoverPasswordBox = ({ onHide, isValid, errors, touched }) => {
  return (
    <div className="login-box--container">
      <div className="login-box">
        <h6 className="login-box--title text-color-white text-center">Recuperar contraseña</h6>
        <Form className="login-box--form">
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                noLabelFocus
                label="Correo electrónico"
                placeholder="usuario@correo.com"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant="secondary"
            size="lg"
            className="my-5 w-100 btn-big"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Recuperar contraseña
          </Button>
        </Form>
        <Link className="login-box--forgot-password-link" to="/login">
          Ya tengo una cuenta
        </Link>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch, history } = props;
  forgotPasswordRequest({
    dispatch,
    params: {
      email: values.email
    },
    successCallback: result => {
      history.push('/login');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPasswordBox)
  )
);
