import React, { useState } from 'react';

import { InfoCard } from '../../Utils/Cards';
import leadObject from './leadObject';
import { SimpleCenteredModal } from '../../Utils/Modal';
import { InfoCardInsuranceItem } from '../../Shared';

const InfoCardLead = ({ lead, userModule }) => {
  const [itemsModalShow, setItemsModalShow] = useState(false);

  const dictionaryDetails = lead.dictionaryDetails || lead.insuranceCategory?.dictionaryDetails;
  const { insuranceItemsAttributes } =
    lead.hasProposal && lead.insurancePolicyProposal ? lead.insurancePolicyProposal : lead;

  const leadInfo = leadObject({
    lead,
    setItemsModalShow,
    userModule
  });

  return (
    <InfoCard title="Información del riesgo" object={leadInfo} className="h-100">
      <SimpleCenteredModal
        size="xl"
        title="Ítems"
        show={itemsModalShow}
        body={<InsuranceItemTable insuranceItems={insuranceItemsAttributes} dictionaryDetails={dictionaryDetails} />}
        onHide={() => setItemsModalShow(false)}
      />
    </InfoCard>
  );
};

const InsuranceItemTable = ({ insuranceItems, dictionaryDetails }) => {
  const currentInsuranceItems = insuranceItems.filter(insuranceItem => !insuranceItem._destroy);

  return currentInsuranceItems.map((insuranceItem, index) => (
    <InfoCardInsuranceItem
      key={`insurance-item-table-${index.toString()}`}
      insuranceItem={insuranceItem}
      dictionaryDetails={dictionaryDetails}
      itemNumber={index + 1}
    />
  ));
};

export default InfoCardLead;
