import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setFilters } from '../../../actions/utils';
import { DefaultHeader, LeadDataTable } from '../../../components';
import { IndexFiltersProvider } from '../../../components/Shared';
import { useFetchData } from '../../../hooks';
import { debounceIndexInsuredLeadRequest } from '../../../requests/insured/insuredLeads';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';
import { insuredLeadParams } from './InsuredLeadFilterParams';
import InsuredLeadFilterHeader from './InsuredLeadFilterHeader';
import columns from './columns';

const InsuredLeadIndex = ({ location }) => {
  const modelUrl = '/insured/leads';

  const customParamsRef = useRef({});
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: leads, totalData, moreData, isFetching, handleIndexRequest, setMoreData } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredLeadRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los datos. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (state) dispatch(setFilters(state));
  };

  useEffect(scrollToTop, []);
  useEffect(handleSetFiltersOnMount, [location]);

  return (
    <IndexFiltersProvider value={{ customParamsRef }}>
      <DefaultHeader
        title="Cotizaciones"
        headerBtn
        headerBtnOptions={{
          name: 'Nueva cotización',
          onClick: () =>
            redirectTo(history, `${modelUrl}/new`, {
              currentParams: camelCaseEmptyStringRecursive(customParamsRef.current)
            })
        }}
      >
        <LeadDataTable
          modelUrl={modelUrl}
          headerOptions={insuredLeadParams}
          columns={columns}
          customParamsRef={customParamsRef}
          handleIndexRequest={handleIndexRequest}
          isFetching={isFetching}
          tableData={leads}
          tableDataAmount={totalData}
          moreData={moreData}
          setMoreData={setMoreData}
          FilterHeaderComponent={InsuredLeadFilterHeader}
          filterHeaderComponentProps={{ setMoreData }}
          defaultParams={{ sortColumn: 'step_days' }}
        />
      </DefaultHeader>
    </IndexFiltersProvider>
  );
};

export default InsuredLeadIndex;
