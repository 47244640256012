import React from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';
import { debounceLicensePlateInfoPublicRequest } from '../../../../requests/public/publicSharedEndpoints';
import { FormikInput } from '../../../Utils/Input';
import { textUpperCase } from '../../../../services/utils';

const LicensePlateField = ({ keyValue, details, modelName, errors, touched, optional }) => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  const handleLicensePlateInfo = ({ licensePlate }) => {
    debounceLicensePlateInfoPublicRequest({
      dispatch,
      formData: true,
      params: snakeCaseKeys({ licensePlate }),
      successCallback: response => {
        setFieldValue(`${modelName}[detail3]`, response.data.Marca);
        setFieldValue(`${modelName}[detail4]`, response.data.Modelo);
        setFieldValue(`${modelName}[detail5]`, response.data['Año']);
        setFieldValue(`${modelName}[detail6]`, response.data['Nro. Motor']);
      }
    });
  };

  const { title: label, placeholder, required, tooltip } = details;

  return (
    <Col key={keyValue} xs={12} md={6} className="mb-4 pl-5">
      <Field name={`${modelName}[${keyValue}]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            abbr={optional ? false : required}
            label={label}
            placeholder={placeholder}
            onChange={element => {
              const licensePlate = textUpperCase(element.target.value);
              handleLicensePlateInfo({ licensePlate });
              setFieldValue(field.name, licensePlate);
            }}
            touched={getIn(touched, field.name)}
            error={getIn(errors, field.name)}
            tooltipText={tooltip}
            maxLength={6}
          />
        )}
      </Field>
    </Col>
  );
};

export default LicensePlateField;
