import React from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingCompanies = () => {
  const { insuranceCompanyLogos } = useSelector(state => state.utils);
  const { type } = useGetRoleByRoute();

  return (
    <section id="insurance-company-logos" className={`broker-landing__horizontal-info broker-landing-${type}`}>
      <h3 className="broker-landing__horizontal-info__title landing-section-title">
        al sumarte a NICO podrás trabajar con
      </h3>
      <div className="broker-landing__horizontal-info__insurance-company-logo">
        {insuranceCompanyLogos.map((insuranceCompany, index) => (
          <div
            key={`insurance-company-logo-${index.toString()}`}
            className="broker-landing__horizontal-info__insurance-company-logo__logo"
          >
            <Image src={insuranceCompany.logoInfo?.fileUrl} alt={insuranceCompany.altInfo} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default BrokerLandingCompanies;
