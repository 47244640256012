import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { IndexFiltersProvider } from '../../../components/Shared';
import { useFetchData, useIndexFilters } from '../../../hooks';
import { debounceIndexInsuredAccountRequest } from '../../../requests/insured/insuredAccounts';
import { accountFiltersParams } from './InsuredAccountFiltersParams';
import columns from './columns';
import AccountDataTable from '../../../components/Account/AccountDatatable';
import InsuredAccountFilterHeader from './InsuredAccountFilterHeader';

const InsuredAccountIndex = () => {
  const modelUrl = '/insured/accounts';

  const { filterStatus, setFilterStatus } = useIndexFilters({});
  const customParamsRef = useRef({});

  const {
    data: accounts,
    totalData: totalAccounts,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredAccountRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las cuentas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true,
    filterStatus,
    setFilterStatus
  });

  return (
    <IndexFiltersProvider value={{ customParamsRef, filterStatus, setFilterStatus }}>
      <DefaultHeader
        title="Cuentas"
        headerBtn
        headerBtnOptions={{
          icon: 'add',
          name: 'Nueva Cuenta',
          path: `${modelUrl}/new`
        }}
      >
        <AccountDataTable
          columns={columns}
          tableData={accounts}
          tableDataAmount={totalAccounts}
          modelUrl={modelUrl}
          isFetching={isFetching}
          moreData={moreData}
          setMoreData={setMoreData}
          accountFiltersParams={accountFiltersParams}
          FilterHeaderComponent={InsuredAccountFilterHeader}
          handleIndexRequest={handleIndexRequest}
        />
      </DefaultHeader>
    </IndexFiltersProvider>
  );
};

export default InsuredAccountIndex;
