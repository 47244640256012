// import infoCardElement from '../../../Utils/Cards/InfoCardMethods';

import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';
import { calculateDays, formatCurrency, handleDateFormat } from '../../../services/utils';

const riskObject = ({ insurancePolicy, setItemsModalShow, userModule }) => {
  const {
    affectPremium,
    affectCommissionPercentage,
    cadenceType,
    currency,
    exemptPremium,
    exemptCommissionPercentage,
    insuranceCategoryName,
    insuranceItemsAttributes,
    parsedEstimatedNetPrime,
    quotationRequestFileInfo = {},
    subjectMatter,
    validityStart,
    validityEnd,
    firstPaymentDate,
    translatedCadenceType,
    translatedRenewalType,
    parsedTotalPremium,
    parsedTotalNetPremium,
    parsedAffectPremium,
    parsedExemptPremium,
    parsedCommissionTotalAmount,
    parsedAffectCommissionPercentage,
    parsedExemptCommissionPercentage,
    parsedNicoCommissionPercentage,
    parsedNicoCommissionAmount,
    parsedCompanyCommissionPercentage,
    parsedBaseCommissionPercentage,
    parsedCompanyCommissionAmount,
    parsedBrokerCommissionPercentage,
    parsedBrokerCommissionAmount
  } = insurancePolicy;

  const hasQuotationRequest = Object.keys(quotationRequestFileInfo).length > 0;
  const currentInsuranceItems = insuranceItemsAttributes.filter(insuranceItem => !insuranceItem._destroy);
  const affectCommissionAmount = (affectPremium * affectCommissionPercentage) / 100;
  const exemptCommissionAmount = (exemptPremium * exemptCommissionPercentage) / 100;
  const cadenceTooltipText =
    cadenceType === 'monthly'
      ? 'Las pólizas con cadencia mensual informan un valor referencial de las primas y comisiones mensuales.<br/> Estos pueden variar mes a mes.'
      : '';

  const object = {};

  if (hasQuotationRequest)
    object.quotationRequestFile = infoCardElement({
      file: true,
      link: true,
      item: {
        title: 'Documento adjunto',
        name: 'Ver archivo',
        url: quotationRequestFileInfo.fileUrl
      }
    });
  // object.insuranceCategoryCategory = infoCardElement({
  //   string: true,
  //   key: 'Tipo de seguro',
  //   value: insuranceCategory.translatedCategory
  // });
  object.insuranceCategoryName = infoCardElement({
    string: true,
    key: 'Ramo',
    value: insuranceCategoryName
  });
  object.currency = infoCardElement({
    string: true,
    key: 'Moneda',
    value: currency
  });
  object.validityStart = infoCardElement({
    string: true,
    key: 'Inicio de vigencia',
    value: handleDateFormat(validityStart)
  });
  object.validityEnd = infoCardElement({
    string: true,
    key: 'Término de vigencia',
    value: handleDateFormat(validityEnd)
  });
  object.totalDays = infoCardElement({
    string: true,
    key: 'Duración',
    value: `${calculateDays(validityStart, validityEnd)} días`
  });
  if (firstPaymentDate)
    object.firstPaymentDate = infoCardElement({
      string: true,
      key: 'Fecha de primer pago',
      value: handleDateFormat(firstPaymentDate)
    });
  object.renewalType = infoCardElement({
    string: true,
    key: 'Tipo de renovación',
    value: translatedRenewalType
  });
  if (parsedEstimatedNetPrime && (userModule === 'admin' || userModule === 'broker')) {
    object.parsedEstimatedNetPrime = infoCardElement({
      string: true,
      key: 'Prima neta estimada',
      value: parsedEstimatedNetPrime
    });
  }
  object.subjectMatter = infoCardElement({
    string: true,
    key: 'Descripción general',
    value: subjectMatter
  });

  if (currentInsuranceItems.length > 0) {
    object.insuranceItems = infoCardElement({
      file: true,
      item: {
        title: `${currentInsuranceItems.length} ${currentInsuranceItems.length > 1 ? 'Ítems' : 'Ítem'}`,
        name: 'Ver detalle',
        onClick: () => setItemsModalShow(true)
      }
    });
  }

  object.cadence = infoCardElement({
    string: true,
    key: 'Cadencia',
    value: translatedCadenceType,
    tooltipText: cadenceTooltipText
  });
  object.totalPremium = infoCardElement({
    string: true,
    key: 'Prima total bruta',
    value: parsedTotalPremium
  });
  object.totalNetPremium = infoCardElement({
    array: true,
    key: 'Prima total neta',
    value: parsedTotalNetPremium,
    items: [
      infoCardElement({
        string: true,
        subelement: true,
        key: 'Prima afecta',
        value: parsedAffectPremium
      }),
      infoCardElement({
        string: true,
        subelement: true,
        key: 'Prima exenta',
        value: parsedExemptPremium
      })
    ]
  });
  object.commission = infoCardElement({
    array: true,
    key: 'Comisión póliza neta',
    value: parsedCommissionTotalAmount,
    items: [
      infoCardElement({
        string: true,
        subelement: true,
        key: `Comisión póliza afecta (${parsedAffectCommissionPercentage})`,
        value: formatCurrency({ value: affectCommissionAmount, addon: ` ${currency}` })
      }),
      infoCardElement({
        string: true,
        subelement: true,
        key: `Comisión póliza exenta (${parsedExemptCommissionPercentage})`,
        value: formatCurrency({ value: exemptCommissionAmount, addon: ` ${currency}` })
      })
    ]
  });
  object.nicoCommission = infoCardElement({
    string: true,
    key: `Comisión NICO (${parsedNicoCommissionPercentage})`,
    value: parsedNicoCommissionAmount
  });
  object.companyCommission = infoCardElement({
    string: true,
    key: `Comisión empresa (${parsedCompanyCommissionPercentage} del ${parsedBaseCommissionPercentage})`,
    value: parsedCompanyCommissionAmount
  });
  object.brokerCommission = infoCardElement({
    string: true,
    key: `Comisión corredor (${parsedBrokerCommissionPercentage} del ${parsedBaseCommissionPercentage})`,
    value: parsedBrokerCommissionAmount
  });

  return object;
};

export default riskObject;
