import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { updateAdminIndustryRequest, showAdminIndustryRequest } from '../../../requests/admin/industries';
import AdminIndustryForm from './AdminIndustryForm';
import basicIndustry from './basicIndustry';

const AdminCompanyEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/industries';
  const [industry, setIndustry] = useState(basicIndustry);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Rubros', href: modelUrl },
    { key: 2, name: 'Editar rubro' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Rubro actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminIndustryRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchIndustry = () => {
    showAdminIndustryRequest(id, {
      dispatch,
      successCallback: response => setIndustry(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchIndustry, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminIndustryForm action="edit" industry={industry} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminCompanyEdit;
