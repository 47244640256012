import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns, ReadMore } from '../../components';
import { DatatableTaskCreator, basicTask } from '../../components/Task';
import { DataTableBadges, DatatableMainInfo } from '../../components/Utils/DataTable';

const columns = memoize(
  (
    step,
    { setMoreData = () => null },
    {
      handleBasicActions,
      modelUrl,
      withShow = false,
      withEdit = false,
      withDestroy = false,
      showClick = false,
      editClick = false
    }
  ) => {
    const isClosed = step === 'closed';

    const leadColumns = [
      {
        name: 'Nombre o razón social',
        selector: 'account',
        sortable: true,
        grow: '2.5',
        cell: ({ accountName, accountNationalIdentification, accountLegalPersonType }) => (
          <DatatableMainInfo
            icon={accountLegalPersonType.includes('natural') ? 'user' : 'organisation'}
            title={accountName}
            subtitle={accountNationalIdentification}
            iconSize="sm"
          />
        ),
        minWidth: '150px'
      }
    ];

    if (isClosed) {
      leadColumns.push({
        name: 'F. cierre',
        selector: 'close_date',
        sortable: true,
        grow: '1',
        cell: ({ closeDate }) => closeDate
      });
    }

    leadColumns.push(
      {
        name: 'Ramo',
        selector: 'insurance_category',
        sortable: true,
        grow: '2',
        cell: ({ insuranceCategoryName }) => insuranceCategoryName
      },
      {
        name: 'Materia asegurable',
        selector: 'subject_matter',
        sortable: true,
        grow: '1.5',
        cell: ({ subjectMatter }) => <ReadMore title="Materia asegurable">{subjectMatter}</ReadMore>
      },
      {
        name: 'Contacto',
        selector: 'primary_contact',
        sortable: true,
        grow: '2',
        cell: ({ accountPrimaryContactName, accountPrimaryContactPhoneNumber }) => (
          <>
            {accountPrimaryContactName ? (
              <DatatableMainInfo
                title={accountPrimaryContactName}
                subtitle={accountPrimaryContactPhoneNumber || 'Sin teléfono'}
                noIcon
              />
            ) : (
              'Sin contacto principal'
            )}
          </>
        )
      },
      {
        name: 'Tareas',
        selector: 'tasks',
        sortable: false,
        grow: '1.5',
        minWidth: '150px',
        cell: ({ task = basicTask, id: leadId }) => (
          <DatatableTaskCreator task={task} taskableType="Lead" taskableId={leadId} setMoreData={setMoreData} />
        )
      },
      {
        name: 'Días de plazo',
        selector: 'deadline_days',
        sortable: true,
        grow: '1',
        cell: ({ deadlineDays, validityStart }) => (
          <DatatableMainInfo noIcon title={deadlineDays} subtitle={validityStart} />
        )
      },
      {
        cell: row => <DataTableBadges row={row} />,
        sortable: false,
        right: true,
        grow: '2.5'
      },
      {
        cell: item => (
          <BasicActionBtns
            item={item}
            clickHandler={handleBasicActions}
            path={modelUrl}
            withShow={withShow}
            withEdit={withEdit}
            withDestroy={withDestroy}
            showClick={showClick}
            editClick={editClick}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        grow: '1',
        minWidth: '150px'
      }
    );

    return leadColumns;
  }
);

export default columns;
