import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminPaymentPlanRequest } from '../../../requests/admin/paymentPlans';
import AdminPaymentPlanDataTable from './AdminPaymentPlanDataTable';

const AdminPaymentPlanIndex = () => {
  const customParamsRef = useRef({});
  const { data: plans, totalData: totalPlans, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPaymentPlanRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los planes de pago. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/payment_plans';

  return (
    <DefaultHeader
      title="Planes de pago"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Plan de Pago',
        path: `${modelUrl}/new`
      }}
    >
      <AdminPaymentPlanDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={plans}
        tableDataAmount={totalPlans}
      />
    </DefaultHeader>
  );
};

export default AdminPaymentPlanIndex;
