import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminCompaniesRequest } from '../../../requests/admin/companies';
import AdminCompanyDataTable from './AdminCompanyDataTable';

const AdminCompanyIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: companies,
    totalData: totalCompanies,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminCompaniesRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar las empresas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/companies';

  return (
    <DefaultHeader
      title="Empresas"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Empresa',
        path: `${modelUrl}/new`
      }}
    >
      <AdminCompanyDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={companies}
        tableDataAmount={totalCompanies}
      />
    </DefaultHeader>
  );
};

export default AdminCompanyIndex;
