import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import {
  updateAdminInsuranceCategoryRequest,
  showAdminInsuranceCategoryRequest
} from '../../../requests/admin/insuranceCategories';
import AdminInsuranceCategoryForm from './AdminInsuranceCategoryForm';
import basicInsuranceCategory from './basicInsuranceCategory';

const AdminInsuranceCategoryEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/insurance_categories';
  const [insuranceCategory, setInsuranceCategory] = useState(basicInsuranceCategory);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Ramos', href: modelUrl },
    { key: 2, name: 'Editar ramo' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Ramo actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminInsuranceCategoryRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchInsuranceCategory = () => {
    showAdminInsuranceCategoryRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: response => setInsuranceCategory(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchInsuranceCategory, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <AdminInsuranceCategoryForm
          action="edit"
          insuranceCategory={insuranceCategory}
          formRequest={handleUpdateRequest}
        />
      }
    />
  );
};

export default AdminInsuranceCategoryEdit;
