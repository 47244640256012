const basicInsuranceCover = {
  id: '',
  amount: '',
  coverageType: '',
  deductible: 0,
  fee: '',
  name: '',
  netPremium: '',
  variationAmount: '',
  variationNetPremium: ''
};

const coverageTypeOptions = [
  {
    label: 'Afecta',
    value: 'affect'
  },
  {
    label: 'Exenta',
    value: 'exempt'
  }
];

export { basicInsuranceCover, coverageTypeOptions };
