import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Breadcrumb from '../Utils/Breadcrumb';
import { BtnTooltip, IconBtn } from '../Utils/Button';
import { ButtonTooltip } from '../Utils/Tooltips';
import { capitalizeString } from '../../services/utils';

const FormHeader = ({ children, className, formComponent, handleBreadcrumb, title, headerTag, ...props }) => {
  const {
    headerBtn,
    headerBtnOptions,
    previousFilterParams,
    secondBtn,
    secondBtnOptions,
    thirdBtn,
    thirdBtnOptions,
    fourthBtn,
    fourthBtnOptions,
    tooltipText
  } = props;

  return (
    <Row className={`custom-header ${className}`}>
      <Breadcrumb routes={handleBreadcrumb} previousFilterParams={previousFilterParams} />
      {headerTag && (
        <Col xs={12} className="mt-4 mb-3">
          {headerTag}
        </Col>
      )}
      {title && (
        <Col className={`custom-header__title ${headerTag ? '' : 'mt-4'}`}>
          <h5 className="text-color-secondary">
            {title}
            {tooltipText && (
              <ButtonTooltip tooltipText={tooltipText} direction="right" className="ml-3">
                ?
              </ButtonTooltip>
            )}
          </h5>
        </Col>
      )}
      {(headerBtn || secondBtn || thirdBtn) && (
        <Col
          lg={headerBtn && secondBtn ? 7 : 4}
          xl={headerBtn && secondBtn ? 7 : 4}
          className={`custom-header__button ${headerTag ? '' : 'mt-4'} ${
            headerBtnOptions.isIconTooltip ? 'small-icon-btn' : ''
          } ${secondBtn ? 'two-buttons' : ''}`}
        >
          {headerBtn &&
            (headerBtnOptions.isIconTooltip ? (
              <BtnTooltip
                variant={headerBtnOptions.variant}
                icon={headerBtnOptions.icon}
                iconSize={headerBtnOptions.iconSize}
                to={headerBtnOptions.path}
                onClick={headerBtnOptions.onClick}
                tooltipText={capitalizeString(headerBtnOptions.name)}
                className={headerBtnOptions.className}
              />
            ) : (
              <IconBtn
                variant={headerBtnOptions.variant}
                icon={headerBtnOptions.icon}
                iconSize={headerBtnOptions.iconSize}
                to={headerBtnOptions.path}
                onClick={headerBtnOptions.onClick}
                disabled={headerBtnOptions.disabled}
                className="rounded-border"
              >
                {capitalizeString(headerBtnOptions.name)}
              </IconBtn>
            ))}
          {secondBtn &&
            (secondBtnOptions.isIconTooltip ? (
              <BtnTooltip
                variant={secondBtnOptions.variant}
                icon={secondBtnOptions.icon}
                iconSize={secondBtnOptions.iconSize}
                to={secondBtnOptions.path}
                onClick={secondBtnOptions.onClick}
                tooltipText={capitalizeString(secondBtnOptions.name)}
                className={secondBtnOptions.className}
              />
            ) : (
              <IconBtn
                variant={secondBtnOptions.variant}
                icon={secondBtnOptions.icon}
                iconSize={secondBtnOptions.iconSize}
                to={secondBtnOptions.path}
                onClick={secondBtnOptions.onClick}
                disabled={secondBtnOptions.disabled}
                className="rounded-border"
              >
                {capitalizeString(secondBtnOptions.name)}
              </IconBtn>
            ))}
          {thirdBtn &&
            (thirdBtnOptions.isIconTooltip ? (
              <BtnTooltip
                variant={thirdBtnOptions.variant}
                icon={thirdBtnOptions.icon}
                iconSize={thirdBtnOptions.iconSize}
                to={thirdBtnOptions.path}
                onClick={thirdBtnOptions.onClick}
                tooltipText={capitalizeString(thirdBtnOptions.name)}
                className={thirdBtnOptions.className}
              />
            ) : (
              <IconBtn
                variant={thirdBtnOptions.variant}
                icon={thirdBtnOptions.icon}
                iconSize={thirdBtnOptions.iconSize}
                to={thirdBtnOptions.path}
                onClick={thirdBtnOptions.onClick}
                disabled={thirdBtnOptions.disabled}
                className="rounded-border"
              >
                {capitalizeString(thirdBtnOptions.name)}
              </IconBtn>
            ))}
          {fourthBtn &&
            (fourthBtnOptions.isIconTooltip ? (
              <BtnTooltip
                variant={fourthBtnOptions.variant}
                icon={fourthBtnOptions.icon}
                iconSize={fourthBtnOptions.iconSize}
                to={fourthBtnOptions.path}
                onClick={fourthBtnOptions.onClick}
                tooltipText={capitalizeString(fourthBtnOptions.name)}
                className={fourthBtnOptions.className}
              />
            ) : (
              <IconBtn
                variant={fourthBtnOptions.variant}
                icon={fourthBtnOptions.icon}
                iconSize={fourthBtnOptions.iconSize}
                to={fourthBtnOptions.path}
                onClick={fourthBtnOptions.onClick}
                disabled={fourthBtnOptions.disabled}
                className="rounded-border"
              >
                {capitalizeString(fourthBtnOptions.name)}
              </IconBtn>
            ))}
        </Col>
      )}
      <Col xs={12} className="custom-header__form">
        {formComponent}
      </Col>
      {children}
    </Row>
  );
};

const defaultBtnProps = {
  className: '',
  disabled: false,
  icon: '',
  name: 'Nuevo',
  path: '',
  variant: 'primary'
};

FormHeader.defaultProps = {
  className: '',
  handleBreadcrumb: [],
  headerBtnOptions: { ...defaultBtnProps, isIconTooltip: false },
  iconTooltip: false,
  secondBtnOptions: defaultBtnProps
};

export default FormHeader;
