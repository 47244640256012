import React from 'react';
import { useHistory } from 'react-router-dom';
import { WhyNicoCard } from '../Shared';
import { IconBtn } from '../../Utils/Button';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const ClientLandingWhyNicoCompanies = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <section id="why-nico" className="landing--why-nico__container">
      <div className="text-center">
        <h4 className="landing--why-nico-client__titlecompanies-persons">
          Revisa aquí los tipos de protección más comunes que necesitan las empresas. Podemos apoyarte en todos.
        </h4>
      </div>
      <div className="landing--why-nico-client landing--why-nico-client__content landing--why-nico-client landing--why-nico-client__margin">
        <WhyNicoCard
          icon="fire"
          title="incendio"
          text="Activos como edificios y oficinas, incluyendo contenidos y mercaderías, entre otros. Principalmente contra incendio, explosión, riesgos políticos y riesgos de la naturaleza."
        />
        <WhyNicoCard
          icon="document"
          title="perjuicios por paralización"
          text="Margen de contribución anual, a causa de una paralización provocada por un siniestro cubierto en la póliza de Propiedad."
        />
        <WhyNicoCard
          icon="handshake"
          title="responsabilidad civil"
          text="Demandas o reclamos formales de terceros por daño físico o material que hayan sufrido, causado por la actividad de la Empresa."
        />
        <WhyNicoCard
          icon="airplane"
          title="transporte"
          text="Pérdida y/o daños que sufra la materia asegurada durante su transporte, ya sea marítimo, aéreo o terrestre, nacional o internacional."
        />
        <WhyNicoCard
          icon="car"
          title="vehículos motorizados"
          text="Daños del vehículo a causa de incendio, volcamiento y colisión, entre otros, responsabilidad civil, y robo, hurto o uso no autorizado del vehículo."
        />
        <WhyNicoCard
          icon="organisation"
          title="ingeniería y Construcción"
          text="Riesgos derivados de la ingeniería civil y construcción de nuevas construcciones, ampliaciones o remodelaciones. También cubre la responsabilidad civil por daños a terceros a causa de dichas obras."
        />
        <WhyNicoCard
          icon="check"
          title="garantía"
          text="Asegura el cumplimiento de una obligación de hacer, y se obliga a pagar al asegurado hasta un monto asegurado los perjuicios que le haya ocasionado dicho incumplimiento."
        />
        <WhyNicoCard
          icon="dollar"
          title="crédito"
          text="Protege del no pago de ventas a crédito en el mercado nacional e internacional causado por una insolvencia declarada o presunta. Además, ayuda a seleccionar a los mejores clientes."
        />
        <WhyNicoCard
          icon="graph"
          title="colectivo de vida"
          text="Protege a las familias de los trabajadores en caso de fallecimiento o invalidez."
        />
        <WhyNicoCard
          icon="stethoscope"
          title="colectivo de salud"
          text="Complementa el aporte de Isapre o Fonasa en los gastos médicos ambulatorios y hospitalarios."
        />
        <WhyNicoCard
          icon="tooth"
          title="colectivo dental"
          text="Reembolso frente a gastos dentales básicos y de especialidad como ortodoncia, implantes y/o prótesis."
        />
        <WhyNicoCard
          icon="aid"
          title="colectivo catastrófico"
          text="Complementa el aporte de Isapre o Fonasa en los gastos médicos de alto costo."
        />
        <WhyNicoCard
          icon="hand"
          title="vida Guardias y Conserjes"
          text="De carácter obligatorio según Decreto Supremo 93."
        />
        <WhyNicoCard
          icon="school"
          title="escolar frente a accidentes"
          text="Cubre gastos en salud a consecuencia de un accidente, para estudiantes desde pre-básica."
        />
        <WhyNicoCard
          icon="detective"
          title="robo/asalto"
          text="Indemnización por daño o pérdida de los bienes asegurados, derivados del robo o asalto por parte de terceros."
        />
      </div>

      <div className="d-flex justify-content-center">
        <IconBtn
          onClick={() => navigateToContact()}
          className="main-button btn-landing w-mobile-100"
          variant="secondary"
        >
          Quiero asesoría GRATIS
        </IconBtn>
      </div>
    </section>
  );
};

export default ClientLandingWhyNicoCompanies;
