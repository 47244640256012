import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import ContactFields from './ContactFields';

const ContactForm = ({
  action,
  errors,
  handleModalClose,
  isSubmitting,
  onHide,
  setFieldValue,
  touched,
  values,
  userModule
}) => {
  const handleCancelButton = () => {
    handleModalClose();
  };

  const submitBtnText = action === 'new' ? 'Crear Contacto' : 'Guardar Cambios';

  return (
    <Form>
      <ContactFields
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        userModule={userModule}
      />
      <Row className="d-flex justify-content-end mt-5">
        <Col md={6} sm={12}>
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              className="w-30 no-shadow mr-5 rounded-border"
              variant="cancel"
              onClick={() => handleCancelButton()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="submit"
              size="lg"
              onClick={onHide}
              className="w-90 no-shadow text-black-50 rounded-border"
              disabled={isSubmitting}
            >
              {submitBtnText}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ contact }) => {
  return {
    contact
  };
};

const validationSchema = Yup.object().shape({
  contact: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    addressAttributes: Yup.object().nullable()
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ContactForm);
