import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components';
import ContactForm from './ContactForm';
import { updateAccountContactRequest } from '../../requests/Account/contact';
import { updateAdminAccountContactRequest } from '../../requests/admin/adminContacts';
import { updateInsuredAccountContactRequest } from '../../requests/insured/insuredContacts';

const ContactEdit = ({ accountId, contact, handleModalClose, modalShow, setMoreData, userModule }) => {
  const dispatch = useDispatch();
  const { id } = contact;

  const handleSuccessUpdate = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Contacto actualizado con éxito' }));
    setMoreData(m => !m);
  };

  const getUpdateRequest = () => {
    const requestMap = {
      admin: updateAdminAccountContactRequest,
      broker: updateAccountContactRequest,
      insured: updateInsuredAccountContactRequest
    };

    return requestMap[userModule];
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    const updateRequest = getUpdateRequest();
    updateRequest(accountId, id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <SimpleCenteredModal
      title="Editar Contacto"
      show={modalShow}
      size="lg"
      onHide={handleModalClose}
      body={
        <ContactForm
          action="edit"
          contact={contact}
          formRequest={handleUpdateRequest}
          handleModalClose={handleModalClose}
          userModule={userModule}
        />
      }
    />
  );
};

export default ContactEdit;
