import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { ButtonTooltip } from '../Tooltips';

const FormikInputRadio = ({
  abbr,
  disabled,
  error,
  field,
  fieldName = '',
  helpText,
  label,
  margin,
  options = [],
  tooltipHtml,
  tooltipText,
  touched,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <section className="formik-input-radio">
      <div className="d-flex align-items-center">
        <Form.Label style={{ backgroundColor: 'transparent' }}>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
        </Form.Label>
        {tooltipText && (
          <ButtonTooltip
            html={tooltipHtml}
            tooltipText={tooltipText}
            containerClassName="ml-2 custom-input--tooltip"
            tabIndex="-1"
          >
            ?
          </ButtonTooltip>
        )}
      </div>
      <Form.Group className={`d-flex align-items-center form-radio-input ${margin && margin}`} {...props}>
        {options.map(option => (
          <div key={`${field.name}[${option.value}]`} className="d-flex justify-content-start align-items-center">
            <Form.Label
              htmlFor={`${field.name}[${option.value}]`}
              className={`custom-radio-label ${field.value === option.value ? 'checked' : ''} ${
                disabled ? 'disabled' : ''
              }`}
            />
            <Form.Check
              {...field}
              type="radio"
              id={`${field.name}[${option.value}]`}
              label={option.label}
              value={option.value}
              name={field.name}
              onClick={() => {
                if (fieldName) setFieldValue(fieldName, option?.label || '');
                if (field.name) setFieldValue(field.name, option?.value || '');
              }}
              disabled={disabled}
            />
          </div>
        ))}
      </Form.Group>

      {error && touched && <Form.Text className="text-danger mt-4">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted mt-4">{helpText}</Form.Text>}
    </section>
  );
};

export default FormikInputRadio;
