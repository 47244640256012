import * as Yup from 'yup';

export const baseInsuranceItemSchema = {
  insuranceCoversAttributes: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().required('Debes ingresar un monto asegurado'),
      coverageType: Yup.string().required('Debes ingresar un tipo de cobertura'),
      fee: Yup.string().nullable(),
      name: Yup.string().required('Debes ingresar un detalle cobertura'),
      netPremium: Yup.string().required('Debes ingresar una prima neta')
    })
  ),
  name: Yup.string()
    .required('Debes ingresar un detalle ítem')
    .max(160, 'Máx. 160 caracteres'),
  notes: Yup.string().max(1000, 'Máx. 1000 caracteres')
};

export const modifyOriginalInsuranceItemSchema = {
  insuranceCoversAttributes: Yup.array().of(
    Yup.object().shape({
      fee: Yup.string().nullable(),
      variationAmount: Yup.string().required('Debes ingresar un monto asegurado'),
      variationNetPremium: Yup.number()
        .required('Debes ingresar una prima neta')
        .when('netPremium', {
          is: netPremium => netPremium < 0,
          then: Yup.number().min(0, 'La prima neta debe ser mayor o igual a 0')
        })
    })
  )
};

export const insuranceItemSchemaByCase = ({
  endorsementType,
  endorsementAction,
  modificationType,
  insuranceItemAttributesSchema
}) => {
  const isCutType = ['cut'].includes(endorsementType);
  const isExtension = ['extension'].includes(endorsementAction);
  const isForModify = ['modify'].includes(endorsementAction);
  const isModification = ['modification'].includes(endorsementType) && ['item'].includes(modificationType);

  if (isModification)
    return insuranceItemAttributesSchema.shape({
      name: Yup.string()
        .required('Debes ingresar un detalle ítem')
        .max(160, 'Máx. 160 caracteres')
    });
  if (isCutType || isExtension) return insuranceItemAttributesSchema.nullable();
  if (['decrease', 'increase'].includes(endorsementType)) {
    return insuranceItemAttributesSchema.shape(
      isForModify ? modifyOriginalInsuranceItemSchema : baseInsuranceItemSchema
    );
  }
  return insuranceItemAttributesSchema.nullable();
};
