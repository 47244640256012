import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';
import { InfoBox } from '../../../../components/Shared';
import { BasicTextArea } from '../../../../components';

const AdminCommissionBulkUploadForm = ({
  errors,
  setFieldValue,
  setSubmitting,
  isSubmitting,
  touched,
  textDescription,
  ...props
}) => {
  const { errorMessage, handleModalClose, placeholder, textAreaName, formType } = props;

  const handleCommissionColumnRules = ({
    rowArray: [policyNumber, endorsementNumber, totalNetCommissionReported, invoiceNumber, invoiceDate]
  }) => {
    let allStructure = '';

    if (policyNumber) {
      const parsedPolicyNumber = policyNumber.trim();
      allStructure += `${parsedPolicyNumber}`;
    }

    if (endorsementNumber) {
      const parsedEndorsementNumber = endorsementNumber.trim();
      allStructure += `\t${parsedEndorsementNumber}`;
    }

    if (totalNetCommissionReported) {
      const parsedTotalNetCommissionReported = totalNetCommissionReported.trim();
      allStructure += `\t${parsedTotalNetCommissionReported}`;
    }

    if (invoiceNumber) {
      const parsedInvoiceNumber = invoiceNumber.trim();
      allStructure += `\t${parsedInvoiceNumber}`;
    }

    if (invoiceDate) {
      const invoiceDateBase = invoiceDate.replace(/[-]/g, '/');
      const parsedInvoiceDate = invoiceDateBase.trim();
      allStructure += `\t${parsedInvoiceDate}`;
    }

    return allStructure;
  };

  const handlePaymentColumnRules = ({ rowArray: [policyNumber, endorsementNumber, invoiceNumber, invoiceDate] }) => {
    let allStructure = '';

    if (policyNumber) {
      const parsedPolicyNumber = policyNumber.trim();
      allStructure += `${parsedPolicyNumber}`;
    }

    if (endorsementNumber) {
      const parsedEndorsementNumber = endorsementNumber.trim();
      allStructure += `\t${parsedEndorsementNumber}`;
    }

    if (invoiceNumber) {
      const parsedInvoiceNumber = invoiceNumber.trim();
      allStructure += `\t${parsedInvoiceNumber}`;
    }

    if (invoiceDate) {
      const invoiceDateBase = invoiceDate.replace(/[-]/g, '/');
      const parsedInvoiceDate = invoiceDateBase.trim();
      allStructure += `\t${parsedInvoiceDate}`;
    }

    return allStructure;
  };

  const handleOnChangeTextArea = ({ fieldName, rows = [] }) => {
    const formattedComment = rows.map(row => {
      const rowArray = row.split('\t').filter(word => word);
      const formattedRows =
        formType === 'commission' ? handleCommissionColumnRules({ rowArray }) : handlePaymentColumnRules({ rowArray });

      const splitElement = row.split(';');
      const splitLength = splitElement.length >= 2;
      return splitLength ? `${splitElement[0].trim()}\t${splitElement[1].trim()}` : formattedRows;
    });

    setFieldValue(fieldName, formattedComment.join('\n'));
  };

  return (
    <>
      {errorMessage && (
        <InfoBox icon="danger" variant="danger" text={`La carga no pudo ser procesada: ${errorMessage}`} />
      )}

      {textDescription}

      <Form>
        <Row>
          <Col xs={12}>
            <Field name={textAreaName}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row={6}
                  placeholder={placeholder}
                  onChange={({ target }) => {
                    const targetValue = target.value;
                    const rows = targetValue.split('\n');
                    handleOnChangeTextArea({ fieldName: field.name, rows });
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  className="p-3 textarea-tab-size"
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mt-2 d-flex justify-content-end">
          <Col md={7}>
            <Row className="d-flex justify-content-end">
              <Col md={3} className="mt-4">
                <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
                  Cargar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ premiumCollection }) => ({ premiumCollection });

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

const commissionValidationSchema = Yup.object().shape({
  premiumCollection: Yup.object().shape({
    reportedCommission: Yup.string().required('Debes ingresar los datos de las comisiones informadas')
  })
});

const paymentValidationSchema = Yup.object().shape({
  premiumCollection: Yup.object().shape({
    associatedPayments: Yup.string().required('Debes ingresar los datos de los pagos asociados')
  })
});

const selectValidationSchema = props => {
  return props.formType === 'commission' ? commissionValidationSchema : paymentValidationSchema;
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema: selectValidationSchema,
  enableReinitialize: true
  // validateOnMount: props => props.action !== 'new'
})(AdminCommissionBulkUploadForm);
