import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminCompanyRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/companies', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminCompanyRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/companies/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminCompanyRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/companies', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminCompanyRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/companies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminCompanyRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('delete', `/admin/companies/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexAdminCompaniesRequest = AwesomeDebouncePromise(indexAdminCompanyRequest, 300);
