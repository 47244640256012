import React, { useRef } from 'react';
import { useAbility } from '@casl/react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useHistory } from 'react-router-dom';

import { DefaultHeader, LeadDataTable } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { useFetchData } from '../../hooks';
import {
  indexInsuranceCategoryRequest,
  debounceIndexInsuranceCategoryRequest
} from '../../requests/insuranceCategories';
import { debounceIndexLeadRequest } from '../../requests/lead';
import { redirectTo } from '../../services/utils';
import { firstStep, leadParams, leadSteps, secondStep, thirdStep } from './LeadFiltersParams';
import columns from './Columns';

const LeadIndex = () => {
  const modelUrl = '/leads';
  const ability = useAbility(AbilityContext);
  const customParamsRef = useRef({});
  const history = useHistory();
  const leadOptions = { steps: leadSteps, params: leadParams, 1: firstStep, 2: secondStep, 3: thirdStep };
  const { data: leads, totalData: totalLeads, moreData, isFetching, handleIndexRequest, setMoreData } = useFetchData({
    debouncedIndexRequest: debounceIndexLeadRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los datos. Por favor inténtalo nuevamente',
    customParams: { renewals: false, ...customParamsRef.current },
    withDataTable: true
  });

  const selectorOptions = [
    {
      isMulti: true,
      fieldName: 'insuranceCategoryId',
      placeholder: 'Ramo',
      indexRequest: indexInsuranceCategoryRequest,
      debounceRequest: debounceIndexInsuranceCategoryRequest
    }
  ];

  return (
    <DefaultHeader
      title="Ventas"
      headerBtn={ability.can('manage', 'Lead')}
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Oportunidad',
        onClick: () =>
          redirectTo(history, `${modelUrl}/new`, { currentParams: camelCaseRecursive(customParamsRef.current) })
      }}
    >
      <LeadDataTable
        tableData={leads}
        tableDataAmount={totalLeads}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
        columns={columns}
        selectorOptions={selectorOptions}
        headerOptions={leadOptions}
        defaultParams={{ sortColumn: 'step_days' }}
      />
    </DefaultHeader>
  );
};

export default LeadIndex;
