import React from 'react';
import { useDispatch } from 'react-redux';

import { updatePublicLeadQuotationRequest } from '../../requests/public/leadQuotations';
import ModalBodyIcon from '../Shared/ModalBodyIcon';
import { SimpleCenteredModal } from '../Utils/Modal';

const LeadQuotationAcceptanceModal = ({ handleClose, leadQuotation, showModal, ...props }) => {
  const { setAccepted, setShowModal, setShowSuccessModal } = props;
  const { acceptanceKey } = leadQuotation;
  const dispatch = useDispatch();

  const handleSuccessRequest = () => {
    setShowSuccessModal(true);
    setAccepted(true);
  };

  const handleApproveRequest = () => {
    updatePublicLeadQuotationRequest(acceptanceKey, {
      dispatch,
      successCallback: handleSuccessRequest,
      callback: () => setShowModal(false)
    });
  };

  return (
    <SimpleCenteredModal
      show={showModal}
      onHide={handleClose}
      body={
        <ModalBodyIcon
          highlightedText="contratar esta cotización"
          highlightedVariant="light-warning"
          handleModalClose={handleClose}
          formRequest={handleApproveRequest}
        />
      }
    />
  );
};

export default LeadQuotationAcceptanceModal;
