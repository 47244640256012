import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { SimpleCenteredModal, WebLayout } from '../../components';
import { CompanyLandingBenefits, CompanyLandingMain } from '../../components/Landing/CompanyLanding';
import { LandingCenteredContent, LandingFooter } from '../../components/Landing/Shared';
import { ModalBodyIcon } from '../../components/Shared';
import showPublicCompanyRequest from '../../requests/public/publicCompanies';
import createPublicLeadRequest from '../../requests/public/publicLeads';
import { scrollToTop } from '../../services/utils';
import CompanyLandingForm from './Form/CompanyLandingForm';
import { basicCompanyLanding, basicCompany } from './Form/basicCompanyLanding';

const CompanyLanding = () => {
  const { settings } = useSelector(state => state.utils);
  const dispatch = useDispatch();

  const location = useLocation();
  const splitPath = location.pathname.split('/');
  const [companyId, insuraceBrokerId] = splitPath.slice(-2);

  const [company, setCompany] = useState(basicCompany);
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);

  const { landingPrimaryColor, landingSecondaryColor, logoInfo } = company;

  const handleLicensePlateData = ({ vehicleLicensePlateData }) => {
    let licensePlateData = '';
    Object.entries(vehicleLicensePlateData).forEach(([key, value]) => {
      if (!value) return;
      licensePlateData += `${key}: ${value}, `;
    });
    return licensePlateData.trimEnd().replace(/.$/, '.');
  };

  const handleTextInputs = values => {
    const {
      insurancePolicyAmount,
      parsedInsurancePolicyDeductible,
      vehicleLicensePlate,
      vehicleLicensePlateData,
      vehicleUse
    } = values.companyLanding;

    const licensePlateData = handleLicensePlateData({ vehicleLicensePlateData });
    const subjectMatter = `${licensePlateData || `Patente: ${vehicleLicensePlate}`}.\nUso: ${vehicleUse}`;

    let coverDetail = '';
    if (insurancePolicyAmount) coverDetail += `Precio: ${insurancePolicyAmount} - `;
    if (parsedInsurancePolicyDeductible) coverDetail += `Deducible: ${parsedInsurancePolicyDeductible}`;

    return { coverDetail, subjectMatter };
  };

  const handleCreatePublicLeadRequest = (values, setSubmitting, resetForm) => {
    const { contract } = values.companyLanding;
    const textInputs = handleTextInputs(values);
    const recordIds = { companyId, insuraceBrokerId };
    const sendParams = snakeCaseKeys({ ...recordIds, companyLanding: { ...values.companyLanding, ...textInputs } });
    sendParams.company_landing.contract = contract;

    createPublicLeadRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: () => {
        setModalShow(true);
        resetForm();
      },
      callback: () => setSubmitting(false)
    });
  };

  const handleFetchCompany = () => {
    setOnRequest(true);
    showPublicCompanyRequest(companyId, {
      dispatch,
      successCallback: response => setCompany(camelCaseRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };

  useEffect(scrollToTop, []);
  useLayoutEffect(handleFetchCompany, [companyId]);

  return onRequest ? (
    <div className="d-flex justify-content-center">
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <WebLayout type="company" extraSettings={{ landingPrimaryColor, landingSecondaryColor, logoInfo }}>
      <CompanyLandingMain company={company} />
      <CompanyLandingBenefits company={company} />
      <CompanyLandingForm
        companyLanding={basicCompanyLanding}
        company={company}
        formRequest={handleCreatePublicLeadRequest}
      />
      <SuccessRequestModal modalShow={modalShow} setModalShow={setModalShow} />
      <LandingCenteredContent
        bgVariant="bg-white"
        title="Recuerda que puedes ingresar a tu portal del Asegurado en NICO "
        text={`Aquí encontrarás todas tus pólizas y podrás solicitar nuestro apoyo para lo que sea que necesites ${String.fromCodePoint(
          '0x1F4AA'
        )}`}
        btnStyle={{ '--inline-primary-color': landingPrimaryColor, '--inline-secondary-color': landingSecondaryColor }}
        onClick={() => window.open('/insured/login', '_blank', 'noopener')}
        btnText="Portal asegurado"
      />
      <LandingFooter settings={settings} extraSettings={{ landingPrimaryColor, landingSecondaryColor }} />
    </WebLayout>
  );
};

const SuccessRequestModal = ({ modalShow, setModalShow }) => {
  const handleClose = () => setModalShow(false);

  return (
    <SimpleCenteredModal
      show={modalShow}
      onHide={handleClose}
      body={
        <ModalBodyIcon
          icon="check-o"
          iconVariant="success"
          content="Solicitud enviada con"
          highlightedText="éxito"
          highlightedVariant="light-success"
          confirmText={false}
          handleModalClose={handleClose}
          formContent={
            <Row className="justify-content-center">
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" onClick={handleClose}>
                  Entendido
                </Button>
              </Col>
            </Row>
          }
        />
      }
    />
  );
};

export default CompanyLanding;
