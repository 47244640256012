import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { DefaultHeader } from '../../components';
import { AbilityContext } from '../../config/abilityContext';
import { useFetchData } from '../../hooks';
import { debounceIndexUsersRequest } from '../../requests/user';
import { customStyles } from './datatableCustomStyle';
import UserDataTable from './UserDataTable';

const UserIndex = () => {
  const ability = useAbility(AbilityContext);
  const history = useHistory();
  const customParamsRef = useRef({});
  const { data: users, totalData: totalUsers, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexUsersRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los usuarios. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/users';

  return (
    <DefaultHeader
      title="Usuarios"
      headerBtn={ability.can('create', 'PlatformUser')}
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Usuario',
        path: `${modelUrl}/new`
      }}
    >
      <UserDataTable
        customParamsRef={customParamsRef}
        customStyles={customStyles}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        onRowClicked={({ id: userId }) => history.push(`/users/${userId}`)}
        setMoreData={setMoreData}
        tableData={users}
        tableDataAmount={totalUsers}
      />
    </DefaultHeader>
  );
};

export default UserIndex;
