import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import { InputRemoteSelect } from '../../Utils/Select';
import { useFetchForRemoteSelect } from '../../../hooks';
import { debounceIndexPaymentPlanRequest, indexPaymentPlanRequest } from '../../../requests/paymentPlan';

const PaymentPlanRemoteSelector = ({ values, errors, touched, field, modelName }) => {
  const { setFieldValue } = useFormikContext();
  const cadenceType = getIn(values, `${modelName}[cadenceType]`);
  const isMonthlyCadence = cadenceType === 'monthly';

  const {
    options: paymentPlans,
    selectedOption: selectedPaymentPlan,
    fetchOptions: fetchPaymentPlans
  } = useFetchForRemoteSelect({
    indexRequest: indexPaymentPlanRequest,
    debouncedIndexRequest: debounceIndexPaymentPlanRequest,
    value: getIn(values, `${modelName}[paymentPlanId]`),
    initialParams: {
      sort_column: 'created_at',
      sort_direction: 'asc'
    }
  });

  const updatePaymentPlanFields = (id, name) => {
    setFieldValue(`${modelName}[paymentPlanId]`, id);
    setFieldValue(`${modelName}[paymentPlanName]`, name);
  };

  useEffect(() => {
    if (isMonthlyCadence) {
      const oneQuotaOption = paymentPlans.find(opt => opt.label === '1 cuota');
      if (oneQuotaOption) updatePaymentPlanFields(oneQuotaOption.value, oneQuotaOption.label);
    }
  }, [isMonthlyCadence, paymentPlans, setFieldValue, modelName]);

  return (
    <InputRemoteSelect
      {...field}
      abbr
      isClearable
      label="Plan de pago"
      placeholder="Seleccionar plan de pago"
      defaultOptions={paymentPlans}
      value={selectedPaymentPlan}
      request={fetchPaymentPlans}
      onChange={data => {
        setFieldValue(`${modelName}[paymentPlanName]`, data?.label || '');
        setFieldValue(field.name, data?.value || '');
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
      disabled={isMonthlyCadence}
    />
  );
};

export default PaymentPlanRemoteSelector;
