import React from 'react';

import { CustomBtn } from '../Utils/Button';
import { StatisticCard } from '../Utils/Cards';

const AttachedFile = ({ extraButton, fileUrl, title }) => {
  const openFile = () => window.open(fileUrl, '_blank', 'noopener');

  const buttons = [{ name: 'Ver', active: true, disabled: !fileUrl, onClick: openFile }];
  if (extraButton) buttons.push(extraButton);

  return (
    <StatisticCard
      title={title}
      icon="notes"
      variant="secondary-light"
      footerButton={buttons.map((button, index) => (
        <CustomBtn
          key={`file-button-${index.toString()}`}
          disabled={button.disabled}
          customClass={index > 0 ? 'mr-3' : ''}
          tooltipText={`${button.disabled ? 'Sin documento asociado' : ''}`}
          onClick={button.onClick}
        >
          {button.name}
        </CustomBtn>
      ))}
    />
  );
};

export default AttachedFile;
