import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { updateAdminCompanyRequest, showAdminCompanyRequest } from '../../../requests/admin/companies';
import AdminCompanyForm from './AdminCompanyForm';
import basicCompany from './basicCompany';

const AdminCompanyEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/companies';
  const [company, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Empresas', href: modelUrl },
    { key: 2, name: 'Editar empresa' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Empresa actualizada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { landingBackground, logo } = values.company;
    const sendParams = snakeCaseKeys(values);
    sendParams.company.logo = logo;
    sendParams.company.landing_background = landingBackground;
    updateAdminCompanyRequest(id, {
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const fetchCompany = () => {
    showAdminCompanyRequest(id, {
      dispatch,
      successCallback: response => setCompany(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchCompany, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminCompanyForm action="edit" company={company} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminCompanyEdit;
