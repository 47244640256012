const basicBeneficiary = {
  nationalIdentification: '',
  name: '',
  birthday: false,
  role: '',
  incorporationDate: '',
  companySentDateIncorporation: '',
  dependentsNumber: 0,
  lifeCoverage: false,
  healthCoverage: false,
  dentalCoverage: false,
  catastrophicCoverage: false,
  comments: ''
};

export default basicBeneficiary;
