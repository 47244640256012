import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';

import { FormikInput, FormikSelect } from '../../../components';
import { debounceLicensePlateInfoPublicRequest } from '../../../requests/public/publicSharedEndpoints';
import { textUpperCase } from '../../../services/utils';

const CompanyLandingSecondSection = ({ modelName, sectionInfoComponent: SectionInfo }) => {
  const { errors, setFieldValue, touched } = useFormikContext();
  const dispatch = useDispatch();

  const handleLicensePlateInfo = ({ licensePlate }) => {
    debounceLicensePlateInfoPublicRequest({
      dispatch,
      formData: true,
      params: snakeCaseKeys({ licensePlate }),
      successCallback: response => setFieldValue(`${modelName}[vehicleLicensePlateData]`, response.data)
    });
  };

  return (
    <div className="mb-5">
      <SectionInfo title="Información del vehículo a asegurar" />
      <Row>
        <Col sm={6}>
          <Field name={`${modelName}[vehicleLicensePlate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Patente"
                placeholder="AAAA11"
                onChange={element => {
                  const licensePlate = textUpperCase(element.target.value);
                  handleLicensePlateInfo({ licensePlate });
                  setFieldValue(field.name, licensePlate);
                }}
                touched={getIn(touched, field.name)}
                error={getIn(errors, field.name)}
                tooltipText="Si el vehículo no tiene patente ingresa 'AAAA11'."
                maxLength={6}
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[vehicleUse]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Uso"
                placeholder="Seleccionar uso"
                options={[
                  { label: 'Particular', value: 'Particular' },
                  { label: 'Comercial', value: 'Comercial' }
                ]}
                onChange={option => setFieldValue(field.name, option?.value || '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText={`Particular:<br/>
                             Se refiere a un uso para fines particulares, como<br/>
                             ir a la casa de un amigo, al parque, a un concierto,<br/>
                             a la universidad, a la oficina, etc., pero no ser<br/>
                             usado para trabajar ${String.fromCodePoint('0x1F699')}<br/>
                             <br/>
                             Comercial:<br/>
                             Se refiere a un uso para trabajar, como repartidor,<br/>
                             taxi, apps, trabajo en terreno, transporte de<br/>
                             carga, transporte de pasajeros, etc. ${String.fromCodePoint('0x1F69A')}`}
              />
            )}
          </Field>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyLandingSecondSection;
