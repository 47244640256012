import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { sendAlert } from '../../actions/utils';
import { BasicTextArea } from '../Utils/Input';
import { ButtonTooltip } from '../Utils/Tooltips';

const AutoSaveCommentsForm = ({
  customClassName,
  debouncedUpdateRequest,
  title,
  resourceComments,
  resourceId,
  resourcePath,
  row,
  withTitle,
  tooltipText,
  setBodyContent,
  ...props
}) => {
  const dispatch = useDispatch();
  const [currentComments, setCurrentComments] = useState(resourceComments);

  useEffect(() => {
    if (setBodyContent) {
      setBodyContent(currentComments);
    }
  }, [currentComments, setBodyContent]);

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Comentarios guardados' }));
  };

  const handleUpdateRequest = () => {
    if (currentComments === resourceComments) return;
    const sendParams = {};
    sendParams[resourcePath] = currentComments;
    debouncedUpdateRequest(resourceId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate
    });
  };

  const handleOnChange = e => {
    setCurrentComments(e.target.value);
  };

  const handleOnMount = () => {
    if (resourceComments) setCurrentComments(resourceComments);
  };

  useEffect(handleOnMount, [resourceComments]);
  useEffect(handleUpdateRequest, [currentComments]);

  return (
    <div className={`comment-box ${withTitle ? 'with-title' : ''}`} {...props}>
      {withTitle && (
        <header className="comment-box__title">
          <p className="section-title capitalize-text mb-0">{title}</p>
          {tooltipText && (
            <ButtonTooltip tooltipText={tooltipText} className="ml-2" tabIndex="-1">
              ?
            </ButtonTooltip>
          )}
        </header>
      )}

      <BasicTextArea
        className={customClassName}
        onChange={handleOnChange}
        placeholder="Escribe tu comentario..."
        value={currentComments}
        row={row}
      />
    </div>
  );
};

AutoSaveCommentsForm.propTypes = {
  customClassName: PropTypes.string,
  debouncedUpdateRequest: PropTypes.func.isRequired,
  resourceComments: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourcePath: PropTypes.string.isRequired,
  row: PropTypes.number,
  title: PropTypes.string,
  withTitle: PropTypes.bool
};

AutoSaveCommentsForm.defaultProps = {
  customClassName: '',
  title: 'Comentarios',
  row: 5,
  withTitle: false
};

export default AutoSaveCommentsForm;
