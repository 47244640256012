import ApiService from '../../services/apiService';

export const createAdminLeadProposalRequest = (
  leadId,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('post', `/admin/leads/${leadId}/lead_proposals`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAdminLeadProposalRequest = (
  leadId,
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/leads/${leadId}/lead_proposals/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });
