const basicAddress = {
  id: '',
  communeId: '',
  street: '',
  regionId: '',
  locationNumber: '',
  fullAddress: ''
};

export default basicAddress;
