import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { IconBtn } from '../Button';

const BadgeIcon = ({ variant, icon, iconSize, tooltipText, direction, delayHide, delayShow, onClick }) => {
  const setType = color => {
    switch (color) {
      case 'danger':
        return 'error';
      case 'grey':
        return 'dark';
      case 'primary-ligth':
        return 'info';
      case 'primary-dark':
        return 'info';
      default:
        return color;
    }
  };

  const setTextColor = color => {
    switch (color) {
      case 'danger':
      case 'grey':
      case 'primary-dark':
        return 'white';
      case 'dark':
        return 'white';
      default:
        return 'black';
    }
  };

  return (
    <div className={`icon-container ${iconSize}`}>
      <IconBtn
        variant={`badge-icon ${variant}`}
        icon={icon}
        data-tip
        data-for={`badge-${tooltipText}`}
        onClick={onClick}
      />
      {tooltipText && (
        <ReactTooltip
          id={`badge-${tooltipText}`}
          delay={{ show: delayShow, hide: delayHide }}
          place={direction}
          type={setType(variant)}
          effect="solid"
          textColor={setTextColor(variant)}
          html
          clickable={false}
          className="button-tooltip opaque"
          border
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </div>
  );
};

BadgeIcon.propTypes = {
  variant: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
  tooltipText: PropTypes.string,
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number
};

BadgeIcon.defaultProps = {
  variant: 'primary',
  iconSize: 'xs',
  tooltipText: '',
  direction: 'top',
  delayHide: 250,
  delayShow: 200
};

export default BadgeIcon;
