import React, { useEffect, useState } from 'react';
import { IconBtn } from '../../Utils/Button';

const MultiLandingNavbar = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  const navbarHeight = 90;

  const showNavbar = () => {
    if (window.scrollY > navbarHeight) {
      setIsNavbarVisible(false);
    } else {
      setIsNavbarVisible(true);
    }
  };

  const setListener = () => {
    window.addEventListener('scroll', showNavbar);
    return () => {
      window.removeEventListener('scroll', showNavbar);
    };
  };

  useEffect(setListener, []);

  return (
    <nav className={`landing--navbar ${isNavbarVisible ? '' : 'not-visible'}`}>
      <div className="landing--navbar__left" />
      <div className="landing--navbar__right">
        <IconBtn to="/login" variant="secondary" className="landing--navbar__button rounded-border mr-4">
          Sesión Corredor - Partner
        </IconBtn>
        <IconBtn to="insured/login" variant="tertiary" className="landing--navbar__button rounded-border mr-4">
          Sesión Asegurado
        </IconBtn>
      </div>
    </nav>
  );
};

export default MultiLandingNavbar;
