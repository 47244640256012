import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexUserRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/users', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showUserRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/users/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showMeRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/users/show_me', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showCommissionsRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/users/show_commissions', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createUserRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/users', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateUserRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/users/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const updateMeRequest = ({ dispatch, params, formData, failureCallback, successCallback, callback, source }) =>
  ApiService.request('put', '/users/update_me', {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteDocumentRequest = ({ dispatch, params, failureCallback, successCallback, callback, source }) =>
  ApiService.request('delete', '/users/destroy_document', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const deleteUserRequest = (id, { dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('delete', `/users/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });
export const insurancePoliciesSummaryRequest = ({ dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/users/policies_summary`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const unfinishTasksCountSummaryRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', `/users/unfinished_tasks`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const pendingsSummaryRequest = ({ dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/users/pendings_summary`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const chartDataSummaryRequest = ({ dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/users/chart_data`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexUsersRequest = AwesomeDebouncePromise(indexUserRequest, 300);
export const debouncePoliciesSummaryRequest = AwesomeDebouncePromise(insurancePoliciesSummaryRequest, 300);
export const debounceUnfinishTasksCountSummaryRequest = AwesomeDebouncePromise(unfinishTasksCountSummaryRequest, 300);
export const debouncePendingsSummaryRequest = AwesomeDebouncePromise(pendingsSummaryRequest, 300);
export const debounceChartDataSummaryRequest = AwesomeDebouncePromise(chartDataSummaryRequest, 300);
