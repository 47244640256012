/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { IconBtn } from '../../Utils/Button';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingKindPlans = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  const planData = [
    {
      title: 'Plan crecimiento',
      description:
        'Para corredores activos en la administración de cartera y búsqueda de oportunidades de crecimiento.',
      features: [
        'Software NICO y todos los servicios (cotización, emisión, renovación, endosos, siniestros, cobranza y comisiones).',
        'Atención entregada por todo el equipo NICO.',
        'La relación con el cliente final la mantienes tú.'
      ]
    },
    {
      title: 'Plan extensión',
      description:
        'Para corredores que buscan enfocar su tiempo en las relaciones personales de su cartera y delegar la administración.',
      features: [
        'Todo los servicios del nivel anterior, y más.',
        'Ejecutivo de cuenta asignado.',
        'Desarrollamos una relación directa con el cliente final, en las instancias y momentos que tú autorices.'
      ]
    }
  ];

  const PlanCard = ({ plan, onButtonClick }) => {
    return (
      <div className="col-md-6 col-sm-12 broker-landing__horizontal-info__kindservices__margin">
        <div className="broker-landing__horizontal-info__kindplans__card broker-landing-card">
          <div className="broker-landing__horizontal-info__kindplans__bluecontainer">
            <h5 className="broker-landing__horizontal-info__kindplans__bluecontainer__title">{plan.title}</h5>
            <p className="broker-landing__horizontal-info__kindplans__bluecontainer__subtitle">{plan.description}</p>
          </div>
          <svg width="53" height="26" viewBox="0 0 53 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.5 25.4727L0.95225 0.368361L52.0478 0.368366L26.5 25.4727Z" fill="#1F2E4F" />
          </svg>
          <div className="broker-landing__horizontal-info__kindservices__card__containertext">
            <div className="broker-landing-features-container">
              {plan.features.map((feature, index) => (
                <Row className="broker-landing-feature" key={index}>
                  <div className="col-2 pr-0">
                    <svg
                      className="broker-landing__horizontal-info__kindplans_arrow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <path
                        d="M12.8013 20.8902L7.49805 15.5869L9.26581 13.8191L12.8013 17.3546L19.8724 10.2836L21.6402 12.0513L12.8013 20.8902Z"
                        fill="#1F2E4F"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.24805 15.498C1.24805 7.90413 7.40413 1.74805 14.998 1.74805C22.592 1.74805 28.748 7.90413 28.748 15.498C28.748 23.092 22.592 29.248 14.998 29.248C7.40413 29.248 1.24805 23.092 1.24805 15.498ZM14.998 26.748C8.78484 26.748 3.74805 21.7113 3.74805 15.498C3.74805 9.28484 8.78484 4.24805 14.998 4.24805C21.2113 4.24805 26.248 9.28484 26.248 15.498C26.248 21.7113 21.2113 26.748 14.998 26.748Z"
                        fill="#1F2E4F"
                      />
                    </svg>
                  </div>
                  <div className="col pl-0">
                    <p className="font-weight-500">{feature}</p>
                  </div>
                </Row>
              ))}
            </div>
            <div className="mb-5 broker-landing-text">
              <p className="font-weight-500 text-center">
                Tú trabajas en hacer crecer tu cartera, NICO se encarga de todo lo demás.
              </p>
            </div>
            <div className="mt-3">
              <IconBtn onClick={onButtonClick} className="main-button btn-landing w-mobile-100" variant="secondary">
                Empieza GRATIS
              </IconBtn>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="kindplans" className="broker-landing__horizontal-info__kindservices">
      <h3 className="text-center text-dark-blue broker-landing__horizontal-info__kindplans__bluecontainer__subtitle">
        El % de cobro de NICO dependerá del nivel de servicio entregado
      </h3>
      <h4 className="broker-landing__horizontal-info__title landing-section-title text-dark-blue">
        Encuentra el plan que mejor se ajusta a tu perfil
      </h4>
      <div className="col broker-landing__horizontal-info__kindplans">
        <Row>
          {planData.map((plan, index) => (
            <PlanCard key={index} plan={plan} onButtonClick={navigateToContact} />
          ))}
        </Row>
      </div>
    </section>
  );
};

export default BrokerLandingKindPlans;
