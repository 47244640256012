import React from 'react';
import { Form } from 'react-bootstrap';
import { ButtonTooltip } from '../Tooltips';

const BasicTextArea = ({
  abbr,
  className,
  error,
  helpText,
  inputType,
  label,
  maxLength,
  optional,
  placeholder,
  row,
  size,
  tooltipText,
  touched,
  ...props
}) => (
  <Form.Group className={`form-textarea-input ${error && touched ? 'is-invalid' : ''}`}>
    {label && (
      <Form.Label>
        {label} {abbr && <abbr className="text-danger">*</abbr>}
        {optional && <span className="optional-label">(Opcional)</span>}
      </Form.Label>
    )}
    <Form.Control
      as="textarea"
      rows={row}
      size={size}
      maxLength={maxLength}
      type={inputType}
      placeholder={placeholder}
      className={`${className} ${tooltipText ? 'with-tooltip' : ''}`}
      {...props}
    />
    {tooltipText && (
      <ButtonTooltip tooltipText={tooltipText} containerClassName="custom-textarea--tooltip">
        ?
      </ButtonTooltip>
    )}
    {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
    {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
  </Form.Group>
);

export default BasicTextArea;
