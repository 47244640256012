import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput, FormikSelect, FormikSwitch, UploadImage } from '../../../components';
import categoryTypes from './categoryTypes';
import AdminInsuranceGeneralTypeSection from './AdminInsuranceGeneralTypeSection';
import AdminInsuranceLifeTypeSection from './AdminInsuranceLifeTypeSection';

const AdminInsuranceCompanyForm = ({
  action,
  errors,
  onHide,
  submitVariant,
  touched,
  setFieldValue,
  insuranceCompany
}) => {
  const submitBtnText = action === 'new' ? 'Crear Aseguradora' : 'Guardar Cambios';
  const [insuranceCompanyType, setInsuranceCompanyType] = useState('');

  useEffect(() => {
    setInsuranceCompanyType(insuranceCompany.category);
  }, [insuranceCompany.category]);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <div className="d-flex justify-content-center align-items-center">
            <Field name="insuranceCompany[logo]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  forProfileAvatar
                  label="Adjuntar logo"
                  imageUrl={getIn(insuranceCompany.logo, 'fileUrl')}
                  onChange={logo => setFieldValue(field.name, logo)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="insuranceCompany[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3} sm={12}>
          <Field name="insuranceCompany[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Aseguradora activa?" />}
          </Field>
        </Col>
        <Col md={3} sm={12}>
          <Field name="insuranceCompany[showInLanding]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Mostrar en Landing?" />}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="insuranceCompany[fullName]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre completo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="insuranceCompany[nationalIdentification]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="insuranceCompany[category]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                options={categoryTypes}
                defaultValue={insuranceCompany.category}
                onChange={data => {
                  setFieldValue(field.name, data ? data.value : '');
                  setInsuranceCompanyType(data.value);
                }}
                isClearable
              />
            )}
          </Field>
        </Col>
      </Row>

      {(insuranceCompanyType === 'general' || insuranceCompanyType === 'both') && <AdminInsuranceGeneralTypeSection />}
      {(insuranceCompanyType === 'life' || insuranceCompanyType === 'both') && <AdminInsuranceLifeTypeSection />}

      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="my-5">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ insuranceCompany }) => {
  return {
    insuranceCompany: {
      ...insuranceCompany
    }
  };
};

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const validationSchema = Yup.object().shape({
  insuranceCompany: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un RUT')
      .rut('El RUT es inválido'),
    fullName: Yup.string().required('Debes ingresar un nombre completo'),
    category: Yup.string().required('Debe ingresar categoría'),
    executiveFullName: Yup.string().nullable(),
    executiveEmail: Yup.string()
      .email('Debes ingresar un correo electrónico válido')
      .nullable(),
    executivePhoneNumber: Yup.string().nullable(),
    logo: Yup.mixed()
      .notRequired()
      .test('fileSize', 'Archivo es demasiado grande.', value => {
        if (!value || Object.keys(value).length === 0) return true;
        return value && value.size && value.size <= FILE_SIZE;
      })
      .test('fileFormat', 'Formato debe ser .jpg, .jpeg o .png', value => {
        if (!value || Object.keys(value).length === 0) return true;
        return value && value.type && SUPPORTED_FORMATS.includes(value.type);
      })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminInsuranceCompanyForm);
