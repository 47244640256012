import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { getIn } from 'formik';
import { FormikDatePicker, UploadImage, IconBtn } from '../../../components';
import fieldName from './adminSettingFieldName';

const AdminSettingForm = ({ setting, setIsEditing, formRequest }) => {
  const { label, dataType, value, id } = setting;
  const [dateField, setDateField] = useState('');
  const isFile = ['file', 'image'].includes(dataType);
  const isDate = ['date'].includes(dataType);
  const inputName = fieldName[dataType];
  const [fieldValue, setFieldValue] = useState(value);

  const handleSubmit = () => {
    const sendParams = {
      setting: {
        [inputName]: fieldValue
      }
    };
    formRequest(id, sendParams);
  };

  const handleChange = e => {
    setFieldValue(e.target.value);
  };

  return (
    <Row>
      <Col md={10} sm={10}>
        {!(isFile || isDate) && (
          <Form.Group className="form-input" controlId={inputName}>
            <Form.Label>
              {label} <abbr className="text-danger">*</abbr>
            </Form.Label>
            <Form.Control type="text" value={fieldValue} onChange={handleChange} />
          </Form.Group>
        )}
        {isFile && (
          <UploadImage
            isImage={dataType === 'image'}
            label={label}
            name="setting[image]"
            imageUrl={getIn(setting.image, 'fileUrl')}
            onChange={data => setFieldValue(data)}
          />
        )}
        {dataType === 'date' && (
          <FormikDatePicker
            selected={dateField}
            autoFocus
            showYearDropdown
            onChange={date => {
              setDateField(date);
              setFieldValue(date);
            }}
          />
        )}
      </Col>
      <Col md={2} sm={2}>
        <div>
          <span className="link-menu float-right px-1">
            <IconBtn icon="check" iconSize="xs" className="btn no-shadow" onClick={handleSubmit} />
          </span>
          <span className="link-menu float-right px-1">
            <IconBtn
              icon="close"
              iconSize="xs"
              className="btn no-shadow"
              type="button"
              onClick={() => setIsEditing(false)}
            />
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default AdminSettingForm;
