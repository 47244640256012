import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminAccountContactRequest = (
  accountId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/accounts/${accountId}/contacts`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminAccountContactRequest = (
  accountId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/admin/accounts/${accountId}/contacts`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminAccountContactsRequest = AwesomeDebouncePromise(indexAdminAccountContactRequest, 300);
