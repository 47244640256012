import React, { useState } from 'react';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { sendAlert } from '../../../actions/utils';
import { updateAdminSettingRequest } from '../../../requests/admin/settings';
import AdminSettingColumn from './AdminSettingColumn';

const AdminSettingTableRow = ({ setting, setMoreData, moreData }) => {
  const { label } = setting;
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Configuración actualizada con éxito' }));
    setMoreData(!moreData);
    setIsEditing(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = (id, values) => {
    const { image, file } = values.setting;
    const sendParams = snakeCaseKeys(values);
    const { setting: valuesSetting } = sendParams;
    updateAdminSettingRequest(id, {
      dispatch,
      params: { setting: { ...valuesSetting, image, file } },
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row className="border-bottom p-3">
      <Col md={3}>{label}</Col>
      <Col md={9}>
        <AdminSettingColumn
          setting={setting}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleUpdateRequest={handleUpdateRequest}
        />
      </Col>
    </Row>
  );
};

export default AdminSettingTableRow;
