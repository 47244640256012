import React, { useEffect, useState } from 'react';

import { RegisterModalMessage } from '../Landing/Shared';
import { SimpleCenteredModal } from '../Utils/Modal';

const RequestRegisterSuccess = () => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const requestRegisterSuccess = !!localStorage.getItem('requestRegisterSuccess');

  const handleModalClose = () => {
    setModalShow(false);
    localStorage.removeItem('requestRegisterSuccess');
  };

  const handleRequestRegisterSuccess = () => {
    setModalShow(requestRegisterSuccess);
    setModalBody(
      <RegisterModalMessage
        icon="check-o"
        iconBgVariant="submit"
        title="¡Gracias!"
        message="Nos contactaremos contigo en menos de 24 horas para que pronto trabajemos juntos :)"
        closeBtnMessage="Ok, entendido"
        closeBtnVariant="submit"
        handleCloseModal={handleModalClose}
      />
    );
  };

  useEffect(handleRequestRegisterSuccess, []);

  return <SimpleCenteredModal size="sm" show={modalShow} body={modalBody} onHide={handleModalClose} />;
};

export default RequestRegisterSuccess;
