import AwesomeDebouncePromise from 'awesome-debounce-promise';

import ApiService from '../../services/apiService';

export const homeDataInsuredUserRequest = ({ dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('get', `/insured/users/home_data`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showInsuredCommissionsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', `/insured/users/show_commissions`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceHomeDataInsuredUserRequest = AwesomeDebouncePromise(homeDataInsuredUserRequest, 300);
