import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import snakecaseKeys from 'snakecase-keys';
import * as Yup from 'yup';

import { FormikInput, FormikSwitch, FormikSelect, UploadImage } from '../../../components';
import '../../../services/yupCustomMethods';
import { roles } from './basicBlog';
import { BasicTextAreaWithEditor, BasicTextArea, FormikDatePicker } from '../../../components/Utils/Input';

const AdminBlogForm = ({ action, errors, touched, setFieldValue, setFieldTouched, values }) => {
  const [defaultBlog, setDefaultBlog] = useState(undefined);
  const submitBtnText = action === 'new' ? 'Crear Blog' : 'Guardar Cambios';

  const generateBlogUrl = title => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  };

  const handleSelector = (data, field) => {
    setFieldValue(field, data.value);
  };

  useEffect(() => {
    setDefaultBlog(values.blog);
  }, [values.blog.image]);

  return (
    <Form className="admin-blog-form">
      <Row>
        <Col md={9}>
          <Field name="blog[title]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                onChange={e => {
                  const { value } = e.target;
                  setFieldValue(field.name, value);
                  const blogUrl = generateBlogUrl(value);
                  setFieldValue('blog[blogUrl]', blogUrl);
                }}
                label="Título"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="blog[publishDate]">
            {({ field }) => {
              return (
                <FormikDatePicker
                  {...field}
                  label="Fecha publicación"
                  onChange={value => setFieldValue(field.name, value)}
                  placeholderText="00/00/0000"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
        <Col md={9}>
          <Field name="blog[blogUrl]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="URL del Blog"
                placeholder="Ingresa la URL única del blog. Ej.: 'Tipos de seguros'"
                onChange={e => setFieldValue(field.name, e.target.value)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="blog[blogType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Categoría"
                placeholder="Seleccionar roles"
                options={roles}
                defaultValue={values.blog.blogType}
                defaultMultiValues={values.blog.blogType}
                onChange={data => handleSelector(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={9}>
          <Field name="blog[subtitle]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                abbr
                label="Bajada"
                placeholder="Ingresa una bajada"
                rows={5}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Col className="d-flex justify-content-center">
            <Field name="blog[featured]">
              {({ field }) => <FormikSwitch {...field} field={field} label="¿Blog destacado?" />}
            </Field>
          </Col>
          <Col className="d-flex justify-content-center">
            <Field name="blog[active]">
              {({ field }) => <FormikSwitch {...field} field={field} label="¿Blog activo?" />}
            </Field>
          </Col>
        </Col>
        <Col md={12}>
          <Field name="blog[content]">
            {({ field }) => (
              <BasicTextAreaWithEditor
                {...field}
                value={values.blog.content}
                label="Contenido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                onChange={value => setFieldValue(field.name, value)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="blog[blogImage]">
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  modelType="blog"
                  label="Imagen Blog"
                  iconComponent={<div className="circle-image">X</div>}
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(defaultBlog, 'image.fileUrl')}
                  fileName={getIn(defaultBlog, 'image.filename')}
                  onChange={image => setFieldValue(field.name, image)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  styles={{ maxHeight: '200px !important' }}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="my-5">
          <Button type="submit" variant="primary" size="lg" className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ blog }) => {
  return {
    blog: {
      ...blog,
      image: blog?.image
    }
  };
};

const validationSchema = Yup.object().shape({
  blog: Yup.object().shape({
    title: Yup.string().required('Debes ingresar un título'),
    subtitle: Yup.string().required('Debes ingresar el contenido del subtítulo'),
    content: Yup.string().required('Debes ingresar el contenido del blog'),
    blogType: Yup.string().required('Debes seleccionar un tipo'),
    publishDate: Yup.string().required('Debes seleccionar una fecha de publicación'),
    active: Yup.boolean(),
    featured: Yup.boolean(),
    blogUrl: Yup.string()
      .required('Debes ingresar una URL')
      .matches(
        /^[a-z0-9]+(-[a-z0-9]+)*$/,
        'La URL debe tener un formato válido. Solo se permiten letras minúsculas, números y guiones entre palabras.'
      )
  })
});

const handleSubmit = (values, { props }) => {
  const { blog } = values;
  const { blogImage } = values.blog;
  const paramsToSend = {
    blog: snakecaseKeys({
      ...blog
    })
  };
  if (blogImage) paramsToSend.blog.image = blogImage;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminBlogForm);
