import React from 'react';
import { Col, Row } from 'react-bootstrap';

const InsuranceEndorsementFormHeader = ({ insurancePolicy }) => {
  const { account, insuranceBrokerName, insuranceCompanyName, policyNumber } = insurancePolicy;

  return (
    <Row>
      <HeaderTitle text="Endoso" />
      <BulletElement element="Número de póliza" description={policyNumber} />
      <BulletElement element="Aseguradora" description={insuranceCompanyName} />
      <BulletElement element="Corredor" description={insuranceBrokerName} />
      <BulletElement element="Cuenta" description={account.name} />
      <BulletElement element="RUT cuenta" description={account.nationalIdentification} />
    </Row>
  );
};

const HeaderTitle = ({ text }) => (
  <Col xs={12}>
    <p className="header-title">{text}</p>
  </Col>
);

const BulletElement = ({ element, description }) => (
  <Col md={6} lg={4}>
    <p className="bullet-element">
      {element}: {description}
    </p>
  </Col>
);

export default InsuranceEndorsementFormHeader;
