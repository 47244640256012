import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { capitalizeString } from '../../../services/utils';

const TabFilters = ({
  buttonsVariant,
  containerClass,
  customParams,
  label,
  optionsArray,
  paramName,
  setCustomParams
}) => {
  const [selectedTab, setSelectedTab] = useState('');
  const [currentValue, setCurrentValue] = useState('');

  const handleClick = option => {
    setSelectedTab(option.value);
    setCustomParams(params => ({ ...params, [paramName]: option.value }));
  };

  const findCurrentTab = optionValue => {
    return Array.isArray(optionValue) && !Array.isArray(selectedTab)
      ? optionValue.includes(selectedTab)
      : JSON.stringify(selectedTab) === JSON.stringify(optionValue);
  };

  const activateCurrentTab = optionValue => {
    const isActiveCurrentTab = findCurrentTab(optionValue) || optionValue === currentValue;
    return isActiveCurrentTab ? '' : 'outline';
  };

  const handleCurrentTab = () => {
    const value = optionsArray.find(({ value: optionValue }) => findCurrentTab(optionValue))?.value || '';
    setCurrentValue(value);
  };

  const handleInitialTab = () => {
    setSelectedTab(customParams[paramName]);
  };

  useEffect(handleInitialTab, [optionsArray, customParams]);
  useEffect(handleCurrentTab, [selectedTab]);

  return (
    <section className="pt-4">
      {label && <p className="font-weight-bold">{label}</p>}
      <div className={`d-flex flex-wrap ${containerClass}`}>
        {optionsArray.map(option => (
          <Button
            key={option.value}
            data={option.value}
            data-target={option.value}
            variant={`${buttonsVariant}`}
            className={`mr-3 mb-2 btn-sm ${activateCurrentTab(
              option.value
            )} no-shadow rounded-border d-flex align-items-center`}
            onClick={() => handleClick(option)}
          >
            {option.label ? capitalizeString(option.label) : capitalizeString(option.value)}
            {option.counter > 0 && <span className="tab-filter-badge">{option.counter}</span>}
          </Button>
        ))}
      </div>
    </section>
  );
};

TabFilters.defaultProps = {
  buttonsVariant: 'secondary-blue',
  containerClass: '',
  optionsArray: [],
  paramName: '',
  setCustomParams: () => null
};

export default TabFilters;
