import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../../components';
import { requestSignIn, validateToken } from '../../../actions/auth';
import { sendAlert } from '../../../actions/utils';
import { resetPasswordRequest } from '../../../requests/utils';

const ResetPasswordBox = ({ onHide, isValid, errors, touched }) => {
  return (
    <div className="login-box--container">
      <div className="login-box">
        <h6 className="login-box--title text-color-white text-center">Cambiar contraseña</h6>
        <Form className="login-box--form">
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                noLabelFocus
                inputType="password"
                label="Contraseña"
                placeholder="Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({ field }) => (
              <FormikInput
                {...field}
                noLabelFocus
                inputType="password"
                label="Confirmar contraseña"
                placeholder="Confirmar Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant="secondary"
            size="lg"
            className="my-5 w-100 btn-big"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Cambiar contraseña
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test('password-confirmation-test', 'Las contraseñas no coinciden', function equalTo(value) {
      if (value && value === this.parent.password) {
        return true;
      }
      return false;
    })
});

const handleSubmit = (values, { props }) => {
  const { location, dispatch } = props;
  const token = new URLSearchParams(location.search).get('token');
  resetPasswordRequest({
    dispatch,
    params: {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      token
    },
    successCallback: result => {
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
      dispatch(
        requestSignIn({
          user: {
            email: result.data.user.email,
            password: values.password
          }
        })
      );
      dispatch(validateToken());
    },
    failureCallback: error => {
      dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.error }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ResetPasswordBox)
  )
);
