import React from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconBtn } from '../../Utils/Button';
import { WhyNicoCard } from '../Shared';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const ClientLandingWhyNico = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <section id="why-nico" className="landing--why-nico-client__container mb-5">
      <Row className="landing--why-nico-client__tittlecontainer">
        <div className="col-md-8 text-center">
          <h3 className="landing--why-nico-client__title landing-section-title font-weight-900">
            Asesoría + Tecnología
          </h3>
          <p>
            En NICO creemos que la tecnología debe ser un complemento a la asesoría humana, cara a cara, y no un
            reemplazo. Por eso, unimos lo mejor de ambos para darte la mayor protección.
          </p>
        </div>
      </Row>

      <div className="landing--why-nico-client landing--why-nico-client__content landing--why-nico-client landing--why-nico-client__margin">
        <WhyNicoCard
          icon="bookmark"
          title="todo tipo de riesgos"
          text="La vida está llena de sorpresas, mejor estar protegido. Desde incendios en la oficina, responsabilidad civil y autos, hasta la salud de personas y mascotas. NICO te asesora para conocer todas tus sorpresas."
        />
        <WhyNicoCard
          icon="lock"
          title="todo tipo de protección"
          text="Trabajamos con 29 compañías de seguros Generales, Vida, Salud, Garantía y Crédito. Con NICO tienes acceso a las mejores alternativas, para encontrar la mejor protección."
        />
        <WhyNicoCard
          icon="check-o"
          title="todo ordenado y siempre disponible"
          text="Para asegurarnos de que todo sea lo más simple, fácil y transparente posible para ti, desarrollamos nuestro propio software, y lo mantenemos siempre actualizado para que tengas todo al día."
        />
      </div>

      <div className="col-md-12 d-flex justify-content-center">
        <IconBtn
          onClick={() => navigateToContact()}
          className="main-button btn-landing w-mobile-100"
          variant="secondary"
        >
          Quiero asesoría GRATIS
        </IconBtn>
      </div>
    </section>
  );
};

export default ClientLandingWhyNico;
