const dictionary2 = {
  detail1: { format: 'text', required: true },
  detail2: { format: 'text', required: true },
  detail3: { format: 'text', required: true },
  detail4: { format: 'text', required: true },
  detail5: { format: 'text', required: true },
  detail6: { format: 'text', required: false },
  detail7: { format: 'text', required: false },
  detail8: { format: 'text', required: true },
  other1: {
    required: false,
    showif: { id: 'detail1', selectedOption: ['Comercial'] }
  },
  other2: {
    format: 'text',
    required: false,
    showif: { id: 'detail8', selectedOption: ['Pesado'] }
  },
  other3: {
    format: 'text',
    required: false,
    showif: { id: 'detail8', selectedOption: ['Pesado'] }
  },
  other4: {
    format: 'text',
    required: false,
    showif: { id: 'detail8', selectedOption: ['Pesado'] }
  },
  other5: {
    format: 'text',
    required: false,
    showif: { id: 'detail8', selectedOption: ['Pesado'] }
  }
};

export default dictionary2;
