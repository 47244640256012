import React from 'react';

import { debounceIndexAdminUsersRequest, indexAdminUserRequest } from '../../requests/admin/adminUsers';
import {
  indexInsuranceBrokerRequest,
  debounceIndexInsuranceBrokeresRequest
} from '../../requests/admin/insuranceBrokers';
import { indexInsuranceCompanyRequest, debounceIndexInsuranceCompanyRequest } from '../../requests/insuranceCompanies';
import categoryTypes from '../../screens/AdminScreens/InsuranceCategory/categoryTypes';
import { HeaderFilter } from '..';
import { tableTabOptions } from './attributeOptions';
import basicInfoRoles from './basicInfoRoles';

const PremiumCollectionFilters = ({ customParams, setCustomParams, userModule, createdFromOptions }) => {
  const currentUserOptions = basicInfoRoles[userModule];
  const { isAdmin } = currentUserOptions;

  const baseSelectorOptions = [
    {
      colSizeClass: 'col-lg',
      fieldName: 'filterInsuranceCompanyId',
      placeholder: 'Compañía',
      indexRequest: indexInsuranceCompanyRequest,
      debounceRequest: debounceIndexInsuranceCompanyRequest
    }
  ];

  const adminSelectorOptions = [
    {
      colSizeClass: 'col-lg',
      fieldName: 'filterExecutiveManagerId',
      placeholder: 'Ejecutivo',
      requestParams: { sortColumn: 'email', for_executives: 'present', admins: 'present', active: 'present' },
      indexRequest: indexAdminUserRequest,
      debounceRequest: debounceIndexAdminUsersRequest
    },
    {
      colSizeClass: 'col-lg',
      fieldName: 'filterInsuranceBrokerId',
      placeholder: 'Corredor',
      indexRequest: indexInsuranceBrokerRequest,
      debounceRequest: debounceIndexInsuranceBrokeresRequest
    }
  ];

  const selectorOptions = [...(isAdmin ? adminSelectorOptions : []), ...baseSelectorOptions];

  const simpleSelectorOptions = [
    {
      colSizeClass: 'col-lg',
      fieldName: 'filterInsuranceCompanyCategory',
      placeholder: 'Área',
      options: categoryTypes
    },
    {
      colSizeClass: 'col-lg',
      fieldName: 'filterCreatedFrom',
      placeholder: 'Origen',
      options: createdFromOptions
    }
  ];

  return (
    <HeaderFilter
      paramName="filterTableTabs"
      tabOptions={tableTabOptions}
      remoteSelectors={selectorOptions}
      simpleSelectors={simpleSelectorOptions}
      customParams={customParams}
      setCustomParams={setCustomParams}
    />
  );
};

export default PremiumCollectionFilters;
