const basicCompany = {
  id: '',
  active: true,
  email: '',
  executiveManagerId: '',
  isNotifiedInsuredUser: false,
  landingBackground: {},
  landingContentOne: '',
  landingContentOneTypewriter: '',
  landingContentTwo: '',
  landingPrimaryColor: '',
  landingSecondaryColor: '',
  logo: {},
  name: '',
  nationalIdentification: '',
  nicoCommissionPercentageGeneral: '',
  nicoCommissionPercentageLife: ''
};

export default basicCompany;
