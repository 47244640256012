import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { ButtonTooltip } from '../Tooltips';

const FormikNumberFormat = ({
  abbr,
  decimalScale,
  delayHide,
  delayShow,
  direction,
  error,
  fieldName,
  helpText,
  label,
  leftAddon,
  onlyNegative,
  optional,
  rightAddon,
  tooltipHtml,
  tooltipText,
  touched,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const defaultValueParsed = parseFloat(props.defaultValue || 0);
  const negativeValue = defaultValueParsed > 0 ? props.defaultValue * -1 : defaultValueParsed;

  return (
    <Form.Group className={error && touched ? 'is-invalid form-numeric-input' : 'form-numeric-input'}>
      {label && (
        <div className="label-with--tooltip">
          <Form.Label>
            {label} {abbr && <abbr className="text-danger">*</abbr>}
            {optional && <span className="optional-label">(Opcional)</span>}
          </Form.Label>
        </div>
      )}

      <InputGroup>
        {leftAddon && (
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <NumberFormat
          allowNegative={onlyNegative}
          {...props}
          allowEmptyFormatting
          className="form-control"
          customInput={Form.Control}
          value={onlyNegative ? negativeValue : props.value}
          decimalScale={decimalScale}
          decimalSeparator=","
          thousandSeparator="."
          onValueChange={values => {
            if (fieldName) setFieldValue(fieldName, values?.floatValue);
          }}
        />
        {rightAddon && (
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2">{rightAddon}</InputGroup.Text>
          </InputGroup.Append>
        )}
        {tooltipText && (
          <ButtonTooltip
            html={tooltipHtml}
            tooltipText={tooltipText}
            containerClassName="custom-input--tooltip"
            tabIndex="-1"
          >
            ?
          </ButtonTooltip>
        )}
      </InputGroup>

      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikNumberFormat.propTypes = {
  decimalScale: PropTypes.number,
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  onlyNegative: PropTypes.bool,
  tooltipHtml: PropTypes.bool
};

FormikNumberFormat.defaultProps = {
  decimalScale: 2,
  delayHide: 0,
  delayShow: 250,
  direction: 'top',
  onlyNegative: false,
  tooltipHtml: true
};

export default FormikNumberFormat;
