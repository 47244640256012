import React from 'react';
import { Image } from 'react-bootstrap';

import { IconBtn } from '../../Utils/Button';
import logoWhite from '../../Utils/MainLogo/logos/logo_white.png';

const CompanyLandingNavbarLogo = ({ secondLogo }) => {
  return (
    <div className="d-flex align-items-center">
      <Image fluid src={logoWhite} alt="Logo" className="company-landing__navbar-logo" />
      <div className="mx-3">
        <IconBtn noBtn onlyIcon icon="add-filled" iconSize="sm" variant="white" />
      </div>
      <Image fluid src={secondLogo} alt="Logo" className="company-landing__navbar-logo" />
    </div>
  );
};

export default CompanyLandingNavbarLogo;
