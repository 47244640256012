import React from 'react';
import { Link } from 'react-router-dom';
import MainLogo from '../../Utils/MainLogo';
import UserTypeCard from './UserTypeCard';
import userTypes from './userTypes';

const MultiLandingMain = () => {
  return (
    <main id="main" className="multilanding--main broker">
      <div className="multilanding--main__info-container">
        <div className="multilanding--main__info">
          <Link to="/landing">
            <MainLogo />
          </Link>
          <h1 className="main-title">Simplificar el universo de los seguros</h1>
          <h2 className="main-subtitle">¿Cómo podemos ayudarte?</h2>
        </div>
        <div className="multilanding--main__user-type row">
          {userTypes.map((userType, index) => (
            <UserTypeCard
              key={`multi-landing-main-${index.toString()}`}
              icon={userType.icon}
              title={userType.title}
              description={userType.description}
              customUserTypeStyle={userType.customStyle}
              href={userType.href}
            />
          ))}
        </div>
      </div>
    </main>
  );
};

export default MultiLandingMain;
