import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Form } from 'react-bootstrap';

const BtnSwitch = ({
  asSwitchCheckbox,
  delayHide,
  delayShow,
  direction,
  initialValue,
  margin,
  onClickChecked,
  onClickUnchecked,
  optional,
  readonly,
  small,
  tooltipText,
  variant,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  const handleOnClick = () => {
    if (readonly) return;
    if (!isChecked) onClickChecked();
    if (isChecked) onClickUnchecked();
    setIsChecked(!isChecked);
  };

  const handleInitValue = () => {
    if (initialValue) setIsChecked(initialValue);
  };

  useEffect(handleInitValue, [initialValue]);

  return (
    <>
      <Form.Group
        onClick={handleOnClick}
        className={`d-flex align-items-center form-checkbox-switch ${small ? 'switch-sm' : ''} ${margin || ''}`}
        data-tip
        data-for={`registerTip-switch-${tooltipText}`}
      >
        <Form.Check readOnly custom type="checkbox" checked={isChecked} id="switch-btn" {...props} />
        <Form.Label
          htmlFor="switch-btn"
          className={`custom-switch-label ${variant || ''} ${isChecked ? 'checked' : ''} ${
            readonly ? 'disabled' : 'cursor-pointer'
          }`}
        />
      </Form.Group>
      {tooltipText && (
        <ReactTooltip
          id={`registerTip-switch-${tooltipText}`}
          delay={{ show: delayShow, hide: delayHide }}
          place={direction}
        >
          {tooltipText}
        </ReactTooltip>
      )}
    </>
  );
};

BtnSwitch.propTypes = {
  onClickChecked: PropTypes.func,
  onClickUnchecked: PropTypes.func,
  asSwitchCheckbox: PropTypes.bool,
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  readonly: PropTypes.bool,
  initialValue: PropTypes.bool
};

BtnSwitch.defaultProps = {
  onClickChecked: () => console.log('missing onClickChecked'),
  onClickUnchecked: () => console.log('missing onClickUnchecked'),
  asSwitchCheckbox: false,
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  readonly: false,
  initialValue: false
};

export default BtnSwitch;
