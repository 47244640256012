import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikInputRadio, FormikSelect, SimpleCenteredModal } from '../..';
import { InfoBox, InfoCardInsuranceItem } from '../../Shared';
import { useDidMountEffect } from '../../../hooks';
import basicInsuranceEndorsement from '../basicInsuranceEndorsement';
import { InsuranceEndorsementContext } from '../InsuranceEndorsementContext';
import { endorsementActionOptions, endorsementTypeOptions } from './attributeOptions';

const InsuranceEndorsementFormType = ({ insurancePolicy, modelName }) => {
  const { originalInsuranceEndorsement } = useContext(InsuranceEndorsementContext);
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const [endorsemenActions, setEndorsemenActions] = useState([]);
  const [showItemSelector, setShowItemSelector] = useState(false);

  const { insuranceItemsAttributes, isAnnulled, isCancelled } = insurancePolicy;
  const { endorsementAction, endorsementType, insuranceItemId } = values.insuranceEndorsement;

  const isModification = endorsementType === 'modification';

  const insuranceItemOptions = insuranceItemsAttributes.map((item, index) => {
    return { ...item, itemNumber: index + 1, label: `Ítem ${index + 1} - ${item.name}`, value: item.id };
  });

  if (isModification) insuranceItemOptions.push({ label: 'Póliza completa', value: -1 });

  const customEndorsementTypeOptions = endorsementTypeOptions.map(option => {
    return option.value.includes('rehabilitation')
      ? { ...option, isDisabled: !isCancelled && !isAnnulled }
      : { ...option, isDisabled: isCancelled || isAnnulled };
  });

  const infoBoxHash = {
    rehabilitation: {
      active: !isCancelled && !isAnnulled,
      text:
        'El último endoso aplicado a la póliza debe ser de cancelación o anulación para poder aplicar un endoso de rehabilitación.'
    }
  };

  const infoBoxData = infoBoxHash[endorsementAction] || infoBoxHash[endorsementType] || {};

  const handleRestoreValues = ({ copyAction, sentValues }) => {
    let restoreValues = { ...sentValues, endorsementType };
    if (copyAction) restoreValues = { ...restoreValues, endorsementAction };
    setFieldValue(modelName, restoreValues);
  };

  const handleSetValues = ({ attribute, copyAction }) => {
    const currentValue = values.insuranceEndorsement[attribute];
    const originalValue = originalInsuranceEndorsement[attribute];

    if (currentValue === originalValue) handleRestoreValues({ copyAction, sentValues: originalInsuranceEndorsement });
    else handleRestoreValues({ copyAction, sentValues: basicInsuranceEndorsement });
  };

  const handleInsuranceItemSelector = () => {
    const isPermittedType = ['decrease', 'increase'].includes(endorsementType);
    const isPermittedAction = ['modify', 'remove'].includes(endorsementAction);
    const isAvailable = isModification || (isPermittedType && isPermittedAction);
    setShowItemSelector(isAvailable);
  };

  const handleEndorsementActions = () => {
    let actions = [];
    if (endorsementType === 'cut') actions = ['cancellation', 'annulment'];
    if (endorsementType === 'decrease') actions = ['modify', 'remove'];
    if (endorsementType === 'increase') actions = ['modify', 'add', 'extension'];
    const currentTypes = endorsementActionOptions.filter(actn => actions.includes(actn.value));
    setEndorsemenActions(currentTypes);
  };

  const handleOnChangeEndorsementAction = () => {
    handleInsuranceItemSelector();
    handleSetValues({ copyAction: true, attribute: 'endorsementAction' });
  };

  const handleOnChangeEndorsementType = () => {
    handleEndorsementActions();
    handleInsuranceItemSelector();
    handleSetValues({ copyAction: false, attribute: 'endorsementType' });
  };

  useDidMountEffect(handleOnChangeEndorsementType, [endorsementType]);
  useDidMountEffect(handleOnChangeEndorsementAction, [endorsementAction]);

  return (
    <>
      <hr className="w-100 my-5" />
      <p className="section-title">¿Qué tipo de endoso quieres aplicar?</p>
      {infoBoxData.active && <InfoBox variant="warning" text={infoBoxData.text} />}
      <Row className="d-flex">
        <Col lg={3}>
          <Field name={`${modelName}[endorsementType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Endosos"
                placeholder="Seleccionar tipo de endoso"
                defaultValue={endorsementType}
                options={customEndorsementTypeOptions}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
              />
            )}
          </Field>
        </Col>
        {endorsemenActions.length > 0 && (
          <Col lg={5}>
            <Field name={`${modelName}[endorsementAction]`}>
              {({ field }) => (
                <FormikInputRadio
                  {...field}
                  abbr
                  field={field}
                  label="Selecciona la modificación a realizar"
                  options={endorsemenActions}
                  fieldName={`${modelName}[translatedEndorsementAction]`}
                  error={getIn(errors, `${modelName}[endorsementAction]`)}
                  touched={getIn(touched, `${modelName}[endorsementAction]`)}
                />
              )}
            </Field>
          </Col>
        )}
        {showItemSelector && (
          <Col lg={4} className="ml-auto">
            <Field name={`${modelName}[insuranceItemId]`}>
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Selecciona el ítem que quieres modificar"
                  placeholder="Seleccionar ítem"
                  defaultValue={insuranceItemId}
                  options={insuranceItemOptions}
                  onChange={data => {
                    const modificationType = data.value === -1 ? 'additional_clauses' : 'item';
                    setFieldValue(`${modelName}[modificationType]`, modificationType);
                    setFieldValue(field.name, data ? data.value : '');
                    setFieldValue(`${modelName}[insuranceItemAttributes]`, data || '');
                    setFieldValue(`${modelName}[originalInsuranceItem]`, data || '');
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
            <ModalItems insuranceItemsAttributes={insuranceItemsAttributes} />
          </Col>
        )}
      </Row>
    </>
  );
};

const ModalItems = ({ insuranceItemsAttributes }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="link" className="d-flex ml-auto p-0" onClick={() => setModalShow(true)}>
        Ver todos los ítems
      </Button>
      <SimpleCenteredModal
        size="xl"
        title="Ítems"
        show={modalShow}
        body={<InsuranceItemTable insuranceItems={insuranceItemsAttributes} />}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

const InsuranceItemTable = ({ insuranceItems }) => {
  return insuranceItems.map((insuranceItem, index) => (
    <InfoCardInsuranceItem
      key={`insurance-item-table-${index.toString()}`}
      insuranceItem={insuranceItem}
      itemNumber={index + 1}
    />
  ));
};

export default InsuranceEndorsementFormType;
