import React, { useRef } from 'react';

import { DefaultHeader } from '../../../../components';
import { useFetchData } from '../../../../hooks';
import { debounceIndexAdminProvincesRequest } from '../../../../requests/admin/provinces';
import AdminProvinceDataTable from './AdminProvinceDataTable';

const AdminProvinceIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: provinces,
    totalData: totalProvinces,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminProvincesRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar las provincias. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/provinces';

  return (
    <DefaultHeader
      title="Provincias"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Provincia',
        path: `${modelUrl}/new`
      }}
    >
      <AdminProvinceDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={provinces}
        tableDataAmount={totalProvinces}
      />
    </DefaultHeader>
  );
};

export default AdminProvinceIndex;
