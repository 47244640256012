import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';

import { useFetchData } from '../../../hooks';
import { debounceIndexAdminBeneficiaryMovsRequest } from '../../../requests/admin/adminBeneficiaryMovements';
import { FormHeader } from '../../../components';
import BeneficiaryMovementColumns from './BeneficiaryMovementColumns';
import SendDocumentEmail from '../../../components/Shared/SendDocumentEmail';
import { beneficiaryMovementsProps } from '../../../components/Shared/SendEmailModalProps';

const AdminBeneficiaryMovementsIndex = ({ location, match }) => {
  const { user: currentUser } = useSelector(state => state.auth);
  const fromAdmin = match.path.includes('/admin');
  const { id: insurancePolicyId } = match.params;
  const { insurancePolicy = {} } = location.state || {};
  const parentModelUrl = fromAdmin ? '/admin/insurance_policies' : '/insurance_policies';

  const [beneficiaryMovement, setBeneficiaryMovement] = useState({});

  const handleBreadCumb = [
    { key: 1, name: 'Pólizas', href: `${parentModelUrl}` },
    { key: 2, name: 'Detalle de póliza', href: `${parentModelUrl}/${insurancePolicyId}` },
    { key: 3, name: 'movimientos' }
  ];

  const { data: beneficiaryMovements, setMoreData, isFetching } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminBeneficiaryMovsRequest,
    parentId: insurancePolicyId
  });

  const { SendEmailModal, openEmailModal } = SendDocumentEmail({
    fromAdmin,
    fileSection: true,
    fileSectionLabel: 'Adjuntos',
    fileSectionDescription: 'Adjunta aquí todos los formularios de las incorporaciones que estás informando.',
    executiveModel: { id: insurancePolicy.id, name: 'InsurancePolicy' }
  });

  const handleOpenEmailModal = () => {
    openEmailModal();
  };

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isFetching && (
        <>
          <FormHeader handleBreadcrumb={handleBreadCumb} title="Historial Movimientos" />

          <BeneficiaryMovementDatatable
            fromAdmin={fromAdmin}
            beneficiaryMovements={beneficiaryMovements}
            insurancePolicyId={insurancePolicyId}
            setMoreData={setMoreData}
            setBeneficiaryMovement={setBeneficiaryMovement}
            handleOpenEmailModal={handleOpenEmailModal}
          />

          <SendEmailModal {...beneficiaryMovementsProps({ beneficiaryMovement, insurancePolicy, currentUser })} />
        </>
      )}
    </>
  );
};

const BeneficiaryMovementDatatable = ({
  fromAdmin,
  beneficiaryMovements,
  insurancePolicyId,
  setMoreData,
  setBeneficiaryMovement,
  handleOpenEmailModal
}) => {
  return (
    <>
      <DataTable
        noHeader
        columns={BeneficiaryMovementColumns({
          insurancePolicyId,
          fromAdmin,
          setMoreData,
          setBeneficiaryMovement,
          handleOpenEmailModal
        })}
        data={beneficiaryMovements}
        totalRows={beneficiaryMovements.length}
        noDataComponent="No hay registros para mostrar"
      />
    </>
  );
};

export default AdminBeneficiaryMovementsIndex;
