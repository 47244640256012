import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconBtn } from '../Button';

const AdminStandardSidebarLinks = ({ isActive, handleCloseSidebar }) => {
  const { notifications = {} } = useSelector(state => state.utils);
  const { pendings: myPendingsCounter } = notifications;

  return (
    <>
      <SidebarLink
        eventKey="home"
        icon="home-alt"
        optionTitle="Inicio"
        optionPath="/admin/home"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="accounts"
        icon="briefcase"
        optionTitle="Cuentas"
        optionPath="/admin/accounts"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="insurance_policies"
        icon="notes"
        optionTitle="Pólizas"
        optionPath="/admin/insurance_policies"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="renewals"
        icon="renewal-file"
        optionTitle="Renovaciones"
        optionPath="/admin/renewals"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="leads"
        icon="trending"
        optionTitle="Ventas"
        optionPath="/admin/leads"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="agenda"
        icon="play-list-add"
        optionTitle="Mi Agenda"
        badge={myPendingsCounter > 0}
        badgeItem={myPendingsCounter}
        optionPath="/admin/agenda"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="premium_collections"
        icon="list-tree"
        optionTitle="Cobranza"
        optionPath="/admin/premium_collections"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
      <SidebarLink
        eventKey="reports"
        icon="document-type"
        optionTitle="Reportes"
        optionPath="/admin/reports"
        onClick={handleCloseSidebar}
        isActivePath={isActive}
      />
    </>
  );
};

const SidebarLink = ({ badge = false, badgeItem, icon, isActivePath, optionPath, optionTitle, ...props }) => (
  <Nav.Link as={Link} to={optionPath} active={isActivePath(optionPath)} {...props}>
    <span className="link-menu">
      <IconBtn icon={icon} variant="disabled" iconSize="sm" className="btn mr-4" />
      {optionTitle}
      {badge && <span className="link-menu--badge">{badgeItem}</span>}
    </span>
  </Nav.Link>
);

export default AdminStandardSidebarLinks;
