import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { scrollToTop } from '../../../services/utils';
import AccountForm from './AccountForm';
import { createInsuredAccountRequest } from '../../../requests/insured/insuredAccounts';
import { useFetchData } from '../../../hooks';
import { debounceInsuredContactInfoRequest } from '../../../requests/insured/insuredContacts';
import insuredBasicAccount from './insuredBasicAccount';

const InsuredAccountNew = ({ location }) => {
  const modelUrl = '/insured/accounts';

  const [account, setAccount] = useState(insuredBasicAccount);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Cuentas', href: modelUrl },
    { key: 2, name: 'Crear nueva cuenta' }
  ];

  const handleSuccessCreate = response => {
    dispatch(sendAlert({ kind: 'success', message: 'Cuenta creada con éxito' }));
    history.push(`${modelUrl}/${response.data.id}`);
  };

  const { data: contactInfo } = useFetchData({
    debouncedIndexRequest: debounceInsuredContactInfoRequest,
    withoutWrapper: true
  });

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    createInsuredAccountRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  const handleCustomParams = () => {
    const { state } = location;
    let updatedAccount = { ...account };

    if (state && state.insuranceBrokerId) {
      updatedAccount.insuranceBrokerId = state.insuranceBrokerId;
    }

    if (contactInfo) {
      const sanitizedContactInfo = {
        addressAttributes: contactInfo.addressAttributes || {
          address: '',
          RegionId: '',
          communeId: ''
        },
        position: contactInfo.position || '',
        phoneNumber: contactInfo.phoneNumber || '',
        email: contactInfo.email || '',
        name: contactInfo.name || '',
        isInsuredUser: contactInfo.isInsuredUser || false
      };

      updatedAccount = {
        ...updatedAccount,
        contactsAttributes: [sanitizedContactInfo]
      };
    }

    setAccount(updatedAccount);
  };

  useEffect(scrollToTop, []);
  useEffect(handleCustomParams, [contactInfo, location]);

  return (
    <div>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={<AccountForm action="new" account={account} formRequest={handleCreateRequest} />}
      />
    </div>
  );
};

export default InsuredAccountNew;
