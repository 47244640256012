import React from 'react';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { conditionalRowStyles, customStyles } from '../Shared/DataTable';
import ComponentDataTable from '../Utils/DataTable';
import LeadFiltersHeader from './Filters/LeadFiltersHeader';
import { deleteAdminLeadRequest } from '../../requests/admin/lead';
import { useDestroyOne } from '../../hooks';
import { deleteLeadRequest } from '../../requests/lead';

const LeadDataTable = ({
  columns,
  customParamsRef,
  dateFromField,
  dateToField,
  defaultParams,
  FilterHeaderComponent,
  filterHeaderComponentProps,
  fromAdmin,
  handleIndexRequest,
  headerOptions,
  initialStep,
  isFetching,
  modelUrl,
  moreData,
  setMoreData,
  tableData,
  tableDataAmount
}) => {
  const history = useHistory();
  const { step, stepNumber } = camelCaseRecursive(customParamsRef.current);
  const currentStep = { key: stepNumber, value: step };

  const successWithPolicy = lead => ['contract_issued'].includes(lead.closeStatus);

  const handleRowClick = row => {
    const { id, insurancePolicyContractId } = row;
    const insurancePolicyModelUrl = fromAdmin ? '/admin/insurance_policies' : '/insurance_policies';
    if (successWithPolicy(row)) return history.push(`${insurancePolicyModelUrl}/${insurancePolicyContractId}`);
    return history.push(`${modelUrl}/${id}`, {
      currentParams: camelCaseRecursive(customParamsRef.current)
    });
  };

  const handleSortCase = name => {
    switch (name) {
      case 'account':
        return { sort_account: name };
      case 'insurance_broker':
        return { sort_insurance_broker: name };
      case 'insurance_category':
        return { sort_insurance_category: name };
      case 'insurance_company':
        return { sort_insurance_company: name };
      case 'primary_contact':
        return { sort_primary_contact: name };
      case 'policy_number':
        return { sort_policy_number: name };
      case 'validity_end':
        return { sort_validity_end: name };
      case 'step_days':
        return { sort_step_days: name };
      case 'deadline_days':
        return { sort_deadline_days: name };
      default:
        return { sort_column: name };
    }
  };

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: fromAdmin ? deleteAdminLeadRequest : deleteLeadRequest,
    modelName: 'venta',
    nameEndsInA: true,
    setMoreData
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns(
          currentStep?.value,
          { setMoreData },
          { handleBasicActions, modelUrl, withShow: false, withEdit: false, withDestroy: true }
        )}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        onRowClicked={handleRowClick}
        highlightOnHover
        conditionalRowStyles={conditionalRowStyles}
        handleSortCase={handleSortCase}
        customDatatableHeader={
          FilterHeaderComponent ? (
            <FilterHeaderComponent {...filterHeaderComponentProps} />
          ) : (
            <LeadFiltersHeader
              fromAdmin={fromAdmin}
              options={headerOptions}
              customParamsRef={customParamsRef}
              setMoreData={setMoreData}
              dateFromField={currentStep?.key === 3 ? 'closeDateFrom' : dateFromField}
              dateToField={currentStep?.key === 3 ? 'closeDateTo' : dateToField}
              initialStep={initialStep}
            />
          )
        }
        customStyles={customStyles}
        pointerOnHover
        resourceRequest={handleIndexRequest}
        defaultParams={defaultParams}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default LeadDataTable;
