import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { updateAdminSlideRequest, showAdminSlideRequest } from '../../../requests/admin/slides';
import AdminSlideForm from './AdminSlideForm';
import basicSlide from './basicSlide';

const AdminSlideEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/slides';
  const [slide, setSlide] = useState(basicSlide);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Slides', href: modelUrl },
    { key: 2, name: 'Editar slide' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Slide actualizada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const { image } = values.slide;
    const sendParams = snakeCaseKeys(values);
    sendParams.slide.image = image;
    updateAdminSlideRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchSlide = () => {
    showAdminSlideRequest(id, {
      dispatch,
      successCallback: response => setSlide(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchSlide, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminSlideForm action="edit" slide={slide} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminSlideEdit;
