import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import { LinkBtn } from '../../components';
import SlideForm from './SlideForm';
import { createSlideRequest } from '../../requests/slides';

const SlideNew = props => {
  // eslint-disable-next-line no-unused-vars
  const [slide, setSlide] = useState({
    fileInfo: {},
    position: '',
    caption: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessCreate = response => {
    const { history } = props;
    const _slide = response.data;
    history.push(`/slides/${_slide.id}`);
  };

  const handleCreateRequest = _slide => {
    createSlideRequest({
      dispatch,
      params: _slide,
      formData: true,
      successCallback: response => handleSuccessCreate(response)
    });
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h2>Crear Slide</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light" block to="/slides">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <SlideForm
            slide={slide}
            action="new"
            formRequest={handleCreateRequest}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect()(SlideNew);
