import React, { useState, useContext } from 'react';
import { Nav, Accordion, Card } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Link } from 'react-router-dom';
import { IconBtn } from '../Button';
import IconGG from '../Icon';

const CustomToggle = ({ eventKey, title, icon, setIsAccordionOpen }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (eventKey === currentEventKey) {
      setIsAccordionOpen(false);
    } else {
      setIsAccordionOpen(true);
    }
  });
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Nav.Link role="button" onClick={decoratedOnClick}>
      <span className="link-menu accordion-header">
        <IconBtn icon={icon} variant="disabled" iconSize="sm" className="btn mr-4" />
        {title}
        <IconGG
          icon={isCurrentEventKey ? 'chevron-up' : 'chevron-down'}
          variant="transparent"
          size="sm"
          className="ml-2"
        />
      </span>
    </Nav.Link>
  );
};

const AccordionNavLink = ({ accordionTitle, accordionIcon, accordionItems, handleCloseSidebar, isActive }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Card className={`nav-accordion ${isAccordionOpen ? 'open' : ''}`}>
        <Card.Header>
          <CustomToggle
            title={accordionTitle}
            icon={accordionIcon}
            setIsAccordionOpen={setIsAccordionOpen}
            eventKey={`accordion-${accordionTitle}`}
          />
        </Card.Header>
        {accordionItems && accordionItems.length > 0 && (
          <Accordion.Collapse eventKey={`accordion-${accordionTitle}`}>
            <Card.Body>
              {accordionItems.map(({ itemTitle, to }) => (
                <Nav.Link
                  key={itemTitle}
                  eventKey={to}
                  as={Link}
                  to={`/${to}`}
                  onClick={handleCloseSidebar}
                  active={isActive(to)}
                >
                  <span className="link-menu nav-accordion--item">
                    <IconGG icon="edit-black-point" variant="disabled" size="sm" className="mr-2" />
                    {itemTitle}
                  </span>
                </Nav.Link>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
};

export default AccordionNavLink;
