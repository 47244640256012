import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../../services/yupCustomMethods';
import { FormikInput, FormikSwitch, FormikSelect, UploadImage } from '../../../components';
import landingTypes from './landingTypes';

const AdminSlideForm = ({ action, errors, onHide, submitVariant, touched, setFieldValue, slide }) => {
  const submitBtnText = action === 'new' ? 'Crear Slide' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-center align-items-center">
            <Field name="slide[image]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  forProfileAvatar
                  imageUrl={getIn(slide.image, 'fileUrl')}
                  onChange={image => setFieldValue(field.name, image)}
                  label="Adjuntar imagen"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="slide[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Slide activa?" />}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="slide[landingType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de landing"
                error={getIn(errors, field.name)}
                // touched
                touched={getIn(touched, field.name)}
                options={landingTypes}
                defaultValue={slide.landingType}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                isClearable
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="slide[position]">
            {({ field }) => (
              <FormikInput
                {...field}
                type="number"
                label="Posición"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="slide[caption]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Frase"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="my-5">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ slide }) => {
  return { slide };
};

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const validationSchema = Yup.object().shape({
  slide: Yup.object().shape({
    position: Yup.string().nullable(),
    caption: Yup.string().nullable(),
    landingType: Yup.string().required('Debe ingresar un tipo de Landing'),
    image: Yup.mixed()
      .test('required', 'Debes adjuntar una imagen.', value => {
        return value.size || value.fileUrl;
      })
      .test('fileSize', 'Archivo es demasiado grande.', value => {
        if (!value.size) return true;
        return value && value.size <= FILE_SIZE;
      })
      .test('fileFormat', 'Formato debe ser .jpg, .jpeg o .png', value => {
        if (!value.type) return true;
        return value && SUPPORTED_FORMATS.includes(value.type);
      })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminSlideForm);
