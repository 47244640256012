import React, { useState } from 'react';
import { getIn } from 'formik';
import { components } from 'react-select';

import { InputRemoteSelect } from '../Select';
import { useFetchForRemoteSelect } from '../../../hooks';

const RemoteSelectFilter = ({
  customParams,
  debouncedIndexRequest,
  fieldName,
  indexRequest,
  isMulti,
  label,
  requestParams,
  setCustomParams,
  ...props
}) => {
  const { fetchOptions } = useFetchForRemoteSelect({
    indexRequest,
    debouncedIndexRequest,
    value: getIn(customParams, fieldName),
    ...(requestParams && { initialParams: requestParams })
  });

  const [selectedOptions, setSelectedOptions] = useState([]);

  const isOptionSelected = option => {
    return selectedOptions.some(({ value }) => value === option.value);
  };

  const CustomOption = optionProps => {
    const { data: optionData, label: optionLabel } = optionProps;

    return (
      <components.Option {...optionProps} className="d-flex">
        <input type="checkbox" className="mr-3" checked={isOptionSelected(optionData)} onChange={() => null} />
        <p className="option">{optionLabel}</p>
      </components.Option>
    );
  };

  const handleOnChangeParams = ({ selected, empty }) => {
    setCustomParams(params => ({ ...params, [fieldName]: selected || empty }));
  };

  const additionalProps = isMulti
    ? {
        closeMenuOnSelect: false,
        hideSelectedOptions: false,
        components: { Option: CustomOption, IndicatorSeparator: () => null },
        onChange: selected => setSelectedOptions(selected || []),
        onBlur: () => handleOnChangeParams({ selected: selectedOptions?.map(option => option.value), empty: [] })
      }
    : { onChange: selected => handleOnChangeParams({ selected: selected?.value, empty: '' }) };

  return <InputRemoteSelect isClearable isMulti={isMulti} request={fetchOptions} {...additionalProps} {...props} />;
};

export default RemoteSelectFilter;
