import React, { useState, useRef, useLayoutEffect } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';
import { Redirect } from 'react-router-dom';

import { useFetchData } from '../../hooks';
import { FormHeader } from '../../components';
import {
  debounceIndexPolicyBeneficiaryRequest,
  indexPolicyBeneficiaryRequest,
  exportBeneficiariesRequest
} from '../../requests/beneficiaries';
import MovementNew from './MovementNew';
import BeneficiaryNew from './BeneficiaryNew';
import { downloadFile } from '../../services/utils';
import { AbilityContext } from '../../config/abilityContext';
import BeneficiaryStatisticCard from '../../components/Beneficiary/BeneficiaryStatisticCard';
import BeneficiaryDatatable from './BeneficiaryDatatable';

const BeneficiaryIndex = ({ match, location }) => {
  const fromAdmin = match.path.includes('/admin');
  const { id: insurancePolicyId } = match.params;
  const { insurancePolicy = {} } = location.state || {};
  const dispatch = useDispatch();
  const ability = useAbility(AbilityContext);

  const parentModelUrl = '/insurance_policies';
  const [movementModalShow, setMovementModalShow] = useState(false);
  const [beneficiaryModalShow, setBeneficiaryModalShow] = useState(false);
  const [activeBeneficiaries, setActiveBeneficiaries] = useState(0);
  const [totalDependentsNumber, setTotalDependentsNumber] = useState(0);

  const customParamsRef = useRef({});

  const { data: beneficiaries, totalData: totalBeneficiaries, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexPolicyBeneficiaryRequest,
    parentId: insurancePolicyId,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los beneficiarios. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const { data: indexMetadata, moreData, setMoreData } = useFetchData({
    debouncedIndexRequest: indexPolicyBeneficiaryRequest,
    parentId: insurancePolicyId,
    withoutWrapper: true
  });

  useLayoutEffect(() => {
    setActiveBeneficiaries(indexMetadata?.metadata?.activeHolders ?? 0);
    setTotalDependentsNumber(indexMetadata?.metadata?.totalDependents ?? 0);
  }, [indexMetadata]);

  const handleMovementModalClose = () => {
    setMovementModalShow(false);
  };

  const handleBeneficiaryModalClose = () => {
    setBeneficiaryModalShow(false);
  };

  const handleBreadCumb = [
    { key: 1, name: 'Pólizas', href: `${parentModelUrl}` },
    { key: 2, name: 'Detalle de póliza', href: `${parentModelUrl}/${insurancePolicyId}` },
    { key: 3, name: 'Nómina' }
  ];

  const handleExportBeneficiariesRequest = ({ xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportBeneficiariesRequest(insurancePolicyId, {
      dispatch,
      params: snakecaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const canManageBeneficiary = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'Beneficiary');
  };

  if (!canManageBeneficiary()) {
    return <Redirect to={`${parentModelUrl}/${insurancePolicyId}`} />;
  }

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <FormHeader
        handleBreadcrumb={handleBreadCumb}
        title="Nómina"
        headerBtn
        headerBtnOptions={{
          icon: 'export',
          name: 'Cargar movimientos',
          onClick: () => setMovementModalShow(true)
        }}
        secondBtn
        secondBtnOptions={{
          icon: 'add',
          name: 'Nuevo Beneficiario',
          onClick: () => setBeneficiaryModalShow(true)
        }}
        thirdBtn
        thirdBtnOptions={{
          isIconTooltip: true,
          icon: 'software-download',
          name: 'Descargar nómina',
          variant: 'primary-dark',
          onClick: () =>
            handleExportBeneficiariesRequest({
              xlsxName: `nomina_poliza_${insurancePolicy.policyNumber}`
            })
        }}
        fourthBtn
        fourthBtnOptions={{
          isIconTooltip: true,
          icon: 'notes',
          name: 'Historial Movimientos',
          variant: 'primary-dark',
          path: {
            pathname: `/insurance_policies/${insurancePolicyId}/movements`,
            state: { originPath: `/insurance_policies/${insurancePolicyId}`, insurancePolicy }
          }
        }}
      />

      <Row className="mb-5">
        <Col md={4}>
          <BeneficiaryStatisticCard
            title="Número de títulares vigentes"
            value={activeBeneficiaries}
            imageVariant="bg-light-success"
          />
        </Col>
        <Col md={4}>
          <BeneficiaryStatisticCard
            icon="briefcase"
            variant="primary-light"
            title="Número de cargas vigentes"
            value={totalDependentsNumber}
          />
        </Col>
      </Row>

      <BeneficiaryDatatable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={parentModelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={beneficiaries}
        tableDataAmount={totalBeneficiaries}
        insurancePolicyId={insurancePolicyId}
      />

      {!isFetching && (
        <>
          <MovementNew
            handleModalClose={handleMovementModalClose}
            modalShow={movementModalShow}
            insurancePolicy={insurancePolicy}
            setMoreData={setMoreData}
            fromAdmin={fromAdmin}
          />

          <BeneficiaryNew
            handleModalClose={handleBeneficiaryModalClose}
            modalShow={beneficiaryModalShow}
            policyId={insurancePolicyId}
            setMoreData={setMoreData}
            fromAdmin={fromAdmin}
          />
        </>
      )}
    </>
  );
};

export default BeneficiaryIndex;
