import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminBeneficiaryMovsRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_policies/${insurancePolicyId}/beneficiary_movements`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const exportAdminMovementSummary = (
  insurancePolicyId,
  beneficiaryMovementId,
  { dispatch, params, successCallback, callback, failureCallback }
) =>
  ApiService.request(
    'get',
    `/admin/insurance_policies/${insurancePolicyId}/beneficiary_movements/${beneficiaryMovementId}/movement_summary`,
    {
      dispatch,
      params,
      responseType: 'text',
      successCallback,
      callback,
      failureCallback
    }
  );

export const debounceIndexAdminBeneficiaryMovsRequest = AwesomeDebouncePromise(indexAdminBeneficiaryMovsRequest, 300);
