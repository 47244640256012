import ApiService from '../services/apiService';

export default function sendDocumentRequest({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) {
  ApiService.request('post', '/shared_endpoints/send_document', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
}
