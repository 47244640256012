const endorsementActionOptions = [
  // { label: 'Sin acción', value: 'empty_action' },
  { label: 'Modificar', value: 'modify' },
  { label: 'Eliminar', value: 'remove' },
  { label: 'Agregar', value: 'add' },
  { label: 'Prorrogar', value: 'extension' },
  { label: 'Cancelación', value: 'cancellation' },
  { label: 'Anulación', value: 'annulment' }
];

const endorsementTypeOptions = [
  // { label: 'Sin tipo', value: 'empty_type' },
  { label: 'Corte', value: 'cut' },
  { label: 'Disminución', value: 'decrease' },
  { label: 'Aumento', value: 'increase' },
  { label: 'Modificación', value: 'modification' },
  { label: 'Rehabilitación', value: 'rehabilitation' }
];

const statusOptions = [
  { label: 'Solicitado', value: 'requested' },
  { label: 'Actualizado', value: 'updated' }
];

export { endorsementActionOptions, endorsementTypeOptions, statusOptions };
