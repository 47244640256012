import React from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown
} from 'react-bootstrap';

const InputDropdownRight = ({
  items,
  placeholder,
  ariaLabel,
  ariaDescribedby,
  variant,
  title,
  size
}) => (
  <InputGroup size={size}>
    <FormControl
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
    />
    <DropdownButton
      as={InputGroup.Append}
      variant={variant}
      title={title}
      id="input-group-dropdown-2"
    >
      {items.map(item => (
        <Dropdown.Item href={item.path} key={item.key}>
          {item.text}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </InputGroup>
);

InputDropdownRight.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  size: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      ariaDescribedby: PropTypes.string,
      key: PropTypes.string.isRequired
    })
  ).isRequired
};

InputDropdownRight.defaultProps = {
  variant: 'info',
  ariaDescribedby: null,
  ariaLabel: null,
  placeholder: null,
  size: 'md'
};

export default InputDropdownRight;
