import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../../actions/utils';
import { FormHeader } from '../../../../components';
import { updateAdminProvinceRequest, showAdminProvinceRequest } from '../../../../requests/admin/provinces';
import AdminProvinceForm from './AdminProvinceForm';
import basicProvince from './basicProvince';

const AdminProvinceEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/provinces';
  const [province, setProvince] = useState(basicProvince);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Provincias', href: modelUrl },
    { key: 2, name: 'Editar provincias' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Provincia actualizada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminProvinceRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchProvince = () => {
    showAdminProvinceRequest(id, {
      dispatch,
      successCallback: response => setProvince(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProvince, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminProvinceForm action="edit" province={province} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminProvinceEdit;
