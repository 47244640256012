import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { redirectTo, scrollToTop } from '../../../services/utils';
import basicInsuranceEndorsement from './basicInsuranceEndorsement';
import InsuranceEndorsementForm from '../../../components/InsuranceEndorsement/InsuranceEndorsementForm';
import { InsuranceEndorsementProvider } from '../../../components/InsuranceEndorsement/InsuranceEndorsementContext';
import { createInsuredInsuranceEndorsementRequest } from '../../../requests/insured/insuredInsuranceEndorsements';

const InsuredInsuranceEndorsementNew = ({ location }) => {
  const action = 'new';
  const { insurancePolicy, modelUrl, originPath } = location.state;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', onClick: () => redirectTo(history, modelUrl) },
    { key: 2, name: 'Detalle de póliza', onClick: () => redirectTo(history, originPath) },
    { key: 3, name: 'Crear nuevo endoso' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Endoso creado con éxito' }));
    redirectTo(history, originPath);
  };

  const renderItemAttributesByCase = ({ endorsementAction, endorsementType }) => {
    if (endorsementType === 'cut') return false;
    return endorsementAction !== 'extension';
  };

  const formatInsuranceItemAttributes = ({ insuranceEndorsement }) => {
    const { endorsementType, endorsementAction } = insuranceEndorsement;
    if (!renderItemAttributesByCase({ endorsementAction, endorsementType })) return undefined;
    let { insuranceItemAttributes } = insuranceEndorsement;
    if (endorsementAction === 'add') {
      insuranceItemAttributes = { ...insuranceItemAttributes, insurancePolicyId: insurancePolicy.id };
    }
    return insuranceItemAttributes;
  };

  const isRequiredItemAttributes = ({ insuranceEndorsement }) => {
    const { endorsementAction, endorsementType } = insuranceEndorsement;
    return (
      (['decrease', 'increase'].includes(endorsementType) && ['add', 'modify', 'remove'].includes(endorsementAction)) ||
      ['modification'].includes(endorsementType)
    );
  };

  const parsedValuesObject = insuranceEndorsement => {
    const { originalInsuranceItem } = insuranceEndorsement;
    const emptyItem = { insuranceItemAttributes: undefined, originalInsuranceItem: undefined };
    let sendParams = { ...insuranceEndorsement, ...emptyItem };
    if (isRequiredItemAttributes({ insuranceEndorsement })) {
      sendParams = {
        ...sendParams,
        originalInsuranceItem,
        insuranceItemAttributes: formatInsuranceItemAttributes({ insuranceEndorsement })
      };
    }
    return sendParams;
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const { approval } = values.insuranceEndorsement;
    const sendParams = snakeCaseKeys(parsedValuesObject(values.insuranceEndorsement));
    createInsuredInsuranceEndorsementRequest(insurancePolicy.id, {
      dispatch,
      params: { insurance_endorsement: { ...sendParams, approval, status: 'updated' } },
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(scrollToTop, []);

  return (
    <InsuranceEndorsementProvider value={{ action, originalInsuranceEndorsement: basicInsuranceEndorsement }}>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={
          <InsuranceEndorsementForm
            action={action}
            insurancePolicy={insurancePolicy}
            insuranceEndorsement={basicInsuranceEndorsement}
            formRequest={handleCreateRequest}
            originPath={originPath}
          />
        }
      />
    </InsuranceEndorsementProvider>
  );
};

export default InsuredInsuranceEndorsementNew;
