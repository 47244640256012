import React, { useRef, useEffect } from 'react';

const useOutsideAlerter = (ref, cb) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      cb();
    }
  };

  const handleEvents = () => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  };

  useEffect(handleEvents, [ref]);
};

const OutsideAlerter = ({ children, handleOutsideEvent }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleOutsideEvent);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
