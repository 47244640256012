import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { IconBtn } from '../Utils/Button';
import { DatesFilter, SearchFilter } from '../Utils/Filters';

const SearchDateFilter = ({
  containerClass,
  customParams,
  dateFromField,
  dateToField,
  setCustomParams,
  showDateOnMount,
  dateFilterTooltipText
}) => {
  const startDateField = dateFromField || 'dateFrom';
  const endDateField = dateToField || 'dateTo';

  const [showSearch, setShowSearch] = useState(true);

  const handleShowSearch = () => {
    setShowSearch(true);
    if (!customParams.query && (customParams[startDateField] || customParams[endDateField])) setShowSearch(false);
    if (showDateOnMount) setShowSearch(showDateOnMount);
  };

  useEffect(handleShowSearch, [customParams]);

  return (
    <Row className={containerClass}>
      {showSearch ? (
        <>
          <Col xs={2} className="pt-4">
            <IconBtn
              variant="cancel"
              className="no-shadow"
              icon="calendar-today"
              onClick={() => setShowSearch(false)}
            />
          </Col>
          <Col xs={10} className="pt-4">
            <SearchFilter customParams={customParams} setCustomParams={setCustomParams} />
          </Col>
        </>
      ) : (
        <>
          <Col xs={10} className="pt-4">
            <DatesFilter
              startDateField={startDateField}
              endDateField={endDateField}
              customParams={customParams}
              setCustomParams={setCustomParams}
              tooltipText={dateFilterTooltipText}
            />
          </Col>
          <Col xs={2} className="d-flex justify-content-end pt-4">
            <IconBtn variant="cancel" className="no-shadow" icon="search" onClick={() => setShowSearch(true)} />
          </Col>
        </>
      )}
    </Row>
  );
};

SearchDateFilter.defaultProps = {
  containerClass: '',
  customParams: {},
  setCustomParams: () => null
};

export default SearchDateFilter;
