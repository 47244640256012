import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';

import { InputRemoteSelect } from '../Utils/Select';
import useFetchForRemoteSelect from '../../hooks/useFetchForRemoteSelect';
import { debounceIndexRegionsRequest, indexRegionRequest } from '../../requests/regions';
import { debounceIndexCommunesRequest, indexCommuneRequest } from '../../requests/communes';

const RegionCommuneSelect = ({ regionAbbr, communeAbbr, modelName, values, setFieldValue, errors, touched }) => {
  const { options: regions, selectedOption: selectedRegion, fetchOptions: fetchRegions } = useFetchForRemoteSelect({
    indexRequest: indexRegionRequest,
    debouncedIndexRequest: debounceIndexRegionsRequest,
    value: getIn(values, `${modelName}[regionId]`),
    initialParams: {
      sort_column: 'display_order',
      sort_direction: 'asc'
    }
  });

  const { options: communes, selectedOption: selectedCommune, fetchOptions: fetchCommunes } = useFetchForRemoteSelect({
    indexRequest: indexCommuneRequest,
    debouncedIndexRequest: debounceIndexCommunesRequest,
    value: getIn(values, `${modelName}[communeId]`),
    initialParams: {
      region: getIn(values, `${modelName}[regionId]` || '')
    },
    nestedDependency: selectedRegion
  });

  const handleRegionChange = value => {
    setFieldValue(`${modelName}[regionId]`, value);
    setFieldValue(`${modelName}[communeId]`, '');
  };

  const communeMessage = () => {
    let message;
    if (!selectedRegion) {
      message = 'Debes seleccionar primero una Región';
    } else {
      message = 'Elige una comuna';
    }
    return message;
  };

  return (
    <Row>
      <Field name={`${modelName}[regionId]`}>
        {({ field }) => (
          <Col md={6}>
            <InputRemoteSelect
              {...field}
              abbr={regionAbbr}
              isClearable={!regionAbbr}
              label="Región"
              defaultOptions={regions}
              value={selectedRegion}
              request={fetchRegions}
              onChange={data => handleRegionChange(data ? data.value : '')}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          </Col>
        )}
      </Field>
      <Col md={6}>
        <Field name={`${modelName}[communeId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              abbr={communeAbbr}
              isClearable={!communeAbbr}
              disabled={!selectedRegion}
              label="Comuna"
              defaultOptions={communes}
              request={fetchCommunes}
              placeholder={communeMessage()}
              value={selectedCommune}
              onChange={data => setFieldValue(field.name, data ? data.value : '')}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default RegionCommuneSelect;
