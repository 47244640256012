import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { useFetchData } from '../../hooks';
import { debounceIndexInsuredAccountRequest } from '../../requests/insured/insuredAccounts';
import { debounceIndexInsuredInsurancePoliciesRequest } from '../../requests/insured/insuredInsurancePolicies';

const InsuredContactForm = ({ errors, isSubmitting, fieldOptions, fromIndex, setFieldValue, touched, values }) => {
  const { accountId, insurancePolicyId } = values.insuredContact;

  const { data: accounts } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredAccountRequest,
    fetchingErrorMessage: 'Error al buscar las cuentas. Por favor inténtelo nuevamente.',
    customParams: { for_selector: 'true' }
  });

  const { data: insurancePolicies, isFetching, setMoreData } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredInsurancePoliciesRequest,
    fetchingErrorMessage: 'Error al buscar las pólizas. Por favor inténtelo nuevamente.',
    customParams: { for_selector: 'true', policy_type: 'contract', account_id: accountId }
  });

  const handleOnChangeAccountId = () => {
    if (fromIndex === 'sinister') setMoreData(m => !m);
    if (insurancePolicyId) setFieldValue('insuredContact[insurancePolicyId]', '');
  };

  useEffect(handleOnChangeAccountId, [accountId]);

  return (
    <Form>
      <Row>
        {fieldOptions.map(({ Component, description, fieldProps, isSelector, name, title, ...props }, index) => {
          const commonProps = { customFieldProps: { ...fieldProps }, fieldProps, name, setFieldValue };

          if (isSelector) {
            if (name === 'accountId') props.selectorProps({ ...commonProps, accounts });
            if (name === 'insurancePolicyId') props.selectorProps({ ...commonProps, insurancePolicies, isFetching });
          }

          return (
            <React.Fragment key={`field-${index.toString()}`}>
              {title && (
                <Col xs={12}>
                  <p className="section-title mb-0">{title}</p>
                </Col>
              )}
              {description && (
                <Col xs={12}>
                  <p className="section-subtitle mb-0">{description}</p>
                </Col>
              )}

              <Col xs={12} className="mb-5">
                <Field name={`insuredContact[${name}]`}>
                  {({ field }) => (
                    <Component
                      {...field}
                      {...commonProps.customFieldProps}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>

      <Row className="my-5">
        <Col>
          <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
            Enviar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ insuredContact }) => ({ insuredContact });

const handleAttributeValidations = ({ fieldOptions }) => {
  const validations = {};
  fieldOptions.forEach(({ fieldProps, name, yupProps }) => {
    let yupValidation = fieldProps.abbr ? Yup.string().required(yupProps.requiredMessage) : Yup.string().nullable();
    yupValidation = yupProps.characterLimit
      ? yupValidation.max(yupProps.characterLimit, `Máx. ${yupProps.characterLimit} caracteres`)
      : yupValidation;
    validations[name] = yupValidation;
  });
  return validations;
};

const validationSchema = ({ fieldOptions }) =>
  Yup.object().shape({
    insuredContact: Yup.object().shape(handleAttributeValidations({ fieldOptions }))
  });

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(InsuredContactForm);
