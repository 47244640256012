import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';

import { HomeWelcomeCard, IconBtn, InfoCardCarousel, StatisticCard } from '../../../components';
import { RequestRegisterSuccess } from '../../../components/Shared';
import { useFetchData } from '../../../hooks';
import { debounceIndexInsuredInsuranceBrokerRequest } from '../../../requests/insured/insuredInsuranceBrokers';
import { debounceHomeDataInsuredUserRequest } from '../../../requests/insured/insuredUsers';
import { redirectTo, scrollToTop } from '../../../services/utils';
import InfoCardInsuranceCompany from './InfoCardInsuranceCompany';

const InsuredHomeIndex = () => {
  const history = useHistory();

  const { data: homeData, isFetching } = useFetchData({
    debouncedIndexRequest: debounceHomeDataInsuredUserRequest,
    withoutWrapper: true
  });

  const { data: brokers } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredInsuranceBrokerRequest
  });

  const {
    currentUser = {},
    daysOnNico = 0,
    insurancePolicyNearInvalidityCount = 0,
    insurancePolicyTotalNetPremium = '0,00 UF',
    insurancePolicyValidCount = 0,
    leadWithQuotationCount = 0
  } = homeData;

  const { name } = currentUser;

  const parseDays = () => {
    const daysString = daysOnNico > 1 ? 'días' : 'día';
    return `${daysOnNico} ${daysString}`;
  };

  useEffect(scrollToTop, []);

  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="insured-home-index">
          <RequestRegisterSuccess />

          <header className="mb-5">
            <HomeWelcomeCard
              icon="smile-mouth-open"
              variant="transparent"
              title={`¡Hola ${name}!`}
              subtitle={`Llevas ${parseDays()} usando NICO`}
            />
          </header>

          <Row>
            <Col sm={6} xl={3} className="d-flex align-items-stretch mb-5">
              <StatisticCard
                clickable
                icon="notes"
                variant="primary-light"
                title="N° pólizas"
                value={insurancePolicyValidCount}
                onClick={() => redirectTo(history, '/insured/insurance_policies', { isValid: 'true' })}
                cardVariant="w-100"
              />
            </Col>
            <Col sm={6} xl={3} className="d-flex align-items-stretch mb-5">
              <StatisticCard
                icon="dollar"
                variant="success"
                title="Prima total neta"
                value={insurancePolicyTotalNetPremium}
                cardVariant="w-100"
              />
            </Col>
            <Col sm={6} xl={3} className="d-flex align-items-stretch mb-5">
              <StatisticCard
                clickable
                icon="danger"
                variant="warning"
                title="Pólizas por vencer"
                value={insurancePolicyNearInvalidityCount}
                onClick={() => redirectTo(history, '/insured/insurance_policies', { nearInvalidity: 'true' })}
                cardVariant="w-100"
              />
            </Col>
            <Col sm={6} xl={3} className="d-flex align-items-stretch mb-5">
              <StatisticCard
                clickable
                icon="calculator"
                variant="primary-light"
                title="Cotizaciones disponibles"
                value={leadWithQuotationCount}
                onClick={() => redirectTo(history, '/insured/leads')}
                cardVariant="w-100"
              />
            </Col>
          </Row>

          {brokers.length > 0 && (
            <Row className="mb-5">
              <Col className="section__info-card-carousel">
                <InfoCardCarousel
                  title="Información corredor"
                  btnComponent={
                    <IconBtn to="/insured/contact" className="btn-sm">
                      Contacto
                    </IconBtn>
                  }
                  items={brokers.map(broker => ({
                    ...broker,
                    avatar: { present: true, fileUrl: broker.userAvatarInfo.fileUrl }
                  }))}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col className="section__info-card-insurance-companies">
              <InfoCardInsuranceCompany />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default InsuredHomeIndex;
