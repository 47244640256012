import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const licensePlateInfoPublicRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) => {
  ApiService.request('post', '/public/shared_endpoints/license_plate_info', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const debounceLicensePlateInfoPublicRequest = AwesomeDebouncePromise(licensePlateInfoPublicRequest, 500);
