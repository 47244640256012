import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal } from '../..';
import { ModalBodyIcon, SendEmailForm } from '../../Shared';
import InsuranceCompanyTable from './InsuranceCompanyTable';
import { sendAdminLeadReservationRequest } from '../../../requests/admin/lead';
import { sendLeadReservationRequest } from '../../../requests/lead';

const sendEmailAttributes = {
  attachments: [],
  body: '',
  from: '',
  subject: '',
  cc: []
};

const ReservationEmail = ({
  fromAdmin,
  extraCloseFunction = () => null,
  fileSection = false,
  fileSectionLabel = '',
  fileSectionDescription = '',
  insuranceCategorySelected,
  leadId
}) => {
  const { user: currentUser } = useSelector(state => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const openEmailModal = () => setModalShow(true);

  const closeModal = () => {
    setModalShow(false);
    extraCloseFunction();
  };

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Reserva enviada con éxito' }));
    setModalShow(false);
    extraCloseFunction();
  };

  const handleSendEmailRequest = (params, setSubmitting) => {
    const sendParams = snakeCaseKeys({ ...params });
    const endpointRequest = fromAdmin ? sendAdminLeadReservationRequest : sendLeadReservationRequest;
    endpointRequest(leadId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const SendEmailModal = ({ attachments = [], body = '', modalTitle = {}, subject = '', cc = [] }) => {
    const [sendEmail, setSendEmail] = useState({ ...sendEmailAttributes, from: currentUser.email });
    const [temporalDropzoneFiles, setTemporalDropzoneFiles] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);

    const handleOnChangeValues = () => {
      setSendEmail({ ...sendEmail, attachments, body, subject, cc, temporalDropzoneFiles, bcc: selectedEmails });
    };

    useEffect(handleOnChangeValues, [attachments, body, subject, cc, temporalDropzoneFiles, selectedEmails]);

    return (
      <SimpleCenteredModal
        size="xl"
        show={modalShow}
        body={
          <ModalBodyIcon
            icon="mail"
            iconVariant="success"
            content={`Estás por solicitar una reserva para la cuenta ${modalTitle.recipient}`}
            confirmText={false}
            highlightedText=""
            highlightedVariant="white"
            recipient={false}
            formContent={
              <>
                <p className="mb-4">
                  Selecciona las Compañías de Seguros a las que quieres solicitar la reserva. Los correos se enviarán
                  con copia oculta.
                </p>

                <InsuranceCompanyTable
                  selectedEmails={selectedEmails}
                  setSelectedEmails={setSelectedEmails}
                  insuranceCategorySelected={insuranceCategorySelected}
                />
                <SendEmailForm
                  sendEmail={sendEmail}
                  formRequest={handleSendEmailRequest}
                  handleModalClose={closeModal}
                  temporalDropzoneFiles={temporalDropzoneFiles}
                  setTemporalDropzoneFiles={setTemporalDropzoneFiles}
                  fileSection={fileSection}
                  fileSectionLabel={fileSectionLabel}
                  fileSectionDescription={fileSectionDescription}
                  showToField={false}
                  showCcField
                />
              </>
            }
          />
        }
        onHide={() => closeModal()}
      />
    );
  };

  return { openEmailModal, SendEmailModal };
};

export default ReservationEmail;
