import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminInsuranceCompanyLogosRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/insurance_company_logos', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showAdminInsuranceCompanyLogoRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_company_logos/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createAdminInsuranceCompanyLogoRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/insurance_company_logos', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminInsuranceCompanyLogoRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/insurance_company_logos/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAdminInsuranceCompanyLogoRequest = (
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/insurance_company_logos/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexAdminInsuranceCompanyLogosRequest = AwesomeDebouncePromise(
  indexAdminInsuranceCompanyLogosRequest,
  300
);
