import basicInsurancePolicy from '../../InsurancePolicy/basicInsurancePolicy';

const insuredBasicInsurancePolicy = {
  executiveManagerId: '',
  insuranceBrokerId: '',
  ...basicInsurancePolicy,
  nicoCode: 'ext',
  translatedNicoCode: 'Ext'
};

export default insuredBasicInsurancePolicy;
