import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { capitalizeString } from '../../services/utils';
import { ButtonTooltip } from '../Utils/Tooltips';
import { BtnTooltip, IconBtn } from '../Utils/Button';

const DefaultHeader = ({
  children,
  className,
  headerBtn,
  headerBtnOptions,
  secondBtn,
  secondBtnOptions,
  thirdBtn,
  thirdBtnOptions,
  fourthBtn,
  fourthBtnOptions,
  title,
  tooltipOnClick,
  tooltipText
}) => (
  <Row className={`custom-header ${className}`}>
    {title && (
      <Col className="custom-header__title">
        <h5 className="text-color-primary-dark">
          {title}
          {(tooltipText || tooltipOnClick) && (
            <ButtonTooltip tooltipText={tooltipText} onClick={tooltipOnClick} direction="right" className="ml-3">
              ?
            </ButtonTooltip>
          )}
        </h5>
      </Col>
    )}
    {headerBtn && (
      <Col md={6} lg={6} className="custom-header__button">
        {headerBtnOptions.isIconTooltip ? (
          <BtnTooltip
            variant={headerBtnOptions.variant}
            icon={headerBtnOptions.icon}
            iconSize={headerBtnOptions.iconSize}
            to={headerBtnOptions.path}
            onClick={headerBtnOptions.onClick}
            tooltipText={capitalizeString(headerBtnOptions.name)}
            className={headerBtnOptions.className}
          />
        ) : (
          <IconBtn
            variant={headerBtnOptions.variant}
            icon={headerBtnOptions.icon}
            to={headerBtnOptions.path}
            onClick={headerBtnOptions.onClick}
            disabled={headerBtnOptions.disabled}
            className="rounded-border"
          >
            {capitalizeString(headerBtnOptions.name)}
          </IconBtn>
        )}

        {secondBtn && (
          <IconBtn
            variant={secondBtnOptions.variant}
            icon={secondBtnOptions.icon}
            to={secondBtnOptions.path}
            onClick={secondBtnOptions.onClick}
            disabled={secondBtnOptions.disabled}
            className="rounded-border"
          >
            {capitalizeString(secondBtnOptions.name)}
          </IconBtn>
        )}

        {thirdBtn &&
          (thirdBtnOptions.isIconTooltip ? (
            <BtnTooltip
              variant={thirdBtnOptions.variant}
              icon={thirdBtnOptions.icon}
              iconSize={thirdBtnOptions.iconSize}
              to={thirdBtnOptions.path}
              onClick={thirdBtnOptions.onClick}
              tooltipText={capitalizeString(thirdBtnOptions.name)}
              className={thirdBtnOptions.className}
            />
          ) : (
            <IconBtn
              variant={thirdBtnOptions.variant}
              icon={thirdBtnOptions.icon}
              iconSize={thirdBtnOptions.iconSize}
              to={thirdBtnOptions.path}
              onClick={thirdBtnOptions.onClick}
              disabled={thirdBtnOptions.disabled}
              className="rounded-border"
            >
              {capitalizeString(thirdBtnOptions.name)}
            </IconBtn>
          ))}

        {fourthBtn &&
          (fourthBtnOptions.isIconTooltip ? (
            <BtnTooltip
              variant={fourthBtnOptions.variant}
              icon={fourthBtnOptions.icon}
              iconSize={fourthBtnOptions.iconSize}
              to={fourthBtnOptions.path}
              onClick={fourthBtnOptions.onClick}
              tooltipText={capitalizeString(fourthBtnOptions.name)}
              className={fourthBtnOptions.className}
            />
          ) : (
            <IconBtn
              variant={fourthBtnOptions.variant}
              icon={fourthBtnOptions.icon}
              iconSize={fourthBtnOptions.iconSize}
              to={fourthBtnOptions.path}
              onClick={fourthBtnOptions.onClick}
              disabled={fourthBtnOptions.disabled}
              className="rounded-border"
            >
              {capitalizeString(fourthBtnOptions.name)}
            </IconBtn>
          ))}
      </Col>
    )}
    <Col xs={12}>{children}</Col>
  </Row>
);
const defaultBtnProps = {
  disabled: false,
  icon: '',
  name: 'Nuevo',
  path: '',
  variant: 'primary'
};

DefaultHeader.defaultProps = {
  className: '',
  headerBtnOptions: defaultBtnProps
};

export default DefaultHeader;
