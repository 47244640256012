export const leadSteps = [
  { key: 1, label: 'Oportunidades', value: 'oportunity' },
  { key: 2, label: 'Cotización y negociación', value: 'quotation' },
  { key: 3, label: 'Cierre', value: 'closed' }
];

export const closeStatuses = [
  { label: 'Cierre', value: ['success', 'failure'] },
  { label: 'Propuesta creada', value: 'proposal_generated' },
  { label: 'Póliza creada', value: 'contract_issued' }
];

export const defaultCloseStatus = ['success', 'proposal_generated', 'contract_issued'];

export const firstStep = {
  stepName: 'oportunity',
  filtersOptions: [{ label: 'Oportunidades', value: 'active' }],
  advancedOptions: []
};

export const secondStep = {
  stepName: 'quotationStatus',
  filtersOptions: [
    { label: 'Con cotización', value: 'with_quotation' },
    { label: 'Cotización pendiente', value: 'without_quotation' },
    { label: 'Todas', value: '' }
  ],
  advancedOptions: []
};

export const thirdStep = {
  stepName: 'closeStatus',
  filtersOptions: [
    { label: 'exitoso', value: ['success', 'proposal_generated', 'contract_issued'] },
    { label: 'no exitoso', value: 'failure' },
    { label: 'Todas', value: '' }
  ],
  advancedOptions: []
};

export const leadParams = {
  1: {
    isVip: '',
    legalPersonType: '',
    legal: '',
    natural: '',
    insuranceCategoryId: '',
    subjectMatter: '',
    step: 'oportunity',
    oportunityQuotation: '',
    closeStatus: '',
    oportunity: 'active',
    stepNumber: 1
  },
  2: {
    isVip: '',
    legalPersonType: '',
    legal: '',
    natural: '',
    insuranceCategoryId: '',
    subjectMatter: '',
    step: 'quotation',
    oportunityQuotation: '',
    closeStatus: '',
    stepNumber: 2
  },
  3: {
    isVip: '',
    legalPersonType: '',
    legal: '',
    natural: '',
    insuranceCategoryId: '',
    subjectMatter: '',
    step: 'closed',
    oportunityQuotation: '',
    closeStatus: defaultCloseStatus,
    stepNumber: 3
  }
};
