import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';
import { calculateDays, handleDateFormat } from '../../../services/utils';

const validityObject = ({ insurancePolicy }) => {
  const { firstPaymentDate, translatedRenewalType, validityEnd, validityStart } = insurancePolicy;

  const object = {};

  object.validityStart = infoCardElement({
    string: true,
    key: 'Inicio',
    value: handleDateFormat(validityStart)
  });
  object.validityEnd = infoCardElement({
    string: true,
    key: 'Término',
    value: handleDateFormat(validityEnd)
  });
  object.totalDays = infoCardElement({
    string: true,
    key: 'Duración',
    value: `${calculateDays(validityStart, validityEnd)} días`
  });
  if (firstPaymentDate)
    object.firstPaymentDate = infoCardElement({
      string: true,
      key: 'Fecha de primer pago',
      value: handleDateFormat(firstPaymentDate)
    });
  object.renewalType = infoCardElement({
    string: true,
    key: 'Tipo de renovación',
    value: translatedRenewalType
  });

  return object;
};

export default validityObject;
