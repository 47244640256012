import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';

import CompanyLandingFirstSection from './CompanyLandingFirstSection';
import CompanyLandingSecondSection from './CompanyLandingSecondSection';
import CompanyLandingThirdSection from './CompanyLandingThirdSection';

const SectionInfo = ({ title, description }) =>
  (title || description) && (
    <div className="mb-4">
      {title && <p className="row-title mb-2">{title}</p>}
      {description && <p className="mb-2">{description}</p>}
    </div>
  );

const CompanyLandingForm = ({ isSubmitting, company }) => {
  const modelName = 'companyLanding';

  const { landingPrimaryColor, landingSecondaryColor } = company;

  return (
    <div className="landing-form landing-form-company" style={{ '--inline-secondary-color': landingSecondaryColor }}>
      <p className="landing-form-company__title">
        Ingresa la información necesaria para cotizar el seguro que necesitas y nos pondremos a trabajar inmediatamente{' '}
        {String.fromCodePoint('0x26A1')}
      </p>
      <div className="landing-form-company__container" style={{ '--inline-primary-color': landingPrimaryColor }}>
        <Form className="m-0">
          <CompanyLandingFirstSection modelName={modelName} sectionInfoComponent={SectionInfo} />
          <CompanyLandingSecondSection modelName={modelName} sectionInfoComponent={SectionInfo} />
          <CompanyLandingThirdSection modelName={modelName} sectionInfoComponent={SectionInfo} company={company} />

          <Row className="d-flex justify-content-end align-items-center">
            <Col sm={4}>
              <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const setInitialValues = ({ companyLanding }) => ({ companyLanding });

const validationSchema = Yup.object().shape({
  companyLanding: Yup.object().shape({
    commune: Yup.string().required('Debes ingresar una comuna'),
    contract: Yup.string().nullable(),
    email: Yup.string()
      .required('Debes ingresar un correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    insurancePolicyAmount: Yup.string().nullable(),
    insurancePolicyDeductible: Yup.string().nullable(),
    name: Yup.string().required('Debes ingresar un nombre y apellido'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un RUT')
      .rut('El RUT es inválido'),
    validityEnd: Yup.string().nullable(),
    vehicleLicensePlate: Yup.string()
      .required('Debes ingresar una patente')
      .licensePlate('Formato de patente inválido')
      .max(6, 'Máx. 6 caracteres'),
    vehicleUse: Yup.string().required('Debes seleccionar un uso')
  })
});

const handleSubmit = (values, { props, setSubmitting, resetForm }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting, resetForm);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CompanyLandingForm);
