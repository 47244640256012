import AwesomeDebouncePromise from 'awesome-debounce-promise';

import ApiService from '../../services/apiService';

export const indexInsuredInsurancePolicyRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insured/insurance_policies', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showInsuredInsurancePolicyRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insured/insurance_policies/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createInsuredInsurancePolicyRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/insured/insurance_policies', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateInsuredInsurancePolicyRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insured/insurance_policies/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    showLeafArrayIndexes: true,
    source
  });

export const debounceIndexInsuredInsurancePoliciesRequest = AwesomeDebouncePromise(
  indexInsuredInsurancePolicyRequest,
  1000
);
