import React from 'react';
import AdminSlideDataTable from './AdminSlideDataTable';
import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminSlidesRequest } from '../../../requests/admin/slides';

const AdminSlideIndex = () => {
  const { data: slides, totalData: totalSlides, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminSlidesRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar las slides. Por favor inténtalo nuevamente',
    // customParams: {
    //   sort_column: 'position',
    //   sort_direction: 'asc'
    // },
    withDataTable: true
  });

  const modelUrl = '/admin/slides';

  return (
    <DefaultHeader
      title="Slides"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Slide',
        path: `${modelUrl}/new`
      }}
    >
      <AdminSlideDataTable
        tableData={slides}
        tableDataAmount={totalSlides}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        modelUrl={modelUrl}
      />
    </DefaultHeader>
  );
};

export default AdminSlideIndex;
