import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';
import { openFile } from '../../../services/utils';

const summaryCoverageObject = ({ insurancePolicy, isProposal }) => {
  const { contractNumber, contractFile, quotation, acceptance, contract, contractProposal } = insurancePolicy;

  const fileLoaded = value => typeof value === 'object' && value;
  const createContractFromProposal = isProposal && contractNumber && contractFile;

  const object = {};

  if (isProposal) {
    object.selectedLeadQuotation = infoCardElement({
      file: true,
      item: {
        title: 'Cotización seleccionada',
        name: quotation?.name || insurancePolicy.quotationInfo?.filename,
        onClick: () => openFile(fileLoaded(quotation) || insurancePolicy.quotationInfo)
      }
    });

    object.selectedLeadAcceptance = infoCardElement({
      file: true,
      item: {
        title: 'Aprobación del cliente',
        name: acceptance?.name || insurancePolicy.acceptanceInfo?.filename,
        onClick: () => openFile(fileLoaded(acceptance) || insurancePolicy.acceptanceInfo)
      }
    });

    if (createContractFromProposal) {
      object.contractFile = infoCardElement({
        file: true,
        item: {
          title: 'Póliza',
          name: contractFile?.name || insurancePolicy.contractFileInfo?.filename,
          onClick: () => openFile(fileLoaded(contractFile) || insurancePolicy.contractFileInfo)
        }
      });
    }
  } else {
    object.contract = infoCardElement({
      file: true,
      item: {
        title: 'Póliza',
        name: contract?.name || insurancePolicy.contractInfo?.filename,
        onClick: () => openFile(fileLoaded(contract) || insurancePolicy.contractInfo)
      }
    });

    object.proposal = infoCardElement({
      file: true,
      item: {
        title: 'Propuesta',
        name: contractProposal?.name || insurancePolicy.contractProposalInfo?.filename,
        onClick: () => openFile(fileLoaded(contractProposal) || insurancePolicy.contractProposalInfo)
      }
    });
  }

  return object;
};

export default summaryCoverageObject;
