import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminSlideRequest = ({ dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', '/admin/slides', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showAdminSlideRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.request('get', `/admin/slides/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createAdminSlideRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('post', '/admin/slides', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminSlideRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/admin/slides/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminSlideRequest = (id, { dispatch, failureCallback, successCallback }) =>
  ApiService.request('delete', `/admin/slides/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminSlidesRequest = AwesomeDebouncePromise(indexAdminSlideRequest, 300);
