import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useFetchData } from '../../hooks';
import { formatCurrency } from '../../services/utils';
import { IconNumberCard } from '..';

const CommissionCollectionStatisticHeader = ({ customParamsRef, headerDashboardRequest, dataTableMoreData }) => {
  const { data: headerDashboardKeys, setMoreData } = useFetchData({
    debouncedIndexRequest: headerDashboardRequest,
    customParams: { display_length: 1000, ...customParamsRef.current },
    withoutWrapper: true
  });

  const {
    calcularedCommission,
    reportedCommission,
    calcularedCompanyCommission,
    reportedCompanyCommission,
    accounts,
    contracts,
    endorsements,
    nicoCodes
  } = headerDashboardKeys;

  useEffect(() => setMoreData(m => !m), [dataTableMoreData]);

  return (
    <>
      <Row className="mt-4">
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard
            icon="dollar"
            variant="success"
            title="Comisión calculada total neta"
            value={formatCurrency({ leftAddon: 'CLP$', value: calcularedCommission, decimals: 0 })}
          />
        </Col>

        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard
            icon="dollar"
            variant="success"
            title="Comisión informada total neta"
            value={formatCurrency({ leftAddon: 'CLP$', value: reportedCommission, decimals: 0 })}
          />
        </Col>

        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard
            icon="dollar"
            variant="success"
            title="Comisión empresa calculada neta"
            value={formatCurrency({ leftAddon: 'CLP$', value: calcularedCompanyCommission, decimals: 0 })}
          />
        </Col>

        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard
            icon="dollar"
            variant="success"
            title="Comisión empresa informada neta"
            value={formatCurrency({ leftAddon: 'CLP$', value: reportedCompanyCommission, decimals: 0 })}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard icon="align-bottom" variant="primary-light" title="Cuentas únicas" value={accounts} />
        </Col>
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard icon="align-bottom" variant="primary-light" title="Pólizas únicas" value={contracts} />
        </Col>
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard icon="align-bottom" variant="primary-light" title="Endosos únicos" value={endorsements} />
        </Col>
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard
            icon="align-bottom"
            variant="primary-light"
            title="Registros con código NICO"
            value={nicoCodes}
          />
        </Col>
      </Row>
    </>
  );
};

export default CommissionCollectionStatisticHeader;
