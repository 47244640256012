import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput } from '../../../components';
import AdminInsuranceCompanyExecutiveSection from './AdminInsuranceCompanyExecutiveSection';
import { BasicTextAreaWithEditor } from '../../../components/Utils/Input';

const AdminInsuranceGeneralTypeSection = () => {
  const { errors, touched, setFieldValue } = useFormikContext();

  return (
    <section className="mt-5">
      <p className="section-title">Seguros Generales</p>
      <Row className="my-3">
        <Col md={6}>
          <Row>
            <Col md={12}>
              <Field name="insuranceCompany[websiteLink]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Sitio web"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="insuranceCompany[premiumPaymentLink]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Pago de primas"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="insuranceCompany[sinisterReportLink]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Denuncia de siniestros"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Field name="insuranceCompany[paymentTransferData]">
            {({ field }) => (
              <div id={field.name}>
                <BasicTextAreaWithEditor
                  {...field}
                  value={field.value}
                  label="Datos de transferencia para pago"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  onChange={value => setFieldValue(field.name, value)}
                  className="insurance-company-demo-editor"
                />
              </div>
            )}
          </Field>
        </Col>
      </Row>

      <Row className="my-3">
        <Col md={4} sm={12}>
          <AdminInsuranceCompanyExecutiveSection title="Ejecutivo de atención (Generales)" prefix="executive" />
        </Col>
        <Col md={4} sm={12}>
          <AdminInsuranceCompanyExecutiveSection
            title="Ejecutivo de cobranza (Generales)"
            prefix="collectionExecutive"
          />
        </Col>
        <Col md={4} sm={12}>
          <AdminInsuranceCompanyExecutiveSection
            title="Ejecutivo de siniestros (Generales)"
            prefix="sinisterExecutive"
          />
        </Col>
      </Row>
    </section>
  );
};

export default AdminInsuranceGeneralTypeSection;
