import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../../components';
import { requestSignIn } from '../../../actions/auth';
import { updateAbility } from '../../../config/ability';
import { AbilityContext } from '../../../config/abilityContext';

const Login = ({ onHide, isValid, errors, touched, signedIn }) => {
  const ability = useAbility(AbilityContext);

  const handleOnLogin = () => {
    if (!signedIn) return;
    updateAbility(ability);
  };

  useEffect(handleOnLogin, [signedIn]);

  return (
    <div className="login-box--container">
      <div className="login-box">
        <h6 className="login-box--title text-color-white text-center">Ingresa a tu cuenta</h6>
        <Form className="login-box--form">
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                noLabelFocus
                label="Correo electrónico"
                placeholder="usuario@correo.com"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                noLabelFocus
                inputType="password"
                label="Contraseña"
                placeholder="**********"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant="secondary"
            size="lg"
            className="my-5 w-100 btn-big"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Inicia sesión
          </Button>
        </Form>
        <Link className="login-box--forgot-password-link" to="/recover_password">
          Olvidé mi contraseña
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Debes ingresar un email'),
  password: Yup.string().required('Debes ingresar una contraseña')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    requestSignIn(
      {
        user: {
          email: values.email,
          password: values.password
        }
      },
      props.fromLocation
    )
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
