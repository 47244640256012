import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { FormHeader } from '../../components';
import { updateMeRequest, showMeRequest } from '../../requests/user';
import ProfileForm from './ProfileForm';
import basicUser from '../User/basicUser';

const ProfileEdit = () => {
  const [user, setUser] = useState(basicUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { modelUrl = '/' } = location.state || {};

  const handleBreadcrumb = [
    { key: 1, name: 'Inicio', href: modelUrl },
    { key: 2, name: 'Mi perfil' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Usuario actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const { avatar } = values.user;
    const sendParams = snakeCaseKeys(values);
    sendParams.user.avatar = avatar;
    updateMeRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchMe = () => {
    showMeRequest({
      dispatch,
      successCallback: response => setUser(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchMe, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<ProfileForm action="edit" user={user} formRequest={handleUpdateRequest} submitVariant="submit" />}
    />
  );
};

export default ProfileEdit;
