import React from 'react';

import { LeadRenewalIndexBody } from '../../../components/LeadRenewal';

const LeadRenewalIndex = () => {
  const renewableUrl = '/renewals';
  const extendableUrl = '/insurance_policies';

  return <LeadRenewalIndexBody renewableUrl={renewableUrl} extendableUrl={extendableUrl} />;
};

export default LeadRenewalIndex;
