import React from 'react';

import { InfoCard } from '../..';
import generalObject from './generalObject';

const InfoCardGeneral = ({ lead, isForm, userModule }) => {
  const generalInfo = generalObject({ lead, isForm, userModule });

  return <InfoCard title="Información de las partes" object={generalInfo} />;
};

export default InfoCardGeneral;
