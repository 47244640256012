import React from 'react';

import MainLogo from '../Utils/MainLogo';

const LoginCatchphrase = ({
  arrayText = ['Ahorra tiempo,', 'mejora tu operación', 'y enfócate en tus clientes'],
  textColor = 'text-color-primary-light-blue'
}) => (
  <div className="login-catchphrase--container">
    <div className="login-catchphrase">
      <MainLogo />
      {arrayText.map((text, index) => (
        <h4 key={`catchphrase-${index.toString()}`} className={`login-catchphrase--text ${textColor}`}>
          {text}
        </h4>
      ))}
    </div>
  </div>
);
export default LoginCatchphrase;
