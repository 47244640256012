import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';

import { IconBtn } from '../Utils/Button';
import { AdvancedFilters, StepTabsFilter, TabFilters } from '../Utils/Filters';
import SearchDateFilter from './SearchDateFilter';

const AdvancedFilterStep = ({ customParams, dateFromField, dateToField, setCurrenStep, setCustomParams, ...props }) => {
  const {
    advancedOptionsBtn = true,
    options,
    paramName,
    remoteSelectors,
    simpleSelectors,
    steps,
    searchOptions,
    dateFilterTooltipText
  } = props;
  const [withAdvanced, setWithAdvanced] = useState(false);

  return (
    <Row className="index-filters d-flex justify-content-lg-between step-button-order">
      <Col lg={7}>
        <StepTabsFilter stepsOptions={steps} setCurrentStep={setCurrenStep} customParams={customParams} />
      </Col>
      <Col md={7} lg={5}>
        <SearchDateFilter
          containerClass="ml-lg-5"
          customParams={customParams}
          setCustomParams={setCustomParams}
          dateFromField={dateFromField}
          dateToField={dateToField}
          dateFilterTooltipText={dateFilterTooltipText}
        />
      </Col>
      <Col md={9} lg={10}>
        <TabFilters
          paramName={paramName}
          optionsArray={getIn(options, 'filtersOptions')}
          customParams={customParams}
          setCustomParams={setCustomParams}
        />
      </Col>
      {advancedOptionsBtn && (
        <Col md={3} lg={2} className="pt-4 d-flex justify-content-lg-end">
          <IconBtn
            icon="search"
            iconClass="ml-2"
            variant="secondary-light"
            className={`no-shadow ${withAdvanced ? '' : 'outline'} rounded-border mobile-w-100`}
            onClick={() => setWithAdvanced(!withAdvanced)}
          >
            <span className="mr-1">Avanzada</span>
          </IconBtn>
        </Col>
      )}
      <Col xs={12} className={`index-filters--secondary ${withAdvanced ? 'show' : ''}`}>
        <AdvancedFilters
          filtersOptions={getIn(options, 'advancedOptions')}
          remoteSelectors={remoteSelectors}
          simpleSelectors={simpleSelectors}
          customParams={customParams}
          setCustomParams={setCustomParams}
          searchOptions={searchOptions}
        />
      </Col>
    </Row>
  );
};

export default AdvancedFilterStep;
