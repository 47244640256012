import React from 'react';

const PremiumCollectionInfo = () => (
  <>
    <p>
      Este módulo se construye con las principales acciones de cobranza de primas. En la tabla, podrás ver en cada fila
      los siguientes registros:
    </p>

    <ul className="mt-4">
      <li className="ml-4 mt-3">
        <span className="font-weight-bold">Deudas de pólizas con cadencia única.</span> Específicamente, si una póliza
        cuya prima se calcula y cobra de forma única (el pago se puede acordar en cuotas) se reporta por la compañía de
        seguros por primas impagas. El registro se crea para hacer seguimiento y lograr el pago de la prima por parte
        del contratante. De esta manera, la póliza se mantiene vigente y no se pierde la cobertura.
      </li>
      <li className="ml-4 mt-3">
        <span className="font-weight-bold">Cobranza de pólizas con cadencia mensual.</span> Específicamente, en el caso
        de pólizas cuya prima se calcula y cobra todos los meses durante la vigencia de la póliza. El registro se crea
        los días 1 de cada mes de cobertura. Es decir, para cada póliza con cadencia mensual, se crea un registro por
        cada mes calendario incluído en el período de vigencia de dicha póliza.
      </li>
    </ul>
  </>
);

export default PremiumCollectionInfo;
