import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { InputRemoteSelect } from '../../Utils/Select';
import { useFetchForRemoteSelect } from '../../../hooks';
import {
  debounceIndexInsuranceCategoryRequest,
  indexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';

const InsuranceCategoryRemoteSelector = ({ field, modelName, disabled = false }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const {
    options: insuranceCategories,
    selectedOption: selectedInsuranceCategory,
    fetchOptions: fetchInsuranceCategories
  } = useFetchForRemoteSelect({
    indexRequest: indexInsuranceCategoryRequest,
    debouncedIndexRequest: debounceIndexInsuranceCategoryRequest,
    value: getIn(values, `${modelName}[insuranceCategoryId]`),
    initialParams: {
      category_type: getIn(values, `${modelName}[insuranceCategoryCategory]` || ''),
      for_selector: false
    },
    nestedDependency: getIn(values, `${modelName}[insuranceCategoryCategory]` || '')
  });

  useEffect(() => {
    const dictionaryDetailsValue = getIn(values, `${modelName}[dictionaryDetails]`);
    const dictionaryTypeValue = getIn(values, `${modelName}[dictionaryType]`);

    if (!dictionaryTypeValue && selectedInsuranceCategory) {
      setFieldValue(`${modelName}[dictionaryType]`, selectedInsuranceCategory.dictionary_type || null);
    }

    if (!dictionaryDetailsValue && dictionaryTypeValue && selectedInsuranceCategory) {
      setFieldValue(
        `${modelName}[dictionaryDetails]`,
        camelCaseRecursive(selectedInsuranceCategory.dictionary_details) || []
      );
    }
  }, [selectedInsuranceCategory, setFieldValue, modelName, values]);

  return (
    <InputRemoteSelect
      abbr
      {...field}
      disabled={disabled}
      isClearable
      label="Ramo"
      placeholder="Ramo"
      defaultOptions={insuranceCategories}
      value={selectedInsuranceCategory}
      request={fetchInsuranceCategories}
      onChange={data => {
        setFieldValue(`${modelName}[insuranceCategoryName]`, data?.label || '');
        setFieldValue(field.name, data?.value || '');
        setFieldValue(`${modelName}[dictionaryDetails]`, camelCaseRecursive(data?.dictionary_details) || []);
        setFieldValue(`${modelName}[dictionaryType]`, data?.dictionary_type || null);
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default InsuranceCategoryRemoteSelector;
