import ApiService from '../../services/apiService';

export default function approveQuotationRequest(
  leadId,
  id,
  { dispatch, params = {}, formData, successCallback, failureCallback, callback }
) {
  ApiService.request('put', `/insured/leads/${leadId}/lead_quotations/${id}/approve_quotation`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback
  });
}
