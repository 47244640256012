import React, { useContext } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { ChevronDown, ChevronUp } from 'react-feather';

const AccordionCard = ({ body, title, length }) => {
  return (
    <Accordion defaultActiveKey="0">
      <Card className="accordion-card">
        <Card.Header>
          <p className="section-title capitalize-text">
            {title}
            {length && <span className="small ml-2">( {length} )</span>}
          </p>
          <ContextAwareToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>{body}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

const ContextAwareToggle = ({ callback, eventKey }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button variant="link" className="btn-toggle-tree pl-5" onClick={decoratedOnClick}>
      {isCurrentEventKey ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
    </Button>
  );
};

export default AccordionCard;
