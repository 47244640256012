import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';

import { BasicTextArea, FormikDatePicker, FormikInput, FormikNumberFormat, FormikSwitch } from '../../../../components';
import { rutFormat, validRutInput } from '../../../../services/utils';

const BeneficiaryFields = ({ setFieldValue, errors, touched }) => {
  const modelName = 'beneficiary';

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <Field name={`${modelName}[nationalIdentification]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="00.000.000-0"
                label="RUT"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Nombre completo"
                label="Nombre y apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[birthday]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de nacimiento"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Field name={`${modelName}[role]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Rol"
                placeholder="Rol"
                touched={getIn(touched, field.name)}
                error={getIn(errors, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[incorporationDate]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de incorporación"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={4}>
          <Field name={`${modelName}[companySentDateIncorporation]`}>
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de envío a compañía - incorporación"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Field name={`${modelName}[dependentsNumber]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr
                label="Número de cargas"
                onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                autoComplete="off"
              />
            )}
          </Field>
        </Col>
      </Row>

      <p className="section-subtitle">Coberturas</p>
      <Row className="mb-5">
        <Col xs={6} md={3}>
          <Field name={`${modelName}[lifeCoverage]`}>
            {({ field }) => <FormikSwitch {...field} field={field} label="Cobertura vida" />}
          </Field>
        </Col>
        <Col xs={6} md={3}>
          <Field name={`${modelName}[healthCoverage]`}>
            {({ field }) => <FormikSwitch {...field} field={field} label="Cobertura salud" />}
          </Field>
        </Col>
        <Col xs={6} md={3}>
          <Field name={`${modelName}[dentalCoverage]`}>
            {({ field }) => <FormikSwitch {...field} field={field} label="Cobertura dental" />}
          </Field>
        </Col>
        <Col xs={6} md={3}>
          <Field name={`${modelName}[catastrophicCoverage]`}>
            {({ field }) => <FormikSwitch {...field} field={field} label="Cobertura catastrófico" />}
          </Field>
        </Col>
      </Row>

      <Row>
        <Col>
          <Field name={`${modelName}[comments]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Comentarios"
                placeholder="Ingresa tu comentario..."
                row={2}
                className="p-3"
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default BeneficiaryFields;
