import React, { useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { IconBtn } from '../Button';

const DefaultModal = ({
  body,
  handleClose,
  handleConfirm,
  show,
  title,
  titleBtnClose,
  titleBtnSave,
  variantBtnClose,
  variantBtnSave,
  withButtons,
  ...props
}) => (
  <Modal {...props} show={show} onHide={handleClose}>
    {title && (
      <Modal.Header>
        <Modal.Title className="capitalize-text">
          <h6>{title}</h6>
        </Modal.Title>
        <IconBtn icon="close-o" variant="transparent" className="black" iconSize="sm" onClick={handleClose} />
      </Modal.Header>
    )}
    <Modal.Body>{body}</Modal.Body>
    {withButtons && (
      <Modal.Footer className="mt-4">
        <Button variant={variantBtnClose} className="no-shadow" onClick={handleClose}>
          {titleBtnClose}
        </Button>
        <Button type="submit" variant={variantBtnSave} className="no-shadow ml-4" onClick={handleConfirm}>
          {titleBtnSave}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

DefaultModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleBtnClose: PropTypes.string,
  titleBtnSave: PropTypes.string,
  variantBtnClose: PropTypes.string,
  variantBtnSave: PropTypes.string
};

DefaultModal.defaultProps = {
  titleBtnClose: 'Cancelar',
  titleBtnSave: 'Confirmar',
  variantBtnClose: 'cancel',
  variantBtnSave: 'submit'
};

const CenteredModal = ({ header, title, body, titlebtn, size, onClickHide, ...props }) => (
  <Modal {...props} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
    {header && (
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body>
      <h4>{title}</h4>
      {body}
    </Modal.Body>
    {titlebtn && (
      <Modal.Footer>
        <Button onClick={onClickHide}>{titlebtn}</Button>
      </Modal.Footer>
    )}
  </Modal>
);

CenteredModal.propTypes = {
  header: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titlebtn: PropTypes.string,
  size: PropTypes.string
};

CenteredModal.defaultProps = {
  header: null,
  size: 'lg',
  body: '',
  titlebtn: ''
};

const ModalCenter = props => {
  const { header, title, body, titlebtn, size, titlebtnmodal } = props;
  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => setModalShow(false);

  return (
    <ButtonToolbar>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        {titlebtnmodal}
      </Button>

      <CenteredModal
        show={modalShow}
        onHide={modalClose}
        header={header}
        title={title}
        body={body}
        titlebtn={titlebtn}
        size={size}
        titlebtnmodal={titlebtnmodal}
      />
    </ButtonToolbar>
  );
};

const objectOrFunction = (component, props) => {
  if (typeof component === 'function') {
    return component(props);
  }
  return component;
};

const SimpleCenteredModal = ({
  body,
  centered,
  closeButton,
  dialogClassName,
  onHide,
  size,
  subtitle,
  title,
  ...props
}) => (
  <Modal {...props} dialogClassName={size ? '' : dialogClassName} size={size} centered={centered} onHide={onHide}>
    {closeButton && (
      <IconBtn
        icon="close-o"
        variant="transparent"
        className="black absolute-top-right m-4 z-1"
        iconSize="sm"
        onClick={onHide}
      />
    )}
    {(title || subtitle) && (
      <Modal.Header className="d-flex align-items-center justify-content-between">
        <Modal.Title className="capitalize-text">
          <h6>{title}</h6>
          {subtitle && <h6 className="mb-0">{subtitle}</h6>}
        </Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body className="modal-body-padding">{objectOrFunction(body)} </Modal.Body>
  </Modal>
);

SimpleCenteredModal.propTypes = {
  size: PropTypes.string,
  closeButton: PropTypes.bool,
  centered: PropTypes.bool
};

SimpleCenteredModal.defaultProps = {
  size: 'sm',
  closeButton: true,
  centered: true
};

export default SimpleCenteredModal;

export { DefaultModal, ModalCenter, CenteredModal, SimpleCenteredModal };
