import React, { useEffect, useState } from 'react';
import PendingSummaryModalShow from './PendingSummaryModalShow';
import { ButtonTooltip } from '../Utils/Tooltips';
import { SimpleNumericCard } from '../Utils/Cards';
import { SimpleCenteredModal } from '../Utils/Modal';
import { useFetchData } from '../../hooks';
import { debouncePendingsSummaryRequest, debounceUnfinishTasksCountSummaryRequest } from '../../requests/user';

const modalTitles = {
  today: 'Pendientes del día',
  next3: 'Pendientes en los próximos 3 días',
  next7: 'Pendientes en los próximos 7 días',
  next30: 'Pendientes en los próximos 30 días'
};

const PendingSummary = ({ fromAdmin = false, moreData }) => {
  const [modalBody, setModalBody] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const fromModule = fromAdmin ? 'admin' : 'broker';

  const { data: summary } = useFetchData({
    debouncedIndexRequest: debouncePendingsSummaryRequest,
    withoutWrapper: true
  });

  const { data: taskCountSummary, setMoreData } = useFetchData({
    debouncedIndexRequest: debounceUnfinishTasksCountSummaryRequest,
    customParams: { fromModule },
    withoutWrapper: true
  });

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const handleOpenModal = timeHorizon => {
    setModalShow(true);
    setModalTitle(modalTitles[timeHorizon]);
    setModalBody(
      <PendingSummaryModalShow
        summary={summary}
        taskCount={taskCountSummary[timeHorizon]}
        selectedTimeHorizon={timeHorizon}
      />
    );
  };

  const calculateTotalPending = timeHorizon => {
    const { sinisterCount, debtCount, nearInvalidity = {}, availableQuotationCount } = summary;
    const staticPendingsCount = sinisterCount + debtCount + availableQuotationCount;
    return staticPendingsCount + taskCountSummary[timeHorizon] + nearInvalidity[timeHorizon] || '';
  };

  const tooltipText =
    'Aquí encontrarás todos los pendientes<br/>que se generan automáticamente por<br/>el ciclo de vida de tus cuentas y pólizas,<br/> alertas y las tareas que tú has creado';

  const handleUpdateTaskCountSummary = () => {
    setMoreData(m => !m);
  };

  useEffect(handleUpdateTaskCountSummary, [moreData]);

  return (
    <>
      <div className="d-flex align-items-center">
        <p className="section-title d-flex align-items-center mr-3 mb-0">Mis pendientes</p>
        <ButtonTooltip tooltipText={tooltipText} direction="right">
          ?
        </ButtonTooltip>
      </div>
      <div className="pending-summary">
        <SimpleNumericCard
          text="Hoy"
          value={calculateTotalPending('today')}
          clickable
          onClick={() => handleOpenModal('today')}
        />
        <SimpleNumericCard
          text="Próximos 3 días"
          value={calculateTotalPending('next3')}
          variant="primary-var-blue"
          clickable
          onClick={() => handleOpenModal('next3')}
        />
        <SimpleNumericCard
          text="Próximos 7 días"
          value={calculateTotalPending('next7')}
          variant="primary-var-blue"
          clickable
          onClick={() => handleOpenModal('next7')}
        />
        <SimpleNumericCard
          text="Próximos 30 días"
          value={calculateTotalPending('next30')}
          variant="primary-var-blue"
          clickable
          onClick={() => handleOpenModal('next30')}
        />
      </div>
      <SimpleCenteredModal title={modalTitle} show={modalShow} onHide={handleCloseModal} body={modalBody} size="sm" />
    </>
  );
};

export default PendingSummary;
