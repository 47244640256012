import React from 'react';
import PropTypes from 'prop-types';
import { BtnTooltip } from '../Utils/Button';

const BasicActionBtns = ({
  clickHandler,
  destroyText,
  editClick,
  inactivateOptions,
  item,
  path,
  resendText,
  showClick,
  transparentStyle,
  withDestroy,
  withEdit,
  withInactivateUser,
  withLandingPath,
  withResend,
  withShow
}) => (
  <div className="datatable-basic-buttons">
    {withLandingPath && (
      <BtnTooltip
        onClick={() => clickHandler(item, 'landingPath')}
        variant="primary-light-blue"
        icon="browse"
        iconSize="sm"
        tooltipText="Landing URL"
        className="no-shadow"
      />
    )}
    {withResend && (
      <BtnTooltip
        onClick={() => clickHandler(item, 'resend')}
        variant="primary-light"
        icon="redo"
        iconSize="sm"
        tooltipText={resendText}
        className="no-shadow"
      />
    )}
    {showClick && (
      <BtnTooltip
        onClick={() => clickHandler(item, 'show')}
        icon="eye"
        iconSize="sm"
        tooltipText="Ver"
        className="no-shadow"
      />
    )}
    {withShow && (
      <BtnTooltip
        to={`${path}/${item.id}`}
        variant={transparentStyle ? 'transparent' : 'primary'}
        icon="eye"
        iconSize="sm"
        tooltipText="Ver"
        className={`no-shadow ${transparentStyle ? 'primary' : ''}`}
      />
    )}
    {editClick && (
      <BtnTooltip
        onClick={() => clickHandler(item, 'edit')}
        variant="warning"
        icon="pen"
        iconSize="sm"
        tooltipText="Editar"
        className="edit-btn no-shadow"
      />
    )}
    {withEdit && (
      <BtnTooltip
        to={`${path}/${item.id}/edit`}
        variant={transparentStyle ? 'transparent' : 'warning'}
        icon="pen"
        iconSize="sm"
        tooltipText="Editar"
        className={`edit-btn no-shadow ${transparentStyle ? 'warning' : ''}`}
      />
    )}
    {withDestroy && (
      <BtnTooltip
        onClick={() => clickHandler(item, 'destroy')}
        variant={transparentStyle ? 'transparent' : 'danger'}
        icon="trash"
        iconSize="sm"
        tooltipText={destroyText}
        className={`no-shadow ${transparentStyle ? 'danger' : ''}`}
      />
    )}
    {withInactivateUser && (
      <BtnTooltip
        onClick={() => clickHandler(item, 'inactivate')}
        variant={inactivateOptions.variant}
        icon={inactivateOptions.icon}
        iconSize="sm"
        tooltipText={inactivateOptions.tooltipText}
        className="no-shadow"
      />
    )}
  </div>
);

BasicActionBtns.propTypes = {
  clickHandler: PropTypes.func,
  destroyText: PropTypes.string,
  editClick: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  path: PropTypes.string,
  resendText: PropTypes.string,
  showClick: PropTypes.bool,
  withDestroy: PropTypes.bool,
  withEdit: PropTypes.bool,
  withInactivateUser: PropTypes.bool,
  withResend: PropTypes.bool,
  withShow: PropTypes.bool
};

BasicActionBtns.defaultProps = {
  clickHandler: () => console.log('Missing clickHandler function'),
  destroyText: 'Eliminar',
  editClick: false,
  path: '',
  resendText: 'Reenviar',
  showClick: false,
  withDestroy: false,
  withEdit: false,
  withInactivateUser: false,
  withResend: false,
  withShow: false
};

export default BasicActionBtns;
