import React from 'react';
import { Row } from 'react-bootstrap';
import ClientLandingAccordionFaqsCompanies from './ClientLandingAccordionFaqsCompanies';

const ClientLandingFaqsCompanies = () => {
  return (
    <section id="faqs" className="partner-landing__faqs">
      <h4 className="broker-landing__horizontal-info__title landing-section-title">Preguntas frecuentes</h4>
      <Row className="col-md-8 col-sm-12">
        <ClientLandingAccordionFaqsCompanies />
      </Row>
    </section>
  );
};

export default ClientLandingFaqsCompanies;
