import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminUserRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/admin/users', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminUserRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/users/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminBrokerRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/admin/users/show_broker`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminCommissionsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', `/admin/users/show_commissions`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminUserRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/users', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminUserRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/users/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const resendWelcomeEmailAdminUserRequest = (
  id,
  { dispatch, successCallback, callback, failureCallback, source }
) =>
  ApiService.request('post', `/admin/users/${id}/resend_welcome_email`, {
    dispatch,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const updateActiveAttributeAdminUserRequest = (id, { dispatch, successCallback, callback, source }) =>
  ApiService.request('put', `/admin/users/${id}/update_active_attribute`, {
    dispatch,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminUsersRequest = AwesomeDebouncePromise(indexAdminUserRequest, 300);
