import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn, useFormikContext } from 'formik';

import { CustomBtn, InfoCard, SimpleCenteredModal, StatisticCard } from '../..';
import { openFile } from '../../../services/utils';
import insuranceEndorsementSummaryObject from './InsuranceEndorsementSummaryObject';

const AttachedFile = ({ attribute, file, modelName, title }) => {
  const { values } = useFormikContext();
  const savedFile = getIn(values, `${modelName}[${attribute}Info]`);

  const fileLoaded = value => typeof value === 'object' && value;

  return (
    <StatisticCard
      icon="notes"
      variant="secondary-light"
      title={title}
      footerButton={
        <CustomBtn
          disabled={!file}
          tooltipText={`${!file ? 'Sin documento asociado' : ''}`}
          onClick={() => openFile(fileLoaded(file) || savedFile)}
        >
          Ver
        </CustomBtn>
      }
    />
  );
};

const InsuranceEndorsementPolicySummary = ({ insurancePolicy }) => {
  const { values } = useFormikContext();
  const { insuranceEndorsement } = values;
  const { approval } = values.insuranceEndorsement;

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  return (
    <Row>
      <Col md={8}>
        <InfoCard
          title="Resumen nueva información póliza"
          object={insuranceEndorsementSummaryObject({
            insuranceEndorsement,
            insurancePolicy,
            setModalBody,
            setModalShow,
            setModalTitle
          })}
        />
      </Col>
      <Col md={4}>
        <AttachedFile
          title="Aprobación del cliente"
          attribute="approval"
          modelName="insuranceEndorsement"
          file={approval}
        />
      </Col>
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        show={modalShow}
        body={modalBody}
        onHide={() => setModalShow(false)}
      />
    </Row>
  );
};

export default InsuranceEndorsementPolicySummary;
