/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { DatatableMainInfo, DataTableTooltipHeaderName } from '../../components';

const columns = memoize(() => {
  return [
    {
      name: 'Nombre y apellido',
      selector: 'name',
      sortable: true,
      grow: '2',
      cell: ({ insuranceBrokerAttributes: { name, nationalIdentification } }) => (
        <DatatableMainInfo iconSize="lg" icon="user" title={name} subtitle={nationalIdentification} />
      )
    },
    {
      name: 'Tipo de corredor',
      selector: 'translated_broker_type',
      sortable: false,
      grow: '1',
      cell: ({ insuranceBrokerAttributes: { translatedBrokerType } }) => translatedBrokerType
    },
    {
      name: 'N° de cuentas',
      selector: 'account_with_clients_count',
      sortable: true,
      grow: '1',
      cell: ({ insuranceBrokerAttributes: { accountWithClientsCount } }) => accountWithClientsCount
    },
    {
      name: 'P. vigentes',
      selector: 'valid_insurance_policies_count',
      sortable: true,
      grow: '1',
      cell: ({ insuranceBrokerAttributes: { validInsurancePoliciesCount } }) => validInsurancePoliciesCount
    },
    {
      name: 'Prima total neta',
      selector: 'total_prime',
      sortable: true,
      grow: '1',
      cell: ({ parsedPoliciesValidTotalNetPremium }) => parsedPoliciesValidTotalNetPremium
    },
    {
      name: (
        <DataTableTooltipHeaderName
          html
          headerName="Comisión total neta"
          tooltipText="Esta es la comisión total que recibirás por esta cuenta.<br/>
                   Todos los descuentos ya fueron aplicados.”"
        />
      ),
      selector: 'total_prime',
      sortable: false,
      grow: '1',
      cell: ({ parsedUserTotalBrokerCommissionAmount }) => parsedUserTotalBrokerCommissionAmount
    }
  ];
});

export default columns;
