import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconBtn, IconGG, MainLogo, WebLayout, WhatsAppButton } from '../../components';
import { ContactForm } from '../../components/Landing/Shared';
import { sendContactFormRequest } from '../../requests/utils';
import { sendAlert } from '../../actions/utils';
import SuccessModal from '../../components/Landing/Shared/SuccessModal';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';

const BrokerDemoContact = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { type } = useGetRoleByRoute();

  const textAreaPlaceholder = `Hola!
Me gustaría agendar una demo para conocer más de NICO y ver como sería trabajar juntos.
De preferencia, me gustaría el día [ingresa aquí un día] a las [ingresa aquí un horario].
Gracias!`;

  const handleSubmit = values => {
    sendContactFormRequest({
      dispatch,
      params: {
        ...values,
        type: 'broker_demo_contact'
      },
      successCallback: result => {
        dispatch(sendAlert({ kind: 'success', message: result.data.message }));
        setShowSuccessModal(true);
      }
    });
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout type="broker">
      <div className={`landing-form landing-form-${type}`}>
        <IconBtn onClick={goBack} variant="transparent" className="login-navbar--back-btn white back-btn">
          <IconGG icon="chevron-left" />
          <span className="login-navbar--back-btn__text ">Volver</span>
        </IconBtn>
        {!showSuccessModal && (
          <div className={`form-container form-${type}`}>
            <MainLogo type="dark" />
            <h5 className="text-center font-weight-900 text-dark-blue">
              Agenda un demo de nuestro software para que veas cómo sería tu vida junto a NICO
            </h5>
            <ContactForm
              formRequest={handleSubmit}
              buttonText="Agendar demo"
              hasTextarea
              textAreaPlaceholder={textAreaPlaceholder}
            />
          </div>
        )}
        {showSuccessModal && <SuccessModal setShowSuccessModal={setShowSuccessModal} />}
        <WhatsAppButton />
      </div>
    </WebLayout>
  );
};

export default BrokerDemoContact;
