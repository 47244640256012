const infoCardElement = ({
  array = false,
  file = false,
  keyBold = false,
  link = false,
  string = false,
  subelement = false,
  ...props
}) => ({
  elementType: { array, file, keyBold, link, string, subelement },
  ...props
});

export default infoCardElement;
