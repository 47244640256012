import React from 'react';
import { DefaultHeader } from '../../../components';
import { debounceIndexAdminSettingRequest } from '../../../requests/admin/settings';
import AdminSettingTable from './AdminSettingTable';
import useFetchData from '../../../hooks/useFetchData';

const AdminClientLandingSetting = () => {
  const { data: settings, setMoreData, moreData, isFetching } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminSettingRequest,
    withoutWrapper: true
  });

  return (
    <DefaultHeader title="configuraciones landing corredor">
      <AdminSettingTable
        settings={settings}
        setMoreData={setMoreData}
        moreData={moreData}
        isFetching={isFetching}
        settingTypeFilter="broker_landing"
      />
    </DefaultHeader>
  );
};

export default AdminClientLandingSetting;
