import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { DefaultHeader } from '../../../components';
import { AbilityContext } from '../../../config/abilityContext';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminInsurancePoliciesRequest } from '../../../requests/admin/adminInsurancePolicies';
import { redirectTo } from '../../../services/utils';
import InsurancePolicyDataTable from '../../InsurancePolicy/InsurancePolicyDataTable';
import columns from './columns';

const AdminInsurancePolicyIndex = () => {
  const modelUrl = '/admin/insurance_policies';

  const ability = useAbility(AbilityContext);
  const customParamsRef = useRef({});
  const history = useHistory();

  const {
    data: insurancePolicies,
    totalData: totalInsurancePolicies,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminInsurancePoliciesRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las cuentas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  return (
    <DefaultHeader
      title="Pólizas"
      headerBtn={ability.can('manage', 'Admin')}
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Póliza',
        onClick: () => redirectTo(history, `${modelUrl}/new`, { policyType: 'contract' })
      }}
    >
      <InsurancePolicyDataTable
        fromAdmin
        tableData={insurancePolicies}
        tableDataAmount={totalInsurancePolicies}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
        columns={columns}
      />
    </DefaultHeader>
  );
};

export default AdminInsurancePolicyIndex;
