import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { IconBtn, IconGG, WhatsAppButton } from '../../components';
import { RequestRegisterForm } from '../../components/Landing/Shared';
import DarkNicoLogoSvg from '../../components/Utils/MainLogo/logos/dark-nico-logo.svg';
import { requestRegisterRequest } from '../../requests/utils';
import { redirectTo } from '../../services/utils';
import basicRegister from '../Landing/basicRegister';

const HomeContact = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = values => {
    requestRegisterRequest({
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => {
        localStorage.setItem('requestRegisterSuccess', true);
        redirectTo(history, '/');
      }
    });
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Row className="home-contact">
      <Col xs={12} className="home-contact__navbar">
        <IconBtn onClick={goBack} variant="transparent" className="home-contact__navbar__back-btn">
          <IconGG icon="chevron-left" />
          <span>Volver</span>
        </IconBtn>
      </Col>
      <Col lg={{ span: 6, offset: 3 }} className="home-contact__form">
        <Row>
          <Col md={12} className="home-contact__form__logo">
            <Image fluid src={DarkNicoLogoSvg} alt="home-contact-dark-nico-logo" />
          </Col>
          <Col md={12} className="home-contact__form__title">
            <h6>Datos para solicitar registro</h6>
          </Col>
          <Col md={12}>
            <RequestRegisterForm action="new" register={basicRegister} formRequest={handleCreateRequest} />
          </Col>
        </Row>
      </Col>
      <div className="background-circles">
        <span className="circle-top" />
        <span className="circle-left" />
        <span className="circle-bottom" />
        <span className="circle-petite-1" />
        <span className="circle-petite-2" />
        <span className="circle-petite-3" />
      </div>
      <WhatsAppButton />
    </Row>
  );
};

export default HomeContact;
