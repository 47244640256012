const initialFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  filterTableTabs: 'open',
  filter_collection_type: 'commission'
};

const createdFromOptions = [
  { label: 'Creación cadencia única', value: 'unique_cadence' },
  { label: 'Endoso cadencia única', value: 'endorsement' },
  { label: 'Cadencia mensual', value: 'monthly_cadence' }
];

const statusOptions = [
  { label: 'Pendiente cobro NICO', value: 'pending_collection_nico' },
  { label: 'Pendiente pago asociado', value: 'pending_payment_associated' },
  { label: 'Cerrada', value: 'closed' }
];

const tableTabOptions = [
  { label: 'Abiertas', value: 'open' },
  { label: 'Cerradas', value: 'closed' },
  { label: 'Todas', value: 'all' }
];

export { createdFromOptions, initialFilterParams, statusOptions, tableTabOptions };
