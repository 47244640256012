import React from 'react';
import PropTypes from 'prop-types';

const SimpleNumericCard = ({ variant, cardVariant, text, value, clickable, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={clickable ? () => onClick() : null}
      onKeyPress={clickable ? () => onClick() : null}
      className={`simple-numeric-card ${cardVariant || ''} position-relative ${clickable ? 'clickable' : ''}`}
    >
      <p className="section-title d-flex align-items-center justify-content-center">{text}</p>
      <div className={`simple-numeric-card--number-container ${variant}`}>
        <h4>{value || 0}</h4>
      </div>
    </div>
  );
};

SimpleNumericCard.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  cardVariant: PropTypes.string,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

SimpleNumericCard.defaultProps = {
  variant: '',
  value: '',
  cardVariant: '',
  clickable: false,
  onClick: () => null
};

export default SimpleNumericCard;
