import React from 'react';

import { InfoCardAccount, ShowAccountStatistics } from '../../components/Account';
import InfoCardContact from '../../components/Contact/InfoCard/InfoCardContact';
import { AutoSaveCommentsForm } from '../../components/Shared';
import { useFetchData } from '../../hooks';
import { debounceUpdateAdminAccountRequest } from '../../requests/admin/adminAccounts';
import { debounceIndexAdminInsurancePoliciesRequest } from '../../requests/admin/adminInsurancePolicies';
import { debounceIndexInsurancePoliciesRequest } from '../../requests/insurancePolicies';
import InsurancePolicyDataTable from '../InsurancePolicy/InsurancePolicyDataTable';
import Columns from './InsurancePolicy/Columns';
import { debounceIndexInsuredInsurancePoliciesRequest } from '../../requests/insured/insuredInsurancePolicies';

const AccountInfo = ({ account, fromAdmin, modelUrl, setMoreData, userModule }) => {
  const { id, adminComments } = account;

  const getInsurancePolicyIndexRequest = () => {
    const requestMap = {
      admin: debounceIndexAdminInsurancePoliciesRequest,
      broker: debounceIndexInsurancePoliciesRequest,
      insured: debounceIndexInsuredInsurancePoliciesRequest
    };

    return requestMap[userModule];
  };

  const {
    data: insurancePolicies,
    totalData: totalInsurancePolicies,
    moreData,
    setMoreData: setMorePolicies,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: getInsurancePolicyIndexRequest(),
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las pólizas. Por favor inténtalo nuevamente',
    withDataTable: true,
    customParams: { account_id: id }
  });

  const getInsurancePolicyModelUrl = () => {
    const requestMap = {
      admin: '/admin/insurance_policies',
      broker: '/insurance_policies',
      insured: '/insured/insurance_policies'
    };

    return requestMap[userModule];
  };

  return (
    <>
      <div className="account-show-layout">
        <div className="account-show--general-info">
          <InfoCardAccount canEditAccount account={account} modelUrl={modelUrl} userModule={userModule} />
          <InfoCardContact
            canEditContact
            fromAdmin={fromAdmin}
            account={account}
            modelUrl={modelUrl}
            setMoreData={setMoreData}
            userModule={userModule}
          />
        </div>
        <ShowAccountStatistics userModule={userModule} account={account} setMoreData={setMoreData} />
      </div>

      {fromAdmin && (
        <div className="mt-5">
          <AutoSaveCommentsForm
            withTitle
            title="Nota admin"
            resourceId={id.toString()}
            resourceComments={adminComments}
            resourcePath="account[admin_comments]"
            debouncedUpdateRequest={debounceUpdateAdminAccountRequest}
          />
        </div>
      )}

      <h5 className="mt-5">Pólizas</h5>
      <InsurancePolicyDataTable
        fromAdmin={fromAdmin}
        tableData={insurancePolicies}
        tableDataAmount={totalInsurancePolicies}
        modelUrl={getInsurancePolicyModelUrl()}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMorePolicies}
        columns={Columns}
        handleIndexRequest={handleIndexRequest}
        noSubHeader
      />
    </>
  );
};

export default AccountInfo;
