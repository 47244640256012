import React from 'react';

import BtnSwitch from './BtnSwitch';
import { ButtonTooltip } from '../Tooltips';

const BulletSwitchBtn = ({ attribute, customWidth = {}, initialValue, onClick, title, tooltipText }) => (
  <div className="bullet-switch-btn">
    <p className="bullet-switch-btn__title" style={customWidth}>
      {title}:
    </p>
    <BtnSwitch
      small
      id={`btn-switch-${1}`}
      initialValue={initialValue}
      onClickChecked={() => onClick({ attribute, value: true })}
      onClickUnchecked={() => onClick({ attribute, value: false })}
    />
    {tooltipText && (
      <ButtonTooltip html tooltipText={tooltipText}>
        ?
      </ButtonTooltip>
    )}
  </div>
);

export default BulletSwitchBtn;
