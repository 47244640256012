import React from 'react';
import { Row } from 'react-bootstrap';

const BrokerLandingPlansHeader = () => {
  return (
    <main id="mainpartner" className="broker-landing__horizontal-info__herobrokerplans ">
      <div className="broker-landing__horizontal-info__herobrokerplans__container">
        <div className="landing--main__info col-sm-12 col-md-7 ">
          <h1>
            En NICO compartimos la comisión. <br />
            Así, con los incentivos bien alineados, <br />
            trabajamos y avanzamos siempre juntos.
          </h1>
          <p>
            Desde el momento que te sumas, de todas las pólizas nuevas y las renovaciones, NICO cobra un porcentaje de
            las comisiones.
          </p>
        </div>
        <div className="col-sm-12 col-md-5 broker-landing__horizontal-info__herobrokerplans__card">
          <Row className=" align-items-center broker-landing__horizontal-info__herobrokerplans__card__container">
            <div className="col-2 pr-0">
              <div className="broker-landing__horizontal-info__herobrokerplans__card__container__iconcontainer">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.2077 8.25H16.791V23.75H14.2077V8.25Z" fill="#1F2E4F" />
                  <path d="M9.04102 17.2917H11.6243V23.75H9.04102V17.2917Z" fill="#1F2E4F" />
                  <path d="M19.3743 12.125H21.9577V23.75H19.3743V12.125Z" fill="#1F2E4F" />
                </svg>
              </div>
            </div>
            <div className="col-10 broker-landing__horizontal-info__herobrokerplans__card">
              <p>
                Adopta una estructura de costos variables, en vez de costos fijos (personal de apoyo, oficina, etc.).
              </p>
            </div>
          </Row>
          <Row className=" align-items-center broker-landing__horizontal-info__herobrokerplans__card__container">
            <div className="col-2 pr-0">
              <div className="broker-landing__horizontal-info__herobrokerplans__card__container__iconcontainer">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.82669 21.7263L0 19.8996L9.13346 10.7661L17.3536 18.9863L22.8337 13.5062L20.5831 11.2556L29.2271 8.93945L26.9109 17.5835L24.6603 15.3329L17.3536 22.6396L9.13346 14.4195L1.82669 21.7263Z"
                    fill="#1F2E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 broker-landing__horizontal-info__herobrokerplans__card">
              <p>Elimina todas las barreras de crecimiento, como capacidad, equipo, conocimiento técnico, etc.</p>
            </div>
          </Row>
          <Row className=" align-items-center broker-landing__horizontal-info__herobrokerplans__card__container">
            <div className="col-2 pr-0">
              <div className="broker-landing__horizontal-info__herobrokerplans__card__container__iconcontainer">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5017 21.9456C12.6531 21.9456 10.3439 19.6364 10.3439 16.7877H20.6596C20.6596 19.6364 18.3504 21.9456 15.5017 21.9456Z"
                    fill="#1F2E4F"
                  />
                  <path
                    d="M18.0807 12.9193C18.0807 13.6315 18.658 14.2088 19.3702 14.2088C20.0823 14.2088 20.6596 13.6315 20.6596 12.9193C20.6596 12.2071 20.0823 11.6298 19.3702 11.6298C18.658 11.6298 18.0807 12.2071 18.0807 12.9193Z"
                    fill="#1F2E4F"
                  />
                  <path
                    d="M11.6333 14.2088C10.9212 14.2088 10.3439 13.6315 10.3439 12.9193C10.3439 12.2071 10.9212 11.6298 11.6333 11.6298C12.3455 11.6298 12.9228 12.2071 12.9228 12.9193C12.9228 13.6315 12.3455 14.2088 11.6333 14.2088Z"
                    fill="#1F2E4F"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.60701 15.4983C2.60701 22.6198 8.38018 28.393 15.5017 28.393C22.6233 28.393 28.3965 22.6198 28.3965 15.4983C28.3965 8.37669 22.6233 2.60352 15.5017 2.60352C8.38018 2.60352 2.60701 8.37669 2.60701 15.4983ZM5.18596 15.4983C5.18596 21.1955 9.8045 25.814 15.5017 25.814C21.199 25.814 25.8175 21.1955 25.8175 15.4983C25.8175 9.801 21.199 5.18246 15.5017 5.18246C9.8045 5.18246 5.18596 9.801 5.18596 15.4983Z"
                    fill="#1F2E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 broker-landing__horizontal-info__herobrokerplans__card">
              <p>Olvídate de gestionar un equipo, sus sueldos, vacaciones, licencias, etc.</p>
            </div>
          </Row>
          <Row className=" align-items-center broker-landing__horizontal-info__herobrokerplans__card__container">
            <div className="col-2 pr-0">
              <div className="broker-landing__horizontal-info__herobrokerplans__card__container__iconcontainer">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.2083 24.5404V28.4154H16.7917V24.5404H18.0833C20.9368 24.5404 23.25 22.2272 23.25 19.3737C23.25 16.5202 20.9368 14.207 18.0833 14.207H16.7917V9.04037H19.375V11.6237H21.9583V6.45703H16.7917V2.58203H14.2083V6.45703H12.9167C10.0632 6.45703 7.75 8.77023 7.75 11.6237C7.75 14.4772 10.0632 16.7904 12.9167 16.7904H14.2083V21.957H11.625V19.3737H9.04167V24.5404H14.2083ZM16.7917 21.957H18.0833C19.5101 21.957 20.6667 20.8004 20.6667 19.3737C20.6667 17.947 19.5101 16.7904 18.0833 16.7904H16.7917V21.957ZM14.2083 14.207V9.04037H12.9167C11.4899 9.04037 10.3333 10.197 10.3333 11.6237C10.3333 13.0504 11.4899 14.207 12.9167 14.207H14.2083Z"
                    fill="#1F2E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 broker-landing__horizontal-info__herobrokerplans__card">
              <p>Reduce tu contabilidad al mínimo. Nunca más cuadrar comisiones y cobrar a cada compañía.</p>
            </div>
          </Row>
        </div>
      </div>
    </main>
  );
};

export default BrokerLandingPlansHeader;
