import React from 'react';
import { Row, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconBtn } from '../../Utils/Button';
import BrokerLandingDemo from '../../../assets/images/Landing/BrokerLanding/woman-demo.webp';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingDemoSecond = () => {
  const history = useHistory();
  const { type, translateType } = useGetRoleByRoute();
  const navigateTo = () => {
    const path = type === 'insured' ? `/${translateType}/landing_contact` : `/${translateType}/demo_contact`;
    history.push(path);
  };

  return (
    <section id="demo" className="broker-landing__horizontal-info__demo">
      <Row className="align-items-center m-0 d-flex justify-content-center">
        <div className="col-sm-12 col-md-4">
          <h5 className="broker-landing__horizontal-info__demo__title text-dark-blue">
            {type === 'insured'
              ? 'Agenda una asesoría gratis para que veas cómo sería tu vida junto a NICO'
              : 'Agenda un demo de nuestro software para que veas cómo sería tu vida junto a NICO'}
          </h5>
          <IconBtn onClick={() => navigateTo()} className="main-button btn-landing" variant="Primary">
            {type === 'insured' ? 'Quiero asesoría GRATIS' : 'Agenda demo'}
          </IconBtn>
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-center">
          <Image
            className="broker-landing__horizontal-info__demo__img"
            src={BrokerLandingDemo}
            alt="Corredoras trabajando comodamente usando NICO"
            width="530"
            height="auto"
          />
        </div>
      </Row>
    </section>
  );
};

export default BrokerLandingDemoSecond;
