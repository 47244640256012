import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal } from '../../../components';
import AdminBeneficiaryForm from './form/AdminBeneficiaryForm';
import { updateAdminPolicyBeneficiaryRequest } from '../../../requests/admin/adminBeneficiaries';

const AdminBeneficiaryEdit = ({ policyId, beneficiary, handleModalClose, modalShow, setMoreData }) => {
  const dispatch = useDispatch();
  const { id } = beneficiary;

  const handleSuccessUpdate = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Beneficiario actualizado con éxito' }));
    setMoreData(m => !m);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    const updateRequest = updateAdminPolicyBeneficiaryRequest;
    updateRequest(policyId, id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <SimpleCenteredModal
      title="Editar Beneficiario"
      show={modalShow}
      size="lg"
      onHide={handleModalClose}
      body={
        <AdminBeneficiaryForm
          action="edit"
          beneficiary={beneficiary}
          formRequest={handleUpdateRequest}
          handleModalClose={handleModalClose}
        />
      }
    />
  );
};

export default AdminBeneficiaryEdit;
