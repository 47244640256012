import ApiService from '../../services/apiService';

export default function sendContactEmailRequest({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) {
  ApiService.request('post', '/insured/shared_endpoints/send_contact_email', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });
}
