import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { InsuranceCategoryRemoteSelector } from '../../InsurancePolicies';
import DropzoneFileSection from '../../Shared/DropzoneFileSection';
import { BasicTextArea, FormikDatePicker, FormikInputRadio, FormikNumberFormat } from '../../Utils/Input';
import CurrencyRadioInput from './CurrencyRadioInput';

const CoverSection = ({ modelName, disabled, userModule }) => {
  const { errors, touched, values } = useFormikContext();
  const currency = values[`${modelName}`]?.currency ?? 'UF';

  const modelValues = values[`${modelName}`];
  const { additionalDocumentsInfo, estimatedNetPrime } = modelValues;

  const { insuranceCategoryCategory } = getIn(values, modelName);

  const insuranceTypeOptions = [
    { label: 'Generales', value: 'general' },
    { label: 'Vida', value: 'life' }
  ];

  const insuranceTypeTooltip = () => {
    return `Generales: Seguros que protegen las cosas o el patrimonio.<br/>
    Vida: Seguros que protegen a las personas, un capital o una renta.`;
  };

  const validityStartTooltip = () => {
    return `Ingresa la fecha de inicio de vigencia según el término de vigencia de la póliza actual,<br/>
    o simplemente una fecha objetivo.`;
  };

  const estimatedNetPrimeTooltip = () => {
    return `Ingresa una prima neta estimada.<br/>
    Si es una Póliza de cadencia única ingresa la prima por la vigencia completa.<br/>
    Si es de cadencia mensual, la prima mensual.`;
  };

  const showParsedEstimatedNetPrime = () => {
    return userModule === 'admin' || userModule === 'broker';
  };

  return (
    <>
      <section className="form-section mb-5 w-100">
        <p className="section-title">Información del Riesgo</p>

        <Row>
          <Col sm={12} md={3} lg={3}>
            <Field name={`${modelName}[insuranceCategoryCategory]`}>
              {({ field }) => (
                <FormikInputRadio
                  {...field}
                  abbr
                  field={field}
                  label="Tipo"
                  defaultValue={insuranceCategoryCategory}
                  options={insuranceTypeOptions}
                  fieldName={`${modelName}[insuranceType]`}
                  tooltipText={insuranceTypeTooltip()}
                />
              )}
            </Field>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Field name={`${modelName}[insuranceCategoryId]`}>
              {({ field }) => (
                <InsuranceCategoryRemoteSelector
                  field={field}
                  modelName={modelName}
                  disabled={disabled || !insuranceCategoryCategory}
                />
              )}
            </Field>
          </Col>
          <Col sm={12} md={4} lg={4}>
            <Field name={`${modelName}[validityStart]`}>
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  abbr
                  label="Inicio vigencia"
                  defaultDate={false}
                  disabled={disabled}
                  placeholder="dd/mm/aaaa"
                  tooltipText={validityStartTooltip()}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={3} lg={3}>
            <Field name={`${modelName}[currency]`}>
              {({ field }) => <CurrencyRadioInput field={field} disabled={disabled || userModule === 'insured'} />}
            </Field>
          </Col>
          {showParsedEstimatedNetPrime() && (
            <Col sm={12} md={4} lg={4}>
              <Field name={`${modelName}[parsedEstimatedNetPrime]`}>
                {({ field }) => (
                  <FormikNumberFormat
                    {...field}
                    label="Prima neta estimada"
                    suffix={` ${currency}`}
                    defaultValue={estimatedNetPrime}
                    tooltipText={estimatedNetPrimeTooltip()}
                    fieldName={`${modelName}[estimatedNetPrime]`}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          <Col sm={12} md={5} lg={5}>
            <DropzoneFileSection
              multiple
              disabled={disabled}
              label="Documentos adicionales"
              modelName="lead"
              originalObject={modelValues}
              persistedFiles={additionalDocumentsInfo}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Field name={`${modelName}[subjectMatter]`}>
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Descripción general"
                  placeholder="Ingresa una descripción general de la materia, el riesgo y la cobertura buscada para esta póliza. Más abajo podrás especificar los detalles para cada ítem."
                  rows={2}
                  className="p-3"
                  disabled={disabled}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>

      <hr className="w-100 mb-5" />
    </>
  );
};

export default CoverSection;
