import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexBolgRequest, showBlogRequest } from '../../requests/blogs';
import BrokerLandingDemoSecond from '../Landing/BrokerLanding/BrokerLandingDemoSecond';
import WhatsAppButton from '../Utils/WhatsAppBtn/WhatsAppButton';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';
import BlogCard from './BlogCard';
import loader from '../../assets/images/Landing/loader.gif';
import { IconBtn } from '../Utils/Button';
import IconGG from '../Utils/Icon';
import BlogInfo from './BlogInfo';

function BlogShow() {
  const { id: blogUrl } = useParams();
  const { type } = useGetRoleByRoute();
  const dispatch = useDispatch();
  const history = useHistory();
  const [randomBlogs, setRandomBlogs] = useState([]);
  const [blog, setBlog] = useState(undefined);
  const [isFetching, setIsFetchings] = useState([]);

  // Random Blogs request
  const getRandomBlogs = () => {
    return indexBolgRequest({
      dispatch,
      params: {
        recent_blogs: blogUrl,
        display_length: 3,
        with_blog_type: type
      },
      successCallback: response => {
        setRandomBlogs(camelCaseRecursive(response.data.data));
      }
    });
  };

  const getBlog = () => {
    setIsFetchings(true);
    return showBlogRequest(blogUrl, {
      dispatch,
      successCallback: response => {
        setBlog(camelCaseRecursive(response.data));
        setIsFetchings(false);
      }
    });
  };

  const goBack = () => {
    history.goBack();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    getBlog();
    getRandomBlogs();
  }, [blogUrl]);

  return (
    <div className="blog-section">
      <div className={`blog-header blog-header-${type}`}>
        <h1>blog</h1>
      </div>
      <IconBtn onClick={goBack} variant="transparent" className="login-navbar--back-btn back-btn">
        <IconGG icon="chevron-left" />
        <span className="login-navbar--back-btn__text ">Volver</span>
      </IconBtn>
      <div className="blog-show">
        {isFetching && (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '600px' }}>
            <img style={{ width: '60px' }} src={loader} alt="Loader" />
          </div>
        )}
        {!isFetching && <BlogInfo blog={blog} />}
        {!blog && <h5 className="text-center">No se encontro el blog.</h5>}
      </div>

      {randomBlogs.length > 0 && (
        <div className="random-blogs">
          <h4>También te puede interesar</h4>
          <div className="random-blogs-grid">
            {randomBlogs.map(randomBlog => (
              <BlogCard blog={randomBlog} />
            ))}
          </div>
          <hr style={{ marginTop: '50px' }} />
        </div>
      )}
      <BrokerLandingDemoSecond />
      <WhatsAppButton />
    </div>
  );
}

export default BlogShow;
