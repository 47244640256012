import React from 'react';
import { useHistory } from 'react-router-dom';
import { LinkBtn } from '../../components';

const NotFound = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="w-50 mx-auto d-flex flex-column align-items-center">
      <h1>404</h1>
      <p>Esta página no existe actualmente</p>
      <br />
      <br />
      <LinkBtn onClick={goBack} className="btn cancel">
        Volver
      </LinkBtn>
    </div>
  );
};

export default NotFound;
