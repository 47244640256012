import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { BasicTextArea } from '../../../../components';
import { InfoBox } from '../../../../components/Shared';

const InsurancePolicyDebtContractForm = ({ errors, setFieldValue, setSubmitting, isSubmitting, touched, ...props }) => {
  const { errorMessage, formRequest, handleModalClose } = props;
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  const handleUncheckAllDebts = () => {
    setSubmitting(true);
    formRequest({ insurancePolicy: { uncheckAllDebts: true } }, setSubmitting);
  };

  const handleColumnRules = ({ rowArray: [policyNumber, debtStartDate, debtAmount, debtInstallment] }) => {
    let allStructure = '';

    if (policyNumber) {
      const parsedPolicyNumber = policyNumber.trim();
      allStructure += `${parsedPolicyNumber}`;
    }

    if (debtStartDate) {
      const debtStartDateBase = debtStartDate.replace(/[-]/g, '/');
      const parsedDebtStartDate = debtStartDateBase.trim();
      allStructure += `\t${parsedDebtStartDate}`;
    }

    if (debtAmount) {
      const debtAmountBase = debtAmount.replace(/[,]/g, '.');
      const emptyDecimal = debtAmountBase.split('.')[1] === '';
      const parsedDebtAmount = emptyDecimal ? debtAmountBase : parseFloat(debtAmountBase) || '';
      allStructure += `\t${parsedDebtAmount}`;
    }

    if (debtInstallment) {
      const parsedDebtInstallment = parseInt(debtInstallment, 0) || '';
      allStructure += `\t${parsedDebtInstallment}`;
    }

    return allStructure;
  };

  const handleOnChangeTextArea = ({ fieldName, rows = [] }) => {
    const formattedComment = rows.map(row => {
      const rowArray = row.split('\t').filter(word => word);
      const formattedRows = handleColumnRules({ rowArray });

      const splitElement = row.split(';');
      const splitLength = splitElement.length >= 2;
      return splitLength ? `${splitElement[0].trim()}\t${splitElement[1].trim()}` : formattedRows;
    });

    setFieldValue(fieldName, formattedComment.join('\n'));
  };

  return (
    <>
      {errorMessage && (
        <InfoBox icon="danger" variant="danger" text={`La carga no pudo ser procesada: ${errorMessage}`} />
      )}

      <p>Para cargar el listado de pólizas con deuda:</p>
      <ul>
        <li className="ml-4">
          Ingresa una póliza por línea con los siguientes datos: N° de póliza; Fecha de inicio de deuda (dd/mm/aaaa);
          Monto deuda; Número cuota
        </li>
        <li className="ml-4">
          Copia los datos desde un documento <span className={commonClasses}>XLSX</span> o separa cada dato de la póliza
          utlizando <span className={commonClasses}>;</span>
        </li>
      </ul>

      <Form>
        <Row>
          <Col xs={12}>
            <Field name="insurancePolicy[debtContracts]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row={6}
                  placeholder={`N° de póliza; Fecha de inicio de deuda; Monto deuda; Número cuota\n...`}
                  onChange={({ target }) => {
                    const targetValue = target.value;
                    const rows = targetValue.split('\n');
                    handleOnChangeTextArea({ fieldName: field.name, rows });
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  className="p-3 textarea-tab-size"
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mt-2 d-flex justify-content-between">
          <Col md={4} className="mt-4">
            <Button
              block
              variant="primary-light"
              className="no-shadow"
              onClick={handleUncheckAllDebts}
              disabled={isSubmitting}
            >
              Limpiar todas
            </Button>
          </Col>
          <Col md={7}>
            <Row>
              <Col md={6} className="mt-4">
                <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
                  Cargar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ insurancePolicy }) => ({ insurancePolicy });

const validationSchema = Yup.object().shape({
  insurancePolicy: Yup.object().shape({
    debtContracts: Yup.string().required('Debes ingresar los datos de las pólizas a modificar')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(InsurancePolicyDebtContractForm);
