import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import { useFetchData } from '../../hooks';
import { debouncedAccountPoliciesSummaryRequest, debounceUpdateAccountRequest } from '../../requests/accounts';
import {
  debouncedAdminAccountPoliciesSummaryRequest,
  debounceUpdateAdminAccountRequest
} from '../../requests/admin/adminAccounts';
import { InfoCardAdditionalDocument, AutoSaveCommentsForm, HistoricalRecordsShow } from '../Shared';
import { StatisticCard } from '../Utils/Cards';
import {
  debouncedInsuredAccountPoliciesSummaryRequest,
  debounceUpdateInsuredAccountRequest
} from '../../requests/insured/insuredAccounts';

const ShowAccountStatistics = ({ account, setMoreData, userModule }) => {
  const { id, additionalDocumentsInfo, comments, historicalRecords } = account;

  const [activeTab, setActiveTab] = useState('summary');

  const isActive = input => {
    return activeTab.includes(input);
  };

  const getUpdateRequest = () => {
    const requestMap = {
      admin: debounceUpdateAdminAccountRequest,
      broker: debounceUpdateAccountRequest,
      insured: debounceUpdateInsuredAccountRequest
    };

    return requestMap[userModule];
  };

  return (
    <div className="account-show-statistics">
      <StatisticsTabs setActiveTab={setActiveTab} isActive={isActive} />
      <div className="account-show-statistics--components-container">
        {isActive('summary') && id && <Summary id={id} userModule={userModule} />}
        {isActive('documents') && (
          <InfoCardAdditionalDocument
            canAddDocuments
            modelName="account"
            object={account}
            additionalDocuments={additionalDocumentsInfo}
            setMoreData={setMoreData}
            // updateRequest={fromAdmin ? debounceUpdateAdminAccountRequest : debounceUpdateAccountRequest}
            updateRequest={getUpdateRequest()}
          />
        )}
        {isActive('comments') && (
          <AutoSaveCommentsForm
            resourceId={id}
            resourceComments={comments}
            resourcePath="account[comments]"
            // debouncedUpdateRequest={fromAdmin ? debounceUpdateAdminAccountRequest : debounceUpdateAccountRequest}
            debouncedUpdateRequest={getUpdateRequest()}
            customClassName="custom-height"
          />
        )}
        {isActive('history') && <AccountHistory historicalRecords={historicalRecords} />}
      </div>
    </div>
  );
};

const StatisticsTabs = ({ setActiveTab, isActive }) => {
  return (
    <section className="account-show-statistics--tabs">
      <Button
        className={`btn-sm no-shadow mr-3 ${isActive('summary') ? '' : 'outline'}`}
        variant="secondary-blue"
        onClick={() => setActiveTab('summary')}
      >
        Resumen
      </Button>
      <Button
        className={`btn-sm no-shadow mr-3 ${isActive('documents') ? '' : 'outline'}`}
        variant="secondary-blue"
        onClick={() => setActiveTab('documents')}
      >
        Documentos
      </Button>
      <Button
        className={`btn-sm no-shadow mr-3 ${isActive('comments') ? '' : 'outline'}`}
        variant="secondary-blue"
        onClick={() => setActiveTab('comments')}
      >
        Comentarios
      </Button>
      <Button
        className={`btn-sm no-shadow ${isActive('history') ? '' : 'outline'}`}
        variant="secondary-blue"
        onClick={() => setActiveTab('history')}
      >
        Historial
      </Button>
    </section>
  );
};

const Summary = ({ id, userModule }) => {
  const getDebouncedIndexRequest = () => {
    const requestMap = {
      admin: debouncedAdminAccountPoliciesSummaryRequest,
      broker: debouncedAccountPoliciesSummaryRequest,
      insured: debouncedInsuredAccountPoliciesSummaryRequest
    };

    return requestMap[userModule];
  };

  const { data: summary, isFetching } = useFetchData({
    debouncedIndexRequest: getDebouncedIndexRequest(),
    withoutWrapper: true,
    parentId: id
  });

  const { debtCount, nearInvalidityCount, sinisterCount, totalCommission, validTotalNetPremium, validCount } = summary;

  const showTotalCommission = () => {
    return ['admin', 'broker'].includes(userModule);
  };

  return (
    <>
      {isFetching && (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isFetching && (
        <div className="account-show-statistics--component summary">
          <StatisticCard icon="danger" variant="warning" title="Pólizas por vencer" value={nearInvalidityCount} />
          <StatisticCard icon="notes" variant="primary-light" title="Pólizas vigentes" value={validCount} />
          <StatisticCard icon="dollar" variant="success" title="Prima total neta" value={validTotalNetPremium} />
          {showTotalCommission() && (
            <StatisticCard
              icon="dollar"
              variant="success"
              title="Comisión total neta"
              value={totalCommission}
              tooltipText="Esta es la comisión total que recibirás por esta cuenta.<br/>Todos los descuentos ya fueron
              aplicados."
            />
          )}
          <StatisticCard icon="smile-sad" variant="danger" title="Siniestros" value={sinisterCount} />
          <StatisticCard icon="dollar" variant="danger" title="Deudas" value={debtCount} />
        </div>
      )}
    </>
  );
};

const AccountHistory = ({ historicalRecords = [] }) => {
  return <HistoricalRecordsShow historicalRecords={historicalRecords} customClassName="custom-height" />;
};

export default ShowAccountStatistics;
