import React from 'react';
import PropTypes from 'prop-types';
import { IconBtn } from '../Button';

const HomeWelcomeCard = ({ icon, variant, title, subtitle }) => {
  return (
    <div className="home-welcome-card">
      <div className="home-welcome-card--icon">
        <IconBtn icon={icon} iconSize="xl" variant={variant} noBtn className="no-shadow" />
      </div>
      <div className="home-welcome-card--content">
        <h4 className="section-title text-color-primary-dark">{title}</h4>
        <p className="text-color-primary-dark">{subtitle}</p>
      </div>
    </div>
  );
};

HomeWelcomeCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  variant: PropTypes.string
};

HomeWelcomeCard.defaultProps = {
  variant: ''
};

export default HomeWelcomeCard;
