import React from 'react';
import { Row, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconBtn } from '../../Utils/Button';

import BrokerLandingUI from '../../../assets/images/Landing/BrokerLanding/broker-landing-ui.webp';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingUiPublicity = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToDemoContact = () => history.push(`/${translateType}/demo_contact`);

  return (
    <section id="uipublicity" className="broker-landing__horizontal-info__uipublicity">
      <Row className="align-items-center m-0">
        <div className="col-sm-12 col-md-4">
          <h5 className="broker-landing__horizontal-info__uipublicity__title text-dark-blue">
            Agenda un demo de nuestro software para que veas cómo sería tu vida junto a NICO
          </h5>
          <IconBtn onClick={() => navigateToDemoContact()} className="main-button btn-landing" variant="Primary">
            Agenda demo
          </IconBtn>
        </div>
        <div className="col-sm-12 col-md-8">
          <Image
            className="broker-landing__horizontal-info__uipublicity__img"
            src={BrokerLandingUI}
            alt="3 plantillas de la interfaz de nico, se destaca el dashboard"
            width="747"
            height="auto"
          />
        </div>
      </Row>
    </section>
  );
};

export default BrokerLandingUiPublicity;
