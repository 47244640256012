import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { useFetchForRemoteSelect } from '../../hooks';
import {
  debounceIndexAdminInsurancePoliciesRequest,
  indexAdminInsurancePolicyRequest
} from '../../requests/admin/adminInsurancePolicies';
import { InputRemoteSelect } from '../Utils/Select';

const InsurancePolicyRemoteSelector = ({ field, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const { createdFrom } = values[modelName];

  const getCadenceType = () => {
    if (createdFrom === 'debt') {
      return 'unique';
    }
    if (createdFrom === 'monthly_cadence') {
      return 'monthly';
    }

    return '';
  };

  const {
    options: insurancePolicies,
    selectedOption: selectedInsurancePolicy,
    fetchOptions: fetchInsurancePolicies
  } = useFetchForRemoteSelect({
    indexRequest: indexAdminInsurancePolicyRequest,
    debouncedIndexRequest: debounceIndexAdminInsurancePoliciesRequest,
    value: getIn(values, `${modelName}[insurancePolicyId]`),
    initialParams: {
      for_selector: true,
      is_valid: true,
      sort_column: 'created_at',
      sort_direction: 'asc',
      cadence_type: getCadenceType(),
      with_debt: createdFrom === 'debt' ? true : ''
    },
    nestedDependency: createdFrom
  });

  return (
    <InputRemoteSelect
      abbr
      {...field}
      isClearable
      label="Número de póliza"
      placeholder="Seleccionar póliza"
      defaultOptions={insurancePolicies}
      value={selectedInsurancePolicy}
      request={fetchInsurancePolicies}
      onChange={data => setFieldValue(field.name, data?.value || '')}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default InsurancePolicyRemoteSelector;
