/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-danger */
import React from 'react';

const BlogInfo = ({ blog }) => {
  return (
    <div className="blog-show-content">
      <p>{blog?.publishDate}</p>
      <h3>{blog?.title}</h3>
      <h6>{blog?.subtitle}</h6>
      <img src={blog?.image?.fileUrl} alt={blog?.image?.filename} />
      <div className="content">
        <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
      </div>
    </div>
  );
};

export default BlogInfo;
