import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import { requestSignOut } from '../../../actions/auth';

const SettingsMenu = ({ canFromNavbarTop, handleClose, profilePath, userPath }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    document.body.classList.remove('no-scroll');
    return dispatch(requestSignOut());
  };

  return (
    <div className="settings-menu">
      {canFromNavbarTop.user && (
        <Nav.Link role="button" onClick={handleClose} as={Link} to={userPath}>
          <span className="link-menu font-weight-bold">Usuarios</span>
        </Nav.Link>
      )}

      {canFromNavbarTop.profile && (
        <Nav.Link role="button" onClick={handleClose} as={Link} to={profilePath}>
          <span className="link-menu font-weight-bold">Mi Perfil</span>
        </Nav.Link>
      )}

      <Nav.Link onClick={handleLogout}>
        <span className="link-menu font-weight-bold">Cerrar Sesión</span>
      </Nav.Link>
    </div>
  );
};

export default SettingsMenu;
