import React from 'react';
import { useSelector } from 'react-redux';
import {
  BrokerLandingCompanies,
  BrokerLandingFaqs,
  BrokerLandingDemoSecond,
  BrokerLandingUiPublicity,
  BrokerLandingGotNumbers,
  BrokerLandingPlansHeader,
  BrokerLandingKindPlans
} from '../../components/Landing/BrokerLanding';
import { LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';

const BrokerPlans = () => {
  const { settings } = useSelector(state => state.utils);

  return (
    <WebLayout type="broker">
      <BrokerLandingPlansHeader settings={settings} />
      <BrokerLandingKindPlans />
      <BrokerLandingUiPublicity settings={settings} />
      <BrokerLandingGotNumbers />
      <BrokerLandingFaqs />
      <BrokerLandingDemoSecond />
      <BrokerLandingCompanies />
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default BrokerPlans;
