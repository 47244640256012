import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { DefaultHeader } from '../../../components';
import { InsuredContactForm } from '../../../components/Shared';
import sendContactEmailRequest from '../../../requests/insured/insuredSharedEndpoints';
import { redirectTo, scrollToTop } from '../../../services/utils';
import { fieldOptions, insuredContactAttributes } from './basicOptions';

const InsuredSinisterIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSendContactEmailRequest = (values, setSubmitting) => {
    sendContactEmailRequest({
      dispatch,
      params: { type: 'sinister', ...snakecaseKeys(values) },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Correo enviado con éxito.' }));
        redirectTo(history, '/insured/home');
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(scrollToTop, []);

  return (
    <DefaultHeader title="Siniestros">
      <HeaderSubtitle />
      <InsuredContactForm
        fromIndex="sinister"
        insuredContact={insuredContactAttributes}
        fieldOptions={fieldOptions}
        formRequest={handleSendContactEmailRequest}
      />
    </DefaultHeader>
  );
};

const HeaderSubtitle = () => {
  return (
    <>
      <p className="mt-5 section-title font-weight-500">
        Si tuviste un siniestro lo primero que debes hacer es comunicárselo a tu corredor. Incluso antes que a la
        compañía de seguros!
      </p>
      <p className="mb-5 section-title font-weight-500">
        Tu corredor te asesorará respecto a cómo actuar y cómo reportar el siniestro a la compañía de seguros. Puedes
        llamar a tu corredor, mandarle un correo electrónico o llenar el formulario a continuación. Por nuestra parte,
        haremos todo lo posible por ayudarte y guiarte en todo el proceso.
      </p>
    </>
  );
};

export default InsuredSinisterIndex;
