import React from 'react';
import { Card, Carousel, Col, Row } from 'react-bootstrap';

import defaultProfileImg from '../../../assets/images/default-profile.png';

const InfoCardCarousel = ({ btnComponent, items = [], title }) => {
  const showControls = items.length > 1;

  return (
    <Carousel fade className="info-card-carousel" controls={showControls} indicators={showControls}>
      {items.map((item, index) => (
        <Carousel.Item key={`card-carousel-${index.toString()}`} interval={10000}>
          <InfoCard index={index} title={title} item={item} btnComponent={btnComponent} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

const InfoCard = ({ btnComponent, index, item, title }) => (
  <Card>
    <Card.Header as="p" className="section-title capitalize-text px-0">
      {title}
      {btnComponent && btnComponent}
    </Card.Header>

    <Row className="card__content">
      {item.avatar.present && (
        <Col xs={5} sm={4} md={3} lg={2} className="h-100">
          <Card.Img alt={`avatar-${index.toString()}`} src={item.avatar.fileUrl || defaultProfileImg} />
        </Col>
      )}

      <Col className="h-100">
        <Card.Body>
          <InfoLine title="Nombre" value={item.name} />
          <InfoLine title="Correo electrónico" value={item.email} />
          <InfoLine lastInfoLine title="Teléfono" value={item.phone} />
        </Card.Body>
      </Col>
    </Row>
  </Card>
);

const InfoLine = ({ lastInfoLine, title, value }) => (
  <div className={lastInfoLine ? '' : 'mb-4'}>
    <Card.Subtitle>{title}:</Card.Subtitle>
    <Card.Text>{value || 'Sin especificar'}</Card.Text>
  </div>
);

export default InfoCardCarousel;
