import React from 'react';

import { FormikSelect } from '../Select';

const SimpleSelectFilter = ({ defaultValue, fieldName, setCustomParams, ...props }) => {
  const handleRemoteChange = selected => {
    setCustomParams(params => ({ ...params, [fieldName]: selected?.value || defaultValue || '' }));
  };

  return <FormikSelect isClearable onChange={handleRemoteChange} {...props} />;
};

export default SimpleSelectFilter;
