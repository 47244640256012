import React from 'react';

const Medal = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46726)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.625 7.875C16.625 9.9564 15.5868 11.7953 14 12.9021V19.25H11.7374L10.5 18.0125L9.26253 19.25H7V12.9021C5.4132 11.7953 4.375 9.9564 4.375 7.875C4.375 4.49226 7.11726 1.75 10.5 1.75C13.8827 1.75 16.625 4.49226 16.625 7.875ZM14.875 7.875C14.875 10.2912 12.9162 12.25 10.5 12.25C8.08375 12.25 6.125 10.2912 6.125 7.875C6.125 5.45875 8.08375 3.5 10.5 3.5C12.9162 3.5 14.875 5.45875 14.875 7.875ZM8.75 17.2876L10.5 15.5377L12.25 17.2877V13.7464C11.6955 13.9114 11.1081 14 10.5 14C9.8919 14 9.3045 13.9114 8.75 13.7464V17.2876Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46726">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Medal;
