import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isUserAdmin, isUserJustAdmin, isUserBroker } from '../services/utils';

const useUtils = () => {
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState(false);
  const [isCurrentUserJustAdmin, setIsCurrentUserJustAdmin] = useState(false);
  const [isCurrentUserBroker, setIsCurrentUserBroker] = useState(false);

  const { user: reduxUser } = useSelector(state => state.auth);
  const handleGetRole = () => {
    setIsCurrentUserAdmin(isUserAdmin(reduxUser));
    setIsCurrentUserBroker(isUserBroker(reduxUser));
    setIsCurrentUserJustAdmin(isUserJustAdmin(reduxUser));
  };

  useEffect(handleGetRole, [reduxUser]);

  return {
    reduxUser,
    isCurrentUserAdmin,
    isCurrentUserJustAdmin,
    isCurrentUserBroker
  };
};

export default useUtils;
