import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Image } from 'react-bootstrap';
import { IconBtn } from '../../Utils/Button';
import BrokerLandingUiSales from '../../../assets/images/Landing/PartnerLanding/ui-sales.webp';

const BrokerLandingSuport = () => {
  const history = useHistory();
  const navigateToHowItWorks = () => {
    history.push(`/partner/como`);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <section id="suport" className="partner-landing__suport__container partner-landing">
      <Row className="align-items-center">
        <div className="col-md-6 ">
          <Image className="w-100" src={BrokerLandingUiSales} alt="Interfaz sección de ventas" />
        </div>

        <div className="col-md-6">
          <h3 className="">
            Agrega la venta de seguros a tu negocio en un abrir y cerrar de ojos. Con NICO es simple, fácil y rápido.
          </h3>

          <Row className=" align-items-center partner-landing__suportpartner">
            <div className="col-2 pr-0 partner-landing__suportpartner__icon ">
              <div className="partner-landing__suportpartner__iconcontainer">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.6666 15.5C20.6666 18.3535 18.3534 20.6667 15.5 20.6667C12.6465 20.6667 10.3333 18.3535 10.3333 15.5C10.3333 12.6465 12.6465 10.3333 15.5 10.3333C18.3534 10.3333 20.6666 12.6465 20.6666 15.5ZM18.0833 15.5C18.0833 16.9267 16.9267 18.0833 15.5 18.0833C14.0732 18.0833 12.9166 16.9267 12.9166 15.5C12.9166 14.0733 14.0732 12.9167 15.5 12.9167C16.9267 12.9167 18.0833 14.0733 18.0833 15.5Z"
                    fill="#1F2E4F"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5 3.875C22.7223 3.875 28.791 8.81475 30.5116 15.5C28.791 22.1853 22.7223 27.125 15.5 27.125C8.27759 27.125 2.20895 22.1853 0.488281 15.5C2.20895 8.81475 8.27759 3.875 15.5 3.875ZM15.5 24.5417C9.71902 24.5417 4.81958 20.7418 3.17389 15.5C4.81958 10.2582 9.71902 6.45833 15.5 6.45833C21.2809 6.45833 26.1803 10.2582 27.826 15.5C26.1803 20.7418 21.2809 24.5417 15.5 24.5417Z"
                    fill="#1F2E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 pl-0">
              <p className="partner-landing__suportpartner__subtitle">Foco</p>
              <p className="partner-landing__suportpartner__text">
                Mantén el foco en tu negocio. Usa tu tiempo para ser el mejor en tu negocio. Nosotros nos encargamos de
                los seguros.
              </p>
            </div>
          </Row>
          <Row className=" align-items-center partner-landing__suportpartner">
            <div className="col-2 pr-0 partner-landing__suportpartner__icon">
              <div className="partner-landing__suportpartner__iconcontainer">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.23047 8.36922L9.3271 14.4659L15.502 8.29102L21.6768 14.4659L27.7721 8.37054V19.8362C27.7721 21.9763 26.0372 23.7112 23.8971 23.7112H7.10547C4.96536 23.7112 3.23047 21.9763 3.23047 19.8362V8.36922ZM25.1888 14.5915V19.8362C25.1888 20.5496 24.6105 21.1279 23.8971 21.1279H7.10547C6.39209 21.1279 5.8138 20.5496 5.8138 19.8362V14.5903L9.3271 18.1036L15.502 11.9287L21.6768 18.1036L25.1888 14.5915Z"
                    fill="#1F2E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 pl-0">
              <p className="partner-landing__suportpartner__subtitle">Valor</p>
              <p className="partner-landing__suportpartner__text">
                Aumenta tu propuesta de valor. Agrega una nueva dimensión a tu producto/servicio y mejora la experiencia
                de producto de tus clientes.
              </p>
            </div>
          </Row>
          <Row className=" align-items-center partner-landing__suportpartner">
            <div className="col-2 pr-0 partner-landing__suportpartner__icon">
              <div className="partner-landing__suportpartner__iconcontainer">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.82669 21.7263L0 19.8996L9.13346 10.7661L17.3536 18.9863L22.8337 13.5062L20.5831 11.2556L29.2271 8.93945L26.9109 17.5835L24.6603 15.3329L17.3536 22.6396L9.13346 14.4195L1.82669 21.7263Z"
                    fill="#1F2E4F"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 pl-0">
              <p className="partner-landing__suportpartner__subtitle">Crecimiento</p>
              <p className="partner-landing__suportpartner__text">
                Haz crecer tu negocio. Suma una nueva línea de ingresos recurrentes, sin impactar tu operación actual.
                NICO se adapta.
              </p>
            </div>
          </Row>
          <h6>Tú generas los leads, NICO vende, administra y te mantiene informado en todo momento.</h6>
        </div>
      </Row>
      <Row className="justify-content-center mt-5">
        <IconBtn
          onClick={() => navigateToHowItWorks()}
          variant="primary-light-blue"
          className="main-button btn-landing col-md-3 col-sm-12 mobile-w-100"
        >
          Cómo funciona
        </IconBtn>
      </Row>
    </section>
  );
};

export default BrokerLandingSuport;
