import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { validateToken } from '../../../actions/auth';
import PrincipalNavbar from './PrincipalNavbar';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';

const NavbarLogged = props => {
  const { user } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();

  const updateAbilityRoles = () => {
    updateAbility(ability, user.role);
  };

  const handleToken = () => {
    if (user.id === 0) {
      dispatch(validateToken());
    }
  };

  useEffect(handleToken, []);

  useEffect(updateAbilityRoles, [user]);

  const { children } = props;
  return (
    <PrincipalNavbar>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/components">
            Componentes
          </Nav.Link>
        </Nav>
        <Nav>
          <NavDropdown alignRight title={user.email} id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/profile">
              Perfil
            </NavDropdown.Item>
            {children}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </PrincipalNavbar>
  );
};

const mapStateToProps = state => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps)(NavbarLogged);
