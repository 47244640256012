import React from 'react';

import { InfoCard } from '../../../components';
import { excludeKeys } from '../../../services/utils';
import validityObject from './validityObject';

const InfoCardValidity = ({ hideAttributes, insurancePolicy }) => {
  let validityInfo = validityObject({ insurancePolicy });
  validityInfo = excludeKeys(validityInfo, hideAttributes);

  return <InfoCard title="Información vigencia" object={validityInfo} />;
};

export default InfoCardValidity;
