import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBeneficiaryMovsRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_policies/${insurancePolicyId}/beneficiary_movements`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const exportMovementSummary = (
  insurancePolicyId,
  beneficiaryMovementId,
  { dispatch, params, successCallback, callback, failureCallback }
) =>
  ApiService.request(
    'get',
    `/insurance_policies/${insurancePolicyId}/beneficiary_movements/${beneficiaryMovementId}/movement_summary`,
    {
      dispatch,
      params,
      responseType: 'text',
      successCallback,
      callback,
      failureCallback
    }
  );

export const debounceIndexBeneficiaryMovsRequest = AwesomeDebouncePromise(indexBeneficiaryMovsRequest, 300);
