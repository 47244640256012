import React from 'react';
import { Row, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconBtn } from '../../Utils/Button';
import BrokerLandingBussiness from '../../../assets/images/Landing/ClientLanding/bussiness.webp';
import BrokerLandingPeople from '../../../assets/images/Landing/ClientLanding/people.webp';

const ClientLandingKindServices = () => {
  const history = useHistory();
  const navigateTo = path => history.push(path);

  return (
    <section
      id="kindservices"
      className="client-landing__horizontal-info__kindservices broker-landing__horizontal-info__kindservices_bgblue broker-color-bg-clients"
    >
      <h4 className="client-landing__horizontal-info__title landing-section-title text-dark-blue">
        ¿Buscas protección como empresa o persona?
      </h4>
      <p className="client-landing__horizontal-info__subtitle landing-section-title text-dark-blue">
        Conoce en detalle los tipos de protección y servicios que podemos entregarte
      </p>
      <div className="col">
        <Row>
          <div className="col-md-6 col-sm-12 client-landing__horizontal-info__kindservices__margin">
            <div className="client-landing__horizontal-info__kindservices__card ">
              <Image
                className="client-landing__horizontal-info__kindservices__card__img"
                src={BrokerLandingBussiness}
                alt="Edificios empresariales"
              />

              <div className="client-landing__horizontal-info__kindservices__card__containertext">
                <h6 className="client-landing__horizontal-info__kindservices__card__titlecard">Empresa</h6>
                <p className="client-landing__horizontal-info__kindservices__card__textcard">
                  Si eres persona jurídica esta información te ayudará.
                </p>
                <div className="client-landing__horizontal-info__kindservices__card__btnleft">
                  <IconBtn
                    onClick={() => navigateTo('/asegurado/companias')}
                    className="main-button btn-landing w-mobile-100"
                    variant="primary"
                  >
                    Conoce más
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="client-landing__horizontal-info__kindservices__card ">
              <Image
                className="client-landing__horizontal-info__kindservices__card__img"
                src={BrokerLandingPeople}
                alt="Grupo de personas felices"
              />

              <div className="client-landing__horizontal-info__kindservices__card__containertext">
                <h6 className="client-landing__horizontal-info__kindservices__card__titlecard">Persona</h6>
                <p className="client-landing__horizontal-info__kindservices__card__textcard">
                  Si eres persona natural esta información te ayudará.
                </p>
                <div className="client-landing__horizontal-info__kindservices__card__btnleft">
                  <IconBtn
                    onClick={() => navigateTo('/asegurado/personas')}
                    className="main-button btn-landing w-mobile-100"
                    variant="primary"
                  >
                    Conoce más
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ClientLandingKindServices;
