import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminIndustryRequest } from '../../../requests/admin/industries';
import AdminIndustryDataTable from './AdminIndustryDataTable';

const AdminIndustryIndex = () => {
  const customParamsRef = useRef({});
  const {
    data: industries,
    totalData: totalIndustries,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminIndustryRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los rubros. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/industries';

  return (
    <DefaultHeader
      title="Rubros"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Rubro',
        path: `${modelUrl}/new`
      }}
    >
      <AdminIndustryDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={industries}
        tableDataAmount={totalIndustries}
      />
    </DefaultHeader>
  );
};

export default AdminIndustryIndex;
