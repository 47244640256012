import React from 'react';

const AdditionsTextDescription = () => {
  const commonClasses = 'bg-light-gray px-3 rounded-pill font-weight-bold';

  return (
    <>
      <p>Para cargar las incorporaciones:</p>
      <ul>
        <li className="ml-4">
          Ingresa un titular (o sus cargas) por línea con los siguientes datos: RUT titular; Nombre titular; Fecha de
          nacimiento titular; Rol titular; Fecha de incorporación; Fecha de envío a compañía - incorporación; Número de
          cargas; Cobertura Vida (1/0); Cobertura Salud (1/0); Cobertura Dental (1/0); Cobertura Catastrófico (1/0);
          Comentarios
        </li>
        <li className="ml-4">
          Si estás incorporando un titular, ingresa en la misma fila la cantidad de cargas con las que se está
          incorporando.
        </li>
        <li className="ml-4">
          Si estás incorporando una o más cargas, quiere decir que el titular ya está creado, por lo que solo se sumarán
          las cargas que ingreses asociadas a ese titular.
        </li>
        <li className="ml-4">
          Copia los datos desde un documento <span className={commonClasses}>XLSX</span> o separa cada dato de la póliza
          utlizando <span className={commonClasses}>;</span>
        </li>
      </ul>
    </>
  );
};

export default AdditionsTextDescription;
