import React from 'react';
import { TestimonyCarousel } from '../../Shared';

import person01 from '../../../assets/images/testimony/person-01.png';
import person02 from '../../../assets/images/testimony/person-02.png';
import person03 from '../../../assets/images/testimony/person-03.png';
import constructor from '../../../assets/images/testimony/constructor.png';
import foodIndustry from '../../../assets/images/testimony/food-industry.png';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

// Testimonios para cada type
const brokerTestimonies = [
  {
    name: 'Cartera de UF 25.000',
    age: '62 años',
    testimonial:
      '“Después de más de 30 años como corredor de seguros, ya estaba cansado de las tareas administrativas. Con NICO, no le dedico ni un minuto y todo funciona perfecto. Además, tengo todo el apoyo comercial y técnico que necesito para ir por grandes cuentas.”',
    image: person03
  },
  {
    name: 'Cartera de UF 12.000',
    age: '68 años',
    testimonial:
      '“Hace algunos años empecé a pensar en retirarme, pero sin una jubilación ni heredero que se hiciera cargo de mi cartera, me costaba pensar en dejar ir lo que tardé tantos años en construir. Ahora, gracias a NICO, dedico el tiempo justo y necesario a mantener las relaciones con mi clientes mientras NICO se encarga de todo lo necesario para que mi cartera siga viva. Así, mantengo mis ingresos por más años y dedico mi tiempo a lo que más me gusta.”',
    image: person02
  },
  {
    name: 'Cartera de UF 8.000',
    age: '30 años',
    testimonial:
      '“Hace pocos meses comencé el curso de corredor de seguros. Apenas aprobé el examen me sumé a NICO y pude empezar a vender inmediatamente, con todas las compañías, todas las capacidades, el mejor software del mercado y hasta con áreas de siniestros y cobranza.”',
    image: person01
  }
];

const insuredTestimonies = [
  {
    name: 'Persona jurídica',
    industry: 'Constructora',
    testimonial:
      '“En NICO encontramos el apoyo que necesitábamos. La asesoría que nos hicieron fue personalizada y en profundidad. Logramos asegurar una obra y equipos de trabajo que varios corredores no habían logrado. Además, fue rápido y a buen precio.”',
    image: constructor
  },
  {
    name: 'Persona natural',
    age: '36 años',
    testimonial:
      '“Simple y claro. Pude hacer todo online, con los pasos explicados con peras y manzanas. Ahora tengo todas mis cosas aseguradas con NICO.”',
    image: person01
  },
  {
    name: 'Persona jurídica',
    industry: 'Industria de alimentos',
    testimonial:
      '“Para nosotros, el bienestar de nuestros más de 1.000 colaboradores es una prioridad. Con NICO logramos diseñar un seguro colectivo de salud, vida y dental con la mejor protección, sin castigar pre-existencias y a un excelente precio. Pero lo más importante, el servicio que nos han entregado durante la cobertura ha sido siempre cercano y oportuno.”',
    image: foodIndustry
  }
];

const partnerTestimonies = [];

const useTestimoniesByType = type => {
  switch (type) {
    case 'broker':
      return brokerTestimonies;
    case 'insured':
      return insuredTestimonies;
    case 'partner':
      return partnerTestimonies;
    default:
      return [];
  }
};

const TestimonySection = () => {
  const { type } = useGetRoleByRoute();
  const testimonies = useTestimoniesByType(type);

  if (testimonies.length === 0) return null;

  return (
    <div className="testimony-section">
      <h3>Lo que opinan nuestros clientes</h3>
      <TestimonyCarousel testimonies={testimonies} />
    </div>
  );
};

export default TestimonySection;
