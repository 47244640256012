import { redirectTo } from '../../../services/utils';
import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const leadCoverageObject = ({
  deleteProposal,
  fromAdmin,
  handleShowModal,
  history,
  lead,
  modelUrl,
  openEmailModal
}) => {
  const {
    hasProposal,
    insurancePolicyContract = {},
    insurancePolicyProposal,
    isRenewal,
    leadProposal,
    selectedLeadQuotation
  } = lead;

  const insurancePolicyProposalEditPath = `/admin/insurance_policies/${insurancePolicyProposal.id}/edit`;
  const hasContract = Object.keys(insurancePolicyContract).length > 0;
  const isClosed = lead.step === 'closed';

  const object = {};

  if (hasProposal && leadProposal)
    object.contractProposal = infoCardElement({
      file: true,
      link: true,
      item: {
        title: 'Propuesta de contratación',
        name: leadProposal.contractProposalInfo.filename,
        url: leadProposal.contractProposalInfo.fileUrl,
        canDelete: fromAdmin && !hasContract,
        deleteOnClick: () => handleShowModal(leadProposal)
      }
    });
  if (hasProposal && insurancePolicyProposal)
    object.insurancePolicyProposal = infoCardElement({
      file: true,
      link: true,
      item: {
        title: `Propuesta de ${isRenewal ? 'renovación' : 'contratación'}`,
        name: insurancePolicyProposal.contractProposalInfo.filename,
        url: insurancePolicyProposal.contractProposalInfo.fileUrl,
        canEdit: fromAdmin && !hasContract,
        editOnClick: () =>
          redirectTo(history, insurancePolicyProposalEditPath, {
            policyType: 'proposal',
            isRenewal,
            leadModelUrl: modelUrl,
            leadPath: `${modelUrl}/${lead.id}`
          }),
        canDelete: fromAdmin && !hasContract,
        deleteOnClick: () => deleteProposal(insurancePolicyProposal),
        canSendEmail: fromAdmin,
        sendEmailOnClick: openEmailModal
      }
    });

  if (isClosed && selectedLeadQuotation) {
    object.selectedLeadQuotation = infoCardElement({
      file: true,
      link: true,
      item: {
        title: 'Cotización seleccionada',
        name: selectedLeadQuotation.quotation?.filename || 'Sin cotización',
        url: selectedLeadQuotation.quotation?.fileUrl
      }
    });

    object.selectedLeadAcceptance = infoCardElement({
      file: true,
      link: true,
      item: {
        title: 'Aprobación del cliente',
        name: selectedLeadQuotation.acceptance.filename,
        url: selectedLeadQuotation.acceptance.fileUrl
      }
    });
  }

  return object;
};

export default leadCoverageObject;
