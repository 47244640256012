import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconBtn, IconGG } from '..';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';

const LoginNavbar = ({ showRegister }) => {
  const history = useHistory();
  const { type, translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${type === 'company' ? 'asegurado' : translateType}/landing_contact`);
  const goBack = () => history.goBack();

  return (
    <div className="login-navbar">
      <IconBtn onClick={goBack} variant="transparent" className="login-navbar--back-btn white">
        <IconGG icon="chevron-left" />
        <span className="login-navbar--back-btn__text ">Volver</span>
      </IconBtn>
      {showRegister && (
        <IconBtn onClick={() => navigateToContact()} className="login-navbar--request-register-btn rounded-border">
          {type === 'insured' ? 'Quiero asesoría GRATIS' : 'Empieza GRATIS'}
        </IconBtn>
      )}
    </div>
  );
};

export default LoginNavbar;
