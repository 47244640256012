import React, { useContext, useEffect, useState } from 'react';
import { Col, FormControl, Form, Row, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import snakeCaseKeys from 'snakecase-keys';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';

import { IndexFiltersContext } from '../../Shared';
import DatatableMainInfo from './DatatableMainInfo';
import DataTableBadges from './DataTableBadges';
import DataTableTooltipHeaderName from './DataTableTooltipHeaderName';

const ComponentDataTable = ({
  columns,
  customParams,
  dateFromField,
  dateToField,
  defaultParams,
  handleSortCase,
  moreData,
  noSubHeader = false,
  rangePicker,
  resourceRequest,
  withHeader,
  ...props
}) => {
  const getDefaultProps = ComponentDataTable.defaultProps;
  const getDefaultParams = { ...getDefaultProps.defaultParams, ...defaultParams };

  const { filterStatus } = useContext(IndexFiltersContext);
  const [displayLength, setDisplayLength] = useState(getDefaultParams.displayLength);
  const [displayStart, setDisplayStart] = useState(getDefaultParams.displayStart);
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0);
  const [sortColumn, setSortColumn] = useState(getDefaultParams.sortColumn);
  const [sortDirection, setSortDirection] = useState(getDefaultParams.sortDirection);
  const [startDate, setStartDate] = useState('');
  const [textInput, setTextInput] = useState('');

  const setRequest = (params, sortedColumn = false) => {
    let sortColumnCase = { sort_column: sortColumn };
    let dateFilter = { [dateFromField]: startDate, [dateToField]: endDate };
    if (rangePicker && startDate && endDate) {
      dateFilter = { date_range: [startDate, endDate] };
    }
    if (handleSortCase) {
      sortColumnCase = handleSortCase(sortColumn);
    }

    let newParams = { ...sortColumnCase, ...params };
    if (sortedColumn) newParams = { ...newParams, ...sortColumnCase };

    resourceRequest(
      {
        query: textInput,
        ...snakeCaseKeys({ displayLength, displayStart, sortDirection }),
        ...dateFilter,
        ...sortColumnCase,
        ...moreData,
        ...params,
        ...customParams
      },
      newParams
    );
  };

  const onChangePage = pageValue => {
    if (pageValue === page) return;
    const newDisplayStart = (pageValue - 1) * displayLength;
    setDisplayStart(newDisplayStart);
    setRequest({ display_start: newDisplayStart });
  };

  const onChangeRowsPage = RowsPageValue => {
    setDisplayLength(RowsPageValue);
    setRequest({ display_start: 0, display_length: RowsPageValue });
  };

  const handleSort = (colValue, dirValue) => {
    setDisplayStart(0);
    setSortColumn(colValue.selector);
    setSortDirection(dirValue);
    setRequest({ display_start: 0, sort_direction: dirValue }, true);
  };

  const handleRowSelection = (selection, handleSelect) => {
    if (!handleSelect) return;
    handleSelect(selection.selectedRows);
  };

  const handleCurrentPage = () => setPage(displayStart / displayLength + 1);

  const handleUpdateDefaultParams = () => {
    if (!filterStatus || filterStatus.isAnUpdate) return;
    setDisplayLength(getDefaultParams.displayLength);
    setDisplayStart(getDefaultParams.displayStart);
    setSortColumn(getDefaultParams.sortColumn);
    setSortDirection(getDefaultParams.sortDirection);
  };

  useEffect(handleUpdateDefaultParams, [filterStatus]);
  useEffect(handleCurrentPage, [displayLength, displayStart]);
  useEffect(setRequest, [moreData, startDate, endDate, textInput, sortColumn, customParams]);

  const { onRequest, data, totalRows, withDates, handleSelect, customDatatableHeader } = props;

  return (
    <div className="mt-4 custom-datatable" style={{ position: 'relative' }}>
      {onRequest && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <DataTable
        pagination
        paginationDefaultPage={page}
        noDataComponent={onRequest ? '' : 'No se encontraron datos.'}
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={displayLength}
        paginationRowsPerPageOptions={[15, 30, 50]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de'
        }}
        sortServer
        onRowSelected={memoize(selection => handleRowSelection(selection, handleSelect))}
        onSort={(colValue, dirValue) => handleSort(colValue, dirValue)}
        sortFunction={a => a}
        noHeader
        subHeader={!noSubHeader}
        disabled={onRequest}
        columns={columns}
        data={data}
        subHeaderComponent={
          <Col className="p-0">
            {customDatatableHeader || (
              <Row className="datatable-sub-header">
                {withDates && (
                  <Col xs={12} lg={4}>
                    <Row>
                      <Col>
                        <Form.Group className="form-datepicker date-input">
                          <DatePicker
                            placeholderText="Desde"
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            showYearDropdown
                            selected={startDate}
                            onChange={date => {
                              setStartDate(date);
                              setDisplayStart(0);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="form-datepicker date-input">
                          <DatePicker
                            placeholderText="Hasta"
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            showYearDropdown
                            selected={endDate}
                            onChange={date => {
                              setEndDate(date);
                              setDisplayStart(0);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col lg={3}>
                  <Form.Group className="form-input">
                    <FormControl
                      placeholder="Buscar"
                      name="textInput"
                      value={textInput}
                      onChange={e => {
                        setDisplayStart(0);
                        setTextInput(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Col>
        }
        {...props}
      />
    </div>
  );
};

ComponentDataTable.propTypes = {
  customParams: PropTypes.instanceOf(Object),
  dateFromField: PropTypes.string,
  dateToField: PropTypes.string,
  defaultParams: PropTypes.instanceOf(Object),
  moreData: PropTypes.bool,
  onRequest: PropTypes.bool,
  resourceRequest: PropTypes.func,
  totalRows: PropTypes.number
};

ComponentDataTable.defaultProps = {
  customParams: {},
  dateFromField: 'date_from',
  dateToField: 'date_to',
  defaultParams: { displayLength: 30, displayStart: 0, sortColumn: 'created_at', sortDirection: 'desc' },
  moreData: false,
  onRequest: false,
  resourceRequest: () => null,
  totalRows: 0
};

export default ComponentDataTable;
export { DatatableMainInfo, DataTableBadges, DataTableTooltipHeaderName };
