import React from 'react';
import PropTypes from 'prop-types';
import { ButtonTooltip } from '../Tooltips';

const DataTableTooltipHeaderName = ({ tooltipText, headerName }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="mr-2" data-tip data-for={`registerTip-datatable-header-name-${tooltipText}`}>
        {headerName}
      </span>
      {tooltipText && <ButtonTooltip tooltipText={tooltipText}>?</ButtonTooltip>}
    </div>
  );
};

export default DataTableTooltipHeaderName;

DataTableTooltipHeaderName.propTypes = {
  headerName: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired
};
