import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import { validateToken } from '../../../actions/auth';
import { setSidebarShow } from '../../../actions/utils';
import { Can } from '../../../config/abilityContext';
import { redirectTo } from '../../../services/utils';
import { LinkBtn } from '../Button';
import AdminSidebarLinks from './AdminSidebarLinks';
import AdminLandingSidebarLinks from './AdminLandingSidebarLinks';
import InsuredSidebarLinks from './InsuredSidebarLinks';
import SidebarLinks from './SidebarLinks';
import AdminStandardSidebarLinks from './AdminStandardSidebarLinks';
import InsuredGammaSidebarLinks from './InsuredGammaSidebarLinks';

const Sidebar = ({ userModule, insuredType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;
  const { user } = useSelector(state => state.auth);
  const {
    uiComponents: { showSidebar }
  } = useSelector(state => state.utils);

  const roles = {
    broker: {
      name: 'Corredor',
      module: 'Plataforma corredor',
      homePath: '/home',
      SidebarComponent: SidebarLinks,
      customSidebarClassName: 'broker-colors',
      can: { changeModule: true }
    },
    insured_gamma: {
      name: 'Asegurado',
      module: 'Plataforma asegurado',
      homePath: '/insured/home',
      SidebarComponent: InsuredGammaSidebarLinks,
      customSidebarClassName: 'insured-colors',
      can: { changeModule: false }
    },
    insured_delta: {
      name: 'Asegurado',
      module: 'Plataforma asegurado',
      homePath: '/insured/home',
      SidebarComponent: InsuredSidebarLinks,
      customSidebarClassName: 'insured-colors',
      can: { changeModule: false }
    },
    admin: {
      name: 'Administrador',
      module: 'Plataforma administrador',
      homePath: '/admin/home',
      SidebarComponent: AdminSidebarLinks,
      customSidebarClassName: 'admin-colors',
      can: { changeModule: true }
    },
    admin_standard: {
      name: 'Administrador',
      module: 'Plataforma administrador',
      homePath: '/admin/home',
      SidebarComponent: AdminStandardSidebarLinks,
      customSidebarClassName: 'admin-colors',
      can: { changeModule: false }
    },
    admin_landing: {
      name: 'Administrador',
      module: 'Plataforma administrador',
      homePath: '/admin/home',
      SidebarComponent: AdminLandingSidebarLinks,
      customSidebarClassName: 'admin-colors',
      can: { changeModule: false }
    }
  };

  // BUTTON TO CHANGE THE MODULE
  const { broker, admin } = roles;
  broker.changeModuleBtn = { title: admin.module, path: admin.homePath, canModule: 'Admin' };
  admin.changeModuleBtn = { title: broker.module, path: broker.homePath, canModule: 'Platform' };

  // GET CURRENT ROLE DATA
  // const currentUserOptions = roles[userModule];
  const currentUserOptions = userModule === 'insured' ? roles[`insured_${insuredType}`] : roles[userModule];
  const { can, changeModuleBtn, customSidebarClassName, SidebarComponent } = currentUserOptions;

  const isActive = path => pathname.includes(path);

  const handleCloseSidebar = (redirectToPath = '') => {
    dispatch(setSidebarShow(false));
    document.body.classList.remove('overflow-hidden');
    if (redirectToPath === pathname) window.location.reload();
    else redirectTo(history, pathname);
  };

  const handleUserTokenValidation = () => {
    if (user.id === 0) dispatch(validateToken());
  };

  useEffect(handleUserTokenValidation, []);

  return (
    <>
      <span className={`mobile-sidebar-bg ${showSidebar ? 'show' : ''}`} />
      <div className={`sidebar ${customSidebarClassName} ${showSidebar ? 'show' : ''}`}>
        <Nav>
          <SidebarComponent isActive={isActive} handleCloseSidebar={handleCloseSidebar} />
        </Nav>
        {can.changeModule && (
          <Can I="manage" a={changeModuleBtn.canModule}>
            <div className="sidebar-admin-button">
              <LinkBtn variant="primary" to={changeModuleBtn.path}>
                {changeModuleBtn.title}
              </LinkBtn>
            </div>
          </Can>
        )}
      </div>
    </>
  );
};

export default Sidebar;
