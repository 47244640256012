import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik, getIn } from 'formik';
import * as Yup from 'yup';

import '../../services/yupCustomMethods';
import {
  commonValidation,
  firstStepFields,
  insuranceItemsAttributesSchema,
  requestQuotationValidation
} from './Form/ValidationSchema';
import LeadFormFirstTab from './Form/LeadFormFirstTab';
import { StepMap } from '../Shared';
import LeadFormSecondTab from './Form/LeadFormSecondTab';

const LeadForm = ({
  errors,
  handleSubmit,
  isRenewal,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  touched,
  values,
  lead,
  onStep,
  userModule,
  ...props
}) => {
  const { action, fromAdmin, modelUrl, setIsRequested } = props;
  const [submitSchema, setSubmitSchema] = useState(false);
  const [step, setStep] = useState(1);
  const history = useHistory();

  const modelName = 'lead';
  const isNew = action === 'new';
  const isNotEdit = action !== 'edit';
  const adminAllowedToEdit = fromAdmin && action === 'edit';
  const submitText = isNotEdit ? 'Solicitar cotización' : 'Guardar cambios';
  const updatedFirstStepFields = fromAdmin ? [...firstStepFields, 'executiveManagerId'] : firstStepFields;

  const { requested } = values.lead;
  const requiredValues = !isNew || (isNew && requested);

  const handleCancelButton = () => {
    switch (action) {
      case 'edit':
        history.push(`${modelUrl}/${values.lead.id}`);
        break;
      case 'new':
        history.push(modelUrl);
        break;
      case 'requestQuotation':
        history.push(`${modelUrl}/${values.lead.id}`);
        break;
      default:
        break;
    }
  };

  const checkStep = stepFields => {
    const stepErrors = getIn(errors, modelName);
    if (!stepErrors) return true;
    const result = stepFields.filter(value => stepErrors[value]);
    return result.length === 0;
  };

  const checkFirstStep = () => {
    return checkStep(updatedFirstStepFields);
  };

  const firstStepTouch = () => {
    updatedFirstStepFields.forEach(field => setFieldTouched(`${modelName}[${field}]`, true));
  };

  const handleSubmitValues = submitType => {
    const isRequestQuotation = isNew && submitType === 'requestQuotation';
    if (isRequestQuotation) setFieldValue(`${modelName}[requested]`, isRequestQuotation);
    if (isNew) setIsRequested(isRequestQuotation);
    setSubmitSchema(true);
  };

  const handleOnSubmit = () => {
    if (submitSchema) {
      handleSubmit();
      setSubmitSchema(false);
    }
  };

  const validStep = stepToValidate => {
    const validations = { 1: checkFirstStep };
    const showErrors = { 1: firstStepTouch };
    const validation = validations[stepToValidate];
    if (validation) {
      if (!validation()) {
        const showError = showErrors[stepToValidate];
        showError();
        return false;
      }
    }
    return true;
  };

  const toggleStep = ({ back = false }) => {
    let steps = { 0: 1, 1: 2 };
    const limitStep = back ? 0 : 2;
    if (back) {
      steps = { 2: 1 };
      setStep(steps[step] || limitStep);
    }

    if (validStep(step)) {
      setStep(steps[step] || limitStep);
    }
  };

  const showSaveButton = () => {
    return isNew && (userModule === 'admin' || userModule === 'broker');
  };

  useEffect(handleOnSubmit, [submitSchema]);
  return (
    <Form>
      <StepMap steps={[1, 2]} currentStep={step} showSummaryIcon={false} />

      {step === 1 && (
        <LeadFormFirstTab
          fromAdmin={fromAdmin}
          modelName={modelName}
          requiredValues={requiredValues}
          userModule={userModule}
        />
      )}

      {step === 2 && (
        <LeadFormSecondTab
          fromAdmin={fromAdmin}
          modelName={modelName}
          isNotEdit={isNotEdit}
          requiredValues={requiredValues}
          adminAllowedToEdit={adminAllowedToEdit}
          action={action}
          userModule={userModule}
          isRenewal={isRenewal}
        />
      )}

      <Row>
        <Col md={6} lg={6} className="mt-4">
          <Row className="d-flex align-items-center">
            <Col md={4} lg={4}>
              <Button block variant="cancel" onClick={handleCancelButton}>
                Cancelar
              </Button>
            </Col>
            {step > 1 && (
              <Col md={4} lg={4}>
                <Button block variant="cancel" onClick={() => toggleStep({ back: true })}>
                  Volver
                </Button>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={6} lg={6}>
          <Row className="d-flex flex-row-reverse align-items-center">
            {step === 2 ? (
              <>
                <Col md={6} lg={6} className="mt-4">
                  <Button
                    block
                    variant="submit"
                    onClick={() => handleSubmitValues('requestQuotation')}
                    disabled={isSubmitting}
                  >
                    {submitText}
                  </Button>
                </Col>
                {showSaveButton() && (
                  <Col md={6} lg={6} className="mt-4">
                    <Button block variant="secondary" onClick={() => handleSubmitValues('new')} disabled={isSubmitting}>
                      Guardar oportunidad
                    </Button>
                  </Col>
                )}
              </>
            ) : (
              <Col md={6} lg={4} className="mt-4">
                <Button block variant="submit" onClick={toggleStep}>
                  Siguiente
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ lead }) => ({ lead });

const validationSchema = ({ action, isRequested, fromAdmin, isRenewal }) => {
  const adminAddedValidation = { executiveManagerId: Yup.string().required('Debes seleccionar un ejecutivo') };

  const renewalInsuranceItemsValidation = Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('Debes ingresar un detalle ítem')
        .max(160, 'Máx. 160 caracteres'),
      notes: Yup.string().max(1000, 'Máx. 1000 caracteres')
    })
  );

  const mixedInsuranceItemsValidation = Yup.mixed().when('dictionaryType', dictionaryType => {
    return insuranceItemsAttributesSchema(dictionaryType, true);
  });

  switch (action) {
    case 'new':
      return Yup.object().shape({
        lead: Yup.object().shape({
          ...(fromAdmin ? adminAddedValidation : {}),
          ...(isRequested ? requestQuotationValidation : commonValidation)
        })
      });
    case 'edit':
      return Yup.object().shape({
        lead: Yup.object().shape({
          ...commonValidation,
          ...(fromAdmin ? adminAddedValidation : {}),
          insuranceItemsAttributes: mixedInsuranceItemsValidation
          // coverDetail: Yup.string().required('Debes ingresar un detalle de cobertura')
        })
      });
    case 'requestQuotation':
      return Yup.object().shape({
        lead: Yup.object().shape({
          ...requestQuotationValidation,
          insuranceItemsAttributes: isRenewal ? renewalInsuranceItemsValidation : mixedInsuranceItemsValidation
          // insuranceItemsAttributes: Yup.mixed().when('dictionaryType', dictionaryType => {
          //   return insuranceItemsAttributesSchema(dictionaryType, true);
          // })
        })
      });
    default:
      return undefined;
  }
};

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(LeadForm);
