import React from 'react';

import { WhyNicoCard } from '../Shared';

const CompanyLandingBenefits = ({ company }) => {
  const { landingPrimaryColor, landingSecondaryColor } = company;

  const cards = [
    {
      icon: 'collage',
      title: 'Ordena tus seguros',
      description:
        'Haz login solo con tu e-mail, administra tus pólizas, cotiza nuevos seguros, denuncia siniestros y recibe notificaciones para que no se te pase nada.'
    },
    {
      icon: 'comment',
      title: 'Asesórate por personas',
      description:
        'La tecnología debe ser un complemento a la asesoría humana, y no un reemplazo. En NICO encuentra asesoría personalizada y especializada.'
    },
    {
      icon: 'heart',
      title: 'Cuenta con el respaldo',
      description:
        'Los seguros están para apoyarte en los momentos difíciles. Si chocas, te roban o te cae un meteorito, ahí estaremos para apoyarte.'
    }
  ];

  return (
    <div className="landing-benefits">
      <p className="landing-benefits__title">Algunos de nuestros beneficios</p>
      <div className="landing-benefits__cards">
        {cards.map((card, index) => (
          <section key={`why-nico-card-${index.toString()}`} className="landing-benefits__cards__section">
            <WhyNicoCard
              icon={card.icon}
              title={card.title}
              subtitle={card.subtitle}
              text={card.description}
              cardStyle={{ '--inline-primary-color': landingPrimaryColor }}
              iconContainerStyle={{ '--inline-secondary-color': landingSecondaryColor }}
            />
          </section>
        ))}
      </div>
    </div>
  );
};

export default CompanyLandingBenefits;
