import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';

import { useDidMountEffect } from '../../../hooks';
import { ButtonTooltip } from '../Tooltips';

const dateFormat = date => {
  if (!date) return '';
  if (date instanceof Date) return date;
  const dateArray = date.split('/');
  return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
};

const DataTableDatePicker = ({
  id,
  attribute,
  defaultValue,
  maxDate,
  minDate,
  onlyTimeSelector,
  placeholder,
  tooltipHtml,
  tooltipText,
  updateDataTable,
  updateRequest,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const handleOnChangeSelectedDate = () => {
    updateRequest({ id, attribute, value: selectedDate, updateDataTable });
  };

  useDidMountEffect(handleOnChangeSelectedDate, [selectedDate]);

  return (
    <InputGroup className="datatable-datepicker">
      <DatePicker
        locale={es}
        autoComplete="off"
        className="form-control"
        dateFormat={onlyTimeSelector ? 'h:mm aa' : 'dd/MM/yyyy'}
        showYearDropdown
        scrollableYearDropdown
        showMonthDropdown
        dropdownMode="select"
        yearDropdownItemNumber={100}
        showTimeSelect={onlyTimeSelector}
        showTimeSelectOnly={onlyTimeSelector}
        minDate={minDate ? dateFormat(minDate) : null}
        maxDate={maxDate ? dateFormat(maxDate) : null}
        placeholderText={placeholder}
        selected={dateFormat(selectedDate)}
        onChange={selectedDateValue => setSelectedDate(selectedDateValue || '')}
        popperProps={{ positionFixed: true }}
        {...props}
      />
      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
    </InputGroup>
  );
};

export default DataTableDatePicker;
