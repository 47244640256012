/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import {
  BasicActionBtns,
  BtnTooltip,
  DataTableDatePicker,
  DataTableInput,
  DatatableMainInfo,
  DataTableNumberFormat,
  DataTableSelect,
  ReadMore
} from '..';
import { statusOptions } from './attributeOptions';
import basicInfoRoles from './basicInfoRoles';
import UploadFiles from './UploadFiles';
import SendEmailBtn from './SendEmailBtn';

const columns = memoize(({ handleButtonClick, handleUpdateRequest, userModule }) => {
  const currentUserOptions = basicInfoRoles[userModule];
  const { basePath, can, isAdmin } = currentUserOptions;

  const premiumCollectionColumns = [
    {
      name: 'Cadencia',
      selector: 'cadence_type',
      sortable: true,
      grow: '1',
      cell: ({ insurancePolicyCadenceType, translatedInsurancePolicyCadenceType }) => (
        <BtnTooltip
          icon={insurancePolicyCadenceType.includes('unique') ? 'calendar-due' : 'calendar-next'}
          iconSize="xl"
          variant="transparent"
          className="black"
          tooltipText={translatedInsurancePolicyCadenceType}
        />
      )
    },
    {
      name: 'Cuenta',
      selector: 'account',
      sortable: true,
      grow: '1',
      minWidth: '250px',
      cell: ({ accountId, accountName, accountNationalIdentification }) => (
        <DatatableMainInfo
          noIcon
          linkTitle={can.redirectToAccount && `${basePath}/accounts/${accountId}`}
          title={accountName}
          subtitle={accountNationalIdentification}
        />
      )
    },
    {
      name: 'N° póliza',
      selector: 'policy_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({
        insurancePolicyId,
        insurancePolicyPolicyNumber,
        insurancePolicyValidityEnd,
        insurancePolicyValidityStart
      }) => (
        <DatatableMainInfo
          noIcon
          linkTitle={can.redirectToInsurancePolicy && `${basePath}/insurance_policies/${insurancePolicyId}`}
          title={insurancePolicyPolicyNumber}
          subtitle={`I: ${insurancePolicyValidityStart}`}
          secondarySubtitle={`V: ${insurancePolicyValidityEnd}`}
        />
      )
    },
    {
      name: 'Compañía',
      selector: 'insurance_company',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ insuranceCompanyLabel }) => insuranceCompanyLabel
    },
    {
      name: 'Código NICO',
      selector: 'nico_code',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ translatedInsurancePolicyNicoCode }) => translatedInsurancePolicyNicoCode
    },
    {
      name: 'Fecha cobranza',
      selector: 'collection_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ collectionDate }) => collectionDate
    },
    {
      name: 'Estado',
      selector: 'status',
      sortable: true,
      grow: '1',
      minWidth: '260px',
      cell: ({ id, status, translatedStatus }) =>
        isAdmin ? (
          <DataTableSelect
            id={id}
            attribute="status"
            defaultValue={status}
            options={statusOptions}
            updateRequest={handleUpdateRequest}
            updateDataTable
          />
        ) : (
          translatedStatus
        )
    },
    {
      name: 'Cuota',
      selector: 'installment_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ installmentColumn }) => installmentColumn
    }
  ];

  if (isAdmin) {
    premiumCollectionColumns.push({
      name: 'Prima calculada total bruta',
      selector: 'total_premium_calculated',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ parsedTotalPremiumCalculated }) => parsedTotalPremiumCalculated
    });
  }

  premiumCollectionColumns.push(
    {
      name: 'Prima informada total bruta',
      selector: 'total_premium_reported',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, currencyPrefix, parsedTotalPremiumReported, totalPremiumReported }) =>
        isAdmin ? (
          <DataTableNumberFormat
            id={id}
            prefix={`${currencyPrefix} `}
            attribute="total_premium_reported"
            defaultValue={totalPremiumReported}
            updateRequest={handleUpdateRequest}
            updateDataTable={false}
          />
        ) : (
          parsedTotalPremiumReported || '-'
        )
    },
    {
      name: 'N° factura',
      selector: 'invoice_number',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, invoiceNumber }) =>
        isAdmin ? (
          <DataTableInput
            id={id}
            attribute="invoice_number"
            defaultValue={invoiceNumber}
            updateRequest={handleUpdateRequest}
            updateDataTable={false}
          />
        ) : (
          invoiceNumber || '-'
        )
    },
    {
      name: 'Fecha emisión factura',
      selector: 'company_issue_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, companyIssueDate }) =>
        isAdmin ? (
          <DataTableDatePicker
            id={id}
            attribute="company_issue_date"
            defaultValue={companyIssueDate}
            updateRequest={handleUpdateRequest}
            updateDataTable={false}
          />
        ) : (
          companyIssueDate || '-'
        )
    },
    {
      name: 'Fecha pago de cliente',
      selector: 'account_payment_date',
      sortable: true,
      grow: '1',
      minWidth: '170px',
      cell: ({ id, accountPaymentDate }) =>
        isAdmin ? (
          <DataTableDatePicker
            id={id}
            attribute="account_payment_date"
            defaultValue={accountPaymentDate}
            updateRequest={handleUpdateRequest}
            updateDataTable
          />
        ) : (
          accountPaymentDate || '-'
        )
    }
  );

  if (isAdmin) {
    premiumCollectionColumns.push(
      {
        name: 'Ejecutivo',
        selector: 'executive_manager',
        sortable: true,
        grow: '1',
        minWidth: '170px',
        cell: ({ executiveManagerLabel }) => executiveManagerLabel
      },
      {
        name: 'Corredor',
        selector: 'insurance_broker',
        sortable: true,
        grow: '1.5',
        minWidth: '170px',
        cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
          <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
        )
      }
    );
  }

  premiumCollectionColumns.push({
    name: 'Comentarios',
    selector: 'comments',
    sortable: true,
    grow: '1',
    minWidth: 'auto',
    cell: ({ id, comments }) =>
      isAdmin ? (
        <ReadMore
          isEditable
          iconBtn
          title="Comentarios"
          item={{ id, attribute: 'comments', defaultValue: comments, handleUpdateRequest }}
        >
          {comments}
        </ReadMore>
      ) : (
        (comments && <ReadMore title="Comentarios">{comments}</ReadMore>) || '-'
      )
  });

  if (isAdmin) {
    premiumCollectionColumns.push(
      {
        grow: '1',
        minWidth: 'auto',
        cell: ({ insurancePolicyCategory, insurancePolicyId }) => (
          <>
            {insurancePolicyCategory === 'life' && (
              <BtnTooltip
                icon="notes"
                iconSize="sm"
                variant="transparent"
                className="black"
                tooltipText="Ir a nómina"
                to={`/admin/insurance_policies/${insurancePolicyId}/beneficiaries`}
              />
            )}
          </>
        )
      },
      {
        grow: '1',
        minWidth: 'auto',
        cell: item => <UploadFiles item={item} updateRequest={handleUpdateRequest} />
      },
      {
        grow: '1',
        minWidth: 'auto',
        cell: item => (
          // <BtnTooltip
          //   icon="mail"
          //   iconSize="sm"
          //   variant="transparent"
          //   className="black"
          //   tooltipText="Enviar correo"
          //   onClick={() => handleButtonClick(item, 'email')}
          // />
          <SendEmailBtn collectionItem={item} />
        )
      },
      {
        grow: '1',
        minWidth: 'auto',
        cell: item => <BasicActionBtns withDestroy transparentStyle item={item} clickHandler={handleButtonClick} />
      }
    );
  }

  return premiumCollectionColumns;
});

export default columns;
