import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { IndexFiltersProvider } from '../../../components/Shared';
import { useIndexFilters } from '../../../hooks';
import { updateAdminAccountRequest, showAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { redirectTo, scrollToTop } from '../../../services/utils';
import basicAccount from '../../Account/basicAccount';
import AdminAccountForm from './AdminAccountForm';

const AdminAccountEdit = ({ match }) => {
  const modelUrl = '/admin/accounts';

  const { id } = match.params;
  const accountShowPath = `${modelUrl}/${id}`;

  const { filterStatus } = useIndexFilters({ originPath: accountShowPath });

  const [account, setAccount] = useState(basicAccount);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Cuentas', onClick: () => redirectTo(history, modelUrl, filterStatus) },
    { key: 2, name: 'Detalle de cuenta', onClick: () => redirectTo(history, accountShowPath, filterStatus) },
    { key: 3, name: 'Editar cuenta' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cuenta actualizada con éxito' }));
    redirectTo(history, accountShowPath, filterStatus);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    updateAdminAccountRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const fetchAccount = () => {
    showAdminAccountRequest(id, {
      dispatch,
      successCallback: response => setAccount(camelCaseRecursive(response.data))
    });
  };

  useEffect(scrollToTop, []);
  useEffect(fetchAccount, []);

  return (
    <IndexFiltersProvider value={filterStatus}>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={
          <AdminAccountForm
            action="edit"
            account={account}
            formRequest={handleUpdateRequest}
            handleCancelButton={() => redirectTo(history, accountShowPath, filterStatus)}
          />
        }
      />
    </IndexFiltersProvider>
  );
};

export default AdminAccountEdit;
