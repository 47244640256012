import React from 'react';
import { getIn } from 'formik';
import { useFetchForRemoteSelect } from '../../hooks';
import { InputRemoteSelect } from '../../components';
import { debounceIndexAdminCompaniesRequest, indexAdminCompanyRequest } from '../../requests/admin/companies';

const InsuranceBrokerCompanyRemoteSelect = ({
  field,
  abbr,
  isClearable,
  label,
  placeholder,
  onChange,
  error,
  touched,
  values,
  modelName
}) => {
  const { options, selectedOption, fetchOptions } = useFetchForRemoteSelect({
    indexRequest: indexAdminCompanyRequest,
    debouncedIndexRequest: debounceIndexAdminCompaniesRequest,
    value: getIn(values, `${modelName}[companyId]`)
  });

  return (
    <InputRemoteSelect
      {...field}
      abbr={abbr}
      isClearable={isClearable}
      label={label}
      placeholder={placeholder}
      defaultOptions={options}
      value={selectedOption}
      onChange={onChange}
      request={fetchOptions}
      error={error}
      touched={touched}
    />
  );
};

export default InsuranceBrokerCompanyRemoteSelect;
