import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import UserForm from './UserForm';
import { createUserRequest } from '../../requests/user';
import { FormHeader } from '../../components';
import { sendAlert } from '../../actions/utils';
import basicUser from './basicUser';

const UserNew = () => {
  const modelUrl = '/users';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Corredor creado con éxito' }));
    history.push(`/users`);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createUserRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  const handleBreadcrumb = [
    { key: 1, name: 'Usuarios', href: modelUrl },
    { key: 2, name: 'Crear nuevo usuario' }
  ];

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <UserForm action="new" user={basicUser} formRequest={handleCreateRequest} submitVariant="submit" />
      }
    />
  );
};

export default UserNew;
