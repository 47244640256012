import React from 'react';
import MultiLandingMain from '../../components/Landing/MultiLanding/MultiLandingMain';
import { MultiLandingNavbar } from '../../components/Landing/Shared';

const MultiLanding = () => {
  return (
    <div className="landing">
      <MultiLandingNavbar />
      <MultiLandingMain />
      {/* <LandingCenteredContent title={needABrokerMainTitle} btnText="Necesito un corredor" to="/client" /> */}
    </div>
  );
};

export default MultiLanding;
