import React from 'react';
import { useSelector } from 'react-redux';

import { LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';
import Blog from '../../components/Shared/Blog';

const InsuredBlog = () => {
  const { settings } = useSelector(state => state.utils);

  return (
    <WebLayout type="insured">
      <Blog />
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default InsuredBlog;
