import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminPaymentPlanRequest } from '../../../requests/admin/paymentPlans';
import AdminPaymentPlanForm from './AdminPaymentPlanForm';
import basicPaymentPlan from './basicPaymentPlan';

const AdminPaymentPlanNew = () => {
  const modelUrl = '/admin/payment_plans';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Planes de pago', href: modelUrl },
    { key: 2, name: 'Crear nuevo plan de pago' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'plan de pago creado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createAdminPaymentPlanRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <AdminPaymentPlanForm action="new" paymentPlan={basicPaymentPlan} formRequest={handleCreateRequest} />
      }
    />
  );
};

export default AdminPaymentPlanNew;
