const colorStyles = {
  control: base => ({
    ...base,
    fontSize: '1.3rem',
    backgroundColor: 'white',
    borderRadius: '2rem',
    minHeight: '5rem',
    height: '5rem',
    paddingLeft: '2rem',
    cursor: 'pointer'
  }),
  menu: base => ({
    ...base,
    fontSize: '1.3rem',
    backgroundColor: '#FFF',
    zIndex: 99999999
  }),
  option: (base, { isFocused }) => {
    return {
      ...base,
      backgroundColor: isFocused ? '#ACE1DB' : 'white',
      color: '#333333'
    };
  },
  multiValueLabel: base => ({
    ...base,
    backgroundColor: '#1f2e4f',
    color: 'white',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px'
  }),
  multiValueRemove: base => ({
    ...base,
    backgroundColor: '#1f2e4f',
    color: 'white',
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    transition: '.2s',
    '&:hover': {
      backgroundColor: '#e9e9e9',
      color: '#1f2e4f'
    }
  })
};

export default colorStyles;
