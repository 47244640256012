import React from 'react';
import { useHistory } from 'react-router-dom';
import { WhyNicoCard } from '../Shared';
import { IconBtn } from '../../Utils/Button';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingWhyNico = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <section id="why-nico" className="landing--why-nico__container">
      <div className="landing--why-nico__tittlecontainer">
        <h2 className="landing--why-nico__title landing-section-title font-weight-900 text-dark-blue">
          {' '}
          <br />
          Opera tu cartera como nunca antes, <br />
          con todos los servicios que NICO hace por ti <br />
        </h2>
      </div>

      <div className="landing--why-nico landing--why-nico__content landing--why-nico landing--why-nico__margin">
        <WhyNicoCard
          icon="file-document"
          title="Cotiza"
          subtitle="Ingresa oportunidades"
          text="NICO cotiza, negocia y te entrega las mejores alternativas de 29 compañías de seguros."
        />
        <WhyNicoCard
          icon="dollar"
          title="Vende"
          subtitle="Cierra la venta"
          text="NICO arma la propuesta, trabaja con la compañía para emitir y carga la póliza."
        />
        <WhyNicoCard
          icon="sync"
          title="Renueva"
          subtitle="Sigue protegiendo"
          text="NICO te avisa con 60 días de anticipación y acompaña en todo el proceso."
        />
        <WhyNicoCard
          icon="copy"
          title="Actualiza"
          subtitle="Genera endosos"
          text="NICO arma la propuesta, trabaja con la compañía para emitir y carga el endoso."
        />
      </div>

      <div className="landing--why-nico landing--why-nico__content mb-5">
        <WhyNicoCard
          icon="lock"
          title="reasegura"
          subtitle="Proteje riesgos complejos"
          text="NICO tiene convenios de reaseguro para encontrar coberturas grandes o complejas."
        />
        <WhyNicoCard
          icon="flag-alt"
          title="defiende"
          subtitle="Guía los siniestros"
          text="NICO te asesora, interactúa con la compañía y el liquidador, y protege a tu cliente."
        />
        <WhyNicoCard
          icon="mail-open"
          title="cobra"
          subtitle="Informa las deudas"
          text="NICO cuadra con todas las compañías, te informa a ti y cobra a tus clientes."
        />
        <WhyNicoCard
          icon="list"
          title="gana"
          subtitle="Ordena tus ingresos"
          text="NICO cobra a las compañías y te reporta al detalle. Solo facturas a NICO una vez al mes."
        />
      </div>

      <div className="d-flex justify-content-center">
        <IconBtn
          onClick={() => navigateToContact()}
          className="main-button btn-landing w-mobile-100"
          variant="secondary"
        >
          Empieza GRATIS
        </IconBtn>
      </div>
    </section>
  );
};

export default BrokerLandingWhyNico;
