import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../actions/utils';
import InfoBox from '../components/Shared/InfoBox';
import { DefaultModal } from '../components/Utils/Modal';
import { lowerCaseTextFormat } from '../services/utils';

const useDestroyOne = ({ deleteRequest, parentId, setMoreData, ...props }) => {
  const { infoText, modelName, nameEndsInA, redirectToUrl } = props;
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  // messages
  const modalTitle = `Eliminar ${lowerCaseTextFormat(modelName)}`;
  const text = `Estás por eliminar un${nameEndsInA ? 'a' : ''} ${lowerCaseTextFormat(modelName)}`;
  const confirmationText = '¿estás seguro de querer hacerlo?';
  const btnTitleSave = `Eliminar ${lowerCaseTextFormat(modelName)}`;
  const successAlertMessage = `${modelName} eliminad${nameEndsInA ? 'a' : 'o'} con éxito`;

  const handleModalClose = () => setModalShow(false);

  const handleSuccessRequest = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: successAlertMessage }));
    if (redirectToUrl) history.push(redirectToUrl);
    else setMoreData(m => !m);
  };

  const deleteElementRequest = id => {
    if (parentId) {
      deleteRequest(parentId, id, {
        dispatch,
        successCallback: () => handleSuccessRequest(id),
        callback: handleModalClose
      });
    } else {
      deleteRequest(id, {
        dispatch,
        successCallback: () => handleSuccessRequest(id),
        callback: handleModalClose
      });
    }
  };

  const handleModalConfirm = () => deleteElementRequest(modalItem.id);

  const handleShowModal = item => {
    const bodyContent = (
      <>
        {infoText && <InfoBox icon="danger" variant="warning" text={infoText} />}
        {text && <p className="mb-3">{text},</p>}
        <p>{confirmationText}</p>
      </>
    );
    setModalShow(true);
    setModalItem(item);
    setModalBody(bodyContent);
  };

  const OnDestroyModalComponent = () => (
    <DefaultModal
      withButtons
      size="sm"
      title={modalTitle}
      body={modalBody}
      show={modalShow}
      titleBtnSave={btnTitleSave}
      variantBtnSave="strong-danger"
      handleClose={handleModalClose}
      handleConfirm={handleModalConfirm}
    />
  );

  return { OnDestroyModalComponent, handleShowModal };
};

export default useDestroyOne;
