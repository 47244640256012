import React from 'react';
import { ItemNestedAttributes } from '../../InsurancePolicies';

const SubjectMatterSection = ({ modelName, action, isRenewal }) => {
  return (
    <section className="form-section mb-5 w-100">
      <p className="section-title">Información de la Materia a asegurar</p>

      <ItemNestedAttributes modelName={modelName} action={action} isRenewal={isRenewal} isLead />
    </section>
  );
};

export default SubjectMatterSection;
