import * as Yup from 'yup';
import moment from 'moment';

import { licensePlateRegex, isValidRut, emailRegex, validPhoneInput } from './utils';

Yup.addMethod(Yup.string, 'emailOrRut', function emailRutValidate(message) {
  return this.test('test-name', message, function validLogin(value) {
    const { path, createError } = this;
    if (!value) {
      return false;
    }
    if (value.indexOf('@') !== -1) {
      // email validation
      if (emailRegex.test(value)) {
        return true;
      }
      if (value.length >= 4) {
        return createError({
          path,
          message: 'El email que ingresaste no es válido'
        });
      }
    } else {
      // rut validation
      if (isValidRut(value)) {
        return true;
      }
      const rut = value
        .replace(/\s/g, '')
        .replace(/\./g, '')
        .replace(/-/g, '');
      if (rut.length >= 4 && !Number.isNaN(Number(rut))) {
        return createError({
          path,
          message: 'El RUT que ingresaste no es válido'
        });
      }
    }
    return false;
  });
});

Yup.addMethod(Yup.string, 'rut', function rutValidate(message) {
  return this.test('test-name', message, function validRut(value) {
    // rut validation
    if (value && isValidRut(value)) {
      return true;
    }
    return false;
  });
});

Yup.addMethod(Yup.string, 'optionalrut', function rutValidate(message) {
  return this.test('test-name', message, function validRut(value) {
    // rut validation
    if (value && isValidRut(value)) {
      return true;
    }
    if (!value) return true;
    return false;
  });
});

Yup.addMethod(Yup.string, 'phone', function phoneValidate(message) {
  return this.test('test-name', message, function validPhone(value) {
    // phone validation
    if ((value && validPhoneInput(value)) || value === undefined) {
      return true;
    }
    return false;
  });
});

Yup.addMethod(Yup.date, 'formatdate', function dateToMomentFormat() {
  return this.transform(function formatMoment(value, originalValue) {
    const newValue = moment(originalValue, 'DD/MM/YYYY');
    return newValue.isValid() ? newValue.toDate() : new Date('');
  });
});

Yup.addMethod(Yup.string, 'licensePlate', function licensePlateValidate(message) {
  return this.test('test-name', message, function validLicensePlate(value) {
    return licensePlateRegex.test(value);
  });
});
