import React from 'react';
import { useSelector } from 'react-redux';
import { IconBtn } from '../Utils/Button';

const NotificationsButton = ({ handleOpenNotifications }) => {
  const { notifications = {} } = useSelector(state => state.utils);
  const { unread: unreadNotificationsCounter } = notifications;

  return (
    <div className="navbar-top--button notification-button">
      <IconBtn variant="primary-dark" onClick={handleOpenNotifications} icon="bell" />
      {unreadNotificationsCounter > 0 && (
        <span className="notification-button--badge">{unreadNotificationsCounter}</span>
      )}
    </div>
  );
};

export default NotificationsButton;
