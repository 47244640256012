import infoCardElement from '../../Utils/Cards/InfoCardMethods';
import { legalPersonTypeOptions } from '../../../screens/Account/AccountFiltersParams';

const accountObject = ({ account, userModule }) => {
  const {
    industryName,
    legalPersonType,
    nationalIdentification,
    translatedClientType,
    executiveManagerLabel
  } = account;

  const legalPersonTypeLabel = legalPersonTypeOptions.find(legalPerson => legalPerson.value === legalPersonType)?.label;

  const object = {};

  object.nationalIdentification = infoCardElement({
    string: true,
    key: 'RUT',
    value: nationalIdentification
  });
  object.legalPersonType = infoCardElement({
    string: true,
    key: 'Persona legal',
    value: legalPersonTypeLabel
  });
  object.clientType = infoCardElement({
    string: true,
    key: 'Tipo de Cliente',
    value: translatedClientType
  });
  if (legalPersonType === 'legal')
    object.industryName = infoCardElement({
      string: true,
      key: 'Rubro',
      value: industryName || 'No especificado'
    });

  if (['admin', 'broker'].includes(userModule))
    object.executiveManager = infoCardElement({
      string: true,
      key: 'Ejecutivo',
      value: executiveManagerLabel
    });

  return object;
};

export default accountObject;
