import { basicAddress } from '../../components';

const basicContact = {
  name: '',
  address: '',
  isInsuredUser: false,
  phoneNumber: '',
  email: '',
  comments: '',
  position: '',
  birthday: '',
  fullAddress: '',
  addressAttributes: {
    ...basicAddress
  }
};

export default basicContact;
