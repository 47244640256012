import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { DefaultHeader } from '../../../components';
import { InsuredContactForm } from '../../../components/Shared';
import sendContactEmailRequest from '../../../requests/insured/insuredSharedEndpoints';
import { redirectTo, scrollToTop } from '../../../services/utils';
import { fieldOptions, insuredContactAttributes } from './basicOptions';

const InsuredContactIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSendContactEmailRequest = (values, setSubmitting) => {
    sendContactEmailRequest({
      dispatch,
      params: { type: 'contact', ...snakecaseKeys(values) },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Correo enviado con éxito.' }));
        redirectTo(history, '/insured/home');
      },
      callback: () => setSubmitting(false)
    });
  };

  useEffect(scrollToTop, []);

  return (
    <DefaultHeader title="Contacto">
      <HeaderSubtitle />
      <InsuredContactForm
        fromIndex="contact"
        insuredContact={insuredContactAttributes}
        fieldOptions={fieldOptions}
        formRequest={handleSendContactEmailRequest}
      />
    </DefaultHeader>
  );
};

const HeaderSubtitle = () => {
  const elements = [
    'Entender una póliza',
    'Proteger algo o a alguien',
    'Cotizar un seguro',
    'Denunciar un siniestro',
    'O simplemente necesitas hablar con alguien experto en seguros'
  ];

  return (
    <>
      <p className="mt-5 section-title font-weight-500">
        Estamos siempre disponibles para protegerte y apoyarte en lo que necesites. Si buscas ayuda para:
      </p>

      {elements.map((element, index) => (
        <p className="bullet-element" key={`list-${index.toString()}`}>
          {element}
        </p>
      ))}

      <p className="mt-3 mb-5 section-title font-weight-500">Escríbenos y con gusto te ayudaremos</p>
    </>
  );
};

export default InsuredContactIndex;
