import ApiService from '../../services/apiService';

export const showInsuredInsuranceEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insured/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createInsuredInsuranceEndorsementRequest = (
  insurancePolicyId,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('post', `/insured/insurance_policies/${insurancePolicyId}/insurance_endorsements`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateInsuredInsuranceEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insured/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteInsuredInsuranceEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/insured/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const exportInsuredInsuranceEndorsementSummary = (
  insurancePolicyId,
  insuranceEndorsementId,
  { dispatch, params, successCallback, callback, failureCallback }
) =>
  ApiService.request(
    'get',
    `/insured/insurance_policies/${insurancePolicyId}/insurance_endorsements/${insuranceEndorsementId}/endorsement_summary`,
    {
      dispatch,
      params,
      responseType: 'text',
      successCallback,
      callback,
      failureCallback
    }
  );

export const updateInsuredCompanyInfoEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request(
    'put',
    `/insured/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}/update_company_info`,
    {
      dispatch,
      params,
      formData,
      successCallback,
      failureCallback,
      callback
    }
  );
