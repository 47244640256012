import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import RemoteSelectFilter from './RemoteSelectFilter';
import SimpleSelectFilter from './SimpleSelectFilter';
import SearchInputFilter from './SearchInputFilter';

const AdvancedFilters = ({
  customParams = {},
  filtersOptions = [],
  margin,
  remoteSelectors = [],
  setCustomParams = () => null,
  simpleSelectors = [],
  searchOptions = []
}) => {
  const handleCheckBoxChange = e => {
    const { name, checked } = e.target;
    setCustomParams(params => ({ ...params, [name]: checked }));
  };

  return (
    <Row className={`advanced-filters--container d-flex align-items-center ${margin} remote-select`}>
      {remoteSelectors.map((selector, index) => (
        <Col
          md={6}
          lg={selector.colSizeClass ? '' : 3}
          key={`remote-select-${index.toString()}`}
          className={`${selector.colSizeClass || ''}`}
        >
          <RemoteSelectFilter
            classNamePrefix="react-select"
            fieldName={selector.fieldName}
            placeholder={selector.placeholder}
            indexRequest={selector.indexRequest}
            debouncedIndexRequest={selector.debounceRequest}
            requestParams={selector.requestParams}
            isMulti={selector.isMulti}
            customParams={customParams}
            setCustomParams={setCustomParams}
          />
        </Col>
      ))}
      {simpleSelectors.map((selector, index) => (
        <Col
          md={6}
          lg={selector.colSizeClass ? '' : 3}
          key={`simple-select-${index.toString()}`}
          className={`${selector.colSizeClass || ''}`}
        >
          <SimpleSelectFilter
            classNamePrefix="react-select"
            fieldName={selector.fieldName}
            placeholder={selector.placeholder}
            defaultValue={selector.defaultValue}
            options={selector.options}
            isMulti={selector.isMulti}
            setCustomParams={setCustomParams}
          />
        </Col>
      ))}
      {filtersOptions.map(item => (
        <Form.Group controlId={item.value} className="form-checkbox pt-4" key={item.value}>
          <Form.Check custom type="checkbox" name={item.value} onChange={handleCheckBoxChange} />
          <Form.Label className={`m-0 ${customParams[item.value] ? 'checked' : ''}`}>
            <p className="font-weight-100 m-0">{item.label || item.value}</p>
          </Form.Label>
        </Form.Group>
      ))}

      {searchOptions.map((searchItem, index) => (
        <Col md={6} lg={3} key={`search-input-${index.toString()}`} style={searchItem.style}>
          <SearchInputFilter searchItem={searchItem} setCustomParams={setCustomParams} customParams={customParams} />
        </Col>
      ))}
    </Row>
  );
};

AdvancedFilters.propTypes = {
  customParams: PropTypes.instanceOf(Object).isRequired,
  filtersOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  margin: PropTypes.string,
  remoteSelectors: PropTypes.instanceOf(Array),
  setCustomParams: PropTypes.func.isRequired
};

AdvancedFilters.defaultProps = {
  margin: '',
  remoteSelectors: []
};

export default AdvancedFilters;
