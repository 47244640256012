import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import { InfoCardInsuranceItem } from '../../Shared';
import { capitalizeFirstLetter } from '../../../services/utils';

const InsuranceEndorsementItemsChanges = ({ insuranceItem }) => {
  const { values } = useFormikContext();
  const { endorsementAction, endorsementType } = values.insuranceEndorsement;

  const isAdd = endorsementAction === 'add';
  const isModify = endorsementAction === 'modify';
  const isRemove = endorsementAction === 'remove';
  const isModification = endorsementType === 'modification';
  const amountTitle = capitalizeFirstLetter(`${isModify ? 'Nuevo ' : ''}monto asegurado`);
  const netPremiumTitle = capitalizeFirstLetter(`${isModify || isRemove ? 'Nueva ' : ''}prima neta`);

  const coverColumns = [
    { columnName: 'N°', value: insuranceItem.value, style: { width: '50px' } },
    { columnName: 'Detalle cobertura', selector: 'name' },
    { columnName: amountTitle, selector: 'parsedAmount', style: { width: '200px' } },
    { columnName: 'Variación monto asegurado', selector: 'parsedVariationAmount', style: { width: '200px' } },
    { columnName: 'Tasa', selector: 'parsedFee', style: { width: '100px' } },
    { columnName: netPremiumTitle, selector: 'parsedNetPremium', style: { width: '150px' } },
    { columnName: 'Variación prima neta', selector: 'parsedVariationNetPremium', style: { width: '150px' } },
    { columnName: 'Tipo', selector: 'coverageTypeLabel', style: { width: '100px' } }
  ];

  if (!isModify) delete coverColumns[3];
  if (isAdd || isModification) delete coverColumns[6];

  return (
    <Row className="mt-5">
      <Col md={12}>
        <p className="section-title mb-5">Cambios en ítem seleccionado</p>
        <InfoCardInsuranceItem insuranceItem={insuranceItem} itemNumber={1} coverColumns={coverColumns} />
      </Col>
    </Row>
  );
};

export default InsuranceEndorsementItemsChanges;
