import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminInsuranceCompanyRequest } from '../../../requests/admin/insuranceCompanies';
import AdminInsuranceCompanyForm from './AdminInsuranceCompanyForm';
import basicInsuranceCompany from './basicInsuranceCompany';

const AdminInsuranceCompanyNew = () => {
  const modelUrl = '/admin/insurance_companies';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Aseguradoras', href: modelUrl },
    { key: 2, name: 'Crear nueva aseguradora' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Aseguradora creada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const { logo } = values.insuranceCompany;
    const sendParams = snakeCaseKeys(values);
    sendParams.insurance_company.logo = logo;
    createAdminInsuranceCompanyRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <AdminInsuranceCompanyForm
          action="new"
          insuranceCompany={basicInsuranceCompany}
          formRequest={handleCreateRequest}
        />
      }
    />
  );
};

export default AdminInsuranceCompanyNew;
