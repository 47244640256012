import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const additionalDocumentObject = ({ additionalDocuments, isEmpty }) => {
  if (isEmpty) return false;

  const object = {};

  object.additionalDocuments = infoCardElement({
    array: true,
    items: additionalDocuments.map(({ filename, fileUrl }) =>
      infoCardElement({
        file: true,
        link: true,
        item: {
          name: filename,
          url: fileUrl
        }
      })
    )
  });

  return object;
};

export default additionalDocumentObject;
