import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BrokerLandingCenteredContentImage from '../../assets/images/Landing/BrokerLanding/broker_landing_2.png';
import cmfLogo from '../../assets/images/Landing/BrokerLanding/cmf-logo.png';
import {
  BrokerLandingNumbers,
  BrokerLandingSuport,
  BrokerLandingCompanies,
  BrokerLandingMain,
  BrokerLandingKindServices,
  BrokerLandingUiPublicity,
  BrokerLandingWhyNico
} from '../../components/Landing/BrokerLanding';
import { LandingCenteredContent, LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';
import TestimonySection from '../../components/Landing/Shared/TestimonySection';
import { scrollToTop } from '../../services/utils';

const BrokerLanding = () => {
  const { settings } = useSelector(state => state.utils);
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();

  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  useEffect(scrollToTop, []);

  return (
    <WebLayout type="broker">
      <BrokerLandingMain settings={settings} />
      <BrokerLandingWhyNico settings={settings} />
      <BrokerLandingSuport settings={settings} />
      <LandingCenteredContent
        title="Estamos regulados por la CMF"
        subtitle="¿Qué esperas?"
        btnText="Empieza GRATIS"
        btnVariant="secondary"
        bgImage={BrokerLandingCenteredContentImage}
        onClick={navigateToContact}
        logo={cmfLogo}
      />
      <BrokerLandingNumbers settings={settings} />
      <BrokerLandingKindServices settings={settings} />
      <TestimonySection />
      <BrokerLandingUiPublicity settings={settings} />
      <BrokerLandingCompanies />
      {/* <LandingCenteredContent title={needABrokerMainTitle} btnText="Necesito un corredor" to="/client" /> */}
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default BrokerLanding;
