import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { BasicTextArea } from '../../../Utils/Input';

const TextAreaField = ({ keyValue, details, modelName, errors, touched, optional }) => {
  const { title: label, placeholder, required, tooltip } = details;
  return (
    <Col xs={12} md={6} className="mb-4 pl-5">
      <Field name={`${modelName}[${keyValue}]`}>
        {({ field }) => (
          <BasicTextArea
            {...field}
            abbr={optional ? false : required}
            label={label}
            placeholder={placeholder || label}
            maxLength={1000}
            rows={2}
            tooltipText={tooltip}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            className="p-3"
          />
        )}
      </Field>
    </Col>
  );
};

export default TextAreaField;
