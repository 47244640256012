import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import RemoteSelect from './RemoteSelect';
import { ButtonTooltip } from '../Tooltips';

const initialDefaultValue = props => {
  const { isMulti, model, values, value } = props;
  if (!model) return value;
  const [defaultModel, attribute] = model;
  if (values) {
    const { [`${attribute}${isMulti ? 'Ids' : 'Id'}`]: vAttributeId } = values;
    const { [`${attribute}${isMulti ? 'Ids' : 'Id'}`]: attributeId } = defaultModel;
    if (attributeId && vAttributeId && attributeId === vAttributeId) {
      const { [`${attribute}${isMulti ? 's' : ''}`]: atributes } = defaultModel;
      return atributes;
    }
    if ((isMulti && vAttributeId.length === 0) || vAttributeId === '') return null;
  }
  return undefined;
};

const InputRemoteSelect = ({
  abbr,
  className = '',
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  helpText,
  label,
  request,
  tooltipText,
  tooltipHtml,
  touched,
  ...props
}) => {
  const colourStyles = {
    control: (base, { isDisabled }) => ({
      ...base,
      fontSize: '1.3rem',
      backgroundColor: isDisabled ? '#e9e9e9' : 'white',
      borderRadius: '2rem',
      minHeight: '5rem',
      height: '5rem',
      paddingLeft: '2rem',
      cursor: 'pointer'
    }),
    menu: base => ({
      ...base,
      fontSize: '1.3rem',
      backgroundColor: '#FFF',
      zIndex: 99999999
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#ACE1DB' : 'white',
        color: '#333333'
      };
    },
    ...(tooltipText && {
      indicatorsContainer: base => ({
        ...base,
        paddingRight: '2rem'
      })
    })
  };

  return (
    <Form.Group
      className={`form-select ${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''} ${
        tooltipText ? 'with-tooltip' : ''
      }`}
    >
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <RemoteSelect
        className={`${props.isMulti ? 'basic-multi-select' : ''} ${className}`}
        value={initialDefaultValue(props)}
        loadOptions={request}
        disabled={disabled}
        styles={colourStyles}
        components={{
          IndicatorSeparator: () => null
        }}
        {...props}
      />

      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

InputRemoteSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

InputRemoteSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default InputRemoteSelect;
