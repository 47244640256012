import React from 'react';
import { useFormikContext } from 'formik';

import InsuranceEndorsementFormDate from './InsuranceEndorsementFormDate';
import InsuranceEndorsementItemForm from './InsuranceEndorsementItemForm';
import { calculateDays } from '../../../services/utils';

const InsuranceEndorsementFormAdd = ({ insurancePolicy, modelName }) => {
  const { values } = useFormikContext();
  const { endorsementDate } = values.insuranceEndorsement;
  const { validityEnd, insuranceItemsAttributes } = insurancePolicy;

  return (
    <>
      <hr className="w-100 my-5" />
      <p className="section-title">Selecciona la fecha de incorporación del ítem</p>
      <InsuranceEndorsementFormDate
        modelName={modelName}
        dateLabel="Fecha de incorporación"
        insurancePolicy={insurancePolicy}
        termDuration={calculateDays(endorsementDate, validityEnd)}
      />

      <hr className="w-100 my-5" />
      <p className="section-title">Información del nuevo ítem</p>
      <InsuranceEndorsementItemForm
        fromEndorsement
        modelName={`${modelName}[insuranceItemAttributes]`}
        insurancePolicy={insurancePolicy}
        insuranceItemsAttributes={insuranceItemsAttributes}
      />
    </>
  );
};

export default InsuranceEndorsementFormAdd;
