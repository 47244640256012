import AwesomeDebouncePromise from 'awesome-debounce-promise';

import ApiService from '../../services/apiService';

export const indexInsuredLeadRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insured/leads', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showInsuredLeadRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insured/leads/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createInsuredLeadRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/insured/leads', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexInsuredLeadRequest = AwesomeDebouncePromise(indexInsuredLeadRequest, 1000);
