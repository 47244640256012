/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../../services/yupCustomMethods';
import { FormikInput } from '../../Utils/Input';
import { rutFormat, validRutInput, handleFormatTextToLowerCase } from '../../../services/utils';

const ClientLandingForm = ({ errors, touched, setFieldValue, isValid, values, resetForm, cleanForm }) => {
  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const handleResetForm = () => {
    resetForm();
  };

  useEffect(handleResetForm, [cleanForm, resetForm]);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="register[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Email"
                placeholder="usuario@correo.com"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="register[rut]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                placeholder="00.000.000-0"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="register[uniqueKey]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Clave única"
                type="password"
                placeholder="Ingresa tu clave única"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <p className="text-center mt-1">Tranquil@, NICO no registra ni almacena tu clave única</p>
        </Col>
        <Col md={12} className="d-flex justify-content-center align-items-center">
          <Field name="register[terms]">
            {({ field }) => <FormikInput {...field} abbr label="" type="checkbox" />}
          </Field>
          <p className="ml-3 mt-2 text-dark font-weight-bold">
            Acepto <a href="/terms-service">términos y condiciones.</a>
          </p>
        </Col>
        {touched.register?.terms && errors.register?.terms && (
          <Col md={12} className="d-flex justify-content-center">
            <label htmlFor="register-terms">
              <small className="text-center text-danger">{errors.register.terms}</small>
            </label>
          </Col>
        )}

        <Col md={12} className="d-flex justify-content-center">
          <Button type="submit" className="mt-3 btn-primary" disabled={!isValid || !values?.register.terms}>
            Conocer mis seguros
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = () => ({
  register: {
    email: '',
    rut: '',
    uniqueKey: '',
    terms: false
  }
});

const validationSchema = Yup.object().shape({
  register: Yup.object().shape({
    email: Yup.string()
      .required('Debes ingresar tu correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    rut: Yup.string()
      .required('Debes ingresar tu RUT')
      .rut('El RUT es inválido'),
    uniqueKey: Yup.string().required('Debes ingresar tu clave única.'),
    terms: Yup.boolean(true).required('Debes aceptar los términos y condiciones.')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema
})(ClientLandingForm);
