import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { IconBtn } from '../Button';

const BasicCard = ({ bgColor, body, borderColor, header, image, onClick, textColor, variant }) => (
  <Card bg={bgColor} text={textColor} border={borderColor} className="basic-card h-100" onClick={onClick}>
    {image.presence && <Card.Img variant={image.direction} src={image.src} />}
    {(header.icon || header.text) && (
      <Card.Header className={`${header.className} text-${variant} bg-${bgColor} border-0`}>
        {header.icon && <IconBtn onlyIcon icon={header.icon} iconSize={header.iconSize} className={header.iconClass} />}
        {header.text && <p className="header-text">{header.text}</p>}
      </Card.Header>
    )}
    <Card.Body className={`${body.className} text-${variant}`}>
      <Card.Title className="text-uppercase mb-0">{body.title}</Card.Title>
      {body.subtitle && <Card.Subtitle className="mt-2 text-muted">{body.subtitle}</Card.Subtitle>}
      {body.text && <Card.Text className="mt-3">{body.text}</Card.Text>}
      {body.titleBtn && (
        <Button as={Link} variant={body.variantBtn} to={body.pathBtn}>
          {body.titleBtn}
        </Button>
      )}
    </Card.Body>
  </Card>
);

BasicCard.propTypes = {
  bgColor: PropTypes.string,
  body: PropTypes.objectOf(PropTypes.string),
  borderColor: PropTypes.string,
  header: PropTypes.objectOf(PropTypes.string),
  image: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  textColor: PropTypes.string,
  variant: PropTypes.string
};

BasicCard.defaultProps = {
  bgColor: 'white',
  body: {},
  borderColor: '',
  header: {},
  image: {},
  onClick: () => null,
  textColor: '',
  variant: ''
};

export default BasicCard;
