import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useFetchData } from '../../hooks';
import { formatCurrency } from '../../services/utils';
import { BtnTooltip, IconNumberCard, SimpleCenteredModal, StatisticCard } from '..';
import EmailPremiumCollectionModal from './EmailPremiumCollectionModal';

const PremiumCollectionStatisticHeader = ({ customParamsRef, headerDashboardRequest, dataTableMoreData }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const { data: headerDashboardKeys, setMoreData } = useFetchData({
    debouncedIndexRequest: headerDashboardRequest,
    customParams: { display_length: 1000, ...customParamsRef.current },
    withoutWrapper: true
  });

  const { calcularedPremium, reportedPremium, accounts, contracts, nicoCodes } = headerDashboardKeys;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleModalConfirmation = (highlightedText, requestType) => {
    setModalShow(true);
    setModalBody(
      <EmailPremiumCollectionModal
        handleModalClose={handleModalClose}
        setMoreData={setMoreData}
        highlightedText={highlightedText}
        requestType={requestType}
      />
    );
  };

  useEffect(() => setMoreData(m => !m), [dataTableMoreData]);

  return (
    <>
      <Row className="mt-4">
        <Col sm={6} md={12} lg={6} xl={4}>
          <StatisticCard
            icon="dollar"
            variant="success"
            cardVariant="mb-4"
            title="Prima calculada total neta"
            value={formatCurrency({ leftAddon: 'CLP$', value: calcularedPremium, decimals: 0 })}
          />
        </Col>
        <Col sm={6} md={12} lg={6} xl={4}>
          <StatisticCard
            icon="dollar"
            variant="success"
            cardVariant="mb-4"
            title="Prima informada total neta"
            value={formatCurrency({ leftAddon: 'CLP$', value: reportedPremium, decimals: 0 })}
          />
        </Col>
        <Col sm={6} md={12} lg={4} xl={3} className="d-flex align-items-center">
          <div>
            <BtnTooltip
              block
              icon="mail"
              iconSize="sm"
              tooltipText="Enviar cobranza clientes"
              onClick={() => handleModalConfirmation('cobranza clientes', 'clients')}
            >
              Clientes
            </BtnTooltip>
            <BtnTooltip
              block
              icon="mail"
              iconSize="sm"
              tooltipText="Enviar cobranza asociados"
              onClick={() => handleModalConfirmation('cobranza asociados', 'associates')}
              className="mt-2"
            >
              Asociados
            </BtnTooltip>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard icon="align-bottom" variant="primary-light" title="Cuentas únicas" value={accounts} />
        </Col>
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard icon="align-bottom" variant="primary-light" title="Pólizas únicas" value={contracts} />
        </Col>
        <Col xs={6} sm={4} md={6} lg={3} xl>
          <IconNumberCard
            icon="align-bottom"
            variant="primary-light"
            title="Registros con código NICO"
            value={nicoCodes}
          />
        </Col>
      </Row>

      <SimpleCenteredModal show={modalShow} body={modalBody} onHide={handleModalClose} />
    </>
  );
};

export default PremiumCollectionStatisticHeader;
