import React, { useState } from 'react';

import { InfoCardInsuranceItem } from '../../../components/Shared';
import { InfoCard, SimpleCenteredModal } from '../../../components';
import riskObject from './riskObject';

const InfoCardRisk = ({ insurancePolicy, userModule }) => {
  const [itemsModalShow, setItemsModalShow] = useState(false);

  const dictionaryDetails = insurancePolicy.dictionaryDetails || insurancePolicy.insuranceCategory?.dictionaryDetails;

  const leadInfo = riskObject({
    insurancePolicy,
    setItemsModalShow,
    userModule
  });

  return (
    <InfoCard title="Información del riesgo" object={leadInfo}>
      <SimpleCenteredModal
        size="xl"
        title="Ítems"
        show={itemsModalShow}
        body={
          <InsuranceItemTable
            insuranceItems={insurancePolicy.insuranceItemsAttributes}
            dictionaryDetails={dictionaryDetails}
          />
        }
        onHide={() => setItemsModalShow(false)}
      />
    </InfoCard>
  );
};

const InsuranceItemTable = ({ insuranceItems, dictionaryDetails }) => {
  const currentInsuranceItems = insuranceItems.filter(insuranceItem => !insuranceItem._destroy);

  return currentInsuranceItems.map((insuranceItem, index) => (
    <InfoCardInsuranceItem
      key={`insurance-item-table-${index.toString()}`}
      insuranceItem={insuranceItem}
      dictionaryDetails={dictionaryDetails}
      itemNumber={index + 1}
    />
  ));
};

export default InfoCardRisk;
