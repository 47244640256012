import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminAccountRequest } from '../../../requests/admin/adminAccounts';
import { scrollToTop } from '../../../services/utils';
import basicAccount from '../../Account/basicAccount';
import AdminAccountForm from './AdminAccountForm';

const AccountNew = ({ location }) => {
  const modelUrl = '/admin/accounts';

  const [account, setAccount] = useState(basicAccount);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Cuentas', href: modelUrl },
    { key: 2, name: 'Crear nueva cuenta' }
  ];

  const handleSuccessCreate = response => {
    dispatch(sendAlert({ kind: 'success', message: 'Cuenta creada con éxito' }));
    history.push(`${modelUrl}/${response.data.id}`);
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    createAdminAccountRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  const handleCustomParams = () => {
    const { state } = location;
    if (state && state.insuranceBrokerId) {
      setAccount({ ...account, insuranceBrokerId: state.insuranceBrokerId });
    }
  };

  useEffect(scrollToTop, []);
  useEffect(handleCustomParams, []);

  return (
    <div>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={<AdminAccountForm action="new" account={account} formRequest={handleCreateRequest} />}
      />
    </div>
  );
};

export default AccountNew;
