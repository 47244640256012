import React, { useRef, useState } from 'react';

import { DefaultHeader, SimpleCenteredModal } from '../../../components';
import { useFetchData } from '../../../hooks';
import {
  debounceHeaderDashboardInsuredPremiumCollectionRequest,
  debounceIndexInsuredPremiumCollectionRequest
} from '../../../requests/insured/insuredPremiumCollections';
import {
  CommissionCollectionDataTable,
  CommissionCollectionInfo,
  CommissionCollectionStatisticHeader
} from '../../../components/CommissionCollection';

const InsuredCommissionCollectionIndex = () => {
  const modelUrl = '/insured/commission_collections';

  const [modalShow, setModalShow] = useState(false);

  const customParamsRef = useRef({});

  const {
    data: premiumCollections,
    totalData: totalPremiumCollections,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexInsuredPremiumCollectionRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar comisiones. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  return (
    <DefaultHeader title="Comisiones" tooltipOnClick={() => setModalShow(true)}>
      <CommissionCollectionStatisticHeader
        customParamsRef={customParamsRef}
        headerDashboardRequest={debounceHeaderDashboardInsuredPremiumCollectionRequest}
        dataTableMoreData={moreData}
      />
      <CommissionCollectionDataTable
        userModule="insured"
        tableData={premiumCollections}
        tableDataAmount={totalPremiumCollections}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
      />
      <SimpleCenteredModal
        size="md"
        show={modalShow}
        title="Comisiones"
        body={<CommissionCollectionInfo />}
        onHide={() => setModalShow(false)}
      />
    </DefaultHeader>
  );
};

export default InsuredCommissionCollectionIndex;
