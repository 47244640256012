import React from 'react';
import PropTypes from 'prop-types';

const HistoricalRecordsShow = ({ customClassName, historicalRecords, withTitle }) => {
  return (
    <div className={`historical-record ${withTitle ? 'with-title' : ''}`}>
      {withTitle && (
        <header className="historical-record__title">
          <p className="section-title capitalize-text">Historial</p>
        </header>
      )}
      {historicalRecords.length ? (
        <div className={`historical-record__scrollable ${customClassName}`}>
          {historicalRecords.map(record => {
            const { title, createdAt, id } = record;
            return (
              <div key={id} className="historical-record--item rounded-border">
                <p className="font-weight-500 historical-record--item__text">{title}</p>
                <p className="font-weight-500 historical-record--item__date">{createdAt}</p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="historical-record--empty-container">
          <p>No hay registros asociados</p>
        </div>
      )}
    </div>
  );
};

HistoricalRecordsShow.propTypes = {
  customClassName: PropTypes.string,
  withTitle: PropTypes.bool
};

HistoricalRecordsShow.defaultProps = {
  customClassName: '',
  withTitle: false
};

export default HistoricalRecordsShow;
