/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import '../../assets/stylesheets/components/utils/custom-pagination.scss';
import IconGG from '../Utils/Icon';

const generatePageNumbers = (currentPage, totalPages) => {
  const maxPages = 4;
  const middleIndex = Math.floor(maxPages / 2);
  let startPage;

  if (totalPages <= maxPages || currentPage <= middleIndex) {
    startPage = 1;
  } else if (currentPage >= totalPages - middleIndex) {
    startPage = totalPages - maxPages + 1;
  } else {
    startPage = currentPage - middleIndex;
  }

  const pageNumbers = Array.from({ length: maxPages }, (_, index) => startPage + index).filter(
    pageNumber => pageNumber <= totalPages
  );

  return pageNumbers;
};

const CustomPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  //   onChangeRowsPerPage,
  currentPage
}) => {
  const [totalPages, setTotalPages] = useState([]);
  const totalNumberOfPages = Math.ceil(rowCount / rowsPerPage);

  useEffect(() => {
    setTotalPages(generatePageNumbers(currentPage, totalNumberOfPages));
  }, [currentPage]);

  const goToPage = page => {
    onChangePage(page);
  };
  const goToNextPage = () => {
    if (currentPage >= totalNumberOfPages) return;
    onChangePage(currentPage + 1);
  };
  const goToPreviousPage = () => {
    if (currentPage === 1) return;
    onChangePage(currentPage - 1);
  };

  return (
    <div className="custom-pagination-component">
      <div onClick={() => goToPreviousPage()} className="text-primary">
        <IconGG icon="chevron-left" />
      </div>
      {totalPages?.map(pageNumber => (
        <div
          key={pageNumber}
          className={`custom-pagination-component-page-item ${pageNumber === currentPage && 'page-item-active'}`}
          aria-hidden="true"
          onClick={() => goToPage(pageNumber)}
        >
          {pageNumber}
        </div>
      ))}
      <p>de {totalNumberOfPages}</p>
      <div onClick={() => goToNextPage()} className="text-primary">
        <IconGG icon="chevron-right" />
      </div>
    </div>
  );
};

export default CustomPagination;
