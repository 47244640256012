/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import defaultImg from '../../../assets/images/default.png';
import { camelCaseEmptyStringRecursive, openFile } from '../../../services/utils';
import { BtnTooltip, IconBtn } from '../Button';

const DropzoneComponent = props => {
  const {
    className,
    countFiles,
    customClass,
    disabled,
    fileAccept,
    files: filesValue,
    label,
    maxFiles: maxFilesValue,
    maxSize,
    minSize,
    msgMaxFiles,
    multiple,
    onDeletePersistedFiles,
    onFileDialogCancel,
    onFileDialogOpen,
    persistedFiles,
    shortDropzone
  } = props;

  const [files, setFiles] = useState(filesValue);
  const [isDisabled, setIsDisabled] = useState(disabled || false);

  const maxFiles = multiple ? maxFilesValue : 1;
  const isFiles = files.length > 0;

  const deleteFile = fileId => {
    const { afterDelete, onDelete } = props;
    const newFiles = [];
    let fileToRemove;

    files.forEach(file => {
      if (file.code !== fileId) newFiles.push(file);
      else fileToRemove = file;
    });

    setFiles(newFiles);
    onDelete(fileToRemove);

    if (afterDelete) afterDelete(fileToRemove);
    if (newFiles.length < maxFiles) setIsDisabled(false);
  };

  const deletePersistedFile = fileId => {
    const newFiles = [];
    let fileToRemove;

    persistedFiles.forEach(persistedFile => {
      if (persistedFile.id !== fileId) newFiles.push(persistedFile);
      else fileToRemove = persistedFile;
    });

    onDeletePersistedFiles(fileToRemove);
    if (newFiles.length < maxFiles) setIsDisabled(false);
  };

  const addFiles = async newFiles => {
    const { duplicateFile, batchNumber, onDrop, onDropUploaded } = props;
    let currentFiles = files;

    await newFiles.forEach(async newFile => {
      if (maxFiles !== undefined && files.length + 1 >= maxFiles) {
        if (files.length >= maxFiles) return;
        setIsDisabled(true);
      }

      if (!duplicateFile) {
        const duplicate = files.filter(
          file => file.name === newFile.name && file.size === newFile.size && file.type === newFile.type
        );
        if (duplicate.length) return;
      }

      const reader = new FileReader();
      const code = Math.floor(Math.random() * 10000000000).toString(16);

      currentFiles = [
        ...currentFiles,
        {
          code,
          name: newFile.name,
          result: defaultImg,
          size: newFile.size,
          type: newFile.type,
          file: newFile,
          loaded: false,
          uploaded: false
        }
      ];

      setFiles(currentFiles);

      reader.onloadend = () => {
        const currentFileUpdated = currentFiles.map(currentFile => {
          if (currentFile.code === code) return { ...currentFile, result: reader.result };
          return currentFile;
        });

        currentFiles = currentFileUpdated;
        setFiles(currentFileUpdated);
      };

      reader.readAsDataURL(newFile);
    });

    const { id: recordId } = props;
    const addedFiles = [];
    let newTotalFiles = [];

    currentFiles.forEach(currentFile => {
      if (!currentFile.loaded) addedFiles.push(currentFile);
      newTotalFiles.push({ ...currentFile, loaded: true });
    });

    currentFiles = newTotalFiles;
    setFiles(newTotalFiles);

    const batchN = batchNumber || addedFiles.length;
    const rangeBatchNumber = Math.ceil(addedFiles.length / batchN);
    let batchFiles;

    for (let index = 0; index < rangeBatchNumber; index += 1) {
      batchFiles = addedFiles.slice(index * batchN, (index + 1) * batchN);

      const filesToFetch = batchFiles.map(batchFile => ({
        document_key: batchFile.code,
        document: batchFile.file
      }));

      const response = await onDrop(recordId, filesToFetch);

      if (response && response.status === 201) {
        const dropzoneFiles = await camelCaseEmptyStringRecursive(response.data);
        newTotalFiles = currentFiles.map(currentFile => {
          const fileUploaded = dropzoneFiles.find(dropzoneFile => dropzoneFile.documentKey === currentFile.code);
          return fileUploaded ? { ...currentFile, id: fileUploaded.id, uploaded: true } : currentFile;
        });
      }

      onDropUploaded(newTotalFiles);
      setFiles(newTotalFiles);
    }
  };

  const textDropzone = ({ isDragActive, isDragReject, isFileTooLarge, rejectedFiles }) => {
    const {
      texts: { active, disabled: disabledText, emptyDropzone, textDefault, reject, tooLarge }
    } = props;

    let text = textDefault;
    if (isDragActive) text = active;
    if (isDragReject) text = reject;
    if (isDisabled) text = disabledText;
    if (isDisabled && [...files, ...persistedFiles].length === 0) text = emptyDropzone;

    if (isFileTooLarge)
      return (
        <p className="too-large ml-md-4">
          <strong>{tooLarge}</strong>
          <br />
          {rejectedFiles.map((file, i) => (
            <span key={`rejected-${i.toString()}`}>
              {i + 1}. {file.name} <br />
            </span>
          ))}
        </p>
      );

    if (text)
      return (
        <div className="dropzone__section__add-files">
          <p>{text}</p>
        </div>
      );

    return '';
  };

  const classContainer = ({ isDragActive, isDragReject, isFileTooLarge }) => {
    const {
      classes: { container, reject, active, disabled: disabledClass }
    } = props;

    let containerClassName = container;
    if (isDragReject || isFileTooLarge) containerClassName += ` ${reject}`;
    if (isDragActive) containerClassName += ` ${active}`;
    if (isDisabled) containerClassName += ` ${disabledClass}`;
    return containerClassName;
  };

  const preview = () =>
    files.map(fileContainer => (
      <Row className="dropzone__section__box-files__file" key={`preview-${fileContainer.code}`}>
        <Col md={2} className="dropzone__section__box-files__file__icon">
          {fileContainer.uploaded ? (
            <BtnTooltip
              icon="trash"
              iconSize="sm"
              variant="transparent"
              className="danger"
              tooltipText="Eliminar"
              onClick={() => deleteFile(fileContainer.code)}
            />
          ) : (
            <Spinner animation="border" variant="primary" />
          )}
          {fileContainer.type.includes('image') ? (
            <IconBtn noBtn icon="image" variant="transparent" />
          ) : (
            <IconBtn noBtn icon="file-document" variant="transparent" />
          )}
        </Col>
        <Col
          className={`dropzone__section__box-files__file__name ${disabled ? '' : 'cursor-pointer'}`}
          onClick={() => openFile(fileContainer.file)}
        >
          <p className="mb-0 text-truncate">{fileContainer.name}</p>
        </Col>
      </Row>
    ));

  const previewPersistedFiles = () =>
    persistedFiles.map(persistedFile => (
      <Row className="dropzone__section__box-files__file" key={`persisted-file-${persistedFile.id}`}>
        <Col md={2} className="dropzone__section__box-files__file__icon">
          {!disabled && (
            <BtnTooltip
              icon="trash"
              iconSize="sm"
              variant="transparent"
              className="danger"
              tooltipText="Eliminar"
              onClick={() => deletePersistedFile(persistedFile.id)}
            />
          )}
          {persistedFile.type.includes('image') ? (
            <IconBtn noBtn icon="image" variant="transparent" />
          ) : (
            <IconBtn noBtn icon="file-document" variant="transparent" />
          )}
        </Col>
        <Col
          className={`dropzone__section__box-files__file__name ${disabled ? '' : 'cursor-pointer'}`}
          onClick={() => openFile(persistedFile)}
        >
          <p className="mb-0 text-truncate">{persistedFile.filename}</p>
        </Col>
      </Row>
    ));

  const validationFiles = () => {
    if (countFiles) countFiles(files.length);
    if (files.length >= maxFiles) {
      msgMaxFiles(`Superaste la cantidad máxima de archivos. El límite de archivos es ${maxFiles}.`);
    } else {
      msgMaxFiles('');
    }
  };

  useEffect(validationFiles, [files]);

  return (
    <section className={`dropzone ${customClass}`}>
      {label && <p className="label-title">{label}</p>}
      <div className={`dropzone__section ${disabled ? 'disabled' : ''}`}>
        <div onClick={onFileDialogOpen} role="button" tabIndex={0} aria-hidden="true">
          <Dropzone
            onDrop={addFiles}
            disabled={isDisabled}
            multiple={multiple}
            maxSize={maxSize}
            minSize={minSize}
            accept={fileAccept}
            onFileDialogCancel={onFileDialogCancel}
            onDropAccepted={onFileDialogCancel}
            onDropRejected={onFileDialogCancel}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
              const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
              return (
                <div
                  {...getRootProps()}
                  className={`${!isFiles ? 'h-100' : ''} ${classContainer({
                    isDragActive,
                    isDragReject,
                    isFileTooLarge
                  })}`}
                >
                  <input {...getInputProps()} />
                  <div className="font-weight-bold">
                    {textDropzone({ isDragActive, isDragReject, isFileTooLarge, rejectedFiles })}
                  </div>
                  {isDragActive && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </div>
              );
            }}
          </Dropzone>
        </div>
        {(files.length > 0 || persistedFiles.length > 0) && (
          <div className={`dropzone__section__box-files ${className || ''} ${shortDropzone ? 'h-short' : ''}`}>
            {previewPersistedFiles()}
            {preview()}
          </div>
        )}
      </div>
    </section>
  );
};

DropzoneComponent.propTypes = {
  texts: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  countFiles: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  persistedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.number,
  onDrop: PropTypes.func,
  onDropUploaded: PropTypes.func,
  onDelete: PropTypes.func,
  onDeletePersistedFiles: PropTypes.func,
  onFileDialogOpen: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  batchNumber: PropTypes.number,
  duplicateFile: PropTypes.bool,
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  msgMaxFiles: PropTypes.func,
  fileAccept: PropTypes.string,
  customClass: PropTypes.string,
  className: PropTypes.string,
  shortDropzone: PropTypes.bool
};

DropzoneComponent.defaultProps = {
  texts: {
    active: 'Suelta los archivos aquí para subir...',
    disabled: '',
    emptyDropzone: 'Sin documentos adjuntos',
    reject: 'Archivo no valido para subir',
    textDefault: 'Arrastra aquí tus archivos o haz click para buscar',
    tooLarge: 'Los siguientes archivos exceden el peso máximo permitido y no serán cargados:'
  },
  classes: {
    active: 'dropzone-active',
    container: 'dropzone-container',
    disabled: 'dropzone-disabled',
    reject: 'dropzone-reject'
  },
  countFiles: () => null,
  files: [],
  persistedFiles: [],
  id: Date.now(),
  onDrop: (id, files) => console.log(id, files),
  onDropUploaded: files => console.log(files),
  onDelete: file => console.log(file),
  onDeletePersistedFiles: file => console.log(file),
  onFileDialogOpen: () => null,
  onFileDialogCancel: () => null,
  batchNumber: 20,
  duplicateFile: true,
  maxFiles: 100,
  disabled: false,
  multiple: true,
  maxSize: 50000000, // 50 MB
  minSize: undefined,
  msgMaxFiles: () => null,
  fileAccept: '',
  customClass: '',
  className: '',
  shortDropzone: false
};

export default DropzoneComponent;
