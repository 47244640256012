import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { InputRemoteSelect } from '../../Utils/Select';
import { useFetchForRemoteSelect } from '../../../hooks';
import {
  debounceIndexInsuranceCompanyRequest,
  indexInsuranceCompanyRequest
} from '../../../requests/insuranceCompanies';

const InsuranceCompanyRemoteSelector = ({ field, modelName, categorySelected }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const {
    options: insuranceCompanies,
    selectedOption: selectedInsuranceCompany,
    fetchOptions: fetchInsuranceCompanies
  } = useFetchForRemoteSelect({
    indexRequest: indexInsuranceCompanyRequest,
    debouncedIndexRequest: debounceIndexInsuranceCompanyRequest,
    value: getIn(values, `${modelName}[insuranceCompanyId]`),
    initialParams: { category: categorySelected || '' }
  });

  return (
    <InputRemoteSelect
      abbr
      {...field}
      isClearable
      label="Compañía aseguradora"
      placeholder="Seleccionar aseguradora"
      defaultOptions={insuranceCompanies}
      value={selectedInsuranceCompany}
      request={fetchInsuranceCompanies}
      onChange={data => {
        setFieldValue(`${modelName}[insuranceCompanyName]`, data?.label || '');
        setFieldValue(field.name, data?.value || '');
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default InsuranceCompanyRemoteSelector;
