import basicInsuranceItem from '../../../components/InsurancePolicies/Forms/basicInsuranceItem';

const basicInsuranceEndorsement = {
  id: '',
  approval: '',
  approvalInfo: {},
  comments: '',
  companyEndorsement: '',
  companyEndorsementInfo: {},
  companyEndorsementNumber: '',
  companyIssueDate: '',
  description: '',
  endorsementAction: '',
  endorsementDate: '',
  endorsementType: '',
  insuranceItemAttributes: basicInsuranceItem,
  insuranceItemId: '',
  originalInsuranceItem: basicInsuranceItem,
  title: '',
  variationAffectPremium: 0,
  variationCommissionTotalAmount: 0,
  variationExemptPremium: 0,
  variationTotalNetPremium: 0
};

export default basicInsuranceEndorsement;
