import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { setFilters } from '../../actions/utils';
import { DefaultHeader } from '../../components';
import { useFetchData } from '../../hooks';
import { debounceIndexInsurancePoliciesRequest } from '../../requests/insurancePolicies';
import InsurancePolicyDataTable from './InsurancePolicyDataTable';
import { AbilityContext } from '../../config/abilityContext';
import { redirectTo } from '../../services/utils';

const InsurancePolicyIndex = ({ location }) => {
  const modelUrl = '/insurance_policies';

  const ability = useAbility(AbilityContext);
  const history = useHistory();
  const customParamsRef = useRef({});
  const dispatch = useDispatch();

  const {
    data: insurancePolicies,
    totalData: totalInsurancePolicies,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexInsurancePoliciesRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las pólizas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (!state) return;
    dispatch(setFilters({ ...state }));
  };

  const headerBtnOptions = () => {
    return {
      icon: 'add',
      name: 'Nueva Póliza',
      onClick: () => redirectTo(history, `${modelUrl}/new`, { policyType: 'contract' })
    };
  };

  const showHeaderBtn = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'InsurancePolicy');
  };

  useEffect(handleSetFiltersOnMount, [location]);

  return (
    <DefaultHeader title="Pólizas" headerBtn={showHeaderBtn()} headerBtnOptions={headerBtnOptions()}>
      <InsurancePolicyDataTable
        tableData={insurancePolicies}
        tableDataAmount={totalInsurancePolicies}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
      />
    </DefaultHeader>
  );
};

export default InsurancePolicyIndex;
