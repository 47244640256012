import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { BasicTextArea, UploadFile } from '../..';

const InsuranceEndorsementFormFooter = ({ modelName }) => {
  const { errors, touched } = useFormikContext();

  return (
    <>
      <Row>
        <Col xs={12}>
          <Field name={`${modelName}[comments]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Comentarios"
                placeholder="Ingresa información que permita tener más contexto del endoso. Por ejemplo, el motivo, el objetivo o cualquier otro dato que consideres necesario registrar."
                rows={2}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <hr className="w-100 my-5" />
      <p className="section-title">Adjunta aprobación de cliente</p>
      <Row>
        <AttachFile
          abbr
          label="Aprobación"
          attribute="approval"
          modelName={modelName}
          fileAccept="application/pdf, image/*"
        />
      </Row>
    </>
  );
};

const AttachFile = ({ abbr, attribute, disabled, fileAccept, label, modelName }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const savedFile = getIn(values, `${modelName}[${attribute}Info]`);
  const newFile = getIn(values, `${modelName}[${attribute}]`);

  const handleFileUrl = file => {
    if (!file) return '';
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
  };

  return (
    <Col xl={6} className="mb-3">
      <Field name={`${modelName}[${attribute}]`}>
        {({ field }) => (
          <UploadFile
            {...field}
            abbr={abbr}
            fileAccept={fileAccept}
            label={label}
            filename={newFile?.name || savedFile?.filename}
            fileUrl={savedFile?.fileUrl || handleFileUrl(newFile)}
            onChange={file => setFieldValue(field.name, file)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            disabled={disabled}
          />
        )}
      </Field>
    </Col>
  );
};

export default InsuranceEndorsementFormFooter;
