import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminCompanyRequest } from '../../../requests/admin/companies';
import AdminCompanyForm from './AdminCompanyForm';
import basicCompany from './basicCompany';

const AdminCompanyNew = () => {
  const modelUrl = '/admin/companies';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Empresas', href: modelUrl },
    { key: 2, name: 'Crear nueva empresa' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Empresa creada con éxito' }));
    history.push(modelUrl);
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const { landingBackground, logo } = values.company;
    const sendParams = snakeCaseKeys(values);
    sendParams.company.logo = logo;
    sendParams.company.landing_background = landingBackground;
    createAdminCompanyRequest({
      dispatch,
      formData: true,
      params: sendParams,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminCompanyForm action="new" company={basicCompany} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminCompanyNew;
