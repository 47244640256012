export const utilsTypes = {
  // SETTINGS
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  // SLIDES
  GET_SLIDES_REQUEST: 'GET_SLIDES_REQUEST',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
  GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
  // PAGE
  GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'GET_PAGE_FAILURE',
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',
  // UICOMPONENTS
  SET_SIDEBAR_SHOW: 'SET_SIDEBAR_SHOW',
  // FILTERS
  SET_FILTERS: 'SET_FILTERS',
  REMOVE_FILTERS: 'REMOVE_FILTERS',
  SET_TASKS_FILTER_TABS_COUNT: 'SET_TASKS_FILTER_TABS_COUNT',
  // NOTIFICATIONS
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  GET_UNREAD_NOTIFICATIONS_REQUEST: 'GET_UNREAD_NOTIFICATIONS_REQUEST',
  GET_UNREAD_NOTIFICATIONS_SUCCESS: 'GET_UNREAD_NOTIFICATIONS_SUCCESS',
  GET_UNREAD_NOTIFICATIONS_FAILURE: 'GET_UNREAD_NOTIFICATIONS_FAILURE',
  // PENDINGS
  GET_PENDINGS_REQUEST: 'GET_PENDINGS_REQUEST',
  GET_PENDINGS_SUCCESS: 'GET_PENDINGS_SUCCESS',
  GET_PENDINGS_FAILURE: 'GET_PENDINGS_FAILURE',
  // INSURANCE COMPANY LOGOS
  GET_INSURANCE_COMPANY_LOGOS_REQUEST: 'GET_INSURANCE_COMPANY_LOGOS_REQUEST',
  GET_INSURANCE_COMPANY_LOGOS_SUCCESS: 'GET_INSURANCE_COMPANY_LOGOS_SUCCESS',
  GET_INSURANCE_COMPANY_LOGOS_FAILURE: 'GET_INSURANCE_COMPANY_LOGOS_FAILURE'
};

// SETTINGS
export const getSettings = () => ({ type: utilsTypes.GET_SETTINGS_REQUEST });

// SLIDE
export const getSlides = () => ({ type: utilsTypes.GET_SLIDES_REQUEST });

// PAGE
export const getPage = url => ({
  type: utilsTypes.GET_PAGE_REQUEST,
  url
});

// ALERT
export const closeAlert = params => ({ type: utilsTypes.CLOSE_ALERT, params });
export const sendAlert = params => ({ type: utilsTypes.SET_ALERT, ...params });

export const setHeaders = params => ({
  type: utilsTypes.SET_HEADERS,
  ...params
});

// UICOMPONENTS
export const setSidebarShow = payload => ({
  type: utilsTypes.SET_SIDEBAR_SHOW,
  payload
});

// FILTERS
export const setFilters = payload => ({
  type: utilsTypes.SET_FILTERS,
  payload
});

export const setTaskFilterTabsCount = payload => ({
  type: utilsTypes.SET_TASKS_FILTER_TABS_COUNT,
  payload
});

export const removeFilters = () => ({
  type: utilsTypes.REMOVE_FILTERS
});

// NOTIFICATIONS
export const getNotifications = () => ({ type: utilsTypes.GET_NOTIFICATIONS_REQUEST });
export const getUnreadNotifications = () => ({ type: utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST });

// PENDINGS
export const getPendings = () => ({ type: utilsTypes.GET_PENDINGS_REQUEST });

// INSURANCE COMPANY LOGOS
export const getInsuranceCompanyLogos = () => ({ type: utilsTypes.GET_INSURANCE_COMPANY_LOGOS_REQUEST });
