import React from 'react';

import { calculateDays, formatCurrency, handleDateFormat } from '../../../services/utils';
import { endorsementActionOptions, endorsementTypeOptions } from './attributeOptions';
import infoCardElement from '../../Utils/Cards/InfoCardMethods';

const InsuranceEndorsementSummaryObject = ({
  insuranceEndorsement,
  insurancePolicy,
  setModalBody,
  setModalShow,
  setModalTitle
}) => {
  const {
    additionalClauses,
    comments,
    endorsementAction,
    endorsementDate,
    endorsementType,
    variationAffectPremium,
    variationExemptPremium,
    variationTotalNetPremium
  } = insuranceEndorsement;
  const { validityStart, currency } = insurancePolicy;
  const isModification = endorsementType === 'modification';
  const endorsementActionLabel = endorsementActionOptions.find(action => action.value === endorsementAction)?.label;
  const endorsementTypeLabel = endorsementTypeOptions.find(type => type.value === endorsementType)?.label;

  const dateLabel = () => {
    const typeLabel = {
      modification: 'Fecha de modificación',
      rehabilitation: 'Fecha de rehabilitación'
    };

    const actionLabel = {
      cut: { cancellation: 'Fecha de cancelación', annulment: 'Fecha de anulación' },
      decrease: { modify: 'Fecha de modificación', remove: 'Fecha de eliminación' },
      increase: { modify: 'Fecha de modificación', add: 'Fecha de incorporación', extension: 'Fecha de prórroga' }
    };

    return typeLabel[endorsementType] || actionLabel[endorsementType][endorsementAction];
  };

  const object = {};
  if (endorsementAction) {
    object.endorsementAction = infoCardElement({
      string: true,
      key: 'Acción',
      value: endorsementActionLabel
    });
  }
  object.endorsementType = infoCardElement({
    string: true,
    key: 'Tipo de endoso',
    value: endorsementTypeLabel
  });

  object.endrosementDate = infoCardElement({
    string: true,
    key: dateLabel(),
    value: handleDateFormat(endorsementDate)
  });
  if (['cut', 'extension'].includes(endorsementAction || endorsementType)) {
    object.newTermDuration = infoCardElement({
      string: true,
      key: 'Nueva duración',
      value: calculateDays(validityStart, endorsementDate)
        .toString()
        .concat(' días')
    });
  }

  if (!isModification) {
    object.variationAffectPremium = infoCardElement({
      string: true,
      key: 'Variación de prima afecta',
      value: formatCurrency({ value: variationAffectPremium, addon: ` ${currency}` })
    });
    object.variationExemptPremium = infoCardElement({
      string: true,
      key: 'Variación de prima exenta',
      value: formatCurrency({ value: variationExemptPremium, addon: ` ${currency}` })
    });
    object.variationTotalNetPremium = infoCardElement({
      string: true,
      key: 'Variación de prima total neta',
      value: formatCurrency({ value: variationTotalNetPremium, addon: ` ${currency}` })
    });
  }

  if (additionalClauses)
    object.additionalClauses = infoCardElement({
      file: true,
      item: {
        title: 'Cláusulas adicionales',
        name: 'Ver',
        onClick: () => {
          setModalTitle('Cláusulas adicionales');
          setModalBody(<p>{additionalClauses}</p>);
          setModalShow(true);
        }
      }
    });

  object.comments = infoCardElement({
    string: true,
    key: 'Comentarios',
    value: comments || 'Sin comentarios'
  });

  return object;
};
export default InsuranceEndorsementSummaryObject;
