import React from 'react';
import { Image } from 'react-bootstrap';

import PropTypes from 'prop-types';
import defaultProfileImg from '../../../assets/images/default-profile.png';

const HomeProfileCard = ({ user = {}, userProfileImg, buttonComponent }) => {
  const { brokerCode, companyName, name } = user;

  return (
    <div className="home-profile-card">
      <section className="home-profile-card--image-container">
        <div className="home-profile-card--image">
          <Image
            src={userProfileImg || defaultProfileImg}
            className="home-profile-card--image__img"
            alt="home-profile-card"
          />
        </div>
      </section>
      <section className="home-profile-card--body">
        <p className="section-title text-color-secondary">{name}</p>
        {companyName && <p className="section-title">{companyName}</p>}
        {brokerCode && (
          <>
            <hr className="divider w-75" />
            <p>{brokerCode ? `Código del corredor ${brokerCode}` : 'Sin código asociado'}</p>
          </>
        )}
      </section>
      <section className="home-profile-card--action">{buttonComponent}</section>
    </div>
  );
};

HomeProfileCard.propTypes = {
  user: PropTypes.instanceOf(Object)
};

HomeProfileCard.defaultProps = {
  user: undefined
};

export default HomeProfileCard;
