/* eslint-disable react/no-array-index-key */
// nico_frontend/src/app/components/Shared/TestimonyCarousel.js
import React from 'react';
import { Carousel } from 'react-bootstrap';

const TestimonyCarousel = ({ testimonies }) => {
  return (
    <Carousel className="testimony-carousel" interval={10000} indicators controls>
      {testimonies.map((testimony, index) => (
        <Carousel.Item key={index} className="testimony-item">
          <div className="testimony-content">
            <img className="testimony-img" src={testimony.image} alt="Profile" />
            <p className="testimony-name">{testimony.name}</p>
            <p className="testimony-customer-type">{testimony.industry || testimony.age}</p>
            <blockquote className="testimony-text">{testimony.testimonial}</blockquote>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default TestimonyCarousel;
