import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminLeadQuotationRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/leads/${id}/lead_quotations`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminLeadQuotationRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/admin/leads/${id}/lead_quotations`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const deleteAdminLeadQuotationRequest = (
  leadId,
  id,
  { dispatch, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/leads/${leadId}/lead_quotations/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const sendQuotationAdminLeadQuotationRequest = (
  leadId,
  id,
  { dispatch, params, successCallback, callback, source }
) =>
  ApiService.request('post', `/admin/leads/${leadId}/lead_quotations/${id}/send_quotation`, {
    dispatch,
    params,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminLeadRequest = AwesomeDebouncePromise(indexAdminLeadQuotationRequest, 1000);
