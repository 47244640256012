import React from 'react';
import { DefaultHeader } from '../../../components';
import { debounceIndexAdminSettingRequest } from '../../../requests/admin/settings';
import AdminSettingTable from './AdminSettingTable';
import useFetchData from '../../../hooks/useFetchData';

const AdminSettingIndex = () => {
  const { data: settings, setMoreData, moreData, isFetching } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminSettingRequest,
    withoutWrapper: true
  });

  return (
    <DefaultHeader title="configuraciones generales">
      <AdminSettingTable settings={settings} setMoreData={setMoreData} moreData={moreData} isFetching={isFetching} />
    </DefaultHeader>
  );
};

export default AdminSettingIndex;
