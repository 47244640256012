import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  ClientLandingInsuredPersonsHeader,
  ClientLandingWhyNicoPersons,
  ClientLandingFaqsPersons
} from '../../components/Landing/ClientLanding';
import { BrokerLandingDemoSecond, BrokerLandingCompanies } from '../../components/Landing/BrokerLanding';
import { LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';

const InsuredPersons = () => {
  const { settings } = useSelector(state => state.utils);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout type="insured">
      <ClientLandingInsuredPersonsHeader settings={settings} />
      {/* <LandingCenteredContent title={needABrokerMainTitle} btnText="Necesito un corredor" to="/client" /> */}
      <ClientLandingWhyNicoPersons />
      <ClientLandingFaqsPersons />
      <BrokerLandingDemoSecond />
      <BrokerLandingCompanies />
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default InsuredPersons;
