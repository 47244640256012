import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexInsuredPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insured/premium_collections', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const headerDashboardInsuredPremiumCollectionRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insured/premium_collections/header_dashboard', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexInsuredPremiumCollectionRequest = AwesomeDebouncePromise(
  indexInsuredPremiumCollectionRequest,
  300
);

export const debounceHeaderDashboardInsuredPremiumCollectionRequest = AwesomeDebouncePromise(
  headerDashboardInsuredPremiumCollectionRequest,
  300
);
