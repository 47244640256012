import ApiService from '../../services/apiService';

export default function showPublicCompanyRequest(
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) {
  ApiService.request('get', `/public/companies/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });
}
