import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import camelCaseRecursive from 'camelcase-keys-recursive';

import { BtnTooltip, IconGG } from '../../../components';
import { handleDateFormat } from '../../../services/utils';
import AdminBeneficiaryEdit from './AdminBeneficiaryEdit';

const BeneficiaryColumns = ({ fromAdmin, insurancePolicyId, setMoreData, handleShowModal }) => {
  const columns = [
    {
      name: 'RUT',
      selector: 'nationalIdentification',
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      grow: '1'
    },
    {
      name: 'Fecha de nacimiento',
      selector: 'birthday',
      cell: ({ birthday }) => handleDateFormat(birthday),
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Rol',
      selector: 'role',
      cell: ({ role }) => role,
      sortable: true,
      grow: '1'
    },
    {
      name: 'Fecha de incorporación',
      selector: 'incorporation_date',
      cell: ({ incorporationDate }) => handleDateFormat(incorporationDate),
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Fecha de envío a compañía - incorporación',
      selector: 'company_sent_date_incorporation',
      cell: ({ companySentDateIncorporation }) => handleDateFormat(companySentDateIncorporation),
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Fecha de exclusión',
      selector: 'exclusion_date',
      cell: ({ exclusionDate }) => (exclusionDate ? handleDateFormat(exclusionDate) : '-'),
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Fecha de envío a compañía - exclusión',
      selector: 'company_sent_date_incorporation',
      cell: ({ companySentDateExclusion }) =>
        companySentDateExclusion ? handleDateFormat(companySentDateExclusion) : '-',
      sortable: true,
      grow: '1.25'
    },
    {
      name: 'Vigente',
      selector: 'applicable',
      cell: ({ applicable }) => (applicable ? 'Sí' : 'No'),
      sortable: false,
      grow: '1'
    },
    {
      name: 'Número de cargas',
      selector: 'dependents_number',
      cell: ({ dependentsNumber }) => dependentsNumber,
      sortable: true,
      grow: '1'
    },
    {
      name: 'Cobertura vida',
      selector: 'life_coverage',
      cell: ({ lifeCoverage }) => (lifeCoverage ? <IconGG icon="check" /> : <IconGG icon="close" />),
      sortable: true,
      grow: '1'
    },
    {
      name: 'Cobertura salud',
      selector: 'health_coverage',
      cell: ({ healthCoverage }) => (healthCoverage ? <IconGG icon="check" /> : <IconGG icon="close" />),
      sortable: true,
      grow: '1'
    },
    {
      name: 'Cobertura dental',
      selector: 'dental_coverage',
      cell: ({ dentalCoverage }) => (dentalCoverage ? <IconGG icon="check" /> : <IconGG icon="close" />),
      sortable: true,
      grow: '1'
    },
    {
      name: 'Cobertura catastrófico',
      selector: 'catastrophic_coverage',
      cell: ({ catastrophicCoverage }) => (catastrophicCoverage ? <IconGG icon="check" /> : <IconGG icon="close" />),
      sortable: true,
      grow: '1'
    },
    {
      name: 'Comentarios',
      selector: 'comments',
      cell: ({ comments }) => comments,
      sortable: true,
      grow: '1'
    }
  ];

  columns.push({
    cell: beneficiary => (
      <ActionButton
        fromAdmin={fromAdmin}
        insurancePolicyId={insurancePolicyId}
        beneficiary={beneficiary}
        setMoreData={setMoreData}
        handleShowDestroyModal={handleShowModal}
      />
    ),
    sortable: false,
    grow: '2'
  });

  return columns;
};

const ActionButton = ({ fromAdmin, beneficiary, insurancePolicyId, setMoreData, handleShowDestroyModal }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <BtnTooltip
        icon="pen"
        iconSize="sm"
        variant="transparent"
        className="edit-btn"
        tooltipText="Editar"
        onClick={() => setShowModal(true)}
      />
      <BtnTooltip
        icon="trash"
        iconSize="sm"
        variant="transparent"
        className="danger"
        tooltipText="Eliminar"
        onClick={() => handleShowDestroyModal(beneficiary)}
      />

      <AdminBeneficiaryEdit
        handleModalClose={handleModalClose}
        modalShow={showModal}
        setMoreData={setMoreData}
        beneficiary={beneficiary}
        policyId={insurancePolicyId}
        fromAdmin={fromAdmin}
      />
    </>
  );
};

export default BeneficiaryColumns;
