import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexInsuredAccountContactRequest = (
  accountId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/insured/accounts/${accountId}/contacts`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const insuredContactInfoRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', `/insured/contacts/contact_info`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateInsuredAccountContactRequest = (
  accountId,
  contactId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/insured/accounts/${accountId}/contacts/${contactId}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const debounceIndexInsuredAccountContactsRequest = AwesomeDebouncePromise(
  indexInsuredAccountContactRequest,
  300
);
export const debounceInsuredContactInfoRequest = AwesomeDebouncePromise(insuredContactInfoRequest, 300);
