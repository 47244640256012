import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikInput } from '../../../Utils/Input';

const TextField = ({ keyValue, details, modelName, errors, touched, optional }) => {
  const { title: label, placeholder, required, tooltip } = details;
  const fieldName = `${modelName}[${keyValue}]`;
  const error = getIn(errors, fieldName);

  return (
    <Col key={keyValue} xs={12} md={6} className="mb-4 pl-5">
      <Field name={`${modelName}[${keyValue}]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            label={label}
            abbr={optional ? false : required}
            tooltipText={tooltip}
            maxLength={160}
            placeholder={placeholder || label}
            error={error}
            touched={getIn(touched, field.name)}
            margin="custom-width"
            value={field.value || ''}
          />
        )}
      </Field>
    </Col>
  );
};

export default TextField;
