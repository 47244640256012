import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { BasicTextArea, UploadFile } from '../../components';

const RequestInsuranceEndorsementForm = ({ errors, handleModalClose, onHide, setFieldValue, touched }) => (
  <Form>
    <Row>
      <Col xs={12} className="mt-3">
        <Field name="insuranceEndorsement[endorsement]">
          {({ field }) => (
            <UploadFile
              {...field}
              label="Endoso"
              onChange={file => setFieldValue(field.name, file)}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col xs={12} className="mt-4">
        <Field name="insuranceEndorsement[comments]">
          {({ field }) => (
            <BasicTextArea
              {...field}
              abbr
              row={4}
              label="Comentario"
              placeholder="Ingresa un comentario..."
              className="p-3"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>

    <Row className="mt-2">
      <Col md={6} className="mt-4">
        <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
          Cancelar
        </Button>
      </Col>
      <Col md={6} className="mt-4">
        <Button block type="submit" variant="submit" className="no-shadow" onClick={onHide}>
          Solicitar
        </Button>
      </Col>
    </Row>
  </Form>
);

const setInitialValues = ({ insuranceEndorsement }) => ({ insuranceEndorsement });

const validationSchema = Yup.object().shape({
  insuranceEndorsement: Yup.object().shape({
    comments: Yup.string().required('Debes ingresar un comentario'),
    endorsement: Yup.mixed().nullable()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(RequestInsuranceEndorsementForm);
