import React, { useRef } from 'react';
import { useAbility } from '@casl/react';

import { DefaultHeader } from '../../components';
import { IndexFiltersProvider } from '../../components/Shared';
import { AbilityContext } from '../../config/abilityContext';
import { useFetchData, useIndexFilters } from '../../hooks';
import { debounceIndexAccountRequest } from '../../requests/accounts';
import AccountDataTable from '../../components/Account/AccountDatatable';
import { accountFiltersParams } from './AccountFiltersParams';
import AccountFilters from './AccountFilters';
import columns from './columns';

const AccountIndex = () => {
  const modelUrl = '/accounts';

  const { filterStatus, setFilterStatus } = useIndexFilters({});

  const ability = useAbility(AbilityContext);
  const customParamsRef = useRef({});

  const {
    data: accounts,
    totalData: totalAccounts,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAccountRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las cuentas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true,
    filterStatus,
    setFilterStatus
  });

  return (
    <IndexFiltersProvider value={{ customParamsRef, filterStatus, setFilterStatus }}>
      <DefaultHeader
        title="Cuentas"
        headerBtn={ability.can('manage', 'Account')}
        headerBtnOptions={{ icon: 'add', name: 'Nueva Cuenta', path: `${modelUrl}/new` }}
      >
        <AccountDataTable
          columns={columns}
          accountFiltersParams={accountFiltersParams}
          tableData={accounts}
          tableDataAmount={totalAccounts}
          modelUrl={modelUrl}
          isFetching={isFetching}
          moreData={moreData}
          setMoreData={setMoreData}
          handleIndexRequest={handleIndexRequest}
          FilterHeaderComponent={AccountFilters}
        />
      </DefaultHeader>
    </IndexFiltersProvider>
  );
};

export default AccountIndex;
