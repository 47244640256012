import React from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { CustomBtn } from '../../../components';
import { openFile } from '../../../services/utils';
import { exportAdminInsuranceEndorsementSummary } from '../../../requests/admin/insuranceEndorsements';
import { exportInsuranceEndorsementSummary } from '../../../requests/insuranceEndorsement';

const ExportInsuranceEndorsementSummaryButton = ({
  insurancePolicyId,
  insuranceEndorsementId,
  disabled,
  fromAdmin
}) => {
  const dispatch = useDispatch();

  const exportSummary = exportFunc => {
    exportFunc(insurancePolicyId, insuranceEndorsementId, {
      dispatch,
      params: {},
      successCallback: response => {
        openFile(camelCaseRecursive(response.data));
      }
    });
  };

  const handleExportInsuranceEndorsementSummary = () => {
    if (fromAdmin) {
      exportSummary(exportAdminInsuranceEndorsementSummary);
    } else {
      exportSummary(exportInsuranceEndorsementSummary);
    }
  };

  return (
    <div>
      <CustomBtn
        key={`file-button-${insuranceEndorsementId.toString()}`}
        onClick={handleExportInsuranceEndorsementSummary}
        className="btn-sm"
        disabled={disabled}
      >
        PDF
      </CustomBtn>
    </div>
  );
};

export default ExportInsuranceEndorsementSummaryButton;
