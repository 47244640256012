import React from 'react';

import summaryCoverageObject from './summaryCoverageObject';
import { InfoCard } from '../../../components';

const InfoCardSummaryCoverage = ({ fromAdmin, insurancePolicy, isProposal }) => {
  const leadInfo = summaryCoverageObject({
    fromAdmin,
    insurancePolicy,
    isProposal
  });

  return <InfoCard title="Información de la cobertura" object={leadInfo} />;
};

export default InfoCardSummaryCoverage;
