import React from 'react';
import jwt from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';

const isSingleAdminRole = roles => {
  const adminRoles = ['admin', 'admin_standard', 'admin_landing'];
  return roles.length === 1 && adminRoles.some(role => roles.includes(role));
};

const LandingLayout = ({ component: Component, ...rest }) => (
  <>
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');

        if (!payload) return <NonLoggedLandingLayout component={Component} {...props} />;

        const user = payload && jwt(payload);

        const { roles } = user;

        if (isSingleAdminRole(roles))
          return <Redirect to={{ pathname: '/admin/home', state: { from: props.location } }} />;

        if (roles.includes('insured'))
          return <Redirect to={{ pathname: '/insured/home', state: { from: props.location } }} />;

        return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />;
      }}
    />
  </>
);

export default LandingLayout;

const NonLoggedLandingLayout = ({ component: Component, ...props }) => {
  return <Component {...props} />;
};
