import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ButtonTooltip } from '../Tooltips';

const BasicTextAreaWithEditor = ({
  abbr,
  className = 'demo-editor',
  error,
  helpText,
  inputType,
  label,
  maxLength,
  optional,
  placeholder,
  row,
  size,
  tooltipText,
  touched,
  onChange,
  ...props
}) => {
  const { value } = props;

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentLoaded, setContentLoaded] = useState(false);
  const [inlineStyles, setInlineStyles] = useState({});

  const formatInlineStyles = currentInlineStyles => {
    const styleItems = { ...inlineStyles };
    currentInlineStyles.forEach(style => {
      const [property, styleValue] = style.split('-');
      if (property && styleValue) {
        styleItems[style] = { element: 'span', style: { [property]: styleValue } };
      }
    });

    return styleItems;
  };

  const handleEditorChange = newState => {
    setEditorState(newState);
    const currentContent = newState.getCurrentContent();
    const currentInlineStyles = newState.getCurrentInlineStyle();

    setInlineStyles(formatInlineStyles(currentInlineStyles));
    const html = stateToHTML(currentContent, { inlineStyles });
    onChange(html);
  };

  useEffect(() => {
    if (value && !contentLoaded) {
      setContentLoaded(true);
      const contentState = stateFromHTML(value);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [value]);

  return (
    <Form.Group className={`form-textarea-input ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label style={{ zIndex: 100 }}>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
          {optional && <span className="optional-label">(Opcional)</span>}
        </Form.Label>
      )}
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="demo-wrapper"
        editorClassName={className}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontFamily',
            'list',
            'textAlign',
            'link',
            'embedded',
            'emoji',
            'remove',
            'history',
            'colorPicker'
          ]
        }}
        {...props}
      />

      {tooltipText && (
        <ButtonTooltip tooltipText={tooltipText} containerClassName="custom-textarea--tooltip">
          ?
        </ButtonTooltip>
      )}
      {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
      {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
    </Form.Group>
  );
};

export default BasicTextAreaWithEditor;
