import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { updateTaskRequest } from '../../requests/tasks';
import { ModalBodyIcon } from '../Shared';
import { BtnCheckbox } from '../Utils/Button';
import { SimpleCenteredModal } from '../Utils/Modal';

const TaskCompleteCheckbox = ({ fromAdmin = false, task, setMoreData }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();

  const fromModule = fromAdmin ? 'admin' : 'broker';

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessUpdateTaskDoneRequest = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Tarea actualizada' }));
    setMoreData(m => !m);
  };

  const handleUpdateTaskDoneState = () => {
    const { id: taskId } = task;
    const sendParams = snakecaseKeys({ fromModule, task: { done: true } });
    updateTaskRequest(taskId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdateTaskDoneRequest
    });
  };

  const handleOpenConfirmationModal = () => {
    setModalShow(true);
    setModalBody(
      <ModalBodyIcon
        icon="check-o"
        iconVariant="success"
        content="estás por marcar esta tarea como"
        highlightedText="Terminada"
        handleModalClose={handleModalClose}
        formRequest={handleUpdateTaskDoneState}
      />
    );
  };

  return (
    <>
      <BtnCheckbox customOnClick={handleOpenConfirmationModal} label="Dar por terminada" />
      <SimpleCenteredModal body={modalBody} show={modalShow} onHide={handleModalClose} size="sm" />
    </>
  );
};

export default TaskCompleteCheckbox;
