import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexRegionRequest = ({ dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', '/regions', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showRegionRequest = (id, { dispatch, params = {}, failureCallback, successCallback, callback, source }) =>
  ApiService.request('get', `/regions/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexRegionsRequest = AwesomeDebouncePromise(indexRegionRequest, 300);
