import React from 'react';
import { useLocation } from 'react-router-dom';
import { LoginCatchphrase, LoginNavbar } from '../../../components/Auth';
import LoginBox from './LoginBox';

const LoginIndex = () => {
  const location = useLocation();
  const { from } = location.state || {};

  return (
    <>
      <div className="login-index">
        <LoginNavbar showRegister />
        <LoginCatchphrase />
        <LoginBox fromLocation={from} />
      </div>
      <div className="login-index--circles">
        <span className="circle circle-big" />
        <span className="circle white circle-petite-1" />
        <span className="circle white circle-petite-2" />
        <span className="circle white circle-petite-3" />
        <span className="circle white circle-bottom" />
        <span className="circle circle-colored" />
      </div>
    </>
  );
};

export default LoginIndex;
