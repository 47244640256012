import React from 'react';

import { RequestRegisterSuccess } from '../../../components/Shared';

const AdminHomeIndex = () => {
  return (
    <div>
      <RequestRegisterSuccess />
      <h1>Admin Home Index</h1>
      <p className="section-title">Esta página esta en construcción</p>
    </div>
  );
};

export default AdminHomeIndex;
