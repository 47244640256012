import React, { useState } from 'react';

import { InfoCard, SimpleCenteredModal } from '../../../components';
import { excludeKeys } from '../../../services/utils';
import generalObject from './generalObject';

const InfoCardGeneral = ({ hideAttributes, insurancePolicy, isForm, userModule }) => {
  const [modalShow, setModalShow] = useState(false);

  let generalInfo = generalObject({ insurancePolicy, isForm, userModule, setModalShow });
  generalInfo = excludeKeys(generalInfo, hideAttributes);

  return (
    <InfoCard title="Información de las partes" object={generalInfo}>
      <SimpleCenteredModal
        size="md"
        title="Cláusulas adicionales"
        show={modalShow}
        body={<p>{insurancePolicy.additionalClauses}</p>}
        onHide={() => setModalShow(false)}
      />
    </InfoCard>
  );
};

export default InfoCardGeneral;
