import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { SimpleCenteredModal } from '../../../components';
import InsurancePolicyDebtContract from './InsurancePolicyDebtContract';

const BulkUploadIndex = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalShow(false);
    setTimeout(() => {
      setModalTitle('');
      setModalBody('');
      setErrorMessage('');
    }, 300);
  };

  const handleModalOpen = ({ body, title }) => {
    setModalShow(true);
    setModalTitle(title);
    setModalBody(body);
  };

  return (
    <Row>
      <Col xs={12} className="mt-4 pt-2">
        <h5 className="text-primary-dark-blue">Carga masiva</h5>
      </Col>

      <InsurancePolicyDebtContract
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        setModalBody={setModalBody}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      <SimpleCenteredModal size="md" show={modalShow} title={modalTitle} body={modalBody} onHide={handleModalClose} />
    </Row>
  );
};

export default BulkUploadIndex;
