import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { FormHeader } from '../../../components';
import { HeaderTag } from '../../../components/Shared';
import { showInsuredInsurancePolicyRequest } from '../../../requests/insured/insuredInsurancePolicies';
import { camelCaseEmptyStringRecursive, scrollToTop } from '../../../services/utils';
import basicInsurancePolicy from '../../InsurancePolicy/basicInsurancePolicy';
import InsurancePolicyInfo from '../../InsurancePolicy/InsurancePolicyInfo';
import { policyTypeOptions } from '../../InsurancePolicy/InsurancePoliciesForms/insurancePoliciesFormOptions';
import useManageInsurancePolicy from './useManageInsurancePolicy';

const InsuredInsurancePolicyShow = ({ match }) => {
  const modelUrl = '/insured/insurance_policies';

  const { id } = match.params;

  const [insurancePolicy, setInsurancePolicy] = useState(basicInsurancePolicy);
  const [isFetching, setIsFetching] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();
  const canManageInsurancePolicy = useManageInsurancePolicy();

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Detalle de póliza' }
  ];

  const {
    accountName,
    isAnnulled,
    isCancelled,
    policyNumber,
    policyType,
    nicoCode,
    insuranceEndorsementsAttributes
  } = insurancePolicy;

  const currentPolicyType = policyTypeOptions.find(policyTypeOption => policyTypeOption.value === policyType) || {};
  const isContract = policyType === 'contract';

  const endorsementRequested = insuranceEndorsementsAttributes.find(object => object.status === 'requested') || false;
  const insuranceEndorsementPath = endorsementRequested ? `${endorsementRequested.id}/edit` : 'new';

  const showHeaderTag = isCancelled || isAnnulled || !isContract;
  const headerTagProps =
    isCancelled || isAnnulled
      ? { variant: 'danger', text: `${currentPolicyType.label} ${isCancelled ? 'cancelada' : 'anulada'}` }
      : { variant: 'secondary-light', text: currentPolicyType.label };

  const handleShowInsurancePolicy = () => {
    setIsFetching(true);
    showInsuredInsurancePolicyRequest(id, {
      dispatch,
      successCallback: response => setInsurancePolicy(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setIsFetching(false)
    });
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowInsurancePolicy, [moreData]);

  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={`${accountName} - N° ${policyNumber}`}
          headerTag={showHeaderTag && <HeaderTag variant={headerTagProps.variant} text={headerTagProps.text} />}
          headerBtn={canManageInsurancePolicy && nicoCode === 'ext'}
          headerBtnOptions={{
            isIconTooltip: true,
            className: 'edit-btn',
            variant: 'primary-dark',
            icon: 'pen',
            name: 'Editar Póliza',
            path: `${modelUrl}/${id}/edit`
          }}
          thirdBtn={isContract && nicoCode === 'ext'}
          thirdBtnOptions={{
            icon: 'add',
            name: 'Nuevo Endoso',
            path: {
              pathname: `${modelUrl}/${id}/endorsements/${insuranceEndorsementPath}`,
              state: { fromFormHeaderShow: true, modelUrl, originPath: `${modelUrl}/${id}`, insurancePolicy }
            }
          }}
          formComponent={
            <InsurancePolicyInfo
              userModule="insured"
              modelUrl={modelUrl}
              insurancePolicy={insurancePolicy}
              setMoreData={setMoreData}
            />
          }
        />
      )}
    </>
  );
};

export default InsuredInsurancePolicyShow;
