import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { FormHeader } from '../../../components';
import { basicLead } from '../../../components/Lead/basicLead';
import LeadInfo from '../../../components/Lead/LeadInfo';
import { showInsuredLeadRequest } from '../../../requests/insured/insuredLeads';
import { camelCaseEmptyStringRecursive, scrollToTop } from '../../../services/utils';

const InsuredLeadShow = ({ match }) => {
  const modelUrl = '/insured/leads';

  const { id } = match.params;

  const [isFetching, setIsFetching] = useState(true);
  const [lead, setLead] = useState(basicLead);
  const [moreData, setMoreData] = useState(false);
  const dispatch = useDispatch();

  const handleBreadcrumb = [
    { key: 1, name: 'Cotizaciones', href: modelUrl },
    { key: 2, name: 'Detalle de cotización' }
  ];

  const handleShowRequest = () => {
    setIsFetching(true);
    showInsuredLeadRequest(id, {
      dispatch,
      successCallback: response => setLead(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setIsFetching(false)
    });
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowRequest, [moreData]);

  return (
    <>
      {isFetching ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={lead?.account?.name}
          formComponent={<LeadInfo userModule="insured" modelUrl={modelUrl} lead={lead} setLead={setLead} setMoreData={setMoreData} />}
        />
      )}
    </>
  );
};

export default InsuredLeadShow;
