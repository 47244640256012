import { primaryLightBlue, primaryVarBlue } from '../../components/Utils/Color';

/* eslint-disable import/prefer-default-export */
export const customStyles = {
  rows: {
    style: {
      minHeight: '7rem',
      backgroundColor: primaryVarBlue,
      borderRadius: '35px',
      marginBottom: '15px',
      border: '1px solid #FFF',
      cursor: 'pointer',
      transition: '.3s',
      '&:not(:last-child)': {
        borderBottom: '1px solid #FFF'
      },
      '&:hover': {
        backgroundColor: primaryVarBlue,
        border: `1px solid ${primaryLightBlue}`
      }
    }
  },
  headCells: {
    style: {
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: '500'
    }
  },
  headRow: {
    style: {
      border: 'none'
    }
  },
  pagination: {
    style: {
      border: 'none',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  cells: {
    style: {
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: '500 !important'
    }
  }
};
