import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import IconGG from '../Icon';

const SearchInputFilter = ({ searchItem, setCustomParams, customParams }) => {
  const [textInput, setTextInput] = useState(customParams[searchItem.fieldName] || '');
  const handleSearchInputChange = e => {
    const { name, value } = e.target;
    setTextInput(value);
    setCustomParams(params => ({ ...params, [name]: value }));
  };

  return (
    <Form.Group className="d-flex search-filter--container align-items-center px-3 py-2 mt-4">
      <Form.Control
        placeholder={searchItem.placeholder}
        type="search"
        name={searchItem.fieldName}
        value={textInput}
        onChange={handleSearchInputChange}
      />
      <IconGG icon={searchItem.icon} className="ml-2" />
    </Form.Group>
  );
};

export default SearchInputFilter;
