import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput, FormikSelect } from '../../../../components';
import { useFetchData } from '../../../../hooks';
import { debounceIndexAdminRegionsRequest } from '../../../../requests/admin/regions';

const AdminProvinceForm = ({ province, action, errors, onHide, submitVariant, touched, setFieldValue }) => {
  const submitBtnText = action === 'new' ? 'Crear Provincia' : 'Guardar Cambios';

  const { data: regions } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminRegionsRequest,
    fetchingErrorMessage: 'Error al buscar las regiones. Por favor inténtelo nuevamente.',
    customParams: {
      for_selector: 'true'
    }
  });

  return (
    <Form>
      <Row>
        <Col md={6} sm={12}>
          <Field name="province[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="province[regionId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Región"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                options={regions}
                defaultValue={province.regionId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                isClearable
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="mt-4">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ province }) => {
  return {
    province: {
      ...province
    }
  };
};

const validationSchema = Yup.object().shape({
  province: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    regionId: Yup.string().required('Debes ingresar una region')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminProvinceForm);
