import React from 'react';
import { getIn, useFormikContext } from 'formik';
import FormikSelect from '../../../components/Utils/Select/FormikSelect';

const NicoCodeSelect = ({ disabled, field, modelName }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const nicoCode = getIn(values, field.name);

  const options = [
    { value: 'yes', label: 'Sí' },
    { value: 'no', label: 'No' },
    { value: 'ext', label: 'Ext' }
  ];

  return (
    <FormikSelect
      abbr
      label="Código NICO"
      placeholder="Selecciona una opción"
      defaultValue={nicoCode}
      isDisabled={disabled}
      options={options}
      error={getIn(errors, field.name)}
      value={nicoCode}
      onChange={option => {
        setFieldValue(`${modelName}[translatedNicoCode]`, option.label);
        setFieldValue(field.name, option.value);
      }}
      touched={getIn(touched, field.name)}
    />
  );
};

export default NicoCodeSelect;
