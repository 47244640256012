const useConvertDictionaryKeys = () => {
  const camelToSnakeCase = str => {
    return str
      .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
      .replace(/([a-z])([0-9])/g, '$1_$2')
      .toLowerCase();
  };

  const convertDictionaryKeys = array => {
    return array.map(obj => {
      return Object.keys(obj).reduce((acc, key) => {
        const snakeKey = camelToSnakeCase(key);
        acc[snakeKey] = obj[key];
        return acc;
      }, {});
    });
  };

  return { convertDictionaryKeys };
};

export default useConvertDictionaryKeys;
