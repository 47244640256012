import React from 'react';
// import { useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { InsuranceBrokerFields } from '../InsuranceBroker';
import { FormikInput, LinkBtn, UploadImage } from '../../components';
import { Can } from '../../config/abilityContext';
import { isUserJustAdmin } from '../../services/utils';

const ProfileForm = ({ errors, onHide, submitVariant, touched, setFieldValue, user, values }) => {
  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="user[avatar]">
            {({ field }) => (
              <UploadImage
                {...field}
                roundedImg
                forProfileAvatar
                label="Adjuntar imagen"
                imageUrl={getIn(user.avatarInfo, 'fileUrl')}
                onChange={image => setFieldValue(field.name, image)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Correo electrónico"
                disabled
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Can I="manage" a="Platform">
        <Row>
          <InsuranceBrokerFields
            fromProfileForm
            values={values}
            errors={errors}
            touched={touched}
            user={user}
            setFieldValue={setFieldValue}
          />
        </Row>
      </Can>
      <Row className="d-flex flex-row-reverse">
        <Col md={3} sm={12} className="mt-4">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            Guardar cambios
          </Button>
        </Col>
        <Col md={2} sm={12} className="mt-4">
          <LinkBtn type="submit" variant="cancel" size="lg" className="w-100" to="/users">
            Cancelar
          </LinkBtn>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ user }) => {
  return {
    user
  };
};

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const validationSchema = ({ user }) => {
  const isAdmin = isUserJustAdmin(user);
  const userRelativeSchema = {
    user: Yup.object().shape({
      avatar: Yup.mixed()
        .test('fileSize', 'Archivo es demasiado grande.', value => {
          if (!value?.size) return true;
          return value && value.size <= FILE_SIZE;
        })
        .test('fileFormat', 'Formato debe ser .jpg, .jpeg o .png', value => {
          if (!value?.type) return true;
          return value && SUPPORTED_FORMATS.includes(value.type);
        })
    })
  };
  if (isAdmin) return Yup.object().shape(userRelativeSchema);

  userRelativeSchema.user.insuranceBrokerAttributes = Yup.object().shape({
    name: Yup.string().required('Debes ingresar Nombre y Apellido del corredor'),
    nationalIdentification: Yup.string().required('Debes ingresar RUT del corredor'),
    brokerCommission: Yup.string().nullable()
  });

  return Yup.object().shape(userRelativeSchema);
};

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileForm);
