import { debounceAutosaveAdminLeadRequest, updateAdminLeadRequest } from '../../requests/admin/lead';
import { debounceAutosaveLeadRequest, updateLeadRequest } from '../../requests/lead';

const basicInfoRoles = {
  broker: {
    isAdmin: false,
    name: 'Corredor',
    indexPath: '/insurance_policies',
    updateRequest: updateLeadRequest,
    debounceUpdateRequest: debounceAutosaveLeadRequest,
    accountPath: '/accounts',
    leadQuotationMessage: {
      empty:
        'Estamos trabajando para encontrar las mejores alternativas. Te notificaremos cuando tengamos cotizaciones disponibles.',
      full: [
        'Revisa las cotizaciones para elegir la mejor alternativa. Luego, haz el cierre exitoso y NICO se encarga de la emisión e ingreso a la plataforma.',
        "Haz click en 'Ver' para revisar la cotización de la Compañía de Seguros. Haz click en 'Seleccionar' para seleccionar la o las cotizaciones que quieras comparar, enviar o aprobar para su contratación."
      ]
    },
    can: {
      addAdditionalDocument: true,
      autoSaveComment: true,
      closeQuotation: true,
      editAccount: true,
      editContact: true
    }
  },
  insured: {
    isAdmin: false,
    name: 'Asegurado',
    indexPath: '/insured/insurance_policies',
    updateRequest: () => null,
    debounceUpdateRequest: () => null,
    accountPath: '',
    leadQuotationMessage: {
      empty: 'Nico está encargándose de las cotizaciones, te notificaremos cuando haya alguna disponible.',
      full: [
        'Revisa las cotizaciones en conjunto con tu corredor para elegir la mejor alternativa. Cuando hayas elegido, aprueba su contratación y nosotros nos encargaremos de la emisión e ingreso a la plataforma.'
      ]
    },
    can: {
      addAdditionalDocument: false,
      autoSaveComment: false,
      closeQuotation: false,
      editAccount: false,
      editContact: false
    }
  },
  admin: {
    isAdmin: true,
    name: 'Administrador',
    indexPath: '/admin/insurance_policies',
    updateRequest: updateAdminLeadRequest,
    debounceUpdateRequest: debounceAutosaveAdminLeadRequest,
    accountPath: '/admin/accounts',
    leadQuotationMessage: {
      empty:
        'Estamos trabajando para encontrar las mejores alternativas. Te notificaremos cuando tengamos cotizaciones disponibles.',
      full: [
        'Revisa las cotizaciones para elegir la mejor alternativa. Luego, haz el cierre exitoso y NICO se encarga de la emisión e ingreso a la plataforma.',
        "Haz click en 'Ver' para revisar la cotización de la Compañía de Seguros. Haz click en 'Seleccionar' para seleccionar la o las cotizaciones que quieras comparar, enviar o aprobar para su contratación."
      ]
    },
    can: {
      addAdditionalDocument: true,
      autoSaveComment: true,
      closeQuotation: true,
      editAccount: true,
      editContact: true
    }
  }
};

export default basicInfoRoles;
