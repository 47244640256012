import { basicInsuranceCover } from './basicInsuranceCover';

const basicInsuranceItem = {
  id: '',
  insuranceCoversAttributes: [basicInsuranceCover],
  name: '',
  notes: '',
  detail1: '',
  detail2: '',
  detail3: '',
  detail4: '',
  detail5: '',
  detail6: '',
  detail7: '',
  detail8: '',
  detail9: '',
  detail10: ''
};

export default basicInsuranceItem;
