import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { rutFormat, validRutInput, handleFormatTextToLowerCase } from '../../../services/utils';
import { BasicTextArea, FormikInput } from '../../Utils/Input';
import '../../../services/yupCustomMethods';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const ContactForm = ({
  errors,
  touched,
  setFieldValue,
  isValid,
  buttonText = 'Enviar',
  hasTextarea,
  textAreaPlaceholder
}) => {
  const { type } = useGetRoleByRoute();
  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const handleOnChangeTextArea = ({ fieldName, rows = [] }) => {
    const formattedComment = rows.map(row => {
      const rowArray = row.split('\t').filter(word => word);
      const splitElement = row.split(';');
      const splitLength = splitElement.length >= 2;
      return splitLength ? `${splitElement[0].trim()}\t${splitElement[1].trim()}` : rowArray;
    });

    setFieldValue(fieldName, formattedComment.join('\n'));
  };

  return (
    <Form>
      <Row>
        <Col md={6}>
          <Field name="name">
            {({ field }) => (
              <FormikInput
                abbr
                {...field}
                label="Nombre y apellido"
                placeholder="Ej. Neil Armstrong"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="national_identification">
            {({ field }) => (
              <FormikInput
                {...field}
                label="RUT"
                placeholder="00.000.000-0"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText="RUT de la persona natural o<br/>
                             jurídica corredora de seguros"
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="phone">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Teléfono"
                placeholder="+56"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                placeholder="usuario@correo.com"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        {hasTextarea && (
          <Col md={12}>
            <Field name="message">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row={6}
                  placeholder={textAreaPlaceholder}
                  onChange={({ target }) => {
                    const targetValue = target.value;
                    const rows = targetValue.split('\n');
                    handleOnChangeTextArea({ fieldName: field.name, rows });
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  className="p-3 textarea-tab-size"
                />
              )}
            </Field>
          </Col>
        )}
        <Col md={12} className="d-flex justify-content-end">
          <Button type="submit" className={`mt-4 btn-submit btn-submit-${type}`} disabled={!isValid}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = (hasTextarea, textAreaPlaceholder) => ({
  name: '',
  phone: '',
  national_identification: '',
  email: '',
  ...(hasTextarea && { message: textAreaPlaceholder })
});

const validationSchema = hasTextarea =>
  Yup.object().shape({
    name: Yup.string().required('Debes ingresar tu nombre y apellido'),
    phone: Yup.string().required('Debes ingresar tu teléfono'),
    national_identification: Yup.string()
      .nullable()
      .notRequired()
      .optionalrut('El RUT es inválido'),
    email: Yup.string()
      .required('Debes ingresar tu correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    ...(hasTextarea && {
      message: Yup.string().required('Debes ingresar un mensaje')
    })
  });

const handleSubmit = (values, { props, resetForm }) => {
  const { formRequest } = props;
  formRequest(values, props);
  resetForm();
};

export default withFormik({
  mapPropsToValues: ({ hasTextarea, textAreaPlaceholder }) => setInitialValues(hasTextarea, textAreaPlaceholder),
  handleSubmit,
  validationSchema: ({ hasTextarea }) => validationSchema(hasTextarea)
})(ContactForm);
