import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IconBtn } from '../Utils/Button';
import defaultProfileImg from '../../assets/images/default-profile.png';

const BeneficiaryStatisticCard = ({ icon, variant: iconVariant, cardVariant, imageVariant, title, value }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={`statistic-card ${cardVariant} position-relative`}>
      <div className="statistic-card--icon">
        {icon ? (
          <IconBtn icon={icon} iconSize="lg" variant={iconVariant} noBtn className="no-shadow" />
        ) : (
          <div className="beneficiary-statistic-card--image">
            <Image
              src={defaultProfileImg}
              className={`beneficiary-statistic-card--image__img ${imageVariant}`}
              alt="beneficiary-statistic-card"
            />
          </div>
        )}
      </div>
      <div className="statistic-card--content">
        <p className="section-title m-0 capitalize-text">{title}</p>
        <h6>{value}</h6>
      </div>
    </div>
  );
};

BeneficiaryStatisticCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  cardVariant: PropTypes.string,
  imageVariant: PropTypes.string
};

BeneficiaryStatisticCard.defaultProps = {
  variant: '',
  value: '',
  cardVariant: '',
  imageVariant: ''
};

export default BeneficiaryStatisticCard;
