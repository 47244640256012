import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { useAbility } from '@casl/react';

import { AddressFieldsForm, FormikDatePicker, FormikInput, FormikNumberFormat, InputButtons } from '../../components';
import { percentageLimit, rutFormat, validRutInput } from '../../services/utils';
import InsuranceBrokerCompanyRemoteSelect from './InsuranceBrokerCompanyRemoteSelect';
import { adminBrokerTypesOptions, brokerTypesOptions } from './insuranceBrokerTypeOptions';
import { AbilityContext } from '../../config/abilityContext';

const InsuranceBrokerFields = ({ errors, fromAdminUserForm, fromProfileForm, setFieldValue, touched, values }) => {
  const modelName = 'user[insuranceBrokerAttributes]';
  const ability = useAbility(AbilityContext);

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  const getBrokerTypesOptions = () => {
    return ability.can('manage', 'Admin') ? adminBrokerTypesOptions : brokerTypesOptions;
  };

  return (
    <>
      {fromAdminUserForm && (
        <Col md={12}>
          <Field name={`${modelName}[companyId]`}>
            {({ field }) => (
              <InsuranceBrokerCompanyRemoteSelect
                {...field}
                abbr
                isClearable
                label="Empresa"
                placeholder="Seleccionar empresa"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched
                values={values}
                modelName={modelName}
              />
            )}
          </Field>
        </Col>
      )}
      <Col md={12} className="my-5">
        <section className="insurance-broker-fields usertypes">
          <p className="section-title">Información de usuario</p>
          <Row>
            {!fromProfileForm && (
              <Col md={12}>
                <InputButtons
                  values={values}
                  label="Tipo de usuario"
                  optionsArray={getBrokerTypesOptions()}
                  modelName={`${modelName}[brokerType]`}
                  setFieldValue={setFieldValue}
                  error={getIn(errors, `${modelName}[brokerType]`)}
                  touched={getIn(touched, `${modelName}[brokerType]`)}
                />
              </Col>
            )}
            <Col md={4} sm={6}>
              <Field name={`${modelName}[name]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Nombre y apellido"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4} sm={6}>
              <Field name={`${modelName}[nationalIdentification]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="RUT"
                    onChange={handleNationalIdentificationFormat}
                    disabled={fromProfileForm}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4} sm={6}>
              <Field name={`${modelName}[birthday]`}>
                {({ field }) => (
                  <FormikDatePicker
                    {...field}
                    label="Fecha de nacimiento"
                    placeholder="dd/mm/aaaa"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4} sm={6}>
              <Field name={`${modelName}[phone]`}>
                {({ field }) => (
                  <FormikNumberFormat
                    {...field}
                    label="Celular"
                    format="+56 # #### ####"
                    onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4} sm={6}>
              <Field name={`${modelName}[parsedBrokerCommission]`}>
                {({ field }) => (
                  <FormikNumberFormat
                    {...field}
                    abbr
                    label="% Sub-comisión corredor"
                    disabled={fromProfileForm}
                    suffix=" %"
                    isAllowed={percentageLimit}
                    fieldName={`${modelName}[brokerCommission]`}
                    error={getIn(errors, `${modelName}[brokerCommission]`)}
                    touched={getIn(touched, `${modelName}[brokerCommission]`)}
                    tooltipText="Corresponde a cómo se reparten la comisión el corredor y el empleador.<br/>
                                 Por ejemplo: Si la comisión de la póliza es $100 y NICO cobra el 10% ($10),<br/>
                                 la empresa de corretaje se lleva $90. De estos $90, digamos que el empleador<br/>
                                 se queda con el 40% ($36) y el corredor el 60% ($54). En este caso, en este<br/>
                                 campo debe decir 60%."
                  />
                )}
              </Field>
            </Col>
            <Col md={4} sm={6}>
              <Field name={`${modelName}[brokerCode]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="Código del corredor"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>

          <p className="section-subtitle">Dirección</p>
          <AddressFieldsForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            parentModel={modelName}
          />
        </section>
      </Col>
    </>
  );
};

export default InsuranceBrokerFields;
