import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { es } from 'date-fns/locale';
import DatePicker from 'react-datepicker';

import IconGG from '../Icon';
import { ButtonTooltip } from '../Tooltips';

const DatesFilter = ({ customParams, endDateField, setCustomParams, startDateField, tooltipText }) => {
  const [endDate, setEndDate] = useState(customParams[endDateField]);
  const [startDate, setStartDate] = useState(customParams[startDateField]);

  const handleDates = () => {
    setStartDate(customParams[startDateField]);
    setEndDate(customParams[endDateField]);
  };

  useEffect(handleDates, [customParams]);

  return (
    <Form.Group className="dates-filter--container px-4 py-2 m-0">
      <Form.Label>Desde</Form.Label>
      <div className="datepicker">
        <DatePicker
          className="form-control m-0"
          selected={startDate}
          dateFormat="dd/MM/yyyy"
          autoComplete="off"
          onChange={selectedStartDate => {
            setStartDate(selectedStartDate);
            setCustomParams(params => ({ ...params, [startDateField]: selectedStartDate || '' }));
          }}
          placeholderText="00/00/0000"
          locale={es}
        />
      </div>
      <Form.Label>Hasta</Form.Label>
      <div className="datepicker">
        <DatePicker
          className="form-control m-0"
          selected={endDate}
          dateFormat="dd/MM/yyyy"
          autoComplete="off"
          onChange={selectedEndDate => {
            setEndDate(selectedEndDate);
            setCustomParams(params => ({ ...params, [endDateField]: selectedEndDate || '' }));
          }}
          placeholderText="00/00/0000"
          locale={es}
        />
      </div>
      <IconGG icon="calendar-today" className={tooltipText ? 'mr-3' : ''} />
      {tooltipText && (
        <ButtonTooltip html tooltipText={tooltipText}>
          ?
        </ButtonTooltip>
      )}
    </Form.Group>
  );
};

export default DatesFilter;
