import basicAccount from '../../screens/Account/basicAccount';
import basicInsuranceCategory from '../../screens/AdminScreens/InsuranceCategory/basicInsuranceCategory';
import basicInsurancePolicy from '../../screens/InsurancePolicy/basicInsurancePolicy';
import { basicLeadQuotation } from '../LeadQuotation';
import basicInsuranceItem from './Form/basicInsuranceItem';

// eslint-disable-next-line import/prefer-default-export
export const basicLead = {
  id: '',
  account: { ...basicAccount, legalPersonType: '' },
  adminComments: '',
  beneficiaryPersonAddress: '',
  beneficiaryPersonName: '',
  beneficiaryPersonNationalIdentification: '',
  closeStatus: '',
  comments: '',
  coverAmount: '',
  coverDetail: '',
  currency: 'UF',
  executiveManagerId: '',
  hiringPersonAddress: '',
  hiringPersonName: '',
  hiringPersonNationalIdentification: '',
  insuranceBrokerId: '',
  insuranceCategory: basicInsuranceCategory,
  insuranceCategoryId: '',
  insuranceItemsAttributes: [basicInsuranceItem],
  insurancePolicyContract: basicInsurancePolicy,
  insurancePolicyProposal: basicInsurancePolicy,
  insuredPersonAddress: '',
  insuredPersonName: '',
  insuredPersonNationalIdentification: '',
  leadQuotations: basicLeadQuotation,
  quotationRequestFile: {},
  renewableInsurancePolicy: basicInsurancePolicy,
  requested: false,
  rowStyle: '',
  selectedLeadQuotation: basicLeadQuotation,
  step: '',
  subjectMatter: '',
  subjectMatterAddress: '',
  validityStart: ''
};
