import React from 'react';
import { Image } from 'react-bootstrap';

import logo from './logos/logo-nico.svg';
import logoDark from './logos/dark-nico-logo.svg';
import logoBroker from './logos/nico-broker.svg';
import logoPartner from './logos/nico-partner.svg';
import logoInsured from './logos/nico-insured.svg';
import logoWhite from './logos/logo_white.png';

// Mapeo de tipos a imágenes
const logoTypes = {
  light: logo,
  dark: logoDark,
  broker: logoBroker,
  partner: logoPartner,
  insured: logoInsured,
  company: logoWhite
};

const MainLogo = ({ type = 'light' }) => {
  const logoSrc = logoTypes[type] || logo;
  return <Image fluid src={logoSrc} alt="Logo" className="main-logo" width="100" height="auto" />;
};

export default MainLogo;
