import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { IconBtn, IconGG } from '../../components';

const HomeTerms = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Row className="home-terms" style={{ bottom: 'auto !impotant' }}>
      <Col xs={12} className="home-contact__navbar">
        <IconBtn onClick={goBack} variant="transparent" className="home-contact__navbar__back-btn">
          <IconGG icon="chevron-left" />
          <span>Volver</span>
        </IconBtn>
      </Col>

      <Col lg={{ span: 8, offset: 3 }} className="home-contact__form">
        <Row>
          <Col md={12} className="home-contact__form__title">
            <h6>Términos y Condiciones de Uso</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              NICO Corredores de Seguros SpA (en adelante ‘NICO’) es una empresa de tecnología, servicios y corretaje de
              seguros establecida en Chile y que se rige por las leyes de Chile y las regulaciones de la Comisión para
              el Mercado Financiero (CMF).
            </p>
            <p>
              Al acceder y utilizar nuestro sitio web y nuestros servicios, aceptas cumplir con los siguientes términos
              y condiciones de uso. Te recomendamos leer detenidamente este documento antes de utilizar nuestros
              servicios.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>1. Servicios Ofrecidos</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              En NICO actuamos como intermediarios entre asegurados, corredores de seguros, compañías de seguros y
              liquidadores. Nuestros servicios incluyen, pero no se limitan a:
            </p>
            <ul style={{ padding: '5px 30px' }}>
              <li>
                Asesoramiento para encontrar las pólizas de seguros que mejor se adapten a las necesidades de las
                partes.
              </li>
              <li>Comparación de diferentes opciones de seguros disponibles en el mercado.</li>
              <li>Ayuda en la gestión de denuncias y asistencia en caso de siniestro.</li>
              <li>Facilitación de la comunicación entre todas las partes.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>2. Responsabilidad del Usuario</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">Al utilizar nuestro sitio web, aceptas que:</p>
            <ul style={{ padding: '5px 30px' }}>
              <li>La información que nos proporcionas es veraz y completa.</li>
              <li>Debes notificarnos cualquier cambio en la información proporcionada.</li>
              <li>Eres responsable de mantener la confidencialidad de tu información de inicio de sesión.</li>
              <li>No utilizarás nuestro sitio web para actividades ilegales o fraudulentas.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>3. Propiedad Intelectual</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Todo el contenido de este sitio web, incluyendo pero no limitado a texto, gráficos, logotipos, imágenes y
              software, está protegido por derechos de autor y otras leyes de propiedad intelectual. Por favor, no uses,
              reproduzcas o distribuyas este contenido sin nuestro consentimiento explícito.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>4. Limitación de Responsabilidad</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              En NICO, no nos haremos responsables por ningún daño directo, indirecto, incidental, especial, consecuente
              o punitivo que pueda surgir del uso o la imposibilidad de utilizar nuestro sitio web. Excepto en los casos
              establecidos en las pólizas de seguro correspondientes.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>5. Cambios en los Términos y Condiciones</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Nos reservamos el derecho de modificar o actualizar estos términos y condiciones en cualquier momento sin
              previo aviso. Te recomendamos que revises periódicamente esta página para estar al tanto de cualquier
              cambio.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>6. Legislación Aplicable</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Estos términos y condiciones se rigen por las leyes de Chile y cualquier disputa relacionada con estos
              términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de Chile.
            </p>
            <p className="mb-3">Al utilizar nuestro sitio web, aceptas estos términos y condiciones en su totalidad.</p>
            <p className="mb-3">
              Si tienes preguntas sobre nuestros términos y condiciones, puedes ponerte en contacto con nuestro equipo
              de atención al cliente en aurora@nicoseguros.com.
            </p>
            <p className="mb-3">Fecha de última actualización: 15 de febrero de 2024</p>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="home-contact__form__title mt-5">
            <h6>Política de Privacidad</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              En NICO, valoramos tu privacidad y nos comprometemos a proteger la información personal que nos
              proporcionas. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos tu
              información cuando utilizas nuestros servicios de intermediación de seguros y visitas nuestro sitio web.
              Al acceder y utilizar nuestros servicios, aceptas los términos descritos en esta política de privacidad.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>1. Información Personal Recopilada</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Recopilamos información personal que nos proporcionas directamente, como tu nombre, dirección de correo
              electrónico, número de teléfono y detalles de contacto. También podemos recopilar información sobre tus
              transacciones con nosotros y cualquier información adicional que nos proporciones durante la prestación de
              nuestros servicios.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>2. Uso de la Información Personal</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">Utilizamos la información personal recopilada para los siguientes propósitos:</p>
            <ul style={{ padding: '5px 30px' }}>
              <li>Proporcionarte los servicios de intermediación de seguros solicitados.</li>
              <li>Comunicarnos contigo sobre tus consultas y transacciones.</li>
              <li>Mejorar y personalizar tu experiencia utilizando nuestros servicios.</li>
              <li>Cumplir con las regulaciones legales y las obligaciones contractuales.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>3. Protección de la Información Personal</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Nos comprometemos a proteger tu información personal y empleamos medidas de seguridad físicas, técnicas y
              administrativas para protegerla contra el acceso no autorizado, la divulgación o el uso indebido.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>4. Compartir Información Personal</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              No compartimos tu información personal con terceros, excepto en las siguientes circunstancias:
            </p>
            <ul style={{ padding: '5px 30px' }}>
              <li>Con tu consentimiento explícito.</li>
              <li>Cuando sea necesario para cumplir con las regulaciones legales y las obligaciones contractuales.</li>
              <li>
                Con nuestros proveedores de servicios externos que nos ayudan a proporcionar nuestros servicios, siempre
                que estos proveedores estén sujetos a obligaciones de confidencialidad.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>5. Acceso y Control de tu Información Personal</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Puedes acceder, corregir o eliminar tu información personal en cualquier momento poniéndote en contacto
              con nosotros a través de los medios proporcionados en nuestro sitio web.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>6. Cambios en la Política de Privacidade</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Nos reservamos el derecho de modificar o actualizar esta política de privacidad en cualquier momento. Te
              recomendamos que revises periódicamente esta política para estar al tanto de cualquier cambio.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-5 broker-landing__horizontal-info__kindservices__card__titlecard">
            <h6>7. Consentimiento</h6>
          </Col>
          <Col md={12}>
            <p className="mb-3">
              Al utilizar nuestros servicios de intermediación de seguros y visitar nuestro sitio web, aceptas los
              términos de esta política de privacidad.
            </p>
            <p className="mb-3">Al utilizar nuestro sitio web, aceptas estos términos y condiciones en su totalidad.</p>
            <p className="mb-3">
              Si tienes preguntas sobre nuestra política de privacidad, puedes ponerte en contacto con nuestro equipo de
              atención al cliente en aurora@nicoseguros.com.
            </p>
            <p className="mb-3">Fecha de última actualización: 15 de febrero de 2024</p>
          </Col>
        </Row>
      </Col>

      <div className="background-circles">
        <span className="circle-top" />
        <span className="circle-left" />
        <span className="circle-petite-1" />
        <span className="circle-petite-2" />
        <span className="circle-petite-3" />
      </div>
    </Row>
  );
};

export default HomeTerms;
