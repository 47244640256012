import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { FormHeader } from '../../../components';
import { showAdminCompanyRequest } from '../../../requests/admin/companies';
import AdminCompanyInfo from './AdminCompanyInfo';
import basicCompany from './basicCompany';

const AdminCompanyShow = ({ match }) => {
  const modelUrl = '/admin/companies';

  const { id } = match.params;

  const [company, setCompany] = useState(basicCompany);
  const dispatch = useDispatch();

  const handleBreadcrumb = [
    { key: 1, name: 'Empresas', href: modelUrl },
    { key: 2, name: 'Detalle de empresa' }
  ];

  const handleSuccessShow = response => {
    setCompany(camelCaseRecursive(response.data));
  };

  const handleShowCompany = () => {
    showAdminCompanyRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(handleShowCompany, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title={company.name}
      formComponent={<AdminCompanyInfo company={company} />}
    />
  );
};

export default AdminCompanyShow;
