import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { FormikDatePicker, FormikInput, UploadFile } from '../..';
import { formatCurrency, handleDateFormat, textUpperCase } from '../../../services/utils';

const InsuranceEndosementFormCompanyInfo = ({ errors, isSubmitting, onHide, setFieldValue, touched, ...props }) => {
  const { insuranceEndorsement, insurancePolicy, originPath } = props;
  const { companyEndorsement } = insuranceEndorsement;

  const handleFileUrl = file => {
    if (!file) return '';
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
  };

  return (
    <Form>
      <InsuranceEndorsementInfo insuranceEndorsement={insuranceEndorsement} insurancePolicy={insurancePolicy} />
      <hr className="w-100 my-5" />

      <Row>
        <Col lg={3}>
          <Field name="insuranceEndorsement[companyIssueDate]">
            {({ field }) => (
              <FormikDatePicker
                {...field}
                abbr
                label="Fecha de emisión (compañía)"
                placeholder="dd/mm/aaaa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col lg={4}>
          <Field name="insuranceEndorsement[companyEndorsementNumber]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="N° endoso (compañía)"
                placeholder="Número de endoso según la compañía"
                onChange={input => setFieldValue(field.name, textUpperCase(input.target.value))}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>

        <Col lg={5} className="mb-3">
          <Field name="insuranceEndorsement[companyEndorsement]">
            {({ field }) => (
              <UploadFile
                {...field}
                abbr
                fileAccept="application/pdf"
                label="Endoso emitido por la compañía"
                filename={companyEndorsement?.name}
                fileUrl={handleFileUrl(companyEndorsement)}
                onChange={file => setFieldValue(field.name, file)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="mt-5 d-flex justify-content-end">
        <Col md={6} lg={3} xl={2} className="mt-4">
          <Button block variant="cancel" className="no-shadow" to={originPath} as={Link}>
            Cancelar
          </Button>
        </Col>
        <Col md={6} lg={4} xl={3} className="mt-4">
          <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
            Aplicar endoso
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const InsuranceEndorsementInfo = ({ insuranceEndorsement, insurancePolicy }) => {
  const { endorsementDate, endorsementTypeLabel, insuranceItem, variationTotalNetPremium } = insuranceEndorsement;
  const { currency } = insurancePolicy;

  return (
    <Row>
      <HeaderTitle text="Endoso" />
      <BulletElement element="Tipo de endoso" description={endorsementTypeLabel} />
      <BulletElement element="Fecha de endoso" description={handleDateFormat(endorsementDate)} />
      {insuranceItem?.name && <BulletElement element="Ítem modificado" description={insuranceItem?.name} />}
      <BulletElement
        element="Variación prima neta"
        description={formatCurrency({ value: variationTotalNetPremium, addon: ` ${currency}` })}
      />
    </Row>
  );
};

const HeaderTitle = ({ text }) => (
  <Col xs={12}>
    <p className="header-title">{text}</p>
  </Col>
);

const BulletElement = ({ element, description }) => (
  <Col md={6}>
    <p className="bullet-element">
      {element}: {description}
    </p>
  </Col>
);

const setInitialValues = ({ insuranceEndorsement }) => ({ insuranceEndorsement });

const validationSchema = Yup.object().shape({
  insuranceEndorsement: Yup.object().shape({
    companyEndorsement: Yup.mixed().required('Debes adjuntar un endoso'),
    companyEndorsementNumber: Yup.string().required('Debes ingresar un número de endoso'),
    companyIssueDate: Yup.date()
      .required('Debes ingresar una fecha de emisión')
      .formatdate()
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(InsuranceEndosementFormCompanyInfo);
