import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { FormHeader } from '../../components';
import { createAccountRequest } from '../../requests/accounts';
import { scrollToTop } from '../../services/utils';
import AccountForm from './AccountForm';
import basicAccount from './basicAccount';

const AccountNew = () => {
  const modelUrl = '/accounts';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Cuentas', href: modelUrl },
    { key: 2, name: 'Crear nueva cuenta' }
  ];

  const handleSuccessCreate = response => {
    dispatch(sendAlert({ kind: 'success', message: 'Cuenta creada con éxito' }));
    history.push(`${modelUrl}/${response.data.id}`);
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = snakeCaseKeys(values);
    createAccountRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  useEffect(scrollToTop, []);

  return (
    <div>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={<AccountForm action="new" account={basicAccount} formRequest={handleCreateRequest} />}
      />
    </div>
  );
};

export default AccountNew;
