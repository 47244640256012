import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useAbility } from '@casl/react';

import { FormHeader } from '../../../components';
import { basicLead } from '../../../components/Lead/basicLead';
import LeadInfo from '../../../components/Lead/LeadInfo';
import { HeaderTag } from '../../../components/Shared';
import { AbilityContext } from '../../../config/abilityContext';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminLeadRequest, showAdminLeadRequest } from '../../../requests/admin/lead';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';

const AdminLeadShow = ({ match, location }) => {
  const { id } = match.params;
  const modelUrl = '/admin/leads';
  const showPath = `${modelUrl}/${id}`;

  const [lead, setLead] = useState(basicLead);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(true);
  const [prevFiltersParams, setPrevFiltersParams] = useState(null);

  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { account, closeStatus, hasProposal, insurancePolicyContract, insurancePolicyProposal } = lead;
  const hasContract = insurancePolicyContract.id;

  const handleBreadcrumb = [
    { key: 1, name: 'Ventas', href: modelUrl },
    { key: 2, name: 'Detalle de oportunidad' }
  ];

  const isStep = value => lead.step === value;

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminLeadRequest,
    modelName: 'Oportunidad',
    nameEndsInA: true,
    redirectToUrl: modelUrl,
    infoText:
      isStep('quotation') &&
      'Si no lograste hacer un cierre exitoso, es mejor marcar la oportunidad como "cierre sin éxito" para mantener el registro.'
  });

  const handleCreateInsurancePolicy = ({ isRenewal, policyType }) => {
    redirectTo(history, '/admin/insurance_policies/new', {
      policyType,
      isRenewal,
      leadModelUrl: modelUrl,
      leadPath: showPath,
      lead
    });
  };

  const canCreatePolicy = () => {
    return isStep('closed') && ['success', 'proposal_generated'].includes(closeStatus);
  };

  const canRequestQuotation = () => {
    return isStep('oportunity') && ability.can('manage', 'Lead');
  };

  const thirdBtnAction = () => {
    if (isStep('oportunity'))
      return {
        icon: 'add',
        name: 'Solicitar Cotización',
        path: `${showPath}/request_quotation`
      };
    if (hasContract)
      return {
        icon: 'notes',
        iconSize: 'sm',
        name: 'Póliza',
        onClick: () => redirectTo(history, `/admin/insurance_policies/${insurancePolicyContract.id}`)
      };
    return {
      icon: 'add',
      name: hasProposal ? 'Crear Póliza' : 'Generar Propuesta',
      onClick: () =>
        handleCreateInsurancePolicy({
          isRenewal: lead.isRenewal,
          policyType: hasProposal ? 'contract' : 'proposal'
        })
    };
  };

  const fourthBtnAction = () => {
    return {
      icon: 'add',
      name: 'Generar Propuesta',
      onClick: () => handleCreateInsurancePolicy({ isRenewal: false, policyType: 'proposal' })
    };
  };

  const handleSuccessShow = response => {
    setLead(camelCaseEmptyStringRecursive(response.data));
    setOnRequest(false);
  };

  const handleShowLead = () => {
    setOnRequest(true);
    showAdminLeadRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (!state) return;
    setPrevFiltersParams(state.currentParams);
  };

  const secondBtnOptions = () => {
    return {
      isIconTooltip: true,
      className: 'edit-btn',
      variant: 'primary-dark',
      icon: 'pen',
      name: 'Editar',
      path: hasProposal ? `/admin/insurance_policies/${insurancePolicyProposal.id}/edit` : `${modelUrl}/${id}/edit`
    };
  };

  useEffect(scrollToTop, []);
  useEffect(handleSetFiltersOnMount, []);
  useEffect(handleShowLead, [moreData]);

  return onRequest ? (
    <div className="containerSpinnerLoad" style={{ height: '100%' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title={account.name}
      headerTag={!lead.requested && <HeaderTag variant="warning" text="Por solicitar cotización" />}
      headerBtn={!isStep('closed')}
      headerBtnOptions={{
        isIconTooltip: true,
        variant: 'strong-danger',
        icon: 'trash',
        name: 'Eliminar Oportunidad',
        onClick: () => handleShowModal(lead)
      }}
      secondBtn
      secondBtnOptions={secondBtnOptions()}
      thirdBtn={canRequestQuotation() || canCreatePolicy() || hasContract}
      thirdBtnOptions={thirdBtnAction()}
      fourthBtn={isStep('oportunity')}
      fourthBtnOptions={fourthBtnAction()}
      previousFilterParams={prevFiltersParams}
      formComponent={<LeadInfo userModule="admin" modelUrl={modelUrl} lead={lead} setLead={setLead} setMoreData={setMoreData} />}
    >
      <OnDestroyModalComponent />
    </FormHeader>
  );
};

export default AdminLeadShow;
