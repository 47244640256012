import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminIndustryRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/industries', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showAdminIndustryRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/industries/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminIndustryRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/industries', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminIndustryRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/industries/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminIndustryRequest = (id, { dispatch, failureCallback, successCallback, callback, source }) =>
  ApiService.request('delete', `/admin/industries/${id}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const debounceIndexAdminIndustryRequest = AwesomeDebouncePromise(indexAdminIndustryRequest, 300);
