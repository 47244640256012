import infoCardElement from '../../../components/Utils/Cards/InfoCardMethods';

const endorsementObject = ({ insurancePolicy, setModalShow }) => {
  const { insuranceEndorsementsAttributes } = insurancePolicy;

  const object = {};

  object.dislayInsuranceEndorsements = infoCardElement({
    file: true,
    item: {
      title: `${insuranceEndorsementsAttributes.length} ${
        insuranceEndorsementsAttributes.length > 1 ? 'Endosos' : 'Endoso'
      }`,
      name: 'Ver endosos',
      onClick: () => setModalShow(true)
    }
  });

  return object;
};

export default endorsementObject;
