import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../../services/utils';
import basicInsuranceEndorsement from './basicInsuranceEndorsement';
import { InsuranceEndosementFormCompanyInfo } from '../../../components/InsuranceEndorsement/Form';
import {
  showInsuredInsuranceEndorsementRequest,
  updateInsuredCompanyInfoEndorsementRequest
} from '../../../requests/insured/insuredInsuranceEndorsements';

const InsuredInsuranceEndorsementCompanyEdit = ({ location, match }) => {
  const action = 'companyEdit';
  const { id } = match.params;
  const { insurancePolicy = {}, modelUrl, originPath } = location.state || {};

  const [insuranceEndorsement, setInsuranceEndorsement] = useState(basicInsuranceEndorsement);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', onClick: () => redirectTo(history, modelUrl) },
    { key: 2, name: 'Detalle de póliza', onClick: () => redirectTo(history, originPath) },
    { key: 3, name: 'Aplicar endoso' }
  ];

  const handleSuccessUpdate = ({ message }) => {
    dispatch(sendAlert({ kind: 'success', message }));
    redirectTo(history, originPath);
  };

  const handleUpdateCompanyInfoRequest = (values, setSubmitting) => {
    const { companyEndorsement } = values.insuranceEndorsement;
    const sendParams = snakeCaseKeys(values);

    sendParams.insurance_endorsement.company_endorsement = companyEndorsement;

    updateInsuredCompanyInfoEndorsementRequest(insurancePolicy.id, id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleSuccessUpdate({ message: 'Endoso aplicado con éxito' }),
      callback: () => setSubmitting(false)
    });
  };

  const fetchInsuranceEndorsement = () => {
    showInsuredInsuranceEndorsementRequest(insurancePolicy.id, id, {
      dispatch,
      successCallback: response => setInsuranceEndorsement(camelCaseEmptyStringRecursive(response.data))
    });
  };

  useEffect(scrollToTop, []);
  useEffect(fetchInsuranceEndorsement, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <InsuranceEndosementFormCompanyInfo
          action={action}
          insurancePolicy={insurancePolicy}
          insuranceEndorsement={insuranceEndorsement}
          formRequest={handleUpdateCompanyInfoRequest}
          originPath={originPath}
        />
      }
    />
  );
};

export default InsuredInsuranceEndorsementCompanyEdit;
