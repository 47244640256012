import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalBodyIcon } from '../Shared';
import { sendPaymentReminderRequest } from '../../requests/admin/adminPremiumCollections';
import { sendAlert } from '../../actions/utils';

const EmailPremiumCollectionModal = ({ handleModalClose, highlightedText, requestType }) => {
  const [onRequest, setOnRequest] = useState(false);
  const variant = 'strong-danger';
  const highlightedVariant = 'light-danger';
  const iconVariant = 'danger';
  const icon = 'mail';
  const dispatch = useDispatch();

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cobranza enviada con éxito' }));
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    handleModalClose();
  };

  const handleRollbackCloseLeadRequest = () => {
    setOnRequest(true);
    const sendParams = { request_type: requestType };
    sendPaymentReminderRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest,
      callback: () => setOnRequest(false)
    });
  };

  return (
    <ModalBodyIcon
      icon={icon}
      iconVariant={iconVariant}
      content="Enviar"
      highlightedText={highlightedText}
      highlightedVariant={highlightedVariant}
      formRequest={handleRollbackCloseLeadRequest}
      handleModalClose={handleModalClose}
      submitVariant={variant}
      isSubmitting={onRequest}
    />
  );
};

export default EmailPremiumCollectionModal;
