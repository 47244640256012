import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput, FormikSelect } from '../../../../components';
import { useFetchData } from '../../../../hooks';
import { debounceIndexAdminProvincesRequest } from '../../../../requests/admin/provinces';

const AdminRegionForm = ({ commune, action, errors, onHide, submitVariant, touched, setFieldValue }) => {
  const { data: provinces } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminProvincesRequest,
    fetchingErrorMessage: 'Error al buscar las provincias. Por favor inténtelo nuevamente.',
    customParams: {
      for_selector: 'true'
    }
  });

  const submitBtnText = action === 'new' ? 'Crear Comuna' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={6} sm={12}>
          <Field name="commune[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="commune[code]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Código"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Field name="commune[provinceId]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Provincia"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                options={provinces}
                defaultValue={commune.regionId}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                isClearable
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="mt-4">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ commune }) => {
  return {
    commune: {
      ...commune
    }
  };
};

const validationSchema = Yup.object().shape({
  commune: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    code: Yup.string().required('Debes ingresar un codigo'),
    provinceId: Yup.string().required('Debes ingresar una Provincia')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminRegionForm);
