import React from 'react';

const Documents = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46734)">
        <path d="M11.1628 6.88945H6.88854V5.46471H11.1628V6.88945Z" fill="white" />
        <path d="M11.1628 9.73893H6.88854V8.31419H11.1628V9.73893Z" fill="white" />
        <path d="M6.88854 12.5884H11.1628V11.1637H6.88854V12.5884Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.03906 15.4379V2.61523H14.0122V5.46471H16.8617V18.2874H6.88854V15.4379H4.03906ZM12.5875 14.0131V4.03997H5.4638V14.0131H12.5875ZM14.0122 6.88945V15.4379H8.31328V16.8626H15.437V6.88945H14.0122Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46734">
          <rect width="17.0969" height="17.0969" fill="white" transform="translate(1.90234 1.90234)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Documents;
