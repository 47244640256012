import React from 'react';
import AccountSection from './AccountSection';
import { PeopleSection } from '../../Shared';

const LeadFormFirstTab = ({ fromAdmin, modelName, userModule }) => {
  return (
    <>
      <AccountSection fromAdmin={fromAdmin} modelName={modelName} userModule={userModule} />
      <PeopleSection modelName={modelName} userModule={userModule} />
    </>
  );
};

export default LeadFormFirstTab;
