import React from 'react';

import { LoginCatchphrase, LoginNavbar } from '../../../components/Auth';
import ResetPasswordBox from './ResetPasswordBox';

const ResetPasswordIndex = () => {
  return (
    <>
      <div className="login-index">
        <LoginNavbar />
        <LoginCatchphrase />
        <ResetPasswordBox />
      </div>
      <div className="login-index--circles">
        <span className="circle circle-big" />
        <span className="circle white circle-petite-1" />
        <span className="circle white circle-petite-2" />
        <span className="circle white circle-petite-3" />
        <span className="circle white circle-bottom" />
        <span className="circle circle-colored" />
      </div>
    </>
  );
};

export default ResetPasswordIndex;
