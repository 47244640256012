import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../../../components';

const AdminRegionForm = ({ action, errors, onHide, submitVariant, touched }) => {
  const submitBtnText = action === 'new' ? 'Crear Region' : 'Guardar Cambios';

  return (
    <Form>
      <Row>
        <Col md={6} sm={12}>
          <Field name="region[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6} sm={12}>
          <Field name="region[code]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Código"
                error={getIn(errors, field.name)}
                toucher={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} sm={12} className="mt-4">
          <Button type="submit" variant={submitVariant} size="lg" onClick={onHide} className="w-100">
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ region }) => {
  return {
    region: {
      ...region
    }
  };
};

const validationSchema = Yup.object().shape({
  region: Yup.object().shape({
    name: Yup.string().required('Debes ingresar un nombre'),
    code: Yup.string().required('Debes ingresar un codigo')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminRegionForm);
