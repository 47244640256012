import React from 'react';

const Flag = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8514 6.5433L12.7519 4.16771H6.54466V8.91888H12.7519L10.8514 6.5433ZM16.047 10.5026H6.54466V18.4212H4.96094V2.58398H16.047L12.8796 6.5433L16.047 10.5026Z"
        fill="white"
      />
    </svg>
  );
};

export default Flag;
