import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminSlideRequest } from '../../../requests/admin/slides';
import AdminSlideForm from './AdminSlideForm';
import basicSlide from './basicSlide';

const AdminSlideNew = () => {
  const modelUrl = '/admin/slides';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Slides', href: modelUrl },
    { key: 2, name: 'Crear nueva slide' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Slide creada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const { image } = values.slide;
    const sendParams = snakeCaseKeys(values);
    sendParams.slide.image = image;
    createAdminSlideRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminSlideForm action="new" slide={basicSlide} formRequest={handleCreateRequest} />}
    />
  );
};

export default AdminSlideNew;
