import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import snakeCaseKeys from 'snakecase-keys';
import PropTypes from 'prop-types';

import { ComponentDataTable } from '../../components';
import { conditionalRowStyles, customStyles } from '../../components/Shared/DataTable';
import { emptyFalseParamsRecursive } from '../../services/utils';
import { insurancePolicyFiltersParams } from './Filters/InsurancePolicyFiltersParams';
import InsurancePolicyFiltersHeader from './Filters/InsurancePolicyFiltersHeader';
import defaultColumns from './columns';
import { removeFilters } from '../../actions/utils';

const InsurancePolicyDataTable = ({
  columns,
  customParamsRef,
  defaultParams,
  FilterHeaderComponent,
  filterHeaderComponentProps = {},
  fromAdmin = false,
  handleIndexRequest,
  isFetching,
  modelUrl,
  moreData,
  noSubHeader,
  setMoreData,
  tableData,
  tableDataAmount
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [customParams, setCustomParams] = useState(
    filterHeaderComponentProps.filterParams || insurancePolicyFiltersParams
  );

  const filtersSanitize = (params = {}) => {
    if (noSubHeader) return;
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
    dispatch(removeFilters());
  };

  const handleSortCase = name => {
    switch (name) {
      case 'account':
        return { sort_account: name };
      case 'policy_number':
        return { sort_column: name };
      case 'subject_matter':
        return { sort_column: name };
      case 'insurance_company':
        return { sort_insurance_company: name };
      case 'insurance_category':
        return { sort_insurance_category: name };
      case 'insurance_broker':
        return { sort_insurance_broker: name };
      case 'step_days':
        return { sort_step_days: name };
      default:
        return { sort_priority: name };
    }
  };

  const handleRowClick = row => {
    const { id } = row;
    history.push(`${modelUrl}/${id}`);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <ComponentDataTable
      onRequest={isFetching}
      columns={columns({ fromAdmin, setMoreData })}
      data={tableData}
      totalRows={tableDataAmount}
      moreData={moreData}
      customStyles={customStyles}
      handleSortCase={handleSortCase}
      onRowClicked={(row, e) => handleRowClick(row, e)}
      highlightOnHover
      conditionalRowStyles={conditionalRowStyles}
      customDatatableHeader={
        FilterHeaderComponent ? (
          <FilterHeaderComponent {...filterHeaderComponentProps} />
        ) : (
          <InsurancePolicyFiltersHeader
            fromAdmin={fromAdmin}
            customParams={customParams}
            setCustomParams={setCustomParams}
            filterHeaderComponentProps={filterHeaderComponentProps}
          />
        )
      }
      pointerOnHover
      resourceRequest={handleIndexRequest}
      noSubHeader={noSubHeader}
      defaultParams={{ sortColumn: 'priority', ...defaultParams }}
    />
  );
};

InsurancePolicyDataTable.propTypes = {
  columns: PropTypes.func
};

InsurancePolicyDataTable.defaultProps = {
  columns: defaultColumns
};

export default InsurancePolicyDataTable;
