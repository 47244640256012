import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { ButtonTooltip } from '../Tooltips';

const DataTableInput = ({
  id,
  attribute,
  defaultValue,
  inputType,
  maxLength,
  placeholder,
  size,
  tooltipHtml,
  tooltipText,
  updateDataTable,
  updateRequest,
  ...props
}) => {
  return (
    <InputGroup className="datatable-input">
      <Form.Control
        type={inputType}
        maxLength={maxLength}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={({ target }) => updateRequest({ id, attribute, value: target.value, updateDataTable })}
        {...props}
      />
      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
    </InputGroup>
  );
};

export default DataTableInput;
