import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { camelCaseEmptyStringRecursive } from '../services/utils';

const useIndexFilters = ({ originPath }) => {
  const [filterStatus, setFilterStatus] = useState({ allFilters: {}, isAnUpdate: false, originPath: '' });
  const location = useLocation();

  const handleOnChangeState = () => {
    if (filterStatus.isAnUpdate) return;

    let newFilterStatus = filterStatus;
    if (location.state) newFilterStatus = { ...newFilterStatus, ...location.state };
    if (originPath) newFilterStatus = { ...newFilterStatus, originPath };
    setFilterStatus(camelCaseEmptyStringRecursive(newFilterStatus));
  };

  useEffect(handleOnChangeState, [location, originPath]);

  return { filterStatus, setFilterStatus };
};

export default useIndexFilters;
