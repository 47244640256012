import camelCaseRecursive from 'camelcase-keys-recursive';
import { utilsTypes } from '../actions/utils';

const initialState = {
  alert: {
    show: false,
    title: '',
    kind: '',
    timeout: 3000,
    message: ''
  },
  filters: {
    filterOnMount: false,
    params: {},
    tasksFilterTabsCounter: {}
  },
  insuranceCompanyLogos: [],
  notifications: {
    data: [],
    unread: 0,
    pendings: 0
  },
  ongoingRequest: {
    getInsuranceCompanyLogos: false,
    getNotifications: false,
    getPage: false,
    getPendings: false,
    getSettings: false,
    getSlides: false
  },
  page: {
    body: null
  },
  settings: {
    iva: '',
    mainContactEmail: ''
  },
  slides: [],
  uiComponents: {
    showSidebar: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case utilsTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: true }
      };
    case utilsTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: camelCaseRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SLIDES_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: true }
      };
    case utilsTypes.GET_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.result,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_SLIDES_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: true },
        url: action.url
      };
    case utilsTypes.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.result,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          kind: action.kind,
          timeout: action.timeout || initialState.alert.timeout,
          message: action.message,
          title: action.title
        }
      };
    case utilsTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    case utilsTypes.SET_SIDEBAR_SHOW:
      return {
        ...state,
        uiComponents: {
          ...state.uiComponents,
          showSidebar: action.payload
        }
      };
    case utilsTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterOnMount: true,
          params: { ...action.payload }
        }
      };
    case utilsTypes.REMOVE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterOnMount: false,
          params: {}
        }
      };
    case utilsTypes.SET_TASKS_FILTER_TABS_COUNT:
      return {
        ...state,
        filters: {
          ...state.filters,
          tasksFilterTabsCounter: { ...action.payload }
        }
      };
    case utilsTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getNotifications: true }
      };
    case utilsTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: { ...state.notifications, data: camelCaseRecursive(action.result.data) },
        ongoingRequest: { ...state.ongoingRequest, getNotifications: false }
      };
    case utilsTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getNotifications: false }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getUnreadNotifications: true }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: { ...state.notifications, unread: action.result },
        ongoingRequest: { ...state.ongoingRequest, getUnreadNotifications: false }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getUnreadNotifications: false }
      };
    case utilsTypes.GET_PENDINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPendings: true }
      };
    case utilsTypes.GET_PENDINGS_SUCCESS:
      return {
        ...state,
        notifications: { ...state.notifications, pendings: action.result },
        ongoingRequest: { ...state.ongoingRequest, getPendings: false }
      };
    case utilsTypes.GET_PENDINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPendings: false }
      };
    case utilsTypes.GET_INSURANCE_COMPANY_LOGOS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getInsuranceCompanyLogos: true }
      };
    case utilsTypes.GET_INSURANCE_COMPANY_LOGOS_SUCCESS:
      return {
        ...state,
        insuranceCompanyLogos: camelCaseRecursive(action.result.data),
        ongoingRequest: { ...state.ongoingRequest, getInsuranceCompanyLogos: false }
      };
    case utilsTypes.GET_INSURANCE_COMPANY_LOGOS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getInsuranceCompanyLogos: false }
      };
    default:
      return state;
  }
};

export default reducer;
