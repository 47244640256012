import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { readAllNotificationsRequest } from '../../requests/notifications';
import NotificationItem from './NotificationItem';
import { getNotifications, getUnreadNotifications } from '../../actions/utils';

const NotificationList = ({ handleClose }) => {
  const dispatch = useDispatch();

  const {
    notifications: { data: notificationsList }
  } = useSelector(state => state.utils);

  const handleSuccessReadAllRequest = () => {
    dispatch(getUnreadNotifications());
  };

  const handleReadAllNotifications = () => {
    readAllNotificationsRequest({
      dispatch,
      successCallback: handleSuccessReadAllRequest
    });
  };

  const handleManageNotificationsWorkflow = () => {
    dispatch(getNotifications());
    return handleReadAllNotifications;
  };

  useEffect(handleManageNotificationsWorkflow, []);

  return (
    <div className="notification-list">
      <div className="notification-list--header">
        <p className="font-weight-bold">Notificaciones</p>
      </div>
      <div className="notification-list--items">
        {notificationsList.length ? (
          <>
            {notificationsList.map(notification => (
              <NotificationItem key={notification.id} notification={notification} handleClose={handleClose} />
            ))}
          </>
        ) : (
          <div className="pt-4">
            <p className="text-center">No tienes ninguna notificación para mostrar</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
