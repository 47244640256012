import React from 'react';
import memoize from 'memoize-one';
import { DataTableBadges, DatatableMainInfo, DataTableTooltipHeaderName } from '../../../components';
import { DatatableTaskCreator, basicTask } from '../../../components/Task';

const columns = memoize(({ setMoreData = () => null }) => {
  return [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      grow: '2',
      minWidth: '150px',
      cell: ({ name, nationalIdentification, rowStyle, icon }) => (
        <DatatableMainInfo
          icon={icon}
          title={name}
          btnIconStyle={rowStyle}
          subtitle={nationalIdentification}
          iconSize="sm"
        />
      )
    },
    {
      name: 'Corredor',
      selector: 'insurance_broker',
      sortable: true,
      grow: '1.5',
      cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
        <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
      )
    },
    {
      name: 'F. creación',
      selector: 'created_at',
      sortable: true,
      grow: '1',
      cell: ({ createdAt }) => createdAt
    },
    {
      name: 'P. vigentes',
      selector: 'valid_policies_count',
      sortable: true,
      grow: '1',
      cell: ({ validInsurancePoliciesCount }) => validInsurancePoliciesCount,
      style: {
        fontWeight: 'bold'
      }
    },
    {
      name: 'Prima total neta',
      selector: 'valid_total_net_premium',
      sortable: true,
      grow: '1',
      cell: ({ parsedPoliciesValidTotalNetPremium }) => parsedPoliciesValidTotalNetPremium
    },
    {
      name: (
        <DataTableTooltipHeaderName
          html
          headerName="Comisión total neta"
          tooltipText="Esta es la comisión total que recibirás<br/>
                       por esta cuenta. Todos los descuentos<br/>
                       ya han sido aplicados."
        />
      ),
      selector: 'total_broker_commission_amount',
      sortable: true,
      grow: '1.25',
      cell: ({ parsedTotalBrokerCommissionAmount }) => parsedTotalBrokerCommissionAmount || 0
    },
    {
      name: 'Tareas',
      selector: 'tasks',
      sortable: false,
      grow: '1',
      minWidth: '130px',
      cell: ({ task = basicTask, id: accountId }) => (
        <DatatableTaskCreator
          shortBtn
          fromAdmin
          task={task}
          taskableType="Account"
          taskableId={accountId}
          setMoreData={setMoreData}
        />
      )
    },
    {
      cell: row => <DataTableBadges row={row} />,
      sortable: false,
      right: true,
      grow: '2'
    }
  ];
});

export default columns;
