const uploadTypes = {
  COMMISSION: 'commission',
  PAYMENT: 'payment'
};

// const uploadTypes = [
//   { label: 'comisión informada', value: 'commission' },
//   { label: 'pagos a asociados', value: 'payment' },
// ];
// eslint-disable-next-line import/prefer-default-export
export { uploadTypes };
