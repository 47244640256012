import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexInsuredAccountRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/insured/accounts', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const showInsuredAccountRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insured/accounts/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateInsuredAccountRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insured/accounts/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const insuredAccountPoliciesSummaryRequest = (
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insured/accounts/${id}/policies_summary`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createInsuredAccountRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/insured/accounts', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexInsuredAccountRequest = AwesomeDebouncePromise(indexInsuredAccountRequest, 300);
export const debouncedInsuredAccountPoliciesSummaryRequest = AwesomeDebouncePromise(
  insuredAccountPoliciesSummaryRequest,
  1000
);
export const debounceUpdateInsuredAccountRequest = AwesomeDebouncePromise(updateInsuredAccountRequest, 1000);
