import React from 'react';
import { getIn, useFormikContext } from 'formik';
import FormikSelect from '../../../components/Utils/Select/FormikSelect';

const CadenceSelect = ({ disabled, field, modelName }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();

  const options = [
    { value: 'unique', label: 'Única' },
    { value: 'monthly', label: 'Mensual' }
  ];

  const cadenceType = getIn(values, field.name);

  const cadenceTooltip = () => {
    return `Única: la prima y comisión se calculan y pagan una sola vez al emitir la póliza. Común en seguros generales.<br/>
    Mensual: la prima y comisión se calculan y pagan todos los meses de vigencia de la póliza. Común en seguros de vida.`;
  };

  return (
    <FormikSelect
      abbr
      label="Cadencia"
      placeholder="Selecciona una opción"
      defaultValue={cadenceType}
      isDisabled={disabled}
      options={options}
      error={getIn(errors, field.name)}
      value={cadenceType}
      onChange={option => {
        setFieldValue(`${modelName}[translatedCadenceType]`, option.label || '');
        setFieldValue(field.name, option.value || '');
      }}
      touched={getIn(touched, field.name)}
      tooltipText={cadenceTooltip()}
    />
  );
};

export default CadenceSelect;
