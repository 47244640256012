import React from 'react';
import { Table } from 'react-bootstrap';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminInsuranceCompanyRequest } from '../../../requests/admin/insuranceCompanies';

const InsuranceCompanyTable = ({ selectedEmails, setSelectedEmails, insuranceCategorySelected }) => {
  const columns = [
    { columnName: 'Compañía', selector: 'name', columnStyle: { width: '30%' } },
    { columnName: 'Nombre Ejecutivo de atención', selector: 'executiveFullName', columnStyle: { width: '30%' } },
    { columnName: 'Correo Ejecutivo de atención', selector: 'executiveEmail', columnStyle: { width: '30%' } },
    { columnName: 'Seleccionar', selector: 'checkbox', columnStyle: { width: '10%' } }
  ];

  const { data: insuranceCompanies } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminInsuranceCompanyRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar las empresas. Por favor inténtalo nuevamente',
    customParams: {
      category: insuranceCategorySelected || '',
      withExecutiveEmail: insuranceCategorySelected || '',
      forBasicTable: true
    }
  });

  const handleCheckboxChange = email => {
    setSelectedEmails(prevSelectedEmails => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter(e => e !== email);
      }
      return [...prevSelectedEmails, email];
    });
  };

  return (
    <Table size="sm" responsive className="table__insurance-covers">
      <InsuranceCompanyTableHeader columns={columns} />
      <InsuranceCompanyTableBody
        items={insuranceCompanies}
        columns={columns}
        selectedEmails={selectedEmails}
        handleCheckboxChange={handleCheckboxChange}
      />
    </Table>
  );
};

const InsuranceCompanyTableHeader = ({ columns }) => (
  <thead>
    <tr>
      {columns &&
        columns.map(({ columnName, columnStyle }, index) => (
          <th style={columnStyle} key={columnName.concat('-', index + 1)}>
            {columnName}
          </th>
        ))}
    </tr>
  </thead>
);

const InsuranceCompanyTableBody = ({ items, columns, handleCheckboxChange, selectedEmails }) => (
  <tbody>
    {items.map((insuranceCompany, index) => (
      <tr key={`insurance-company-${index.toString()}`}>
        {columns &&
          columns.map(({ selector, columnName }, coverIndex) => (
            <td className="align-middle" key={columnName.concat('-', coverIndex + 1)}>
              {selector === 'checkbox' ? (
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(insuranceCompany.executiveEmail)}
                  checked={selectedEmails.includes(insuranceCompany.executiveEmail)}
                  disabled={!insuranceCompany.executiveEmail}
                />
              ) : (
                insuranceCompany[selector]
              )}
            </td>
          ))}
      </tr>
    ))}
  </tbody>
);

export default InsuranceCompanyTable;
