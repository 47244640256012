import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { SimpleCenteredModal } from '../../components';
import { sendAlert } from '../../actions/utils';
import AdminMovementForm from '../AdminScreens/Beneficiary/form/AdminMovementForm';
import { buklUploadBeneficiaryRequest } from '../../requests/beneficiaries';
import SendDocumentEmail from '../../components/Shared/SendDocumentEmail';
import { ModalBodyIcon } from '../../components/Shared';
import { beneficiaryMovementsProps } from '../../components/Shared/SendEmailModalProps';

const MovementNew = ({ modalShow, handleModalClose, insurancePolicy, setMoreData }) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector(state => state.auth);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalConfirmationBody, setModalConfirmationBody] = useState('');
  const [modalConfirmationShow, setModalConfirmationShow] = useState(false);
  const [beneficiaryMovement, setBeneficiaryMovement] = useState(null);

  const closeEmailModal = () => {
    setMoreData(m => !m);
  };

  const { SendEmailModal, openEmailModal } = SendDocumentEmail({
    fileSection: true,
    fileSectionLabel: 'Adjuntos',
    fileSectionDescription: 'Adjunta aquí todos los formularios de las incorporaciones que estás informando.',
    extraCloseFunction: closeEmailModal,
    executiveModel: { id: insurancePolicy.id, name: 'InsurancePolicy' }
  });

  const handleSuccessRequest = response => {
    if (Array.isArray(response.data)) {
      setErrorMessage(response.data.join(', '));
      if (response.data.length > 0) return;
    } else {
      setBeneficiaryMovement(camelCaseRecursive(response.data));
    }

    openEmailModal();
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Nómina actualizada con éxito' }));
  };

  const handleAdditionsRequest = (values, setSubmitting) => {
    buklUploadBeneficiaryRequest(insurancePolicy.id, {
      dispatch,
      params: snakeCaseKeys(values.beneficiary),
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const handleModalConfirmation = (values, setSubmitting) => {
    setModalConfirmationShow(true);
    setModalConfirmationBody(
      <ModalBodyIcon
        icon="reorder"
        iconVariant="danger"
        iconClass="text-dark"
        submitVariant="strong-danger"
        content="¿Estás seguro de querer enviar los "
        highlightedText="movimientos?"
        highlightedVariant="white"
        subContent="Al enviarlos, se aplicarán los movimientos a la nómina actual y se guardará el registro"
        formRequest={() => handleAdditionsRequest(values, setSubmitting)}
        handleModalClose={() => {
          setModalConfirmationShow(false);
          setSubmitting(false);
        }}
      />
    );
  };

  return (
    <>
      <SimpleCenteredModal
        title="Movimientos"
        body={
          <AdminMovementForm
            beneficiary={{ exclusions: '', additions: '' }}
            formRequest={handleModalConfirmation}
            handleModalClose={handleModalClose}
            errorMessage={errorMessage}
          />
        }
        show={modalShow}
        onHide={handleModalClose}
        size="xl"
      />

      <SimpleCenteredModal
        show={modalConfirmationShow}
        body={modalConfirmationBody}
        onHide={() => null}
        closeButton={false}
      />

      {beneficiaryMovement && (
        <SendEmailModal {...beneficiaryMovementsProps({ beneficiaryMovement, insurancePolicy, currentUser })} />
      )}
    </>
  );
};

export default MovementNew;
