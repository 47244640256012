import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, Legend, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { ButtonTooltip } from '../Tooltips';
import { primaryVarGrey, primaryLightBlue } from '../Color';
import { monthTranslation, legendTranslation, legendColor } from './chartTranslations';

const CustomBarChart = ({ data, title, tooltipText, titleClass }) => (
  <div className="bar-chart--container">
    <div className={`bar-chart--container--title ${titleClass || ''}`}>
      <p className="section-title">{title}</p>
      {tooltipText && <ButtonTooltip tooltipText={tooltipText}>?</ButtonTooltip>}
    </div>
    <div className="bar-chart">
      <ResponsiveContainer width="99%" aspect={3}>
        <BarChart data={data}>
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <Legend content={<CustomLegend />} align="right" />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="renewable" stackId="renewable" fill={primaryLightBlue} radius={[2, 2, 0, 0]} />
          <Bar dataKey="sold" stackId="renewable" fill={primaryVarGrey} radius={[2, 2, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const CustomLegend = ({ payload }) => (
  <ul className="bar-chart--legend-container">
    {payload.map(({ value }) => (
      <li className={`bar-chart--legend--item ${legendColor[value]}`} key={`item-${value}`}>
        {legendTranslation[value]}
      </li>
    ))}
  </ul>
);

const CustomTooltip = ({ active, payload, label }) => {
  const shouldShowTooltip = active && payload && payload.length;
  if (!shouldShowTooltip) return null;
  const { payload: barInfo } = payload[0];
  const { isPast, renewable, sold, total } = barInfo;
  return (
    <div className="bar-chart--tooltip-container">
      <article className="bar-chart--tooltip">
        <p className="detail font-weight-bold mb-3">Resumen para {monthTranslation[label]}</p>
        {!isPast && (
          <p className={`detail font-weight-500 bar-chart--tooltip__item mb-1 ${legendColor.renewable}`}>
            {legendTranslation.renewable}: {renewable} UF
          </p>
        )}
        <p className={`detail font-weight-500 bar-chart--tooltip__item mb-1 ${legendColor.sold}`}>
          {legendTranslation.sold}: {sold} UF
        </p>
        {!isPast && (
          <p className={`detail font-weight-500 bar-chart--tooltip__item ${legendColor.total}`}>
            {legendTranslation.total}: {total} UF
          </p>
        )}
      </article>
    </div>
  );
};

CustomBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      isPast: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      renewable: PropTypes.number.isRequired
    })
  ).isRequired,
  title: PropTypes.string
};

CustomBarChart.defaultProps = {
  title: ''
};

export default CustomBarChart;
