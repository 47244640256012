import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { BulletSwitchBtn, IconBtn } from '../../components';
import { useDestroyOne } from '../../hooks';
import { deleteAccountContactRequest, updateAccountContactRequest } from '../../requests/Account/contact';
import { deleteAdminAccountContactRequest, updateAdminAccountContactRequest } from '../../requests/admin/adminContacts';
import ContactEdit from './ContactEdit';

const ContactCard = ({
  accountId,
  canBeDeleted,
  contact,
  fromAdmin,
  setMoreData,
  totalCommercialContacts,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const { userModule } = props;

  const { OnDestroyModalComponent, handleShowModal } = useDestroyOne({
    deleteRequest: fromAdmin ? deleteAdminAccountContactRequest : deleteAccountContactRequest,
    parentId: accountId,
    modelName: 'Contacto',
    nameEndsInA: false,
    setMoreData
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="info-card mb-5">
        <header className="info-card--header">
          <p className="section-title text-lowercase">{contact.name}</p>
          <IconBtn
            size="xs"
            icon="pen"
            variant="primary-dark"
            className="btn edit-btn no-shadow"
            onClick={() => setShowModal(true)}
          />
        </header>

        <ContactInfo contact={contact} />
        <ContactMarks fromAdmin={fromAdmin} contact={contact} accountId={accountId} setMoreData={setMoreData} />

        {canBeDeleted && (
          <footer className="info-card--footer pt-0">
            <IconBtn
              onClick={() => handleShowModal(contact)}
              icon="trash"
              className="btn no-shadow danger"
              variant="transparent"
              size="xs"
            />
          </footer>
        )}
      </div>

      <OnDestroyModalComponent />

      <ContactEdit
        handleModalClose={handleModalClose}
        modalShow={showModal}
        setMoreData={setMoreData}
        contact={contact}
        accountId={accountId}
        totalCommercialContacts={totalCommercialContacts}
        userModule={userModule}
      />
    </>
  );
};

const ContactInfo = ({ contact }) => {
  const { birthday, comments, email, fullAddress, phoneNumber, position } = contact;

  return (
    <div className="mb-5">
      <div className="grid-two-columns">
        <InfoLine title="Correo electrónico" value={email} />
        <InfoLine title="Teléfono" value={phoneNumber} />
        <InfoLine title="Fecha de nacimiento" value={birthday} />
        <InfoLine title="Cargo" value={position} />
      </div>
      <InfoLine title="Dirección" value={fullAddress} />
      <InfoLine title="Comentarios" value={comments} />
    </div>
  );
};

const InfoLine = ({ title, value }) => (
  <p className="bullet-element">
    {title}: {value || 'Sin especificar'}
  </p>
);

const ContactMarks = ({ accountId, contact, fromAdmin, setMoreData }) => {
  const { id: contactId, isInsuredUser } = contact;
  const dispatch = useDispatch();

  const handleUpdateRequest = ({ attribute, value }) => {
    const updateRequest = fromAdmin ? updateAdminAccountContactRequest : updateAccountContactRequest;
    const sendParams = snakecaseKeys({ contact: { [attribute]: value } });

    updateRequest(accountId, contactId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Contacto actualizado con éxito' }));
        setMoreData(m => !m);
      }
    });
  };

  return (
    <>
      <p className="section-title text-lowercase">Marcas de contacto</p>
      <BulletSwitchBtn
        title="Gestor"
        attribute="isInsuredUser"
        initialValue={isInsuredUser}
        onClick={handleUpdateRequest}
        tooltipText="Al estar habilitado como 'Gestor', <br/>
                     el contacto/usuario podrá ingresar <br/>
                     a NICO y revisar toda la información <br/>
                     relacionada a esta Cuenta."
      />
    </>
  );
};

export default ContactCard;
