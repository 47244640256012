import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import { IconBtn } from '../../Utils/Button';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const ClientLandingInsuredPersonsHeader = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <main id="mainpartner" className="client-landing--main__heroclientsplans">
      <div className="client-landing--main__heroclientsplans__container client-landing--main__heroclientsplans__container__inner justify-content-center">
        <div className="landing--main__info col-sm-12 col-md-4 ">
          <svg width="104" height="105" viewBox="0 0 104 105" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M51.9987 74.1367C42.4258 74.1367 34.6654 66.3763 34.6654 56.8034H69.332C69.332 66.3763 61.5716 74.1367 51.9987 74.1367Z"
              fill="#29E5F5"
            />
            <path
              d="M60.6654 43.8034C60.6654 46.1966 62.6055 48.1367 64.9987 48.1367C67.3919 48.1367 69.332 46.1966 69.332 43.8034C69.332 41.4101 67.3919 39.4701 64.9987 39.4701C62.6055 39.4701 60.6654 41.4101 60.6654 43.8034Z"
              fill="#29E5F5"
            />
            <path
              d="M38.9987 48.1367C36.6055 48.1367 34.6654 46.1966 34.6654 43.8034C34.6654 41.4101 36.6055 39.4701 38.9987 39.4701C41.3919 39.4701 43.332 41.4101 43.332 43.8034C43.332 46.1966 41.3919 48.1367 38.9987 48.1367Z"
              fill="#29E5F5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.66537 52.47C8.66537 76.4024 28.0664 95.8034 51.9987 95.8034C75.931 95.8034 95.332 76.4024 95.332 52.47C95.332 28.5377 75.931 9.13672 51.9987 9.13672C28.0664 9.13672 8.66537 28.5377 8.66537 52.47ZM17.332 52.47C17.332 71.6159 32.8528 87.1367 51.9987 87.1367C71.1446 87.1367 86.6654 71.6159 86.6654 52.47C86.6654 33.3242 71.1446 17.8034 51.9987 17.8034C32.8528 17.8034 17.332 33.3242 17.332 52.47Z"
              fill="#29E5F5"
            />
          </svg>
          <h1>
            Asesórate <br /> con NICO
          </h1>
          <h6 className="client-landing--main__heroclientsplans__subtitle">
            Encuentra la mejor protección y administra todos tus seguros en un solo lugar.
          </h6>
          <IconBtn
            onClick={() => navigateToContact()}
            className="main-button btn-landing w-mobile-100 mt-5"
            variant="secondary"
          >
            Quiero asesoría GRATIS
          </IconBtn>
        </div>
        <div className="col-sm-12 col-md-6 client-landing--main__heroclientsplans__card p-5">
          <Row className=" align-items-center client-landing--main__heroclientsplans__card__container">
            <div className="col-2 pr-0">
              <div className="client-landing--main__heroclientsplans__card__container__iconcontainer">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.2077 8.25H16.791V23.75H14.2077V8.25Z" fill="#29E5F5" />
                  <path d="M9.04102 17.2917H11.6243V23.75H9.04102V17.2917Z" fill="#29E5F5" />
                  <path d="M19.3743 12.125H21.9577V23.75H19.3743V12.125Z" fill="#29E5F5" />
                </svg>
              </div>
            </div>
            <div className="col-10 pl-0 client-landing--main__heroclientsplans__card">
              <p>
                <span style={{ fontWeight: '900' }}>La mejor protección te la entrega quien mejor te conoce.</span>{' '}
                Contrata todos tus seguros a través de NICO y asegúrate de tener la mejor protección, sin coberturas
                duplicadas, sin vacíos y sin sorpresas.
              </p>
            </div>
          </Row>
          <Row className=" align-items-center client-landing--main__heroclientsplans__card__container">
            <div className="col-2 pr-0">
              <div className="client-landing--main__heroclientsplans__card__container__iconcontainer">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.82669 21.7263L0 19.8996L9.13346 10.7661L17.3536 18.9863L22.8337 13.5062L20.5831 11.2556L29.2271 8.93945L26.9109 17.5835L24.6603 15.3329L17.3536 22.6396L9.13346 14.4195L1.82669 21.7263Z"
                    fill="#29E5F5"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 pl-0 client-landing--main__heroclientsplans__card">
              <p>
                <span style={{ fontWeight: '900' }}>Tu protección ordenada como nunca antes.</span> Administra todas tus
                pólizas en un solo sitio. Haz log-in y accede a toda tu información siempre actualizada y ordenada.
                Revisa pagos, renovaciones y siniestros, entre otros.
              </p>
            </div>
          </Row>
          <Row className=" align-items-center client-landing--main__heroclientsplans__card__container">
            <div className="col-2 pr-0">
              <div className="client-landing--main__heroclientsplans__card__container__iconcontainer">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5017 21.9456C12.6531 21.9456 10.3439 19.6364 10.3439 16.7877H20.6596C20.6596 19.6364 18.3504 21.9456 15.5017 21.9456Z"
                    fill="#29E5F5"
                  />
                  <path
                    d="M18.0807 12.9193C18.0807 13.6315 18.658 14.2088 19.3702 14.2088C20.0823 14.2088 20.6596 13.6315 20.6596 12.9193C20.6596 12.2071 20.0823 11.6298 19.3702 11.6298C18.658 11.6298 18.0807 12.2071 18.0807 12.9193Z"
                    fill="#29E5F5"
                  />
                  <path
                    d="M11.6333 14.2088C10.9212 14.2088 10.3439 13.6315 10.3439 12.9193C10.3439 12.2071 10.9212 11.6298 11.6333 11.6298C12.3455 11.6298 12.9228 12.2071 12.9228 12.9193C12.9228 13.6315 12.3455 14.2088 11.6333 14.2088Z"
                    fill="#29E5F5"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.60701 15.4983C2.60701 22.6198 8.38018 28.393 15.5017 28.393C22.6233 28.393 28.3965 22.6198 28.3965 15.4983C28.3965 8.37669 22.6233 2.60352 15.5017 2.60352C8.38018 2.60352 2.60701 8.37669 2.60701 15.4983ZM5.18596 15.4983C5.18596 21.1955 9.8045 25.814 15.5017 25.814C21.199 25.814 25.8175 21.1955 25.8175 15.4983C25.8175 9.801 21.199 5.18246 15.5017 5.18246C9.8045 5.18246 5.18596 9.801 5.18596 15.4983Z"
                    fill="#29E5F5"
                  />
                </svg>
              </div>
            </div>
            <div className="col-10 pl-0 client-landing--main__heroclientsplans__card">
              <p>
                <span style={{ fontWeight: '900' }}>Atención en tiempo y forma.</span> Además de darte acceso y control,
                NICO siempre se anticipa y te mantiene al tanto de todo lo que viene. Renovaciones, prórrogas o deudas,
                tu ejecutivo está siempre encima.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </main>
  );
};

export default ClientLandingInsuredPersonsHeader;
