const accountFiltersParams = {
  query: '',
  dateFrom: '',
  dateTo: '',
  clientType: 'actual',
  isVip: '',
  legalPersonType: '',
  natural: '',
  legal: '',
  isNonWantedClient: '',
  withDebt: '',
  withSinister: ''
};

const accountAdvancedOptions = [
  { label: 'Natural', value: 'natural' },
  { label: 'Jurídica', value: 'legal' },
  { label: 'Vip', value: 'isVip' },
  { label: 'Por vencer', value: 'nearInvalidity' },
  { label: 'Siniestro', value: 'withSinister' },
  { label: 'Deuda', value: 'withDebt' },
  { label: 'No deseado', value: 'isNonWantedClient' }
];

const accountTabsOptions = [
  { label: 'Clientes', value: 'actual' },
  { label: 'No Clientes', value: 'ex' },
  { label: 'Todas', value: 'all' }
];

const legalPersonTypeOptions = [
  { label: 'Natural', value: 'natural' },
  { label: 'Jurídica', value: 'legal' }
];

export { accountAdvancedOptions, accountFiltersParams, accountTabsOptions, legalPersonTypeOptions };
