import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';

import LeadQuotationFields from './LeadQuotationFields';

const LeadQuotationForm = ({ action, insuranceCategory, onHide }) => {
  const submitBtnText = action === 'new' ? 'Cargar' : 'Guardar cambios';
  const modelName = 'leadQuotation';

  return (
    <Form>
      <LeadQuotationFields modelName={modelName} insuranceCategory={insuranceCategory} />
      <Row className="d-flex flex-row-reverse mt-4">
        <Col md={5} className="mt-4">
          <Button type="submit" variant="submit" size="lg" className="w-100 no-shadow">
            {submitBtnText}
          </Button>
        </Col>
        <Col md={4} className="mt-4">
          <Button variant="cancel" size="lg" className="w-100 no-shadow" onClick={onHide}>
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ leadQuotation }) => ({ leadQuotation });

const validationSchema = Yup.object().shape({
  leadQuotation: Yup.object().shape({
    affectCommissionPercentage: Yup.string().nullable(),
    exemptCommissionPercentage: Yup.string().nullable(),
    insuranceCompanyId: Yup.string().required('Debes seleccionar una aseguradora'),
    name: Yup.string().required('Debes ingresar una pequeña descripción'),
    quotation: Yup.mixed().required('Debes adjuntar una cotización')
  })
});

const handleSubmit = (values, { props }) => {
  const { onHide, formRequest } = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(LeadQuotationForm);
