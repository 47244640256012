import React from 'react';

const Document = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.54167 15.2507H14.4583V13.6673H6.54167V15.2507Z" fill="white" />
      <path d="M14.4583 12.084H6.54167V10.5007H14.4583V12.084Z" fill="white" />
      <path d="M6.54167 8.91732H9.70833V7.33398H6.54167V8.91732Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 2.58398C4.43832 2.58398 3.375 3.64731 3.375 4.95898V16.0423C3.375 17.354 4.43832 18.4173 5.75 18.4173H15.25C16.5617 18.4173 17.625 17.354 17.625 16.0423V8.12565C17.625 5.06507 15.1439 2.58398 12.0833 2.58398H5.75ZM5.75 4.16732H11.2917V8.12565H16.0417V16.0423C16.0417 16.4795 15.6872 16.834 15.25 16.834H5.75C5.31277 16.834 4.95833 16.4795 4.95833 16.0423V4.95898C4.95833 4.52176 5.31277 4.16732 5.75 4.16732ZM12.875 4.2465C14.1506 4.50544 15.2035 5.37782 15.7123 6.54232H12.875V4.2465Z"
        fill="white"
      />
    </svg>
  );
};

export default Document;
