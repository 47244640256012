import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { setTaskFilterTabsCount } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { TaskDataTable } from '../../../components/Task';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminTaskRequest, debounceGetAdminTasksTabCountRequest } from '../../../requests/admin/tasks';
import { scrollToTop } from '../../../services/utils';
import columns from './column';

const AdminAllTasksIndex = () => {
  const modelUrl = '/admin/agenda';
  const dispatch = useDispatch();

  const handleBreadcrumb = [
    { key: 1, name: 'Mi agenda', href: modelUrl },
    { key: 2, name: 'Tareas terminadas' }
  ];

  const customParamsRef = useRef({});
  const { data: tasks, totalData: totalTasks, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminTaskRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar las tareas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current
  });

  const { date_from: dateFrom, date_to: dateTo, query } = customParamsRef.current;

  const handleSuccessGetTasksTabsRequest = response => {
    const filterTabsCount = response.data;
    dispatch(setTaskFilterTabsCount({ ...filterTabsCount }));
  };

  const handleGetTaskTabsCountRequest = () => {
    const sendParams = snakecaseKeys({ ...customParamsRef.current, fromModule: 'admin' });
    delete sendParams.taskable_type;
    debounceGetAdminTasksTabCountRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessGetTasksTabsRequest
    });
  };

  const tooltipText = 'Aquí encontrarás todas las tareas terminadas';

  useEffect(scrollToTop, []);
  useEffect(handleGetTaskTabsCountRequest, [dateFrom, dateTo, query, moreData]);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      title="Tareas terminadas"
      tooltipText={tooltipText}
      formComponent={
        <TaskDataTable
          fromAdmin
          showAllTasks
          columns={columns}
          customParamsRef={customParamsRef}
          handleIndexRequest={handleIndexRequest}
          isFetching={isFetching}
          tableData={tasks}
          tableDataAmount={totalTasks}
          moreData={moreData}
          setMoreData={setMoreData}
        />
      }
    />
  );
};

export default AdminAllTasksIndex;
