import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { useAbility } from '@casl/react';

import { sendAlert } from '../../actions/utils';
import { FormHeader } from '../../components';
import { createInsurancePolicyRequest } from '../../requests/insurancePolicies';
import InsurancePolicyForm from './InsurancePolicyForm';
import basicInsurancePolicy from './basicInsurancePolicy';
import { AbilityContext } from '../../config/abilityContext';

const InsurancePolicyNew = () => {
  const modelUrl = '/insurance_policies';

  const [accountReference, setAccountReference] = useState('');
  const { user: currentUser } = useSelector(state => state.auth);

  const isSigmaBroker = () => {
    return currentUser.insurance_broker_attributes?.broker_type === 'sigma';
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ability = useAbility(AbilityContext);

  const { policyType } = !!location.state && location.state;

  const handleBreadcrumb = [
    { key: 1, name: 'Pólizas', href: modelUrl },
    { key: 2, name: 'Crear nueva póliza' }
  ];

  const handleRedirectToBeneficiaries = response => {
    if (response.data.insurance_company.category === 'life') {
      history.push({
        pathname: `${modelUrl}/${response.data.id}/beneficiaries`,
        state: { originPath: `${modelUrl}/${response.data.id}`, insurancePolicy: response.data }
      });
    } else {
      history.push(`${modelUrl}/${response.data.id}`);
    }
  };

  const handleSuccessCreate = response => {
    dispatch(sendAlert({ kind: 'success', message: 'Póliza creada con éxito' }));
    handleRedirectToBeneficiaries(response);
  };

  const handleParams = ({ contract, contractProposal, ...insurancePolicy }) => {
    let sendParams = snakeCaseKeys(insurancePolicy);
    sendParams = { ...sendParams, contract, contract_proposal: contractProposal, policy_type: policyType };
    return sendParams;
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const sendParams = handleParams(values.insurancePolicy);
    createInsurancePolicyRequest({
      dispatch,
      params: { insurance_policy: sendParams },
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  const handleSetAccountOnMount = () => {
    const { state } = location;
    if (!state) return;
    const { accountId } = state;
    setAccountReference(accountId);
  };

  const disableNicoCode = () => {
    return ability.can('manage', 'InsurancePolicy');
  };

  const canManageInsurancePolicy = () => {
    return ability.can('manage', 'Admin') || ability.can('manage', 'InsurancePolicy');
  };

  useEffect(handleSetAccountOnMount, [location]);

  if (!canManageInsurancePolicy()) {
    return <Redirect to={modelUrl} />;
  }

  return (
    <div>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        formComponent={
          <InsurancePolicyForm
            userModule="broker"
            action="new"
            modelUrl={modelUrl}
            insurancePolicy={{
              ...basicInsurancePolicy,
              accountId: accountReference,
              ...(isSigmaBroker() && { nicoCode: 'ext', translatedNicoCode: 'Ext' })
            }}
            formRequest={handleCreateRequest}
            disableNicoCode={disableNicoCode()}
          />
        }
      />
    </div>
  );
};

export default InsurancePolicyNew;
