import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminAccountRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('get', '/admin/accounts', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const showAdminAccountRequest = (
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/accounts/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createAdminAccountRequest = ({
  dispatch,
  params,
  formData,
  successCallback,
  failureCallback,
  callback,
  source
}) =>
  ApiService.request('post', '/admin/accounts', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateAdminAccountRequest = (
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/admin/accounts/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteAdminAccountRequest = (id, { dispatch, successCallback, failureCallback, callback, source }) =>
  ApiService.request('delete', `/admin/accounts/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const adminAccountPoliciesSummaryRequest = (
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/admin/accounts/${id}/policies_summary`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceIndexAdminAccountRequest = AwesomeDebouncePromise(indexAdminAccountRequest, 1000);
export const debounceUpdateAdminAccountRequest = AwesomeDebouncePromise(updateAdminAccountRequest, 1000);
export const debouncedAdminAccountPoliciesSummaryRequest = AwesomeDebouncePromise(
  adminAccountPoliciesSummaryRequest,
  1000
);
