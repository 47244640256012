import React from 'react';

const Dollar = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46725)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.53125 16.4613V19.0606H11.2641V16.4613H12.1305C14.0445 16.4613 15.5961 14.9097 15.5961 12.9957C15.5961 11.0817 14.0445 9.53008 12.1305 9.53008H11.2641V6.06446H12.9969V7.79727H14.7297V4.33164H11.2641V1.73242H9.53125V4.33164H8.66485C6.75083 4.33164 5.19922 5.88326 5.19922 7.79727C5.19922 9.71128 6.75083 11.2629 8.66485 11.2629H9.53125V14.7285H7.79844V12.9957H6.06563V16.4613H9.53125ZM11.2641 14.7285H12.1305C13.0875 14.7285 13.8633 13.9527 13.8633 12.9957C13.8633 12.0387 13.0875 11.2629 12.1305 11.2629H11.2641V14.7285ZM9.53125 9.53008V6.06446H8.66485C7.70784 6.06446 6.93203 6.84026 6.93203 7.79727C6.93203 8.75428 7.70784 9.53008 8.66485 9.53008H9.53125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46725">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Dollar;
