import React, { useContext } from 'react';

import { IndexFiltersContext } from '../../Shared';
import { IconBtn } from '../../Utils/Button';
import InfoCard from '../../Utils/Cards/InfoCard';
import accountObject from './accountObject';

const InfoCardAccount = ({ account, canEditAccount, defaultCard, modelUrl, userModule }) => {
  const filterStatus = useContext(IndexFiltersContext);
  const accountInfo = accountObject({ account, userModule });

  return (
    <InfoCard
      title="Información cuenta"
      object={accountInfo}
      className={defaultCard ? '' : 'borderless bg-light-gray'}
      iconBtnComponent={
        canEditAccount && (
          <IconBtn
            to={{ pathname: `${modelUrl}/${account.id}/edit`, state: filterStatus }}
            icon="pen"
            className="btn edit-btn no-shadow"
            variant="primary-dark"
            size="xs"
          />
        )
      }
    />
  );
};

export default InfoCardAccount;
