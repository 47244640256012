import React from 'react';
import { InsuranceItemForm } from '../../InsurancePolicies/Forms/ItemNestedAttributes';

const InsuranceEndorsementItemForm = ({ fromEndorsement, insuranceItemsAttributes, modelName, insurancePolicy }) => {
  const actualItemsCount = insuranceItemsAttributes.length;
  const newItemIndex = actualItemsCount + 1;

  return (
    <InsuranceItemForm
      key={`item-${newItemIndex}`}
      title={`Ítem ${newItemIndex}`}
      modelName={modelName}
      fromEndorsement={fromEndorsement}
      insurancePolicy={insurancePolicy}
    />
  );
};

export default InsuranceEndorsementItemForm;
