/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { BtnTooltip } from '../Button';
import { ButtonTooltip } from '../Tooltips';

const UploadFile = ({ className, disabled, error, label, onChange, optional, touched, ...props }) => {
  const { abbr, btnTooltipText, buttonStyle, fileAccept, fileUrl, filename, nameBtn, tooltipHtml, tooltipText } = props;

  const [name, setName] = useState('');

  const handleOnChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setName(file.name);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    onChange(file);
  };

  const handleDownloadFile = () => window.open(fileUrl, 'noopener');

  useEffect(() => setName(filename || ''), [filename]);

  return (
    <div className={`custom-upload-file ${className} ${label ? 'pt-4' : ''}`}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
          {optional && <span className="optional-label">(Opcional)</span>}
        </Form.Label>
      )}
      <div className="custom-file">
        <span
          className={`custom-upload-file--display ${tooltipText ? 'custom-padding' : ''} ${
            error && touched ? 'is-invalid' : ''
          } ${disabled ? 'disabled' : ''}`}
        >
          {name || 'Ningún archivo adjunto'}
          {tooltipText && (
            <ButtonTooltip
              html={tooltipHtml}
              tooltipText={tooltipText}
              containerClassName="custom-input--tooltip"
              tabIndex="-1"
            >
              ?
            </ButtonTooltip>
          )}
        </span>
        <input
          type="file"
          accept={fileAccept}
          id={`inputGroupFile01-${label}`}
          className="custom-upload-file--input"
          aria-describedby="inputGroupFileAddon01"
          onChange={handleOnChange}
          disabled={disabled}
        />

        {disabled ? (
          <BtnTooltip
            variant="primary"
            className="custom-upload-file--label no-shadow"
            onClick={handleDownloadFile}
            tooltipText={btnTooltipText || ''}
            disabled={!fileUrl}
          >
            Ver
          </BtnTooltip>
        ) : (
          <label
            className="custom-upload-file--label btn btn-primary no-shadow"
            htmlFor={`inputGroupFile01-${label}`}
            style={buttonStyle}
          >
            {nameBtn}
          </label>
        )}
      </div>
      {error && touched ? <small className="text-danger form-text">{error}</small> : null}
    </div>
  );
};

UploadFile.defaultProps = {
  customClassName: '',
  fileAccept: '',
  label: 'Archivo',
  nameBtn: 'Adjuntar',
  onChange: () => {},
  tooltipHtml: true
};

export default UploadFile;
