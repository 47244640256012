/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import useGetRoleByRoute from '../../hooks/useGetRoleByRoute';

const BlogCard = ({ blog }) => {
  const { type } = useGetRoleByRoute();
  const history = useHistory();
  const { title, subtitle, image, blogUrl, publishDate } = blog;

  const navigateToBLog = () => {
    history.push(`/${type}/blog/${blogUrl}`);
    window.scrollTo({ top: 250, left: 0, behavior: 'smooth' });
  };

  return (
    <div onClick={() => navigateToBLog()} className="blog-card" style={{ backgroundImage: `url(${image.fileUrl})` }}>
      <p className="published-date">{publishDate}</p>
      <h6>{title}</h6>
      <p>{subtitle}</p>
    </div>
  );
};

export default BlogCard;
