import React from 'react';
import { useSelector } from 'react-redux';
import './WhatsAppButton.css';
import whatsappLogo from '../../../assets/svg/whatsapp.svg';

const WhatsAppButton = () => {
  const { settings } = useSelector(state => state.utils);

  const cleanPhoneNumber = phoneNumber => {
    return phoneNumber?.replace(/\D+/g, '');
  };

  const phoneNumber = cleanPhoneNumber(settings.contactPhone);
  const isValidPhoneNumber = phoneNumber && phoneNumber.length >= 10; // Asume que un número válido tiene al menos 10 dígitos

  if (!isValidPhoneNumber) {
    return null;
  }

  return (
    <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <img width="60" height="60" src={whatsappLogo} alt="WhatsApp" />
    </a>
  );
};

export default WhatsAppButton;
