import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import alienOperator from '../../../assets/images/alien_operator_success.png';

import ClientLandingForm from './ClientLandingForm';
import { requestRegisterCmf } from '../../../requests/utils';
import { SimpleCenteredModal } from '../../Utils/Modal';
import { sendAlert } from '../../../actions/utils';

const ClientLandingFormSection = () => {
  const dispatch = useDispatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [cleanForm, setCleanForm] = useState(false);

  const handleCreateRequest = values => {
    requestRegisterCmf({
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => {
        setShowSuccessModal(true);
      },
      failureCallback: () => {
        dispatch(
          sendAlert({
            kind: 'error',
            message: 'Houston, tenemos un problema😬. Por favor vuelve a intentar más tarde.'
          })
        );
      }
    });
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    setCleanForm(!cleanForm);
  };

  return (
    <section id="demo" className="client-landing--main__info__formclient">
      <Row className="align-items-center m-0 d-flex justify-content-center">
        <div className="col-sm-12 col-md-5">
          <h2 className="client-landing--main__info__formclient__title text-dark-blue">
            Para empezar a mejorar tu experiencia con los seguros, partamos por conocer qué seguros tienes actualmente.
          </h2>
          <h6 className="client-landing--main__info__formclient__subtitle">
            Completa tus datos y revisa TODAS las pólizas vigentes que tienes con compañías de seguros en Chile.
          </h6>
          <p className="client-landing--main__info__formclient__text">*Disponible solo para personas naturales.</p>
        </div>
        <div className="col-sm-12 col-md-5 client-landing--form">
          <ClientLandingForm formRequest={handleCreateRequest} cleanForm={cleanForm} />
        </div>
      </Row>
      <SimpleCenteredModal
        show={showSuccessModal}
        size="sm"
        onHide={handleModalClose}
        body={
          <div className="client-landing--main__success_modal">
            <img src={alienOperator} alt="alienOperator" style={{ maxWidth: '130px' }} />
            <p className="client-landing--main__modal-text">
              <span className="client-landing--main__modal-span text-dark-blue">¡Felicitaciones!</span> Has dado el
              primer paso para una mejor protección.
            </p>
            <p className="mb-2">
              En menos de un minuto <b>recibirás un correo</b> con un reporte detallado de todas tus pólizas.
            </p>
            <Button type="button" onClick={() => handleModalClose()} className="mt-4 btn-primary">
              Entendido
            </Button>
          </div>
        }
      />
    </section>
  );
};

export default ClientLandingFormSection;
