import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Image } from 'react-bootstrap';
import { IconBtn } from '../../Utils/Button';
import BrokerLandingRrhh from '../../../assets/images/Landing/PartnerLanding/rrhh.webp';
import BrokerLandingDpto from '../../../assets/images/Landing/PartnerLanding/dpto.webp';
import BrokerLandingOrg from '../../../assets/images/Landing/PartnerLanding/org.webp';
import useGetRoleByRoute from '../../../hooks/useGetRoleByRoute';

const BrokerLandingKindServices = () => {
  const history = useHistory();
  const { translateType } = useGetRoleByRoute();
  const navigateToContact = () => history.push(`/${translateType}/landing_contact`);

  return (
    <section id="kindservicespartners" className="partner-landing__kindservices">
      <h4 className="partner-landing__title landing-section-title text-dark-blue">NICO se adapta a tu mercado</h4>
      <p className="partner-landing__subtitle text-dark-blue">Aquí algunos ejemplos</p>
      <div className="col">
        <Row>
          <div className="col-md-4 col-sm-12 partner-landing__kindservices__margin">
            <div className="partner-landing__kindservices__card ">
              <Image
                className="partner-landing__kindservices__card__img"
                src={BrokerLandingRrhh}
                alt="Reunión en una oficina de trabajo"
              />

              <div className="partner-landing__kindservices__card__containertext">
                <h6 className="partner-landing__kindservices__card__titlecard">Software de gestión de RRHH</h6>
                <p className="partner-landing__kindservices__card__textcard">
                  Protege a los trabajadores de tus clientes con seguros complementarios colectivos de salud, dental y
                  vida. Todo integrado en tu software y complementando tu oferta.
                </p>
                <div className="partner-landing__kindservices__card__btnleft">
                  <IconBtn
                    onClick={() => navigateToContact()}
                    className="main-button btn-landing w-mobile-100"
                    variant="primary-light-blue"
                  >
                    Empieza GRATIS
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 partner-landing__kindservices__margin">
            <div className="partner-landing__kindservices__card ">
              <Image
                className="partner-landing__kindservices__card__img"
                src={BrokerLandingDpto}
                alt="Mamá e hija disfrutando de su casa"
              />

              <div className="partner-landing__kindservices__card__containertext">
                <h6 className="partner-landing__kindservices__card__titlecard">
                  Software de administración de propiedades
                </h6>
                <p className="partner-landing__kindservices__card__textcard">
                  Protege a los propietarios, sus propiedades, el contenido y el pago de arriendo, con seguros que exige
                  la regulación y más. Todo integrado en tu plataforma y con las mejores condiciones.
                </p>
                <div className="partner-landing__kindservices__card__btnleft">
                  <IconBtn
                    onClick={() => navigateToContact()}
                    className="main-button btn-landing w-mobile-100"
                    variant="primary-light-blue"
                  >
                    Empieza GRATIS
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12">
            <div className="partner-landing__kindservices__card ">
              <Image
                className="partner-landing__kindservices__card__img"
                src={BrokerLandingOrg}
                alt="Grupo de una organización en una lugar autóctono"
              />

              <div className="partner-landing__kindservices__card__containertext">
                <h6 className="partner-landing__kindservices__card__titlecard">Asociaciones</h6>
                <p className="partner-landing__kindservices__card__textcard">
                  Moviliza a tu comunidad a contratar sus seguros a través de esta alianza y genera ingresos para
                  financiar las actividades e iniciativas de la asociación.
                </p>
                <div className="partner-landing__kindservices__card__btnleft">
                  <IconBtn
                    onClick={() => navigateToContact()}
                    className="main-button btn-landing w-mobile-100"
                    variant="primary-light-blue"
                  >
                    Empieza GRATIS
                  </IconBtn>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default BrokerLandingKindServices;
