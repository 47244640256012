import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { BtnTooltip } from '../Utils/Button';
import {
  getAdminPremiumCollectionPaymentDataRequest,
  sendAdminReminderRequest
} from '../../requests/admin/adminPremiumCollections';
import { premiumCollectionProps } from '../Shared/SendEmailModalProps';
import SendDocumentEmail from '../Shared/SendDocumentEmail';

const SendEmailBtn = ({ collectionItem }) => {
  const [premiumCollection, setPremiumCollection] = useState(null);
  const dispatch = useDispatch();

  const handleSendEmailRequest = (params, setSubmitting, handleSuccessRequest) => {
    const endpointRequest = sendAdminReminderRequest;

    endpointRequest(collectionItem.id, {
      dispatch,
      params,
      formData: true,
      successCallback: handleSuccessRequest,
      callback: () => setSubmitting(false)
    });
  };

  const { SendEmailModal, openEmailModal } = SendDocumentEmail({
    showCcField: true,
    showBccField: true,
    fileSection: true,
    fileSectionLabel: 'Adjuntos',
    textCancelButton: 'Cerrar',
    fromAdmin: true,
    customHandleSendEmailRequest: handleSendEmailRequest
  });

  const handleGetPaymentData = () => {
    getAdminPremiumCollectionPaymentDataRequest(collectionItem.id, {
      dispatch,
      params: {},
      successCallback: response => {
        const updatedData = camelCaseRecursive(response.data);
        setPremiumCollection({
          ...updatedData,
          accountName: collectionItem.accountName,
          additionalDocumentsInfo: collectionItem.additionalDocumentsInfo,
          insurancePolicyPolicyNumber: collectionItem.insurancePolicyPolicyNumber,
          insuranceBrokerName: collectionItem.insuranceBrokerName,
          createdFrom: collectionItem.createdFrom
        });
      }
    });
  };

  const handleOpenEmailModal = () => {
    if (premiumCollection) {
      openEmailModal();
    }
  };

  useEffect(handleOpenEmailModal, [premiumCollection]);

  return (
    <>
      <BtnTooltip
        icon="mail"
        iconSize="sm"
        variant="transparent"
        className="black"
        tooltipText="Enviar correo"
        onClick={handleGetPaymentData}
      />

      {premiumCollection && <SendEmailModal {...premiumCollectionProps({ premiumCollection })} />}
    </>
  );
};

export default SendEmailBtn;
