import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import PropTypes from 'prop-types';

import { ButtonTooltip } from '../Tooltips';

const dateFormat = date => {
  if (!date) return '';
  if (date instanceof Date) return date;
  const dateArray = date.split('/');
  return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
};

const FormikDatePicker = ({
  abbr,
  defaultDate,
  disabled,
  error,
  label,
  margin,
  maxDate,
  minDate,
  onChange,
  onlyTimeSelector,
  optional,
  placeholder,
  tooltipHtml,
  tooltipText,
  touched,
  value,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  const handleRequiredDate = () => {
    if (!value && abbr && defaultDate) setFieldValue(props.name, new Date());
  };

  useEffect(handleRequiredDate, [value]);

  return (
    <Form.Group data-tip data-for={`registerTip-datepicker-${tooltipText}`} className={`form-datepicker ${margin}`}>
      {label && (
        <Form.Label>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
          {optional && <span className="optional-label">(Opcional)</span>}
        </Form.Label>
      )}

      <DatePicker
        className={`form-control ${error && touched ? 'is-invalid' : ''}`}
        selected={dateFormat(value)}
        dateFormat={onlyTimeSelector ? 'h:mm aa' : 'dd/MM/yyyy'}
        autoComplete="off"
        onChange={inputDate => setFieldValue(props.name, inputDate || '')}
        placeholderText={placeholder}
        locale={es}
        showYearDropdown
        scrollableYearDropdown
        showMonthDropdown
        dropdownMode="select"
        yearDropdownItemNumber={100}
        readOnly={disabled}
        showTimeSelect={onlyTimeSelector}
        showTimeSelectOnly={onlyTimeSelector}
        minDate={minDate ? dateFormat(minDate) : null}
        maxDate={maxDate ? dateFormat(maxDate) : null}
        {...props}
      />

      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}

      {error && touched ? <Form.Text className="text-danger">{error}</Form.Text> : null}
    </Form.Group>
  );
};

FormikDatePicker.propTypes = {
  defaultDate: PropTypes.bool,
  tooltipText: PropTypes.string
};

FormikDatePicker.defaultProps = {
  defaultDate: true,
  tooltipText: ''
};

export default FormikDatePicker;
