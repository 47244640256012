import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAbility } from '@casl/react';
import { Spinner } from 'react-bootstrap';

import { FormHeader } from '../../components';
import { IndexFiltersProvider } from '../../components/Shared';
import { AbilityContext } from '../../config/abilityContext';
import { useIndexFilters } from '../../hooks';
import { showAccountRequest } from '../../requests/accounts';
import { camelCaseEmptyStringRecursive, redirectTo, scrollToTop } from '../../services/utils';
import basicAccount from './basicAccount';
import AccountInfo from './AccountInfo';

const AccountShow = ({ match }) => {
  const modelUrl = '/accounts';

  const { id } = match.params;

  const { filterStatus } = useIndexFilters({ originPath: modelUrl });

  const [account, setAccount] = useState(basicAccount);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const ability = useAbility(AbilityContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Cuentas', onClick: () => redirectTo(history, modelUrl, filterStatus) },
    { key: 2, name: 'Detalle de cuenta' }
  ];

  const handleShowAccount = () => {
    setOnRequest(true);
    showAccountRequest(id, {
      dispatch,
      successCallback: response => setAccount(camelCaseEmptyStringRecursive(response.data)),
      callback: () => setOnRequest(false)
    });
  };

  useEffect(scrollToTop, []);
  useEffect(handleShowAccount, [moreData]);

  return (
    <IndexFiltersProvider value={filterStatus}>
      {onRequest ? (
        <div className="containerSpinnerLoad" style={{ height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <FormHeader
          handleBreadcrumb={handleBreadcrumb}
          title={account.name}
          headerBtn={ability.can('manage', 'Lead')}
          headerBtnOptions={{
            icon: 'add',
            name: 'Nueva Oportunidad',
            onClick: () => redirectTo(history, '/leads/new', { accountId: id })
          }}
          formComponent={
            <AccountInfo userModule="broker" account={account} modelUrl={modelUrl} setMoreData={setMoreData} />
          }
        />
      )}
    </IndexFiltersProvider>
  );
};

export default AccountShow;
