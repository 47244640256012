import React from 'react';
import { Col } from 'react-bootstrap';

import { NestedAttributes } from '../../components';
import ContactFields from './ContactFields';
import basicContact from './basicContact';

const ContactAdd = ({ contactsAttributes, values, touched, errors, setFieldValue, parent, userModule }) => {
  const mapResults = contactsAttributes.map((body, index) => {
    if (body._destroy) {
      return undefined;
    }

    return (
      <Col>
        <ContactFields
          key={`contact-${index.toString()}`}
          index={index}
          touched={touched}
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
          parentModel={parent}
          forNestedAttributes
          userModule={userModule}
        />
      </Col>
    );
  });

  return (
    <NestedAttributes
      isColumnReverse
      removeFirstItem={!(userModule === 'insured')}
      attributesTitle="Información de contacto"
      mapInputs={mapResults}
      arrayValues={contactsAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${parent}[contactsAttributes]`}
      newAttributes={{ ...basicContact }}
      btnMessage="Agregar contacto"
      removeBtnStyle={{ position: 'absolute', top: '2rem', right: 0 }}
    />
  );
};

export default ContactAdd;
