import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminInsuranceCompanyLogoRequest } from '../../../requests/admin/insuranceCompanyLogos';
import basicInsuranceCompanyLogo from './basicInsuranceCompanyLogo';
import InsuranceCompanyLogoForm from './InsuranceCompanyLogoForm';

const InsuranceCompanyLogoNew = () => {
  const modelUrl = '/admin/insurance_company_logos';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Logo de aseguradoras', href: modelUrl },
    { key: 2, name: 'Crear nuevo logo' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Logo creado con éxito' }));
    history.push(modelUrl);
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const { logo } = values.insuranceCompanyLogo;
    const sendParams = snakeCaseKeys(values);
    sendParams.insurance_company_logo.logo = logo;
    createAdminInsuranceCompanyLogoRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <InsuranceCompanyLogoForm
          action="new"
          insuranceCompanyLogo={basicInsuranceCompanyLogo}
          formRequest={handleCreateRequest}
        />
      }
    />
  );
};

export default InsuranceCompanyLogoNew;
