import {
  debounceUpdateAdminInsurancePolicyRequest,
  updateAdminInsurancePolicyRequest
} from '../../requests/admin/adminInsurancePolicies';
import { debounceUpdateInsurancePolicyRequest, updateInsurancePolicyRequest } from '../../requests/insurancePolicies';

const basicInfoRoles = {
  broker: {
    isAdmin: false,
    name: 'Corredor',
    indexPath: '/insurance_policies',
    updateRequest: updateInsurancePolicyRequest,
    debounceUpdateRequest: debounceUpdateInsurancePolicyRequest,
    hideAttributes: { general: [], monetary: [], validity: [] },
    can: {
      addAdditionalDocument: true,
      autoSaveComment: true,
      editEndorsement: true,
      editSwitchBtn: true,
      sendDocument: true,
      showComment: true
    }
  },
  insured: {
    isAdmin: false,
    name: 'Asegurado',
    indexPath: '/insured/insurance_policies',
    updateRequest: () => null,
    debounceUpdateRequest: () => null,
    hideAttributes: {
      general: [],
      monetary: ['commission', 'nicoCommission', 'companyCommission', 'brokerCommission'],
      validity: []
    },
    can: {
      addAdditionalDocument: false,
      autoSaveComment: false,
      editEndorsement: false,
      editSwitchBtn: false,
      sendDocument: false,
      showComment: false
    }
  },
  admin: {
    isAdmin: true,
    name: 'Administrador',
    indexPath: '/admin/insurance_policies',
    updateRequest: updateAdminInsurancePolicyRequest,
    debounceUpdateRequest: debounceUpdateAdminInsurancePolicyRequest,
    hideAttributes: { general: [], monetary: [], validity: [] },
    can: {
      addAdditionalDocument: true,
      autoSaveComment: true,
      editEndorsement: true,
      editSwitchBtn: true,
      sendDocument: true,
      showComment: true
    }
  }
};

export default basicInfoRoles;
