import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ModalBodyIcon from '../Shared/ModalBodyIcon';
import { SimpleCenteredModal } from '../Utils/Modal';

const LeadQuotationSuccessModal = ({ brokerName, showModal, handleClose }) => {
  const handleSubContentModal = (
    <p>
      Pronto <span>{brokerName}</span> te contactará para confirmar la emisión de la póliza y que estás protegido.
    </p>
  );

  return (
    <SimpleCenteredModal
      show={showModal}
      onHide={handleClose}
      body={
        <ModalBodyIcon
          icon="check-o"
          iconVariant="success"
          content="Has aprobado la cotización con éxito"
          subContent={handleSubContentModal}
          confirmContent="¡Gracias!"
          highlightedText=""
          highlightedVariant="transparent"
          formContent={
            <Row className="justify-content-center">
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" onClick={handleClose}>
                  entendido
                </Button>
              </Col>
            </Row>
          }
        />
      }
    />
  );
};

export default LeadQuotationSuccessModal;
