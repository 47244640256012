import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import jwt from 'jwt-decode';

import {
  getInsuranceCompanyLogos,
  getPendings,
  getSettings,
  getUnreadNotifications,
  sendAlert
} from './app/actions/utils';
import { Alert } from './app/components';
import routes from './app/config/routes';
import { history } from './app/config/store';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import './index.scss';

const App = () => {
  const dispatch = useDispatch();
  const {
    ongoingRequest: { getUnreadNotifications: ongoingUnreadNotificationRequest },
    settings: { siteCatchPhrase = 'simplificar el universo de los seguros' }
  } = useSelector(state => state.utils);

  const updateSiteCatchPhrase = () => {
    document.title = `nico | ${siteCatchPhrase}`;
  };

  const handleInitialRequests = () => {
    const payload = localStorage.getItem('jwt');
    const user = payload && jwt(payload);
    const { roles = [] } = user || {};

    const isAdmin = roles.includes('admin');
    const isBroker = roles.includes('broker');
    const isInsured = roles.includes('insured');

    dispatch(getSettings());
    if (roles.length === 0 || isInsured) dispatch(getInsuranceCompanyLogos());

    if (!payload) return;
    const currentTime = Date.now() / 1000;
    const isExpired = currentTime >= jwt(payload).exp;

    if (isExpired) {
      dispatch(sendAlert({ kind: 'error', message: 'Tu sesión ha expirado' }));
      return;
    }

    if (ongoingUnreadNotificationRequest) return;
    if (isAdmin || isBroker) dispatch(getUnreadNotifications());
    if (isBroker) dispatch(getPendings());
  };

  const handleChangeRoute = () => {
    history.listen(() => handleInitialRequests());
  };

  useEffect(handleInitialRequests, []);
  useEffect(updateSiteCatchPhrase, [siteCatchPhrase]);
  useEffect(handleChangeRoute, [history]);

  return (
    <GlobalErrorBoundary>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      <Alert />
    </GlobalErrorBoundary>
  );
};

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);
