import React from 'react';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import { FormikNumberFormat } from '../../../Utils/Input';

const NumberField = ({ keyValue, details, modelName, errors, touched, optional }) => {
  const { title: label, placeholder, required, tooltip, prefix, suffix, formatNumber } = details;

  return (
    <Col sm={12} md={6} className="mb-4 pl-5">
      <Field name={`${modelName}[${keyValue}]`}>
        {({ field }) => (
          <FormikNumberFormat
            {...field}
            abbr={optional ? false : required}
            label={label}
            placeholder={placeholder || label}
            prefix={prefix ? `${prefix} ` : ''}
            suffix={suffix ? ` ${suffix}` : ''}
            tooltipText={tooltip}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
            value={field.value || ''}
            format={formatNumber}
          />
        )}
      </Field>
    </Col>
  );
};

export default NumberField;
