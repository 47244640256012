import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminPolicyBeneficiaryRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('get', `/admin/insurance_policies/${insurancePolicyId}/beneficiaries`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const createAdminPolicyBeneficiaryRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/admin/insurance_policies/${insurancePolicyId}/beneficiaries`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const adminBuklUploadBeneficiaryRequest = (
  insurancePolicyId,
  { dispatch, params = {}, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('post', `/admin/insurance_policies/${insurancePolicyId}/beneficiaries/bulk_upload`, {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const updateAdminPolicyBeneficiaryRequest = (
  insurancePolicyId,
  beneficiaryId,
  { dispatch, params, formData, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('put', `/admin/insurance_policies/${insurancePolicyId}/beneficiaries/${beneficiaryId}`, {
    dispatch,
    params,
    formData,
    successCallback,
    callback,
    failureCallback,
    source
  });

export const deleteAdminPolicyBeneficiaryRequest = (
  insurancePolicyId,
  beneficiaryId,
  { dispatch, failureCallback, successCallback, callback, source }
) =>
  ApiService.request('delete', `/admin/insurance_policies/${insurancePolicyId}/beneficiaries/${beneficiaryId}`, {
    dispatch,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const exportAdminBeneficiariesRequest = (
  insurancePolicyId,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) => {
  ApiService.request('get', `/admin/insurance_policies/${insurancePolicyId}/beneficiaries/export.xlsx`, {
    dispatch,
    params,
    responseType: 'text',
    successCallback,
    failureCallback,
    callback,
    source
  });
};

export const debounceIndexAdminPolicyBeneficiaryRequest = AwesomeDebouncePromise(
  indexAdminPolicyBeneficiaryRequest,
  300
);
