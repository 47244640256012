import React from 'react';

const CommissionCollectionInfo = () => (
  <>
    <p>
      Este módulo se construye con las principales acciones de cobranza de comisiones. En la tabla, podrás ver en cada
      fila los siguientes registros:
    </p>

    <ul className="mt-4">
      <li className="ml-4 mt-3">
        <span className="font-weight-bold">Nuevas pólizas con cadencia única.</span> Específicamente, cada vez que se
        emite una nueva póliza cuya prima y comisión se calculan y cobran de forma única (el pago de la prima se puede
        acordar en cuotas). El registro se crea para asegurar el pago de la comisión total. El pago de la comisión no
        necesariamente significa que el pago de primas está al día.
      </li>
      <li className="ml-4 mt-3">
        <span className="font-weight-bold">Endosos a pólizas con cadencia única.</span> Específicamente, cada vez que se
        aplica un endoso que genere una variación en la prima y la comisión (positiva o negativa) de una póliza de
        cadencia única. El registro se crea para asegurar el pago o reversa de la variación de la comisión. El pago de
        la comisión no necesariamente significa que el pago de primas está al día.
      </li>
      <li className="ml-4 mt-3">
        <span className="font-weight-bold">Cobranza de pólizas con cadencia mensual.</span> Específicamente, en el caso
        de pólizas cuya prima y comisión se calculan y cobran todos los meses durante la vigencia de la póliza. El
        registro se crea los días 1 de cada mes de cobertura. Es decir, para cada póliza con cadencia mensual, se crea
        un registro por cada mes calendario incluído en el período de vigencia de dicha póliza.
      </li>
    </ul>
  </>
);

export default CommissionCollectionInfo;
