import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import snakecaseKeys from 'snakecase-keys';

import { DefaultHeader, SimpleCenteredModal } from '../../../components';
import {
  PremiumCollectionDataTable,
  PremiumCollectionInfo,
  PremiumCollectionStatisticHeader
} from '../../../components/PremiumCollection';
import { useFetchData } from '../../../hooks';
import {
  debounceHeaderDashboardAdminPremiumCollectionRequest,
  debounceIndexAdminPremiumCollectionRequest,
  exportAdminPremiumCollectionRequest
} from '../../../requests/admin/adminPremiumCollections';
import { downloadFile } from '../../../services/utils';
import AdminPremiumCollectionNew from './AdminPremiumCollectionNew';

const AdminPremiumCollectionIndex = () => {
  const modelUrl = '/admin/premium_collections';
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const customParamsRef = useRef({});

  const {
    data: premiumCollections,
    totalData: totalPremiumCollections,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPremiumCollectionRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar primas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const handleExportPremiumCollectionRequest = ({ xlsxName, ...extraProps }) => {
    const sendParams = { xlsxName, ...extraProps };
    exportAdminPremiumCollectionRequest({
      dispatch,
      params: snakecaseKeys(sendParams),
      successCallback: response => downloadFile(response)
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setTimeout(() => {
      setModalTitle('');
      setModalBody('');
    }, 300);
  };

  const handleModalTooltip = () => {
    setModalShow(true);
    setModalTitle('Cobranza');
    setModalBody(<PremiumCollectionInfo />);
  };

  const handleModalCreate = () => {
    setModalShow(true);
    setModalTitle('Crear cobranza');
    setModalBody(
      <AdminPremiumCollectionNew
        setModalShow={setModalShow}
        setMoreData={setMoreData}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <DefaultHeader
      title="Cobranza"
      tooltipOnClick={handleModalTooltip}
      headerBtn
      headerBtnOptions={{
        isIconTooltip: true,
        icon: 'software-download',
        name: 'Descargar reporte',
        onClick: () =>
          handleExportPremiumCollectionRequest({
            xlsxName: 'reporte_de_cobranza'
          })
      }}
      secondBtn
      secondBtnOptions={{
        icon: 'add',
        name: 'Nueva cobranza',
        onClick: handleModalCreate
      }}
    >
      <PremiumCollectionStatisticHeader
        customParamsRef={customParamsRef}
        headerDashboardRequest={debounceHeaderDashboardAdminPremiumCollectionRequest}
        dataTableMoreData={moreData}
      />
      <PremiumCollectionDataTable
        userModule="admin"
        tableData={premiumCollections}
        tableDataAmount={totalPremiumCollections}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
      />
      <SimpleCenteredModal size="md" show={modalShow} title={modalTitle} body={modalBody} onHide={handleModalClose} />
    </DefaultHeader>
  );
};

export default AdminPremiumCollectionIndex;
