import React from 'react';
import { WhyNicoCard } from '../Shared';

const BrokerLandingWhyNico = () => {
  return (
    <section id="why-nico" className="landing--why-nico-partner__container mb-5">
      <div className="landing--why-nico-partner__tittlecontainer">
        <h4 className="landing--why-nico-partner__title landing-section-title font-weight-900 text-dark-blue">
          {' '}
          <br />
          Tú tienes una cartera de clientes,
          <br />
          NICO una corredora de seguros.<span style={{ color: '#21B0F2' }}> Juntos somos más.</span>
          <br />
        </h4>
      </div>

      <div className="landing--why-nico-partner landing--why-nico-partner__content landing--why-nico-partner landing--why-nico-partner__margin">
        <WhyNicoCard
          icon="smile-mouth-open"
          title="Comercial"
          text="Seleccionamos los seguros que son complementarios a tu propuesta de valor y participamos de todos los puntos de contacto con el cliente que tú nos digas. Trabajamos con todo tipo de seguros."
        />
        <WhyNicoCard
          icon="terminal"
          title="Técnico"
          text="Desde la primera asesoría y las negociaciones, hasta los siniestros. NICO es experto y se encarga de todo, para que tú puedas enfocarte en ser el mejor en tu negocio principal."
        />
        <WhyNicoCard
          icon="browse"
          title="Regulatorio"
          text="La intermediación de seguros está regulada por la CMF y, por lo mismo, tiene una serie de requisitos y restricciones. No te preocupes, NICO ya las cumple todas. Así, puedes empezar a operar hoy mismo."
        />
      </div>
    </section>
  );
};

export default BrokerLandingWhyNico;
