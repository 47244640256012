import React from 'react';

import { RequestRegisterSuccess } from '../Shared';
import { LandingNavbar } from '../Landing/Shared';

const WebLayout = ({ type = '', extraSettings, children }) => {
  return (
    <div className="landing">
      <RequestRegisterSuccess />
      <LandingNavbar type={type} extraSettings={extraSettings} />
      {children}
    </div>
  );
};

export default WebLayout;
