import React from 'react';

import { HeaderFilter } from '../../components';
import { accountAdvancedOptions, accountTabsOptions } from './AccountFiltersParams';

const AccountFilters = ({ customParams, setCustomParams }) => {
  const selectorOptions = [];

  return (
    <HeaderFilter
      paramName="clientType"
      tabOptions={accountTabsOptions}
      remoteSelectors={selectorOptions}
      advancedOptions={accountAdvancedOptions}
      customParams={customParams}
      setCustomParams={setCustomParams}
    />
  );
};

export default AccountFilters;
