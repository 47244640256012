const basicNavbarRoles = {
  broker: {
    isAdmin: false,
    name: 'Corredor',
    profilePath: { pathname: '/profile', state: { modelUrl: '/home' } },
    userPath: '/users',
    can: {
      notification: true,
      profile: true,
      trainning: false,
      user: true
    }
  },
  insured_gamma: {
    isAdmin: false,
    name: 'Asegurado',
    can: {
      notification: false,
      profile: false,
      trainning: false,
      user: false
    }
  },
  insured_delta: {
    isAdmin: false,
    name: 'Asegurado',
    can: {
      notification: false,
      profile: false,
      trainning: false,
      user: false
    }
  },
  admin: {
    isAdmin: true,
    name: 'Administrador',
    profilePath: { pathname: '/admin/profile', state: { modelUrl: '/admin/home' } },
    can: {
      notification: true,
      profile: true,
      trainning: false,
      user: false
    }
  },
  admin_standard: {
    isAdmin: true,
    name: 'Administrador',
    profilePath: { pathname: '/admin/profile', state: { modelUrl: '/admin/home' } },
    can: {
      notification: false,
      profile: false,
      trainning: false,
      user: false
    }
  },
  admin_landing: {
    isAdmin: true,
    name: 'Administrador',
    profilePath: { pathname: '/admin/profile', state: { modelUrl: '/admin/home' } },
    can: {
      notification: false,
      profile: false,
      trainning: false,
      user: false
    }
  }
};

export default basicNavbarRoles;
