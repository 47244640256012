/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';

import { DataTableBadges, DatatableMainInfo, ReadMore } from '../../../components';

const columns = memoize(() => [
  {
    name: 'N° póliza',
    selector: 'policy_number',
    sortable: true,
    grow: '1',
    cell: ({ policyNumber, validityStart, validityEnd, rowStyle }) => (
      <DatatableMainInfo
        icon={rowStyle.color?.includes('dark') ? 'ghost-character' : 'file-document'}
        iconSize="lg"
        btnIconStyle={rowStyle}
        title={policyNumber}
        subtitle={`I: ${validityStart}`}
        secondarySubtitle={`V: ${validityEnd}`}
      />
    ),
    minWidth: '200px'
  },
  {
    name: 'Cuenta',
    selector: 'account',
    sortable: true,
    grow: '1',
    cell: ({ accountName, accountNationalIdentification }) => (
      <DatatableMainInfo noIcon title={accountName} subtitle={accountNationalIdentification} />
    )
  },
  {
    name: 'Corredor',
    selector: 'insurance_broker',
    sortable: true,
    grow: '1.5',
    cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
      <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
    )
  },
  {
    name: 'Compañía',
    selector: 'insurance_company',
    sortable: true,
    grow: '1',
    cell: ({ insuranceCompanyName }) => insuranceCompanyName
  },
  {
    name: 'Ramo',
    selector: 'insurance_category',
    sortable: true,
    grow: '1',
    cell: ({ insuranceCategoryName }) => insuranceCategoryName
  },
  {
    name: 'Materia asegurable',
    selector: 'subject_matter',
    sortable: true,
    grow: '1',
    cell: ({ subjectMatter }) => <ReadMore title="Materia asegurable">{subjectMatter}</ReadMore>
  },
  {
    cell: row => <DataTableBadges row={row} />,
    sortable: false,
    right: true,
    grow: '1'
  }
]);

export default columns;
