import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { BasicTextArea, TabFilters } from '../../../../components';
import { InfoBox } from '../../../../components/Shared';
import AdditionsTextDescription from './AdditionsTextDescription';
import ExclusionsTextDescription from './ExclusionsTextDescription';
import movementTabsOptions from './movementTabOptions';

const AdminMovementForm = ({ errors, setFieldValue, setSubmitting, isSubmitting, touched, ...props }) => {
  const { errorMessage, handleModalClose } = props;
  const [tabOptions, setTabOptions] = useState({ tab: 'additions' });

  const handleColumnRulesAdd = ({
    rowArray: [
      nationalIdentif,
      name,
      birthday,
      role,
      incorpDate,
      companySentDateIncorp,
      dependNumber,
      lifeCov,
      healthCov,
      dentalCov,
      catastrophicCov,
      comments
    ]
  }) => {
    let allStructure = '';

    if (nationalIdentif) {
      const parsedNationalIdentification = nationalIdentif.trim();
      allStructure += `${parsedNationalIdentification}`;
    }

    if (name) {
      const parsedName = name.trim();
      allStructure += `\t${parsedName}`;
    }

    if (birthday) {
      const birthdayBase = birthday.replace(/[-]/g, '/');
      const parsedBirthday = birthdayBase.trim();
      allStructure += `\t${parsedBirthday}`;
    }

    if (role) {
      const parsedRole = role.trim();
      allStructure += `\t${parsedRole}`;
    }

    if (incorpDate) {
      const incorporationDate = incorpDate.replace(/[-]/g, '/');
      const parsedIncorporationDate = incorporationDate.trim();
      allStructure += `\t${parsedIncorporationDate}`;
    }

    if (companySentDateIncorp) {
      const companySentDateIncorporation = companySentDateIncorp.replace(/[-]/g, '/');
      const parsedcompanySentDateIncorporation = companySentDateIncorporation.trim();
      allStructure += `\t${parsedcompanySentDateIncorporation}`;
    }

    if (dependNumber) {
      const parsedDependentsNumber = parseInt(dependNumber, 0);
      allStructure += `\t${parsedDependentsNumber}`;
    }

    if (lifeCov) {
      const parsedLifeCoverage = parseInt(lifeCov, 0);
      allStructure += `\t${parsedLifeCoverage}`;
    }

    if (healthCov) {
      const parsedHealthCoverage = parseInt(healthCov, 0);
      allStructure += `\t${parsedHealthCoverage}`;
    }

    if (dentalCov) {
      const parsedDentalCoverage = parseInt(dentalCov, 0);
      allStructure += `\t${parsedDentalCoverage}`;
    }

    if (catastrophicCov) {
      const parsedCatastrophicCoverage = parseInt(catastrophicCov, 0);
      allStructure += `\t${parsedCatastrophicCoverage}`;
    }

    if (comments) {
      const parsedComments = comments.trim();
      allStructure += `\t${parsedComments}`;
    }

    return allStructure;
  };

  const handleColumnRulesExcl = ({
    rowArray: [nationalIdentif, exclDate, companySentDateExcl, dependNumber, comments]
  }) => {
    let allStructure = '';

    if (nationalIdentif) {
      const parsedNationalIdentification = nationalIdentif.trim();
      allStructure += `${parsedNationalIdentification}`;
    }

    if (exclDate) {
      const exclusionDate = exclDate.replace(/[-]/g, '/');
      const parsedExclusionDate = exclusionDate.trim();
      allStructure += `\t${parsedExclusionDate}`;
    }

    if (companySentDateExcl) {
      const companySentDateExclusion = companySentDateExcl.replace(/[-]/g, '/');
      const parsedCompanySentDateExclusion = companySentDateExclusion.trim();
      allStructure += `\t${parsedCompanySentDateExclusion}`;
    }

    if (dependNumber) {
      const parsedDependentsNumber = parseInt(dependNumber, 0);
      allStructure += `\t${parsedDependentsNumber}`;
    }

    if (comments) {
      const parsedComments = comments.trim();
      allStructure += `\t${parsedComments}`;
    }

    return allStructure;
  };

  const handleOnChangeTextArea = ({ fieldName, rows = [], formType }) => {
    const formattedComment = rows.map(row => {
      const rowArray = row.split('\t').filter(word => word);
      const formattedRows =
        formType === 'additions' ? handleColumnRulesAdd({ rowArray }) : handleColumnRulesExcl({ rowArray });

      const splitElement = row.split(';');
      const splitLength = splitElement.length >= 2;
      return splitLength ? `${splitElement[0].trim()}\t${splitElement[1].trim()}` : formattedRows;
    });

    setFieldValue(fieldName, formattedComment.join('\n'));
  };

  const additionsPlaceholder = () => {
    let placeholderText = `RUT titular; Nombre titular; Fecha de nacimiento titular; Rol titular; Fecha de incorporación; `;
    placeholderText += `Fecha de envío a compañía - incorporación; Número de cargas; Cobertura Vida (1/0); Cobertura Salud (1/0); `;
    placeholderText += `Cobertura Dental (1/0); Cobertura Catastrófico (1/0); Comentarios\n...`;
    return placeholderText;
  };

  const exclusionsPlaceholder = () => {
    return `RUT titular; Fecha de exclusión;  Fecha de envío a compañía - exclusión; Número de cargas; comentarios\n...`;
  };

  return (
    <>
      {errorMessage && (
        <InfoBox icon="danger" variant="danger" text={`La carga no pudo ser procesada: ${errorMessage}`} />
      )}

      <Form>
        <TabFilters
          optionsArray={movementTabsOptions}
          paramName="tab"
          customParams={tabOptions}
          setCustomParams={setTabOptions}
        />

        {tabOptions.tab === 'additions' && (
          <>
            <AdditionsTextDescription />
            <Row>
              <Col xs={12}>
                <Field name="beneficiary[additions]">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      row={6}
                      placeholder={additionsPlaceholder()}
                      onChange={({ target }) => {
                        const targetValue = target.value;
                        const rows = targetValue.split('\n');
                        handleOnChangeTextArea({ fieldName: field.name, rows, formType: 'additions' });
                      }}
                      error={getIn(errors, 'beneficiary')}
                      touched={getIn(touched, field.name)}
                      className="p-3 textarea-tab-size"
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </>
        )}

        {tabOptions.tab === 'exclusions' && (
          <>
            <ExclusionsTextDescription />
            <Row>
              <Col xs={12}>
                <Field name="beneficiary[exclusions]">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      row={6}
                      placeholder={exclusionsPlaceholder()}
                      onChange={({ target }) => {
                        const targetValue = target.value;
                        const rows = targetValue.split('\n');
                        handleOnChangeTextArea({ fieldName: field.name, rows, formType: 'exclusions' });
                      }}
                      error={getIn(errors, 'beneficiary')}
                      touched={getIn(touched, field.name)}
                      className="p-3 textarea-tab-size"
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </>
        )}

        <Row className="mt-2 d-flex justify-content-end">
          <Col md={7}>
            <Row className="d-flex justify-content-end">
              <Col md={3} className="mt-4">
                <Button block variant="cancel" className="no-shadow" onClick={handleModalClose}>
                  Cancelar
                </Button>
              </Col>
              <Col md={6} className="mt-4">
                <Button block type="submit" variant="submit" className="no-shadow" disabled={isSubmitting}>
                  Cargar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = ({ beneficiary }) => ({ beneficiary });

const validationSchema = Yup.object().shape({
  beneficiary: Yup.object()
    .shape({
      exclusions: Yup.string(),
      additions: Yup.string()
    })
    .test('at-least-one', 'Debes completar al menos uno de los campos: Incorporaciones o Exclusiones', value => {
      const additions = value.additions || '';
      const exclusions = value.exclusions || '';
      return additions.trim() !== '' || exclusions.trim() !== '';
    })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true
  // validateOnMount: props => props.action !== 'new'
})(AdminMovementForm);
