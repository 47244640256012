const basicInfoRoles = {
  broker: {
    isAdmin: false,
    name: 'Corredor',
    basePath: '',
    can: {
      redirectToAccount: true,
      redirectToInsurancePolicy: true,
      redirectToInsuranceEndorsement: true
    }
  },
  insured: {
    isAdmin: false,
    name: 'Asegurado',
    basePath: '/insured',
    can: {
      redirectToAccount: false,
      redirectToInsurancePolicy: true,
      redirectToInsuranceEndorsement: true
    }
  },
  admin: {
    isAdmin: true,
    name: 'Administrador',
    basePath: '/admin',
    can: {
      redirectToAccount: true,
      redirectToInsurancePolicy: true,
      redirectToInsuranceEndorsement: true
    }
  }
};

export default basicInfoRoles;
