import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ContactEdit from '../../../screens/Contact/ContactEdit';
import { IconBtn } from '../../Utils/Button';
import { InfoCard } from '../../Utils/Cards';
import contactObject from './contactObject';

const InfoCardContact = ({ account, canEditContact, fromAdmin, modelUrl, setMoreData, userModule }) => {
  const { primaryContact, totalCommercialContacts = 0 } = account;

  const contactInfo = contactObject({ contact: primaryContact, isEmpty: !primaryContact });

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);

  return (
    <InfoCard
      title="Información contacto principal"
      object={contactInfo}
      iconBtnComponent={
        canEditContact &&
        primaryContact && (
          <IconBtn
            onClick={() => setShowModal(true)}
            icon="pen"
            className="btn edit-btn no-shadow"
            variant="primary-dark"
            size="xs"
          />
        )
      }
      footerButton={
        canEditContact && (
          <Button
            as={Link}
            to={`${modelUrl}/${account.id}/contacts`}
            variant="secondary-light outline"
            className="no-shadow"
          >
            Ver Contactos
          </Button>
        )
      }
    >
      {primaryContact ? (
        <ContactEdit
          handleModalClose={handleModalClose}
          modalShow={showModal}
          setMoreData={setMoreData}
          contact={primaryContact}
          accountId={account.id}
          fromAdmin={fromAdmin}
          totalCommercialContacts={totalCommercialContacts}
          userModule={userModule}
        />
      ) : (
        <p className="mt-1">Sin contacto principal</p>
      )}
    </InfoCard>
  );
};

export default InfoCardContact;
