import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';

import { rutFormat } from '../../../services/utils';
import '../../../services/yupCustomMethods';
import { AccountFields } from '../../Account';

const AccountForm = ({ action, errors, handleCancelButton, isSubmitting, onHide, touched, values }) => {
  const submitBtnText = action === 'new' ? 'Crear Cuenta' : 'Guardar Cambios';

  return (
    <Form>
      <AccountFields values={values} errors={errors} touched={touched} action={action} />
      <Row className="d-flex flex-row-reverse align-items-center">
        <Col md={4} lg={3} className="mt-4">
          <Button block type="submit" variant="submit" onClick={onHide} disabled={isSubmitting}>
            {submitBtnText}
          </Button>
        </Col>
        <Col md={3} lg={2} className="mt-4">
          <Button block variant="cancel" className="mr-3" onClick={handleCancelButton}>
            Cancelar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ account }) => {
  const { nationalIdentification } = account;
  return {
    account: {
      ...account,
      nationalIdentification: rutFormat(nationalIdentification)
    }
  };
};

const validationSchema = Yup.object().shape({
  account: Yup.object().shape({
    contactsAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debes ingresar un nombre'),
        addressAttributes: Yup.object().nullable(),
        email: Yup.string()
          .required('Debes ingresar tu correo electrónico')
          .email('Debes ingresar un correo electrónico válido')
      })
    ),
    industryId: Yup.string().when('legalPersonType', {
      is: 'natural',
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Debes ingresar un rubro')
    }),
    // insuranceBrokerId: Yup.string().required('Debes seleccionar un corredor'),
    insuranceBrokerId: Yup.string().when('$fromAdmin', {
      is: true,
      then: Yup.string().required('Debes seleccionar un corredor'),
      otherwise: Yup.string().nullable()
    }),
    legalPersonType: Yup.string().required('Debes ingresar un tipo de cuenta'),
    name: Yup.string().required('Debes ingresar un nombre'),
    nationalIdentification: Yup.string()
      .required('Debes ingresar un RUT')
      .rut('El RUT es inválido')
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AccountForm);
