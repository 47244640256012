import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexInsuranceEndorsementsRequest = (
  insurancePolicyId,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_policies/${insurancePolicyId}/insurance_endorsements`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const createInsuranceEndorsementRequest = (
  insurancePolicyId,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('post', `/insurance_policies/${insurancePolicyId}/insurance_endorsements`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const exportInsuranceEndorsementSummary = (
  insurancePolicyId,
  insuranceEndorsementId,
  { dispatch, params, successCallback, callback, failureCallback }
) =>
  ApiService.request(
    'get',
    `/insurance_policies/${insurancePolicyId}/insurance_endorsements/${insuranceEndorsementId}/endorsement_summary`,
    {
      dispatch,
      params,
      responseType: 'text',
      successCallback,
      callback,
      failureCallback
    }
  );

export const showInsuranceEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, params = {}, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('get', `/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const updateCompanyInfoEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback }
) =>
  ApiService.request(
    'put',
    `/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}/update_company_info`,
    {
      dispatch,
      params,
      formData,
      successCallback,
      failureCallback,
      callback
    }
  );

export const updateInsuranceEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, params, formData, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('put', `/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const deleteInsuranceEndorsementRequest = (
  insurancePolicyId,
  id,
  { dispatch, successCallback, failureCallback, callback, source }
) =>
  ApiService.request('delete', `/insurance_policies/${insurancePolicyId}/insurance_endorsements/${id}`, {
    dispatch,
    successCallback,
    failureCallback,
    callback,
    source
  });

export const debounceInsuranceEndorsementsRequest = AwesomeDebouncePromise(indexInsuranceEndorsementsRequest, 300);
