import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import moment from 'moment';

import { removeFilters } from '../../../actions/utils';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import {
  debounceIndexInsuranceCategoryRequest,
  indexInsuranceCategoryRequest
} from '../../../requests/insuranceCategories';
import {
  debounceIndexInsuranceBrokeresRequest,
  indexInsuranceBrokerRequest
} from '../../../requests/admin/insuranceBrokers';
import { debounceIndexAdminUsersRequest, indexAdminUserRequest } from '../../../requests/admin/adminUsers';
import { closeStatuses, defaultCloseStatus } from '../../../screens/Lead/LeadFiltersParams';
import { AdvancedFilterStep } from '../../Shared';
import {
  debounceIndexInsuranceCompanyRequest,
  indexInsuranceCompanyRequest
} from '../../../requests/insuranceCompanies';

const LeadFiltersHeader = ({ fromAdmin, dateFromField, dateToField, setMoreData, ...props }) => {
  const { advancedOptionsBtn, customParamsRef, initialStep = 1, options } = props;
  const beginningOfMonth = new Date(moment().startOf('month'));

  const [currentStep, setCurrenStep] = useState(initialStep);
  const [customParams, setCustomParams] = useState(options.params[currentStep]);
  const [prevFilters, setPrevFilters] = useState({});
  const dispatch = useDispatch();

  const { filters } = useSelector(state => state.utils);
  const { filterOnMount, params: filterParams } = filters;

  const isCloseStep = currentStep === 3;

  const baseSelectorOptions = [
    {
      isMulti: true,
      fieldName: 'insuranceCategoryId',
      placeholder: 'Ramo',
      indexRequest: indexInsuranceCategoryRequest,
      debounceRequest: debounceIndexInsuranceCategoryRequest,
      colSizeClass: 'col-lg'
    }
  ];

  const adminSelectorOptions = [
    {
      fieldName: 'executiveId',
      placeholder: 'Ejecutivo',
      requestParams: { sortColumn: 'email', for_executives: 'present', admins: 'present', active: 'present' },
      indexRequest: indexAdminUserRequest,
      debounceRequest: debounceIndexAdminUsersRequest,
      colSizeClass: 'col-lg'
    },
    {
      fieldName: 'insuranceBrokerId',
      placeholder: 'Corredor',
      indexRequest: indexInsuranceBrokerRequest,
      debounceRequest: debounceIndexInsuranceBrokeresRequest,
      colSizeClass: 'col-lg'
    }
  ];

  const remoteSelectorOptions = [...(fromAdmin ? adminSelectorOptions : []), ...baseSelectorOptions];
  const simpleSelectorOptions = [];
  const searchOptions = [];

  if (isCloseStep) {
    simpleSelectorOptions.push({
      fieldName: 'closeStatus',
      placeholder: 'Estado',
      options: closeStatuses,
      defaultValue: defaultCloseStatus,
      colSizeClass: 'col-lg'
    });

    remoteSelectorOptions.push({
      fieldName: 'LeadQuotationCompanyId',
      placeholder: 'Aseguradora',
      indexRequest: indexInsuranceCompanyRequest,
      debounceRequest: debounceIndexInsuranceCompanyRequest,
      colSizeClass: 'col-lg'
    });

    searchOptions.push({
      fieldName: 'proposalNumber',
      placeholder: 'Buscar número propuesta...',
      icon: 'search',
      debounceRequest: debounceIndexInsuranceBrokeresRequest,
      colSizeClass: 'col-lg'
    });
  }

  const handleFilterRequest = () => {
    const { legal, natural } = customParams;
    let legalPersonType = '';
    const clientType = '';

    if (legal && !natural) {
      legalPersonType = 'legal';
    } else if (!legal && natural) {
      legalPersonType = 'natural';
    }

    const newParams = { ...emptyFalseParamsRecursive(customParams), legalPersonType, clientType };
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = snakeCaseKeys(newParams);
    setMoreData(m => !m);
    dispatch(removeFilters());
  };

  const handleStepChange = () => {
    if (filterOnMount) return;
    let dateFrom;
    if (isCloseStep) dateFrom = beginningOfMonth;
    setCustomParams({ ...options.params[currentStep], ...prevFilters, [dateFromField]: dateFrom });
    setPrevFilters({});
  };

  const handleFilterOnMount = () => {
    if (!filterOnMount) return;
    setPrevFilters(filterParams);
    setCurrenStep(filterParams.stepNumber);
  };

  const dateFilterTooltip = () => {
    return `Selecciona las fechas de inicio y término para cruzar por la fecha de 'Inicio de vigencia'.<br/>
    Recuerda que esta puede ser el término de vigencia de la póliza actual, o simplemente una fecha objetivo.`;
  };

  useEffect(handleStepChange, [currentStep, dateFromField]);
  useEffect(handleFilterRequest, [customParams]);
  useEffect(handleFilterOnMount, [filterOnMount]);

  return (
    <AdvancedFilterStep
      paramName={options[currentStep].stepName}
      options={options[currentStep]}
      steps={options.steps}
      remoteSelectors={remoteSelectorOptions}
      simpleSelectors={simpleSelectorOptions}
      setCurrenStep={setCurrenStep}
      customParams={customParams}
      setCustomParams={setCustomParams}
      dateFromField={dateFromField}
      dateToField={dateToField}
      advancedOptionsBtn={advancedOptionsBtn}
      searchOptions={searchOptions}
      dateFilterTooltipText={dateFilterTooltip()}
    />
  );
};

export default LeadFiltersHeader;
