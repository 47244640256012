import React from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { ModalBodyIcon } from '../../components/Shared';
import { createInsuranceEndorsementRequest } from '../../requests/insuranceEndorsement';
import RequestInsuranceEndorsementForm from './RequestInsuranceEndorsementForm';

const basicInsuranceEndorsement = {
  comments: '',
  endorsement: ''
};

const InsuranceEndorsementNew = ({ handleModalClose, insurancePolicyId, setMoreData }) => {
  const dispatch = useDispatch();

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Solicitud de endoso realizada con éxito' }));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleCreateInsuranceEndorsementRequest = params => {
    const { endorsement } = params.insuranceEndorsement;
    const sendParams = snakeCaseKeys(params.insuranceEndorsement);
    if (endorsement) sendParams.endorsement = endorsement;
    else delete sendParams.endorsement;
    createInsuranceEndorsementRequest(insurancePolicyId, {
      dispatch,
      params: { insurance_endorsement: sendParams },
      formData: true,
      successCallback: handleSuccessRequest
    });
  };

  return (
    <ModalBodyIcon
      icon="file-add"
      highlightedText="Solicitar endoso"
      highlightedVariant="light-warning"
      formContent={
        <RequestInsuranceEndorsementForm
          action="new"
          insuranceEndorsement={basicInsuranceEndorsement}
          formRequest={handleCreateInsuranceEndorsementRequest}
          handleModalClose={handleModalClose}
        />
      }
    />
  );
};

export default InsuranceEndorsementNew;
