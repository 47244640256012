import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { basicLead } from '../../../components/Lead/basicLead';
import LeadForm from '../../../components/Lead/LeadForm';
import { showLeadRequest, updateLeadRequest } from '../../../requests/lead';
import { showAdminLeadRequest, updateAdminLeadRequest } from '../../../requests/admin/lead';
import { camelCaseEmptyStringRecursive, scrollToTop } from '../../../services/utils';
import basicInsuranceItem from '../../../components/Lead/Form/basicInsuranceItem';
import useConvertDictionaryKeys from '../../../hooks/useConvertDictionaryKeys';

const AdminLeadEdit = ({ match }) => {
  const { id } = match.params;
  const currentModel = match.path.split('/');
  const isRenewal = currentModel.includes('renewals');
  const fromAdmin = currentModel.includes('admin');
  let modelUrl = isRenewal ? '/renewals' : '/leads';
  if (fromAdmin) modelUrl = `/admin${modelUrl}`;
  const showUrl = `${modelUrl}/${id}`;

  const [lead, setLead] = useState(basicLead);
  const dispatch = useDispatch();
  const history = useHistory();
  const { convertDictionaryKeys } = useConvertDictionaryKeys();

  const renewalBreadcrumb = [
    { key: 1, name: 'Renovación', href: modelUrl },
    { key: 2, name: 'Detalle de póliza', href: showUrl },
    { key: 3, name: 'Solicitud de cotización' }
  ];

  const leadsBreadcrumb = [
    { key: 1, name: 'Ventas', href: modelUrl },
    { key: 2, name: 'Detalle de oportunidad', href: showUrl },
    { key: 3, name: 'editar oportunidad' }
  ];

  const handleBreadcrumb = isRenewal ? renewalBreadcrumb : leadsBreadcrumb;

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Oportunidad actualizada con éxito' }));
    history.push(showUrl);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { quotationRequestFile } = values.lead;
    const sendParams = snakeCaseKeys(values, { exclude: ['_destroy'] });

    if (sendParams.lead.insurance_items_attributes) {
      sendParams.lead.insurance_items_attributes = convertDictionaryKeys(sendParams.lead.insurance_items_attributes);
    }

    sendParams.lead.quotation_request_file = quotationRequestFile;
    const updateRequest = fromAdmin ? updateAdminLeadRequest : updateLeadRequest;

    updateRequest(lead.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const handleSuccessShow = response => {
    const data = camelCaseEmptyStringRecursive(response.data);
    if (!data.insuranceItemsAttributes || data.insuranceItemsAttributes.length === 0) {
      data.insuranceItemsAttributes = [basicInsuranceItem];
    }
    setLead(data);
  };

  const fetchLead = () => {
    const fetchLeadRequest = fromAdmin ? showAdminLeadRequest : showLeadRequest;

    fetchLeadRequest(id, {
      dispatch,
      params: { edit: true },
      successCallback: handleSuccessShow
    });
  };

  useEffect(scrollToTop, []);
  useEffect(fetchLead, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <LeadForm
          userModule="admin"
          action="edit"
          fromAdmin={fromAdmin}
          modelUrl={modelUrl}
          lead={lead}
          formRequest={handleUpdateRequest}
        />
      }
    />
  );
};

export default AdminLeadEdit;
