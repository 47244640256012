import React from 'react';
import { ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminSlideRequest } from '../../../requests/admin/slides';
import columns from './Columns';

const CompanyDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  handleIndexRequest,
  modelUrl
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminSlideRequest,
    modelName: 'Slide',
    nameEndsInA: false,
    setMoreData
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default CompanyDataTable;
