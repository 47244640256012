import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { indexSlidesRequest } from '../../requests/slides';
import { downloadFile } from '../../services/utils';
import { LinkBtn, BasicDropdown } from '../../components';
import SlideDataTable from './SlideDataTable';

const SlideIndex = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessDownload = response => {
    downloadFile(response);
    setLoading(false);
  };

  const downloadIndex = format => {
    setLoading(true);
    indexSlidesRequest({
      dispatch,
      format,
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h2>Slides</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="primary" block to="/slides/new">
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={2}>
          <BasicDropdown
            variant="button"
            titleDrop="Download"
            items={[
              {
                key: '1',
                title: 'pdf',
                props: {
                  onClick: () => downloadIndex('pdf'),
                  disabled: loading
                }
              },
              {
                key: '2',
                title: 'xlsx',
                props: {
                  onClick: () => downloadIndex('xlsx'),
                  disabled: loading
                }
              }
            ]}
          />
        </Col>
      </Row>
      <SlideDataTable />
    </>
  );
};

export default withRouter(connect()(SlideIndex));
