import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../../actions/utils';
import { FormHeader } from '../../../../components';
import { updateAdminRegionRequest, showAdminRegionRequest } from '../../../../requests/admin/regions';
import AdminRegionForm from './AdminRegionForm';
import basicRegion from './basicRegion';

const AdminRegionEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/regions';
  const [region, setRegion] = useState(basicRegion);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Regiones', href: modelUrl },
    { key: 2, name: 'Editar región' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Región actualizada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminRegionRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchRegion = () => {
    showAdminRegionRequest(id, {
      dispatch,
      successCallback: response => setRegion(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchRegion, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminRegionForm action="edit" region={region} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminRegionEdit;
