import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { es } from 'date-fns/locale';
import { ModalBodyIcon } from '../Shared';
import { rollbackRequestedAdminLeadRequest } from '../../requests/admin/lead';
import { rollbackRequestedLeadRequest } from '../../requests/lead';
import { BasicTextArea } from '../Utils/Input';
import { BtnCheckbox } from '../Utils/Button';

const BackToStepModal = ({ handleModalClose, isAdmin, lead, setMoreData, isRenewal }) => {
  const submitBtnText = 'Etapa 1';
  const variant = 'strong-danger';
  const highlightedVariant = 'light-danger';
  const iconVariant = 'danger';
  const icon = 'arrow-left-o';
  const dispatch = useDispatch();
  const [selectedValidityStart, setSelectedValidityStart] = useState(lead.validityStart);
  const [comments, setComments] = useState('');
  const [notifyBroker, setNotifyBroker] = useState(true);

  const handleChecked = () => {
    setNotifyBroker(true);
  };

  const handleUnchecked = () => {
    setNotifyBroker(false);
  };

  const handleSuccessRequest = () => {
    setMoreData(m => !m);
  };

  const handleCloseLeadParams = () => {
    const sendParams = {
      close_status: null,
      validity_start: selectedValidityStart,
      notify_broker: notifyBroker ? 'true' : 'false',
      comments
    };
    return { lead: sendParams };
  };

  const handleRollbackCloseLeadRequest = () => {
    const sendParams = handleCloseLeadParams();
    const closeRequest = isAdmin ? rollbackRequestedAdminLeadRequest : rollbackRequestedLeadRequest;
    closeRequest(lead.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest
    });
  };

  const dateFormat = date => {
    if (!date) return '';
    if (date instanceof Date) return date;
    const dateArray = date.split('/');
    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
  };

  return (
    <>
      <ModalBodyIcon
        icon={icon}
        iconVariant={iconVariant}
        content="Volver a"
        subContent={
          <>
            <p>Estás por hacer a esta oportunidad volver a la etapa 1</p>
            <p>¿Estás seguro de querer hacerlo?</p>

            <p>
              Ingresa una nueva fecha de inicio de vigencia u objetivo. Puede ser la misma que ya ingresaste o un
              recordatorio para una futura renovación
            </p>

            <Row>
              <Col md={12}>
                <div className="form-datepicker m-0">
                  <Form.Label>
                    Inicio vigencia <abbr className="text-danger">*</abbr>
                  </Form.Label>
                  <DatePicker
                    className="form-control m-0"
                    selected={dateFormat(selectedValidityStart)}
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                    onChange={selectedStartDate => {
                      setSelectedValidityStart(selectedStartDate);
                      // setStartDate(selectedStartDate);
                      // setCustomParams(params => ({ ...params, [startDateField]: selectedStartDate || '' }));
                    }}
                    placeholderText="dd/mm/aaaa"
                    locale={es}
                  />
                </div>
              </Col>
              <Col md={12}>
                <BasicTextArea
                  abbr
                  label="Comentarios"
                  onChange={e => {
                    setComments(e.target.value);
                  }}
                  placeholder={`Deja un comentario para saber por qué no fue posible avanzar con esta ${
                    isRenewal ? 'renovación' : 'oportunidad'
                  }`}
                  value={comments}
                  row={3}
                />
              </Col>

              <Col md={12}>
                <BtnCheckbox
                  label="Notificar al corredor"
                  checked={notifyBroker}
                  onClickChecked={handleChecked}
                  onClickUnchecked={handleUnchecked}
                />
              </Col>
            </Row>
          </>
        }
        confirmText={false}
        highlightedText={submitBtnText}
        highlightedVariant={highlightedVariant}
        formRequest={handleRollbackCloseLeadRequest}
        handleModalClose={handleModalClose}
        submitVariant={variant}
        isSubmitting={!selectedValidityStart || !comments}
      />
    </>
  );
};

export default BackToStepModal;
