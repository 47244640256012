import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { BasicTextArea, FormikInput } from '../..';
import { InsuranceEndorsementContext } from '../InsuranceEndorsementContext';
import InsuranceEndorsementFormDate from './InsuranceEndorsementFormDate';

const ItemModification = ({ modelName, insurancePolicy, sectionStructure: SectionStructure }) => (
  <>
    <SectionStructure title="Información actual del ítem">
      <CurrentInsuranceItem modelName={`${modelName}[originalInsuranceItem]`} />
    </SectionStructure>
    <SectionStructure title="Ingresa la nueva información del ítem">
      <UpdatedInsuranceItem modelName={`${modelName}[insuranceItemAttributes]`} insurancePolicy={insurancePolicy} />
    </SectionStructure>
  </>
);

const CurrentInsuranceItem = ({ modelName }) => (
  <Row>
    <Col lg={3}>
      <Field name={`${modelName}[itemNumber]`}>
        {({ field }) => <FormikInput {...field} abbr disabled label="Número de ítem" />}
      </Field>
    </Col>
    <Col lg={9}>
      <Field name={`${modelName}[name]`}>
        {({ field }) => <FormikInput {...field} abbr disabled label="Detalle ítem" />}
      </Field>
    </Col>
    <Col lg={12}>
      <Field name={`${modelName}[notes]`}>
        {({ field }) => (
          <BasicTextArea {...field} disabled label="Notas ítem" maxLength={500} rows={2} className="p-3" />
        )}
      </Field>
    </Col>
  </Row>
);

const UpdatedInsuranceItem = ({ modelName, insurancePolicy }) => {
  const { action } = useContext(InsuranceEndorsementContext);
  const { errors, setFieldValue, touched } = useFormikContext();

  const handleInitialValues = () => {
    if (action === 'edit') return;
    setFieldValue(`${modelName}[name]`, '');
    setFieldValue(`${modelName}[notes]`, '');
  };

  useEffect(handleInitialValues, []);

  return (
    <>
      <InsuranceEndorsementFormDate
        modelName="insuranceEndorsement"
        dateLabel="Fecha de modificación"
        insurancePolicy={insurancePolicy}
      />
      <Row>
        <Col xs={12}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                maxLength={160}
                label="Nuevo detalle ítem"
                placeholder="Detalle ítem"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col xs={12}>
          <Field name={`${modelName}[notes]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Nuevas notas ítem"
                placeholder="Escribe todas las notas necesarias del ítem"
                maxLength={500}
                rows={2}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                className="p-3"
              />
            )}
          </Field>
        </Col>
      </Row>
    </>
  );
};

export default ItemModification;
