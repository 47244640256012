import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';

import '../../../services/yupCustomMethods';
import { FormikInput, FormikSwitch, UploadImage } from '../../../components';

const InsuranceCompanyLogoForm = ({ errors, isSubmitting, touched, setFieldValue, ...props }) => {
  const { action, insuranceCompanyLogo, onHide, submitVariant } = props;
  const submitBtnText = action === 'new' ? 'Crear logo' : 'Guardar cambios';

  return (
    <Form>
      <Row>
        <Col md={6}>
          <div className="d-flex justify-content-center align-items-center">
            <Field name="insuranceCompanyLogo[logo]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  forProfileAvatar
                  label="Adjuntar logo"
                  imageUrl={getIn(insuranceCompanyLogo.logo, 'fileUrl')}
                  onChange={logo => setFieldValue(field.name, logo)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <Field name="insuranceCompanyLogo[altInfo]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Información alternativa"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText="La información alternativa es <br/>
                           requerida en caso de no poder <br/>
                           mostrar el logo cargado"
              />
            )}
          </Field>
        </Col>
        <Col md={3}>
          <Field name="insuranceCompanyLogo[displayOrder]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                type="number"
                label="Posición"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name="insuranceCompanyLogo[active]">
            {({ field }) => <FormikSwitch {...field} field={field} label="¿Logo activo?" />}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="insuranceCompanyLogo[websiteLink]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Sitio web"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name="insuranceCompanyLogo[premiumPaymentLink]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Pago de primas"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end">
        <Col md={3} className="my-5">
          <Button block type="submit" variant={submitVariant} onClick={onHide} disabled={isSubmitting}>
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ insuranceCompanyLogo }) => ({ insuranceCompanyLogo });

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const validationSchema = Yup.object().shape({
  insuranceCompanyLogo: Yup.object().shape({
    active: Yup.boolean().nullable(),
    altInfo: Yup.string().required('Debes ingresar una información alternativa'),
    displayOrder: Yup.string().required('Debes ingresar una posición en la que se muestra'),
    logo: Yup.mixed()
      .test('required', 'Debes adjuntar un logo.', value => {
        return value && (value.size || value.fileUrl);
      })
      .test('fileSize', 'Archivo es demasiado grande.', value => {
        return value && value.size && value.size <= FILE_SIZE;
      })
      .test('fileFormat', 'Formato debe ser .jpg, .jpeg o .png', value => {
        return value && value.type && SUPPORTED_FORMATS.includes(value.type);
      }),
    premiumPaymentLink: Yup.string().nullable(),
    websiteLink: Yup.string().nullable()
  })
});

const handleSubmit = (values, { props, setSubmitting }) => {
  const { formRequest, onHide } = props;
  formRequest(values, setSubmitting);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(InsuranceCompanyLogoForm);
