import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikInput } from '../../../components';
import { handleFormatTextToLowerCase, rutFormat, validRutInput } from '../../../services/utils';

const CompanyLandingFirstSection = ({ modelName, sectionInfoComponent: SectionInfo }) => {
  const { errors, setFieldValue, touched } = useFormikContext();

  const handleNationalIdentificationFormat = e => {
    if (validRutInput(e)) {
      const formattedValue = rutFormat(e.target.value);
      setFieldValue(e.target.name, formattedValue);
    }
  };

  return (
    <div className="mb-5">
      <SectionInfo
        title="Información del dueño del vehículo a asegurar"
        description={`Ingresa la información del dueño/a del vehículo, independiente de quién sea que va a pagar el seguro ${String.fromCodePoint(
          '0x1F911'
        )}`}
      />
      <Row>
        <Col sm={6}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                placeholder="Nombre completo"
                label="Nombre y apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[nationalIdentification]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                placeholder="00.000.000-0"
                onChange={handleNationalIdentificationFormat}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[commune]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Comuna de residencia"
                placeholder="Ingresa una comuna"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={6}>
          <Field name={`${modelName}[email]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico de contacto"
                placeholder="usuario@correo.com"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                tooltipText={`Aquí puedes ingresar un e-mail que no<br/>
                             necesariamente sea el del dueño del vehículo ${String.fromCodePoint('0x1F609')}`}
              />
            )}
          </Field>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyLandingFirstSection;
