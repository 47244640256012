import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../actions/utils';
import { FormHeader } from '../../components';
import { createLeadRequest } from '../../requests/lead';
import LeadForm from '../../components/Lead/LeadForm';
import { basicLead } from '../../components/Lead/basicLead';
import useConvertDictionaryKeys from '../../hooks/useConvertDictionaryKeys';

const LeadNew = ({ location }) => {
  const modelUrl = '/leads';
  const dispatch = useDispatch();
  const history = useHistory();

  const [accountReference, setAccountReference] = useState('');
  const [isRequested, setIsRequested] = useState(basicLead.requested);
  const [prevFiltersParams, setPrevFiltersParams] = useState(null);
  const { convertDictionaryKeys } = useConvertDictionaryKeys();

  const handleBreadcrumb = [
    { key: 1, name: 'Ventas', href: modelUrl },
    { key: 2, name: 'Crear nueva oportunidad' }
  ];

  const handleSuccessCreate = response => {
    const { id, requested } = response.data;
    const action = requested ? 'Cotización solicitada' : 'Oportunidad creada';
    dispatch(sendAlert({ kind: 'success', message: `${action} con éxito` }));
    history.push(`${modelUrl}/${id}`);
  };

  const shouldShowDetail = (detail, item) => {
    const { showif } = detail;
    if (!showif) return true;

    const { id, selected_option: selectedOption } = showif;
    const selectedValue = item[id];
    return selectedValue && selectedOption.includes(selectedValue);
  };

  const proccessInsuranceItemsAttributes = sendParams => {
    const otherFields = sendParams.lead.dictionary_details.filter(detail => detail.id.startsWith('other'));
    if (otherFields.length === 0) return '';

    const title = 'Información de formulario de cotización:\n';
    let data = '';
    if (!data.includes(title)) {
      data += title;
    }

    sendParams.lead.insurance_items_attributes.forEach(item => {
      const otherFieldsString = otherFields
        .filter(detail => item[detail.id] !== undefined && shouldShowDetail(detail, item))
        .map(detail => `${detail.title}: ${item[detail.id]}`)
        .join('\n');

      data += otherFieldsString;
    });

    return data;
  };

  const handleCreateRequest = (values, setSubmitting) => {
    const { quotationRequestFile } = values.lead;
    const sendParams = snakeCaseKeys(values);
    sendParams.lead.quotation_request_file = quotationRequestFile;

    if (sendParams.lead.insurance_items_attributes && sendParams.lead.dictionary_details.length > 0) {
      if (!sendParams.subject_matter) {
        sendParams.lead.subject_matter += proccessInsuranceItemsAttributes(sendParams);
      } else {
        sendParams.lead.subject_matter += `\n\n${proccessInsuranceItemsAttributes(sendParams)}`;
      }
      sendParams.lead.insurance_items_attributes = convertDictionaryKeys(sendParams.lead.insurance_items_attributes);
    }

    createLeadRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      callback: () => setSubmitting(false)
    });
  };

  const handleSetFiltersOnMount = () => {
    const { state } = location;
    if (!state) return;
    if (state.accountId) setAccountReference(state.accountId);
    else setPrevFiltersParams(state.currentParams);
  };

  useEffect(handleSetFiltersOnMount, []);

  return (
    <div>
      <FormHeader
        handleBreadcrumb={handleBreadcrumb}
        previousFilterParams={prevFiltersParams}
        formComponent={
          <LeadForm
            userModule="broker"
            action="new"
            modelUrl={modelUrl}
            lead={{ ...basicLead, accountId: accountReference }}
            formRequest={handleCreateRequest}
            isRequested={isRequested}
            setIsRequested={setIsRequested}
          />
        }
      />
    </div>
  );
};

export default LeadNew;
