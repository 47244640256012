import ApiService from '../services/apiService';

export const resetPasswordRequest = ({ dispatch, params, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/password/reset', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const forgotPasswordRequest = ({ dispatch, params, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/password/forgot', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const sendContactFormRequest = ({ dispatch, params, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/pages/contact', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const requestRegisterRequest = ({ dispatch, params, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/pages/request_register', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });

export const requestRegisterCmf = ({ dispatch, params, failureCallback, successCallback, callback, source }) =>
  ApiService.request('post', '/public/custom_service', {
    dispatch,
    params,
    failureCallback,
    successCallback,
    callback,
    source
  });
