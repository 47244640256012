import React from 'react';

import ContractModification from './ContractModification';
import ItemModification from './ItemModification';

const SectionStructure = ({ children, title }) => (
  <>
    <hr className="w-100 my-5" />
    <p className="section-title">{title}</p>
    {children}
  </>
);

const InsuranceEndorsementFormModification = ({ modelName, insurancePolicy, isContractModification }) =>
  isContractModification ? (
    <ContractModification modelName={modelName} insurancePolicy={insurancePolicy} sectionStructure={SectionStructure} />
  ) : (
    <ItemModification modelName={modelName} insurancePolicy={insurancePolicy} sectionStructure={SectionStructure} />
  );

export default InsuranceEndorsementFormModification;
