import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Toast, Col, Button } from 'react-bootstrap';

const BasicToast = props => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const { src, text, body, title, header } = props;
  return (
    <Row>
      <Col xs={6}>
        <Toast onClose={handleClose} show={show} delay={3000} autohide>
          <Toast.Header>
            <img src={src} className="rounded mr-2" alt="basic-toast" />
            <strong className="mr-auto">{header}</strong>
            <small>{text}</small>
          </Toast.Header>
          <Toast.Body>{body}</Toast.Body>
        </Toast>
      </Col>
      <Col xs={6}>
        <Button onClick={handleShow}>{title}</Button>
      </Col>
    </Row>
  );
};

BasicToast.propTypes = {
  src: PropTypes.string,
  text: PropTypes.string,
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired
};

BasicToast.defaultProps = {
  src: null,
  text: null
};

export default BasicToast;
