import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { createAdminInsuranceCategoryRequest } from '../../../requests/admin/insuranceCategories';
import AdminInsuranceCategoryForm from './AdminInsuranceCategoryForm';
import basicInsuranceCategory from './basicInsuranceCategory';

const AdminInsuranceCategoryNew = () => {
  const modelUrl = '/admin/insurance_categories';
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Ramos', href: modelUrl },
    { key: 2, name: 'Crear nuevo ramo' }
  ];

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Empresa creada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureCreate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    createAdminInsuranceCategoryRequest({
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureCreate
    });
  };

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <AdminInsuranceCategoryForm
          action="new"
          insuranceCategory={basicInsuranceCategory}
          formRequest={handleCreateRequest}
        />
      }
    />
  );
};

export default AdminInsuranceCategoryNew;
