export const taskFiltersParams = {
  query: '',
  dateFrom: '',
  dateTo: new Date(),
  taskableType: 'account'
};

const taskTabsOptions = tabsCounter => {
  const {
    account: accountCounter,
    insurance_policy: insuracePolicyCounter,
    renewal: renewalCounter,
    lead: leadCounter
  } = tabsCounter;
  return [
    { label: `Cuentas (${accountCounter || 0})`, value: 'account' },
    { label: `Pólizas (${insuracePolicyCounter || 0})`, value: 'insurance_policy' },
    { label: `Renovaciones (${renewalCounter || 0})`, value: 'renewal' },
    { label: `Ventas (${leadCounter || 0})`, value: 'lead' }
  ];
};

export default taskTabsOptions;
