import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ClientLandingMain,
  ClientLandingKindServices,
  ClientLandingWhyNico,
  ClientLandingFormSection
} from '../../components/Landing/ClientLanding';
import { BrokerLandingCompanies, BrokerLandingDemoSecond } from '../../components/Landing/BrokerLanding';
import { LandingFooter } from '../../components/Landing/Shared';
import { WebLayout, WhatsAppButton } from '../../components';
import TestimonySection from '../../components/Landing/Shared/TestimonySection';
import { scrollToTop } from '../../services/utils';

const ClientLanding = () => {
  const { settings } = useSelector(state => state.utils);

  useEffect(scrollToTop, []);

  return (
    <WebLayout type="insured">
      <ClientLandingMain settings={settings} />
      <ClientLandingWhyNico />
      <ClientLandingFormSection />
      <ClientLandingKindServices />
      <TestimonySection />
      <BrokerLandingDemoSecond />
      <BrokerLandingCompanies />
      <LandingFooter settings={settings} />
      <WhatsAppButton />
    </WebLayout>
  );
};

export default ClientLanding;
