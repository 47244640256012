import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setSidebarShow } from '../../../actions/utils';
import { NotificationButton, NotificationList } from '../../Notification';
import { IconBtn } from '../Button';
import MainLogo from '../MainLogo';
import OutsideAlerter from '../OutsideAlerter';
import SettingsMenu from './SettingsMenu';
import basicNavbarRoles from './basicNavbarRoles';

const NavbarTop = ({ userModule, insuredType }) => {
  const [openPopUpMenu, setOpenPopUpMenu] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const {
    uiComponents: { showSidebar }
  } = useSelector(state => state.utils);
  const dispatch = useDispatch();

  // GET CURRENT ROLE DATA
  // const currentUserOptions = basicNavbarRoles[userModule];
  const currentUserOptions =
    userModule === 'insured' ? basicNavbarRoles[`insured_${insuredType}`] : basicNavbarRoles[userModule];
  const { can, isAdmin, profilePath, userPath } = currentUserOptions;

  const handleOpenPopUpMenu = () => {
    setOpenPopUpMenu(true);
    document.body.classList.add('no-scroll');
  };

  const handleClosePopUpMenu = () => {
    setOpenPopUpMenu(false);
    setIsNotificationsOpen(false);
    document.body.classList.remove('no-scroll');
  };

  const handleOpenNotifications = () => {
    setIsNotificationsOpen(true);
    handleOpenPopUpMenu();
  };

  const handleOpenSettings = () => {
    setIsNotificationsOpen(false);
    handleOpenPopUpMenu();
  };

  const handleToggleSidebar = () => {
    if (showSidebar) {
      dispatch(setSidebarShow(false));
      document.body.classList.remove('no-scroll');
    } else {
      dispatch(setSidebarShow(true));
      document.body.classList.add('no-scroll');
    }
  };

  return (
    <>
      <div className={`cover-screen ${openPopUpMenu ? 'show' : ''}`} />
      <div id="navbar-top" className={`navbar-top ${showSidebar ? 'sidebar-active' : ''} ${isAdmin ? 'admin' : ''}`}>
        <div className="mobile-menu navbar-top--button">
          <IconBtn onClick={handleToggleSidebar} icon={showSidebar ? 'close-o' : 'menu-round'} />
        </div>

        <div className="navbar-top--logo">
          <Link to="/">
            <MainLogo />
          </Link>
        </div>

        <div className="navbar-top--right">
          {can.trainning && (
            <div className="navbar-top--button">
              <IconBtn disabled variant="primary-dark" to="/trainings" icon="readme" />
            </div>
          )}

          {can.notification && <NotificationButton handleOpenNotifications={handleOpenNotifications} />}

          <div className="navbar-top--button">
            <IconBtn variant="primary-dark" onClick={handleOpenSettings} icon="more" />
          </div>
        </div>
      </div>

      <OutsideAlerter handleOutsideEvent={handleClosePopUpMenu}>
        <div className={`popup-menu ${openPopUpMenu ? 'show' : ''} ${isNotificationsOpen ? 'show-notifications' : ''}`}>
          {isNotificationsOpen ? (
            <NotificationList handleClose={handleClosePopUpMenu} />
          ) : (
            <SettingsMenu
              isAdmin={isAdmin}
              handleClose={handleClosePopUpMenu}
              canFromNavbarTop={can}
              profilePath={profilePath}
              userPath={userPath}
            />
          )}
        </div>
      </OutsideAlerter>
    </>
  );
};

export default NavbarTop;
