import ApiService from '../../services/apiService';

export default function sendVerificationCodeRequest({
  dispatch,
  params = {},
  formData,
  successCallback,
  failureCallback,
  callback
}) {
  ApiService.request('post', `/insured/sign_in_by_verification_codes/send_code`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback,
    callback
  });
}
