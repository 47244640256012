import React from 'react';
import memoize from 'memoize-one';

import { DatatableMainInfo, DataTableBadges, ReadMore } from '../../../components';
import { DatatableTaskCreator, basicTask } from '../../../components/Task';

const columns = memoize(({ setMoreData = () => null }) => [
  {
    name: 'N° póliza',
    selector: 'policy_number',
    sortable: true,
    grow: '1.5',
    minWidth: '150px',
    cell: ({ policyNumber, validityStart, validityEnd, rowStyle }) => (
      <DatatableMainInfo
        icon={rowStyle.color?.includes('dark') ? 'ghost-character' : 'file-document'}
        iconSize="sm"
        btnIconStyle={rowStyle}
        title={policyNumber}
        subtitle={`I: ${validityStart}`}
        secondarySubtitle={`V: ${validityEnd}`}
      />
    )
  },
  {
    name: 'Cuenta',
    selector: 'account',
    sortable: true,
    grow: '1.5',
    cell: ({ accountName, accountNationalIdentification }) => (
      <DatatableMainInfo noIcon title={accountName} subtitle={accountNationalIdentification} />
    )
  },
  {
    name: 'Corredor',
    selector: 'insurance_broker',
    sortable: true,
    grow: '1.5',
    cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
      <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
    )
  },
  {
    name: 'Compañía',
    selector: 'insurance_company',
    sortable: true,
    grow: '1',
    cell: ({ insuranceCompanyName }) => insuranceCompanyName
  },
  {
    name: 'Ramo',
    selector: 'insurance_category',
    sortable: true,
    grow: '1',
    cell: ({ insuranceCategoryName }) => insuranceCategoryName
  },
  {
    name: 'Materia asegurable',
    selector: 'subject_matter',
    sortable: true,
    grow: '1',
    cell: ({ subjectMatter }) => <ReadMore title="Materia asegurable">{subjectMatter}</ReadMore>
  },
  {
    name: 'Tareas',
    selector: 'tasks',
    sortable: false,
    grow: '1',
    minWidth: '130px',
    cell: ({ task = basicTask, id: insurancePolicyId }) => (
      <DatatableTaskCreator
        shortBtn
        fromAdmin
        task={task}
        taskableType="InsurancePolicy"
        taskableId={insurancePolicyId}
        setMoreData={setMoreData}
      />
    )
  },
  {
    cell: row => <DataTableBadges row={row} />,
    sortable: false,
    right: true,
    grow: '1.5'
  }
]);

export default columns;
