import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import { updateAdminBlogRequest, showAdminBlogRequest } from '../../../requests/admin/blogs';
import { basicBlog } from './basicBlog';
import AdminBlogForm from './AdminBlogForm';

const AdminBlogEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/blogs';
  const [blog, setBlog] = useState(basicBlog);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Blogs', href: modelUrl },
    { key: 2, name: 'Editar blog' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Blog actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    updateAdminBlogRequest(id, {
      dispatch,
      params: values,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchBlog = () => {
    showAdminBlogRequest(id, {
      dispatch,
      successCallback: response => setBlog(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchBlog, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminBlogForm action="edit" blog={blog} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminBlogEdit;
