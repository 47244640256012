import React, { useRef } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useHistory } from 'react-router-dom';

import { DefaultHeader, LeadDataTable } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminLeadRequest } from '../../../requests/admin/lead';
import { redirectTo } from '../../../services/utils';
import { firstStep, leadParams, leadSteps, secondStep, thirdStep } from '../../Lead/LeadFiltersParams';
import columns from './Columns';

const AdminLeadIndex = () => {
  const modelUrl = '/admin/leads';
  const showPath = `${modelUrl}`;
  const customParamsRef = useRef({});
  const history = useHistory();
  const leadOptions = { steps: leadSteps, params: leadParams, 1: firstStep, 2: secondStep, 3: thirdStep };
  const { data: leads, totalData: totalLeads, moreData, isFetching, handleIndexRequest, setMoreData } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminLeadRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar los datos. Por favor inténtalo nuevamente',
    customParams: { renewals: false, ...customParamsRef.current },
    withDataTable: true
  });

  const handleCreateInsurancePolicyProposal = () => {
    const policyType = 'proposal';
    redirectTo(history, '/admin/insurance_policies/new', {
      policyType,
      isRenewal: false,
      leadModelUrl: modelUrl,
      leadPath: showPath,
      lead: null
    });
  };

  return (
    <DefaultHeader
      title="Ventas"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nueva Oportunidad',
        onClick: () =>
          redirectTo(history, `${modelUrl}/new`, { currentParams: camelCaseRecursive(customParamsRef.current) })
      }}
      secondBtn
      secondBtnOptions={{
        icon: 'add',
        name: 'Generar Propuesta',
        onClick: () => handleCreateInsurancePolicyProposal()
      }}
    >
      <LeadDataTable
        fromAdmin
        tableData={leads}
        tableDataAmount={totalLeads}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
        columns={columns}
        headerOptions={leadOptions}
        defaultParams={{ sortColumn: 'validity_start', sortDirection: 'asc' }}
      />
    </DefaultHeader>
  );
};

export default AdminLeadIndex;
