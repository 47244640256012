import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../../actions/utils';
import { FormHeader } from '../../../../components';
import { updateAdminCommuneRequest, showAdminCommuneRequest } from '../../../../requests/admin/communes';
import AdminCommuneForm from './AdminCommuneForm';
import basicCommune from './basicCommune';

const AdminCommuneEdit = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/communes';
  const [commune, setCommune] = useState(basicCommune);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Comunas', href: modelUrl },
    { key: 2, name: 'Editar comuna' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Comuna actualizada con éxito' }));
    history.push(modelUrl);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateAdminCommuneRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const fetchCommune = () => {
    showAdminCommuneRequest(id, {
      dispatch,
      successCallback: response => setCommune(camelCaseRecursive(response.data)),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchCommune, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={<AdminCommuneForm action="edit" commune={commune} formRequest={handleUpdateRequest} />}
    />
  );
};

export default AdminCommuneEdit;
