import React, { useRef, useState } from 'react';

import { DefaultHeader, SimpleCenteredModal } from '../../components';
import {
  PremiumCollectionDataTable,
  PremiumCollectionInfo,
  PremiumCollectionStatisticHeader
} from '../../components/PremiumCollection';
import { useFetchData } from '../../hooks';
import {
  debounceHeaderDashboardPremiumCollectionRequest,
  debounceIndexPremiumCollectionRequest
} from '../../requests/premiumCollections';

const PremiumCollectionIndex = () => {
  const modelUrl = '/premium_collections';

  const [modalShow, setModalShow] = useState(false);

  const customParamsRef = useRef({});

  const {
    data: premiumCollections,
    totalData: totalPremiumCollections,
    moreData,
    setMoreData,
    isFetching,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexPremiumCollectionRequest,
    fetchingErrorMessage: 'Oops, hubo un problema al buscar primas. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  return (
    <DefaultHeader title="Cobranza" tooltipOnClick={() => setModalShow(true)}>
      <PremiumCollectionStatisticHeader
        customParamsRef={customParamsRef}
        headerDashboardRequest={debounceHeaderDashboardPremiumCollectionRequest}
        dataTableMoreData={moreData}
      />
      <PremiumCollectionDataTable
        userModule="broker"
        tableData={premiumCollections}
        tableDataAmount={totalPremiumCollections}
        modelUrl={modelUrl}
        isFetching={isFetching}
        moreData={moreData}
        setMoreData={setMoreData}
        handleIndexRequest={handleIndexRequest}
        customParamsRef={customParamsRef}
      />
      <SimpleCenteredModal
        size="md"
        show={modalShow}
        title="Primas"
        body={<PremiumCollectionInfo />}
        onHide={() => setModalShow(false)}
      />
    </DefaultHeader>
  );
};

export default PremiumCollectionIndex;
