import React from 'react';

const Smile = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6507_46728)">
        <path
          d="M10.4363 14.8377C8.49185 14.8377 6.91554 13.2614 6.91554 11.3169H13.9571C13.9571 13.2614 12.3808 14.8377 10.4363 14.8377Z"
          fill="white"
        />
        <path
          d="M12.1967 8.67633C12.1967 9.16245 12.5908 9.55653 13.0769 9.55653C13.563 9.55653 13.9571 9.16245 13.9571 8.67633C13.9571 8.19021 13.563 7.79614 13.0769 7.79614C12.5908 7.79614 12.1967 8.19021 12.1967 8.67633Z"
          fill="white"
        />
        <path
          d="M7.79574 9.55653C7.30962 9.55653 6.91554 9.16245 6.91554 8.67633C6.91554 8.19021 7.30962 7.79614 7.79574 7.79614C8.28186 7.79614 8.67593 8.19021 8.67593 8.67633C8.67593 9.16245 8.28186 9.55653 7.79574 9.55653Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.63437 10.4367C1.63437 15.2979 5.57514 19.2387 10.4363 19.2387C15.2975 19.2387 19.2383 15.2979 19.2383 10.4367C19.2383 5.57554 15.2975 1.63477 10.4363 1.63477C5.57514 1.63477 1.63437 5.57554 1.63437 10.4367ZM3.39476 10.4367C3.39476 14.3257 6.54737 17.4783 10.4363 17.4783C14.3253 17.4783 17.4779 14.3257 17.4779 10.4367C17.4779 6.54777 14.3253 3.39516 10.4363 3.39516C6.54737 3.39516 3.39476 6.54777 3.39476 10.4367Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6507_46728">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Smile;
