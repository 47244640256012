import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert } from '../../../actions/utils';
import { FormHeader } from '../../../components';
import {
  updateAdminInsuranceCompanyLogoRequest,
  showAdminInsuranceCompanyLogoRequest
} from '../../../requests/admin/insuranceCompanyLogos';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import basicInsuranceCompanyLogo from './basicInsuranceCompanyLogo';
import InsuranceCompanyLogoForm from './InsuranceCompanyLogoForm';

const InsuranceCompanyLogo = ({ match }) => {
  const { id } = match.params;
  const modelUrl = '/admin/insurance_company_logos';
  const [insuranceCompanyLogo, setInsuranceCompanyLogo] = useState(basicInsuranceCompanyLogo);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBreadcrumb = [
    { key: 1, name: 'Logo de aseguradoras', href: modelUrl },
    { key: 2, name: 'Editar logo' }
  ];

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Logo actualizado con éxito' }));
    history.push(modelUrl);
  };

  const handleUpdateRequest = (values, setSubmitting) => {
    const { logo } = values.insuranceCompanyLogo;
    const sendParams = snakeCaseKeys(values);
    sendParams.insurance_company_logo.logo = logo;
    updateAdminInsuranceCompanyLogoRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      callback: () => setSubmitting(false)
    });
  };

  const fetchInsuranceCompanyLogo = () => {
    showAdminInsuranceCompanyLogoRequest(id, {
      dispatch,
      successCallback: response => {
        const data = camelCaseEmptyStringRecursive(response.data);
        setInsuranceCompanyLogo({ ...data, logo: data.logoInfo });
      }
    });
  };

  useEffect(fetchInsuranceCompanyLogo, []);

  return (
    <FormHeader
      handleBreadcrumb={handleBreadcrumb}
      formComponent={
        <InsuranceCompanyLogoForm
          action="edit"
          insuranceCompanyLogo={insuranceCompanyLogo}
          formRequest={handleUpdateRequest}
        />
      }
    />
  );
};

export default InsuranceCompanyLogo;
