import React, { useRef } from 'react';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceIndexAdminUsersRequest } from '../../../requests/admin/adminUsers';
import AdminUserDataTable from './AdminUserDataTable';

const AdminUserIndex = () => {
  const customParamsRef = useRef({});
  const { data: users, totalData: totalUsers, moreData, setMoreData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminUsersRequest,
    fetchingErrorMessage: 'Ops, hubo un problema al buscar los usuarios. Por favor inténtalo nuevamente',
    customParams: customParamsRef.current,
    withDataTable: true
  });

  const modelUrl = '/admin/users';

  return (
    <DefaultHeader
      title="Usuarios"
      headerBtn
      headerBtnOptions={{
        icon: 'add',
        name: 'Nuevo Usuario',
        path: `${modelUrl}/new`
      }}
    >
      <AdminUserDataTable
        customParamsRef={customParamsRef}
        handleIndexRequest={handleIndexRequest}
        isFetching={isFetching}
        modelUrl={modelUrl}
        moreData={moreData}
        setMoreData={setMoreData}
        tableData={users}
        tableDataAmount={totalUsers}
      />
    </DefaultHeader>
  );
};

export default AdminUserIndex;
