import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput, FormikNumberFormat } from '../../../components';
import { handleFormatTextToLowerCase } from '../../../services/utils';

const AdminInsuranceCompanyExecutiveSection = ({ title, prefix = '' }) => {
  const { errors, touched, setFieldValue } = useFormikContext();

  return (
    <section className="mt-5">
      <p className="section-title">{title}</p>

      <Row>
        <Col sm={12}>
          <Field name={`insuranceCompany[${prefix}FullName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Nombre completo"
                placeholder="Nombre completo del ejecutivo"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={12}>
          <Field name={`insuranceCompany[${prefix}Email]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Correo electrónico"
                placeholder="Correo electrónico del ejecutivo"
                onChange={e => handleFormatTextToLowerCase(e, setFieldValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col sm={12}>
          <Field name={`insuranceCompany[${prefix}PhoneNumber]`}>
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                label="Teléfono"
                format="+56 # #### ####"
                onValueChange={number => setFieldValue(field.name, number.formattedValue)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </section>
  );
};

export default AdminInsuranceCompanyExecutiveSection;
