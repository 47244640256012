import React from 'react';
import { useDispatch } from 'react-redux';

import { rollbackCloseLeadRequest } from '../../requests/lead';
import { rollbackCloseAdminLeadRequest } from '../../requests/admin/lead';
import { ModalBodyIcon } from '../Shared';

const RollbackCloseStatusModal = ({ handleModalClose, isAdmin, leadId, setMoreData }) => {
  const submitBtnText = 'Etapa 2';
  const variant = 'strong-danger';
  const highlightedVariant = 'light-danger';
  const iconVariant = 'danger';
  const icon = 'arrow-left-o';
  const dispatch = useDispatch();

  const handleSuccessRequest = () => {
    setMoreData(m => !m);
  };

  const handleCloseLeadParams = () => {
    const sendParams = { close_status: 'success' };
    return { lead: sendParams };
  };

  const handleRollbackCloseLeadRequest = () => {
    const sendParams = handleCloseLeadParams();
    const closeRequest = isAdmin ? rollbackCloseAdminLeadRequest : rollbackCloseLeadRequest;
    closeRequest(leadId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessRequest
    });
  };

  return (
    <ModalBodyIcon
      icon={icon}
      iconVariant={iconVariant}
      content="Estás por hacer a esta oportunidad volver a la"
      highlightedText={submitBtnText}
      highlightedVariant={highlightedVariant}
      formRequest={handleRollbackCloseLeadRequest}
      handleModalClose={handleModalClose}
      submitVariant={variant}
    />
  );
};

export default RollbackCloseStatusModal;
