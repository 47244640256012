import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { capitalizeString } from '../../../services/utils';
import IconGG from '../Icon';

const StepTabsFilter = ({ setCurrentStep, stepsOptions, customParams }) => {
  const [selectedTab, setSelectedTab] = useState(stepsOptions[0].value || '');

  const handleClick = (value, step) => {
    setSelectedTab(value);
    setCurrentStep(parseInt(step, 10));
  };

  const handleSetCurrentStep = () => {
    const { step } = customParams;
    setSelectedTab(step);
  };

  useEffect(handleSetCurrentStep, [customParams]);

  return (
    <section className="pt-4">
      <div className="step-tabs--container d-inline-flex">
        {stepsOptions.map((item, index) => {
          const shouldShowSeparator = index + 1 < stepsOptions.length;
          return (
            <div key={`step-tab-${index.toString()}`} className="d-flex align-items-center">
              <Button
                key={item.value}
                data-target={item.value}
                data-step={item.key}
                className={`step-tabs--button ${selectedTab === item.value ? 'active' : ''}`}
                onClick={() => handleClick(item.value, item.key)}
              >
                <span className="step-tabs--button__step">{item.key}</span>
                <span className="step-tabs--button__text">
                  {item.label ? capitalizeString(item.label) : capitalizeString(item.value)}
                </span>
              </Button>
              {shouldShowSeparator ? (
                <IconGG className="icon-btn--icon mx-2 text-color-secondary" icon="chevron-right" size="md" />
              ) : null}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default React.memo(StepTabsFilter);
