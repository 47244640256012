import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ButtonTooltip } from '../Tooltips';

const FormikSwitch = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  field,
  label,
  margin,
  onClick,
  toolbarVariant,
  tooltipText,
  tooltipHtml,
  ...props
}) => (
  <section className="formik-switch">
    {label && (
      <Form.Label data-tip data-for={`registerTip-switch-${tooltipText}`}>
        {label} {abbr && <abbr className="text-danger">*</abbr>}
      </Form.Label>
    )}
    <Form.Group
      onClick={onClick}
      className={`d-flex align-items-center form-checkbox-switch ${margin && margin}`}
      data-tip
      data-for={`registerTip-switch-${tooltipText}`}
    >
      <Form.Check
        {...field}
        custom
        type="checkbox"
        checked={field.value}
        id={field.name}
        disabled={disabled}
        {...props}
      />
      <Form.Label
        htmlFor={field.name}
        className={`custom-switch-label ${field.value ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
      />

      {tooltipText && (
        <ButtonTooltip
          html={tooltipHtml}
          tooltipText={tooltipText}
          containerClassName="custom-input--tooltip"
          tabIndex="-1"
        >
          ?
        </ButtonTooltip>
      )}
    </Form.Group>
  </section>
);
FormikSwitch.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikSwitch.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};
export default FormikSwitch;
