import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Image, Row, Col } from 'react-bootstrap';
import { IconBtn } from '..';
import { showLeadQuotationRequest } from '../../requests/leadQuotations';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import { deleteAdminLeadQuotationRequest } from '../../requests/admin/leadQuotation';
import defaultProfileImg from '../../assets/images/default-profile.png';
import { useDestroyOne } from '../../hooks';

const SelectQuotation = ({
  canCloseQuotation,
  currentLeadQuotation,
  fromAdmin,
  isSelected,
  lead,
  setMoreData,
  setSelectedQuotations
}) => {
  const [leadQuotation, setLeadQuotation] = useState(currentLeadQuotation);
  const dispatch = useDispatch();

  const {
    id,
    insuranceCompanyLogoInfo,
    name,
    parsedAffectCommissionPercentage,
    parsedExemptCommissionPercentage,
    quotation
  } = leadQuotation;
  const quotationTitle = `${name}`;

  const handleShowLeadQuotationRequest = () => {
    showLeadQuotationRequest(id, {
      dispatch,
      successCallback: response => setLeadQuotation(camelCaseEmptyStringRecursive(response.data))
    });
  };

  const handleShowLeadQuotation = () => {
    handleShowLeadQuotationRequest();
    window.open(quotation?.fileUrl, '_blank', 'noopener');
  };

  const handleSelectQuotation = () => {
    setSelectedQuotations(prevSelectedQuotations => {
      const exists = prevSelectedQuotations.find(q => q.id === leadQuotation.id);
      if (exists) {
        return prevSelectedQuotations.filter(q => q.id !== leadQuotation.id);
      }
      return [...prevSelectedQuotations, leadQuotation];
    });
  };

  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    deleteRequest: deleteAdminLeadQuotationRequest,
    parentId: lead.id,
    modelName: 'Cotización',
    nameEndsInA: true,
    setMoreData
  });

  return (
    <tr className={`quotation-select__container ${isSelected ? 'quotation-select__selected' : ''}`}>
      <td className="align-middle" style={{ width: '10%' }}>
        <div className="profile-avatar">
          {insuranceCompanyLogoInfo && Object.keys(insuranceCompanyLogoInfo).length > 0 ? (
            <div className="custom-file-img mb-3">
              <Image
                src={insuranceCompanyLogoInfo.fileUrl || defaultProfileImg}
                fluid
                alt="image-1"
                style={{ filter: 'grayscale(100%)' }}
              />
            </div>
          ) : (
            <div className="custom-file-img mb-3">
              <Image src={defaultProfileImg} fluid alt="default-profile-img" style={{ filter: 'grayscale(100%)' }} />
            </div>
          )}
        </div>
      </td>

      <td className="align-middle" style={{ width: '35%' }}>
        <p className="list-element">{quotationTitle}</p>
      </td>

      <td className="align-middle" style={{ width: '10%' }}>
        {canCloseQuotation && parsedAffectCommissionPercentage}
      </td>

      <td className="align-middle" style={{ width: '10%' }}>
        {canCloseQuotation && parsedExemptCommissionPercentage}
      </td>

      <td className="align-middle" style={{ width: '35%' }}>
        <Row>
          <Col md={3} lg={3}>
            <Button variant="primary" className="btn-sm" onClick={handleShowLeadQuotation}>
              Ver
            </Button>
          </Col>
          <Col md={5} lg={5}>
            <Button variant="primary" className="btn-sm" onClick={handleSelectQuotation}>
              {isSelected ? 'Deseleccionar' : 'Seleccionar'}
            </Button>
          </Col>
          {fromAdmin && (
            <Col md={2} lg={2}>
              <IconBtn
                icon="trash"
                variant="transparent"
                className="danger"
                onClick={() => handleShowModal(leadQuotation)}
              />
            </Col>
          )}
        </Row>
      </td>

      {/* <td className="align-middle">
        <Button variant="primary" className="btn-sm" onClick={handleShowLeadQuotation}>
          Ver
        </Button>
      </td>

      <td className="align-middle">
        <Button variant="primary" className="btn-sm" onClick={handleSelectQuotation}>
          {isSelected ? 'Deseleccionar' : 'Seleccionar'}
        </Button>
      </td>

      {fromAdmin && (
        <td className="align-middle">
          <IconBtn
            icon="trash"
            variant="transparent"
            className="danger"
            onClick={() => handleShowModal(leadQuotation)}
          />
        </td>
      )} */}

      <OnDestroyModalComponent />
    </tr>
  );
};

export default SelectQuotation;
