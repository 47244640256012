import React from 'react';
import memoize from 'memoize-one';

import { BasicActionBtns, ReadMore } from '../../../../components';
import { StepDaysLabel } from '../../../../components/Shared';
import { DataTableBadges, DatatableMainInfo } from '../../../../components/Utils/DataTable';
import { DatatableTaskCreator, basicTask } from '../../../../components/Task';

const columns = memoize(
  (
    step,
    { setMoreData = () => null },
    {
      handleBasicActions,
      modelUrl,
      withShow = false,
      withEdit = false,
      withDestroy = false,
      showClick = false,
      editClick = false
    }
  ) => {
    const isClosed = step === 'closed';

    const leadColumns = [
      {
        name: 'N° póliza',
        selector: 'policy_number',
        sortable: true,
        grow: '1.5',
        minWidth: '150px',
        cell: ({
          renewableInsurancePolicyPolicyNumber,
          renewableInsurancePolicyRowStyle,
          renewableInsurancePolicyValidityEnd,
          renewableInsurancePolicyValidityStart
        }) => (
          <DatatableMainInfo
            icon={renewableInsurancePolicyRowStyle.color?.includes('dark') ? 'ghost-character' : 'file-document'}
            iconSize="sm"
            btnIconStyle={renewableInsurancePolicyRowStyle}
            title={renewableInsurancePolicyPolicyNumber}
            subtitle={`I: ${renewableInsurancePolicyValidityStart}`}
            secondarySubtitle={`V: ${renewableInsurancePolicyValidityEnd}`}
          />
        )
      },
      {
        name: 'Cuenta',
        selector: 'account',
        sortable: true,
        grow: '1.5',
        cell: ({ accountName, accountNationalIdentification }) => (
          <DatatableMainInfo noIcon title={accountName} subtitle={accountNationalIdentification} />
        )
      },
      {
        name: 'Corredor',
        selector: 'insurance_broker',
        sortable: true,
        grow: '1.5',
        cell: ({ insuranceBrokerCompanyName, insuranceBrokerName }) => (
          <DatatableMainInfo noIcon title={insuranceBrokerName} subtitle={insuranceBrokerCompanyName} />
        )
      }
    ];

    if (isClosed) {
      leadColumns.push({
        name: 'F. cierre',
        selector: 'close_date',
        sortable: true,
        grow: '1',
        cell: ({ closeDate }) => closeDate
      });
    }

    leadColumns.push(
      {
        name: 'Compañía',
        selector: 'insurance_company',
        sortable: true,
        grow: '1',
        cell: ({ renewableInsurancePolicyInsuranceCompanyName, insuranceProposalNumber }) => (
          <DatatableMainInfo
            noIcon
            title={renewableInsurancePolicyInsuranceCompanyName}
            subtitle={insuranceProposalNumber}
          />
        )
      },
      {
        name: 'Ramo',
        selector: 'insurance_category',
        sortable: true,
        grow: '1',
        cell: ({ insuranceCategoryName }) => insuranceCategoryName
      },
      {
        name: 'Materia',
        selector: 'subject_matter',
        sortable: true,
        grow: '1',
        cell: ({ subjectMatter }) => (
          <ReadMore more="+" title="Materia">
            {subjectMatter}
          </ReadMore>
        )
      },
      {
        name: 'Tareas',
        selector: 'tasks',
        sortable: false,
        grow: '1',
        minWidth: '130px',
        cell: ({ task = basicTask, id: leadId }) => (
          <DatatableTaskCreator
            shortBtn
            fromAdmin
            task={task}
            taskableType="Lead"
            taskableId={leadId}
            setMoreData={setMoreData}
          />
        )
      },
      {
        name: 'Días en la etapa',
        selector: 'step_days',
        sortable: true,
        grow: '0.5',
        cell: lead => <StepDaysLabel days={lead[`${lead.currentStep}StepDays`]} />
      },
      {
        cell: row => <DataTableBadges row={row} />,
        sortable: false,
        right: true,
        grow: '1.5'
      },
      {
        cell: item => (
          <BasicActionBtns
            item={item}
            clickHandler={handleBasicActions}
            path={modelUrl}
            withShow={withShow}
            withEdit={withEdit}
            withDestroy={withDestroy}
            showClick={showClick}
            editClick={editClick}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
        grow: '1',
        minWidth: '150px'
      }
    );

    return leadColumns;
  }
);

export default columns;
