import {
  danger,
  dark,
  greenSubmit,
  primaryDarkBlue,
  primaryLightBlue,
  primaryVarBlue,
  primaryVarGrey,
  varDanger,
  varDark,
  varLightBlue,
  varSuccess,
  varWarningYellow,
  warningYellow,
  white
} from '../../Utils/Color';

const hasMessage = ({ message, backgroundColor, color = '' }) =>
  message
    ? {
        '&::before': {
          content: `"${message}" !important`,
          color
        },
        '&:hover::before': {
          content: `"${message}"`,
          backgroundColor,
          opacity: '1',
          color
        }
      }
    : {};

const conditionalRowStyles = [
  {
    when: row => row.rowStyle.color === 'white',
    style: row => ({
      backgroundColor: white,
      border: `2px solid ${primaryDarkBlue}`,
      '&:not(:last-child)': {
        borderBottom: `2px solid ${primaryDarkBlue}`
      },
      '&:hover': {
        backgroundColor: white,
        border: `2px solid ${primaryDarkBlue}`,
        boxShadow: `0 5px 10px -5px ${primaryVarGrey}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: primaryDarkBlue, color: white })
    })
  },
  {
    when: row => row.rowStyle.color === 'varLightBlue',
    style: row => ({
      backgroundColor: varLightBlue,
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        backgroundColor: varLightBlue,
        border: `1px solid ${primaryLightBlue}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: primaryLightBlue })
    })
  },
  {
    when: row => row.rowStyle.color === 'lightBlue',
    style: row => ({
      backgroundColor: primaryVarBlue,
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        backgroundColor: primaryVarBlue,
        border: `1px solid ${primaryLightBlue}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: primaryLightBlue })
    })
  },
  {
    when: row => row.rowStyle.color === 'dark',
    style: row => ({
      backgroundColor: varDark,
      color: white,
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        backgroundColor: varDark,
        border: `1px solid ${dark}`,
        color: white
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: dark, color: white })
    })
  },
  {
    when: row => row.rowStyle.color === 'darkOutline',
    style: row => ({
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        border: `1px solid ${dark}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: dark, color: white })
    })
  },
  {
    when: row => row.rowStyle.color === 'success',
    style: row => ({
      backgroundColor: varSuccess,
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        backgroundColor: varSuccess,
        border: `1px solid ${greenSubmit}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: greenSubmit })
    })
  },
  {
    when: row => row.rowStyle.color === 'successOutline',
    style: row => ({
      backgroundColor: white,
      border: `2px solid ${greenSubmit}`,
      '&:not(:last-child)': {
        borderBottom: `2px solid ${greenSubmit}`
      },
      '&:hover': {
        backgroundColor: white,
        border: `2px solid ${greenSubmit}`,
        boxShadow: `0 5px 10px -5px ${primaryVarGrey}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: greenSubmit })
    })
  },
  {
    when: row => row.rowStyle.color === 'warning',
    style: row => ({
      backgroundColor: varWarningYellow,
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        backgroundColor: varWarningYellow,
        border: `1px solid ${warningYellow}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: warningYellow })
    })
  },
  {
    when: row => row.rowStyle.color === 'warningOutline',
    style: row => ({
      backgroundColor: '#FFFF',
      border: `2px solid ${warningYellow} !important`,
      '&:hover': {
        backgroundColor: white,
        border: `2px solid ${warningYellow}`,
        boxShadow: `0 5px 10px -5px ${primaryVarGrey}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: warningYellow })
    })
  },
  {
    when: row => row.rowStyle.color === 'danger',
    style: row => ({
      backgroundColor: varDanger,
      '&:not(:last-child)': {
        border: `1px solid ${white}`,
        borderBottom: `1px solid ${white}`
      },
      '&:hover': {
        backgroundColor: varDanger,
        border: `1px solid ${danger}`
      },
      ...hasMessage({ message: row.rowStyle.message, backgroundColor: danger, color: white })
    })
  }
];

export default conditionalRowStyles;
