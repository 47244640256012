/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { IconBtn } from '../../Utils/Button';
import MainLogo from '../../Utils/MainLogo';
import CompanyLandingNavbarLogo from '../CompanyLanding/CompanyLandingNavbarLogo';
import navbarItems from './navbarLinks';

const LandingNavbar = ({ type = '', extraSettings = {} }) => {
  const history = useHistory();
  const location = useLocation();
  const loginPath = { broker: '/login', insured: '/insured/login', partner: '/login' };
  const navbarHeight = location.pathname.includes('/blog') ? 0 : 300;
  const mobileWidthThreshold = 768;
  const isCompany = type === 'company';

  const [isBackgroundVisible, setIsBackgroundVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);
  const [isOpen, setIsOpen] = useState(false);

  const translateType = typeItem => {
    switch (typeItem) {
      case 'broker':
        return 'corredor';
      case 'partner':
        return 'partner';
      case 'insured':
        return 'asegurado';
      default:
        return '';
    }
  };

  const navigateToContact = () => history.push(`/${translateType(type)}/landing_contact`);

  const updateMobileStatus = () => {
    setIsMobile(window.innerWidth < mobileWidthThreshold);
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  const navigateTo = path => {
    setIsOpen(false);
    setTimeout(() => {
      history.push(path);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 300);
  };

  const isActiveLink = href => {
    return location.pathname === href;
  };

  const showBackground = () => {
    if (window.scrollY > navbarHeight) {
      setIsBackgroundVisible(true);
    } else {
      setIsBackgroundVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', showBackground);
    window.addEventListener('resize', updateMobileStatus);

    updateMobileStatus();

    return () => {
      window.removeEventListener('scroll', showBackground);
      window.removeEventListener('resize', updateMobileStatus);
    };
  }, []);

  if (isMobile) {
    return (
      <nav
        className={`landingMobileNavbar landingMobileNavbar-${type}`}
        style={{
          '--inline-primary-color': extraSettings.landingPrimaryColor,
          '--inline-secondary-color': extraSettings.landingSecondaryColor
        }}
      >
        <div className="mobileMenuButtons d-flex justify-content-end align-items-center w-100">
          {isCompany ? (
            <CompanyLandingNavbarLogo secondLogo={extraSettings.logoInfo?.fileUrl} />
          ) : (
            <Link to="/">
              <MainLogo type={type} />
            </Link>
          )}

          <div className="d-flex justify-content-end w-100">
            {isCompany ? (
              <IconBtn to="/insured/login" variant="transparent" className="outline-white landing--navbar__button">
                Sesión Asegurado
              </IconBtn>
            ) : (
              <>
                {!isOpen && !isCompany && (
                  <IconBtn to={loginPath[type]} variant="primary" className={`mobileLogginBtn-${type} ml-2`}>
                    Inicia Sesión
                  </IconBtn>
                )}
                <IconBtn
                  onClick={toggleOpen}
                  variant={isCompany ? 'transparent' : 'outline-primary'}
                  className={`${isCompany ? 'outline-white' : ''} mobileMenuOpenBtn-${type} ml-2`}
                >
                  {!isOpen ? 'Menú' : 'Cerrar'}
                </IconBtn>
              </>
            )}
          </div>
        </div>
        <div className={`mobileMenuContainer ${isOpen ? 'opened' : 'closed'}`}>
          <div
            className={`mobileMenu mobileMenu-${type}`}
            style={{
              '--inline-primary-color': extraSettings.landingPrimaryColor,
              '--inline-secondary-color': extraSettings.landingSecondaryColor
            }}
          >
            {navbarItems(type).map(link => (
              <div
                onClick={() => navigateTo(link.href)}
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                className={`mobileMenuItem mobileMenuItem-${type} ${isActiveLink(link.href) &&
                  `mobileMenuItem-${type}-active`}`}
              >
                <p>{link.text}</p>
              </div>
            ))}

            <div className="mobileMenuLogin">
              {type !== 'broker' && (
                <IconBtn
                  onClick={() => history.push(`/corredor`)}
                  variant="outline-primary"
                  className={`mobileMenuOpenBtn-${type} w-100 mb-3`}
                >
                  Soy Corredor
                </IconBtn>
              )}
              {type !== 'partner' && (
                <IconBtn
                  onClick={() => history.push(`/partner`)}
                  variant="outline-primary"
                  className={`mobileMenuOpenBtn-${type} w-100 mb-3`}
                >
                  Soy Partner
                </IconBtn>
              )}
              {type !== 'insured' && (
                <IconBtn
                  onClick={() => history.push(`/asegurado`)}
                  variant="outline-primary"
                  className={`mobileMenuOpenBtn-${type} w-100 mb-3`}
                >
                  Soy Asegurado
                </IconBtn>
              )}
              <IconBtn
                onClick={() => navigateToContact()}
                variant="outline-primary"
                className={`mobileMenuOpenBtn-${type} w-100 mb-3 ${type === 'insured' && 'bg-primary'}`}
              >
                {type === 'broker' && 'Empieza GRATIS'}
                {type === 'partner' && 'Quiero saber más'}
                {type === 'insured' && 'Quiero asesoría GRATIS'}
              </IconBtn>
              <IconBtn to={loginPath[type]} variant="secondary" className={`mobileLogginBtn-${type}`}>
                Inicia Sesión
              </IconBtn>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav
      className={`landing--navbar ${type} landing--navbar-${type} ${isBackgroundVisible ? '' : 'not-visible'}`}
      style={{
        '--inline-primary-color': extraSettings.landingPrimaryColor,
        '--inline-secondary-color': extraSettings.landingSecondaryColor
      }}
    >
      <div className="landing--navbar__left">
        {isCompany ? (
          <CompanyLandingNavbarLogo secondLogo={extraSettings.logoInfo?.fileUrl} />
        ) : (
          <Link to="/">
            <MainLogo type={type} />
          </Link>
        )}

        <div className="landing--navbar__center">
          {navbarItems(type).map(link => (
            <div
              className={`landing--navbar__link
                ${type === 'partner' && 'landing--navbar__link_partner'}
                ${isActiveLink(link.href) ? 'active' : ''}
                `}
              key={link.key}
              onClick={() => navigateTo(link.href)}
            >
              <p className="link-text">{link.text}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="landing--navbar__right">
        {isCompany ? (
          <IconBtn
            to="/insured/login"
            variant="transparent"
            className="outline-white landing--navbar__button rounded-border mr-4"
          >
            Sesión Asegurado
          </IconBtn>
        ) : (
          <>
            {type !== 'broker' && (
              <IconBtn
                onClick={() => navigateTo(`/corredor`)}
                variant={type !== 'partner' ? 'transparent' : ''}
                className={`landing--navbar__button outline-white-bgblue rounded-border mr-3 landing-button-${type}`}
              >
                Soy Corredor
              </IconBtn>
            )}
            {type !== 'partner' && (
              <IconBtn
                onClick={() => navigateTo(`/partner`)}
                variant={type !== 'partner' ? 'transparent' : ''}
                className={`landing--navbar__button outline-white-bgblue rounded-border mr-3 landing-button-${type}`}
              >
                Soy Partner
              </IconBtn>
            )}
            {type !== 'insured' && (
              <IconBtn
                onClick={() => navigateTo(`/asegurado`)}
                variant={type !== 'partner' ? 'transparent' : ''}
                className={`landing--navbar__button outline-white-bgblue rounded-border mr-3 landing-button-${type}`}
              >
                Soy Asegurado
              </IconBtn>
            )}
            <IconBtn
              onClick={() => navigateToContact()}
              variant={type !== 'partner' ? 'transparent' : ''}
              className={`landing--navbar__button outline-white-bgblue rounded-border mr-3 landing-button-${type}`}
            >
              {type === 'broker' && 'Empieza GRATIS'}
              {type === 'partner' && 'Quiero saber más'}
              {type === 'insured' && 'Quiero asesoría GRATIS'}
            </IconBtn>
            <IconBtn
              to={loginPath[type]}
              variant={type === 'partner' ? 'primary' : 'secondary'}
              className={`landing--navbar__button rounded-border ${type === 'partner' && 'login-btn-partner'}`}
            >
              Inicia Sesión
            </IconBtn>
          </>
        )}
      </div>
    </nav>
  );
};

export default LandingNavbar;
